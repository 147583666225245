import React from "react";
import FormLayout from "../../common/components/layout/FormLayout";
import {
  ROUTE_COMPANY_WASTE_RECOVERY_REQUESTS,
  ROUTE_MY_WASTE_RECOVERY_REQUESTS,
  ROUTE_WASTE_RECOVERY_REQUEST_SUMMARY,
} from "../../../routes";
import WasteStatusTimeline from "../components/WasteStatusTimeline";
import getWasteRecoveryRequestStatus from "../graphql/queries/getWasteRecoveryRequestStatus.graphql";
import { useQuery } from "@apollo/client";
import LoadingWrapper from "../../common/components/elements/LoadingWrapper";
import WasteRecoveryRequestStatusEnum from "../models/WasteRecoveryRequestStatus";
import { useParams } from "react-router";
import RequestStatusLifecycleCommercialForm from "../components/RequestStatusLifecycleCommercialForm";
import RequestStatusLifecycleAuthorForm from "../components/RequestStatusLifecycleAuthorForm";
import { useAuth } from "../../authentication/contexts/useAuth";
import Roles from "../../users/models/Roles";
import { Link } from "react-router-dom";
import pathToRegexp from "path-to-regexp";
import SoftDeletableRequest from "../components/SoftDeletableRequest";
import RequestStatusLifecycleSalesForm from "../components/RequestStatusLifecycleSalesForm";

export default function WasteRecoveryRequestStatus() {
  const { hasRole } = useAuth();
  const { uuid } = useParams();
  const { loading, error, data } = useQuery(getWasteRecoveryRequestStatus, {
    variables: { uuid },
  });

  const formClasses =
    "col-lg-6 d-flex flex-column align-items-center justify-content-center";

  return (
    <SoftDeletableRequest deleted={data?.getWasteRecoveryRequest?.deleted}>
      <FormLayout
        title={`Statut de la demande de reprise de déchets N° ${
          data?.getWasteRecoveryRequest?.number ?? "-"
        }`}
        size={FormLayout.INTERMEDIATE}
        className="waste-request-lifecycle"
        backRoute={
          hasRole(Roles.COMMERCIAL) || hasRole(Roles.SALES)
            ? ROUTE_MY_WASTE_RECOVERY_REQUESTS
            : ROUTE_COMPANY_WASTE_RECOVERY_REQUESTS
        }
        actions={
          <>
            <Link
              className="btn btn-primary float-right ml-1"
              to={pathToRegexp.compile(ROUTE_WASTE_RECOVERY_REQUEST_SUMMARY)({
                uuid,
              })}
              type="button"
            >
              Détails
            </Link>
          </>
        }
      >
        <LoadingWrapper loading={loading} error={Boolean(error)}>
          {() => {
            let status = new WasteRecoveryRequestStatusEnum(
              data.getWasteRecoveryRequest.status,
            );
            const { commercial, author, documents, number } =
              data.getWasteRecoveryRequest;
            const normalizedDocuments = documents.map((d) => ({
              name: d.name,
              producerName: d.producer.legalCompanyName,
              url: d.url,
              number,
            }));

            return (
              <>
                <div className="row">
                  <div className="offset-xl-6 col-xl-6 text-right mb-lg-2 mb-4">
                    {hasRole(Roles.COMMERCIAL) || hasRole(Roles.SALES) ? (
                      <>
                        Contact client:{" "}
                        <a href={`mailto:${author.email}`}>{author.email}</a>
                      </>
                    ) : (
                      <>
                        Contact commercial:{" "}
                        {commercial ? (
                          <a href={`mailto:${commercial.email}`}>
                            {commercial.email}
                          </a>
                        ) : (
                          <span className="text-muted">N/A</span>
                        )}
                      </>
                    )}
                  </div>
                </div>

                <div className="row">
                  <WasteStatusTimeline
                    className="col-lg-6 border-right"
                    status={status}
                    request={data.getWasteRecoveryRequest}
                  />

                  {(hasRole(Roles.COMMERCIAL) && (
                    // Lifecycle form differs on the user role, either commercial:
                    <RequestStatusLifecycleCommercialForm
                      className={formClasses}
                      status={status}
                      uuid={uuid}
                      documents={normalizedDocuments}
                    />
                  )) ||
                    (hasRole(Roles.SALES) && (
                      // either sales:
                      <RequestStatusLifecycleSalesForm
                        className={formClasses}
                        status={status}
                        uuid={uuid}
                        documents={normalizedDocuments}
                      />
                    )) || (
                      // or the author of the request:
                      <RequestStatusLifecycleAuthorForm
                        className={formClasses}
                        status={status}
                        uuid={uuid}
                        documents={normalizedDocuments}
                      />
                    )}
                </div>
              </>
            );
          }}
        </LoadingWrapper>
      </FormLayout>
    </SoftDeletableRequest>
  );
}
