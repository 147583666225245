import I18n from "i18n-js";
import React from "react";
import { func, shape } from "prop-types";
import { connect } from "react-redux";
import { ROUTE_CARRIERS } from "../../../routes";
import FormPage from "../../common/components/layout/FormPage";
import CarrierForm from "../components/CarrierForm";
import { resetCarrier, createCarrier } from "../actions/carriersActions";

class CreateCarrier extends FormPage {
  static propTypes = {
    resetCarrier: func.isRequired,
    createCarrier: func.isRequired,
    match: shape().isRequired,
    carrier: shape().isRequired,
  };

  constructor() {
    super();

    this.onSubmit = this.onSubmit.bind(this);
  }

  /**
   * {@inheritdoc}
   */
  componentDidMount() {
    this.props.resetCarrier();
  }

  /**
   * @param {object} carrier
   */
  onSubmit(carrier) {
    const { createCarrier, match } = this.props;
    const paramsCarrier = {
      clientUuid: match.params.uuid,
      ...carrier,
    };

    createCarrier(paramsCarrier);
  }

  /**
   * {@inheritdoc}
   */
  getTitle() {
    return I18n.t("pages.carriers.create.title");
  }

  /**
   * {@inheritdoc}
   */
  getBackRoute() {
    return ROUTE_CARRIERS;
  }

  /**
   * {@inheritdoc}
   */
  renderContent() {
    const { carrier } = this.props;

    return <CarrierForm loading={carrier.saving} onSubmit={this.onSubmit} />;
  }
}

const mapStateToProps = (state) => ({
  carrier: state.carrier,
});

const mapDispatchToProps = (dispatch) => ({
  resetCarrier: () => dispatch(resetCarrier()),
  createCarrier: (params) => dispatch(createCarrier(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateCarrier);
