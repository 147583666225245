/**
 * @param {Number|String} value
 * @param {Number}        decimals
 */
export function formatFloat(value, decimals = 2) {
  if (value === 0) {
    return value;
  }

  return parseFloat(value).toLocaleString("fr", {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  });
}

export function formatNumber(value) {
  return typeof value === "number" && value.toLocaleString("fr");
}
