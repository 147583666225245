/*
 * This file was generated by the "elao/enum" PHP package.
 * The code inside belongs to you and can be altered, but no BC promise is guaranteed.
 */

import { ReadableEnum } from "../../../libs/enum";

class CommercialRecoveryRequestRejectReason extends ReadableEnum {
  static INCOMPLETE = "INCOMPLETE";
  static CANNOT_HANDLE = "CANNOT_HANDLE";
  static OTHER = "OTHER";

  static get readables() {
    return {
      [CommercialRecoveryRequestRejectReason.INCOMPLETE]:
        "enum.waste.commercial_reject_reason.incomplete",
      [CommercialRecoveryRequestRejectReason.CANNOT_HANDLE]:
        "enum.waste.commercial_reject_reason.cannot_handle",
      [CommercialRecoveryRequestRejectReason.OTHER]:
        "enum.waste.commercial_reject_reason.other",
    };
  }
}

export default CommercialRecoveryRequestRejectReason;
