import React from "react";
import { useQuery } from "@apollo/client";
import LoadingWrapper from "../../common/components/elements/LoadingWrapper";
import shipping from "../graphql/queries/shipping.types.graphql";
import BsffShippingInformations from "./BsffShippingInformations";
import BsddShippingInformations from "./BsddShippingInformations";
import PropTypes from "prop-types";
import { trans } from "../../../translations";

TrackdechetsShippingInformation.propTypes = {
  shippingUuid: PropTypes.string,
};

export default function TrackdechetsShippingInformation({ shippingUuid }) {
  const { loading, error, data } = useQuery(shipping, {
    variables: {
      uuid: shippingUuid,
    },
  });

  return (
    <div>
      <h3>{trans("trackdechets.name")}</h3>
      <LoadingWrapper loading={loading} error={Boolean(error)}>
        {() => {
          const shipping = data.shipping;

          return (
            <div>
              <BsffShippingInformations
                shipping={shipping}
                shippingType={shipping.type}
              />
              <hr />
              <BsddShippingInformations
                shipping={shipping}
                shippingType={shipping.type}
              />
            </div>
          );
        }}
      </LoadingWrapper>
    </div>
  );
}
