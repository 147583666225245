import React, { Component } from "react";
import { parse } from "qs";
import { shape } from "prop-types";
import { inPeriod } from "../../utils/filterUtils";

class List extends Component {
  static propTypes = {
    location: shape().isRequired,
    history: shape().isRequired,
  };

  state = {
    filters: {
      page: 1,
    },
  };

  constructor(props, state = {}) {
    super(props, state);

    this.state = { ...this.state, ...state };
    this.cityFilter = this.cityFilter.bind(this);
    this.onPageChanged = this.onPageChanged.bind(this);
    this.getCurrentPage = this.getCurrentPage.bind(this);
  }

  componentDidMount() {
    const filters = parse(this.props.location.search.substr(1));

    if (Object.entries(filters).length === 0) {
      return;
    }

    // Parse page filter:
    filters["page"] = (filters["page"] && parseInt(filters["page"])) || 1;

    // Set filters from path url
    this.setState({ filters });
  }

  /**
   * @param {Object} filters
   */
  updateUrl(filters) {
    const { history } = this.props;

    // Clear null filter
    Object.keys(filters).forEach(
      (key) =>
        !filters[key] && filters[key] !== undefined && delete filters[key],
    );

    history.push({
      pathname: history.location.pathname,
      search: Object.keys(filters)
        .map((key) => [key, filters[key]].map(encodeURIComponent).join("="))
        .join("&"),
    });
  }

  /**
   * @param {Object}    event
   * @param {String}    filter
   * @param {Function?} callback
   * @param {Boolean}   isCaseSensitive
   */
  onChangeFilter(event, filter, callback = null, isCaseSensitive = false) {
    const { value } = event.target;

    this.onChangedFilterValue(value, filter, callback, isCaseSensitive);
  }

  /**
   * @param {String|Number} value
   * @param {String}    filter
   * @param {Function?} callback
   * @param {Boolean}   isCaseSensitive
   */
  onChangedFilterValue(
    value,
    filter,
    callback = null,
    isCaseSensitive = false,
  ) {
    let oldFiltersState = {};
    this.setState(
      (prevState) => {
        oldFiltersState = Object.assign({}, prevState.filters);
        const newFiltersState = Object.assign(this.state.filters, {
          page: 1,
          // eslint-disable-next-line no-nested-ternary
          [filter]: value?.length
            ? isCaseSensitive
              ? value
              : value.toLowerCase()
            : null,
        });

        this.updateUrl(newFiltersState);

        return { filters: newFiltersState };
      },
      () => {
        if (!callback) {
          return;
        }

        callback({ filters: oldFiltersState });
      },
    );
  }

  /**
   * @param {Number} page
   */
  onPageChanged(page) {
    this.setState((prevState) => {
      const newFiltersState = {
        ...prevState.filters,
        page,
      };

      this.updateUrl(newFiltersState);

      return { filters: newFiltersState };
    });
  }

  /**
   * @param {Boolean} checked
   * @param {String}  filter
   */
  onChangeCheckboxFilter(checked, filter) {
    const newFiltersState = Object.assign(this.state.filters, {
      [filter]: checked,
    });

    this.updateUrl(newFiltersState);

    this.setState({ filters: newFiltersState });
  }

  /**
   * @param {Date}          date
   * @param {String}        filter
   * @param {Function|null} callback
   */
  onChangeDateFilter(date, filter, callback = null) {
    let oldFiltersState = {};
    this.setState(
      (prevState) => {
        oldFiltersState = Object.assign({}, prevState.filters);

        const newFiltersState = Object.assign(this.state.filters, {
          [filter]: Date.parse(date),
        });

        this.updateUrl(newFiltersState);

        return { filters: newFiltersState };
      },
      () => {
        if (!callback) {
          return;
        }

        callback({ filters: oldFiltersState });
      },
    );
  }

  /**
   * @param {String}  property
   * @param {Boolean} strict
   *
   * @return {Function}
   */
  getFilterByText(property, strict = false) {
    if (!property) {
      throw new Error(
        'A param "property" is required to use getFilterByText method.',
      );
    }

    const value = this.state.filters[property];

    if (!value) {
      return () => true;
    }

    return (data) => {
      if (strict) {
        return data[property] && data[property] === value;
      }

      return data[property] && data[property].toLowerCase().includes(value);
    };
  }

  /**
   * @param {String}  property
   * @param {Boolean} isUuid
   *
   * @return {Function}
   */
  getFilterBySelect(property, isUuid = true) {
    if (!property) {
      throw new Error(
        'A param "property" is required to use getFilterBySelect method.',
      );
    }

    const value = this.state.filters[property];

    if (!value) {
      return () => true;
    }

    return (data) => {
      if (data[property]) {
        if (!isUuid) {
          return data[property].toLowerCase().includes(value);
        }

        return data[property].uuid === value;
      }

      return data[`${property}Uuid`] === value;
    };
  }

  /**
   * @param {String} property
   *
   * @return {Function}
   */
  getFilterByDate(property) {
    if (!property) {
      throw new Error(
        'A param "property" is required to use getFilterByDate method.',
      );
    }

    const { startDate, endDate } = this.state.filters;

    if (!startDate && !endDate) {
      return () => true;
    }

    return inPeriod(
      startDate instanceof Date ? startDate : parseInt(startDate),
      endDate instanceof Date ? endDate : parseInt(endDate),
      property,
    );
  }

  /**
   * @param {String}  property
   * @param {Boolean} reverse
   *
   * @return {Function}
   */
  getFilterByCheckbox(property, reverse = false) {
    if (!property) {
      throw new Error(
        'A param "property" is required to use getFilterByCheckbox method.',
      );
    }

    const value = this.state.filters[property];

    if (!value) {
      return () => true;
    }

    return (data) => {
      if (reverse) {
        return !data[property];
      }

      return data[property];
    };
  }

  /**
   * @param {String}   property
   * @param {Function} callback
   *
   * @return {Function}
   */
  getCustomFilter(property, callback) {
    if (!property || !callback) {
      throw new Error(
        'A param "property" and "callback" are required to use getCustomFilter method.',
      );
    }

    const value = this.state.filters[property];

    if (!value) {
      return () => true;
    }

    return (data) => callback(data, value);
  }

  /**
   * @returns {Number}
   */
  getCurrentPage() {
    return this.state.filters.page;
  }

  /**
   * @param {Object} data
   * @param {String} value
   *
   * @return {Boolean}
   */
  cityFilter(data, value) {
    return (
      data.address &&
      data.address.city &&
      data.address.city.toLowerCase().includes(value)
    );
  }

  /**
   * Render page title
   *
   * @return {String}
   */
  renderTitle() {
    throw new Error("You must implement method renderTitle");
  }

  /**
   * Render the content of the list
   *
   * @return {JSX.Element|JSX.Element[]}
   */
  renderContent() {
    throw new Error("You must implement method renderContent");
  }

  /**
   * Render filters
   *
   * @return {Array} Array of filters elements (Select, TextInput, Checkbox...)
   */
  renderFilters() {}

  /**
   * {@inheritdoc}
   */
  render() {
    return (
      <div>
        <h1>{this.renderTitle()}</h1>
        {this.renderContent()}
      </div>
    );
  }
}

export default List;
