import React from "react";
import WasteFormBreadcrumb from "../components/WasteFormBreadcrumb";
import FormLayout from "../../common/components/layout/FormLayout";
import {
  ROUTE_MY_WASTE_RECOVERY_REQUESTS,
  ROUTE_WASTE_RECOVERY_REQUESTS_CREATE_TRANSPORT,
} from "../../../routes";
import { connect, useSelector } from "react-redux";
import {
  addArticle,
  removeArticle,
} from "../actions/createWasteRecoveryRequestActions";
import PropTypes from "prop-types";
import { ArticleInput } from "../propTypes/types";
import WasteArticlesForm from "../components/WasteArticlesForm";
import { useHistory } from "react-router";
import {
  getCollectionFieldsViolations,
  getFieldViolations,
} from "../selectors/createWasteRecoveryRequestSelectors";
import { StandaloneInvalidFeedback } from "../../common/components/form/InvalidFeedback";

export default connect(
  (state) => {
    const { siteUuid, articles, containers } =
      state.createWasteRecoveryRequestReducer;

    return { siteUuid, articles, hasContainers: containers.length > 0 };
  },
  {
    addArticle,
    removeArticle,
  },
)(CreateWasteRecoveryRequestArticles);

CreateWasteRecoveryRequestArticles.propTypes = {
  siteUuid: PropTypes.string.isRequired,
  articles: PropTypes.arrayOf(ArticleInput),
  hasContainers: PropTypes.bool.isRequired,
  addArticle: PropTypes.func.isRequired,
  removeArticle: PropTypes.func.isRequired,
};

CreateWasteRecoveryRequestArticles.propTypes = {};

function CreateWasteRecoveryRequestArticles({
  siteUuid,
  hasContainers,
  articles,
  addArticle,
  removeArticle,
}) {
  const history = useHistory();

  const globalErrors = useSelector(getFieldViolations("articles")).map(
    (v) => v.message,
  );
  const errors = useSelector(
    getCollectionFieldsViolations(
      "articles",
      [
        "articleUuid",
        "producerUuid",
        "quantity",
        "mass",
        "treatmentMode",
        "toDestruct",
        "toReturn",
        "packaging",
      ],
      articles.length,
      (v) => v.message,
    ),
  );

  const onSubmit = (e) => {
    e.preventDefault();

    history.push(ROUTE_WASTE_RECOVERY_REQUESTS_CREATE_TRANSPORT);
  };

  const onAddArticle = () => addArticle({});

  const onChangeArticle = (index, data = {}) => {
    addArticle({ ...data }, index);
  };

  return (
    <div>
      <FormLayout
        title="Demande de reprise de déchets"
        backRoute={ROUTE_MY_WASTE_RECOVERY_REQUESTS}
        size={FormLayout.LARGE}
      >
        <form onSubmit={onSubmit}>
          <WasteFormBreadcrumb position={WasteFormBreadcrumb.ARTICLES} />

          <h3>{articles.length} article(s) à reprendre</h3>

          <WasteArticlesForm
            siteUuid={siteUuid}
            articlesData={articles}
            onChange={onChangeArticle}
            onAdd={onAddArticle}
            onDelete={removeArticle}
            errors={errors}
          />

          <StandaloneInvalidFeedback errors={globalErrors} />

          <button
            type="submit"
            className="btn btn-primary float-right"
            disabled={
              // At least 1 container or 1article is required
              !hasContainers && articles.length === 0
            }
          >
            Passer à l&apos;étape suivante
          </button>
        </form>
      </FormLayout>
    </div>
  );
}
