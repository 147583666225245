/* eslint react/no-unescaped-entities: 0 */
import React, { Component } from "react";

export default class PrivacyPolicy extends Component {
  /**
   * {@inheritdoc}
   */
  render() {
    return (
      <div className="container">
        <h1>Política de privacidad</h1>
        <h2>I - Información general</h2>
        <h3>1.1 ¿Para quién es esta Política?</h3>
        <p>
          Esta Política está dirigida a cualquier persona que visite el sitio y
          utilice la aplicación móvil Clim'app. Esta persona se denomina aquí
          "el Socio y/o el Agente".
        </p>
        <h3>
          1.2 ¿Quién es el proveedor de servicios y cómo ponerse en contacto con
          él?
        </h3>
        <p>
          La aplicación Clim'app que el Socio y/o el Agente utilizan y/o en
          general el servicio para el que se pretende aplicar esta Política (en
          adelante "Clim'app"), está publicada por: Dehon Service, con domicilio
          social en 4, rue de la Croix Faubin 75011 PARIS, IVA n° FR 62 310 259
          205 (en adelante "Nosotros").
        </p>
        <p>
          El Socio y/o el Agente pueden ponerse en contacto con el Proveedor de
          Servicios en la dirección postal indicada anteriormente. Para
          cualquier pregunta relacionada con esta Política, el Socio y/o Agente
          también puede escribir a la siguiente dirección de correo electrónico:
          info@climapp.dehon.com.
        </p>
        <h3>
          1.3 La importancia de proteger los datos del Socio y/o del
          Representante Personal en Clim'app
        </h3>
        <p>
          Clim'app está sujeto a la ley francesa. Esto implica el cumplimiento
          de la Ley Nº 78-17, de 6 de enero de 1978, relativa a la tecnología de
          la información, los archivos y las libertades. El texto de esta ley
          puede consultarse en línea en el sitio web de la Commission Nationale
          de l'Informatique et des Libertés (www.cnil.fr).
        </p>
        <p>
          El Proveedor de Servicios se preocupa por la privacidad del Socio y/o
          Agente cuando utiliza Clim'app y es consciente de la importancia que
          el Socio y/o Agente le concede. Por lo tanto, el Proveedor hace todo
          lo que está en su mano para respetar la privacidad del Socio y/o del
          Agente cuando utiliza Clim'app.
        </p>
        <p>
          El Proveedor de Servicios sólo recoge los datos personales que el
          Socio y/o el Agente decida facilitar voluntariamente.
        </p>
        <p>
          Al utilizar Clim'app, el Socio y/o Agente reconoce haber leído y
          aceptado las condiciones establecidas en esta Política y los objetivos
          y métodos de procesamiento que implica.
        </p>
        <h2>II - El controlador</h2>
        <p>
          El Proveedor es el responsable del tratamiento de los datos personales
          efectuado en el marco de Clim'app. Los datos de contacto del proveedor
          de servicios se especifican en el Artículo 1 de esta Política.
        </p>
        <h2>III - Datos tratados</h2>
        <p>
          En el marco de Clim'app, el Prestador de Servicios podrá tratar los
          siguientes datos personales relativos al Socio y/o al Agente:
        </p>
        <ul>
          <li>El nombre y apellidos del Socio y/o del Agente;</li>
          <li>
            La contraseña y el identificador del Socio y/o del Agente que
            permite el acceso a su Cuenta en Clim'app (la contraseña nunca se
            almacena de forma clara en los servidores del Proveedor de
            Servicios, de acuerdo con los requisitos de la Commission Nationale
            de l'Informatique et des Libertés);
          </li>
          <li>
            El número de teléfono, dirección de correo electrónico del Socio y/o
            Agente;
          </li>
          <li>
            El dominio y la dirección IP (detectados automáticamente) del Socio
            y/o Agente;
          </li>
          <li>
            Información sobre el uso de Clim'app (incluidas las páginas
            consultadas) por el Socio y/o el Agente;
          </li>
          <li>
            El historial de las comunicaciones escritas entre el Socio y/o el
            Agente y el Proveedor de Servicios;
          </li>
        </ul>
        <h2>
          IV-Generaly, cualquier información proporcionada voluntariamente, por
          ejemplo, al utilizar Clim'app por el Socio y/o el Agente. Las
          finalidades del tratamiento de estos datos
        </h2>
        <p>
          El Proveedor de Servicios procesa estos datos para los siguientes
          fines:
        </p>
        <ul>
          <li>
            Crear la cuenta del Socio y/o Agente y permitirle acceder a ella y
            gestionarla;
          </li>
          <li>
            Permitir al Socio y/o Agente utilizar todos los servicios ofrecidos
            en Clim'app;
          </li>
          <li>
            Contacte rápidamente y responda a las preguntas del Socio y/o del
            Agente;
          </li>
          <li>
            Detectar la ubicación del Socio y/o Agente para mostrar la
            información adecuada;
          </li>
          <li>
            Asegurar la administración técnica y la seguridad de Clim'app;
          </li>
          <li>
            Transmitir al Socio y/o al Agente, incluso por correo electrónico,
            información sobre los productos y servicios similares a Clim'app que
            proporciona el Proveedor de Servicios;
          </li>
          <li>
            Elaborar estadísticas, en particular sobre el uso de las diferentes
            secciones de Clim'app por el Socio y/o el Agente;
          </li>
          <li>
            Con sujeción al acuerdo del Socio y/o del Agente sobre esta opción,
            transmitir, incluso por correo electrónico, información sobre otros
            productos y servicios que ofrezca el Proveedor de Servicios o que
            ofrezcan terceros;
          </li>
          <li>
            Sujeto al acuerdo del Socio y/o del Agente sobre esta opción,
            identificar las áreas de interés de este último y personalizar la
            publicidad que puede ser mostrada en Clim'app;
          </li>
        </ul>
        <h2>V - Seguridad y confidencialidad</h2>
        <h3>5.1 Seguridad</h3>
        <p>
          El Proveedor de Servicios está muy atento a la seguridad de los datos
          del Socio y/o Agente.
        </p>
        <p>
          El Proveedor de Servicios utiliza procedimientos de seguridad y
          cifrado reconocidos para garantizar la seguridad de la transmisión de
          datos del Socio y/o Agente hacia y desde Clim'app.
        </p>
        <h3>5.2 Confidencialidad</h3>
        <p>
          El Proveedor de Servicios también está muy atento a la
          confidencialidad de los datos del Socio y/o Agente.
        </p>
        <p>
          Los datos del Socio y/o del Agente no serán transmitidos a terceros
          sin su consentimiento, con excepción de los subcontratistas del
          Proveedor de Servicios, que el Proveedor de Servicios selecciona con
          cuidado y rigor para la prestación de los servicios de Clim'app.
          Dichos subcontratistas actuarán únicamente siguiendo instrucciones y
          en nombre del Proveedor. Tomarán las medidas necesarias para
          garantizar la seguridad y confidencialidad en el tratamiento de los
          datos del Socio y/o Agente y cumplirán con esta Política.
        </p>
        <p>
          Sólo en el caso de que el Socio y/o el Agente hayan dado su
          consentimiento, el Proveedor de Servicios podrá transmitir
          determinados datos personales a los Socios con el fin de transmitir al
          Socio y/o al Agente, incluso por correo electrónico, información sobre
          otros productos y servicios ofrecidos por estos terceros, o a las
          agencias de publicidad con el fin de personalizar los mensajes
          publicitarios mostrados en Clim'app.
        </p>
        <p>
          El Proveedor también se reserva el derecho de revelar datos personales
          a petición de una autoridad administrativa o judicial o si el
          Proveedor cree de buena fe que dicha revelación es necesaria para
          cumplir con cualquier ley o regulación aplicable.
        </p>
        <h2>VI- Período de conservación</h2>
        <p>
          Los datos personales del Socio y/o del Agente que el Proveedor de
          Servicios procesa en el contexto de Clim'app se conservan durante el
          período estrictamente necesario para alcanzar los fines para los que
          fueron recogidos (de acuerdo con la lista de fines especificada en el
          Artículo 4 anterior).
        </p>
        <h2>VII-Derechos de los socios y/o agentes</h2>
        <p>
          De acuerdo con la Ley n° 78-17 del 6 de enero de 1978 sobre la
          informática, los archivos y las libertades, el Socio y/o el Agente
          tienen derecho a acceder a sus datos personales, a obtener una
          rectificación si parecen erróneos, inexactos o incompletos, o a
          oponerse a su tratamiento por motivos legítimos. El Socio y/o el
          Agente también tienen el derecho de oponerse al uso de sus datos para
          fines de prospección comercial, de forma gratuita y sin que se
          solicite ninguna justificación.
        </p>

        <p>
          El Socio y/o el Agente podrán dirigir sus solicitudes de acceso,
          rectificación u oposición, poniéndose en contacto con el Prestador de
          Servicios a través de los datos de contacto mencionados en el artículo
          1 del presente Reglamento.
        </p>
        <p>
          Puede obtenerse información adicional sobre la protección de la
          intimidad en el tratamiento informático en la Commission Nationale de
          l'Informatique et des Libertés.
        </p>
      </div>
    );
  }
}
