import {
  GET_USER_INFOS_IN_PROGRESS,
  GET_USER_INFOS_SUCCESS,
  GET_USER_INFOS_FAIL,
  POST_USER_IN_PROGRESS,
  POST_USER_SUCCESS,
  POST_USER_FAIL,
  PUT_USER_IN_PROGRESS,
  PUT_USER_SUCCESS,
  PUT_USER_FAIL,
} from "../constants";

const initialState = {
  infos: undefined,
  saving: false,
  saved: false,
  errors: null,
};

/**
 * User reducer
 *
 * @param {Object} state
 * @param {Object} action
 *
 * @return {Object}
 */
export default function user(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case GET_USER_INFOS_IN_PROGRESS:
      return {
        ...state,
        infos: undefined,
      };

    case GET_USER_INFOS_SUCCESS:
      return {
        ...state,
        infos: payload.infos,
      };

    case GET_USER_INFOS_FAIL:
      return {
        ...state,
        infos: null,
      };

    case POST_USER_IN_PROGRESS:
    case PUT_USER_IN_PROGRESS:
      return {
        ...state,
        saving: true,
        saved: false,
        errors: null,
      };

    case POST_USER_SUCCESS:
    case PUT_USER_SUCCESS:
      return {
        ...state,
        saving: false,
        saved: true,
      };

    case POST_USER_FAIL:
    case PUT_USER_FAIL:
      return {
        ...state,
        saving: false,
        saved: false,
        errors: payload.violations,
      };

    default:
      return state;
  }
}
