import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { fetch } from "./appContainer";
import { initTranslations } from "./translations";
import App from "./modules/common/wrappers/App";
import "../assets/styles/main.scss";

initTranslations();

ReactDOM.render(
  <Provider store={fetch("redux_store")}>
    <App />
  </Provider>,
  document.getElementById("root"),
);
