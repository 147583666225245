import Enum from "../../common/models/Enum";

class ImportFinalClientStrategy extends Enum {
  static IGNORE = "ignore";
  static FORCE_CREATE = "force_create";
  static ASK_PRESTATION = "ask_prestation";

  static values = [
    ImportFinalClientStrategy.IGNORE,
    ImportFinalClientStrategy.FORCE_CREATE,
    ImportFinalClientStrategy.ASK_PRESTATION,
  ];

  static readables = {
    [ImportFinalClientStrategy.IGNORE]: "import_final_client_strategy.ignore",
    [ImportFinalClientStrategy.FORCE_CREATE]:
      "import_final_client_strategy.force_create",
    [ImportFinalClientStrategy.ASK_PRESTATION]:
      "import_final_client_strategy.ask_prestation",
  };
}

export default ImportFinalClientStrategy;
