import I18n from "i18n-js";
import React, { Component } from "react";
import { node, string, instanceOf } from "prop-types";
import CompanyCountry from "../../models/CompanyCountry";

export default class ImportClientsContent extends Component {
  static propTypes = {
    strategyWidget: node.isRequired,
    downloadSample: node.isRequired,
    /**
     * The current user client name
     */
    client: string.isRequired,
    /**
     * The current user company country
     */
    companyCountry: instanceOf(CompanyCountry),
  };

  /**
   * {@inheritdoc}
   */
  render() {
    const Content = I18n.t("Components.ImportClientsContent");

    return <Content {...this.props} />;
  }
}
