/* eslint react/no-unescaped-entities: 0 */
import React, { Component } from "react";

export default class PrivacyPolicy extends Component {
  /**
   * {@inheritdoc}
   */
  render() {
    return (
      <div className="container">
        <h1>Privacy Policy</h1>
        <h2>I - General information</h2>
        <h3>1.1 Who is this Policy for?</h3>
        <p>
          This Policy is intended for anyone who visits the site and uses the
          Clim’app mobile application. This person is referred to here as "the
          Partner and/or the Agent".
        </p>
        <h3>1.2 Who is the Service Provider and how to contact him/her?</h3>
        <p>
          The Clim’app application that the Partner and/or the Agent uses and/or
          in general the service for the use of which this Policy is intended to
          apply (hereinafter "Clim’app"), is published by: Dehon Service, whose
          registered office is located at 4, rue de la Croix Faubin 75011 PARIS,
          VAT n° FR 62 310 259 205 (hereinafter "We").
        </p>
        <p>
          The Partner and/or the Agent may contact the Service Provider at the
          postal address indicated above. For any question relating to this
          Policy, the Partner and/or Agent may also write to the following email
          address: info@climapp.dehon.com.
        </p>
        <h3>
          1.3 The importance of protecting the data of the Partner and/or the
          Personal Representative on Clim’app
        </h3>
        <p>
          Clim’app is subject to French law. This implies compliance with Law
          No. 78-17 of 6 January 1978 on information technology, files and
          freedoms. The text of this law can be consulted online on the website
          of the Commission Nationale de l’Informatique et des Libertés
          (www.cnil.fr).
        </p>
        <p>
          The Service Provider is concerned about the privacy of the Partner
          and/or Agent when using Clim’app and is aware of the importance that
          the Partner and/or Agent attaches to it. The Supplier therefore does
          everything in its power to respect the privacy of the Partner and/or
          the Agent when using Clim’app.
        </p>
        <p>
          The Service Provider only collects personal data that the Partner
          and/or Agent chooses to voluntarily provide.
        </p>
        <p>
          By using Clim’app, the Partner and/or Agent acknowledges having read
          and accepted the conditions set out in this Policy and the objectives
          and processing methods it implies.
        </p>
        <h2>II - The controller</h2>
        <p>
          The Supplier is the controller of the processing of personal data
          carried out within the framework of Clim’app. The contact details of
          the service provider are specified in Article 1 of this Policy.
        </p>
        <h2>III - Data processed</h2>
        <p>
          In the context of Clim’app, the Service Provider may process the
          following personal data concerning the Partner and/or the Agent:
        </p>
        <ul>
          <li>The name and surname of the Partner and/or the Agent;</li>
          <li>
            The password and identifier of the Partner and/or the Agent allowing
            access to his Account on Clim’app (the password is never stored in
            clear on the Service Provider’s servers, in accordance with the
            requirements of the Commission Nationale de l’Informatique et des
            Libertés);
          </li>
          <li>
            The telephone number, e-mail address of the Partner and/or Agent;
          </li>
          <li>
            The domain and IP address (automatically detected) of the Partner
            and/or Agent;
          </li>
          <li>
            Information concerning the use of Clim’app (including the pages
            consulted) by the Partner and/or the Agent;
          </li>
          <li>
            The history of written communications between the Partner and/or the
            Agent and the Service Provider;
          </li>
        </ul>
        <h2>
          IV-Generaly, any information given voluntarily, for example when using
          Clim’app by the Partner and/or the Agent. The purposes of the
          processing of this data
        </h2>
        <p>
          The Service Provider processes this data for the following purposes:
        </p>
        <ul>
          <li>
            Create the Partner and/or Agent’s account and allow them to access
            and manage it;
          </li>
          <li>
            Allow the Partner and/or Agent to use all the services offered on
            Clim’app;
          </li>
          <li>
            Contact quickly and answer questions from the Partner and/or the
            Agent;
          </li>
          <li>
            Detect the location of the Partner and/or Agent in order to display
            the appropriate information;
          </li>
          <li>Ensure the technical administration and security of Clim’app;</li>
          <li>
            Transmit to the Partner and/or the Agent, including by e-mail,
            information on products and services similar to Clim’app that the
            Service Provider provides;
          </li>
          <li>
            To produce statistics, in particular on the use of the various
            Clim’app sections by the Partner and/or the Agent;
          </li>
          <li>
            Subject to the agreement of the Partner and/or the Agent on this
            option, transmit, including by e-mail, information on other products
            and services that the Service Provider offers or that third parties
            offer;
          </li>
          <li>
            Subject to the agreement of the Partner and/or the Agent on this
            option, identify the latter’s areas of interest and personalize the
            advertising that may be displayed on Clim’app;
          </li>
        </ul>
        <h2>V - Security and confidentiality</h2>
        <h3>5.1 Security</h3>
        <p>
          The Service Provider is very attentive to the security of the
          Partner’s and/or Agent’s data.
        </p>
        <p>
          The Service Provider uses recognised security and encryption
          procedures to ensure the security of the Partner and/or Agent’s data
          transmission to and from Clim’app.
        </p>
        <h3>5.2 Confidentiality</h3>
        <p>
          The Service Provider is also very attentive to the confidentiality of
          the Partner and/or Agent’s data.
        </p>
        <p>
          The Partner’s and/or the Agent’s data will not be transmitted to any
          third party without its consent, with the exception of the Service
          Provider’s subcontractors, which the Service Provider selects with
          care and rigour for the performance of Clim’app’s services. Such
          subcontractors shall act only on instructions and on behalf of the
          Supplier. They will take the necessary measures to ensure security and
          confidentiality when processing the Partner and/or Agent’s data and
          will comply with this Policy.
        </p>
        <p>
          Only if the Partner and/or the Agent has given his consent, the
          Service Provider may also transmit certain personal data to partners
          in order to transmit to the Partner and/or the Agent, including by
          e-mail, information on other products and services offered by these
          third parties, or to advertising agencies in order to personalise the
          advertising messages displayed on Clim’app.
        </p>
        <p>
          The Supplier also reserves the right to disclose personal data at the
          request of an administrative or judicial authority or if the Supplier
          believes in good faith that such disclosure is required to comply with
          any applicable law or regulation.
        </p>
        <h2>VI- Conservation period</h2>
        <p>
          The personal data of the Partner and/or the Agent that the Service
          Provider processes in the context of Clim’app are kept for the period
          strictly necessary to achieve the purpose(s) for which they were
          collected (according to the list of purposes specified in Article 4
          above).
        </p>
        <h2>VII-Partner and/or Agent’s rights</h2>
        <p>
          In accordance with Law No. 78-17 of 6 January 1978 on data processing,
          files and liberties, the Partner and/or the Agent have the right to
          access their personal data, the right to obtain rectification if they
          appear erroneous, inaccurate or incomplete, or to oppose their
          processing for legitimate reasons. The Partner and/or the Agent also
          has the right to object to the use of its data for commercial
          prospecting purposes, free of charge and without any justification
          being requested.
        </p>
        <p>
          The Partner and/or the Agent may address its requests for access,
          rectification or opposition, by contacting the Service Provider at the
          contact details mentioned in Article 1 hereof.
        </p>
        <p>
          Additional information concerning the protection of privacy in
          computerised processing may be obtained from the Commission Nationale
          de l’Informatique et des Libertés.
        </p>
      </div>
    );
  }
}
