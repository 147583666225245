import { func, string } from "prop-types";
import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import listSites from "../graphql/queries/listSites.graphql";
import wasteEligibleContainers from "../graphql/queries/wasteEligibleContainers.graphql";
import Select from "../../common/components/form/Select";
import { alphabetically, uniq } from "../../common/utils/filterUtils";
import InvalidFeedback from "../../common/components/form/InvalidFeedback";
import StatelessCheckbox from "../../common/components/form/StatelessCheckbox";

SelectWasteSite.propTypes = {
  value: string, // selected value
  label: string.isRequired,
  id: string.isRequired,
  onChangedValue: func.isRequired,
  errors: InvalidFeedback.propTypes.errors,
};

export default function SelectWasteSite({ value, errors, ...props }) {
  const [withContainers, setWithContainers] = useState(false);

  const query = withContainers ? wasteEligibleContainers : listSites;

  const { loading, error, data } = useQuery(query);
  let options;

  if (withContainers) {
    options = uniq(
      (data?.wasteEligibleContainers || []).reduce(
        (sites, { site }) => [
          ...sites,
          {
            uuid: site.uuid,
            label: `${site?.address.city} | ${site.designation}`,
            group: site.client.legalCompanyName,
          },
        ],
        [],
      ),
      "uuid",
    ).sort((a, b) => alphabetically(a.label, b.label));
  } else {
    options = (data?.listSites || [])
      .map((site) => ({
        uuid: site.uuid,
        label: `${site?.address.city} | ${site.designation}`,
        group: site.client.legalCompanyName,
      }))
      .sort((a, b) => alphabetically(a.label, b.label));
  }

  return (
    <>
      <Select
        loading={loading}
        loadingError={Boolean(error)}
        options={options}
        renderOption={(option) => (
          <option key={`option-${option.uuid}`} value={option.uuid}>
            {option.label}
          </option>
        )}
        optionGroup={(option) => option.group}
        defaultValue={value}
        error={
          !loading &&
          ((options.length === 0 && "Aucun site éligible") || errors)
        }
        {...props}
      />
      <StatelessCheckbox
        id="charteredByClient"
        label="Filtrer les sites avec contenants éligibles seulement"
        checked={withContainers}
        onChangedValue={setWithContainers}
        className="mb-4"
      />
    </>
  );
}
