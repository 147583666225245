import {
  SERVICE_BENEFICIARY_FOUND,
  SERVICE_BENEFICIARY_NOT_FOUND,
  POST_SERVICE_BENEFICIARY_CONTRACT_IN_PROGRESS,
  POST_SERVICE_BENEFICIARY_CONTRACT_SUCCESS,
  POST_SERVICE_BENEFICIARY_CONTRACT_FAILURE,
} from "../constants";

const initialState = {
  postContractInProgress: false,
  postContractSuccess: false,
  infos: null,
};

/**
 * Providers reducer
 *
 * @param {Object} state
 * @param {Object} action
 *
 * @return {Object}
 */
export default function beneficiary(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case SERVICE_BENEFICIARY_FOUND:
      return {
        ...state,
        infos: payload.infos,
        postContractSuccess: false,
      };

    case SERVICE_BENEFICIARY_NOT_FOUND:
      return {
        ...state,
        infos: null,
        postContractSuccess: false,
      };

    case POST_SERVICE_BENEFICIARY_CONTRACT_IN_PROGRESS:
      return {
        ...state,
        postContractInProgress: true,
        postContractSuccess: false,
      };

    case POST_SERVICE_BENEFICIARY_CONTRACT_SUCCESS:
      return {
        ...state,
        postContractInProgress: false,
        postContractSuccess: true,
      };
    case POST_SERVICE_BENEFICIARY_CONTRACT_FAILURE:
      return {
        ...state,
        postContractInProgress: false,
        postContractSuccess: false,
      };

    default:
      return state;
  }
}
