import {
  POST_CLIENT_IN_PROGRESS,
  POST_CLIENT_SUCCESS,
  POST_CLIENT_FAIL,
  PUT_CLIENT_IN_PROGRESS,
  PUT_CLIENT_SUCCESS,
  PUT_CLIENT_FAIL,
  GET_CLIENT_INFO_FAIL,
  GET_CLIENT_INFO_IN_PROGRESS,
  GET_CLIENT_INFO_SUCCESS,
} from "../constants";

const initialState = {
  saving: false,
  saved: false,
  errors: null,
  info: undefined,
};

/**
 * User reducer
 *
 * @param {Object} state
 * @param {Object} action
 *
 * @return {Object}
 */
export default function client(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case GET_CLIENT_INFO_IN_PROGRESS:
      return {
        ...state,
        info: undefined,
      };

    case GET_CLIENT_INFO_SUCCESS:
      return {
        ...state,
        info: payload.info,
      };

    case GET_CLIENT_INFO_FAIL:
      return {
        ...state,
        info: null,
      };

    case POST_CLIENT_IN_PROGRESS:
    case PUT_CLIENT_IN_PROGRESS:
      return {
        ...state,
        saving: true,
        saved: false,
        errors: null,
      };

    case POST_CLIENT_SUCCESS:
    case PUT_CLIENT_SUCCESS:
      return {
        ...state,
        saving: false,
        saved: true,
      };

    case POST_CLIENT_FAIL:
    case PUT_CLIENT_FAIL:
      return {
        ...state,
        saving: false,
        saved: false,
        errors: payload.violations,
      };

    default:
      return state;
  }
}
