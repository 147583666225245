import I18n from "i18n-js";
import { arrayOf, bool, func, shape } from "prop-types";
import React, { useEffect, useReducer, useRef } from "react";
import { connect } from "react-redux";
import DateInput from "../../common/components/form/DateInput";
import { FormUtils } from "../../common/components/form/Form";
import Select from "../../common/components/form/Select";
import SubmitButton from "../../common/components/form/SubmitButton";
import { alphabetically } from "../../common/utils/filterUtils";
import { getClientsList } from "../../company/actions/clientsActions";
import { getSitesList } from "../../company/actions/sitesActions";
import ClientUtils from "../../company/utils/ClientUtils";
import { getInstallationsList } from "../../installation/actions/installationsActions";
import { getUsersList } from "../../users/actions/usersActions";
import UserUtils from "../../users/utils/UserUtils";
import InterventionType from "../models/InterventionType";
import Purpose from "../models/Purpose";
import Roles from "../../users/models/Roles";

function getSitesFromClient(sitesList, clientUuid) {
  return sitesList.filter((site) => site.client.uuid === clientUuid);
}

function getInstallationsFromSite(installationsList, siteUuid) {
  return installationsList.filter(
    (installation) => installation.site.uuid === siteUuid,
  );
}

function getTechniciansFromUsers(usersList) {
  return usersList.filter((user) =>
    user.roles.find((v) => v === Roles.TECHNICIAN),
  );
}

const SET_SITES_LIST = "SET_SITES_LIST";
const SET_CLIENTS_LIST = "SET_CLIENTS_LIST";
const SET_INSTALLATIONS_LIST = "SET_INSTALLATIONS_LIST";
const SET_USERS_LIST = "SET_USERS_LIST";
const SET_CLIENT = "SET_CLIENT";
const SET_SITE = "SET_SITE";
const SET_INSTALLATION = "SET_INSTALLATION";
const SET_INTERVENTION_TYPE = "SET_INTERVENTION_TYPE";
const SET_INTERVENTION_PURPOSE = "SET_INTERVENTION_PURPOSE";
const SET_TECHNICIAN = "SET_TECHNICIAN";
const SET_DATE = "SET_DATE";

const SET_DEFAULT_INTERVENTION = "SET_DEFAULT_INTERVENTION";

function useInterventionFormFetchData(
  getSitesList,
  getClientsList,
  getInstallationsList,
  getUsersList,
) {
  useEffect(() => {
    getSitesList();
  }, [getSitesList]);

  useEffect(() => {
    getClientsList();
  }, [getClientsList]);

  useEffect(() => {
    getInstallationsList();
  }, [getInstallationsList]);

  useEffect(() => {
    getUsersList();
  }, [getUsersList]);
}

function reducer(state, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_SITES_LIST:
      return {
        ...state,
        sitesList: payload,
        siteOptions: getSitesFromClient(payload, state.client),
      };
    case SET_CLIENTS_LIST:
      return { ...state, clientsList: payload, clientsOptions: payload };
    case SET_INSTALLATIONS_LIST:
      return {
        ...state,
        installationsList: payload,
        installationOptions: getInstallationsFromSite(payload, state.site),
      };
    case SET_USERS_LIST:
      return {
        ...state,
        usersList: payload,
        userOptions: getTechniciansFromUsers(payload),
      };
    case SET_CLIENT:
      return {
        ...state,
        siteOptions: getSitesFromClient(state.sitesList, payload),
        userOptions: getTechniciansFromUsers(state.usersList),
        client: payload,
        site: undefined,
        installation: undefined,
        user: undefined,
      };
    case SET_SITE: {
      const installationOptions = getInstallationsFromSite(
        state.installationsList,
        payload,
      );
      const installation =
        installationOptions.length === 1
          ? installationOptions[0]?.uuid
          : undefined;
      return {
        ...state,
        installationOptions,
        site: payload,
        installation,
      };
    }
    case SET_INSTALLATION:
      return {
        ...state,
        installation: payload,
      };
    case SET_INTERVENTION_TYPE:
      return {
        ...state,
        interventionType: payload,
        ...(!Purpose.isValidPurpose(state.interventionPurpose, payload) && {
          interventionPurpose: undefined,
        }),
      };
    case SET_INTERVENTION_PURPOSE:
      return {
        ...state,
        interventionPurpose: payload,
      };
    case SET_TECHNICIAN:
      return {
        ...state,
        technician: payload,
      };
    case SET_DATE:
      return {
        ...state,
        date: payload,
      };
    case SET_DEFAULT_INTERVENTION:
      return {
        ...state,
        ...payload,
        siteOptions: getSitesFromClient(state.sitesList, payload.client),
        userOptions: getTechniciansFromUsers(state.usersList),
        installationOptions: getInstallationsFromSite(
          state.installationsList,
          payload.site,
        ),
      };
    default:
      return state;
  }
}

const useInterventionFormState = (
  sitesList,
  getSitesList,
  clientsList,
  getClientsList,
  installationsList,
  getInstallationsList,
  usersList,
  getUsersList,
  info,
) => {
  const [state, dispatch] = useReducer(reducer, {});

  useInterventionFormFetchData(
    getSitesList,
    getClientsList,
    getInstallationsList,
    getUsersList,
  );

  useEffect(() => {
    dispatch({ type: SET_SITES_LIST, payload: sitesList });
  }, [sitesList]);

  useEffect(() => {
    dispatch({ type: SET_CLIENTS_LIST, payload: clientsList });
  }, [clientsList]);

  useEffect(() => {
    dispatch({ type: SET_INSTALLATIONS_LIST, payload: installationsList });
  }, [installationsList]);

  useEffect(() => {
    dispatch({ type: SET_USERS_LIST, payload: usersList });
  }, [usersList]);

  useEffect(() => {
    if (info) {
      const payload = {
        client: info.installation.site.client.uuid,
        site: info.installation.site.uuid,
        installation: info.installation.uuid,
        interventionType: info.type,
        interventionPurpose: info.purpose,
        technician: info.user.uuid,
        date: new Date(info.plannedAt),
      };
      dispatch({ type: SET_DEFAULT_INTERVENTION, payload });
    }
  }, [info]);

  return [state, dispatch];
};

InterventionForm.propTypes = {
  clients: shape().isRequired,
  clientsList: arrayOf(shape).isRequired,
  getClientsList: func.isRequired,
  sites: shape().isRequired,
  sitesList: arrayOf(shape).isRequired,
  getSitesList: func.isRequired,
  installations: shape().isRequired,
  installationsList: arrayOf(shape).isRequired,
  getInstallationsList: func.isRequired,
  users: shape(),
  usersList: arrayOf(shape),
  getUsersList: func.isRequired,
  info: shape(),
  loading: bool,
  onSubmit: func.isRequired,
  goBack: func.isRequired,
  interventions: shape().isRequired,
};

function InterventionForm({
  clients,
  clientsList,
  getClientsList,
  sites,
  sitesList,
  getSitesList,
  installations,
  installationsList,
  getInstallationsList,
  users,
  usersList,
  getUsersList,
  onSubmit: handleSubmit,
  info,
  loading,
}) {
  const [state, dispatch] = useInterventionFormState(
    sitesList,
    getSitesList,
    clientsList,
    getClientsList,
    installationsList,
    getInstallationsList,
    usersList,
    getUsersList,
    info,
  );

  const clientRef = useRef();
  const siteRef = useRef();
  const installationRef = useRef();
  const interventionTypeRef = useRef();
  const interventionPurposeRef = useRef();
  const techRef = useRef();
  const dateRef = useRef();

  const isFormValid = () => {
    return FormUtils.validate({
      client: clientRef,
      site: siteRef,
      installation: installationRef,
      interventionType: interventionTypeRef,
      interventionPurpose: interventionPurposeRef,
      tech: techRef,
      date: dateRef,
    });
  };

  const onSubmit = () => {
    if (!isFormValid()) {
      return;
    }

    const intervention = {
      purpose: state.interventionPurpose,
      type: state.interventionType,
      installation: state.installation,
      plannedAt: state.date?.toISOString(),
      user: state.technician,
    };

    handleSubmit(intervention);
  };

  if (
    (clients.inProgress && !clientsList) ||
    (sites.inProgress && !sitesList) ||
    (installations.inProgress && !installationsList) ||
    (users.inProgress && !usersList)
  ) {
    return <div className="loader" />;
  }

  return (
    <div>
      <form>
        <Select
          id="client"
          ref={clientRef}
          onChange={(event) =>
            dispatch({ type: SET_CLIENT, payload: event.target.value })
          }
          label={I18n.t("pages.plannedInterventions.create.form.client")}
          renderOption={(option) => (
            <option key={option.uuid} value={option.uuid}>
              {ClientUtils.renderLabel(option)}
            </option>
          )}
          defaultValue={state.client}
          options={clientsList}
          placeholder={I18n.t(
            "pages.plannedInterventions.create.form.client:placeholder",
          )}
        />
        <Select
          id="site"
          ref={siteRef}
          onChange={(event) =>
            dispatch({ type: SET_SITE, payload: event.target.value })
          }
          label={I18n.t("pages.plannedInterventions.create.form.site")}
          renderOption={(option) => (
            <option key={option.uuid} value={option.uuid}>
              {option.designation}
            </option>
          )}
          defaultValue={state.site}
          options={state.siteOptions}
          placeholder={I18n.t(
            "pages.plannedInterventions.create.form.site:placeholder",
          )}
          disabled={!state.client}
        />
        <Select
          id="installation"
          ref={installationRef}
          onChange={(event) =>
            dispatch({ type: SET_INSTALLATION, payload: event.target.value })
          }
          label={I18n.t("pages.plannedInterventions.create.form.installation")}
          renderOption={(option) => (
            <option key={option.uuid} value={option.uuid}>
              {option.designation}
            </option>
          )}
          defaultValue={state.installation}
          options={state.installationOptions}
          placeholder={I18n.t(
            "pages.plannedInterventions.create.form.installation:placeholder",
          )}
          disabled={!state.site}
        />
        <Select
          id="interventionType"
          ref={interventionTypeRef}
          onChange={(event) =>
            dispatch({
              type: SET_INTERVENTION_TYPE,
              payload: event.target.value,
            })
          }
          defaultValue={state.interventionType}
          label={I18n.t(
            "pages.plannedInterventions.create.form.intervention:type",
          )}
          renderOption={(option) => (
            <option key={option} value={option}>
              {I18n.t(InterventionType.readableFor(option))}
            </option>
          )}
          options={InterventionType.values}
          disabled={!state.installation}
          placeholder={I18n.t(
            "pages.plannedInterventions.create.form.intervention:type:placeholder",
          )}
        />
        <Select
          id="interventionPurpose"
          ref={interventionPurposeRef}
          onChange={(event) =>
            dispatch({
              type: SET_INTERVENTION_PURPOSE,
              payload: event.target.value,
            })
          }
          defaultValue={state.interventionPurpose}
          label={I18n.t(
            "pages.plannedInterventions.create.form.intervention:purpose",
          )}
          renderOption={(option) => (
            <option key={option} value={option}>
              {I18n.t(Purpose.readableFor(option))}
            </option>
          )}
          options={
            Purpose.PURPOSES_PER_INTERVENTION_TYPE[state.interventionType]
          }
          placeholder={I18n.t(
            "pages.plannedInterventions.create.form.intervention:purpose:placeholder",
          )}
          disabled={!state.interventionType}
          optional
        />
        <Select
          id="tech"
          ref={techRef}
          onChange={(event) =>
            dispatch({ type: SET_TECHNICIAN, payload: event.target.value })
          }
          defaultValue={state.technician}
          label={I18n.t("pages.plannedInterventions.create.form.technician")}
          renderOption={(option) => (
            <option key={option.uuid} value={option.uuid}>
              {UserUtils.renderLabel(option)}
            </option>
          )}
          options={state.userOptions}
          placeholder={I18n.t(
            "pages.plannedInterventions.create.form.technician:placeholder",
          )}
          disabled={!state.interventionType}
        />
        <DateInput
          id="date"
          ref={dateRef}
          defaultValue={state.date}
          label={I18n.t("pages.plannedInterventions.create.form.plannedDate")}
          minDate={new Date()}
          onChange={(newDate) => dispatch({ type: SET_DATE, payload: newDate })}
          disabled={!state.interventionType}
        />
        <SubmitButton loading={loading} onClick={onSubmit} />
      </form>
    </div>
  );
}

const mapStateToProps = (state) => ({
  sites: state.sites,
  sitesList: state.sites.list
    .filter((site) => !site.mobile)
    .sort((a, b) => alphabetically(a.designation, b.designation)),
  clients: state.clients,
  clientsList: state.clients.list.sort((a, b) =>
    alphabetically(a.legalCompanyName, b.legalCompanyName),
  ),
  installations: state.installations,
  installationsList: state.installations.list.sort((a, b) =>
    alphabetically(a.designation, b.designation),
  ),
  users: state.users,
  usersList: state.users.list,
  interventions: state.plannedInterventions,
});

const mapDispatchToProps = (dispatch) => ({
  getSitesList: () => dispatch(getSitesList()),
  getClientsList: () => dispatch(getClientsList()),
  getInstallationsList: () => dispatch(getInstallationsList()),
  getUsersList: () => dispatch(getUsersList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(InterventionForm);
