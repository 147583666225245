import I18n from "i18n-js";
import React from "react";
import { func, shape } from "prop-types";
import { connect } from "react-redux";
import { ROUTE_MOBILE_SITES } from "../../../routes";
import FormPage from "../../common/components/layout/FormPage";
import SiteForm from "../components/SiteForm";
import { createSite } from "../actions/sitesActions";

class CreateMobileSite extends FormPage {
  static propTypes = {
    createSite: func.isRequired,
    match: shape().isRequired,
    site: shape().isRequired,
  };

  constructor() {
    super();

    this.onSubmit = this.onSubmit.bind(this);
  }

  /**
   * @param {object} site
   */
  onSubmit(site) {
    const { createSite, match } = this.props;
    const paramsSite = {
      clientUuid: match.params.uuid,
      ...site,
    };

    createSite(paramsSite);
  }

  /**
   * {@inheritdoc}
   */
  getTitle() {
    return I18n.t("pages.mobile_sites.create.title");
  }

  /**
   * {@inheritdoc}
   */
  getBackRoute() {
    return ROUTE_MOBILE_SITES;
  }

  /**
   * {@inheritdoc}
   */
  renderContent() {
    const { site } = this.props;

    return <SiteForm loading={site.saving} onSubmit={this.onSubmit} mobile />;
  }
}

const mapStateToProps = (state) => ({
  site: state.site,
});

const mapDispatchToProps = (dispatch) => ({
  createSite: (params) => dispatch(createSite(params, true)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateMobileSite);
