import React from "react";
import { useQuery } from "@apollo/client";
import LoadingWrapper from "../../common/components/elements/LoadingWrapper";
import bsddFromShippingIn from "../graphql/queries/bsddFromShippingIn.graphql";
import BsddList from "./BsddList";
import PropTypes from "prop-types";

BsddShippingReceiptInformation.propTypes = {
  shippingUuid: PropTypes.string.isRequired,
};

export default function BsddShippingReceiptInformation({ shippingUuid }) {
  const { loading, error, data } = useQuery(bsddFromShippingIn, {
    variables: {
      uuid: shippingUuid,
    },
  });

  return (
    <LoadingWrapper loading={loading} error={Boolean(error)}>
      {() => {
        const bsdds = data.bsddFromShippingIn ?? [];

        return <BsddList bsdds={bsdds} />;
      }}
    </LoadingWrapper>
  );
}
