import I18n from "i18n-js";
import React, { createRef } from "react";
import { func, shape, bool } from "prop-types";
import { connect } from "react-redux";
import Form from "../../common/components/form/Form";
import TextInput from "../../common/components/form/TextInput";
import Select from "../../common/components/form/Select";
import SubmitButton from "../../common/components/form/SubmitButton";
import ClientTypes from "../../company/models/ClientTypes";
import { alphabetically } from "../../common/utils/filterUtils";
import DateInput from "../../common/components/form/DateInput";
import ClientUtils from "../../company/utils/ClientUtils";
import CompanyCountry from "../../company/models/CompanyCountry";
import { getClientsList } from "../../company/actions/clientsActions";

class CarrierForm extends Form {
  static propTypes = {
    clients: shape().isRequired,
    getClientsList: func.isRequired,
    userProfile: shape().isRequired,
    carrier: shape().isRequired,
    goBack: func.isRequired,
    infos: shape(),
    loading: bool,
    onSubmit: func.isRequired,
  };

  static defaultProps = {
    infos: {
      address: {},
      transportAuthorization: {},
    },
    loading: false,
  };

  constructor(props) {
    super(props);

    // Define all inputs form
    this.inputs.client = createRef();
    this.inputs.legalCompanyName = createRef();
    this.inputs.siret = createRef();
    this.inputs.vatCode = createRef();
    this.inputs.expirationDate = createRef();
    this.inputs.receiptNumber = createRef();
    this.inputs.prefectureNumber = createRef();
    this.inputs.street = createRef();
    this.inputs.addressAddition = createRef();
    this.inputs.city = createRef();
    this.inputs.country = createRef();
    this.inputs.postal = createRef();

    this.updateMod = !!props.infos.uuid;

    const { transportAuthorization } = props.infos;

    this.expirationDate =
      transportAuthorization.expirationDate &&
      new Date(transportAuthorization.expirationDate);

    this.onSubmit = this.onSubmit.bind(this);
    this.renderClientOption = this.renderClientOption.bind(this);
  }

  /**
   * {@inheritdoc}
   */
  componentDidMount() {
    this.props.getClientsList();
  }

  /**
   * {@inheritdoc}
   */
  componentDidUpdate(prevProps) {
    const { carrier, goBack } = this.props;

    if (
      prevProps.carrier.saved === carrier.saved &&
      prevProps.carrier.errors === carrier.errors
    ) {
      return;
    }

    if (carrier.errors) {
      this.setState({ errors: carrier.errors });
    }

    if (carrier.saved) {
      goBack();
    }
  }

  /**
   * onSubmit form
   */
  onSubmit() {
    if (!this.isFormValid()) {
      return;
    }

    const carrier = {
      legalCompanyName: this.getInputValue("legalCompanyName"),
      siret: this.getInputValue("siret"),
      vatCode: this.getInputValue("vatCode"),
      address: {
        country: this.getInputValue("country"),
        city: this.getInputValue("city"),
        postal: this.getInputValue("postal"),
        street: this.getInputValue("street"),
        addressAddition: this.getInputValue("addressAddition"),
      },
      transportAuthorization: {
        receiptNumber: this.getInputValue("receiptNumber"),
        expirationDate: (
          this.getInputValue("expirationDate") || this.expirationDate
        ).toISOString(),
        prefectureNumber: this.getInputValue("prefectureNumber"),
      },
    };

    if (!this.updateMod) {
      carrier.clientUuid = this.getInputValue("client");
    }

    this.props.onSubmit(carrier);
  }

  /**
   * @param {Object} option
   *
   * @return {Element}
   */
  renderClientOption(option) {
    return (
      <option key={`option-${option.uuid}`} value={option.uuid}>
        {ClientUtils.renderLabel(option)}
      </option>
    );
  }

  /**
   * Render clients select
   *
   * @return {Element}
   */
  renderClientsSelect() {
    const { clients, infos } = this.props;
    let options = null;

    if (clients.list.length) {
      options = clients.list
        .filter((client) => new ClientTypes(client.type).acceptsCarriers())
        .sort((a, b) =>
          alphabetically(
            ClientUtils.renderLabel(a),
            ClientUtils.renderLabel(b),
          ),
        );
    }

    return (
      <Select
        id="client"
        label={I18n.t("pages.carriers.create.form.client:label")}
        ref={this.inputs.client}
        options={options}
        renderOption={this.renderClientOption}
        disabled={this.updateMod}
        defaultValue={(this.updateMod && infos.clientUuid) || ""}
        error={this.getInputErrors("client")}
        loadingError={Boolean(clients.error)}
        loading={clients.inProgress}
      />
    );
  }

  /**
   * {@inheritdoc}
   */
  render() {
    const { loading, infos, userProfile } = this.props;
    const companyCountry = new CompanyCountry(userProfile.companyCountry);

    return (
      <form>
        {this.renderClientsSelect()}
        <TextInput
          id="legalCompanyName"
          label={I18n.t("pages.carriers.create.form.legalCompanyName:label")}
          defaultValue={infos.legalCompanyName}
          ref={this.inputs.legalCompanyName}
          error={this.getInputErrors("legalCompanyName")}
        />
        {companyCountry.needsSiret() && (
          <TextInput
            id="siret"
            type="siret"
            label={I18n.t("pages.carriers.create.form.siret:label")}
            defaultValue={infos.siret}
            ref={this.inputs.siret}
            error={this.getInputErrors("siret")}
          />
        )}
        {companyCountry.canUseVatCode() && (
          <TextInput
            id="vatCode"
            label={I18n.t("pages.carriers.create.form.vatCode:label")}
            defaultValue={infos.vatCode}
            ref={this.inputs.vatCode}
            error={this.getInputErrors("vatCode")}
            optional={!companyCountry.needsVatCode()}
          />
        )}
        <TextInput
          id="receiptNumber"
          label={I18n.t("pages.carriers.create.form.receiptNumber:label")}
          defaultValue={infos.transportAuthorization.receiptNumber}
          ref={this.inputs.receiptNumber}
          error={this.getInputErrors("receiptNumber")}
        />
        <DateInput
          id="expirationDate"
          label={I18n.t("pages.carriers.create.form.expirationDate:label")}
          defaultValue={this.expirationDate}
          ref={this.inputs.expirationDate}
          error={this.getInputErrors("expirationDate")}
        />
        {companyCountry.carriersNeedsTransportAuthorizationPrefectureNumber() && (
          <TextInput
            id="prefectureNumber"
            label={I18n.t("pages.carriers.create.form.prefectureNumber:label")}
            defaultValue={infos.transportAuthorization.prefectureNumber}
            ref={this.inputs.prefectureNumber}
            error={this.getInputErrors("prefectureNumber")}
          />
        )}
        <TextInput
          id="street"
          label={I18n.t("pages.carriers.create.form.street:label")}
          defaultValue={infos.address.street}
          ref={this.inputs.street}
          error={this.getInputErrors("street")}
        />
        <TextInput
          id="addressAddition"
          label={I18n.t("pages.carriers.create.form.addressAddition:label")}
          defaultValue={infos.address.addressAddition}
          ref={this.inputs.addressAddition}
          optional
        />
        <TextInput
          id="city"
          label={I18n.t("pages.carriers.create.form.city:label")}
          defaultValue={infos.address.city}
          ref={this.inputs.city}
          error={this.getInputErrors("city")}
        />
        <TextInput
          id="country"
          label={I18n.t("pages.carriers.create.form.country:label")}
          defaultValue={infos.address.country}
          ref={this.inputs.country}
          error={this.getInputErrors("country")}
        />
        <TextInput
          id="postal"
          label={I18n.t("pages.carriers.create.form.postal:label")}
          defaultValue={infos.address.postal}
          ref={this.inputs.postal}
          error={this.getInputErrors("postal")}
        />
        <SubmitButton loading={loading} onClick={this.onSubmit} />
      </form>
    );
  }
}

export default connect(
  (state) => ({
    clients: state.clients,
    carrier: state.carrier,
    userProfile: state.auth.userProfile,
  }),
  (dispatch) => ({
    getClientsList: () => dispatch(getClientsList()),
  }),
)(CarrierForm);
