import React, { Component } from "react";
import { func, string } from "prop-types";
import { connect } from "react-redux";
import Dropdown from "../elements/Dropdown";
import { changeLanguage } from "../../../authentication/actions/authActions";
import Lang from "../../models/Lang";

class SelectLanguage extends Component {
  static propTypes = {
    changeLanguage: func.isRequired,
    lang: string.isRequired,
    tagName: string,
  };

  static defaultProps = {
    tagName: "li",
  };

  /**
   * @param {Event}  event
   * @param {String} lang
   */
  changeLanguage(event, lang) {
    event.preventDefault();

    this.props.changeLanguage(lang);
  }

  /**
   * {@inheritdoc}
   */
  render() {
    const { lang, tagName } = this.props;

    return (
      <Dropdown
        className="nav-item dropdown-language"
        tagName={tagName}
        label={lang.toUpperCase()}
      >
        {Lang.selectables.map((lang) => (
          <button
            key={lang}
            type="button"
            onClick={(event) => this.changeLanguage(event, lang)}
          >
            {Lang.readableFor(lang)}
          </button>
        ))}
      </Dropdown>
    );
  }
}

const mapStateToProps = (state) => ({
  lang: state.auth.lang,
});

const mapDispatchToProps = (dispatch) => ({
  changeLanguage: (lang) => dispatch(changeLanguage(lang)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectLanguage);
