import I18n from "i18n-js";
import React from "react";
import { func, shape } from "prop-types";
import { connect } from "react-redux";
import { ROUTE_USERS } from "../../../routes";
import FormPage from "../../common/components/layout/FormPage";
import CreateUserForm from "../components/CreateUserForm";
import { createUser } from "../actions/usersActions";

class CreateUser extends FormPage {
  static propTypes = {
    createUser: func.isRequired,
    match: shape().isRequired,
    user: shape().isRequired,
  };

  constructor() {
    super();

    this.onSubmit = this.onSubmit.bind(this);
  }

  /**
   * @param {object} user
   */
  onSubmit(user) {
    const { createUser, match } = this.props;
    const paramsUser = {
      clientUuid: match.params.uuid,
      ...user,
    };

    createUser(paramsUser);
  }

  /**
   * {@inheritdoc}
   */
  getTitle() {
    return I18n.t("pages.users.create.title");
  }

  /**
   * {@inheritdoc}
   */
  getBackRoute() {
    return ROUTE_USERS;
  }

  /**
   * {@inheritdoc}
   */
  renderContent() {
    const { user } = this.props;

    return <CreateUserForm loading={user.saving} onSubmit={this.onSubmit} />;
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  createUser: (params) => dispatch(createUser(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateUser);
