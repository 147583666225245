import I18n from "i18n-js";
import React, { Children, useMemo } from "react";
import ReactIs from "react-is";
import {
  arrayOf,
  bool,
  element,
  func,
  node,
  number,
  oneOfType,
  shape,
  string,
} from "prop-types";
import classnames from "classnames";

StatelessTable.propTypes = {
  actions: oneOfType([element, arrayOf(element)]),
  data: arrayOf(shape).isRequired,
  headData: arrayOf(oneOfType([string, element])),
  columnStyle: arrayOf(string),
  pagination: bool,
  currentPage: number,
  onPageChanged: func,
  paginationAside: node,
  rowData: func.isRequired,
  filters: oneOfType([element, arrayOf(element)]),
  emptyContent: string,
  isActiveRow: func,
  className: string,
  perPage: number,
  tableClasses: string,
};

StatelessTable.defaultProps = {
  actions: null,
  headData: [],
  columnStyle: [],
  pagination: false,
  currentPage: 1,
  paginationAside: null,
  onPageChanged: null,
  filters: null,
  emptyContent: null,
  isActiveRow: () => true,
  className: "col-12",
  perPage: 50,
  tableClasses: null,
};

export default function StatelessTable({
  actions,
  data,
  headData,
  columnStyle,
  pagination,
  currentPage,
  onPageChanged,
  paginationAside,
  rowData,
  filters,
  emptyContent,
  isActiveRow,
  className,
  perPage,
  tableClasses,
}) {
  const chunkedData = useMemo(() => chunk(data, perPage), [perPage, data]);
  const currentData = useMemo(
    () => chunkedData[Math.max(currentPage - 1, 0)] ?? [],
    [chunkedData, currentPage],
  );

  const renderFilters = () => {
    let filtersArray = filters;
    // Check if filters is a Fragment and fetch its children:
    if (filters && ReactIs.isFragment(filters)) {
      filtersArray = Children.toArray(filters.props.children);
    }
    // No filters:
    if (!filtersArray || !filtersArray.length) {
      return null;
    }

    return (
      <form className="filter">
        <span className="title">{I18n.t("common.filter")}</span>
        <div className="row">
          {filtersArray.map((filter, index) => (
            <div key={"filter".concat(index)} className="col-lg-2 col-md-12">
              {filter}
            </div>
          ))}
        </div>
      </form>
    );
  };

  /**
   * @param {Object} event
   * @param {Number} newPage
   */
  const onPaginate = (event, newPage) => {
    event.preventDefault();

    onPageChanged && onPageChanged(newPage);
  };

  /**
   * Render table head
   */
  const renderHead = () => {
    if (!headData.length) {
      return null;
    }

    return (
      <tr>
        {headData.map((item, index) => (
          <th className={columnStyle[index] || ""} key={index}>
            {item}
          </th>
        ))}
      </tr>
    );
  };

  /**
   * Render each table row
   */
  const renderRows = () => {
    return currentData.map((row, index) => {
      const rowClasses = classnames({ "table-active": !isActiveRow(row) });

      return (
        <tr className={rowClasses} key={index}>
          {rowData(row, index).map((column, rowIndex) => (
            <td className={columnStyle[rowIndex] || ""} key={rowIndex}>
              {column}
            </td>
          ))}
        </tr>
      );
    });
  };

  /**
   * Render pagination
   */
  const renderPagination = () => {
    const itemNodes = chunkedData.map((chunk, index) => {
      const page = index + 1;
      const classname = classnames("page-item", {
        active: page === currentPage,
      });

      return (
        <li className={classname} key={"page-item".concat(index)}>
          <button
            type="button"
            className="page-link"
            onClick={(event) => onPaginate(event, page)}
          >
            {page}
          </button>
        </li>
      );
    });

    return (
      <nav>
        <ul className="pagination">{itemNodes}</ul>
      </nav>
    );
  };

  /**
   * Render table (content)
   */
  const renderTable = () => {
    if (!currentData.length) {
      return <p>{emptyContent || I18n.t("common.no_data")}</p>;
    }

    return (
      <div className="table-responsive table-wrap">
        <table className={classnames("table", tableClasses)}>
          <thead>{renderHead()}</thead>
          <tbody>{renderRows()}</tbody>
        </table>
      </div>
    );
  };

  return (
    <div className={`${className} table-component`}>
      {renderFilters()}
      {(pagination || actions || paginationAside) && (
        <div className="btn-toolbar align-items-center">
          {pagination && <div className="col-auto">{renderPagination()}</div>}
          {paginationAside && (
            <div className="pagination-aside col-auto">{paginationAside}</div>
          )}
          {actions && <div className="col text-right">{actions}</div>}
        </div>
      )}
      {renderTable()}
      {pagination && renderPagination()}
    </div>
  );
}

/**
 * Chunk array method
 *
 * @param {Array} source
 * @param {Number} length
 *
 * @return {Array}
 */
function chunk(source, length) {
  if (source.length === 0) {
    return [[]];
  }

  const chunkedData = [];
  const data = source.slice(0);

  while (data.length > 0) {
    chunkedData.push(data.splice(0, length));
  }

  return chunkedData;
}
