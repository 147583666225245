class DisconnectError extends Error {
  constructor(...args) {
    super(...args);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, DisconnectError);
    }
  }
}

export default DisconnectError;
