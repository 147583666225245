import React from "react";
import { string } from "prop-types";
import deleteWasteRecoveryRequestMutation from "../../../graphql/mutations/deleteWasteRecoveryRequest.graphql";
import {
  errorAlert,
  successAlert,
} from "../../../../common/actions/alertActions";
import { useDispatch } from "react-redux";
import { onDeleteWasteRecoveryRequest } from "../../../graphql/cache/apolloCache";
import { useMutation } from "../../../../common/api/GraphQLClient";

DeleteRequestAction.propTypes = {
  uuid: string.isRequired,
};

export default function DeleteRequestAction({ uuid }) {
  const dispatch = useDispatch();
  const [deleteRequest, { loading }] = useMutation(
    deleteWasteRecoveryRequestMutation,
    {
      update: onDeleteWasteRecoveryRequest,
    },
  );

  const onClick = () => {
    // eslint-disable-next-line no-alert
    if (
      !window.confirm(
        "Êtes-vous certain de vouloir supprimer cette demande ? Cette action est irréversible.",
      )
    ) {
      return;
    }

    deleteRequest({ variables: { uuid } })
      .then(() => dispatch(successAlert("Votre demande a été supprimée")))
      .catch(() => {
        dispatch(errorAlert("Une erreur est survenue"));
      });
  };

  return (
    <button
      type="button"
      className="action"
      onClick={onClick}
      disabled={loading}
    >
      <i className="fa fa-trash" aria-hidden="true" />
    </button>
  );
}
