import I18n from "i18n-js";
import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { stringify } from "qs";
import { func, shape } from "prop-types";
import SmallBox from "../components/SmallBox";
import {
  ROUTE_CONTAINERS,
  ROUTE_FIBSDS,
  ROUTE_INSTALLATIONS,
  ROUTE_INTERVENTIONS,
  ROUTE_LEAK_DETECTORS,
  ROUTE_PROVIDER_CONTRACTS,
  ROUTE_PROVIDERS,
} from "../../../routes";
import { getDashboardMetrics } from "../actions/dashboardActions";
import { formatNumber } from "../../common/utils/formatUtils";
import InterventionType from "../../intervention/models/InterventionType";
import TextDateInput from "../../common/components/form/TextDateInput";
import Roles from "../../users/models/Roles";

class Dashboard extends Component {
  static propTypes = {
    metrics: shape(),
    currentUser: shape(),
    getDashboardMetrics: func.isRequired,
  };

  static defaultProps = {
    metrics: undefined,
    currentUser: undefined,
  };

  state = {
    interventionsSinceFilter: new Date(new Date().getFullYear(), 0, 1),
  };

  constructor() {
    super();

    this.onChangeInterventionSinceFilter =
      this.onChangeInterventionSinceFilter.bind(this);
  }

  /**
   * {@inheritdoc}
   */
  componentDidMount() {
    this.props.getDashboardMetrics(this.state.interventionsSinceFilter);
  }

  onChangeInterventionSinceFilter(date) {
    this.setState({ interventionsSinceFilter: date }, () => {
      this.props.getDashboardMetrics(this.state.interventionsSinceFilter);
    });
  }

  getNumbersData() {
    const { metrics, currentUser } = this.props;
    const leakDetectorMetrics = metrics.leakDetectors;
    const installationMetrics = metrics.installations;
    const interventionsMetrics = metrics.interventions;
    const interventionsByType = interventionsMetrics.countByType;
    const interventionsSince = this.state.interventionsSinceFilter.getTime();
    const containersMetrics = metrics.containers;
    const serviceProvidersMetrics = metrics.serviceProviders;
    const providerUri = currentUser.serviceProviderContractBeneficiary
      ? ROUTE_PROVIDERS
      : ROUTE_PROVIDER_CONTRACTS;
    const isTechnician = !currentUser.roles.includes(Roles.MANAGER);

    const numbers = {
      interventions: {
        legend: (
          <div>
            {I18n.t("pages.dashboard.numbers.interventions.row_title")}{" "}
            {I18n.t("pages.dashboard.numbers.since_filter")}
            <TextDateInput
              className="dashboard__number-legend-datepicker"
              onChange={this.onChangeInterventionSinceFilter}
              defaultValue={this.state.interventionsSinceFilter}
            />
          </div>
        ),
        data: [
          {
            value: formatNumber(interventionsByType[InterventionType.FILLING]),
            label: I18n.t("pages.dashboard.numbers.interventions.filling"),
            uri: {
              pathname: ROUTE_INTERVENTIONS,
              search: stringify({
                type: InterventionType.FILLING,
                startDate: interventionsSince,
              }),
            },
          },
          {
            value: formatNumber(interventionsByType[InterventionType.LEAK]),
            label: I18n.t(
              "pages.dashboard.numbers.interventions.leak_detection",
            ),
            uri: {
              pathname: ROUTE_INTERVENTIONS,
              search: stringify({
                type: InterventionType.LEAK,
                startDate: interventionsSince,
              }),
            },
          },
          {
            value: formatNumber(
              interventionsByType[InterventionType.LEAK_REPAIR],
            ),
            label: I18n.t("pages.dashboard.numbers.interventions.leak_repair"),
            uri: {
              pathname: ROUTE_INTERVENTIONS,
              search: stringify({
                type: InterventionType.LEAK_REPAIR,
                startDate: interventionsSince,
              }),
            },
          },
          {
            value: formatNumber(interventionsByType[InterventionType.DRAINAGE]),
            label: I18n.t("pages.dashboard.numbers.interventions.drainage"),
            uri: {
              pathname: ROUTE_INTERVENTIONS,
              search: stringify({
                type: InterventionType.DRAINAGE,
                startDate: interventionsSince,
              }),
            },
          },
          !isTechnician && {
            value: formatNumber(interventionsMetrics.toCompleteCount),
            label: I18n.t(
              "pages.dashboard.numbers.interventions.awaiting_treatment",
            ),
            uri: {
              pathname: ROUTE_FIBSDS,
              search: stringify({
                toComplete: true,
                startDate: interventionsSince,
              }),
            },
            alert: interventionsMetrics.toCompleteCount > 0,
          },
          !isTechnician && {
            value: formatNumber(interventionsMetrics.latelyCompletedCount),
            label: I18n.t(
              "pages.dashboard.numbers.interventions.lately_completed",
            ),
            uri: {
              pathname: ROUTE_FIBSDS,
              search: stringify({
                latelyCompleted: true,
                startDate: interventionsSince,
              }),
            },
          },
        ].filter((v) => v),
      },
      containers: {
        break: true,
        data: [
          {
            value: formatNumber(containersMetrics.count),
            label: I18n.t("pages.dashboard.numbers.containers.known"),
            uri: ROUTE_CONTAINERS,
          },
          {
            value: formatNumber(containersMetrics.overloaded),
            label: I18n.t("pages.dashboard.numbers.containers.overloaded"),
            uri: {
              pathname: ROUTE_CONTAINERS,
              search: stringify({ overloaded: true }),
            },
            alert: containersMetrics.overloaded > 0,
          },
        ],
      },
      leak_detectors: {
        data: [
          {
            value: formatNumber(leakDetectorMetrics.count),
            label: I18n.t("pages.dashboard.numbers.leak_detectors.known"),
            uri: ROUTE_LEAK_DETECTORS,
          },
          {
            value: formatNumber(leakDetectorMetrics.toCheckSoon),
            label: I18n.t(
              "pages.dashboard.numbers.leak_detectors.to_check_soon",
            ),
            uri: {
              pathname: ROUTE_LEAK_DETECTORS,
              search: stringify({
                endDate: moment().subtract(10, "months").toDate().getTime(),
              }),
            },
            alert: leakDetectorMetrics.toCheckSoon > 0,
          },
        ],
      },
      installations: {
        data: [
          {
            value: formatNumber(installationMetrics.count),
            label: I18n.t("pages.dashboard.numbers.installations.known"),
            uri: {
              pathname: ROUTE_INSTALLATIONS,
              search: stringify({ active: true }),
            },
          },
          {
            value: formatNumber(installationMetrics.toRepair),
            label: I18n.t("pages.dashboard.numbers.installations.to_repair"),
            uri: {
              pathname: ROUTE_INSTALLATIONS,
              search: stringify({ hasLeaks: true }),
            },
            alert: installationMetrics.toRepair > 0,
          },
          {
            value: formatNumber(installationMetrics.toCheckAfterRepair),
            label: I18n.t(
              "pages.dashboard.numbers.installations.to_check_after_repair",
            ),
            uri: {
              pathname: ROUTE_INSTALLATIONS,
              search: stringify({ mustBeCheckedAfterRepair: true }),
            },
            alert: installationMetrics.toCheckAfterRepair > 0,
          },
          {
            value: formatNumber(installationMetrics.expectingLeakDetectionSoon),
            label: I18n.t(
              "pages.dashboard.numbers.installations.expecting_leak_detection",
            ),
            uri: {
              pathname: ROUTE_INSTALLATIONS,
              search: stringify({ expectLeakDetectionSoon: true }),
            },
            alert: installationMetrics.expectingLeakDetectionSoon > 0,
          },
        ],
      },
    };

    if (serviceProvidersMetrics !== undefined) {
      numbers.service_providers = {
        break: true,
        data: [
          {
            value: formatNumber(serviceProvidersMetrics.active),
            label: I18n.t("pages.dashboard.numbers.service_providers.active"),
            uri: {
              pathname: providerUri,
              search: stringify({ active: true }),
            },
          },
          {
            value: formatNumber(serviceProvidersMetrics.expiringSoon),
            label: I18n.t(
              "pages.dashboard.numbers.service_providers.to_renew_soon",
            ),
            uri: {
              pathname: providerUri,
              search: stringify({ toRenew: true }),
            },
            alert: serviceProvidersMetrics.expiringSoon > 0,
          },
          {
            value: formatNumber(serviceProvidersMetrics.pendingApproval),
            label: I18n.t(
              "pages.dashboard.numbers.service_providers.waiting_approval",
            ),
            uri: {
              pathname: providerUri,
              search: stringify({ approve: true }),
            },
            alert: serviceProvidersMetrics.pendingApproval > 0,
          },
        ],
      };
    }

    return numbers;
  }

  renderNumber(value, label, uri, alert) {
    return (
      <div className="dashboard__number" key={label}>
        <SmallBox value={value} label={label} moreUri={uri} alert={alert} />
      </div>
    );
  }

  renderNumbersRow(row, data, legend) {
    const numbers = data.map((d) =>
      this.renderNumber(d.value, d.label, d.uri, d.alert || false),
    );

    return (
      <fieldset className="dashboard__number-group" key={row}>
        <legend>
          {legend || I18n.t(`pages.dashboard.numbers.${row}.row_title`)}
        </legend>
        <div className="dashboard__number-row">{numbers}</div>
      </fieldset>
    );
  }

  renderNumbersSection(data) {
    const rows = [];

    Object.keys(data).forEach((key) => {
      if (data[key].break) {
        rows.push(<div className="flex-break" key={`break-before-${key}`} />);
      }

      rows.push(this.renderNumbersRow(key, data[key].data, data[key].legend));
    });

    return <section className="dashboard__numbers">{rows}</section>;
  }

  renderAlertSummary() {
    const { metrics, currentUser } = this.props;
    const isTechnician = !currentUser.roles.includes(Roles.MANAGER);
    const transNs = "pages.dashboard.alert_summary.alerts";
    const trans = (key, placeholders) =>
      I18n.t(`${transNs}.${key}`, placeholders);
    const alerts = [];

    const leakDetectorMetrics = metrics.leakDetectors;
    if (leakDetectorMetrics.toCheckSoon > 0) {
      alerts.push(
        trans("leak_detectors:to_check_soon", {
          count: leakDetectorMetrics.toCheckSoon,
        }),
      );
    }

    const installationMetrics = metrics.installations;
    if (installationMetrics.toRepair > 0) {
      alerts.push(
        trans("installations:to_repair", {
          count: installationMetrics.toRepair,
        }),
      );
    }
    if (installationMetrics.toCheckAfterRepair > 0) {
      alerts.push(
        trans("installations:to_check_after_repair", {
          count: installationMetrics.toCheckAfterRepair,
        }),
      );
    }
    if (installationMetrics.expectingLeakDetectionSoon > 0) {
      alerts.push(
        trans("installations:expecting_leak_detection", {
          count: installationMetrics.expectingLeakDetectionSoon,
        }),
      );
    }

    const containersMetrics = metrics.containers;
    if (containersMetrics.overloaded > 0) {
      alerts.push(
        trans("containers:overloaded", { count: containersMetrics.overloaded }),
      );
    }

    const interventionMetrics = metrics.interventions;
    if (!isTechnician && interventionMetrics.toCompleteCount > 0) {
      alerts.push(
        trans("interventions:to_complete", {
          count: interventionMetrics.toCompleteCount,
        }),
      );
    }

    const serviceProvidersMetrics = metrics.serviceProviders;
    if (serviceProvidersMetrics !== undefined) {
      if (serviceProvidersMetrics.expiringSoon > 0) {
        alerts.push(
          trans("service_providers:expiring_soon", {
            count: serviceProvidersMetrics.expiringSoon,
          }),
        );
      }
      if (serviceProvidersMetrics.pendingApproval > 0) {
        alerts.push(
          trans("service_providers:waiting_approval", {
            count: serviceProvidersMetrics.pendingApproval,
          }),
        );
      }
    }

    if (alerts.length === 0) {
      return null;
    }

    return (
      <section className="col dashboard__alert_summary">
        <div className="alert alert-hint">
          <h5 className="alert-heading">
            {I18n.t("pages.dashboard.alert_summary.title")}
          </h5>
          <ul>
            {alerts.map((a) => (
              <li key={a}>{a}</li>
            ))}
          </ul>
        </div>
      </section>
    );
  }

  /**
   * {@inheritdoc}
   */
  render() {
    const { metrics, currentUser } = this.props;

    return (
      <div className="dashboard">
        <h1>{I18n.t("pages.dashboard.title")}</h1>
        {[metrics, currentUser].includes(undefined) && (
          <div className="loader loader--big" />
        )}
        {[metrics, currentUser].includes(null) && (
          <p>{I18n.t("common.loadingApiError")}</p>
        )}
        {metrics && currentUser && this.renderAlertSummary()}
        {metrics &&
          currentUser &&
          this.renderNumbersSection(this.getNumbersData())}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  metrics: state.dashboard.metrics,
  currentUser: state.auth.userProfile,
});

const mapDispatchToProps = (dispatch) => ({
  getDashboardMetrics: (date) => dispatch(getDashboardMetrics(date)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
