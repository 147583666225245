const prefix = "APP_ERROR_";
export const Actions = {
  SET: prefix + "SET",
  RESET: prefix + "RESET",
};

export function error404() {
  return { type: Actions.SET, payload: { code: 404 } };
}

export function error403() {
  return { type: Actions.SET, payload: { code: 403 } };
}

export function clearError() {
  return { type: Actions.RESET };
}
