import React from "react";
import { Redirect, Route } from "react-router-dom";
import { fetch } from "../../../../appContainer";
import Footer from "../layout/Footer";
import SelectLanguage from "../layout/SelectLanguage";
import { shape } from "prop-types";

/**
 * Auth route
 * Custom react-router route
 */
const AuthRoute = ({ component: Component, ...props }) => {
  // eslint-disable-line
  return (
    <Route
      {...props}
      render={(innerProps) =>
        !fetch("authenticator").isAuthenticated() ? (
          <div className="auth">
            <SelectLanguage tagName="div" />
            <section>
              <Component {...innerProps} />
            </section>
            <Footer className="full" />
          </div>
        ) : (
          <Redirect to={{ pathname: "/" }} />
        )
      }
    />
  );
};

AuthRoute.propTypes = {
  component: shape().isRequired,
};

export default AuthRoute;
