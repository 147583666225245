import React from "react";
import { alphabetically, uniq } from "../../../../common/utils/filterUtils";
import ClientUtils from "../../../../company/utils/ClientUtils";
import I18n from "i18n-js";
import Select from "../../../../common/components/form/Select";
import { func, string, shape, arrayOf } from "prop-types";

ClientFilter.propTypes = {
  requests: arrayOf(
    shape({
      site: shape({
        client: shape({
          uuid: string.isRequired,
          legalCompanyName: string.isRequired,
          address: shape({
            city: string.isRequired,
          }),
        }).isRequired,
      }).isRequired,
    }),
  ),
  defaultValue: string,
  onChangedValue: func,
};
ClientFilter.defaultProps = {
  requests: [],
  defaultValue: "",
  onChangedValue: null,
};

export default function ClientFilter({
  requests,
  defaultValue,
  onChangedValue,
}) {
  const clients = uniq(
    requests.reduce((clients, request) => {
      const { client } = request.site;

      return [
        ...clients,
        {
          uuid: client.uuid,
          legalCompanyName: client.legalCompanyName,
          city: client.address.city,
        },
      ];
    }, []),
    "uuid",
  ).sort((a, b) =>
    alphabetically(ClientUtils.renderLabel(a), ClientUtils.renderLabel(b)),
  );

  return (
    <Select
      id="filterByClient"
      label={I18n.t("filters.by_client")}
      placeholder={I18n.t("filters.by_client:default")}
      onChangedValue={onChangedValue}
      renderOption={(option) => (
        <option key={option.uuid} value={option.uuid}>
          {ClientUtils.renderLabel(option)}
        </option>
      )}
      options={clients}
      defaultValue={defaultValue}
      emptyStringAsNull
      optional
    />
  );
}
