/* eslint react/no-unescaped-entities: 0 */
import React, { Component } from "react";

export default class CGU extends Component {
  /**
   * {@inheritdoc}
   */
  render() {
    return (
      <div className="container">
        <h1>Allgemeine Nutzungsbedingungen CLIM'app</h1>

        <p>
          Clim'app unterliegt den vorliegenden Allgemeinen Nutzungsbedingungen
          (ANB). Außer im Falle einer besonderen schriftlichen Vereinbarung
          impliziert jede Bestellung und/oder Nutzung der Dienste von Clim'app
          durch den Kunden (Kunde) automatisch die Zustimmung des Kunden zu den
          vorliegenden Nutzungsbestimmungen. Der Kunde verpflichtet sich, diese
          zu respektieren und dafür zu sorgen, dass sie von seinen Mitarbeitern
          und/oder Partnern bei der Erstellung eines Kontos, beim Zugriff auf
          Clim'app und allgemein bei der Nutzung von Clim'app respektiert
          werden.
        </p>

        <h2>1. Beschreibung der über die Anwendung bereitgestellten Dienste</h2>

        <p>
          CLIMALIFE hat die Clim'app entwickelt, die sowohl über eine Website
          als auch über eine mobile Anwendung zugänglich ist und Kälteprofis
          täglich begleitet.
        </p>

        <p>Clim'app ist eine mobile Anwendung, mit der :</p>

        <ul>
          <li>Die Verwaltung und Deklaration von Eingriffen</li>
          <li>Verwaltung von Anlagen und Behältern</li>
          <li>Erstellung von regulatorischen Dokumenten</li>
        </ul>

        <p>Clim'app ist ebenfalls eine Webanwendung, die :</p>

        <ul>
          <li>Sichtbarkeit des Kältemittelbestands</li>
          <li>
            Die Möglichkeit, die Umgebung des Kälteprofis zu verwalten
            (Standorte, Anlagen, Fahrzeuge, Detektoren...)
          </li>
          <li>
            Dashboards, die die wesentlichen Aktivitäten der Tätigkeit
            zusammenfassen
          </li>
        </ul>

        <p>
          Die Dienste von Clim'app können jederzeit weiterentwickelt werden, um
          der Entwicklung der Technologien und/oder der geltenden Gesetzgebung
          Rechnung zu tragen. CLIMALIFE bemüht sich nach besten Kräften, den
          Nutzer über jede wesentliche Änderung der Dienste zu informieren.
        </p>

        <h2>2. Verpflichtungen des Auftraggebers</h2>

        <p>
          Im Rahmen der Nutzung der Dienste handelt der Kunde als
          Gewerbetreibender für die Zwecke seiner gewerblichen, industriellen,
          handwerklichen oder freiberuflichen Tätigkeit und unter Ausschluss
          jeglicher persönlicher Zwecke.
        </p>

        <p>
          Er verpflichtet sich, über den Dienst eine rechtmäßige Tätigkeit
          auszuüben und über alle erforderlichen Genehmigungen zu verfügen, sei
          es von seinen Partnern oder im Falle der Ausübung einer
          reglementierten Tätigkeit und/oder zur Einhaltung der für seine
          Tätigkeit geltenden gesetzlichen Bestimmungen;
        </p>

        <p>
          Der Kunde hat die Möglichkeit, seinen Mitarbeitern und/oder
          Subunternehmern (Nutzer) den Zugriff auf den Clim'app-Service in
          seinem Namen zu gestatten. In diesem Zusammenhang stellt der Kunde
          sicher, dass seine Mitarbeiter und/oder Unterauftragnehmer die
          Akzeptanz dieser AVB und der beigefügten Datenschutzcharta einhalten.
          Im Allgemeinen ist der Kunde gegenüber CLIMALIFE für die Einhaltung
          der allgemeinen Nutzungsbedingungen durch seine Agenten und Partner
          verantwortlich.
        </p>

        <h2>3. Zugang zu Clim'app Services</h2>

        <p>
          Dem Kunden ist bekannt, dass der Dienst online angeboten wird und dass
          jeder Zugriff auf den Dienst voraussetzt, dass der Kunde und/oder
          seine Benutzer über ein Internet-Abonnement und eine
          Computerausrüstung verfügen, die ihnen die Verbindung zum Dienst
          ermöglicht. Der Kunde erkennt an, dass die Kosten für den Anschluss
          und die Anschaffung der Computerausrüstung vom Kunden und den
          Benutzern zu tragen sind;
        </p>

        <h3>Das Herunterladen der Clim'app</h3>

        <p>
          Der Zugang zu den Diensten setzt voraus, dass Sie das Clim'app-Angebot
          von CLIMALIFE angenommen und zuvor die mobile Anwendung Clim'app,
          deren Funktionalitäten und Interoperabilitätsbedingungen zum Zeitpunkt
          des Herunterladens angegeben sind, im Anwendungsshop, wie dem App
          Store oder Google Play, heruntergeladen haben.
        </p>

        <p>
          Neue Versionen der Anwendung werden regelmäßig über den
          Anwendungsshop, wie z. B. den App Store oder Google Play, zur
          Verfügung gestellt. Durch das Herunterladen dieser Updates akzeptiert
          der Benutzer die sich daraus ergebenden Änderungen an der Anwendung
          oder den Diensten. Der Nutzer verpflichtet sich, regelmäßig Updates
          herunterzuladen, und erkennt an, dass CLIMALIFE keine Haftung
          übernommen werden kann, wenn der Nutzer eine Version vor der
          aktualisierten Version verwendet. Kontoerstellung
        </p>

        <p>
          Die Dienste sind zugänglich, sobald der Benutzer sein Konto in
          Übereinstimmung mit den in diesem Artikel angegebenen Bedingungen
          erstellt hat.
        </p>

        <h3>Kontoerstellung</h3>

        <p>
          Die Erstellung eines Kontos auf Clim'app erfolgt in verschiedenen
          Schritten: Jeder Schritt wird nacheinander durch das "Anklicken" eines
          Bestätigungssymbols durchlaufen. Während der verschiedenen Schritte
          der Kontoerstellung muss der Benutzer möglicherweise persönliche Daten
          (wie Name, Nachname, Vorname usw.) eingeben, deren Verarbeitung durch
          die Datenschutzrichtlinie geregelt ist.
        </p>

        <p>
          Wenn ein Benutzer auf ein Symbol klickt, dessen Wortlaut oder
          Darstellung eine Verpflichtung hervorruft, die jeder durchschnittliche
          Internetbenutzer verstehen würde, ist der Benutzer durch seinen Klick
          gebunden.
        </p>

        <h3>Schecks vor der Einrichtung des Kontos</h3>

        <p>
          CLIMALIFE behält sich das Recht vor, den Antrag auf Einrichtung eines
          Kontos nicht zu bestätigen, insbesondere in den folgenden Fällen:
        </p>
        <ul>
          <li>Im Falle einer ungültigen Angabe;</li>
          <li>
            Wenn das Angebot von CLIMALIFE nicht formell vom Kunden angenommen
            wurde.
          </li>
        </ul>

        <p>
          In einem solchen Fall wird der Antrag auf Einrichtung eines Kontos
          nicht bestätigt und es kann sich daraus keine Verpflichtung ergeben.
          Gültigkeit und Nachweis der Erstellung des Kontos
        </p>

        <p>
          Ungeachtet anderer schriftlicher Nachweise oder auf einem anderen
          dauerhaften Datenträger aufbewahrter Nachweise wird vereinbart, dass
          die computerisierten Aufzeichnungen, die in den computergestützten
          Systemen von CLIMALIFE oder denen seiner Unterauftragnehmer aufbewahrt
          werden, als Beweis für die Mitteilung des Inhalts der Einrichtung
          Ihres Kontos und dessen Datum gelten.
        </p>

        <h3>Hilfe</h3>

        <p>
          Für die Behandlung von Anomalien steht ein elektronischer
          Support-Service zur Verfügung. Meldungen über Anomalien müssen
          unverzüglich per E-Mail an folgende Adresse gesendet werden:
          support@Clim'app.dehon.com. CLIMALIFE diagnostiziert die Anomalie und
          führt dann deren Korrektur durch.
        </p>

        <h2>4. Ihre Verpflichtungen bei der Nutzung unserer Dienste</h2>
        <h3>4.1 Sicherheit des Kontos</h3>

        <p>
          Der Zugang zum Konto ist dem Kunden nach Identifizierung mit der
          Kennung und dem Passwort vorbehalten, die jeder Benutzer bei der
          Erstellung seines Kontos frei bestimmt.
        </p>

        <p>
          Der Benutzer erkennt an, dass sein Passwort streng vertraulich und
          persönlich ist. Die Nutzung seines Logins und Passworts über das
          Internet erfolgt auf eigene Gefahr. Es ist Sache des Benutzers, alle
          notwendigen Maßnahmen zu ergreifen, um sich gegen eine Verletzung zu
          schützen. In diesem Sinne kann CLIMALIFE nicht für einen
          betrügerischen Zugriff auf das Konto des Nutzers oder für eine
          Änderung dieses Kontos verantwortlich gemacht werden. In einem solchen
          Fall verpflichtet sich der Kunde, CLIMALIFE so schnell wie möglich zu
          informieren.
        </p>

        <p>
          Der Kunde bleibt der alleinige Garant für die in seinem Konto
          gespeicherten Informationen. Er kann sie jederzeit hinzufügen, ändern
          oder entfernen.
        </p>

        <h3>
          4.2 Mit dem Zugriff auf, dem Besuch oder der Nutzung von Clim'app über
          seine Nutzer garantiert und bestätigt der Kunde, dass er für jede
          Nutzung von Clim'app durch ihn selbst oder durch eine Person, die
          seine Kennungen verwendet, verantwortlich ist.
        </h3>

        <h3>4.3 Verbot der illegalen oder unzulässigen Nutzung von Clim'app</h3>

        <p>
          Der Nutzer ist darüber informiert, dass alle geltenden Gesetze und
          Vorschriften im Internet anwendbar sind und bestätigt, dass er
          Clim'app nicht für illegale oder gesetzlich oder durch diese
          Nutzungsbedingungen verbotene Zwecke verwendet.
        </p>

        <h2>5. Preise</h2>

        <p>
          Die Preisbedingungen sind in dem von CLIMALIFE abgegebenen und vom
          Kunden akzeptierten Preisangebot vereinbart.
        </p>

        <p>
          Die Preise sind exklusive Steuern. Die Bezahlung der Rechnungen
          erfolgt bei der ersten Produktbestellung des Kunden in bar, danach
          innerhalb von 30 Tagen zum Monatsende ab Rechnungsdatum, ohne
          Skontoabzug für vorzeitige Zahlung. CLIMALIFE behält sich das Recht
          vor, die vereinbarten Zahlungsbedingungen zu revidieren, falls der
          Kunde nicht mehr zufriedenstellend durch die Kreditversicherung von
          CLIMALIFE gedeckt ist. Jede Reklamation muss schriftlich vor dem
          Fälligkeitsdatum der genannten Rechnung erfolgen. Darüber hinaus
          entbindet das Auftreten einer Streitigkeit den Kunden nicht von der
          Zahlung der Rechnung in ihrer Gesamtheit.
        </p>

        <p>
          Unbeschadet sonstiger Maßnahmen von MATELEX schuldet der Kunde für
          nicht am Fälligkeitstag gezahlte Beträge (a) Verzugszinsen in Höhe von
          1,5 % pro Monat auf die geschuldeten Beträge ab Fälligkeit der
          Rechnung; (b) eine pauschale Entschädigung für Inkassokosten in Höhe
          von 40 €. CLIMALIFE behält sich außerdem das Recht vor, dem Kunden
          alle zusätzlichen Kosten in Rechnung zu stellen, die durch die
          Eintreibung seiner Forderung entstanden sind (insbesondere
          außergerichtliche Handlungen, Inkassokosten und -gebühren, externe
          Intervention usw.), sowie alle Schäden, die durch den Zahlungsverzug
          entstanden sind. Ab dem ersten Verzug behält sich CLIMALIFE außerdem
          das Recht vor, einen laufenden Auftrag zu stornieren oder auszusetzen
          und die Zahlungsfrist zu verkürzen. Ebenso kann jeder Verzug bei einer
          Rechnung zum Verfall der Frist einer anderen noch nicht fälligen
          Rechnung führen, wobei letztere sofort fällig wird.
        </p>

        <h2>6. Dauer</h2>

        <p>
          Die Dauer der Nutzung des Dienstes wird in den vom Kunden akzeptierten
          Sonderbedingungen vereinbart. Nach Ablauf dieser Frist hat der Kunde,
          unabhängig von der Ursache, jegliche Nutzung des Dienstes unverzüglich
          einzustellen.
        </p>

        <p>
          Sollte der Kunde die Bestimmungen des Vertrages nicht einhalten,
          behält sich CLIMALIFE das Recht vor, den Vertrag fristlos zu kündigen.
        </p>

        <p>
          Der Kunde hat eine Frist von 3 Monaten, um auf die von der Anwendung
          generierten Dokumente zuzugreifen und diese abzurufen. Nach diesem
          Zeitraum hat der Kunde eine Frist von fünf (5) Jahren ab dem Datum der
          Beendigung des Vertrages, um von CLIMALIFE die Rückgabe der
          Kundendaten zu verlangen. Dieser Service zur Datenübertragbarkeit kann
          dem Kunden in Rechnung gestellt werden.
        </p>

        <h2>7. Vertraulichkeit / Daten</h2>

        <p>
          CLIMALIFE kann in keiner Weise für den Inhalt und die Kontrolle der
          Kundendaten und/oder jeglicher Informationen, die im Rahmen des
          Dienstes übertragen oder verarbeitet werden, verantwortlich gemacht
          werden, für die der Kunde der Garant bleibt.
        </p>

        <p>
          CLIMALIFE verpflichtet sich, die Vertraulichkeit und Sicherheit der
          Daten gegenüber Dritten und im Rahmen der vertraglichen Bedingungen zu
          gewährleisten, die CLIMALIFE an den Dienstleister, der Eigentümer des
          Serverzentrums ist, binden. CLIMALIFE kann jedoch die Kundendaten im
          Rahmen der Ausübung seiner Tätigkeit nutzen. Durch ausdrückliche
          Vereinbarung wird zwischen den Parteien festgelegt, dass die Nutzung
          der Kundendaten durch CLIMALIFE nicht als Verstoß gegen die im Vertrag
          vorgesehenen Vertraulichkeitsbedingungen angesehen werden kann.
        </p>

        <p>
          Zur Verarbeitung personenbezogener Daten: siehe Datenschutzrichtlinie.
        </p>

        <h2>8. Verantwortung</h2>

        <h3>8.1 Allgemeine Informationen</h3>

        <p>
          {" "}
          CLIMALIFE unterliegt einer Ausschließlichkeitsverpflichtung gegenüber
          allen anderen. Sie garantiert die Übereinstimmung der Leistungen mit
          den hierin gemachten Beschreibungen. Der Kunde übernimmt alle anderen
          Verantwortlichkeiten als die der Übereinstimmung der Leistungen mit
          ihrer Beschreibung und insbesondere die bezüglich :
        </p>

        <ul>
          <li>die Wahrung der Vertraulichkeit der Zugangscodes ;</li>
          <li>
            den Betrieb seines internen Netzwerks und seiner eigenen
            IT-Ausstattung (insbesondere der lokalen Infrastruktur),
          </li>
          <li>der in den Dienst eingegebenen Kundendaten;</li>
          <li>die Rechtmäßigkeit seiner Tätigkeit;</li>
          <li>die Rechtmäßigkeit und Angemessenheit der Kundendaten;</li>
          <li>der Nutzung des Dienstes durch seine Bevollmächtigten; und</li>
          <li>die Angemessenheit des Dienstes für seine Bedürfnisse,</li>
          <li>den Betrieb des Dienstes,</li>
          <li>die Qualifikation und Kompetenz seiner Mitarbeiter.</li>
        </ul>

        <p>
          Darüber hinaus und im Falle eines nachgewiesenen Verschuldens des
          Kunden haftet CLIMALIFE nur für den Ersatz der vermögensrechtlichen
          Folgen direkter und vorhersehbarer Schäden aufgrund der Ausführung der
          Dienstleistungen. Folglich haftet CLIMALIFE unter keinen Umständen für
          indirekte oder unvorhersehbare Verluste oder Schäden des Kunden oder
          Dritter, insbesondere nicht für entgangenen Gewinn, Verlust,
          Ungenauigkeit oder Korruption von Dateien oder Daten, kommerziellen
          Schaden, Umsatz- oder Gewinnverlust, Verlust von Kunden, Verlust von
          Gelegenheiten, Kosten für die Beschaffung eines Ersatzprodukts, einer
          Ersatzdienstleistung oder einer Ersatztechnologie, die im Zusammenhang
          mit der Nichterbringung oder fehlerhaften Erbringung der
          Dienstleistungen stehen oder daraus resultieren.
        </p>

        <p>
          In allen Fällen ist die Höhe der Haftung von CLIMALIFE strikt auf die
          Erstattung der vom Kunden zum Zeitpunkt des Eintritts des
          haftungsbegründenden Ereignisses tatsächlich gezahlten Beträge auf den
          durchschnittlichen Verbrauch der letzten zwölf Monate beschränkt.
        </p>

        <p>
          Darüber hinaus kann CLIMALIFE nicht für die versehentliche Zerstörung
          der Daten durch den Kunden oder einen Dritten verantwortlich gemacht
          werden, der mit Hilfe der dem Kunden gegebenen Kennungen auf die
          Anwendungsdienste zugegriffen hat.
        </p>

        <p>
          Jede der Parteien erklärt, dass sie bei einer namhaften
          Versicherungsgesellschaft für alle schädlichen Folgen der Handlungen,
          für die sie nach dem Vertrag haftbar gemacht werden könnte, versichert
          ist.
        </p>

        <p>
          Diese Versicherung muss während der gesamten Laufzeit des Vertrages
          aufrechterhalten werden. Auf Verlangen einer Vertragspartei stellt die
          andere Vertragspartei der genannten Vertragspartei eine jährliche
          Bescheinigung ihrer Versicherungsgesellschaft zur Verfügung, in der
          die Beträge und die gedeckten Risiken angegeben sind.
        </p>

        <p>
          CLIMALIFE kann in keinem Fall für Schäden verantwortlich gemacht
          werden, die durch eine Unterbrechung oder einen Ausfall des
          Telekommunikationsbetreibers, des Stromversorgers oder im Falle
          höherer Gewalt entstehen.
        </p>

        <h3>8.2 Sicherheit/Zugang</h3>

        <p>
          CLIMALIFE unternimmt angemessene und sorgfältige Anstrengungen, um die
          Sicherheit und die betriebliche Integrität von Clim'app
          aufrechtzuerhalten, indem es Sicherheitsmaßnahmen ergreift, die an die
          Art der Daten und die mit dieser Tätigkeit verbundenen Risiken
          angepasst sind. Allerdings können viele Faktoren, die außerhalb der
          Kontrolle des Unternehmens liegen, den Betrieb von Clim'app
          beeinträchtigen.
        </p>

        <p>
          CLIMALIFE bemüht sich, soweit möglich, die Dienste 7 Tage die Woche,
          24 Stunden am Tag zugänglich zu halten.
        </p>

        <p>
          CLIMALIFE behält sich das Recht vor, den Zugang zu allen oder einem
          Teil der Clim'app-Dienste ohne Vorankündigung zu unterbrechen,
          vorübergehend auszusetzen oder zu ändern, um Wartungsarbeiten,
          Upgrades oder andere, insbesondere technische, Gründe zu
          gewährleisten, ohne dass diese Manöver zu einer Verpflichtung oder
          Entschädigung führen.
        </p>

        <p>Folglich:</p>

        <ul>
          <li>
            CLIMALIFE kann im Falle einer Unterbrechung des Zugangs zu Clim'app
            und den sich daraus ergebenden Konsequenzen nicht haftbar gemacht
            werden;
          </li>
          <li>
            CLIMALIFE kann nicht für eventuelle Schäden (direkt oder indirekt)
            oder vorübergehende oder dauerhafte Zwischenfälle verantwortlich
            gemacht werden, die an den Daten des Nutzers oder an seiner
            Computerausrüstung während seines Zugriffs auf Clim'app oder während
            des Besuchs seines Kontos oder allgemein während der Übertragung der
            Dateien und der Software, aus denen Clim'app besteht, auf sein
            Empfangsgerät entstehen würden. Insbesondere ist CLIMALIFE nicht
            verantwortlich für die mögliche Übertragung eines Virus durch
            Clim'app.
          </li>
          <li>
            CLIMALIFE ist nicht verantwortlich für die betrügerische Nutzung
            seiner Vertriebsmittel und lehnt jede Verantwortung im Falle des
            Eindringens in seine Computersysteme und des Datendiebstahls ab,
            vorbehaltlich zwingender gesetzlicher und behördlicher Bestimmungen,
            die dem entgegenstehen können, wobei es sich versteht, dass
            CLIMALIFE alle nützlichen Mittel einsetzt, um solche unerlaubten
            Eingriffe zu verhindern.
          </li>
        </ul>

        <h3>8.3 Aktualisierungen</h3>

        <p>
          Trotz der Bemühungen, die Richtigkeit der Informationen und/oder
          Dokumente auf Clim'app zu gewährleisten, lehnt CLIMALIFE jede
          Verantwortung, welcher Art auch immer, für den Fall ab, dass
          Informationen und/oder Dokumente auf Clim'app unrichtig sind oder
          nicht aktualisiert werden.
        </p>

        <h2>9. Hyperlinks zu anderen Diensten</h2>

        <p>
          Clim'app kann auf andere Dienste (Websites, Anwendungen usw.)
          verweisen, über die CLIMALIFE keine technische oder inhaltliche
          Kontrolle hat. Die Existenz eines Hypertext-Links zu einem anderen
          Dienst stellt keine Bestätigung dieses Dienstes oder seines Inhalts
          durch CLIMALIFE dar.
        </p>

        <p>
          CLIMALIFE kann daher keine Garantie für die Vollständigkeit oder
          Exaktheit des Inhalts dieser Drittanbieter-Dienste sowie für deren
          Verfügbarkeit geben.
        </p>

        <p>
          CLIMALIFE lehnt jede Verantwortung für direkte oder indirekte Schäden
          ab, die sich aus der Konsultation oder Nutzung der Webdienste, auf die
          Clim'app verweist, oder der auf diesen Diensten veröffentlichten
          Informationen ergeben. Sie lehnt auch jede Verantwortung für die
          Verarbeitung personenbezogener Daten in diesen Diensten ab.
        </p>

        <h2>10. Geistiges Eigentum</h2>

        <p>
          CLIMALIFE gewährt dem Kunden nur ein persönliches, nicht
          ausschließliches und nicht übertragbares Recht, auf Clim'app
          zuzugreifen und diese zu nutzen, vorbehaltlich der Einhaltung der
          vorliegenden Allgemeinen Nutzungsbedingungen. Der Kunde kann jedoch
          seinen Mitarbeitern und/oder Partnern ein Recht zur Nutzung und zum
          Zugriff auf den Dienst im Namen des Kunden unter seiner vollen
          Verantwortung einräumen.
        </p>

        <p>
          Der Kunde erkennt an und akzeptiert, dass die Nutzung kein geistiges
          Eigentumsrecht an dem Dienst oder an einem Element, das den Betrieb
          und die Lieferung ermöglicht, und allgemein an einem Gerät oder
          Material, das von CLIMALIFE zur Verfügung gestellt wird oder in der
          Plattform oder in der Anwendung enthalten ist, verleiht.
        </p>

        <p>
          Clim'app (als untrennbares Ganzes betrachtet) und die verschiedenen
          Elemente, die auf Clim'app erscheinen (wie Texte, Partnerumgebung,
          Fotos, Kataloge, Layouts, Marken, Logos, Illustrationen, spezifische
          Software, Videos), sind durch geistige Eigentumsrechte geschützt, die
          CLIMALIFE oder seinen Lizenzgebern gehören.
        </p>

        <p>
          Das Logo, die Namen von Produkten, Dienstleistungen oder die Namen von
          Unternehmen, die auf Clim'app erwähnt werden, stellen wahrscheinlich
          Marken, Handelsnamen, Firmennamen der jeweiligen Eigentümer dar. Der
          Kunde verpflichtet sich ausdrücklich, diese geistigen Eigentumsrechte
          zu respektieren.
        </p>

        <p>
          Der Kunde verpflichtet sich, die Informationen, die Software, das
          Produkt oder die Dienstleistung, die er von Clim'app erhalten hat,
          weder ganz noch teilweise zu ändern, zu kopieren, zu verteilen, zu
          übertragen, zu senden, zu veröffentlichen, zu lizenzieren, zu
          übertragen, zu verkaufen, zu reproduzieren oder reproduzieren zu
          lassen, weder vorübergehend noch dauerhaft, durch irgendein Verfahren,
          bekannt oder unbekannt, auf irgendeinem Medium, ohne die vorherige
          schriftliche Zustimmung von CLIMALIFE. Die Nichtbeachtung dieses
          Verbots stellt einen Verstoß dar, der die zivil- und strafrechtliche
          Haftung des Fälschers zur Folge haben kann.
        </p>

        <p>
          Der Kunde ist verpflichtet, CLIMALIFE unverzüglich über jede ihm
          bekannte rechtswidrige oder vertragswidrige Nutzung des Dienstes zu
          informieren. Wenn CLIMALIFE nach diesen Informationen beschließt,
          Maßnahmen gegen einen Dritten zu ergreifen, verpflichtet sich der
          Nutzer, alle notwendige Unterstützung zu leisten, die
          vernünftigerweise verlangt werden kann.
        </p>

        <h2>11. Löschung des Kontos</h2>

        <p>Der Benutzer kann sein Konto jederzeit löschen.</p>

        <p>
          CLIMALIFE kann das Konto des Nutzers von sich aus löschen, was die
          Beendigung des vorliegenden Vertrages zur Folge hat (mit Ausnahme von
          Bestimmungen, die auch nach der Beendigung des vorliegenden Vertrages
          in Kraft bleiben), insbesondere in den folgenden Fällen, wobei diese
          Liste nicht abschließend ist:
        </p>

        <p>
          Diese Löschung der Initiative erfolgt, wenn das Konto über einen
          bestimmten Zeitraum nicht genutzt wird;
        </p>
        <p>
          Diese Löschung der Initiative stellt eine vorübergehende oder
          endgültige Maßnahme dar, die von CLIMALIFE gegen ein verdächtiges
          Konto ergriffen wird, zum Beispiel wenn CLIMALIFE Grund zu der Annahme
          hat, dass der Nutzer den vorliegenden Vertrag nicht einhält;
        </p>

        <p>
          Soweit möglich und innerhalb der geltenden gesetzlichen Grenzen teilt
          CLIMALIFE seine Entscheidung zur Löschung des Benutzerkontos vorher
          mit.
        </p>

        <p>
          Der Nutzer hat keinen Anspruch auf Entschädigung für die Löschung
          seines Accounts.
        </p>

        <p>
          Nach der Löschung seines Kontos bewahrt CLIMALIFE die Daten und
          Inhalte für die Zeit auf, die für die technischen Vorgänge der
          Löschung des Kontos notwendig ist, und in jedem Fall innerhalb der
          Grenzen und Verpflichtungen, die durch die geltenden Gesetze auferlegt
          werden. CLIMALIFE behält sich das Recht vor, die auf Clim'app
          veröffentlichten Informationen und Inhalte, die für die Verwaltung der
          Installation durch CLIMALIFE notwendig sind, zu behalten.
        </p>

        <h2>12. Schlussbestimmungen</h2>

        <h3>12.1 Änderungen dieser Allgemeinen Nutzungsbedingungen</h3>

        <p>
          Die vorliegenden Allgemeinen Nutzungsbedingungen können jederzeit und
          ohne Vorankündigung geändert werden, je nach den an Clim'app
          vorgenommenen Änderungen, Änderungen der Gesetzgebung oder aus einem
          anderen Grund. Die jeweils gültige Version dieser Nutzungsbedingungen,
          die durchsetzbar ist, ist auf Clim'app dauerhaft verfügbar. Der
          Benutzer verpflichtet sich, sie regelmäßig zu konsultieren.
        </p>

        <h3>12.2 Vereinbarung des Nachweises</h3>

        <p>
          CLIMALIFE kann mit dem Nutzer zum Austausch der für Clim'app
          notwendigen Informationen auf elektronischem Wege übergehen. Es wird
          davon ausgegangen, dass jede elektronische Kommunikation zwischen den
          Parteien die gleiche Beweiskraft hat wie ein schriftliches Dokument
          auf Papier.
        </p>

        <p>
          Eine gedruckte Version dieser Allgemeinen Nutzungsbedingungen und
          einer eventuellen Warnung in elektronischer Form wird in jedem
          Gerichts- oder Verwaltungsverfahren, das mit diesem Vertragsverhältnis
          in Zusammenhang steht, auf die gleiche Weise und unter den gleichen
          Bedingungen akzeptiert wie andere Geschäftsdokumente und Register, die
          in gedruckter Form erstellt und aufbewahrt werden.
        </p>

        <h3>15.3 Trennbarkeit</h3>

        <p>
          Sollte eine Klausel dieser Allgemeinen Nutzungsbedingungen nach
          geltendem Recht ganz oder teilweise für nichtig, ungültig,
          rechtswidrig oder unanwendbar erklärt werden, so berührt dies nicht
          die Gültigkeit der übrigen Klauseln, vorbehaltlich der Annahme der
          Nichtigkeit einer wesentlichen Klausel oder der gegenseitigen
          Abhängigkeit der vertraglichen Bestimmungen. Die nichtige, ungültige,
          rechtswidrige oder unanwendbare Klausel wird rückwirkend durch eine
          gültige und anwendbare Klausel ersetzt, deren Inhalt dem der
          ursprünglichen Klausel möglichst nahe kommt.
        </p>

        <h3>15.6 Benachrichtigung</h3>

        <p>
          Jede Mitteilung oder Benachrichtigung, die CLIMALIFE macht, ist
          gültig, wenn sie an die E-Mail-Adresse adressiert ist, die der Nutzer
          angegeben hat, auch wenn diese nicht mehr gültig ist.
        </p>

        <h3>15.8 Anwendbares Recht und zuständige Gerichte</h3>

        <p>
          Die vorliegenden Allgemeinen Nutzungsbedingungen werden in
          Übereinstimmung mit dem Recht geregelt und ausgelegt, das für das
          Unternehmen CLIMALIFE gilt, das das vom Kunden angenommene
          Clim'app-Angebot erstellt hat.
        </p>

        <p>
          Alle Streitigkeiten im Zusammenhang mit der Nutzung von Clim'app sowie
          der Gültigkeit, Auslegung, Ausführung oder Nichtausführung der
          vorliegenden Nutzungsbedingungen fallen unter die ausschließliche
          Zuständigkeit der Gerichte des Gerichtsstandes dieser Einrichtung.
        </p>
      </div>
    );
  }
}
