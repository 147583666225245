import React, { useState } from "react";
import { number, func } from "prop-types";
import StatelessTable from "./StatelessTable";

const parentProps = { ...StatelessTable.propTypes };
delete parentProps.initialPage;
delete parentProps.currentPage;

Table.propTypes = {
  ...parentProps,
  initialPage: number,
  onPageChanged: func,
};

Table.defaultProps = {
  initialPage: 1,
  onPageChanged: null,
};

/**
 * Table with state for auto handling page change.
 *
 * @deprecated Please use StatelessTable as a controlled component
 */
export default function Table({ initialPage, onPageChanged, ...props }) {
  const [currentPage, setCurrentPage] = useState(initialPage);

  return (
    <StatelessTable
      currentPage={currentPage}
      onPageChanged={(newPage) => {
        setCurrentPage(newPage);

        onPageChanged && onPageChanged(newPage);
      }}
      {...props}
    />
  );
}
