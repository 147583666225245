import { func, string } from "prop-types";
import React from "react";
import { useQuery } from "@apollo/client";
import wasteRecoverableArticles from "../graphql/queries/wasteRecoverableArticles.graphql";
import Select from "../../common/components/form/Select";
import { alphabetically, uniq } from "../../common/utils/filterUtils";

SelectWasteArticle.propTypes = {
  value: string, // selected value
  label: Select.propTypes.label,
  id: string.isRequired,
  onChangedValue: func.isRequired,
};

export default function SelectWasteArticle({ value, ...props }) {
  const { loading, error, data } = useQuery(wasteRecoverableArticles);

  let articles = data?.wasteRecoverableArticles || [];
  articles = uniq(
    articles.reduce(
      (articles, { uuid, designation }) => [
        ...articles,
        {
          uuid,
          designation,
        },
      ],
      [],
    ),
    "uuid",
  ).sort((a, b) => alphabetically(a.designation, b.designation));

  return (
    <Select
      loading={loading}
      loadingError={Boolean(error)}
      options={articles}
      renderOption={(option) => (
        <option key={`option-${option.uuid}`} value={option.uuid}>
          {option.designation}
        </option>
      )}
      defaultValue={value}
      error={
        data?.wasteRecoverableArticles.length === 0 && "Aucun article éligible"
      }
      {...props}
    />
  );
}
