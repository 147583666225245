/* eslint react/no-unescaped-entities: 0 */
/* eslint react/display-name: 0 */
import React, { Component } from "react";
import ImportInstallationsContent from "./ImportInstallationsContent";

export default class extends Component {
  static propTypes = ImportInstallationsContent.propTypes;

  /**
   * {@inheritdoc}
   */
  render() {
    return (
      <div>
        <p>
          To import your installations, the data must be saved in TSV format,
          and follow the following structure:
        </p>

        <ul>
          <li>Kunden-Nr.</li>
          <li>Standort-Nummer</li>
          <li>Anlagen Nr.</li>
          <li>
            Kältemittel-Code <i>*1</i>
          </li>
          <li>Bezeichnung</li>
          <li>Nennfüllmenge</li>
          <li>Aktuelle Füllmenge</li>
          <li>
            Bezeichner num <i>*2</i>
          </li>
          <li>Markierung</li>
          <li>Datum der Inbetriebnahme</li>
          <li>Datum der letzten wiederkehrenden Prüfung</li>
          <li>
            Indirekter Lecksuchgerät <i>*3</i>
          </li>
        </ul>

        <p>
          <i>
            *1 : to be chosen from this{" "}
            <a href="#" onClick={this.props.downloadFluids}>
              liste
            </a>
            <br />
            *2 : optional
            <br />
            *3 : 0 = no detector — 1 = presence of a detector
            <br />
          </i>
        </p>

        <p>{this.props.downloadSample}</p>

        <p>
          After integration, a summary will inform you about the number of
          entities created or updated, and will provide an anomaly file
          containing the unintegrated lines, and the reason(s) for the
          rejection.
        </p>

        <p>The possible reasons for anomalies are:</p>

        <ul>
          <li>Absence of mandatory data</li>
          <li>No fluid available</li>
          <li>Value different from 1/0</li>
          <li>Invalid date</li>
        </ul>
      </div>
    );
  }
}
