import I18n from "i18n-js";
import React from "react";
import { func, shape } from "prop-types";
import { connect } from "react-redux";
import { ROUTE_CARRIERS } from "../../../routes";
import FormPage from "../../common/components/layout/FormPage";
import { getCarrierInfos, updateCarrier } from "../actions/carriersActions";
import CarrierForm from "../components/CarrierForm";

class UpdateCarrier extends FormPage {
  static propTypes = {
    getCarrierInfos: func.isRequired,
    updateCarrier: func.isRequired,
    match: shape().isRequired,
    carrier: shape().isRequired,
  };

  constructor() {
    super();

    this.onSubmit = this.onSubmit.bind(this);
  }

  /**
   * {@inheritdoc}
   */
  componentDidMount() {
    const { getCarrierInfos, match } = this.props;

    getCarrierInfos(match.params.uuid);
  }

  /**
   * @param {object} carrier
   */
  onSubmit(carrier) {
    const { updateCarrier, match } = this.props;

    updateCarrier(match.params.uuid, carrier);
  }

  /**
   * {@inheritdoc}
   */
  getTitle() {
    return I18n.t("pages.carriers.update.title");
  }

  /**
   * {@inheritdoc}
   */
  getBackRoute() {
    return ROUTE_CARRIERS;
  }

  /**
   * {@inheritdoc}
   */
  renderContent() {
    const { carrier } = this.props;

    return (
      <CarrierForm
        loading={carrier.saving}
        infos={carrier.infos}
        onSubmit={this.onSubmit}
      />
    );
  }

  /**
   * {@inheritdoc}
   */
  render() {
    const { carrier } = this.props;

    if (carrier.infos === undefined) {
      return <div className="loader" />;
    }

    if (carrier.infos === null) {
      return <p>{I18n.t("pages.carrier.update.unknown")}</p>;
    }

    return super.render();
  }
}

const mapStateToProps = (state) => ({
  carrier: state.carrier,
});

const mapDispatchToProps = (dispatch) => ({
  getCarrierInfos: (uuid) => dispatch(getCarrierInfos(uuid)),
  updateCarrier: (uuid, carrier) => dispatch(updateCarrier(uuid, carrier)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateCarrier);
