import I18n from "i18n-js";
import React, { Component } from "react";
import { func, shape } from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { login } from "../actions/authActions";
import TextInput from "../../common/components/form/TextInput";
import BigButton from "../../common/components/elements/BigButton";
import { ROUTE_CONTACT_US, ROUTE_FORGOT_PASSWORD } from "../../../routes";

class Login extends Component {
  static propTypes = {
    login: func.isRequired,
    auth: shape().isRequired,
  };

  state = {
    errorMessage: null,
  };

  constructor() {
    super();

    this.username = null;
    this.password = null;

    this.onValidate = this.onValidate.bind(this);
    this.setUsername = this.setUsername.bind(this);
    this.setPassword = this.setPassword.bind(this);
  }

  /**
   * {@inheritdoc}
   */
  componentDidUpdate(prevProps) {
    const { auth } = this.props;

    if (prevProps.auth.errorCode !== auth.errorCode) {
      this.setState({ errorMessage: auth.errorCode });
    }
  }

  /**
   * @param {Object} event
   */
  onValidate(event) {
    event.preventDefault();

    if (!this.username.value.length || !this.password.value.length) {
      return;
    }

    this.props.login(this.username.value, this.password.value);
  }

  /**
   * @param {String} username
   */
  setUsername(username) {
    this.username = username;
  }

  /**
   * @param {String} password
   */
  setPassword(password) {
    this.password = password;
  }

  /**
   * {@inheritdoc}
   */
  render() {
    const { errorMessage } = this.state;
    const { auth } = this.props;

    return (
      <div className="login">
        <div className="form">
          <h1 className="text-center">
            <img
              className="logo"
              src="/dist/assets/images/logo.png"
              alt="Clim'app"
            />
            Administration
          </h1>
          <form onSubmit={this.onValidate}>
            <TextInput
              id="email"
              autoComplete="username"
              placeholder={I18n.t("pages.login.username")}
              label={I18n.t("pages.login.username")}
              labelInside
              ref={this.setUsername}
              error={errorMessage !== null}
            />
            <TextInput
              id="password"
              type="password"
              autoComplete="current-password"
              placeholder={I18n.t("pages.login.password")}
              label={I18n.t("pages.login.password")}
              labelInside
              ref={this.setPassword}
              error={
                errorMessage
                  ? I18n.t(`pages.login.error.${errorMessage}`)
                  : null
              }
            />
            <BigButton
              tagName="button:submit"
              loading={auth.loginInProgress}
              label={I18n.t("pages.login.submit")}
            />
          </form>
          <div className="sub-link text-center">
            <Link to={ROUTE_FORGOT_PASSWORD}>
              {I18n.t("pages.login.forgot_password")}
            </Link>
          </div>
        </div>
        <div className="help-link text-center">
          <a href={ROUTE_CONTACT_US}>{I18n.t("pages.login.contact")}</a>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  login: (username, password) => dispatch(login(username, password)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
