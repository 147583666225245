import React, { useState } from "react";
import { bool, func } from "prop-types";
import { useQuery } from "@apollo/client";
import myWasteRecoveryRequests from "../graphql/queries/myWasteRecoveryRequests.graphql";
import I18n from "i18n-js";
import { formatDate } from "../../common/utils/dateUtils";
import Quantity from "../../common/components/elements/Quantity";
import DateInput from "../../common/components/form/DateInput";
import StatelessTextInput from "../../common/components/form/StatelessTextInput";
import WasteRecoveryRequestStatus from "../models/WasteRecoveryRequestStatus";
import { NumberParam, useQueryParam } from "use-query-params";
import { Link } from "react-router-dom";
import {
  ROUTE_WASTE_RECOVERY_REQUEST_STATUS,
  ROUTE_WASTE_RECOVERY_REQUEST_SUMMARY,
  ROUTE_WASTE_RECOVERY_REQUESTS_CREATE,
} from "../../../routes";
import { connect } from "react-redux";
import { clear } from "../actions/createWasteRecoveryRequestActions.js";
import { EnumChoice } from "../../common/components/form/EnumChoice";
import pathToRegexp from "path-to-regexp";
import LoadingWrapper from "../../common/components/elements/LoadingWrapper";
import StatelessTable from "../../common/components/elements/StatelessTable";
import {
  combineOrFilters,
  dateFromFilter,
  greaterThanNumberFilter,
  propertyContainsFilter,
  propertyFilter,
} from "../utils/filters";
import ClientFilter from "../components/wasteRequestsList/filters/ClientFilter";
import SiteFilter from "../components/wasteRequestsList/filters/SiteFilter";
import DeleteRequestAction from "../components/wasteRequestsList/actions/DeleteRequestAction";
import { hasPendingRequest } from "../selectors/createWasteRecoveryRequestSelectors";

export default connect(
  (state) => ({
    hasPendingRequest: hasPendingRequest(state),
  }),
  {
    onCreate: clear,
  },
)(function Page(props) {
  return (
    <div>
      <h1>{I18n.t("pages.my_waste_recovery_requests.list.title")}</h1>
      <MyWasteRecoveryRequestsList {...props} />
    </div>
  );
});

MyWasteRecoveryRequestsList.propTypes = {
  onCreate: func.isRequired,
  hasPendingRequest: bool.isRequired,
};

function MyWasteRecoveryRequestsList(props) {
  const [page, setPage] = useQueryParam("page", NumberParam);
  const [filters, setFilters] = useState({
    client: null,
    site: null,
    date: null,
    weight: 0,
    status: null,
    references: null,
    number: null,
  });

  const { loading, error, data } = useQuery(myWasteRecoveryRequests);

  return (
    <LoadingWrapper loading={loading} error={Boolean(error)}>
      {() => {
        const requests = data.myWasteRecoveryRequests;
        const filteredData = filtered(requests, filters);
        const { onCreate, hasPendingRequest } = props;

        return (
          <StatelessTable
            emptyContent={I18n.t("pages.my_waste_recovery_requests.list.empty")}
            data={filteredData}
            headData={getHeadData()}
            rowData={getRowData}
            filters={getFilters(requests, filters, setFilters)}
            pagination
            onPageChanged={setPage}
            currentPage={page}
            paginationAside={
              <span className="text-primary">
                {I18n.t(
                  "pages.my_waste_recovery_requests.list.pagination_aside",
                  {
                    count: filteredData.length,
                  },
                )}
              </span>
            }
            actions={getActions(onCreate, hasPendingRequest)}
          />
        );
      }}
    </LoadingWrapper>
  );
}

function getHeadData() {
  return [
    /* eslint-disable react/jsx-key */
    I18n.t("pages.my_waste_recovery_requests.head.number"),
    I18n.t("pages.my_waste_recovery_requests.head.authorReference"),
    I18n.t("pages.my_waste_recovery_requests.head.commercialReference"),
    I18n.t("pages.my_waste_recovery_requests.head.client"),
    I18n.t("pages.my_waste_recovery_requests.head.site"),
    I18n.t("pages.my_waste_recovery_requests.head.date"),
    <div className="text-right">
      {I18n.t("pages.my_waste_recovery_requests.head.totalWeight")}
    </div>,
    I18n.t("pages.my_waste_recovery_requests.head.status"),
    null,
    /* eslint-enable react/jsx-key */
  ];
}

function getRowData(request) {
  const {
    site,
    canBeRemoved,
    uuid,
    number,
    authorReference,
    commercialReference,
  } = request;
  const { client } = site;

  const actions = [
    <Link
      to={pathToRegexp.compile(ROUTE_WASTE_RECOVERY_REQUEST_SUMMARY)({
        uuid: request.uuid,
      })}
      className="action"
      key="details"
    >
      <i className="fa fa-eye" aria-hidden="true" />
    </Link>,
    <Link
      to={pathToRegexp.compile(ROUTE_WASTE_RECOVERY_REQUEST_STATUS)({
        uuid: request.uuid,
      })}
      className="action"
      key="status"
    >
      <i className="fa fa-pencil" aria-hidden="true" />
    </Link>,
  ];

  if (canBeRemoved) {
    actions.push(<DeleteRequestAction uuid={uuid} key="delete" />);
  }

  return [
    /* eslint-disable react/jsx-key */
    number,
    authorReference,
    commercialReference,
    client.legalCompanyName,
    `${site.designation} - ${site.address.city}`,
    formatDate(request.transportDate || request.date),
    <Quantity value={request.totalWeight} unit="kg" />,
    I18n.t(WasteRecoveryRequestStatus.readableFor(request.status)),
    actions,
    /* eslint-enable react/jsx-key */
  ];
}

function getActions(onCreate, canResume) {
  return [
    canResume && (
      <Link
        to={ROUTE_WASTE_RECOVERY_REQUESTS_CREATE}
        className="btn btn--secondary mr-2"
        key="resume"
      >
        Reprendre ma demande en cours
      </Link>
    ),
    <Link
      to={ROUTE_WASTE_RECOVERY_REQUESTS_CREATE}
      className="btn btn-primary"
      key="create"
      onClick={onCreate}
    >
      <i className="fa fa-plus" />{" "}
      {I18n.t("pages.my_waste_recovery_requests.list.actions.add")}
    </Link>,
  ].filter(Boolean);
}

function getFilters(requests, filters, setFilters) {
  return [
    /* eslint-disable react/jsx-key */
    <StatelessTextInput
      id="filterByNumber"
      label={I18n.t("pages.my_waste_recovery_requests.list.filters.number")}
      placeholder={I18n.t(
        "pages.my_waste_recovery_requests.list.filters.number:placeholder",
      )}
      onChangedValue={(v) => setFilters({ ...filters, number: v || null })}
      type="number"
      min={0}
      defaultValue={filters.number}
      datalist={Array.from(
        new Set([].concat(...requests.map((r) => r.number))),
      ).sort()}
      optional
    />,
    <StatelessTextInput
      id="filterByReferences"
      label={I18n.t("pages.my_waste_recovery_requests.list.filters.references")}
      placeholder={I18n.t(
        "pages.my_waste_recovery_requests.list.filters.references:placeholder",
      )}
      onChangedValue={(v) => setFilters({ ...filters, references: v || null })}
      defaultValue={filters.references}
      datalist={Array.from(
        new Set(
          []
            .concat(...requests.map((r) => r.commercialReference))
            .concat(...requests.map((r) => r.authorReference)),
        ),
      ).sort()}
      optional
    />,
    <ClientFilter
      requests={requests}
      onChangedValue={(uuid) =>
        setFilters({ ...filters, site: null, client: uuid })
      }
      defaultValue={filters.client}
    />,
    <SiteFilter
      requests={requests}
      onChangedValue={(uuid) => setFilters({ ...filters, site: uuid })}
      defaultValue={filters.site}
      filteredClient={filters.client}
    />,
    <DateInput
      id="filterByDate"
      label={I18n.t("filters.by_date")}
      onChange={(date) =>
        setFilters({ ...filters, date: date ? Date.parse(date) : null })
      }
      defaultValue={filters.date && new Date(Number(filters.date))}
      legend
      optional
      isClearable
    />,
    <StatelessTextInput
      id="filterByWeight"
      label={I18n.t("filters.by_weight")}
      placeholder={I18n.t("filters.by_weight:placeholder")}
      onChangedValue={(v) => setFilters({ ...filters, weight: v || 0 })}
      type="number"
      min={0}
      step="0.01"
      defaultValue={filters.barcode}
      optional
    />,
    <EnumChoice
      id="filterByStatus"
      label={I18n.t("filters.by_status")}
      placeholder={I18n.t("filters.by_status:placeholder")}
      enumClass={WasteRecoveryRequestStatus}
      onChangedValue={(v) => setFilters({ ...filters, status: v })}
      defaultValue={filters.status}
      optional
    />,
    /* eslint-enable react/jsx-key */
  ];
}

function filtered(
  requests,
  { client, site, date, weight, status, references, number },
) {
  return requests
    .filter(propertyFilter((request) => request.site.client.uuid, client))
    .filter(propertyFilter((request) => request.site.uuid, site))
    .filter(
      dateFromFilter((request) => request.transportDate || request.date, date),
    )
    .filter(greaterThanNumberFilter((request) => request.totalWeight, weight))
    .filter(propertyFilter((request) => request.status, status))
    .filter(propertyFilter((request) => request.number, number))
    .filter(
      combineOrFilters(
        propertyContainsFilter(
          (request) => request.commercialReference,
          references,
        ),
        propertyContainsFilter(
          (request) => request.authorReference,
          references,
        ),
      ),
    );
}
