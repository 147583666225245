import React from "react";
import classnames from "classnames";
import CommonTypes from "../../common/propTypes/types";
import PropTypes, { string } from "prop-types";

LifecycleFormOutcome.propTypes = {
  children: CommonTypes.requiredChildren,
  iconClassname: string.isRequired,
};
LifecycleFormOutcome.defaultProps = {};

export function LifecycleFormOutcome({ children, iconClassname, ...props }) {
  return (
    <div className="outcome d-flex flex-column align-items-center" {...props}>
      <i className={classnames("fa outcome-icon m-3", iconClassname)} />
      {children}
    </div>
  );
}

SuccessOutcome.propTypes = {
  children: CommonTypes.requiredChildren,
};

export function SuccessOutcome({ children }) {
  return (
    <LifecycleFormOutcome iconClassname="text-success fa-check-circle">
      <p>Cette demande est close</p>

      {children}
    </LifecycleFormOutcome>
  );
}

export function RejectedOutcome() {
  return (
    <LifecycleFormOutcome iconClassname="text-danger fa-times-circle">
      <p>Cette demande est close</p>
    </LifecycleFormOutcome>
  );
}

WaitingOutcome.propTypes = {
  children: CommonTypes.requiredChildren,
};

export function WaitingOutcome({ children }) {
  return (
    <LifecycleFormOutcome iconClassname="text-muted fa-info-circle">
      {children}
    </LifecycleFormOutcome>
  );
}

DocumentsList.propTypes = {
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      producerName: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      number: PropTypes.number.isRequired,
    }),
  ).isRequired,
};

export function DocumentsList({ documents }) {
  return (
    documents.length > 0 && (
      <div>
        <p>
          Vous trouverez ci-dessous les documents liés à cette demande pour les
          différents producteurs de déchets:
        </p>

        <ul>
          {documents.map((document, i) => {
            const url = new URL(document.url);
            url.searchParams.append(
              "name",
              `${document.name} - ${document.producerName} N° ${document.number}.pdf`,
            );

            return (
              <li key={`doc-${i}`}>
                <a href={url} target="_blank" rel="noopener noreferrer">
                  {document.producerName}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    )
  );
}
