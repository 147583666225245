import GraphQLErrors from "./GraphQLErrors";

/**
 * Extract violations from a graphQlError if compliant.
 */
export function getGraphQLViolations(graphQLErrors) {
  const violations = [];
  (graphQLErrors || []).forEach(({ api_problem, code }) => {
    if (code === GraphQLErrors.INVALID_PAYLOAD) {
      violations.push(...api_problem.violations);
    }
  });

  return violations;
}
