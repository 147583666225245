import I18n from "i18n-js";
import React from "react";
import { func, shape } from "prop-types";
import { connect } from "react-redux";
import FormPage from "../../common/components/layout/FormPage";
import { getInterventionInfo } from "../actions/interventionActions";
import { getLeakDetectorInfos } from "../../detectors/actions/leakDetectorsActions";
import { ROUTE_INTERVENTIONS } from "../../../routes";
import iconLeak from "../../../../assets/images/leak.png";
import iconCheck from "../../../../assets/images/check.png";
import ClientUtils from "../../company/utils/ClientUtils";
import BsffInterventionInformation from "../components/BsffInterventionInformation";

class Intervention extends FormPage {
  static propTypes = {
    leakDetector: shape(),
    intervention: shape(),
    getInterventionInfo: func.isRequired,
    getLeakDetectorInfos: func.isRequired,
    match: shape().isRequired,
  };

  static defaultProps = {
    leakDetector: undefined,
    intervention: undefined,
  };

  /**
   * {@inheritdoc}
   */
  componentDidMount() {
    const { match, intervention } = this.props;
    const { uuid } = match.params;

    if (intervention && intervention.uuid === uuid) {
      return;
    }

    this.props.getInterventionInfo(this.props.match.params.uuid);
  }

  /**
   * {@inheritdoc}
   */
  componentDidUpdate(prevProps) {
    const { intervention } = this.props;

    if (prevProps.intervention === intervention) {
      return;
    }

    if (intervention && intervention.detectorUuid) {
      this.props.getLeakDetectorInfos(intervention.detectorUuid);
    }
  }

  renderContainers() {
    const { containerLoads } = this.props.intervention;

    if (!containerLoads.length) {
      return null;
    }

    return (
      <div>
        <b>{I18n.t("pages.interventions.view.container_loads:label")}</b>
        <ul className="list-container">
          {containerLoads.map(({ load, container }) => (
            <li key={container.uuid} className="list-container__item">
              <span className="fa fa-database" />
              <p>{`${load} kg`}</p>
              <div className="list-container__detail">
                <p>{container.designation}</p>
                <p>
                  {container.competitor
                    ? container.competitor.designation
                    : "Climalife"}
                  {" - "}
                  {container.barcode}
                </p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    );
  }

  renderLeaks() {
    const { leaks } = this.props.intervention;

    if (!leaks.length) {
      return null;
    }

    return (
      <div>
        <b>{I18n.t("pages.interventions.view.leaks:label")}</b>
        <ul className="list-leak">
          {leaks.map(({ uuid, repaired, location, component }) => (
            <li key={uuid} className="list-leak__item">
              {repaired ? (
                <img src={iconCheck} alt="" className="list-leak__icon" />
              ) : (
                <img src={iconLeak} alt="" className="list-leak__icon" />
              )}
              <p className="list-leak__description">
                {location.length
                  ? location
                  : I18n.t("pages.interventions.view.leaks:no_description")}
              </p>
              {component ? (
                <div className="list-leak__details">
                  <p>{component.natureDesignation}</p>
                  <p>{component.mark}</p>
                </div>
              ) : (
                <p className="list-leak__details">
                  {I18n.t("pages.interventions.view.leaks:no_component")}
                </p>
              )}
            </li>
          ))}
        </ul>
      </div>
    );
  }

  /**
   * {@inheritdoc}
   */
  getTitle() {
    return I18n.t("pages.interventions.view.title");
  }

  /**
   * {@inheritdoc}
   */
  getBackRoute() {
    return ROUTE_INTERVENTIONS;
  }

  /**
   * {@inheritdoc}
   */
  renderContent() {
    const { intervention, leakDetector } = this.props;

    if (
      intervention === undefined ||
      (intervention.detectorUuid && !leakDetector)
    ) {
      return <div className="loader" />;
    }

    if (intervention === null) {
      return <p>{I18n.t("common.loadingApiError")}</p>;
    }

    return (
      <div className="card card-block">
        <dl className="row">
          <dt className="col-sm-3">
            {I18n.t("pages.interventions.view.client:label")}
          </dt>
          <dd className="col-sm-9">
            {ClientUtils.renderLabel(intervention.client)}
          </dd>
          <dt className="col-sm-3">
            {I18n.t("pages.interventions.view.site:label")}
          </dt>
          <dd className="col-sm-9">{intervention.site.designation}</dd>
          <dt className="col-sm-3">
            {I18n.t("pages.interventions.view.installation:label")}
          </dt>
          <dd className="col-sm-9">{intervention.installation.designation}</dd>
          {intervention.detectorUuid && [
            <dt key="detector" className="col-sm-3">
              {I18n.t("pages.interventions.view.detector:label")}
            </dt>,
            <dd key="detector-content" className="col-sm-9">
              {leakDetector.barcode}
            </dd>,
          ]}
          <dt className="col-sm-3">
            {I18n.t("pages.interventions.view.record:label")}
          </dt>
          <dd className="col-sm-9">{intervention.record}</dd>
        </dl>
        {this.renderContainers()}
        {this.renderLeaks()}
        <dl className="row">
          <dt className="col-sm-3">
            {I18n.t("pages.interventions.view.observations:label")}
          </dt>
          <dd className="col-sm-9">
            {intervention.observations ||
              I18n.t("pages.interventions.view.observations:empty")}
          </dd>
        </dl>
        <div className="signature">
          <div>
            <b>{I18n.t("pages.interventions.view.client_signature:label")}</b>
            {intervention.clientSignature && (
              <img
                src={intervention.clientSignature}
                alt={I18n.t("pages.interventions.view.client_signature:label")}
              />
            )}
          </div>
          <div>
            <b>{I18n.t("pages.interventions.view.operator_signature:label")}</b>
            <img
              src={intervention.operatorSignature}
              alt={I18n.t("pages.interventions.view.operator_signature:label")}
            />
          </div>
        </div>
        <hr />
        <BsffInterventionInformation interventionUuid={intervention.uuid} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  intervention: state.interventions.info,
  leakDetector: state.leakDetector.infos,
});

const mapDispatchToProps = (dispatch) => ({
  getInterventionInfo: (uuid) => dispatch(getInterventionInfo(uuid)),
  getLeakDetectorInfos: (detectorUuid) =>
    dispatch(getLeakDetectorInfos(detectorUuid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Intervention);
