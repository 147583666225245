import I18n from "i18n-js";
import pathToRegexp from "path-to-regexp";
import React from "react";
import { func, shape } from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getLang } from "../../common/utils/langUtils";
import { alphabetically } from "../../common/utils/filterUtils";
import {
  deleteLeakDetector,
  getLeakDetectorsList,
} from "../actions/leakDetectorsActions";
import List from "../../common/components/layout/List";
import Table from "../../common/components/elements/Table";
import TextInput from "../../common/components/form/TextInput";
import Select from "../../common/components/form/Select";
import DateInput from "../../common/components/form/DateInput";
import ClientUtils from "../../company/utils/ClientUtils";
import { getClientsList } from "../../company/actions/clientsActions";
import {
  ROUTE_LEAK_DETECTOR,
  ROUTE_LEAK_DETECTOR_UPDATE,
  ROUTE_LEAK_DETECTORS_ADD,
} from "../../../routes";

class LeakDetectorsList extends List {
  static propTypes = {
    leakDetectors: shape().isRequired,
    clients: shape().isRequired,
    getLeakDetectorsList: func.isRequired,
    getClientsList: func.isRequired,
    deleteLeakDetector: func.isRequired,
    location: shape().isRequired,
  };

  constructor() {
    super();

    this.leakDetectorRoute = pathToRegexp.compile(ROUTE_LEAK_DETECTOR);
    this.leakDetectorUpdateRoute = pathToRegexp.compile(
      ROUTE_LEAK_DETECTOR_UPDATE,
    );

    this.dataTableRow = this.dataTableRow.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }

  /**
   * {@inheritdoc}
   */
  componentDidMount() {
    super.componentDidMount();

    this.props.getLeakDetectorsList();
    this.props.getClientsList();
  }

  onDelete(uuid) {
    // eslint-disable-next-line no-alert
    if (!window.confirm(I18n.t("pages.leak_detectors.confirm.delete"))) {
      return;
    }

    this.props.deleteLeakDetector(uuid);
  }

  /**
   * @return {Array}
   */
  dataTableHead() {
    return [
      I18n.t("pages.leak_detectors.head.client"),
      I18n.t("pages.leak_detectors.head.serial_number"),
      I18n.t("pages.leak_detectors.head.mark"),
      I18n.t("pages.leak_detectors.head.barcode"),
      I18n.t("pages.leak_detectors.head.last_inspection"),
      I18n.t("pages.leak_detectors.head.validity"),
      I18n.t("pages.leak_detectors.head.designation"),
      I18n.t("pages.leak_detectors.head.actions"),
    ];
  }

  /**
   * @param {Object} rowData
   *
   * @return {Array}
   */
  dataTableRow(rowData) {
    const lastInspection = new Date(
      rowData.lastInspectionDate,
    ).toLocaleDateString(getLang());
    const expirationDate = new Date(rowData.expirationDate).toLocaleDateString(
      getLang(),
    );
    const currentDate = new Date();
    const owner =
      this.props.clients.list.find(
        (client) => client.uuid === rowData.clientUuid,
      ) || null;
    const expired = currentDate > new Date(rowData.expirationDate);
    const expiration = (
      <div className={`text-${expired ? "danger" : "success"}`}>
        <i
          className={`fa fa-${expired ? "times" : "check"}`}
          aria-hidden="true"
          title={expirationDate}
        />
      </div>
    );

    const actions = (
      <div>
        <Link
          to={this.leakDetectorRoute({ uuid: rowData.uuid })}
          className="action"
        >
          <i className="fa fa-eye" aria-hidden="true" />
        </Link>
        <Link
          to={this.leakDetectorUpdateRoute({ uuid: rowData.uuid })}
          className="action"
        >
          <i className="fa fa-pencil" aria-hidden="true" />
        </Link>
        <button
          onClick={() => this.onDelete(rowData.uuid)}
          className="action danger"
        >
          <i className="fa fa-trash" aria-hidden="true" />
        </button>
      </div>
    );

    return [
      owner ? ClientUtils.renderLabel(owner) : I18n.t("common.unknown"),
      rowData.serialNumber,
      rowData.mark,
      rowData.barcode,
      lastInspection,
      expiration,
      rowData.designation,
      actions,
    ];
  }

  /**
   * {@inheritdoc}
   */
  renderFilters() {
    const { clients } = this.props;
    const { filters } = this.state;

    return [
      <Select
        key="filterByClient"
        id="filterByClient"
        defaultValue={filters.client}
        onChange={(event) => this.onChangeFilter(event, "client")}
        label={I18n.t("filters.by_client")}
        renderOption={(option) => (
          <option key={option.uuid} value={option.uuid}>
            {ClientUtils.renderLabel(option)}
          </option>
        )}
        options={clients.list.sort((a, b) =>
          alphabetically(
            ClientUtils.renderLabel(a),
            ClientUtils.renderLabel(b),
          ),
        )}
        placeholder={I18n.t("filters.by_client:default")}
        optional
      />,
      <TextInput
        key="filterBySerialNumber"
        id="filterBySerialNumber"
        defaultValue={filters.serialNumber}
        onChange={(event) => this.onChangeFilter(event, "serialNumber")}
        label={I18n.t("filters.by_serial_number")}
        optional
      />,
      <TextInput
        key="filterByMark"
        id="filterByMark"
        defaultValue={filters.mark}
        onChange={(event) => this.onChangeFilter(event, "mark")}
        label={I18n.t("filters.by_mark")}
        optional
      />,
      <TextInput
        key="filterByBarcode"
        id="filterByBarcode"
        defaultValue={filters.barcode}
        onChange={(event) => this.onChangeFilter(event, "barcode")}
        label={I18n.t("filters.by_barcode")}
        optional
      />,
      <DateInput
        key="filterByStartDate"
        id="filterByStartDate"
        defaultValue={filters.startDate && new Date(Number(filters.startDate))}
        onChange={(event) => this.onChangeDateFilter(event, "startDate")}
        label={I18n.t("filters.by_start_date")}
        legend
        optional
      />,
      <DateInput
        key="filterByEndDate"
        id="filterByEndDate"
        defaultValue={filters.endDate && new Date(Number(filters.endDate))}
        onChange={(event) => this.onChangeDateFilter(event, "endDate")}
        label={I18n.t("filters.by_end_date")}
        legend
        optional
      />,
    ];
  }

  /**
   * {@inheritdoc}
   */
  renderContent() {
    const { leakDetectors, clients } = this.props;

    if (clients.inProgress || leakDetectors.inProgress) {
      return <div className="loader" />;
    }

    if (clients.error || leakDetectors.error) {
      return <p>{I18n.t("common.loadingApiError")}</p>;
    }

    const actions = (
      <Link to={ROUTE_LEAK_DETECTORS_ADD} className="btn btn-primary">
        {I18n.t("pages.leak_detectors.list.actions.add")}
      </Link>
    );

    const list = leakDetectors.list
      .filter(this.getFilterBySelect("client"))
      .filter(this.getFilterByText("serialNumber"))
      .filter(this.getFilterByText("barcode"))
      .filter(this.getFilterByText("mark"))
      .filter(this.getFilterByDate("lastInspectionDate"))
      .sort((a, b) => {
        const clientA = clients.list.find(
          (client) => client.uuid === a.clientUuid,
        );
        const clientB = clients.list.find(
          (client) => client.uuid === b.clientUuid,
        );

        return (
          alphabetically(clientA.legalCompanyName, clientB.legalCompanyName) ||
          alphabetically(a.serialNumber, b.serialNumber)
        );
      });

    return (
      <Table
        emptyContent={I18n.t("pages.leak_detectors.list.empty")}
        data={list}
        headData={this.dataTableHead()}
        rowData={this.dataTableRow}
        filters={this.renderFilters()}
        pagination
        onPageChanged={this.onPageChanged}
        initialPage={this.getCurrentPage()}
        actions={actions}
      />
    );
  }

  /**
   * {@inheritdoc}
   */
  renderTitle() {
    return I18n.t("pages.leak_detectors.list.title");
  }
}

const mapStateToProps = (state) => ({
  leakDetectors: state.leakDetectors,
  clients: state.clients,
});

const mapDispatchToProps = (dispatch) => ({
  getLeakDetectorsList: () => dispatch(getLeakDetectorsList()),
  getClientsList: () => dispatch(getClientsList()),
  deleteLeakDetector: (uuid) => dispatch(deleteLeakDetector(uuid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LeakDetectorsList);
