/**
 * Extract a specific header attribute.
 * E.g: from Headers containing 'Content-Disposition: attachment; filename="foo.tsv"',
 * extractHeaderAttribute(headers, 'Content-Disposition', 'filename') will return "foo.tsv".
 */
export function extractHeaderAttribute(headers, key, attribute) {
  // https://regex101.com/r/hJ7tS6/51
  const regex = new RegExp(
    `${attribute}[^;=\\n]*=(?:(\\\\?['"])(.*?)\\1|(?:[^\\s]+'.*?')?([^;\\n]*))`,
  );
  const header = headers.get(key);
  const result = header && regex.exec(header);

  return result[2] || null;
}
