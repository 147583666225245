import I18n from "i18n-js";
import { fetch } from "../../../appContainer";
import {
  GET_CARRIERS_LIST_IN_PROGRESS,
  GET_CARRIERS_LIST_SUCCESS,
  GET_CARRIERS_LIST_FAIL,
  GET_CARRIER_INFOS_IN_PROGRESS,
  GET_CARRIER_INFOS_SUCCESS,
  GET_CARRIER_INFOS_FAIL,
  POST_CARRIER_IN_PROGRESS,
  POST_CARRIER_SUCCESS,
  POST_CARRIER_FAIL,
  PUT_CARRIER_IN_PROGRESS,
  PUT_CARRIER_SUCCESS,
  PUT_CARRIER_FAIL,
  DELETE_CARRIER_IN_PROGRESS,
  DELETE_CARRIER_SUCCESS,
  DELETE_CARRIER_FAIL,
  RESET_CARRIER,
} from "../constants";
import { SUBMIT_SUCCESS, SUBMIT_FAIL } from "../../common/constants";

/**
 * Get carriers list
 */
export function getCarriersList() {
  return (dispatch) => {
    dispatch({ type: GET_CARRIERS_LIST_IN_PROGRESS });

    fetch("api").getCarriersList(
      (list) => {
        dispatch({ type: GET_CARRIERS_LIST_SUCCESS, payload: { list } });
      },
      () => dispatch({ type: GET_CARRIERS_LIST_FAIL }),
    );
  };
}

/**
 * Get carrier infos
 */
export function getCarrierInfos(uuid) {
  return (dispatch) => {
    dispatch({ type: GET_CARRIER_INFOS_IN_PROGRESS });

    fetch("api").getCarrierInfos(
      uuid,
      (infos) => {
        dispatch({ type: GET_CARRIER_INFOS_SUCCESS, payload: { infos } });
      },
      () => dispatch({ type: GET_CARRIER_INFOS_FAIL }),
    );
  };
}

/**
 * Create carrier
 */
export function createCarrier(carrier) {
  return (dispatch) => {
    dispatch({ type: POST_CARRIER_IN_PROGRESS });

    fetch("api").createCarrier(
      carrier,
      () => {
        const message = I18n.t("alerts.create:carrier.success");

        dispatch({ type: SUBMIT_SUCCESS, payload: { message } });
        dispatch({ type: POST_CARRIER_SUCCESS });
      },
      (error) => {
        const message = I18n.t("alerts.create:carrier.fail");

        dispatch({ type: SUBMIT_FAIL, payload: { message } });
        dispatch({
          type: POST_CARRIER_FAIL,
          payload: { violations: error.violations },
        });
      },
    );
  };
}

/**
 * Update carrier
 */
export function updateCarrier(uuid, carrier) {
  return (dispatch) => {
    dispatch({ type: PUT_CARRIER_IN_PROGRESS });

    fetch("api").updateCarrier(
      uuid,
      carrier,
      () => {
        const message = I18n.t("alerts.update:carrier.success");

        dispatch({ type: SUBMIT_SUCCESS, payload: { message } });
        dispatch({ type: PUT_CARRIER_SUCCESS });
      },
      (error) => {
        const message = I18n.t("alerts.update:carrier.fail");

        dispatch({ type: SUBMIT_FAIL, payload: { message } });
        dispatch({
          type: PUT_CARRIER_FAIL,
          payload: { violations: error.violations },
        });
      },
    );
  };
}

/**
 * Delete carrier
 */
export function deleteCarrier(uuid) {
  return (dispatch) => {
    dispatch({ type: DELETE_CARRIER_IN_PROGRESS });

    fetch("api").deleteCarrier(
      uuid,
      () => {
        const message = I18n.t("alerts.delete:carrier.success");

        dispatch({ type: SUBMIT_SUCCESS, payload: { message } });
        dispatch(getCarriersList());
        dispatch({ type: DELETE_CARRIER_SUCCESS });
      },
      () => {
        const message = I18n.t("alerts.delete:carrier.fail");

        dispatch({ type: DELETE_CARRIER_FAIL });
        dispatch({ type: SUBMIT_FAIL, payload: { message } });
      },
    );
  };
}

/**
 * Reset carrier
 */
export function resetCarrier() {
  return (dispatch) => dispatch({ type: RESET_CARRIER });
}
