import { fetch } from "../../../appContainer";
import {
  GET_FLUID_SUMMARIES_LIST_IN_PROGRESS,
  GET_FLUID_SUMMARIES_LIST_SUCCESS,
  GET_FLUID_SUMMARIES_LIST_FAIL,
  UPDATE_FLUID_SUMMARIES_LIST_IN_PROGRESS,
  UPDATE_FLUID_SUMMARIES_LIST_SUCCESS,
  UPDATE_FLUID_SUMMARIES_LIST_FAIL,
} from "../constants";

/**
 * Get fluid usage summaries list
 *
 * @param {Date} startDate
 * @param {Date} endDate
 */
export function getFluidSummariesList(startDate, endDate) {
  return (dispatch) => {
    dispatch({ type: GET_FLUID_SUMMARIES_LIST_IN_PROGRESS });

    fetch("api").getFluidSummariesList(
      startDate,
      endDate,
      (list) => {
        dispatch({ type: GET_FLUID_SUMMARIES_LIST_SUCCESS, payload: { list } });
      },
      () => dispatch({ type: GET_FLUID_SUMMARIES_LIST_FAIL }),
    );
  };
}

/**
 * update fluid usage summaries list
 *
 * @param {Date} startDate
 * @param {Date} endDate
 */
export function updateFluidSummariesList(startDate, endDate) {
  return (dispatch) => {
    dispatch({ type: UPDATE_FLUID_SUMMARIES_LIST_IN_PROGRESS });

    fetch("api").getFluidSummariesList(
      startDate,
      endDate,
      (list) => {
        dispatch({
          type: UPDATE_FLUID_SUMMARIES_LIST_SUCCESS,
          payload: { list },
        });
      },
      () => dispatch({ type: UPDATE_FLUID_SUMMARIES_LIST_FAIL }),
    );
  };
}
