import I18n from "i18n-js";
import React, { createRef } from "react";
import { func, shape, bool } from "prop-types";
import { connect } from "react-redux";
import PreviewableImage from "../../common/components/form/PreviewableImage";
import Form from "../../common/components/form/Form";
import TextInput from "../../common/components/form/TextInput";
import Select from "../../common/components/form/Select";
import Fieldset from "../../common/components/form/Fieldset";
import SubmitButton from "../../common/components/form/SubmitButton";
import ClientTypes from "../models/ClientTypes";
import { alphabetically } from "../../common/utils/filterUtils";
import Radio from "../../common/components/form/Radio";
import ClientUtils from "../utils/ClientUtils";
import { getClientsList } from "../actions/clientsActions";
import CompanyCountry from "../models/CompanyCountry";

class SiteForm extends Form {
  static propTypes = {
    clients: shape().isRequired,
    getClientsList: func.isRequired,
    site: shape().isRequired,
    infos: shape(),
    loading: bool,
    mobile: bool,
    onSubmit: func.isRequired,
    goBack: func.isRequired,
  };

  static defaultProps = {
    mobile: false,
    infos: {
      address: null,
      contact: null,
    },
    loading: false,
  };

  constructor(props) {
    super(props);

    this.updateMod = !!props.infos.uuid;

    this.state = {
      ...this.state,
      siteType: this.getSiteType(this.props.infos),
      selectedClient: null,
      contactError: null,
    };

    // Define all inputs form
    this.inputs.designation = createRef();
    this.inputs.street = createRef();
    this.inputs.addressAddition = createRef();
    this.inputs.city = createRef();
    this.inputs.country = createRef();
    this.inputs.postal = createRef();
    this.inputs.client = createRef();
    this.inputs.siteResponsibleSignature = createRef();
    this.inputs.siteResponsibleName = createRef();
    this.inputs.lastname = createRef();
    this.inputs.firstname = createRef();
    this.inputs.email = createRef();
    this.inputs.externalId = createRef();
    this.inputs.siret = createRef();

    this.onChangeClient = this.onChangeClient.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.renderClientOption = this.renderClientOption.bind(this);
    this.isValidSiteTypeForClient = this.isValidSiteTypeForClient.bind(this);
    this.onChangeSiteType = this.onChangeSiteType.bind(this);
  }

  /**
   * {@inheritdoc}
   */
  componentDidMount() {
    this.props.getClientsList();
  }

  /**
   * {@inheritdoc}
   */
  componentDidUpdate(prevProps) {
    const { infos, site, clients, goBack } = this.props;

    if (
      clients.list.length &&
      clients.list !== prevProps.clients.list &&
      this.updateMod
    ) {
      const selectedClient = clients.list.find(
        (client) => infos.client.uuid === client.uuid,
      );

      this.setState({ selectedClient });
    }

    if (
      prevProps.site.saved === site.saved &&
      prevProps.site.errors === site.errors
    ) {
      return;
    }

    if (site.errors) {
      this.setState({ errors: site.errors });
    }

    if (site.saved) {
      goBack();
    }
  }

  /**
   * onChange client select
   *
   * @param {Object} event
   */
  onChangeClient(event) {
    const uuid = event.target.value;
    const { clients } = this.props;
    const selectedClient = clients.list.find((client) => client.uuid === uuid);

    if (selectedClient) {
      this.setState(this.recomputeStateForClient(selectedClient));
    }
  }

  onChangeSiteType(siteType) {
    this.setState({ siteType });
  }

  isFormValid() {
    const { selectedClient, siteType } = this.state;
    const errors = [];

    if (
      ["warehouse", "treatment"].includes(siteType) &&
      !this.getInputValue("siteResponsibleName")
    ) {
      errors.push({ path: "siteResponsibleName", reason: true });
    }

    if (
      selectedClient &&
      !this.isValidSiteTypeForClient(
        siteType,
        selectedClient.type,
        selectedClient.distributor,
      )
    ) {
      errors.push({
        path: "siteType",
        reason: I18n.t("pages.sites.create.form.site_type:error"),
      });
    }

    this.setState({ errors });

    return super.isFormValid() && errors.length === 0;
  }

  /**
   * onSubmit form
   */
  onSubmit() {
    if (!this.isFormValid()) {
      return;
    }

    const { siteType } = this.state;
    const { mobile, infos } = this.props;
    const site = {
      externalId: this.getInputValue("externalId") || infos.externalId,
      designation: this.getInputValue("designation"),
      address: mobile
        ? null
        : {
            country: this.getInputValue("country"),
            city: this.getInputValue("city"),
            postal: this.getInputValue("postal"),
            street: this.getInputValue("street"),
            addressAddition: this.getInputValue("addressAddition"),
          },
      siteResponsibleSignature: this.getInputValue("siteResponsibleSignature"),
      siteResponsibleName: this.getInputValue("siteResponsibleName"),
      treatmentSite: mobile ? false : siteType === "treatment",
      warehouse: mobile ? false : siteType === "warehouse",
      siret: !mobile ? this.getInputValue("siret") : null,
    };

    const email = this.getInputValue("email");
    const lastname = this.getInputValue("lastname");
    const firstname = this.getInputValue("firstname");

    // If has contact info:
    if (email || lastname || firstname) {
      site.contact = { lastname, firstname, email };
    }

    if (!this.updateMod) {
      site.clientUuid = this.getInputValue("client");
    }

    this.props.onSubmit(site);
  }

  getSiteType(site) {
    if (!site) {
      return "none";
    }

    if (site.treatmentSite) {
      return "treatment";
    }

    if (site.warehouse) {
      return "warehouse";
    }

    return "none";
  }

  getSiteTypeChoices() {
    const clientType =
      this.state.selectedClient && this.state.selectedClient.type;
    const distributor =
      this.state.selectedClient && this.state.selectedClient.distributor;

    return [
      {
        label: I18n.t("pages.sites.create.form.site_type:treatment"),
        value: "treatment",
        disabled: !this.isValidSiteTypeForClient(
          "treatment",
          clientType,
          distributor,
        ),
      },
      {
        label: I18n.t("pages.sites.create.form.site_type:warehouse"),
        value: "warehouse",
        disabled: !this.isValidSiteTypeForClient(
          "warehouse",
          clientType,
          distributor,
        ),
      },
      {
        label: I18n.t("pages.sites.create.form.site_type:none"),
        value: "none",
        disabled: !this.isValidSiteTypeForClient(
          "none",
          clientType,
          distributor,
        ),
      },
    ];
  }

  recomputeStateForClient(selectedClient) {
    const newState = { selectedClient };
    const { siteType } = this.state;

    // Recompute the site type:
    if (
      !this.isValidSiteTypeForClient(
        siteType,
        selectedClient.type,
        selectedClient.distributor,
      )
    ) {
      // For a distributor, warehouse is the only choice
      newState.siteType = selectedClient.distributor ? "warehouse" : "none";
    }

    return newState;
  }

  isValidSiteTypeForClient(siteType, clientType, distributor) {
    switch (siteType) {
      case "none":
        return clientType !== ClientTypes.COMPANY && !distributor;
      case "treatment":
        return !distributor;
      case "warehouse":
        return true;
      default:
        return true;
    }
  }

  /**
   * @param {Object} option
   *
   * @return {Element}
   */
  renderClientOption(option) {
    return (
      <option key={`option-${option.uuid}`} value={option.uuid}>
        {ClientUtils.renderLabel(option)}
      </option>
    );
  }

  /**
   * Render clients select
   *
   * @return {Element}
   */
  renderClientsSelect() {
    const { clients, infos } = this.props;

    const options = clients.list.sort((a, b) =>
      alphabetically(ClientUtils.renderLabel(a), ClientUtils.renderLabel(b)),
    );

    return (
      <Select
        id="client"
        label={I18n.t("pages.sites.create.form.client:label")}
        ref={this.inputs.client}
        options={options}
        renderOption={this.renderClientOption}
        disabled={this.updateMod}
        defaultValue={(this.updateMod && infos.client.uuid) || ""}
        onChange={this.onChangeClient}
        loadingError={Boolean(clients.error)}
        loading={clients.inProgress}
      />
    );
  }

  /**
   * Render SiteResponsibleName input
   *
   * @returns {Element}
   */
  renderSiteResponsibleName() {
    return (
      ["warehouse", "treatment"].includes(this.state.siteType) && (
        <TextInput
          id="siteResponsibleName"
          label={I18n.t("pages.sites.create.form.siteResponsibleName:label")}
          defaultValue={this.props.infos.siteResponsibleName}
          ref={this.inputs.siteResponsibleName}
          error={this.getInputErrors("siteResponsibleName")}
        />
      )
    );
  }

  /**
   * Render SiteResponsibleSignature input
   *
   * @returns {Element}
   */
  renderSiteResponsibleSignature() {
    return (
      ["warehouse", "treatment"].includes(this.state.siteType) && (
        <PreviewableImage
          id="siteResponsibleSignature"
          label={I18n.t(
            "pages.sites.create.form.siteResponsibleSignature:label",
          )}
          ref={this.inputs.signature}
          defaultValue={this.props.infos.siteResponsibleSignature}
          accept={["image/png"]}
        />
      )
    );
  }

  renderContactInfos() {
    const { infos } = this.props;

    return (
      <Fieldset title={I18n.t("pages.sites.create.form.fieldset:title")}>
        <TextInput
          id="lastname"
          label={I18n.t("pages.sites.create.form.lastname:label")}
          ref={this.inputs.lastname}
          defaultValue={infos.contact && infos.contact.lastname}
          optional
          error={this.getInputErrors("contact")}
        />
        <TextInput
          id="firstname"
          label={I18n.t("pages.sites.create.form.firstname:label")}
          ref={this.inputs.firstname}
          defaultValue={infos.contact && infos.contact.firstname}
          optional
        />
        <TextInput
          id="email"
          type="email"
          label={I18n.t("pages.sites.create.form.email:label")}
          ref={this.inputs.email}
          defaultValue={infos.contact && infos.contact.email}
          optional
        />
      </Fieldset>
    );
  }

  renderAddressFields() {
    const { infos } = this.props;

    return (
      <div>
        <TextInput
          id="street"
          label={I18n.t("pages.sites.create.form.street:label")}
          defaultValue={infos.address && infos.address.street}
          ref={this.inputs.street}
          error={this.getInputErrors("street")}
        />
        <TextInput
          id="addressAddition"
          label={I18n.t("pages.sites.create.form.addressAddition:label")}
          defaultValue={infos.address && infos.address.addressAddition}
          ref={this.inputs.addressAddition}
          optional
        />
        <TextInput
          id="city"
          label={I18n.t("pages.sites.create.form.city:label")}
          defaultValue={infos.address && infos.address.city}
          ref={this.inputs.city}
          error={this.getInputErrors("city")}
        />
        <TextInput
          id="postal"
          label={I18n.t("pages.sites.create.form.postal:label")}
          defaultValue={infos.address && infos.address.postal}
          ref={this.inputs.postal}
          error={this.getInputErrors("postal")}
        />
        <TextInput
          id="country"
          label={I18n.t("pages.sites.create.form.country:label")}
          defaultValue={infos.address && infos.address.country}
          ref={this.inputs.country}
          error={this.getInputErrors("country")}
        />
        {this.renderSiteResponsibleName()}
        {this.renderSiteResponsibleSignature()}
        {this.renderContactInfos()}
      </div>
    );
  }

  /**
   * {@inheritdoc}
   */
  render() {
    const { loading, infos, mobile, userProfile } = this.props;
    const { siteType, selectedClient } = this.state;
    const companyCountry = new CompanyCountry(userProfile.companyCountry);
    const isIndividual = selectedClient?.individual && !selectedClient?.siret;

    return (
      <form>
        {this.renderClientsSelect()}
        {!mobile && (
          <TextInput
            id="externalId"
            defaultValue={infos.externalId}
            label={I18n.t("pages.sites.create.form.externalId:label")}
            ref={this.inputs.externalId}
            error={this.getInputErrors("externalId")}
            optional
          />
        )}
        {!mobile && companyCountry.usesTrackdechets() && (
          <TextInput
            id="siret"
            label={I18n.t("pages.sites.create.form.siret:label")}
            defaultValue={infos.siret}
            ref={this.inputs.siret}
            error={this.getInputErrors("siret")}
            optional={isIndividual}
          />
        )}
        <TextInput
          id="designation"
          label={I18n.t("pages.sites.create.form.designation:label")}
          defaultValue={infos.designation}
          ref={this.inputs.designation}
          error={this.getInputErrors("designation")}
        />
        {!mobile && (
          <Radio
            name="siteType"
            choices={this.getSiteTypeChoices()}
            onChange={this.onChangeSiteType}
            value={siteType}
            error={this.getInputErrors("siteType")}
          />
        )}
        {!mobile && this.renderAddressFields()}
        <SubmitButton loading={loading} onClick={this.onSubmit} />
      </form>
    );
  }
}

export default connect(
  (state) => ({
    clients: state.clients,
    site: state.site,
    userProfile: state.auth.userProfile,
  }),
  (dispatch) => ({
    getClientsList: () => dispatch(getClientsList()),
  }),
)(SiteForm);
