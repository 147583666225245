import I18n from "i18n-js";
import React from "react";
import { func } from "prop-types";
import { Route } from "react-router-dom";
import SelectLanguage from "../../components/layout/SelectLanguage";
import { ROUTE_HOME, ROUTE_LOGIN } from "../../../../routes";
import { goBack } from "../../utils/navigationUtils";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { useAuth } from "../../../authentication/contexts/useAuth";
import { logout } from "../../../authentication/actions/authActions";

PublicRoute.propTypes = {
  component: func.isRequired,
  onBack: func,
};

PublicRoute.defaultProps = {
  onBack: () => {},
};

/**
 * Public route
 * Custom react-router route
 */
export default function PublicRoute({ component: Component, onBack, ...rest }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { logged } = useAuth();

  const _logout = () => {
    dispatch(logout());
    history.push(ROUTE_LOGIN);
  };

  return (
    <Route
      {...rest}
      render={(props) => (
        <div className="public">
          <button
            className="btn btn--primary back"
            onClick={() => {
              goBack(history, ROUTE_HOME);
              onBack();
            }}
          >
            {I18n.t("common.back")}
          </button>
          {logged && (
            <button
              type="button"
              className="ml-2 btn btn-outline-dark back"
              onClick={_logout}
            >
              <i className="fa fa-power-off" /> {I18n.t("common.logout")}
            </button>
          )}
          <SelectLanguage tagName="div" />
          <section className="min-vh-100">
            <Component {...props} />
          </section>
        </div>
      )}
    />
  );
}
