import I18n from "i18n-js";
import React, { createRef } from "react";
import { bool, func, shape, string, arrayOf } from "prop-types";
import Form from "../../common/components/form/Form";
import PreviewableImage from "../../common/components/form/PreviewableImage";
import SubmitButton from "../../common/components/form/SubmitButton";
import TextInput from "../../common/components/form/TextInput";

export default class EnterpriseForm extends Form {
  static propTypes = {
    loading: bool,
    onSubmit: func.isRequired,
    enterprise: shape({
      logo: string,
      wasteCollectorRegistryCode: string,
      wasteProducerRegistryCode: string,
    }).isRequired,
    goBack: func.isRequired,
    errors: arrayOf(shape()),
    saved: bool.isRequired,
  };

  static defaultProps = {
    loading: false,
    errors: null,
  };

  constructor(props) {
    super(props);
    this.inputs.logo = createRef();
    this.inputs.wasteCollectorRegistryCode = createRef();
    this.inputs.wasteProducerRegistryCode = createRef();

    this.onSubmit = this.onSubmit.bind(this);
  }

  /**
   * {@inheritdoc}
   */
  componentDidUpdate(prevProps) {
    const { goBack, errors, saved } = this.props;

    if (prevProps.errors === errors && prevProps.saved === saved) {
      return;
    }

    if (errors) {
      this.setState({ errors });
    }

    if (saved) {
      goBack();
    }
  }

  isFormValid() {
    const errors = [];

    this.setState({ errors });

    return super.isFormValid();
  }

  onSubmit() {
    if (!this.isFormValid()) {
      return;
    }

    this.props.onSubmit({
      logo: this.getInputValue("logo"),
      wasteCollectorRegistryCode: this.getInputValue(
        "wasteCollectorRegistryCode",
      ),
      wasteProducerRegistryCode: this.getInputValue(
        "wasteProducerRegistryCode",
      ),
    });
  }

  render() {
    const { loading, enterprise } = this.props;

    return (
      <form>
        <PreviewableImage
          id="enterpriseLogo"
          label={I18n.t("pages.my_enterprise.update.form.logo:label")}
          ref={this.inputs.logo}
          accept={["image/*"]}
          defaultValue={enterprise.logo}
          error={this.getInputErrors("logo")}
          optional
          inline
        />
        <TextInput
          id="wasteCollectorRegistryCode"
          label={I18n.t(
            "pages.my_enterprise.update.form.wasteCollectorRegistryCode:label",
          )}
          defaultValue={enterprise.wasteCollectorRegistryCode}
          ref={this.inputs.wasteCollectorRegistryCode}
          error={this.getInputErrors("wasteCollectorRegistryCode")}
          optional
        />
        <TextInput
          id="wasteProducerRegistryCode"
          label={I18n.t(
            "pages.my_enterprise.update.form.wasteProducerRegistryCode:label",
          )}
          defaultValue={enterprise.wasteProducerRegistryCode}
          ref={this.inputs.wasteProducerRegistryCode}
          error={this.getInputErrors("wasteProducerRegistryCode")}
          optional
        />
        <SubmitButton loading={loading} onClick={this.onSubmit} />
      </form>
    );
  }
}
