import I18n from "i18n-js";
import pathToRegexp from "path-to-regexp";
import React from "react";
import { arrayOf, shape, func } from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getLang } from "../../common/utils/langUtils";
import { getCarriersList, deleteCarrier } from "../actions/carriersActions";
import { alphabetically } from "../../common/utils/filterUtils";
import TextInput from "../../common/components/form/TextInput";
import List from "../../common/components/layout/List";
import CompanyCountry from "../../company/models/CompanyCountry";
import { ROUTE_CARRIER, ROUTE_CARRIERS_ADD } from "../../../routes";
import { CompanySyncStatusesTable } from "../../trackdechets/components/CompanySyncStatusesTable";

class CarriersList extends List {
  static propTypes = {
    carriers: shape().isRequired,
    carriersList: arrayOf(shape).isRequired,
    userProfile: shape().isRequired,
    getCarriersList: func.isRequired,
    deleteCarrier: func.isRequired,
  };

  constructor() {
    super();
    this.carrierRoute = pathToRegexp.compile(ROUTE_CARRIER);

    this.dataTableRow = this.dataTableRow.bind(this);
  }

  /**
   * {@inheritdoc}
   */
  componentDidMount() {
    super.componentDidMount();

    this.props.getCarriersList();
  }

  /**
   * @param {String}
   */
  onDelete(uuid) {
    // eslint-disable-next-line no-alert
    if (!window.confirm(I18n.t("pages.carriers.confirm.delete"))) {
      return;
    }

    this.props.deleteCarrier(uuid);
  }

  /**
   * @return {Array}
   */
  dataTableHead() {
    const { userProfile } = this.props;
    const companyCountry = new CompanyCountry(userProfile.companyCountry);

    return [
      I18n.t("pages.carriers.head.company_name"),
      companyCountry.needsSiret()
        ? I18n.t("pages.carriers.head.siret_number")
        : I18n.t("pages.carriers.head.vatCode"),
      I18n.t("pages.carriers.head.address"),
      I18n.t("pages.carriers.head.expiration"),
      I18n.t("pages.carriers.head.actions"),
    ];
  }

  /**
   * @param {Object} rowData
   *
   * @return {Array}
   */
  dataTableRow(rowData) {
    const { userProfile } = this.props;
    const companyCountry = new CompanyCountry(userProfile.companyCountry);
    const address = (
      <span>
        {rowData.address.street}
        <br />
        {rowData.address.postal},{rowData.address.city}
        <br />
        {rowData.address.country}
      </span>
    );

    const expirationDate = new Date(
      rowData.transportAuthorization.expirationDate,
    ).toLocaleDateString(getLang());
    const currentDate = new Date();
    const expired =
      currentDate > new Date(rowData.transportAuthorization.expirationDate);
    const expiration = (
      <div className={`text-${expired ? "danger" : "success"}`}>
        <i
          className={`fa fa-${expired ? "times" : "check"}`}
          aria-hidden="true"
          title={expirationDate}
        />
      </div>
    );

    const actions = (
      <div>
        <Link to={this.carrierRoute({ uuid: rowData.uuid })} className="action">
          <i className="fa fa-pencil" aria-hidden="true" />
        </Link>
        <button
          onClick={() => this.onDelete(rowData.uuid)}
          className="action danger"
        >
          <i className="fa fa-trash" aria-hidden="true" />
        </button>
      </div>
    );

    return [
      rowData.legalCompanyName,
      companyCountry.needsSiret() ? rowData.siret : rowData.vatCode,
      address,
      expiration,
      actions,
    ];
  }

  /**
   * {@inheritdoc}
   */
  renderContent() {
    const { carriers, carriersList } = this.props;

    if (carriers.inProgress) {
      return <div className="loader" />;
    }

    if (carriers.error) {
      return <p>{I18n.t("common.loadingApiError")}</p>;
    }

    const actions = (
      <div>
        <Link to={ROUTE_CARRIERS_ADD} className="btn btn-primary">
          {I18n.t("pages.carriers.list.actions.add")}
        </Link>
      </div>
    );

    return (
      <CompanySyncStatusesTable
        target="CARRIER"
        allData={carriersList || []}
        emptyContent={I18n.t("pages.carriers.list.empty")}
        data={carriersList.filter(this.getFilterByText("legalCompanyName"))}
        headData={this.dataTableHead()}
        rowData={this.dataTableRow}
        pagination
        onPageChanged={this.onPageChanged}
        initialPage={this.getCurrentPage()}
        filters={this.renderFilters()}
        actions={actions}
      />
    );
  }

  /**
   * {@inheritdoc}
   */
  renderFilters() {
    const { filters } = this.state;

    return [
      <TextInput
        key="filterByLegalCompanyName"
        id="filterByLegalCompanyName"
        defaultValue={filters.legalCompanyName}
        onChange={(event) => this.onChangeFilter(event, "legalCompanyName")}
        label={I18n.t("filters.by_legalCompanyName")}
        optional
      />,
    ];
  }

  /**
   * {@inheritdoc}
   */
  renderTitle() {
    return I18n.t("pages.carriers.list.title");
  }
}

const mapStateToProps = (state) => ({
  carriers: state.carriers,
  carriersList: state.carriers.list.sort((a, b) =>
    alphabetically(a.legalCompanyName, b.legalCompanyName),
  ),
  userProfile: state.auth.userProfile,
});

const mapDispatchToProps = (dispatch) => ({
  getCarriersList: () => dispatch(getCarriersList()),
  deleteCarrier: (uuid) => dispatch(deleteCarrier(uuid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CarriersList);
