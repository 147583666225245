import Enum from "../../common/models/Enum";

class ClientTypes extends Enum {
  static COMPANY = "company";

  static CF = "CF";

  static DO = "DO";

  static FINAL = "final";

  static SERVICE_BENEFICIARY = "service_beneficiary";

  static values = [
    ClientTypes.COMPANY,
    ClientTypes.CF,
    ClientTypes.DO,
    ClientTypes.FINAL,
    ClientTypes.SERVICE_BENEFICIARY,
  ];

  static readables = {
    [ClientTypes.COMPANY]: "clients.type.company",
    [ClientTypes.CF]: "clients.type.cf",
    [ClientTypes.DO]: "clients.type.do",
    [ClientTypes.FINAL]: "clients.type.final",
  };

  /**
   * @return {Boolean}
   */
  canHaveFinalClient() {
    return [ClientTypes.CF, ClientTypes.DO].includes(this.value);
  }

  /**
   * @return {Boolean}
   */
  canCreateUsers() {
    return [ClientTypes.COMPANY, ClientTypes.CF, ClientTypes.DO].includes(
      this.value,
    );
  }

  /**
   * @return {Boolean}
   */
  acceptsCarriers() {
    return [ClientTypes.COMPANY, ClientTypes.CF, ClientTypes.DO].includes(
      this.value,
    );
  }

  /**
   * @return {Boolean}
   */
  canHaveDetectors() {
    return [ClientTypes.COMPANY, ClientTypes.CF, ClientTypes.DO].includes(
      this.value,
    );
  }
}

export default ClientTypes;
