import I18n from "i18n-js";
import React, { Component } from "react";
import { bool, func, instanceOf, oneOf, oneOfType, string } from "prop-types";
import classnames from "classnames";
import DatePicker from "react-datepicker";
import InvalidFeedback, { hasErrors } from "./InvalidFeedback";

class DateInput extends Component {
  static propTypes = {
    id: string,
    error: InvalidFeedback.propTypes.errors,
    label: string,
    legend: oneOfType([bool, string]),
    placeholder: string,
    optional: bool,
    isClearable: bool,
    onChange: func,
    defaultValue: instanceOf(Date),
    minDate: instanceOf(Date),
    layout: oneOf(["vertical", "horizontal"]),
    labelClassName: string,
    widgetClassName: string,
  };

  static defaultProps = {
    id: null,
    error: null,
    label: null,
    legend: null,
    placeholder: "JJ/MM/AAAA",
    optional: false,
    isClearable: false,
    onChange: null,
    defaultValue: null,
    minDate: null,
    layout: "vertical",
    labelClassName: null,
    widgetClassName: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      ...this.computeState(props),
      value: props.defaultValue,
    };

    this.onChange = this.onChange.bind(this);
  }

  /**
   * {@inheritdoc}
   */
  componentDidUpdate(prevProps) {
    const { defaultValue, legend, error } = this.props;

    if (prevProps.defaultValue !== defaultValue) {
      this.setState({ defaultValue, value: defaultValue });
    }

    if (prevProps.legend === legend || prevProps.error === error) {
      return;
    }

    this.setState(this.computeState());
  }

  /**
   * @return {String}
   */
  get value() {
    return this.state.value;
  }

  /**
   * @param {Date} newDate
   */
  onChange(newDate) {
    const { onChange } = this.props;

    this.setState(
      {
        value: newDate,
      },
      () => {
        if (onChange) {
          onChange(this.state.value);
        }
      },
    );
  }

  computeState() {
    return {
      legendContent:
        this.props.legend === true
          ? I18n.t("form.dateFormat")
          : this.props.legend,
      errorContent: this.props.error,
    };
  }

  isValid() {
    const { optional } = this.props;
    const { value } = this.state;

    if (!optional && !value) {
      this.setState({ errorContent: I18n.t("common.not_specified") });
      return false;
    }

    return true;
  }

  /**
   * {@inheritdoc}
   */
  render() {
    const { legendContent, errorContent } = this.state;
    const {
      id,
      error, // eslint-disable-line no-unused-vars
      label,
      legend, // eslint-disable-line no-unused-vars
      placeholder,
      optional,
      onChange, // eslint-disable-line no-unused-vars
      isClearable,
      minDate,
      layout,
      labelClassName,
      widgetClassName,
      ...props
    } = this.props;

    const horizontalLayout = layout === "horizontal";

    return (
      <div
        className={classnames("form-group datepicker", {
          "is-invalid": hasErrors(errorContent),
          row: horizontalLayout,
        })}
      >
        {label && (
          <label
            htmlFor={id}
            className={classnames(labelClassName, {
              "col-form-label": horizontalLayout,
              [labelClassName || "col-sm-2"]: horizontalLayout,
            })}
          >
            {label}
            {optional || "*"}
          </label>
        )}
        <div
          className={classnames(widgetClassName, {
            [widgetClassName || "col-sm-10"]: horizontalLayout,
          })}
        >
          <DatePicker
            id={id}
            className={classnames("form-control", {
              "is-invalid": hasErrors(errorContent),
            })}
            dateFormat="dd/MM/yyyy"
            todayButton={I18n.t("form.todayButton")}
            selected={this.state.value}
            placeholderText={placeholder}
            onChange={this.onChange}
            withPortal
            fixedHeight
            isClearable={isClearable}
            minDate={minDate}
            required={!optional}
            {...props}
          />
          <InvalidFeedback errors={errorContent} />
          {legendContent && (
            <small className="form-text text-muted">{legendContent}</small>
          )}
        </div>
      </div>
    );
  }
}

export default DateInput;
