import {
  arrayOf,
  bool,
  instanceOf,
  number,
  shape,
  string,
  oneOfType,
} from "prop-types";

export const AddressInput = shape({
  street: string,
  streetAddition: string,
  city: string,
  postalCode: string,
  country: string,
});

export const Address = shape({
  street: string.isRequired,
  streetAddition: string,
  city: string.isRequired,
  postalCode: string.isRequired,
  country: string.isRequired,
});

export const ContainerInput = shape({
  containerUuid: string.isRequired,
  treatmentMode: string,
  packaging: string,
  toReturn: bool,
  toDestruct: bool,
});

export const ArticleInput = shape({
  articleUuid: string,
  producerUuid: string,
  quantity: number,
  mass: number,
  packaging: string,
  treatmentMode: string,
  toReturn: bool,
  toDestruct: bool,
});

export const WasteRequestInput = shape({
  siteUuid: string,
  date: oneOfType([string, instanceOf(Date)]),
  comments: string,
  nbCages: number,
  nbPallets: number,
  attestationReceiptAddress: AddressInput,
  containers: arrayOf(ContainerInput),
  articles: arrayOf(ArticleInput),
  charteredByClient: bool,
  carrierUuid: string,
  transportNumber: string,
});

export const WasteRequestSummary = shape({
  siteUuid: string.isRequired,
  date: string.isRequired,
  comments: string,
  nbCages: number.isRequired,
  nbPallets: number.isRequired,
  attestationReceiptAddress: Address,
  containers: arrayOf(
    shape({
      container: shape({
        barcode: string.isRequired,
      }).isRequired,
      article: shape({
        designation: string.isRequired,
        volume: number,
      }).isRequired,
      fluid: shape({
        designation: string.isRequired,
      }),
      currentLoad: number.isRequired,
      treatmentMode: string.isRequired,
      packaging: string,
      toDestruct: bool.isRequired,
      toReturn: bool.isRequired,
    }),
  ),
  articles: arrayOf(
    shape({
      article: {
        designation: string.isRequired,
      }.isRequired,
      producer: shape({
        legalCompanyName: string.isRequired,
      }),
      mass: number.isRequired,
      quantity: number.isRequired,
      treatmentMode: string.isRequired,
      packaging: string,
      toDestruct: bool.isRequired,
      toReturn: bool.isRequired,
    }),
  ),
  charteredByClient: bool.isRequired,
  carrierUuid: string,
  transportNumber: string,
});
