import React from "react";
import BsffShippingReceiptInformation from "./BsffShippingReceiptInformation";
import BsffShippingSendInformation from "./BsffShippingSendInformation";
import PropTypes from "prop-types";
import { trans } from "../../../translations";
import ShippingType from "../models/ShippingType";

BsffShippingInformations.propTypes = {
  shipping: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    shippedBsffs: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  shippingType: PropTypes.string.isRequired,
};

export default function BsffShippingInformations({ shipping, shippingType }) {
  return (
    <div>
      <h4>{trans("trackdechets.bsff.list.title")}</h4>
      <div>
        {trans(
          shipping.shippedBsffs.length === 0
            ? "trackdechets.bsff.shipping.no-scan"
            : "trackdechets.bsff.shipping.scan",
        )}
        <ul>
          {shipping.shippedBsffs.map((bordereauId) => (
            <li key={`bsff-${bordereauId}`}>{bordereauId}</li>
          ))}
        </ul>
      </div>
      {shippingType === ShippingType.IN ? (
        <BsffShippingReceiptInformation shippingUuid={shipping.uuid} />
      ) : (
        <BsffShippingSendInformation shippingUuid={shipping.uuid} />
      )}
    </div>
  );
}
