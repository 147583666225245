import I18n from "i18n-js";
import { fetch } from "../../../appContainer";
import {
  GET_INSTALLATIONS_LIST_IN_PROGRESS,
  GET_INSTALLATIONS_LIST_SUCCESS,
  GET_INSTALLATIONS_LIST_FAIL,
  IMPORT_INSTALLATIONS_IN_PROGRESS,
  IMPORT_INSTALLATIONS_SUCCESS,
  IMPORT_INSTALLATIONS_FAIL,
} from "../constants";
import { extractHeaderAttribute } from "../../common/utils/headerUtils";
import { SUBMIT_FAIL, SUBMIT_SUCCESS } from "../../common/constants";

/**
 * Get installations list
 */
export function getInstallationsList() {
  return (dispatch) => {
    dispatch({ type: GET_INSTALLATIONS_LIST_IN_PROGRESS });

    fetch("api").getInstallationsList(
      (list) => {
        dispatch({ type: GET_INSTALLATIONS_LIST_SUCCESS, payload: { list } });
      },
      () => dispatch({ type: GET_INSTALLATIONS_LIST_FAIL }),
    );
  };
}

/**
 * Import installations
 */
export function importInstallations(tsv) {
  return (dispatch) => {
    dispatch({ type: IMPORT_INSTALLATIONS_IN_PROGRESS });

    fetch("api").importInstallations(
      tsv,
      (tsvReportContent, response) => {
        const message = I18n.t("alerts.import:installations.success");
        const tsvReport = tsvReportContent && {
          name: extractHeaderAttribute(
            response.headers,
            "Content-Disposition",
            "filename",
          ),
          content: tsvReportContent,
        };

        dispatch({ type: SUBMIT_SUCCESS, payload: { message } });
        dispatch({
          type: IMPORT_INSTALLATIONS_SUCCESS,
          payload: { tsvReport },
        });
      },
      (content, response) => {
        let message = I18n.t("alerts.import:installations.fail");
        let error = true;

        // File too large:
        if (response.status === 413) {
          message = I18n.t("alerts.import:installations.file_too_large");
        }
        // Validation error:
        if ([422, 400].includes(response.status)) {
          error = content;
        }

        dispatch({ type: SUBMIT_FAIL, payload: { message } });
        dispatch({ type: IMPORT_INSTALLATIONS_FAIL, payload: { error } });
      },
    );
  };
}
