import I18n from "i18n-js";
import { fetch } from "../../../appContainer";
import {
  GET_USERS_LIST_IN_PROGRESS,
  GET_USERS_LIST_SUCCESS,
  GET_USERS_LIST_FAIL,
  POST_USER_IN_PROGRESS,
  POST_USER_SUCCESS,
  POST_USER_FAIL,
  PATCH_USER_LOCK_SUCCESS,
  PATCH_USER_LOCK_FAIL,
  PATCH_USER_UNLOCK_SUCCESS,
  PATCH_USER_UNLOCK_FAIL,
  GET_USER_INFOS_IN_PROGRESS,
  GET_USER_INFOS_SUCCESS,
  GET_USER_INFOS_FAIL,
  PUT_USER_IN_PROGRESS,
  PUT_USER_SUCCESS,
  PUT_USER_FAIL,
} from "../constants";
import { SUBMIT_SUCCESS, SUBMIT_FAIL } from "../../common/constants";

/**
 * Get users list
 */
export function getUsersList() {
  return (dispatch) => {
    dispatch({ type: GET_USERS_LIST_IN_PROGRESS });

    fetch("api").getUsersList(
      (list) => {
        dispatch({ type: GET_USERS_LIST_SUCCESS, payload: { list } });
      },
      () => dispatch({ type: GET_USERS_LIST_FAIL }),
    );
  };
}

/**
 * Create user
 */
export function createUser(user) {
  return (dispatch) => {
    dispatch({ type: POST_USER_IN_PROGRESS });

    fetch("api").createUser(
      user,
      () => {
        const message = I18n.t("alerts.create:user.success");

        dispatch({ type: SUBMIT_SUCCESS, payload: { message } });
        dispatch({ type: POST_USER_SUCCESS });
      },
      (error) => {
        const message = I18n.t("alerts.create:user.fail");

        dispatch({ type: SUBMIT_FAIL, payload: { message } });
        dispatch({
          type: POST_USER_FAIL,
          payload: { violations: error.violations },
        });
      },
    );
  };
}

/**
 * Get user infos
 */
export function getUserInfos(uuid) {
  return (dispatch) => {
    dispatch({ type: GET_USER_INFOS_IN_PROGRESS });

    fetch("api").getUserInfos(
      uuid,
      (infos) => {
        dispatch({ type: GET_USER_INFOS_SUCCESS, payload: { infos } });
      },
      () => dispatch({ type: GET_USER_INFOS_FAIL }),
    );
  };
}

/**
 * Update user
 */
export function updateUser(uuid, user) {
  return (dispatch) => {
    dispatch({ type: PUT_USER_IN_PROGRESS });

    fetch("api").updateUser(
      uuid,
      user,
      () => {
        const message = I18n.t("alerts.update:user.success");

        dispatch({ type: SUBMIT_SUCCESS, payload: { message } });
        dispatch({ type: PUT_USER_SUCCESS });
      },
      (error) => {
        const message = I18n.t("alerts.update:user.fail");

        dispatch({ type: SUBMIT_FAIL, payload: { message } });
        dispatch({
          type: PUT_USER_FAIL,
          payload: { violations: error.violations },
        });
      },
    );
  };
}

/**
 * Lock user
 */
export function lockUser(uuid) {
  return (dispatch) => {
    fetch("api").lockUser(
      uuid,
      () => {
        const message = I18n.t("alerts.lock:user.success");

        dispatch({ type: SUBMIT_SUCCESS, payload: { message } });
        dispatch({
          type: PATCH_USER_LOCK_SUCCESS,
          payload: { uuid, locked: true },
        });
      },
      () => {
        const message = I18n.t("alerts.lock:user.fail");

        dispatch({ type: PATCH_USER_LOCK_FAIL });
        dispatch({ type: SUBMIT_FAIL, payload: { message } });
      },
    );
  };
}

/**
 * Unlock user
 */
export function unlockUser(uuid) {
  return (dispatch) => {
    fetch("api").unlockUser(
      uuid,
      () => {
        const message = I18n.t("alerts.unlock:user.success");

        dispatch({ type: SUBMIT_SUCCESS, payload: { message } });
        dispatch({
          type: PATCH_USER_UNLOCK_SUCCESS,
          payload: { uuid, locked: false },
        });
      },
      () => {
        const message = I18n.t("alerts.unlock:user.fail");

        dispatch({ type: PATCH_USER_UNLOCK_FAIL });
        dispatch({ type: SUBMIT_FAIL, payload: { message } });
      },
    );
  };
}
