import { func, string } from "prop-types";
import React from "react";
import { useQuery } from "@apollo/client";
import listCarriers from "../graphql/queries/listCarriers.graphql";
import Select from "../../common/components/form/Select";
import { alphabetically } from "../../common/utils/filterUtils";
import InvalidFeedback from "../../common/components/form/InvalidFeedback";

SelectCarrier.propTypes = {
  value: string, // selected value
  label: string.isRequired,
  id: string.isRequired,
  onChangedValue: func.isRequired,
  errors: InvalidFeedback.propTypes.errors,
};

export default function SelectCarrier({ value, errors, ...props }) {
  const { loading, error, data } = useQuery(listCarriers);

  let carriers = data?.listCarriers.slice() || [];
  carriers = carriers.sort((a, b) =>
    alphabetically(a.legalCompanyName, b.legalCompanyName),
  );

  return (
    <Select
      loading={loading}
      loadingError={Boolean(error)}
      options={carriers}
      renderOption={(option) => (
        <option key={`option-${option.uuid}`} value={option.uuid}>
          {option.legalCompanyName}
        </option>
      )}
      defaultValue={value}
      error={
        !loading &&
        ((carriers.length === 0 && "Aucun transporteur disponible") || errors)
      }
      {...props}
    />
  );
}
