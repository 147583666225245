import React from "react";
import { node, string, oneOf } from "prop-types";
import I18n from "i18n-js";
import { useHistory } from "react-router";
import { goBack } from "../../utils/navigationUtils";
import classnames from "classnames";

FormLayout.MEDIUM = "MEDIUM";
FormLayout.INTERMEDIATE = "INTERMEDIATE";
FormLayout.LARGE = "LARGE";
FormLayout.SIZES = {
  [FormLayout.MEDIUM]: "col-xs-12 col-sm-10 col-lg-7",
  [FormLayout.INTERMEDIATE]: "col-md-8 col-lg-9",
  [FormLayout.LARGE]: "col-md-12 col-lg-11",
};

FormLayout.propTypes = {
  children: node.isRequired,
  actions: node,
  title: string,
  backRoute: string.isRequired,
  size: oneOf([FormLayout.MEDIUM, FormLayout.INTERMEDIATE, FormLayout.LARGE]),
  className: string,
};

FormLayout.defaultProps = {
  title: null,
  size: FormLayout.MEDIUM,
  className: null,
};

export default function FormLayout({
  children,
  title,
  backRoute,
  size,
  className,
  actions,
}) {
  const history = useHistory();

  return (
    <div className={classnames("row justify-content-center", className)}>
      <div className={FormLayout.SIZES[size]}>
        <div className="float-right">
          <button
            type="button"
            className="btn btn--secondary back mb-0"
            onClick={(event) => {
              event.preventDefault();
              goBack(history, backRoute);
            }}
          >
            {I18n.t("common.history_back")}
          </button>
          {actions}
        </div>
        {title && <h1>{title}</h1>}
      </div>
      <div className={FormLayout.SIZES[size]}>{children}</div>
    </div>
  );
}
