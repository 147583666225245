import I18n from "i18n-js";
import React, { Component } from "react";
import { array, func, shape } from "prop-types";
import { createSite, getSitePayloadFromClient } from "../actions/sitesActions";
import { connect } from "react-redux";

class ButtonCreateSite extends Component {
  static propTypes = {
    client: shape().isRequired,
    site: shape().isRequired,
    sitesList: array.isRequired,
    createSite: func.isRequired,
  };

  constructor() {
    super();

    this.state = {
      creating: false,
    };

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(event) {
    event.preventDefault();

    const { client, createSite } = this.props;

    this.setState({ creating: true });

    createSite(getSitePayloadFromClient(client));
  }

  siteAlreadyExists() {
    return this.props.sitesList.find(
      (site) => site.client.uuid === this.props.client.uuid,
    );
  }

  render() {
    if (this.siteAlreadyExists()) {
      return null;
    }

    const { site } = this.props;
    const { creating } = this.state;

    if (site.saving && creating) {
      return (
        <span className="action action--btn action--disabled">
          {I18n.t("pages.clients.list.create_site:progress")}
        </span>
      );
    }

    if (site.saved && creating) {
      return (
        <span className="action action--btn action--disabled">
          {I18n.t("pages.clients.list.create_site:success")}
        </span>
      );
    }

    return (
      <button
        type="button"
        className="action action--btn"
        onClick={this.handleClick}
      >
        {I18n.t("pages.clients.list.create_site:button")}
      </button>
    );
  }
}

const mapStateToProps = (state) => ({
  site: state.site,
  sitesList: state.sites.list.filter((site) => !site.mobile),
});

const mapDispatchToProps = (dispatch) => ({
  createSite: (params) => dispatch(createSite(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ButtonCreateSite);
