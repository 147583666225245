import React, { Component } from "react";
import { bool, object, oneOfType, string, func } from "prop-types";
import classnames from "classnames";
import { Link } from "react-router-dom";

class SmallBox extends Component {
  static propTypes = {
    label: string.isRequired,
    value: string.isRequired,
    moreUri: oneOfType([string, bool, object, func]),
    alert: bool,
  };

  static defaultProps = {
    moreUri: false,
    alert: false,
  };

  /**
   * {@inheritdoc}
   */
  render() {
    const { value, label, moreUri, alert } = this.props;

    return (
      <div
        className={`small-box ${classnames({
          "bg-info": !alert,
          "bg-hint": alert,
        })}`}
      >
        <div className="small-box__inner">
          <h3 className="small-box__value">{value}</h3>
        </div>

        {(moreUri && (
          <Link to={moreUri} className="small-box__label">
            {label}
          </Link>
        )) || <div className="small-box__label">{label}</div>}
      </div>
    );
  }
}

export default SmallBox;
