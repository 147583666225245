import React from "react";
import Spinner from "../Spinner";
import GenericLoadingError from "./GenericLoadingError";
import PropTypes from "prop-types";

LoadingWrapper.propTypes = {
  loading: PropTypes.bool.isRequired,
  error: PropTypes.bool,
  children: PropTypes.func.isRequired,
};

LoadingWrapper.defaultProps = {
  error: false,
};

export default function LoadingWrapper({ loading, error, children }) {
  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <GenericLoadingError />;
  }

  return children();
}
