export const LOGIN_IN_PROGRESS = "LOGIN_IN_PROGRESS";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS";
export const GET_USER_PROFILE_FAIL = "GET_USER_PROFILE_FAIL";
export const POST_FORGOT_PASSWORD_IN_PROGRESS =
  "POST_FORGOT_PASSWORD_IN_PROGRESS";
export const POST_FORGOT_PASSWORD_SUCCESS = "POST_FORGOT_PASSWORD_SUCCESS";
export const POST_FORGOT_PASSWORD_FAIL = "POST_FORGOT_PASSWORD_FAIL";
export const PUT_CHANGE_PASSWORD_IN_PROGRESS =
  "PUT_CHANGE_PASSWORD_IN_PROGRESS";
export const PUT_CHANGE_PASSWORD_SUCCESS = "PUT_CHANGE_PASSWORD_SUCCESS";
export const PUT_CHANGE_PASSWORD_FAIL = "PUT_CHANGE_PASSWORD_FAIL";
export const RESET_FORGOT_PASSWORD = "RESET_FORGOT_PASSWORD";
export const ACCEPT_TERMS_SUCCESS = "ACCEPT_TERMS_SUCCESS";
export const ACCEPT_TERMS_FAILURE = "ACCEPT_TERMS_FAILURE";
export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";
