import React from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";
import wasteEligibleContainers from "../graphql/queries/wasteEligibleContainers.graphql";
import I18n from "i18n-js";
import Quantity from "../../common/components/elements/Quantity";
import Table from "../../common/components/elements/Table";
import GenericLoadingError from "../../common/components/elements/GenericLoadingError";

WasteContainersList.propTypes = {
  siteUuid: PropTypes.string.isRequired,
  excludedContainersUuids: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default function WasteContainersList({
  siteUuid,
  onClick,
  excludedContainersUuids,
}) {
  const { loading, error, data } = useQuery(wasteEligibleContainers);

  if (loading) {
    return <div className="loader" />;
  }

  if (error) {
    return <GenericLoadingError />;
  }

  let containers = data?.wasteEligibleContainers || [];
  containers = containers.filter(
    (c) =>
      c.site.uuid === siteUuid && !excludedContainersUuids.includes(c.uuid),
  );

  return (
    <Table
      className="waste-containers-list"
      tableClasses="table-hover table-sm table-striped"
      emptyContent={
        "Il n'y a plus de contenant à reprendre pour ce site" ||
        I18n.t(
          "pages.create_waste_recovery.steps.containers.no_containers_available",
        )
      }
      data={containers}
      headData={[
        /* eslint-disable react/jsx-key */
        "Identifiant",
        "Désignation",
        "Fluide",
        <div className="text-right">Volume</div>,
        <div className="text-right">Qté actuelle</div>,
        <div className="text-center">
          <button
            type="button"
            className="action"
            title="Tout reprendre"
            onClick={() => containers.forEach(onClick)}
          >
            <i className="fa fa-arrow-circle-o-down" aria-hidden="true" />
          </button>
        </div>,
        /* eslint-enable react/jsx-key */
      ]}
      rowData={(container) => [
        /* eslint-disable react/jsx-key */
        container.barcode,
        container.article.designation,
        container.knownFluid?.designation,
        <Quantity value={container.article.volume} unit={"L"} />,
        <Quantity value={container.currentLoad} unit={"kg"} />,
        <div className="text-center">
          <button
            type="button"
            className="action"
            onClick={() => onClick(container)}
          >
            <i className="fa fa-arrow-circle-o-down" aria-hidden="true" />
          </button>
        </div>,
        /* eslint-enable react/jsx-key */
      ]}
    />
  );
}
