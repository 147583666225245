import I18n from "i18n-js";
import React, { Component } from "react";
import { func, shape } from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { parse } from "qs";
import { resetForgotPassword, changePassword } from "../actions/authActions";
import TextInput from "../../common/components/form/TextInput";
import BigButton from "../../common/components/elements/BigButton";
import { ROUTE_LOGIN, ROUTE_NOT_FOUND } from "../../../routes";

class ChangePassword extends Component {
  static propTypes = {
    resetForgotPassword: func.isRequired,
    changePassword: func.isRequired,
    forgotPassword: shape().isRequired,
    location: shape().isRequired,
    history: shape().isRequired,
  };

  constructor() {
    super();

    this.state = {
      errorMessage: null,
    };

    this.email = null;
    this.password = null;
    this.secondPassword = null;

    this.onValidate = this.onValidate.bind(this);
    this.setEmail = this.setEmail.bind(this);
    this.setPassword = this.setPassword.bind(this);
    this.setSecondPassword = this.setSecondPassword.bind(this);
  }

  /**
   * {@inheritdoc}
   */
  componentDidMount() {
    const { resetForgotPassword, history, location } = this.props;
    const query = parse(location.search.substr(1));
    const { token, email } = query;

    resetForgotPassword();

    if (!token || !email) {
      history.replace(ROUTE_NOT_FOUND);
    }
  }

  /**
   * {@inheritdoc}
   */
  componentDidUpdate(prevProps) {
    const { forgotPassword } = this.props;

    if (prevProps.forgotPassword.error !== forgotPassword.error) {
      this.setState({ errorMessage: forgotPassword.error });
    }
  }

  /**
   * @param {Object} event
   */
  onValidate(event) {
    event.preventDefault();

    const query = parse(this.props.location.search.substr(1));
    const { token, email } = query;
    const password = this.password.value;
    const secondPassword = this.secondPassword.value;

    if (!password.length || !secondPassword.length) {
      return;
    }

    if (password !== secondPassword) {
      this.setState({
        errorMessage: I18n.t("pages.change_password.error.different_password"),
      });

      return;
    }

    this.setState({ errorMessage: null });

    this.props.changePassword(token, email, password);
  }

  /**
   * @param {String} email
   */
  setEmail(email) {
    this.email = email;
  }

  /**
   * @param {String} password
   */
  setPassword(password) {
    this.password = password;
  }

  /**
   * @param {String} secondPassword
   */
  setSecondPassword(secondPassword) {
    this.secondPassword = secondPassword;
  }

  /**
   * {@inheritdoc}
   */
  render() {
    const { inProgress } = this.props.forgotPassword;
    const query = parse(this.props.location.search.substr(1));
    const { email } = query;
    const { errorMessage } = this.state;

    return (
      <div className="login">
        <form className="form" onSubmit={this.onValidate}>
          <h1 className="text-center">
            {I18n.t("pages.change_password.title")}
          </h1>
          <TextInput
            id="email"
            placeholder={I18n.t("pages.change_password.email")}
            label={I18n.t("pages.change_password.email")}
            value={email}
            labelInside
            disabled
            ref={this.setEmail}
          />
          <TextInput
            id="password"
            type="password"
            placeholder={I18n.t("pages.change_password.password")}
            label={I18n.t("pages.change_password.password")}
            labelInside
            ref={this.setPassword}
            error={errorMessage !== null}
          />
          <TextInput
            id="second_password"
            type="password"
            placeholder={I18n.t("pages.change_password.second_password")}
            label={I18n.t("pages.change_password.second_password")}
            labelInside
            ref={this.setSecondPassword}
            error={errorMessage}
          />
          <BigButton
            tagName="button:submit"
            loading={inProgress}
            label={I18n.t("pages.change_password.submit")}
          />
        </form>
        <div className="help-link text-center">
          <Link to={ROUTE_LOGIN}>{I18n.t("pages.forgot_password.login")}</Link>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  forgotPassword: state.forgotPassword,
});

const mapDispatchToProps = (dispatch) => ({
  resetForgotPassword: () => dispatch(resetForgotPassword()),
  changePassword: (token, email, password) =>
    dispatch(changePassword(token, email, password)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
