import QRCode from "react-qr-code";
import React from "react";
import PropTypes from "prop-types";
import { trans } from "../../../translations";

BsddList.propTypes = {
  bsdds: PropTypes.arrayOf(
    PropTypes.shape({
      designation: PropTypes.string.isRequired,
      bordereauId: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      previousBsff: PropTypes.string,
      nextBsdd: PropTypes.shape({
        bordereauId: PropTypes.string,
      }),
    }).isRequired,
  ).isRequired,
};

export default function BsddList({ bsdds }) {
  return (
    <div key="bsdd-list-shipping">
      <ul className="list-group">
        {bsdds.map((bsdd) => (
          <li key={`bsff-${bsdd.id}`} className="list-group-item">
            <div className="row switch-qrcode">
              <div className="col-sm-9">
                <dl>
                  <dt>{trans("trackdechets.bsdd.list.designation")}</dt>
                  <dd>{bsdd.designation ?? "-"}</dd>
                  <dt>{trans("trackdechets.bsdd.list.noteId")}</dt>
                  <dd>{bsdd.bordereauId}</dd>
                  <dt>{trans("trackdechets.bsdd.list.status")}</dt>
                  <dd>{trans("trackdechets.bsdd.status." + bsdd.status)}</dd>
                  <dt>{trans("trackdechets.bsdd.list.previousBsdd")}</dt>
                  <dd>{bsdd.previousBsdd?.bordereauId ?? "-"}</dd>
                  <dt>{trans("trackdechets.bsdd.list.nextBsdd")}</dt>
                  <dd>{bsdd.nextBsdd?.bordereauId ?? "-"}</dd>
                  {bsdd.nextBsdd?.bordereauId && (
                    <dd>
                      <QRCode
                        className="blur-qrcode"
                        value={bsdd.nextBsdd.bordereauId}
                        size={128}
                      />
                    </dd>
                  )}
                </dl>
              </div>
              <div className="col-sm-3">
                <QRCode
                  className="blur-qrcode"
                  value={bsdd.bordereauId}
                  size={128}
                />
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
