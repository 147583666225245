import React, { useState } from "react";
import PropTypes from "prop-types";
import WasteRecoveryRequestStatus from "../models/WasteRecoveryRequestStatus";
import RawSubmit from "../../common/components/form/RawSubmit";
import { errorAlert, successAlert } from "../../common/actions/alertActions";
import { useMutation } from "../../common/api/GraphQLClient";
import charterRequestAsSales from "../graphql/mutations/charterRequestAsSales.graphql";
import { useDispatch } from "react-redux";
import SelectCarrier from "../../company/components/SelectCarrier";
import DateInput from "../../common/components/form/DateInput";
import StatelessTextInput from "../../common/components/form/StatelessTextInput";
import {
  DocumentsList,
  RejectedOutcome,
  SuccessOutcome,
  WaitingOutcome,
} from "./RequestLifecycleOutcome";
import ConfirmRequestDispatchAction from "./ConfirmRequestDispatchAction";

RequestStatusLifecycleSalesForm.propTypes = {
  status: PropTypes.instanceOf(WasteRecoveryRequestStatus).isRequired,
  uuid: PropTypes.string.isRequired,
  className: PropTypes.string,
  documents: DocumentsList.propTypes.documents,
};

RequestStatusLifecycleSalesForm.defaultProps = {
  className: null,
};

export default function RequestStatusLifecycleSalesForm({
  uuid,
  status,
  className,
  documents,
}) {
  return (
    <div className={className}>
      {(() => {
        switch (status.value) {
          case WasteRecoveryRequestStatus.CREATED:
          case WasteRecoveryRequestStatus.AWAITING_ESTIMATE:
            return (
              <WaitingOutcome>
                <p>
                  <i>
                    En attente d&apos;une action de la part du service
                    commercial
                  </i>
                </p>
              </WaitingOutcome>
            );

          case WasteRecoveryRequestStatus.COMMERCIAL_APPROVED:
            return (
              <WaitingOutcome>
                <p>En attente de la décision du demandeur</p>
              </WaitingOutcome>
            );

          case WasteRecoveryRequestStatus.AUTHOR_APPROVED:
            // only if non-chartered by client, otherwise, the request is immediately closed:
            return <TransportForm uuid={uuid} />;

          case WasteRecoveryRequestStatus.COMMERCIAL_REJECTED:
          case WasteRecoveryRequestStatus.AUTHOR_REJECTED:
            return <RejectedOutcome />;

          case WasteRecoveryRequestStatus.AWAITING_DISPATCH:
            return (
              <SuccessOutcome>
                <DocumentsList documents={documents} />
                <ConfirmRequestDispatchAction uuid={uuid} />
              </SuccessOutcome>
            );

          case WasteRecoveryRequestStatus.CLOSED:
            return (
              <SuccessOutcome>
                <DocumentsList documents={documents} />
              </SuccessOutcome>
            );
        }
      })()}
    </div>
  );
}

TransportForm.propTypes = {
  uuid: PropTypes.string.isRequired,
  className: PropTypes.string,
};

TransportForm.defaultProps = {
  className: null,
};

function TransportForm({ uuid, className }) {
  const dispatch = useDispatch();
  const [charter, { loading }] = useMutation(charterRequestAsSales);
  const [carrier, setCarrier] = useState(null);
  const [date, setDate] = useState(null);
  const [number, setNumber] = useState(null);

  const onSubmit = (e) => {
    e.preventDefault();

    charter({
      variables: {
        uuid,
        payload: {
          date,
          carrierUuid: carrier,
          transportNumber: number,
        },
      },
    })
      .then(() => dispatch(successAlert("Enregistré avec succès")))
      .catch(() => dispatch(errorAlert("Une erreur est survenue")));
  };

  const isValid = () => {
    return carrier && date && number;
  };

  const inputProps = {
    layout: "horizontal",
    labelClassName: "col-xl-4 text-xl-right",
    widgetClassName: "col-xl-8",
  };

  return (
    <form onSubmit={onSubmit} className={className}>
      <SelectCarrier
        id="select-carrier"
        label="Transporteur"
        defaultValue={carrier}
        onChangedValue={setCarrier}
        {...inputProps}
      />

      <DateInput
        id="date"
        label="Date de reprise"
        defaultValue={date}
        onChange={setDate}
        minDate={new Date()}
        {...inputProps}
      />

      <StatelessTextInput
        id="number"
        label="N° retour"
        defaultValue={number}
        onChangedValue={setNumber}
        {...inputProps}
      />

      <div className="row">
        <div className="col-12">
          <RawSubmit
            btnClasses="btn btn-primary btn-block btn-lg"
            loading={loading}
            disabled={!isValid()}
          >
            Finaliser la demande
          </RawSubmit>
        </div>
      </div>
    </form>
  );
}
