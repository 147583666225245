export const cacheConfig = {
  typePolicies: {
    Site: {
      keyFields: ["uuid"],
    },
    Client: {
      keyFields: ["uuid"],
    },
    Carrier: {
      keyFields: ["uuid"],
    },
    Container: {
      keyFields: ["uuid"],
    },
    WasteRecoverableArticle: {
      keyFields: ["uuid"],
    },
    WasteRecoveryRequest: {
      keyFields: ["uuid"],
    },
    Bsdd: {
      keyFields: ["id"],
    },
    Bsff: {
      keyFields: ["id"],
    },
    BsffIntervention: {
      keyFields: ["id"],
    },
    CompanySyncStatus: {
      keyFields: ["id", "uuid"],
    },
    InterventionPlanned: {
      keyFields: ["uuid"],
    },
  },
};
