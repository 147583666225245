import React, { Children, cloneElement, Component } from "react";
import { arrayOf, element, oneOfType, string, oneOf } from "prop-types";
import classnames from "classnames";

class Dropdown extends Component {
  static propTypes = {
    className: string,
    children: oneOfType([element, arrayOf(element)]).isRequired,
    label: oneOfType([string, element]).isRequired,
    tagName: oneOf(["div", "li"]),
  };

  static defaultProps = {
    className: null,
    tagName: "div",
  };

  state = {
    isOpen: false,
  };

  constructor() {
    super();

    this.dropdown = null;

    this.onToggle = this.onToggle.bind(this);
    this.onDocumentClick = this.onDocumentClick.bind(this);
    this.renderLabel = this.renderLabel.bind(this);
  }

  /**
   * {@inheritdoc}
   */
  componentDidMount() {
    document.addEventListener("click", this.onDocumentClick, false);
  }

  /**
   * {@inheritdoc}
   */
  componentWillUnmount() {
    document.removeEventListener("click", this.onDocumentClick, false);
  }

  /**
   * Close dropdown on document click
   *
   * @param {Object} event
   */
  onDocumentClick(event) {
    if (!this.dropdown) {
      return;
    }

    if (!this.dropdown.contains(event.target)) {
      this.setState({ isOpen: false });
    }
  }

  /**
   * Toggle dropdown
   */
  onToggle() {
    const { isOpen } = this.state;

    this.setState({ isOpen: !isOpen });
  }

  /**
   * Allows multiple onClick
   *
   * @param {Object}   event
   * @param {Function} callback
   */
  onClickChild(event, callback) {
    if (callback) {
      callback(event);
    }

    this.onToggle();
  }

  /**
   * Render label
   *
   * @return {element}
   */
  renderLabel() {
    const { label } = this.props;

    if (typeof label === "string") {
      return <span>{label}</span>;
    }

    return label;
  }

  /**
   * {@inheritdoc}
   */
  render() {
    const { children, tagName, className } = this.props;
    const { isOpen } = this.state;

    const dropdownClasses = classnames(
      "dropdown",
      {
        show: isOpen,
      },
      className,
    );

    const dropdownMenuClasses = classnames("dropdown-menu", {
      show: isOpen,
    });

    const dropdownContent = [
      <button
        key="toggle"
        className="dropdown-toggle"
        type="button"
        onClick={this.onToggle}
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {this.renderLabel()}
      </button>,
      <div key="menu" className={dropdownMenuClasses}>
        {Children.map(
          children,
          (child) =>
            child &&
            cloneElement(child, {
              className: "dropdown-item",
              onClick: (event) => this.onClickChild(event, child.props.onClick),
            }),
        )}
      </div>,
    ];

    if (tagName === "li") {
      return (
        <li
          className={dropdownClasses}
          ref={(ref) => {
            this.dropdown = ref;
          }}
        >
          {dropdownContent}
        </li>
      );
    }

    return <div className={dropdownClasses}>{dropdownContent}</div>;
  }
}

export default Dropdown;
