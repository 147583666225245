import I18n from "i18n-js";
import React, { Component } from "react";
import { func, shape } from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { forgotPassword, resetForgotPassword } from "../actions/authActions";
import TextInput from "../../common/components/form/TextInput";
import BigButton from "../../common/components/elements/BigButton";
import { ROUTE_LOGIN } from "../../../routes";

class ForgotPassword extends Component {
  static propTypes = {
    resetForgotPassword: func.isRequired,
    forgotPassword: func.isRequired,
    forgotPasswordState: shape().isRequired,
  };

  constructor() {
    super();

    this.email = null;

    this.onValidate = this.onValidate.bind(this);
    this.setEmail = this.setEmail.bind(this);
  }

  /**
   * {@inheritdoc}
   */
  componentDidMount() {
    this.props.resetForgotPassword();
  }

  /**
   * @param {Object} event
   */
  onValidate(event) {
    event.preventDefault();

    if (!this.email.value.length) {
      return;
    }

    this.props.forgotPassword(this.email.value);
  }

  /**
   * @param {String} email
   */
  setEmail(email) {
    this.email = email;
  }

  /**
   * {@inheritdoc}
   */
  render() {
    const { inProgress } = this.props.forgotPasswordState;

    return (
      <div className="login">
        <div className="form">
          <h1 className="text-center">
            <img
              className="logo"
              src="/dist/assets/images/logo.png"
              alt="Clim'app"
            />
            {I18n.t("pages.forgot_password.title")}
          </h1>
          <form onSubmit={this.onValidate}>
            <TextInput
              type="email"
              id="email"
              placeholder={I18n.t("pages.forgot_password.email")}
              label={I18n.t("pages.forgot_password.email")}
              labelInside
              ref={this.setEmail}
            />
            <BigButton
              tagName="button:submit"
              loading={inProgress}
              label={I18n.t("pages.forgot_password.submit")}
            />
          </form>
          <div className="sub-link text-center">
            <Link to={ROUTE_LOGIN}>
              {I18n.t("pages.forgot_password.login")}
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  forgotPasswordState: state.forgotPassword,
});

const mapDispatchToProps = (dispatch) => ({
  resetForgotPassword: () => dispatch(resetForgotPassword()),
  forgotPassword: (email) => dispatch(forgotPassword(email)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
