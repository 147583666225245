/* eslint react/no-unescaped-entities: 0 */
/* eslint react/display-name: 0 */
import React, { Component } from "react";
import ImportClientsContent from "./ImportClientsContent";

export default class extends Component {
  static propTypes = ImportClientsContent.propTypes;

  /**
   * {@inheritdoc}
   */
  render() {
    return (
      <div>
        <p>
          Está a punto de importar clientes a su entidad "{this.props.client}".
          <br />
          Asegúrese de que sus datos sólo corresponden a los clientes de esta
          entidad.
        </p>

        <p>
          Para importar sus clientes: los datos deben ser guardados en formato
          TSV, y respetar la siguiente estructura:
        </p>

        <ul>
          <li>Nº de cliente</li>
          <li>Nombre de la empresa</li>
          <li>VAT Code</li>
          <li>País</li>
          <li>Ciudad</li>
          <li>Código postal</li>
          <li>Calle</li>
          <li>
            Calle complementaria <i>*1</i>
          </li>
        </ul>

        <p>
          <i>*1 Opcional</i>
        </p>

        <p>{this.props.downloadSample}</p>

        <p>
          <strong>
            Si entre sus clientes ya hay entidades referenciadas en nuestra base
            de datos, ¿desea
          </strong>
        </p>

        {this.props.strategyWidget}

        <p>
          <i>
            *2 El establecimiento de una relación de servicio le permite acceder
            a todos los sitios e instalaciones de sus clientes y beneficiarse
            automáticamente de sus apuestas y creaciones.
          </i>
        </p>

        <p>
          Después de la integración, la síntesis le informará sobre el número de
          entidades creadas y actualizadas, y le proporcionará un archivo de
          anomalía que contendrá las líneas no integradas, así como la(s)
          razón(es) del rechazo.
        </p>

        <p>Las posibles causas de las anomalías son:</p>

        <ul>
          <li>Ausencia de datos obligatorios</li>
          <li>VAT Code incorrecto</li>
          <li>Fecha incorrecta</li>
        </ul>
      </div>
    );
  }
}
