export function uniq(list, uniqueKey) {
  return list.filter(
    (item, index, self) =>
      self.findIndex((t) => t[uniqueKey] === item[uniqueKey]) === index,
  );
}

export function alphabetically(a, b) {
  return a.localeCompare(b);
}

/**
 * Display a fixed
 *
 * @param {Number} value
 * @param {Number} precision
 *
 * @return {String}
 */
export function fixed(value, precision = 2) {
  if (!value) {
    return "0";
  }

  return value.toFixed(precision).replace(/\.?0*$/, "");
}

/**
 * `.toDateString()` method permits to ignore time from date
 *
 * @param {Date} date
 *
 * @return {Date}
 */
export function dayDate(date) {
  return new Date(new Date(date).toDateString());
}

/**
 * Return a filter function that match only object within the given time range
 *
 * @param {Date|Number|null} startDate
 * @param {Date|Number|null} endDate
 * @param {String|Function} property
 *
 * @return {Function}
 */
export function inPeriod(startDate, endDate, property) {
  const start = startDate && dayDate(startDate);
  const end = endDate && dayDate(endDate);

  return (object) => {
    const propertyValue =
      property instanceof Function ? property(object) : object[property];
    const date = dayDate(propertyValue);

    return (!start || date >= start) && (!end || date <= end);
  };
}

/**
 * Return a filter function that match only object including the given pattern
 *
 * @param {String} pattern
 * @param {String} property
 *
 * @return {Function}
 */
export function includes(pattern, property) {
  return (object) =>
    object[property].toLowerCase().includes(pattern.toLowerCase());
}
