import Enum from "../../common/models/Enum";
import ClientTypes from "../../company/models/ClientTypes";

class Roles extends Enum {
  static SUPER_ADMIN = "SUPER_ADMIN";
  static ADMIN = "ADMIN";
  static MANAGER = "MANAGER";
  static TECHNICIAN = "TECHNICIAN";

  static COMMERCIAL = "COMMERCIAL";
  static SALES = "SALES";

  static values = [
    Roles.SUPER_ADMIN,
    Roles.ADMIN,
    Roles.MANAGER,
    Roles.TECHNICIAN,
    Roles.COMMERCIAL,
    Roles.SALES,
  ];

  static readables = {
    [Roles.SUPER_ADMIN]: "users.roles.super_admin",
    [Roles.ADMIN]: "users.roles.admin",
    [Roles.MANAGER]: "users.roles.manager",
    [Roles.TECHNICIAN]: "users.roles.technician",
    [Roles.COMMERCIAL]: "users.roles.commercial",
    [Roles.SALES]: "users.roles.sales",
  };

  static clientTypesForRole = {
    [Roles.COMMERCIAL]: [ClientTypes.COMPANY],
    [Roles.SALES]: [ClientTypes.COMPANY],
    [Roles.SUPER_ADMIN]: [ClientTypes.COMPANY],
    [Roles.ADMIN]: [ClientTypes.COMPANY, ClientTypes.CF, ClientTypes.DO],
    [Roles.MANAGER]: [ClientTypes.CF, ClientTypes.DO],
    [Roles.TECHNICIAN]: [ClientTypes.CF, ClientTypes.DO],
  };

  /**
   * @param {ClientTypes} clientType
   *
   * @return {Array}
   */
  static forClientType(clientType) {
    const clientRoles = Roles.clientTypesForRole;

    return Object.keys(clientRoles).filter((role) =>
      clientRoles[role].includes(clientType.value),
    );
  }

  /**
   * @param {String[]}userRoles
   * @param {String} role
   */
  static userHasRole(userRoles, role) {
    return userRoles.includes(role);
  }
}

export default Roles;
