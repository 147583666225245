import React from "react";
import { bool, oneOf, string } from "prop-types";
import classnames from "classnames";
import { Link } from "react-router-dom";
import {
  ROUTE_WASTE_RECOVERY_REQUESTS_CREATE,
  ROUTE_WASTE_RECOVERY_REQUESTS_CREATE_ARTICLES,
  ROUTE_WASTE_RECOVERY_REQUESTS_CREATE_CONTAINERS,
  ROUTE_WASTE_RECOVERY_REQUESTS_CREATE_SUMMARY,
  ROUTE_WASTE_RECOVERY_REQUESTS_CREATE_TRANSPORT,
} from "../../../routes";
import { requiredChildren } from "../../common/propTypes/types";

WasteFormBreadcrumb.INFO = 1;
WasteFormBreadcrumb.CONTAINERS = 2;
WasteFormBreadcrumb.ARTICLES = 3;
WasteFormBreadcrumb.TRANSPORT = 4;
WasteFormBreadcrumb.SUMMARY = 5;

WasteFormBreadcrumb.ROUTES = {
  [WasteFormBreadcrumb.INFO]: ROUTE_WASTE_RECOVERY_REQUESTS_CREATE,
  [WasteFormBreadcrumb.CONTAINERS]:
    ROUTE_WASTE_RECOVERY_REQUESTS_CREATE_CONTAINERS,
  [WasteFormBreadcrumb.ARTICLES]: ROUTE_WASTE_RECOVERY_REQUESTS_CREATE_ARTICLES,
  [WasteFormBreadcrumb.TRANSPORT]:
    ROUTE_WASTE_RECOVERY_REQUESTS_CREATE_TRANSPORT,
  [WasteFormBreadcrumb.SUMMARY]: ROUTE_WASTE_RECOVERY_REQUESTS_CREATE_SUMMARY,
};

WasteFormBreadcrumb.propTypes = {
  position: oneOf([
    WasteFormBreadcrumb.INFO,
    WasteFormBreadcrumb.CONTAINERS,
    WasteFormBreadcrumb.ARTICLES,
    WasteFormBreadcrumb.TRANSPORT,
    WasteFormBreadcrumb.SUMMARY,
  ]),
};
WasteFormBreadcrumb.defaultProps = {};

export default function WasteFormBreadcrumb({ position }) {
  const { INFO, CONTAINERS, ARTICLES, TRANSPORT, SUMMARY } =
    WasteFormBreadcrumb;

  // TODO: translate
  return (
    <nav className="waste-breadcrumb" aria-label="breadcrumb">
      <ol className="breadcrumb">
        <BreadcrumbLink
          active={position === INFO}
          route={ROUTE_WASTE_RECOVERY_REQUESTS_CREATE}
        >
          Informations générales
        </BreadcrumbLink>
        <BreadcrumbLink
          active={position === CONTAINERS}
          route={ROUTE_WASTE_RECOVERY_REQUESTS_CREATE_CONTAINERS}
          enabled={position >= CONTAINERS}
        >
          Contenants de récupération
        </BreadcrumbLink>
        <BreadcrumbLink
          active={position === ARTICLES}
          route={ROUTE_WASTE_RECOVERY_REQUESTS_CREATE_ARTICLES}
          enabled={position >= ARTICLES}
        >
          Articles hors récupération
        </BreadcrumbLink>
        <BreadcrumbLink
          active={position === TRANSPORT}
          route={ROUTE_WASTE_RECOVERY_REQUESTS_CREATE_TRANSPORT}
          enabled={position >= TRANSPORT}
        >
          Transport
        </BreadcrumbLink>
        <BreadcrumbLink
          active={position === SUMMARY}
          route={ROUTE_WASTE_RECOVERY_REQUESTS_CREATE_SUMMARY}
          enabled={position >= SUMMARY}
        >
          Résumé
        </BreadcrumbLink>
      </ol>
    </nav>
  );
}

BreadcrumbLink.propTypes = {
  enabled: bool,
  active: bool,
  route: string,
  children: requiredChildren,
};
BreadcrumbLink.defaultProps = {
  enabled: true,
  active: false,
};

function BreadcrumbLink({ active, children, route, enabled }) {
  if (!active) {
    return (
      <BreadcrumbItem {...{ active, enabled }}>
        <Link
          to={route}
          className={classnames("btn-link", {
            disabled: !enabled,
          })}
        >
          {children}
        </Link>
      </BreadcrumbItem>
    );
  }

  return <BreadcrumbItem {...{ active, enabled }}>{children}</BreadcrumbItem>;
}

BreadcrumbItem.propTypes = {
  children: requiredChildren,
  active: bool.isRequired,
};

function BreadcrumbItem({ active, children }) {
  return (
    <li
      className={classnames("breadcrumb-item", {
        "active font-weight-bold": active,
      })}
    >
      {children}
    </li>
  );
}
