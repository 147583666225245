import React from "react";
import { Card } from "../../common/components/elements/Card";
import LoadingWrapper from "../../common/components/elements/LoadingWrapper";
import { useQuery } from "@apollo/client";
import userIdentity from "../../trackdechets/qraphql/queries/userIdentity.graphql";
import { useAuth } from "../../authentication/contexts/useAuth";
import TrackdechetsStatus from "../components/TrackdechetsStatus";
import { ROUTE_TRACKDECHETS_OAUTH_CALLBACK } from "../../../routes";
import ContentPage from "../../common/components/layout/ContentPage";

export default function TrackdechetsStatusPage() {
  const { user } = useAuth();
  const { loading, error, data } = useQuery(userIdentity, {
    variables: {
      userUuid: user.uuid,
    },
  });

  const callbackUri = new URL(
    ROUTE_TRACKDECHETS_OAUTH_CALLBACK,
    window.location.origin,
  );
  const trackdechetsUri = `${AppConfig.URL_TRACKDECHETS_AUTH}?response_type=code&redirect_uri=${callbackUri}&client_id=${AppConfig.CLIENT_ID_TRACKDECHETS_AUTH}`;

  return (
    <ContentPage title={`Paramétrage TrackDéchets`}>
      <Card>
        <LoadingWrapper loading={loading} error={Boolean(error)}>
          {() => {
            if (data.userIdentity) {
              return <TrackdechetsStatus userIdentity={data.userIdentity} />;
            }

            return (
              <div className="row justify-content-center m-3">
                <a className={"btn btn-primary"} href={trackdechetsUri}>
                  Associer mon compte à Trackdéchets
                </a>
              </div>
            );
          }}
        </LoadingWrapper>
      </Card>
    </ContentPage>
  );
}
