import I18n from "i18n-js";
import pathToRegexp from "path-to-regexp";
import React from "react";
import { arrayOf, bool, func, shape } from "prop-types";
import { Link } from "react-router-dom";
import Table from "../../common/components/elements/Table";
import List from "../../common/components/layout/List";
import { ROUTE_SITES_IMPORT } from "../../../routes";
import ClientTypes from "../models/ClientTypes";
import Roles from "../../users/models/Roles";

class AbstractSitesList extends List {
  static propTypes = {
    getSitesList: func.isRequired,
    deleteSite: func.isRequired,
    clients: shape().isRequired,
    sites: shape().isRequired,
    sitesList: arrayOf(shape).isRequired,
    userProfile: shape(),
    allowImport: bool,
  };

  static defaultProps = {
    allowImport: false,
    userProfile: null,
  };

  constructor() {
    super();

    this.onDelete = this.onDelete.bind(this);
    this.dataTableRow = this.dataTableRow.bind(this);
    this.getLabel = this.getLabel.bind(this);
    this.renderTableComponent = this.renderTableComponent.bind(this);
  }

  /**
   * {@inheritdoc}
   */
  componentDidMount() {
    super.componentDidMount();

    this.props.getSitesList();
  }

  /**
   * @param {String}  uuid
   * @param {Boolean} isMobile
   */
  onDelete(uuid, isMobile) {
    // eslint-disable-next-line no-alert
    if (!window.confirm(this.getLabel("confirm.delete"))) {
      return;
    }

    this.props.deleteSite(uuid, isMobile);
  }

  getI18nPageKey() {
    throw new Error("You must implement method getI18nPageKey");
  }

  getSiteRoute() {
    throw new Error("You must implement method getSiteRoute");
  }

  getAddSiteRoute() {
    throw new Error("You must implement method getAddSiteRoute");
  }

  getLabel(key) {
    return I18n.t(`pages.${this.getI18nPageKey()}.${key}`);
  }

  /**
   * @return {Array}
   */
  dataTableHead() {
    throw new Error("You must implement method dataTableHead");
  }

  /**
   * @param {Object} rowData
   *
   * @return {Array}
   */
  // eslint-disable-next-line
  dataTableRow(rowData) {
    throw new Error("You must implement method dataTableRow");
  }

  userCanImportSites() {
    const { roles, clientType } = this.props.userProfile;
    // At least MANAGER role & not a company user
    return roles.includes(Roles.MANAGER) && clientType !== ClientTypes.COMPANY;
  }

  renderRowActions(rowData) {
    const route = pathToRegexp.compile(this.getSiteRoute());

    return (
      <div>
        <Link to={route({ uuid: rowData.uuid })} className="action">
          <i className="fa fa-pencil" aria-hidden="true" />
        </Link>
        <button
          onClick={() => this.onDelete(rowData.uuid, rowData.mobile)}
          className="action danger"
        >
          <i className="fa fa-trash" aria-hidden="true" />
        </button>
      </div>
    );
  }

  /**
   * {@inheritdoc}
   */
  renderTitle() {
    return this.getLabel("list.title");
  }

  /**
   * {@inheritdoc}
   */
  renderContent() {
    const { clients, sites, sitesList, allowImport } = this.props;

    if (sites.inProgress || clients.inProgress) {
      return <div className="loader" />;
    }

    if (sites.error || clients.error) {
      return <p>{I18n.t("common.loadingApiError")}</p>;
    }

    const actions = (
      <div>
        {allowImport && this.userCanImportSites() && (
          <Link to={ROUTE_SITES_IMPORT} className="btn btn-primary">
            <i className="fa fa-file" />{" "}
            {I18n.t("pages.sites.list.actions.import")}
          </Link>
        )}
        <Link to={this.getAddSiteRoute()} className="btn btn-primary">
          {this.getLabel("list.actions.add")}
        </Link>
      </div>
    );

    const list = sitesList
      .filter(this.getFilterBySelect("client"))
      .filter(this.getFilterByText("designation"))
      .filter(this.getCustomFilter("city", this.cityFilter));

    return this.renderTableComponent(sitesList, {
      emptyContent: this.getLabel("list.empty"),
      data: list,
      headData: this.dataTableHead(),
      rowData: this.dataTableRow,
      pagination: true,
      onPageChanged: this.onPageChanged,
      initialPage: this.getCurrentPage(),
      filters: this.renderFilters(),
      actions: actions,
    });
  }

  renderTableComponent(sitesList, tableProps) {
    return <Table {...tableProps} />;
  }
}

export default AbstractSitesList;
