import React from "react";
import PropTypes from "prop-types";
import WasteFormBreadcrumb from "../components/WasteFormBreadcrumb";
import FormLayout from "../../common/components/layout/FormLayout";
import {
  ROUTE_MY_WASTE_RECOVERY_REQUESTS,
  ROUTE_WASTE_RECOVERY_REQUESTS_CREATE_ARTICLES,
} from "../../../routes";
import { useHistory } from "react-router";
import WasteContainersList from "../components/WasteContainersList";
import { connect, useSelector } from "react-redux";
import {
  addContainer,
  removeContainer,
} from "../actions/createWasteRecoveryRequestActions";
import WasteContainersForm from "../components/WasteContainersForm";
import { ContainerInput } from "../propTypes/types";
import { getCollectionFieldsViolations } from "../selectors/createWasteRecoveryRequestSelectors";

export default connect(
  (state) => {
    const { siteUuid, containers } = state.createWasteRecoveryRequestReducer;

    return { siteUuid, containers };
  },
  {
    addContainer,
    removeContainer,
  },
)(CreateWasteRecoveryRequestContainers);

CreateWasteRecoveryRequestContainers.propTypes = {
  siteUuid: PropTypes.string.isRequired,
  containers: PropTypes.arrayOf(ContainerInput).isRequired,
  addContainer: PropTypes.func.isRequired,
  removeContainer: PropTypes.func.isRequired,
};

function CreateWasteRecoveryRequestContainers({
  siteUuid,
  containers,
  addContainer,
  removeContainer,
}) {
  const history = useHistory();

  const errors = useSelector(
    getCollectionFieldsViolations(
      "containers",
      ["treatmentMode", "toDestruct", "toReturn", "packaging"],
      containers.length,
      (v) => v.message,
    ),
  );

  const onSubmit = (e) => {
    e.preventDefault();

    history.push(ROUTE_WASTE_RECOVERY_REQUESTS_CREATE_ARTICLES);
  };

  const onSelectedContainer = (container) =>
    addContainer({ containerUuid: container.uuid });
  const onChangeContainer = (containerUuid, data = {}) =>
    addContainer({ containerUuid, ...data });
  const onRemoveSelected = (container) => removeContainer(container.uuid);

  return (
    <div>
      <FormLayout
        title="Demande de reprise de déchets"
        backRoute={ROUTE_MY_WASTE_RECOVERY_REQUESTS}
        size={FormLayout.LARGE}
      >
        <form onSubmit={onSubmit}>
          <WasteFormBreadcrumb position={WasteFormBreadcrumb.CONTAINERS} />

          <h3>Contenants disponibles sur le site</h3>

          <WasteContainersList
            siteUuid={siteUuid}
            excludedContainersUuids={containers.map((c) => c.containerUuid)}
            onClick={onSelectedContainer}
          />

          <h3>{containers.length} contenant(s) à reprendre</h3>

          <WasteContainersForm
            containersData={containers}
            onChange={onChangeContainer}
            onDelete={onRemoveSelected}
            errors={errors}
          />

          <button type="submit" className="btn btn-primary float-right">
            Passer à l&apos;étape suivante
          </button>
        </form>
      </FormLayout>
    </div>
  );
}
