import I18n from "i18n-js";
import { fetch } from "../../../appContainer";
import {
  GET_CLIENTS_LIST_IN_PROGRESS,
  GET_CLIENTS_LIST_SUCCESS,
  GET_CLIENTS_LIST_FAIL,
  POST_CLIENT_FAIL,
  POST_CLIENT_IN_PROGRESS,
  POST_CLIENT_SUCCESS,
  PUT_CLIENT_FAIL,
  PUT_CLIENT_IN_PROGRESS,
  PUT_CLIENT_SUCCESS,
  GET_CLIENT_INFO_IN_PROGRESS,
  GET_CLIENT_INFO_SUCCESS,
  GET_CLIENT_INFO_FAIL,
  DELETE_CLIENT_IN_PROGRESS,
  DELETE_CLIENT_SUCCESS,
  DELETE_CLIENT_FAIL,
  IMPORT_CLIENTS_IN_PROGRESS,
  IMPORT_CLIENTS_SUCCESS,
  IMPORT_CLIENTS_FAIL,
} from "../constants";
import { SUBMIT_SUCCESS, SUBMIT_FAIL } from "../../common/constants";
import { extractHeaderAttribute } from "../../common/utils/headerUtils";
import { createSite, getSitePayloadFromClient } from "./sitesActions";

/**
 * Get clients list
 */
export function getClientsList() {
  return (dispatch, getState) => {
    if (getState().clients.inProgress) {
      return;
    }

    dispatch({ type: GET_CLIENTS_LIST_IN_PROGRESS });

    fetch("api").getClientsList(
      (list) => {
        dispatch({ type: GET_CLIENTS_LIST_SUCCESS, payload: { list } });
      },
      () => dispatch({ type: GET_CLIENTS_LIST_FAIL }),
    );
  };
}

/**
 * Get client info
 */
export function getClientInfo(uuid) {
  return (dispatch) => {
    dispatch({ type: GET_CLIENT_INFO_IN_PROGRESS });

    fetch("api").getClientInfo(
      uuid,
      (info) => {
        dispatch({ type: GET_CLIENT_INFO_SUCCESS, payload: { info } });
      },
      () => dispatch({ type: GET_CLIENT_INFO_FAIL }),
    );
  };
}

/**
 * Create client
 */
export function createClient(client, createDefaultSite) {
  return (dispatch) => {
    dispatch({ type: POST_CLIENT_IN_PROGRESS });

    fetch("api").createClient(
      client,
      ({ uuid }) => {
        const message = I18n.t("alerts.create:client.success");

        // Create default site for this client
        if (createDefaultSite) {
          dispatch(createSite(getSitePayloadFromClient(client, uuid)));
        }

        dispatch({ type: SUBMIT_SUCCESS, payload: { message } });
        dispatch({ type: POST_CLIENT_SUCCESS });
      },
      (error) => {
        const message = I18n.t("alerts.create:client.fail");

        dispatch({ type: SUBMIT_FAIL, payload: { message } });
        dispatch({
          type: POST_CLIENT_FAIL,
          payload: { violations: error.violations },
        });
      },
    );
  };
}

/**
 * Update client
 */
export function updateClient(uuid, client) {
  return (dispatch) => {
    dispatch({ type: PUT_CLIENT_IN_PROGRESS });

    fetch("api").updateClient(
      uuid,
      client,
      () => {
        const message = I18n.t("alerts.update:client.success");

        dispatch({ type: SUBMIT_SUCCESS, payload: { message } });
        dispatch({ type: PUT_CLIENT_SUCCESS });
      },
      (error) => {
        const message = I18n.t("alerts.update:client.fail");

        dispatch({ type: SUBMIT_FAIL, payload: { message } });
        dispatch({
          type: PUT_CLIENT_FAIL,
          payload: { violations: error.violations },
        });
      },
    );
  };
}

export function deleteClient(uuid) {
  return (dispatch) => {
    dispatch({ type: DELETE_CLIENT_IN_PROGRESS });

    fetch("api").deleteClient(
      uuid,
      () => {
        const message = I18n.t("alerts.delete:client.success");

        dispatch({ type: SUBMIT_SUCCESS, payload: { message } });
        dispatch(getClientsList());
        dispatch({ type: DELETE_CLIENT_SUCCESS });
      },
      () => {
        const message = I18n.t("alerts.delete:client.fail");

        dispatch({ type: DELETE_CLIENT_FAIL });
        dispatch({ type: SUBMIT_FAIL, payload: { message } });
      },
    );
  };
}

/**
 * Import final clients
 */
export function importClients(strategy, tsv) {
  return (dispatch) => {
    dispatch({ type: IMPORT_CLIENTS_IN_PROGRESS });

    fetch("api").importClients(
      strategy,
      tsv,
      (tsvReportContent, response) => {
        const message = I18n.t("alerts.import:clients.success");
        const tsvReport = tsvReportContent && {
          name: extractHeaderAttribute(
            response.headers,
            "Content-Disposition",
            "filename",
          ),
          content: tsvReportContent,
        };

        dispatch({ type: SUBMIT_SUCCESS, payload: { message } });
        dispatch({ type: IMPORT_CLIENTS_SUCCESS, payload: { tsvReport } });
      },
      (content, response) => {
        let message = I18n.t("alerts.import:clients.fail");
        let error = true;

        // File too large:
        if (response.status === 413) {
          message = I18n.t("alerts.import:clients.file_too_large");
        }
        // Validation error:
        if ([422, 400].includes(response.status)) {
          error = content;
        }

        dispatch({ type: SUBMIT_FAIL, payload: { message } });
        dispatch({ type: IMPORT_CLIENTS_FAIL, payload: { error } });
      },
    );
  };
}
