/*
 * This file was generated by the "elao/enum" PHP package.
 * The code inside belongs to you and can be altered, but no BC promise is guaranteed.
 */

import { ReadableEnum } from "../../../libs/enum";

class WasteRecoveryRequestStatus extends ReadableEnum {
  static CREATED = "CREATED";
  static AWAITING_ESTIMATE = "AWAITING_ESTIMATE";
  static COMMERCIAL_APPROVED = "COMMERCIAL_APPROVED";
  static COMMERCIAL_REJECTED = "COMMERCIAL_REJECTED";
  static AUTHOR_APPROVED = "AUTHOR_APPROVED";
  static AUTHOR_REJECTED = "AUTHOR_REJECTED";
  static AWAITING_DISPATCH = "AWAITING_DISPATCH";
  static CLOSED = "CLOSED";

  static get readables() {
    return {
      [WasteRecoveryRequestStatus.CREATED]: "enum.waste.request_status.created",
      [WasteRecoveryRequestStatus.AWAITING_ESTIMATE]:
        "enum.waste.request_status.awaiting_estimate",
      [WasteRecoveryRequestStatus.COMMERCIAL_APPROVED]:
        "enum.waste.request_status.commercial_approved",
      [WasteRecoveryRequestStatus.COMMERCIAL_REJECTED]:
        "enum.waste.request_status.commercial_rejected",
      [WasteRecoveryRequestStatus.AUTHOR_APPROVED]:
        "enum.waste.request_status.author_approved",
      [WasteRecoveryRequestStatus.AUTHOR_REJECTED]:
        "enum.waste.request_status.author_rejected",
      [WasteRecoveryRequestStatus.AWAITING_DISPATCH]:
        "enum.waste.request_status.awaiting_dispatch",
      [WasteRecoveryRequestStatus.CLOSED]: "enum.waste.request_status.closed",
    };
  }

  isDone(currentStatus) {
    const self = WasteRecoveryRequestStatus;

    /**
     * Statuses "order" represented by a weight where lower ones happens before greater ones.
     * Statuses corresponding to equivalent steps but different results are given the same weight.
     */
    const weights = {
      [self.CREATED]: 0,
      [self.AWAITING_ESTIMATE]: 1,
      [self.COMMERCIAL_APPROVED]: 2,
      [self.COMMERCIAL_REJECTED]: 2,
      [self.AUTHOR_APPROVED]: 3,
      [self.AUTHOR_REJECTED]: 3,
      [self.AWAITING_DISPATCH]: 4,
      [self.CLOSED]: 5,
    };

    return weights[currentStatus.value] > weights[this.value];
  }

  isDoneOrCurrent(currentStatus) {
    if (this.value === currentStatus.value) {
      return true;
    }

    return this.isDone(currentStatus);
  }

  /**
   * Next available statuses by status
   */
  static get availableTransitions() {
    const self = WasteRecoveryRequestStatus;
    return {
      [self.CREATED]: [self.AWAITING_ESTIMATE],
      [self.AWAITING_ESTIMATE]: [
        self.COMMERCIAL_APPROVED,
        self.COMMERCIAL_REJECTED,
      ],
      [self.COMMERCIAL_APPROVED]: [self.AUTHOR_APPROVED, self.AUTHOR_REJECTED],
      [self.COMMERCIAL_REJECTED]: [],
      [self.AUTHOR_APPROVED]: [self.AWAITING_DISPATCH],
      [self.AUTHOR_REJECTED]: [],
      [self.AWAITING_DISPATCH]: [self.CLOSED],
      [self.CLOSED]: [],
    };
  }

  static needsCommercialAction() {
    return [
      new WasteRecoveryRequestStatus(WasteRecoveryRequestStatus.CREATED),
      new WasteRecoveryRequestStatus(
        WasteRecoveryRequestStatus.AWAITING_ESTIMATE,
      ),
    ];
  }

  static needsSalesAction() {
    return [
      new WasteRecoveryRequestStatus(
        WasteRecoveryRequestStatus.AUTHOR_APPROVED,
      ),
    ];
  }

  static needsAuthorAction() {
    return [
      new WasteRecoveryRequestStatus(
        WasteRecoveryRequestStatus.COMMERCIAL_APPROVED,
      ),
    ];
  }

  static needsTransportAction() {
    return [
      new WasteRecoveryRequestStatus(
        WasteRecoveryRequestStatus.AWAITING_DISPATCH,
      ),
    ];
  }
}

export default WasteRecoveryRequestStatus;
