class Enum {
  /**
   * @param {String|Integer} value One of the possible enum values
   */
  constructor(value) {
    if (!this.constructor.accepts(value)) {
      throw new Error(
        `Invalid value for "${this.constructor.name}" enum type.` +
          `Expected one of ${JSON.stringify(
            this.constructor.values,
          )}. Got "${value}".`,
      );
    }

    this.value = value;
  }

  /**
   * @param {String|Integer} value
   *
   * @return {Boolean} True if the value is an acceptable value for the enum type
   */
  static accepts(value) {
    return this.values.indexOf(value) !== -1;
  }

  /**
   * @returns {Enum[]} the list of all possible enum instances.
   */
  static get instances() {
    return this.values.map((value) => new this(value));
  }

  /**
   * Get the human readable purpose
   *
   * @param {String|Integer} value
   */
  static readableFor(value) {
    return this.readables[value];
  }

  /**
   * @param {String|Integer} value
   *
   * @return {Boolean} True if the enum instance has given value
   */
  is(value) {
    return value === this.value;
  }

  /**
   * Returns the human readable version of the enum instance
   *
   * @return {String}
   */
  getReadable() {
    return this.constructor.readableFor(this.value);
  }

  /**
   * Returns the human readable version of the enum instance
   *
   * @return {String}
   */
  toString() {
    return this.getReadable();
  }
}

export default Enum;
