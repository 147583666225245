import React from "react";
import classnames from "classnames";
import { arrayOf, element, oneOfType, string } from "prop-types";
import { requiredChildren } from "../../propTypes/types";

Card.propTypes = {
  title: string,
  className: string,
  children: requiredChildren,
};

Card.defaultProps = {
  title: null,
  className: null,
  children: oneOfType([element, arrayOf(element)]).isRequired,
};

export function Card({ title, children, className, ...props }) {
  return (
    <div className={classnames("card", className)} {...props}>
      <div className="card-body">
        {title && <h3 className="card-title">{title}</h3>}

        {children}
      </div>
    </div>
  );
}
