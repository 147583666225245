import { useQuery } from "@apollo/client";
import I18n from "i18n-js";
import pathToRegexp from "path-to-regexp";
import { func, shape, string } from "prop-types";
import React, { useMemo } from "react";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  ROUTE_INTERVENTIONS_PLANNED_ADD,
  ROUTE_INTERVENTIONS_PLANNED_UPDATE,
} from "../../../routes";
import { errorAlert, successAlert } from "../../common/actions/alertActions";
import { useMutation } from "../../common/api/GraphQLClient";
import { alphabetically } from "../../common/utils/filterUtils";
import { getLang } from "../../common/utils/langUtils";
import { getClientsList } from "../../company/actions/clientsActions";
import ClientUtils from "../../company/utils/ClientUtils";
import { getUsersList } from "../../users/actions/usersActions";
import InterventionList from "../components/InterventionList";
import { onDeletePlannedInterventionRequest } from "../graphql/cache/apolloCache";
import { deleteInterventionPlannedQuery } from "../graphql/mutations/plannedInterventionMutations";
import { listInterventionPlannedQuery } from "../graphql/queries/plannedInterventionQueries";
import InterventionType from "../models/InterventionType";
import Purpose from "../models/Purpose";

const updateInterventionRoute = pathToRegexp.compile(
  ROUTE_INTERVENTIONS_PLANNED_UPDATE,
);

RowActions.propTypes = {
  rowData: shape({
    uuid: string,
  }),
  onDelete: func,
};

function RowActions({ rowData, onDelete }) {
  const dispatch = useDispatch();
  const [deleteRequest] = useMutation(deleteInterventionPlannedQuery, {
    update: onDeletePlannedInterventionRequest,
  });

  function deleteIntervetion(uuid) {
    // eslint-disable-next-line no-alert
    if (
      !window.confirm(
        I18n.t("pages.interventions.actions.delete.alert.message"),
      )
    ) {
      return;
    }

    deleteRequest({ variables: { uuid } })
      .then(() => {
        const message = I18n.t("alerts.delete:plannedIntervention.success");

        dispatch(successAlert(message));
        if (null !== onDelete) {
          onDelete(uuid);
        }
      })
      .catch((error) => {
        console.error("error", error);
        const message = I18n.t("alerts.delete:plannedIntervention.fail");
        dispatch(errorAlert(message));
      });
  }

  return (
    <div>
      <Link
        to={updateInterventionRoute({ uuid: rowData.uuid })}
        className="action"
      >
        <i className="fa fa-pencil" aria-hidden="true" />
      </Link>
      <button
        onClick={() => deleteIntervetion(rowData.uuid)}
        className="action danger"
      >
        <i className="fa fa-trash" aria-hidden="true" />
      </button>
    </div>
  );
}

class InterventionListPlanned extends InterventionList {
  /**
   * {@inheritdoc}
   */
  renderTitle() {
    return I18n.t("pages.plannedInterventions.list.title");
  }

  renderActions() {
    return (
      <Link to={ROUTE_INTERVENTIONS_PLANNED_ADD} className="btn btn-primary">
        {I18n.t("pages.plannedInterventions.create.title")}
      </Link>
    );
  }

  dataTableHead() {
    return [
      I18n.t("pages.interventions.list.head.date"),
      ...(this.shouldRenderTechFilter
        ? [I18n.t("pages.interventions.list.head.technician")]
        : []),
      I18n.t("pages.interventions.list.head.client"),
      I18n.t("pages.interventions.list.head.installation"),
      I18n.t("pages.interventions.list.head.type"),
      I18n.t("pages.interventions.list.head.purpose"),
      "",
    ];
  }

  dataTableRow(rowData) {
    const { client, installation, plannedAt, type, purpose, userUuid } =
      rowData;

    const technician = this.renderTechnician(userUuid);

    return [
      new Date(plannedAt).toLocaleDateString(getLang()),
      ...(technician ? [technician] : []),
      ClientUtils.renderLabel(client),
      this.getInstallationLabel(installation),
      I18n.t(InterventionType.readableFor(type)),
      purpose ? I18n.t(Purpose.readableFor(purpose)) : null,
      <RowActions
        key="actions"
        rowData={rowData}
        onDelete={this.removeIntervention}
      />,
    ];
  }
}

const InterventionListWrapper = (props) => {
  const { loading, data } = useQuery(listInterventionPlannedQuery);

  const interventionsList = useMemo(() => {
    return data?.listInterventionPlanned.map((d) => ({
      user: d.user,
      installation: d.installation,
      site: d.installation.site,
      userUuid: d.user.uuid,
      uuid: d.uuid,
      type: d.type,
      purpose: d.purpose,
      plannedAt: d.plannedAt,
      client: {
        ...d.installation.site.client,
        city: d.installation.site.client.address.city,
      },
    }));
  }, [data]);

  const interventions = useMemo(() => {
    return { inProgress: loading };
  }, [loading]);

  return (
    <InterventionListPlanned
      {...props}
      interventionsList={interventionsList ?? []}
      interventions={interventions}
      getInterventionList={() => null}
    />
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  clientsList: state.clients.list.sort((a, b) =>
    alphabetically(a.legalCompanyName, b.legalCompanyName),
  ),
  users: state.users,
  userProfile: state.auth.userProfile,
});

const mapDispatchToProps = (dispatch) => ({
  getClientsList: () => dispatch(getClientsList()),
  getUsersList: () => dispatch(getUsersList()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InterventionListWrapper);
