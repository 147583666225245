/* eslint react/no-unescaped-entities: 0 */
import React, { Component } from "react";

export default class CGU extends Component {
  /**
   * {@inheritdoc}
   */
  render() {
    return (
      <div className="container">
        <h1>Términos y condiciones generales de uso-usuario</h1>

        <h2>Aviso de advertencia</h2>
        <p>
          La aplicación Clim'app está disponible en versión beta. En
          consecuencia, Usted acepta que Clim'app puede, durante Su uso,
          contener errores u otras anomalías.
        </p>
        <p>
          Por lo tanto, usted acepta que no seremos responsables de ningún daño
          sufrido por usted como resultado del uso de Clim'app.
        </p>

        <h2>Preámbulo</h2>
        <p>
          Estas Condiciones Generales de Uso (en adelante denominadas "CGC") son
          un anexo al contrato SaaS firmado entre el Socio y Climalife (en
          adelante denominado "el Contrato") y forman parte integrante del
          mismo.
        </p>
        <p>
          El contrato SaaS, los presentes Términos y Condiciones de Uso de
          Clim'app y la Política de Privacidad constituyen el marco contractual
          completo aplicable a cualquier servicio prestado por Clim'app en su
          beneficio.
        </p>
        <p>
          Es importante leer, comprender y aceptar cada uno de los siguientes
          términos, ya que cualquier servicio proporcionado por Clim'app en su
          beneficio está sujeto a la aplicación de este marco contractual, y en
          particular de estos Términos y Condiciones Generales de Uso. Si no
          está de acuerdo con los términos de este documento, no utilice
          Clim'app.
        </p>
        <p>
          Este Preámbulo forma parte integrante de las Condiciones Generales de
          Uso de la aplicación móvil Clim'app.
        </p>

        <h2>I - Información general</h2>

        <h3>1.1 ¿A quién van dirigidas estas Condiciones Generales de Uso?</h3>
        <p>
          Estos Términos y Condiciones Generales de Uso están dirigidos a
          cualquier Empleado de un Socio de Climalife que haya firmado y
          aceptado el Acuerdo y haya aceptado estos T&C y la Política de
          Privacidad adjunta al Acuerdo. que visite el sitio y utilice la
          aplicación móvil denominada Clim'app (en adelante "Clim'app"). Esta
          persona se denomina aquí "Usted" (véanse las definiciones más
          adelante).
        </p>

        <h3>1.2 ¿Quiénes somos y cómo ponernos en contacto con nosotros?</h3>
        <p>
          La aplicación móvil, Clim'app que Usted utiliza y/o en general el
          servicio de la sociedad de la información en el que se integran estas
          Condiciones Generales de Uso, es la de..: Climalife (en lo sucesivo
          denominados "nosotros").
        </p>
        <p>Nuestros datos de contacto precisos:</p>
        <p>Forma societaria: sociedad anónima</p>
        <p>
          Dirección de la sede social: 4rue de la Croix Faubin - 75011 Paris
        </p>
        <p>Dirección de correo electrónico: info@climapp.dehon.com</p>
        <p>Número de inscripción en el Registro Mercantil: 310 259 205</p>
        <p>NÚMERO DE CUBA: ES 62 310 259 205</p>
        <p>
          Puede ponerse en contacto con nosotros en la dirección postal indicada
          anteriormente.
        </p>
        <p>
          Para cualquier cuestión relacionada con estas Condiciones Generales de
          Uso, también puede utilizar la siguiente dirección de correo
          electrónico: info@climapp.dehon.com
        </p>

        <h2>II - Definiciones</h2>
        <p>
          En las presentes Condiciones Generales de Uso, los siguientes términos
          se entenderán como sigue, especificándose que las definiciones
          incorporadas en el Acuerdo se aplicarán a las presentes CGU:
        </p>
        <p>
          Clim'app o la Aplicación se refiere a la aplicación móvil en la que
          ofrecemos nuestros Servicios.
        </p>
        <p>
          Cuenta: la página que enumera todos los Datos del Socio, incluyendo
          los datos personales alojados en Clim'app de acuerdo con la Política
          de Privacidad.
        </p>
        <p>
          Términos y condiciones generales de uso o CGC se refiere a estos
          Términos y condiciones generales de uso de Clim'app que rigen el uso
          de Clim'app.
        </p>
        <p>
          Contenido(s): toda la información, datos, imágenes, textos, vídeos,
          fotografías, publicados en Clim'app por el Agente y/o el Socio.
        </p>
        <p>
          Contrato se refiere al contrato SaaS firmado entre Climalife y el
          Socio que rige los términos y condiciones de acceso a la Plataforma.
        </p>
        <p>
          Agente: cualquier persona que acceda a la Aplicación y ejerza sus
          actividades bajo la autoridad y el control del Socio.{" "}
        </p>
        <p>
          Medio duradero: cualquier instrumento que le permita a Usted y/o a
          Nosotros almacenar la información dirigida a Usted de una manera que
          le permita referirse a ella posteriormente durante un período de
          tiempo adaptado a los fines para los que está destinada la información
          y que permita la reproducción idéntica de la información almacenada.
        </p>

        <h2>
          III - Finalidad y aceptación de las presentes Condiciones Generales de
          Uso
        </h2>
        <p>
          Clim'app se rige por las presentes Condiciones Generales de Uso. El
          Socio se compromete a ponerlos en conocimiento del Agente y a
          asegurarse de que el Agente se compromete a respetarlos al crear una
          Cuenta, al acceder a Clim'app y, en general, al utilizar Clim'app y
          Nuestros Servicios.
        </p>
        <p>
          En cualquier caso, el Agente que descargue y utilice la Aplicación
          reconoce haber leído y aceptado las presentes Condiciones Generales de
          Uso.
        </p>

        <h2>
          IV-Descripción de los servicios prestados a través de la aplicación
        </h2>
        <p>
          Climalife ha desarrollado Clim'app, la aplicación móvil que da soporte
          a los profesionales de la refrigeración a diario.
        </p>
        <p>Clim'app es una aplicación móvil que te permite:</p>
        <ul>
          <li>Gestión y presentación de informes de las intervenciones</li>
          <li>Gestión de instalaciones y contenedores</li>
          <li>La producción de documentos regulatorios</li>
        </ul>
        <p>Clim'app es también una aplicación web que ofrece:</p>
        <ul>
          <li>Visibilidad en el stock de refrigerantes</li>
          <li>
            La posibilidad de administrar el entorno del profesional de la
            refrigeración (emplazamientos, instalaciones, vehículos,
            detectores....)
          </li>
          <li>
            Cuadros de mando que resumen la parte principal de la actividad
          </li>
        </ul>

        <h2>V - Evolución de nuestros servicios</h2>
        <p>
          Usted entiende que podemos cambiar los Servicios en cualquier momento
          para tener en cuenta, en particular, los cambios en la tecnología y/o
          la legislación aplicable. Hacemos todo lo posible para informarle de
          cualquier cambio material en Nuestros Servicios.
        </p>
        <p>
          Usted entiende que la versión de Clim'app que descarga es una versión
          de prueba. Usted reconoce que todas o parte de las funcionalidades de
          la versión de prueba actual de Clim'app, como resultado, pueden estar
          incompletas y/o defectuosas.{" "}
        </p>

        <h2>VI - Sus compromisos generales</h2>
        <p>
          En el marco de estas Condiciones Generales de Uso, Usted actúa como
          profesional para los fines de Su actividad comercial, industrial,
          artesanal o liberal, y con exclusión de todos los fines personales.
        </p>
        <p>
          Usted reconoce que está actuando, dentro del alcance de este acuerdo,
          como un profesional dentro del significado del Código del Consumidor.
        </p>

        <h2>VII - Acceso a nuestros servicios</h2>

        <h3>7.1 Descargar la aplicación Clim'app</h3>
        <p>
          El acceso a Nuestros Servicios requiere haber descargado previamente
          la aplicación móvil Clim'app, cuyas funcionalidades y condiciones de
          interoperabilidad se indican antes de la descarga, en la tienda de
          aplicaciones, como la App Store o Google Play.
        </p>
        <p>
          Las nuevas versiones de la aplicación se ponen regularmente a su
          disposición a través de la tienda de aplicaciones, como la App Store o
          Google Play. Al descargar estas actualizaciones, Usted acepta
          cualquier cambio en la aplicación o en los Servicios que resulten de
          ellas. Usted acepta descargar regularmente actualizaciones y reconoce
          que no se nos puede atribuir ninguna responsabilidad si utiliza una
          versión anterior a la actualizada.{" "}
        </p>

        <h3>7.2 Creación de su cuenta</h3>
        <p>
          Nuestros Servicios son accesibles para Usted tan pronto como Usted
          haya creado Su Cuenta de acuerdo con los términos y condiciones
          establecidos en este artículo.
        </p>
        <p>Creación de su cuenta</p>
        <p>
          La creación de una cuenta en Clim'app sigue diferentes pasos: cada
          paso se completa sucesivamente con el "clic" de un icono de
          confirmación.
        </p>
        <p>
          Durante los distintos pasos de la creación de una cuenta, es posible
          que se le pida que proporcione información personal (como su nombre,
          apellido, etc.) cuyo procesamiento por nuestra parte se rige por
          nuestra Política de privacidad.
        </p>
        <p>
          Cuando Usted hace clic en un icono cuya redacción o presentación
          genera un compromiso que cualquier usuario medio de Internet
          entendería, Usted está obligado por su clic. Este es el caso, por
          ejemplo, de los iconos "Validación", "Confirmación" o cualquier otro
          título similar.
        </p>
        <p>
          Sujeto al siguiente párrafo, estamos obligados por la creación de Su
          Cuenta tan pronto como se complete toda la información solicitada en
          el proceso de creación de la Cuenta.
        </p>
        <p>Cheques antes de la creación de su cuenta</p>
        <p>
          Nos reservamos el derecho de no validar la solicitud de creación de
          una Cuenta en particular en los siguientes casos:
        </p>
        <ul>
          <li>En caso de que la información proporcionada no sea válida;</li>
          <li>Si el Contrato no ha sido firmado.</li>
        </ul>
        <p>
          En tal caso, la solicitud de creación de una Cuenta no es validada y
          no puede dar lugar a ninguna obligación.
        </p>
        <p>La validez y la prueba de la creación de su cuenta</p>
        <p>
          Sin perjuicio de cualquier otra evidencia escrita o evidencia
          almacenada en otro medio duradero al cual Usted pueda tener acceso, se
          acuerda que los registros computarizados, almacenados en Nuestros
          sistemas computarizados o los de Nuestros subcontratistas, podrán ser
          utilizados como prueba de las comunicaciones, el contenido de la
          creación de Su Cuenta y su fecha.
        </p>

        <h2>VIII - Sus obligaciones en el uso de nuestros servicios</h2>

        <h3>8.1 La seguridad de su cuenta</h3>
        <p>
          El acceso a Su Cuenta está reservado para Usted después de
          identificarse utilizando Su nombre de usuario y contraseña que Usted
          determine libremente al crear Su Cuenta.
        </p>
        <p>
          Usted reconoce que su contraseña es estrictamente confidencial. El uso
          de su nombre de usuario y contraseña a través de Internet es bajo su
          propio riesgo. Es Su responsabilidad tomar todas las medidas
          necesarias para proteger Su nombre de usuario y contraseña contra
          cualquier violación. Usted acepta desconectarse de Su Cuenta después
          de cualquier uso. En este sentido, no nos hacemos responsables de
          ningún acceso fraudulento a Su Cuenta ni de ningún cambio realizado en
          la misma. En tal caso, Usted se compromete a informarnos lo antes
          posible.
        </p>
        <p>
          Usted sigue siendo el único garante de la información almacenada en Su
          Cuenta. Puede añadir, modificar o eliminarlos en cualquier momento.
        </p>

        <h3>8.2 El uso de nuestros servicios</h3>
        <p>
          Al acceder, visitar o utilizar Clim'app, Usted garantiza y certifica
          que es responsable de todo uso de Clim'app por su parte, o por
          cualquier persona que utilice sus credenciales.
        </p>

        <h3>8.3 Prohibición del uso ilegal o ilegal de Clim'app</h3>
        <p>
          Se le informa que todas las leyes y reglamentos aplicables son
          aplicables en Internet.
        </p>
        <p>
          Usted certifica que no está utilizando Clim'app para ningún propósito
          ilegal o prohibido por la ley o por estas Condiciones de uso.
        </p>
        <p>
          Como lista no exhaustiva, usted no debe, bajo ninguna circunstancia:
        </p>
        <ul>
          <li>
            Interceptar o intentar interceptar correo electrónico o cualquier
            otra comunicación privada que no sea para Usted;
          </li>
          <li>
            El uso significa que limitaría el uso legítimo y pacífico de
            Clim'app por parte de un tercero;
          </li>
          <li>
            Tergiversar su identidad como Nuestro empleado, agente o sirviente;
          </li>
          <li>
            Utilizar la información obtenida de Clim'app para abusar, acosar o
            lesionar a otra persona o para contactar, promover, solicitar o
            vender información sobre esa persona sin su previo consentimiento
            explícito.
          </li>
        </ul>

        <h3>8.4 El contenido que usted publica en nuestros servicios</h3>
        <p>La publicación de su contenido</p>
        <p>
          Al publicar información, contenido, comentarios o reacciones en
          Clim'app, usted contribuye al funcionamiento de Clim'app. Para que
          esta operación se lleve a cabo correctamente y para que Clim'app sea
          efectiva, Usted se compromete a cumplir con todos estos Términos y
          Condiciones Generales de Uso, así como con las disposiciones legales y
          reglamentarias aplicables.
        </p>
        <p>
          El Contenido que Usted proporciona en Clim'app no puede, pero esta
          lista no es exhaustiva:
        </p>
        <ul>
          <li>dañar la imagen de Clim'app;</li>
          <li>
            ser falsa, inexacta o engañosa (en la medida de lo posible, debe ser
            cierta y verificada);
          </li>
          <li>ser difamatorio, amenazante o acosador;</li>
          <li>
            contengan virus, troyanos o cualquier otro programa informático cuyo
            propósito sea dañar, interceptar o expropiar el sistema, los datos o
            la información personal;
          </li>
          <li>
            infringir los derechos de terceros, como los derechos de propiedad
            intelectual, el derecho a la intimidad y la protección de datos
            personales.
          </li>
        </ul>
        <p>
          La información que Usted proporciona, configura, modifica y controla
          es de su exclusiva responsabilidad.
        </p>
        <p>Propiedad intelectual de su contenido</p>
        <p>
          Para el Contenido que Usted publica en Clim'app, protegido por los
          derechos de propiedad intelectual que Usted pueda tener, Usted nos
          concede una licencia no exclusiva, transferible, sublicenciable, libre
          de regalías y mundial para usar, reproducir, modificar, traducir,
          distribuir, adaptar y comunicar dicho Contenido publicado en Clim'app
          para fines que incluyen la correcta administración y operación de
          Clim'app. Esta licencia se aplicará durante todo el período legal de
          los derechos de autor, incluso si Usted decide dejar de utilizar
          Clim'app.
        </p>

        <h3>8.5 Compensación</h3>
        <p>
          Usted nos garantiza e indemniza por cualquier reclamación y/o
          procedimiento presentado por un tercero, cualquiera que sea su forma y
          naturaleza, que esté directa o indirectamente relacionado con la
          información proporcionada y otros Contenidos difundidos por Usted en
          Clim'app o que sea causado por Su uso de Clim'app que sea contrario a
          estos Términos y Condiciones Generales de Uso y a las leyes y/o
          reglamentos en vigor. Como tal, Usted será responsable de todos los
          daños a los que podamos ser condenados, así como de los costos y
          honorarios legales en los que debamos incurrir.
        </p>
        <p>
          Nos reservamos el derecho, a nuestra elección y a nuestra costa, de
          participar en la defensa y/o en la solución amistosa de una
          reclamación o de asumir solos la defensa y el control de los
          procedimientos, sin eximirle de sus obligaciones de indemnización.
          Usted no podrá, bajo ninguna circunstancia, aceptar una solución
          amistosa que implique Nuestra responsabilidad o que nos imponga
          ninguna obligación, sin Nuestro consentimiento previo.
        </p>

        <h2>IX - Limitación de nuestra responsabilidad</h2>

        <h3>9.1 Información general</h3>
        <p>
          Clim'app y sus Servicios se ofrecen "tal cual", "tal cual" y sin
          ninguna garantía expresa o implícita en cuanto a sus condiciones de
          uso y/o disponibilidad.
        </p>
        <p>
          Se le informa de que nuestros servicios cumplen con la legislación
          francesa vigente. No podemos ser considerados responsables en caso de
          incumplimiento de la legislación de otro Estado.
        </p>
        <p>
          En caso de incumplimiento de nuestros servicios, no se podrá exigir
          nuestra responsabilidad si el incumplimiento de los servicios, o su
          ejecución incorrecta, es atribuible a usted o es atribuible a un hecho
          imprevisible e insuperable de un tercero ajeno al servicio, o
          finalmente a un caso de fuerza mayor.
        </p>
        <p>
          En general, Nuestra responsabilidad no puede ser asumida en caso de
          uso de los Servicios que no cumplan con estos Términos y Condiciones
          Generales de Uso.
        </p>
        <p>
          Usted reconoce que la versión de Clim'app que descarga es una versión
          de prueba. Usted reconoce que todas o parte de las funcionalidades de
          la versión de prueba actual de Clim'app, como resultado, pueden estar
          incompletas y/o defectuosas. No nos hacemos responsables de ningún
          daño, pérdida de datos o información, alteraciones en Sus dispositivos
          y periféricos que la versión de prueba actual de Clim'app pueda
          causarle. En general, Usted reconoce que descarga esta versión de
          prueba bajo su propio riesgo y que no podemos ser considerados
          responsables de ningún daño directo o indirecto.
        </p>

        <h3>9.2 Calidad de la información y del contenido</h3>
        <p>
          Sólo estamos obligados por una obligación de medios. No apoyamos
          ninguna obligación de resultado de ningún tipo con respecto a la
          calidad de la información y del Contenido disponible en Clim'app.
        </p>
        <p>
          En general, no garantizamos la integridad, exhaustividad, exactitud,
          legitimidad, fiabilidad o disponibilidad de los Contenidos de la
          información y Servicios que ofrecemos en esta versión beta.
          Utilizaremos todos los medios disponibles para ofrecerle Contenido de
          calidad, pero bajo ninguna circunstancia podremos aceptar
          responsabilidad alguna por cualquier consecuencia que resulte, en
          particular, del uso de Nuestros Servicios por Usted o que resulte de
          la información proporcionada a través de Nuestros Servicios.
        </p>

        <h3>
          9.3 Limitación de Nuestra responsabilidad por el Contenido
          proporcionado por Usted
        </h3>
        <p>
          No podemos ser considerados responsables, civil o penalmente, por el
          Contenido enviado, distribuido o puesto en línea por Usted a través de
          Clim'app.{" "}
        </p>
        <p>
          Cuando tenemos conocimiento real de cualquier actividad o información
          ilegal cometida a través de o a través de sus Servicios, tomamos las
          medidas apropiadas de acuerdo con las leyes o reglamentos aplicables,
          lo que puede resultar en que no podamos acceder a dicha información,
          eliminándola a nuestra entera discreción, o comunicando inmediatamente
          actividades o información ilegales a las autoridades administrativas o
          judiciales correspondientes.{" "}
        </p>
        <p>
          Cuando el Contenido es eliminado o hecho inaccesible por Nosotros,
          Usted reconoce y acepta que no tiene derecho a ninguna compensación en
          relación con esta acción.
        </p>

        <h3>9.4 Seguridad/acceso</h3>
        <p>
          Nos esforzamos razonable y diligentemente por mantener la seguridad y
          la integridad operativa de Clim'app mediante la adopción de medidas de
          seguridad adaptadas a la naturaleza de los datos y a los riesgos que
          presenta Nuestra actividad. Sin embargo, muchos factores más allá de
          Nuestro control pueden interferir con el funcionamiento de Clim'app.
        </p>
        <p>
          Nos esforzamos, en la medida de lo posible, para mantener Nuestros
          Servicios accesibles los 7 días de la semana, las 24 horas del día.
        </p>
        <p>
          Nos reservamos el derecho de interrumpir, suspender temporalmente o
          modificar sin previo aviso el acceso a la totalidad o a una parte de
          los Servicios Clim'app, con el fin de garantizar el mantenimiento, la
          actualización o por cualquier otro motivo, en particular técnico, sin
          que estas operaciones den lugar a ninguna obligación o compensación,
          de la que nos esforzaremos por informarle con antelación, excepto en
          casos urgentes.
        </p>
        <p>Por lo tanto:</p>
        <ul>
          <li>
            Nuestra responsabilidad en caso de interrupción del acceso a
            Clim'app y de las consecuencias que puedan derivarse de ello no
            puede ser asumida;
          </li>
          <li>
            No nos hacemos responsables de ningún posible daño (directo o
            indirecto) o incidente temporal o permanente que pueda ser causado a
            Sus datos o equipos informáticos cuando Usted accede a Clim'app o
            cuando visita Su Cuenta o, en general, cuando envía a Su dispositivo
            los archivos y software que componen Clim'app. En particular, no nos
            hacemos responsables de la posible transmisión de un virus a través
            de Clim'app.
          </li>
          <li>
            Nosotros no somos responsables del uso fraudulento de Nuestros
            medios de distribución y rechazamos cualquier responsabilidad en
            caso de intrusión en Nuestros sistemas informáticos y robo de datos,
            sujeto a las disposiciones legales y reglamentarias obligatorias
            cuando sea aplicable en contrario, entendiéndose que Nosotros
            utilizamos todos los medios necesarios para prevenir dichas
            intrusiones ilegales.
          </li>
        </ul>

        <h3>9.5 Actualizaciones</h3>
        <p>
          A pesar de todos los esfuerzos para asegurar la exactitud de la
          información y/o documentos en Clim'app, rechazamos toda
          responsabilidad de cualquier tipo por cualquier inexactitud o falta de
          actualización de cualquier información y/o documento en Clim'app.
        </p>

        <h3>9.6 Daños</h3>
        <p>
          No nos hacemos responsables de los daños relacionados con un caso de
          fuerza mayor o con cualquier perjuicio moral, de imagen o comercial,
          pérdida de clientes, pérdida de imagen de marca, desorden comercial o
          cualquier otro daño especial, incidental o indirecto que surja de o en
          relación con Clim'app.
        </p>

        <h2>X - Divulgación a terceros</h2>
        <p>
          En la medida en que lo permita la ley aplicable, podremos divulgar
          cualquier Contenido o datos de Clim'app para cumplir con las leyes
          aplicables o si, de buena fe, creemos que dicha acción es necesaria,
          incluso en relación con procedimientos legales, para hacer cumplir los
          Términos de uso, para responder a quejas y/o reclamos que aleguen una
          infracción de los derechos de terceros, para proteger Nuestros
          derechos o intereses, los de Nuestros clientes o el público.
        </p>

        <h2>XI - Hipervínculos a otros servicios</h2>
        <p>
          Clim'app puede referirse a otros servicios (sitios web, aplicaciones,
          etc.) sobre los cuales no ejercemos ningún control técnico o de
          contenido. La existencia de un enlace de hipertexto a otro servicio no
          constituye una aprobación por nuestra parte de dicho servicio o de su
          contenido.
        </p>
        <p>
          Por lo tanto, no podemos ofrecer ninguna garantía en cuanto a la
          integridad o exactitud del contenido de estos servicios de terceros,
          ni en cuanto a su disponibilidad.
        </p>
        <p>
          No nos hacemos responsables de ningún daño directo o indirecto
          derivado de la consulta o del uso de los servicios web a los que se
          refiere Clim'app o de la información publicada en estos servicios.
          Tampoco asumimos ninguna responsabilidad por el procesamiento de datos
          personales en estos servicios.
        </p>

        <h2>XII - Propiedad Intelectual</h2>
        <p>
          Le concedemos únicamente un derecho gratuito, personal, no exclusivo e
          intransferible de acceso y uso de Clim'app, sujeto al cumplimiento de
          estos Términos y Condiciones Generales de Uso.
        </p>
        <p>
          Clim'app (considerado como un todo inseparable) y los diferentes
          elementos que aparecen en Clim'app (tales como textos, fotografías,
          catálogos, maquetas, marcas, logotipos, ilustraciones, software
          específico, vídeos) están protegidos por derechos de propiedad
          intelectual que nos pertenecen a nosotros o a nuestros licenciantes.
          En su caso, han sido autorizados para su publicación, distribución o
          derechos de uso adquiridos de estos terceros.
        </p>
        <p>
          El logotipo, nombres de productos, nombres de servicios o nombres de
          empresas mencionados en Clim'app pueden constituir marcas comerciales,
          nombres comerciales y nombres de empresas de sus respectivos
          propietarios. Usted se compromete expresamente a respetar estos
          derechos de propiedad intelectual.
        </p>
        <p>
          Usted acepta, sin que esta lista sea exhaustiva: modificar, copiar,
          distribuir, transmitir, difundir, publicar, licenciar, transferir,
          vender, reproducir o hacer reproducir total o parcialmente la
          información, software, producto o servicio obtenido de Clim'app,
          temporal o permanentemente, por cualquier proceso, conocido o
          desconocido, en cualquier medio, sin el consentimiento previo por
          escrito de Clim'app. El incumplimiento de esta prohibición constituye
          una infracción que puede acarrear la responsabilidad civil y penal del
          infractor.
        </p>

        <h2>XIII - Tratamiento de datos personales</h2>
        <p>
          En relación con el tratamiento de sus datos personales que llevamos a
          cabo, le remitimos a la Política de Privacidad.
        </p>

        <h2>XIV - Supresión de la cuenta</h2>
        <p>
          Usted puede eliminar Su Cuenta en cualquier momento y reconoce que la
          eliminación de Su Cuenta constituye la terminación de estos Términos y
          Condiciones.
        </p>
        <p>
          Podemos eliminar Su Cuenta por iniciativa propia, lo que resultará en
          la terminación de este Acuerdo (a menos que se disponga lo contrario
          incluso después de la terminación de este Acuerdo), en particular en
          los siguientes casos, esta lista no es exhaustiva:
        </p>
        <ul>
          <li>
            Esta eliminación de la iniciativa será el resultado de la parte no
            utilizada de Su Cuenta durante un período de tiempo determinado;
          </li>
          <li>
            Esta eliminación de la iniciativa constituye una medida temporal o
            definitiva tomada por Nosotros contra una cuenta sospechosa, por
            ejemplo cuando Nosotros tenemos razones para creer que Usted no está
            cumpliendo con estas condiciones;
          </li>
          <li>
            Esta supresión de la iniciativa puede ser el resultado de una
            quiebra de Clim'app o de cambios en Nuestras actividades
            comerciales.
          </li>
        </ul>
        <p>
          En la medida de lo posible y dentro de los límites legales aplicables,
          le notificaremos por adelantado de Nuestra decisión de eliminar Su
          Cuenta.
        </p>
        <p>
          Usted no puede reclamar ninguna compensación por la eliminación de Su
          Cuenta.
        </p>
        <p>
          Tras la eliminación de Su Cuenta, conservaremos Sus datos y Contenido
          que Usted haya publicado en Clim'app durante el tiempo necesario para
          las operaciones técnicas de eliminación de la Cuenta y, en cualquier
          caso, dentro de los límites y obligaciones impuestos por las leyes
          aplicables. Nos reservamos el derecho de mantener la información y el
          Contenido publicado en Clim'app que sea necesario para que podamos
          gestionar Su Instalación.
        </p>

        <h2>XV - Disposiciones finales</h2>

        <h3>
          15.1 Modificaciones de las presentes Condiciones Generales de Uso
        </h3>
        <p>
          Podemos modificar estos Términos y Condiciones Generales de Uso en
          cualquier momento, sin previo aviso, de acuerdo con los cambios
          realizados en Clim'app, cambios en la legislación o por cualquier otra
          razón legítima. La versión de estos Términos y Condiciones Generales
          de Uso que es aplicable contra Usted está disponible permanentemente
          en Clim'app. Usted se compromete a consultarlo regularmente.
        </p>
        <p>
          En el caso particular de la necesidad de adaptar Clim'app a los
          cambios legislativos, haremos todo lo posible para hacer estas
          adaptaciones lo más rápidamente posible. Durante este período, Usted
          reconoce que no podemos ser responsables por el incumplimiento
          temporal.
        </p>

        <h3>15.2 Acuerdo de prueba</h3>
        <p>
          Podemos intercambiar con Usted la información necesaria para Clim'app
          por medios electrónicos. Se presume que cualquier comunicación
          electrónica entre Usted y Nosotros tiene el mismo valor probatorio que
          un documento escrito en papel.
        </p>
        <p>
          En cualquier procedimiento judicial o administrativo relacionado con
          esta relación contractual se aceptará una versión impresa de estas
          Condiciones Generales de Uso y cualquier aviso de advertencia emitido
          en formato electrónico, sobre la misma base y bajo las mismas
          condiciones que otros documentos y registros comerciales creados y
          mantenidos en forma impresa.
        </p>

        <h3>15.3 Divisibilidad</h3>
        <p>
          En caso de que alguna de las disposiciones de estas Condiciones
          Generales de Uso sea declarada nula, inválida, ilegal o inaplicable en
          virtud de la legislación aplicable, en su totalidad o en parte, tal
          situación no afectará a la validez de las disposiciones restantes, a
          condición de que una disposición esencial sea nula o de que las
          disposiciones contractuales sean interdependientes. La cláusula
          inválida, nula, ilegal o inaplicable se sustituirá retroactivamente
          por una cláusula válida y aplicable cuyo contenido sea lo más parecido
          posible al de la cláusula original.
        </p>

        <h3>15.4 Fuerza mayor</h3>
        <p>
          No obstante cualquier disposición en contrario, ninguna Parte
          Contratante será responsable por cualquier retraso en el cumplimiento
          o incumplimiento de sus obligaciones resultante de eventos de fuerza
          mayor (tales como huelgas, guerras, terremotos, cataclismos de
          cualquier tipo, efectos directos o indirectos de explosión, incendio,
          desprendimiento de calor, inundaciones o cualquier otro evento
          imprevisible e irresistible de fuerza mayor externa).
        </p>

        <h3>15.5 Relaciones</h3>
        <p>
          Estos Términos y Condiciones de Uso no se considerarán en ningún caso
          una empresa conjunta u otra asociación entre Usted y Nosotros, y
          ninguna de las Partes Contratantes de estos Términos y Condiciones de
          Uso se considerará el agente o empleado de la otra. La relación que
          tenemos con usted es la de un contratista independiente.
        </p>
        <p>
          En el caso de una disputa entre Usted y un tercero, Nosotros no
          tenemos obligación de involucrarnos. Usted nos libera a nosotros,
          nuestros directores, empleados y otros miembros de cualquier acción,
          daños de cualquier tipo, conocidos o desconocidos, relacionados con
          dichos conflictos a los que Usted estará obligado.
        </p>

        <h3>15.6 Notificación</h3>
        <p>
          Cualquier comunicación o notificación que le hagamos será válida si se
          envía a la dirección de correo electrónico que Usted nos ha
          proporcionado, incluso si ya no es válida.
        </p>

        <h3>15.8 Ley aplicable y tribunales competentes</h3>
        <p>
          Estas Condiciones Generales de Uso se regirán e interpretarán de
          acuerdo con la legislación francesa.
        </p>
        <p>
          Cualquier litigio relativo a la utilización de Clim'app y a la
          validez, interpretación, ejecución o no ejecución de las presentes
          Condiciones Generales de Uso será competencia exclusiva de los
          Tribunales de París, para cualquier tipo de procedimiento.
        </p>
      </div>
    );
  }
}
