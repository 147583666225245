import React from "react";
import ShippingType from "../models/ShippingType";
import I18n from "i18n-js";

class ShippingUtils {
  static renderShippingSitesLabel(type, site, originSite = null) {
    if (!originSite) {
      return (
        <strong>{site ? site.designation : I18n.t("common.unknown")}</strong>
      );
    }

    const labels =
      type === ShippingType.OUT
        ? [
            <strong key="originSite">{originSite.designation}</strong>,
            <span key="site">{site.designation}</span>,
          ]
        : [
            <span key="originSite">{originSite.designation}</span>,
            <strong key="site">{site.designation}</strong>,
          ];

    return (
      <span>
        {labels[0]} &#8594; {labels[1]}
      </span>
    );
  }
}

export default ShippingUtils;
