import React from "react";
import { useQuery } from "@apollo/client";
import LoadingWrapper from "../../common/components/elements/LoadingWrapper";
import bsffFromShippingIn from "../graphql/queries/bsffFromShippingIn.graphql";
import PropTypes from "prop-types";
import BsffList from "./BsffList";

BsffShippingReceiptInformation.propTypes = {
  shippingUuid: PropTypes.string.isRequired,
};

export default function BsffShippingReceiptInformation({ shippingUuid }) {
  const { loading, error, data } = useQuery(bsffFromShippingIn, {
    variables: {
      uuid: shippingUuid,
    },
  });

  return (
    <LoadingWrapper loading={loading} error={Boolean(error)}>
      {() => {
        const bsffs = data.bsffFromShippingIn ?? [];

        return <BsffList bsffs={bsffs} />;
      }}
    </LoadingWrapper>
  );
}
