import React, { Component, createRef } from "react";
import { bool, func, oneOf, oneOfType, string } from "prop-types";

/**
 * @deprecated Please use StatelessCheckbox as a controlled component
 */
class Checkbox extends Component {
  static propTypes = {
    id: string.isRequired,
    label: oneOfType([string, oneOf([false])]).isRequired,
    checked: bool,
    onChange: func,
    className: string,
  };

  static defaultProps = {
    checked: false,
    onChange: null,
    className: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      checked: props.checked,
    };

    this.checkbox = createRef();

    this.onChange = this.onChange.bind(this);
  }

  /**
   * @return {String}
   */
  get value() {
    return this.checkbox.current.checked;
  }

  /**
   * @param {Boolean} value
   */
  set value(value) {
    this.checkbox.current.checked = value;
    this.setState({ checked: value });
  }

  /**
   * On change event
   */
  onChange() {
    const { onChange } = this.props;
    const { checked } = this.state;
    const value = !checked;

    this.setState({ checked: value });

    if (onChange) {
      onChange(value);
    }
  }

  /**
   * {@inheritdoc}
   */
  render() {
    const { checked } = this.state;
    const { id, label, className } = this.props;

    return (
      <div className={className}>
        <input
          id={id}
          type="checkbox"
          ref={this.checkbox}
          checked={checked}
          onChange={this.onChange}
        />
        <label htmlFor={id}>{label !== false ? label : " "}</label>
      </div>
    );
  }
}

export default Checkbox;
