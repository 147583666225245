import React from "react";
import PropTypes from "prop-types";
import { Card } from "../../../common/components/elements/Card";
import { formatDate } from "../../../common/utils/dateUtils";
import StatelessTable from "../../../common/components/elements/StatelessTable";
import { useQuery } from "@apollo/client";
import Spinner from "../../../common/components/Spinner";
import getSite from "../../graphql/queries/summary/getSite.graphql";
import getCarrier from "../../../company/graphql/queries/getCarrier.graphql";
import { WasteRequestInput, WasteRequestSummary } from "../../propTypes/types";
import { requiredChildren } from "../../../common/propTypes/types";

RequestSummary.propTypes = {
  request: PropTypes.oneOfType([WasteRequestInput, WasteRequestSummary]),
  renderArticleRow: PropTypes.func.isRequired,
  renderContainerRow: PropTypes.func.isRequired,
  children: requiredChildren,
};

export default function RequestSummary({
  request: {
    siteUuid,
    date,
    comments,
    nbCages,
    nbPallets,
    attestationReceiptAddress,
    containers,
    articles,
    charteredByClient,
    carrierUuid,
    transportNumber,
  },
  renderArticleRow,
  renderContainerRow,
  children,
}) {
  return (
    <div className="row">
      <div className="col-xl-6">
        <Card title="Informations générales" className="h-100">
          {siteUuid && <SiteRows uuid={siteUuid} />}
          <DetailRow title="Date de reprise souhaitée">
            <span>{date && formatDate(new Date(date))}</span>
          </DetailRow>
          {attestationReceiptAddress && (
            <DetailRow title="Adresse d'envoi postal de l'attestation de traitement">
              <Address address={attestationReceiptAddress} />
            </DetailRow>
          )}
          <DetailRow title="Commentaires">
            {comments && <p>{comments}</p>}
          </DetailRow>
        </Card>
      </div>

      <div className="col-xl-6 order-last order-xl-0 mt-4 mt-xl-0">
        <Card title="Transport" className="h-100">
          <DetailRow title="Transport affrété par mes soins">
            <span>{charteredByClient ? "Oui" : "Non"}</span>
          </DetailRow>
          {charteredByClient && [
            <DetailRow title="Transporteur" key="carrier">
              {carrierUuid && <CarrierRow uuid={carrierUuid} />}
            </DetailRow>,
            <DetailRow title="N° du doc de transport déchet" key="doc">
              <span>{transportNumber}</span>
            </DetailRow>,
          ]}
          <DetailRow title="Nombre de palettes">
            <span>{nbPallets}</span>
          </DetailRow>
          <DetailRow title="Nombre de cages">
            <span>{nbCages}</span>
          </DetailRow>
        </Card>
      </div>

      <div className="col-12 mt-4">
        <Card title="Contenants de récupération">
          <p>{containers.length} contenants de récupération à reprendre</p>

          <StatelessTable
            tableClasses="table-sm table-striped"
            columnStyle={[
              null,
              null,
              null,
              "text-right",
              "text-right",
              null,
              "text-center",
              "text-center",
            ]}
            headData={[
              "Identifiant",
              "Désignation",
              "Fluide",
              "Volume",
              "Qté actuelle",
              "Traitement",
              "Destruction contenant",
              "Renvoi contenant",
              "Support logistique",
            ]}
            data={containers}
            rowData={(container) => renderContainerRow(container)}
          />
        </Card>
      </div>

      <div className="col-12 mt-4">
        <Card title="Articles hors récupération">
          <p>{articles.length} articles hors récupération à reprendre</p>

          <StatelessTable
            tableClasses="table-sm table-striped"
            columnStyle={[
              null,
              null,
              "text-right",
              "text-right",
              null,
              "text-center",
              "text-center",
            ]}
            headData={[
              "Article hors récupération",
              "Producteur",
              "Nombre",
              "Masse nette",
              "Traitement",
              "Destruction contenant",
              "Renvoi contenant",
              "Support logistique",
            ]}
            data={articles}
            rowData={(article) => renderArticleRow(article)}
          />
        </Card>
      </div>

      {children}
    </div>
  );
}

function SiteRows({ uuid }) {
  const { loading, data } = useQuery(getSite, {
    variables: { uuid },
  });

  if (loading) {
    return <Spinner />;
  }

  const site = data.getSite;

  return [
    <DetailRow title="Site" key="site">
      <SiteRow site={site} />
    </DetailRow>,
    <DetailRow title="Propriétaire du site" key="site-client">
      <ClientRow client={site.client} />
    </DetailRow>,
    <DetailRow title="Administratif" key="administrative-client">
      <ClientRow client={site.administrativeClient} />
    </DetailRow>,
  ];
}

/* eslint-disable react/prop-types */
function ClientRow({ client }) {
  return (
    <>
      <span key="value">{client.legalCompanyName}</span>
      <Address address={client.address} className="text-muted" />
    </>
  );
}

function SiteRow({ site }) {
  return <span key="value">{site.designation}</span>;
}

function CarrierRow({ uuid }) {
  const { loading, data } = useQuery(getCarrier, {
    variables: { uuid },
  });

  const carrier = data?.getCarrier;

  return [
    <Spinner show={loading} key="loader" />,
    carrier && <span key="value">{carrier.legalCompanyName}</span>,
  ];
}

export function DetailRow({ title, children, ...props }) {
  return (
    <div className="row mb-2" {...props}>
      <strong className="col-lg-3">{title}</strong>
      <div className="col-lg-9">{children}</div>
    </div>
  );
}

function Address({ address, className }) {
  return (
    <address className={className}>
      <span>{address.street}</span>
      <br />
      {address.streetAddition && [
        <span key="addition">{address.streetAddition}</span>,
        <br key="br" />,
      ]}
      {
        <span>
          {address.city}, {address.postalCode} - {address.country}
        </span>
      }
    </address>
  );
}

export function BooleanCell({ value }) {
  return value ? (
    <i className="fa fa-check-circle" />
  ) : (
    <i className="fa fa-circle-o text-muted" />
  );
}
/* eslint-enable react/prop-types */
