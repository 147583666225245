import I18n from "i18n-js";
import { fetch } from "../../../appContainer";
import {
  GET_SITES_LIST_IN_PROGRESS,
  GET_SITES_LIST_SUCCESS,
  GET_SITES_LIST_FAIL,
  GET_SITE_INFOS_IN_PROGRESS,
  GET_SITE_INFOS_SUCCESS,
  GET_SITE_INFOS_FAIL,
  POST_SITE_IN_PROGRESS,
  POST_SITE_SUCCESS,
  POST_SITE_FAIL,
  PUT_SITE_IN_PROGRESS,
  PUT_SITE_SUCCESS,
  PUT_SITE_FAIL,
  DELETE_SITE_IN_PROGRESS,
  DELETE_SITE_SUCCESS,
  DELETE_SITE_FAIL,
  IMPORT_SITES_IN_PROGRESS,
  IMPORT_SITES_SUCCESS,
  IMPORT_SITES_FAIL,
} from "../constants";
import { SUBMIT_SUCCESS, SUBMIT_FAIL } from "../../common/constants";
import { extractHeaderAttribute } from "../../common/utils/headerUtils";

/**
 * Get sites list
 */
export function getSitesList() {
  return (dispatch) => {
    dispatch({ type: GET_SITES_LIST_IN_PROGRESS });

    fetch("api").getSitesList(
      (list) => {
        dispatch({ type: GET_SITES_LIST_SUCCESS, payload: { list } });
      },
      () => dispatch({ type: GET_SITES_LIST_FAIL }),
    );
  };
}

/**
 * Get site infos
 */
export function getSiteInfos(uuid) {
  return (dispatch) => {
    dispatch({ type: GET_SITE_INFOS_IN_PROGRESS });

    fetch("api").getSiteInfos(
      uuid,
      (infos) => {
        dispatch({ type: GET_SITE_INFOS_SUCCESS, payload: { infos } });
      },
      () => dispatch({ type: GET_SITE_INFOS_FAIL }),
    );
  };
}

/**
 * @param {Object}  client
 * @param {String?} uuid
 *
 * @return {Object}
 */
export function getSitePayloadFromClient(client, uuid) {
  return {
    clientUuid: client.uuid || uuid,
    designation: `${client.legalCompanyName || client.name} - ${
      client.address.city
    }`,
    siret: client.siret || null,
    address: {
      country: client.address.country,
      city: client.address.city,
      postal: client.address.postal,
      street: client.address.street,
      addressAddition: client.address.addressAddition,
    },
  };
}

/**
 * Create site
 *
 * @param {Object}  site
 * @param {Boolean} isMobileSite
 */
export function createSite(site, isMobileSite) {
  return (dispatch) => {
    dispatch({ type: POST_SITE_IN_PROGRESS });

    fetch("api").createSite(
      site,
      () => {
        const message = I18n.t(
          `alerts.create:${isMobileSite ? "site_mobile" : "site"}.success`,
        );

        dispatch({ type: SUBMIT_SUCCESS, payload: { message } });
        dispatch({ type: POST_SITE_SUCCESS });
      },
      (error) => {
        const message = I18n.t(
          `alerts.create:${isMobileSite ? "site_mobile" : "site"}.fail`,
        );

        dispatch({ type: SUBMIT_FAIL, payload: { message } });
        dispatch({
          type: POST_SITE_FAIL,
          payload: { violations: error.violations },
        });
      },
    );
  };
}

/**
 * Update site
 *
 * @param {String}  uuid
 * @param {Object}  site
 * @param {Boolean} isMobileSite
 */
export function updateSite(uuid, site, isMobileSite) {
  return (dispatch) => {
    dispatch({ type: PUT_SITE_IN_PROGRESS });

    fetch("api").updateSite(
      uuid,
      site,
      () => {
        const message = I18n.t(
          `alerts.update:${isMobileSite ? "site_mobile" : "site"}.success`,
        );

        dispatch({ type: SUBMIT_SUCCESS, payload: { message } });
        dispatch({ type: PUT_SITE_SUCCESS });
      },
      (error) => {
        const message = I18n.t(
          `alerts.update:${isMobileSite ? "site_mobile" : "site"}.fail`,
        );

        dispatch({ type: SUBMIT_FAIL, payload: { message } });
        dispatch({
          type: PUT_SITE_FAIL,
          payload: { violations: error.violations },
        });
      },
    );
  };
}

/**
 * Delete site
 *
 * @param {Object}  site
 * @param {Boolean} isMobileSite
 */
export function deleteSite(uuid, isMobileSite) {
  return (dispatch) => {
    dispatch({ type: DELETE_SITE_IN_PROGRESS });

    fetch("api").deleteSite(
      uuid,
      () => {
        const message = I18n.t(
          `alerts.delete:${isMobileSite ? "site_mobile" : "site"}.success`,
        );

        dispatch({ type: SUBMIT_SUCCESS, payload: { message } });
        dispatch(getSitesList());
        dispatch({ type: DELETE_SITE_SUCCESS });
      },
      () => {
        const message = I18n.t(
          `alerts.delete:${isMobileSite ? "site_mobile" : "site"}.fail`,
        );

        dispatch({ type: DELETE_SITE_FAIL });
        dispatch({ type: SUBMIT_FAIL, payload: { message } });
      },
    );
  };
}

/**
 * Import sites
 */
export function importSites(tsv) {
  return (dispatch) => {
    dispatch({ type: IMPORT_SITES_IN_PROGRESS });

    fetch("api").importSites(
      tsv,
      (tsvReportContent, response) => {
        const message = I18n.t("alerts.import:sites.success");
        const tsvReport = tsvReportContent && {
          name: extractHeaderAttribute(
            response.headers,
            "Content-Disposition",
            "filename",
          ),
          content: tsvReportContent,
        };

        dispatch({ type: SUBMIT_SUCCESS, payload: { message } });
        dispatch({ type: IMPORT_SITES_SUCCESS, payload: { tsvReport } });
      },
      (content, response) => {
        let message = I18n.t("alerts.import:sites.fail");
        let error = true;

        // File too large:
        if (response.status === 413) {
          message = I18n.t("alerts.import:sites.file_too_large");
        }
        // Validation error:
        if ([422, 400].includes(response.status)) {
          error = content;
        }

        dispatch({ type: SUBMIT_FAIL, payload: { message } });
        dispatch({ type: IMPORT_SITES_FAIL, payload: { error } });
      },
    );
  };
}
