/* eslint react/no-unescaped-entities: 0 */
import React, { Component } from "react";

export default class PrivacyPolicy extends Component {
  /**
   * {@inheritdoc}
   */
  render() {
    return (
      <div className="container">
        <h1>CHARTER PRIVÉLEVEN CLIM'APP</h1>
        <h2>
          2. Het belang van de bescherming van persoonsgegevens op Clim'app
        </h2>

        <p>
          Climalife (de onderneming Dehon Service waarvan de maatschappelijke
          zetel gevestigd is te 4, rue de la Croix Faubin 75011 PARIS), in haar
          hoedanigheid van beheerder van Clim's app, maakt zich zorgen over het
          respect van de privacy van de Gebruiker bij het gebruik van Clim's app
          en is zich bewust van het belang ervan.
        </p>

        <p>
          Het doel van dit beleid is om u te informeren over onze praktijken met
          betrekking tot het verzamelen, gebruiken en delen van persoonlijke
          gegevens (de Gegevens) en is gericht aan elke persoon die de site
          bezoekt en gebruik maakt van de Clim'app mobiele applicatie. Deze
          persoon wordt hier aangeduid als de "Gebruiker".
        </p>

        <p>
          De gebruiker kan contact opnemen met Climalife op het hierboven
          vermelde postadres. Voor elke vraag met betrekking tot dit Handvest
          kan hij ook schrijven naar het volgende e-mailadres:
          info@climapp.dehon.com.
        </p>
        <h2>3. De verwerkte gegevens</h2>

        <p>
          In het kader van Clim'app zal Climalife waarschijnlijk de volgende
          persoonsgegevens van de Klant en/of de Agent verwerken:
        </p>

        <ul>
          <li>De achternaam en voornaam van de gebruiker.</li>
          <li>
            Het wachtwoord en de identificator van de Gebruiker die toegang
            geeft tot zijn Account op Clim's app (het wachtwoord wordt nooit in
            duidelijke tekst opgeslagen op de servers van Climalife, in
            overeenstemming met de vereisten van de Nationale Commissie voor
            Informatietechnologie en Burgerlijke Vrijheden);
          </li>
          <li>Het IP-adres (automatisch gedetecteerd) van de gebruiker;</li>
          <li>
            Informatie over het gebruik van Clim'app (inclusief de geraadpleegde
            pagina's) door de Gebruiker;
          </li>
          <li>
            In het algemeen wordt alle informatie die vrijwillig wordt gegeven,
            bijvoorbeeld bij het gebruik van Clim'app door de Gebruiker.
          </li>
        </ul>

        <h2>4. De doeleinden van de verwerking van deze gegevens</h2>

        <p>Climalife verwerkt deze gegevens voor de volgende doeleinden:</p>

        <ul>
          <li>
            de toegang tot en het beheer van de rekening in het kader van het
            gesloten contract mogelijk te maken;
          </li>
          <li>
            Om de gebruiker in staat te stellen gebruik te maken van alle
            diensten die worden aangeboden op Clim'app, inclusief identificatie
            in CERFA-documenten;
          </li>
          <li>
            Om de technische administratie en veiligheid van Clim'app te
            waarborgen;
          </li>
          <li>
            De informatie over producten en diensten die vergelijkbaar zijn met
            de Clim'app die Climalife aanbiedt, ook per e-mail aan de gebruiker
            doorsturen;
          </li>
          <li>
            Het opstellen van statistieken, in het bijzonder over het gebruik
            van de verschillende secties van Clim'app door de Gebruiker;
          </li>
          <li>
            Informatie over andere producten en diensten die Climalife aanbiedt
            of die door derden worden aangeboden, ook via e-mail, door te geven.
          </li>
        </ul>

        <p>
          Deze behandelingen zijn gebaseerd op uw toestemming bij het openen van
          uw account.
        </p>
        <h2>5. Veiligheid en vertrouwelijkheid</h2>

        <p>
          De persoonsgegevens van de gebruiker worden zonder zijn toestemming
          niet doorgegeven aan derden, met uitzondering van de onderaannemers
          van Climalife en andere entiteiten van de DEHON-groep. De
          onderaannemers zullen enkel in opdracht en voor rekening van Climalife
          handelen in het kader van het gesloten contract.
        </p>

        <p>
          Climalife kan ook informatie over de Gebruiker delen met andere
          entiteiten van de DEHON-groep in het kader van dit Handvest.
        </p>

        <p>
          Climalife behoudt zich echter ook het recht voor om persoonsgegevens
          bekend te maken op verzoek van een administratieve of gerechtelijke
          instantie of indien Climalife te goeder trouw van mening is dat een
          dergelijke bekendmaking noodzakelijk is om te voldoen aan de geldende
          wet- of regelgeving.
        </p>

        <p>
          Als algemene regel geldt dat wij uw persoonsgegevens binnen de
          Europese Unie bewaren. In gevallen waarin wij een deel van uw
          persoonsgegevens aan derde landen doorgeven, zorgen wij er echter voor
          dat deze overdracht wordt uitgevoerd in overeenstemming met de
          geldende voorschriften en garanderen wij een voldoende niveau van
          bescherming van de privacy en de fundamentele rechten van personen in
          overeenstemming met de DDR.
        </p>
        <h2>6. Bewaartermijn</h2>

        <p>
          De gegevens worden aan het einde van de volgende periodes
          gearchiveerd:
        </p>

        <ul>
          <li>
            5 jaar na het laatste gebruik van het Platform door de
            hoofdaccounthouder, als de rekening niet is afgesloten;
          </li>
          <li>2 jaar na de sluiting van de rekening.</li>
        </ul>

        <h2>7. Rechten van de gebruiker</h2>
        <h3>1. Recht van toegang :</h3>

        <p>
          De Gebruiker kan toegang hebben tot zijn persoonlijke gegevens die
          door ClimApp worden verwerkt. Op verzoek zal Climalife de gebruiker
          een kopie van alle persoonlijke gegevens en alle wettelijk vereiste
          informatie verstrekken, inclusief :
        </p>

        <ul>
          <li>
            De categorieën van persoonsgegevens die worden verzameld en
            verwerkt;
          </li>
          <li>De doeleinden van hun verwerking;</li>
          <li>
            De categorieën van ontvangers aan wie de persoonsgegevens zijn
            verstrekt of waarschijnlijk zullen worden verstrekt;
          </li>
          <li>De bewaartermijn van persoonsgegevens;</li>
          <li>
            Informatie over de rechten van de Gebruiker met betrekking tot zijn
            persoonlijke gegevens.
          </li>
        </ul>

        <h3>2. Recht op correctie</h3>

        <p>
          De Gebruiker kan vragen om zijn persoonlijke gegevens te corrigeren
          als deze onjuist, onvolledig of verouderd blijken te zijn.
        </p>

        <h3>3. Recht op verwijdering</h3>

        <p>
          De Gebruiker kan in de volgende gevallen verzoeken om zijn
          persoonsgegevens te laten verwijderen:
        </p>

        <ul>
          <li>
            Als zijn persoonsgegevens niet meer nodig zijn voor de doeleinden
            waarvoor ze worden verzameld of verwerkt;
          </li>
          <li>
            Indien de Gebruiker bezwaar heeft gemaakt tegen het verzamelen of
            verwerken van zijn/haar persoonsgegevens in overeenstemming met de
            volgende paragraaf van dit artikel;
          </li>
          <li>
            Indien de verwerking van de persoonsgegevens van de Gebruiker
            onwettig is;
          </li>
          <li>
            Indien de persoonlijke gegevens van de Gebruiker moeten worden
            verwijderd om ClimApp in staat te stellen te voldoen aan een
            wettelijke verplichting van haar kant.
          </li>
        </ul>

        <h3>4. Recht van bezwaar</h3>

        <p>
          Behoudens gerechtvaardigde redenen die verband houden met de
          bijzondere aard van zijn situatie, kan de Klant en/of de Agent zich
          verzetten tegen elke verwerking van zijn persoonsgegevens wanneer zijn
          persoonsgegevens worden verwerkt op basis van de noodzaak om een
          opdracht van algemeen belang uit te voeren of het legitieme belang van
          ClimApp na te streven. De Klant en/of de Agent kan zich ook altijd,
          zonder opgave van redenen, verzetten tegen de verwerking van zijn
          persoonsgegevens wanneer deze plaatsvindt voor commerciële
          prospectiedoeleinden.
        </p>

        <h3>5. Recht op beperking van de verwerking</h3>

        <p>
          De Gebruiker kan in de volgende gevallen verzoeken om beperking van de
          verwerking van zijn/haar persoonsgegevens:
        </p>

        <ul>
          <li>
            In het geval dat hij de juistheid van zijn persoonlijke gegevens
            betwist, gedurende de tijd die nodig is voor Climalife om deze te
            verifiëren;
          </li>
          <li>
            Indien de verwerking van zijn persoonsgegevens illegaal is en de
            Gebruiker deze wil beperken in plaats van de verwijdering van deze
            gegevens te vragen;
          </li>
          <li>
            Indien de Gebruiker bezwaar heeft gemaakt tegen de verwerking van
            zijn persoonsgegevens, dan verifieert ClimApp gedurende de periode
            dat zij geen andere doorslaggevende en gerechtvaardigde redenen
            heeft om deze te blijven verwerken.
          </li>
        </ul>

        <h3>6. Uitoefening van bovengenoemde rechten</h3>

        <p>
          Deze rechten kunnen worden uitgeoefend door eenvoudigweg een e-mail te
          sturen naar het volgende adres: info@climapp.dehon.com, die een
          leesbare kopie van het identiteitsbewijs van de gebruiker moet
          bevatten en, voor de uitoefening van het recht van verzet, een
          beschrijving van de redenen voor het verzet.
        </p>

        <p>
          Climalife kan bezwaar maken tegen verzoeken om een of meer van de
          bovengenoemde rechten uit te oefenen indien deze verzoeken misbruik
          lijken te maken, met name wat betreft de frequentie, het aantal, het
          repetitieve of het systematische karakter ervan.
        </p>
      </div>
    );
  }
}
