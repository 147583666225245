import I18n from "i18n-js";
import React, { Component } from "react";
import { shape } from "prop-types";
import { Link } from "react-router-dom";
import classnames from "classnames";
import HeadUserInfos from "./HeadUserInfos";
import SelectLanguage from "./SelectLanguage";
import Dropdown from "../elements/Dropdown";
import ClientTypes from "../../../company/models/ClientTypes";
import Roles from "../../../users/models/Roles";
import {
  ROUTE_HOME,
  ROUTE_MY_ENTERPRISE,
  ROUTE_USERS,
  ROUTE_SITES,
  ROUTE_INSTALLATIONS,
  ROUTE_MOBILE_SITES,
  ROUTE_CARRIERS,
  ROUTE_LEAK_DETECTORS,
  ROUTE_CONTAINERS,
  ROUTE_INTERVENTIONS,
  ROUTE_FIBSDS,
  ROUTE_SHIPPINGS,
  ROUTE_CLIENTS,
  ROUTE_PROVIDERS,
  ROUTE_PROVIDER_CONTRACTS,
  ROUTE_FLUID_SUMMARIES,
  ROUTE_INTERVENTIONS_PLANNED,
  // Tmp hide waste recovery request feature
  // ROUTE_MY_WASTE_RECOVERY_REQUESTS,
  // ROUTE_COMPANY_WASTE_RECOVERY_REQUESTS,
} from "../../../../routes";

class Header extends Component {
  static navigationItems = {
    dashboard: ROUTE_HOME,
    organization: {
      my_enterprise: ROUTE_MY_ENTERPRISE,
      users: ROUTE_USERS,
      carriers: ROUTE_CARRIERS,
      clients: ROUTE_CLIENTS,
      sites: ROUTE_SITES,
      mobileSites: ROUTE_MOBILE_SITES,
    },
    interventions: {
      interventionsCompleted: ROUTE_INTERVENTIONS,
      interventionsPlanned: ROUTE_INTERVENTIONS_PLANNED,
      fibsds: ROUTE_FIBSDS,
      fluid_summaries: ROUTE_FLUID_SUMMARIES,
    },
    shippings: ROUTE_SHIPPINGS,
    stuff: {
      leak_detectors: ROUTE_LEAK_DETECTORS,
      containers: ROUTE_CONTAINERS,
      installations: ROUTE_INSTALLATIONS,
    },
    service_providers: {
      providers_list: ROUTE_PROVIDERS,
      provider_contracts_list: ROUTE_PROVIDER_CONTRACTS,
    },
    // Tmp hide waste recovery request feature
    // my_waste_recovery_requests: ROUTE_MY_WASTE_RECOVERY_REQUESTS,
    // company_waste_recovery_requests: ROUTE_COMPANY_WASTE_RECOVERY_REQUESTS,
  };

  static adminRoutes = ["users", "fibsds"];
  static commercialAndSalesRoutes = ["company_waste_recovery_requests"];
  static commercialAndSalesExcludedRoutes = ["my_waste_recovery_requests"];
  static DOCFRoutes = ["service_providers"];

  static propTypes = {
    location: shape().isRequired,
    userProfile: shape().isRequired,
    ownClient: shape().isRequired,
  };

  constructor() {
    super();

    this.state = {
      openMenu: false,
    };

    this.toggleMenu = this.toggleMenu.bind(this);
  }

  /**
   * @param {String} route
   *
   * @return {String}
   */
  getNavLinkClasses(route) {
    const { location } = this.props;
    const currentLocation = location.pathname.replace(/\//g, "");
    const shortRoute = route.replace(/\//g, "");

    return classnames("nav-link", {
      active: !shortRoute.length
        ? currentLocation === shortRoute
        : currentLocation.includes(shortRoute),
    });
  }

  toggleMenu() {
    const { openMenu } = this.state;

    this.setState({ openMenu: !openMenu });
  }

  /**
   * Render navigation elements
   *
   * @return {Element|null}
   */
  renderNavigationItems() {
    const routes = Header.navigationItems;
    const { ownClient, userProfile } = this.props;
    const userRoles = userProfile.roles;

    return Object.keys(routes).map((key) => {
      const route = routes[key];

      // Commercial & sales
      if (
        Header.commercialAndSalesRoutes.includes(key) &&
        !(
          Roles.userHasRole(userRoles, Roles.COMMERCIAL) ||
          Roles.userHasRole(userRoles, Roles.SALES)
        )
      ) {
        return null;
      }

      if (
        Header.commercialAndSalesExcludedRoutes.includes(key) &&
        (Roles.userHasRole(userRoles, Roles.COMMERCIAL) ||
          Roles.userHasRole(userRoles, Roles.SALES))
      ) {
        return null;
      }

      // Admin
      if (
        Header.adminRoutes.includes(key) &&
        !Roles.userHasRole(userRoles, Roles.ADMIN)
      ) {
        return null;
      }

      // CF / DO
      if (Header.DOCFRoutes.includes(key)) {
        const isAuthorized = new ClientTypes(
          ownClient.type,
        ).canHaveFinalClient();

        if (!isAuthorized) {
          return null;
        }
      }

      // Sub navigation
      if (typeof route === "object") {
        return (
          <Dropdown
            key={key}
            className="nav-item"
            tagName="li"
            label={I18n.t(`navbar.${key}`)}
          >
            {Object.keys(route).map((subKey) => {
              const subRoute = route[subKey];

              if (
                Header.adminRoutes.includes(subKey) &&
                !Roles.userHasRole(userRoles, Roles.ADMIN)
              ) {
                return null;
              }

              return (
                <Link key={subKey} to={subRoute}>
                  {I18n.t(`navbar.${subKey}`)}
                </Link>
              );
            })}
          </Dropdown>
        );
      }

      return (
        <li key={key} className="nav-item">
          <Link to={route} className={this.getNavLinkClasses(route)}>
            {I18n.t(`navbar.${key}`)}
          </Link>
        </li>
      );
    });
  }

  /**
   * {@inheritdoc}
   */
  render() {
    const { openMenu } = this.state;
    const navClasses = classnames("nav", {
      "nav--open": openMenu,
    });

    return (
      <header className="header">
        <div className="col-12 header__content">
          <Link to={ROUTE_HOME} className="logo">
            <img src="/dist/assets/images/logo.png" alt="Clim'app" />
            {AppConfig.APP_RUNTIME !== "production" && (
              <span className="env-logo-hint">{AppConfig.APP_RUNTIME}</span>
            )}
          </Link>
          <div className={navClasses}>
            <ul>
              {this.renderNavigationItems()}
              <HeadUserInfos />
              <SelectLanguage />
            </ul>
          </div>
          <i className="fa fa-bars hamburger" onClick={this.toggleMenu}></i>
        </div>
      </header>
    );
  }
}

export default Header;
