import React from "react";
import { node, oneOf, string } from "prop-types";
import classnames from "classnames";

ContentPage.MEDIUM = "MEDIUM";
ContentPage.INTERMEDIATE = "INTERMEDIATE";
ContentPage.LARGE = "LARGE";
ContentPage.SIZES = {
  [ContentPage.MEDIUM]: "col-xs-12 col-sm-10 col-lg-7",
  [ContentPage.INTERMEDIATE]: "col-md-8 col-lg-9",
  [ContentPage.LARGE]: "col-md-12 col-lg-11",
};

ContentPage.propTypes = {
  children: node.isRequired,
  title: string,
  size: oneOf([
    ContentPage.MEDIUM,
    ContentPage.INTERMEDIATE,
    ContentPage.LARGE,
  ]),
  className: string,
};

ContentPage.defaultProps = {
  title: null,
  size: ContentPage.MEDIUM,
  className: null,
};

export default function ContentPage({ children, size, title, className }) {
  return (
    <div className={classnames("row justify-content-center", className)}>
      <div className={ContentPage.SIZES[size]}>{title && <h1>{title}</h1>}</div>
      <div className={ContentPage.SIZES[size]}>{children}</div>
    </div>
  );
}
