/* eslint react/no-unescaped-entities: 0 */
import React, { Component } from "react";

export default class CGU extends Component {
  /**
   * {@inheritdoc}
   */
  render() {
    return (
      <div className="container">
        <h1>Algemene gebruiksvoorwaarden CLIM'app</h1>

        <p>
          Clim'app wordt beheerst door deze algemene gebruiksvoorwaarden (GCU).
          Behalve in het geval van een speciale schriftelijke overeenkomst,
          houdt elke bestelling en/of elk gebruik van de Clim's app diensten
          door de klant (Klant) automatisch in dat de Klant zich aan deze
          gebruiksvoorwaarden houdt. De Klant verbindt zich ertoe deze te
          respecteren en ervoor te zorgen dat ze door zijn medewerkers en/of
          partners worden gerespecteerd bij het aanmaken van een Account, bij de
          toegang tot Clim'app, en in het algemeen, bij het gebruik van
          Clim'app.
        </p>

        <h2>
          1. Beschrijving van de diensten die via de Applicatie worden geleverd
        </h2>

        <p>
          CLIMALIFE heeft Clim'app ontwikkeld, die zowel toegankelijk is via een
          website als via een mobiele applicatie die koelprofessionals dagelijks
          begeleidt.
        </p>

        <p>Clim'app is een mobiele applicatie die :</p>

        <ul>
          <li>Het beheer en de verklaring van de interventies</li>
          <li>Beheer van faciliteiten en containers</li>
          <li>Productie van regelgevende documenten</li>
        </ul>

        <p>Clim'app is ook een webapplicatie die :</p>

        <ul>
          <li>Zichtbaarheid op de voorraad koudemiddelen</li>
          <li>
            De mogelijkheid om de omgeving van de koelprofessional te beheren
            (sites, installaties, voertuigen, detectoren...)
          </li>
          <li>
            Dashboards die de essentiële activiteit van de activiteit
            samenvatten
          </li>
        </ul>

        <p>
          De diensten van Clim's app kunnen op elk moment evolueren om rekening
          te houden met de evolutie van de technologieën en/of de toepasselijke
          wetgeving. CLIMALIFE doet zijn uiterste best om de gebruiker te
          informeren over elke belangrijke wijziging in de diensten.
        </p>

        <h2>2. Verplichtingen van de klant</h2>

        <p>
          In het kader van het Gebruik van de Diensten handelt de Klant als een
          professional voor de doeleinden van zijn commerciële, industriële,
          ambachtelijke of liberale activiteit, en met uitsluiting van alle
          persoonlijke doeleinden.
        </p>

        <p>
          Hij verbindt zich ertoe een legale activiteit uit te oefenen via de
          Dienst en over alle nodige vergunningen te beschikken, hetzij van zijn
          partners, hetzij in geval van uitoefening van een gereglementeerde
          activiteit en/of de reglementaire bepalingen die op zijn activiteit
          van toepassing zijn, na te leven;
        </p>

        <p>
          De Klant heeft de mogelijkheid om zijn Medewerkers en/of
          onderaannemers (Gebruiker) toegang te geven tot de Clim'app dienst in
          zijn naam. In dit verband zal de Klant ervoor zorgen dat zijn
          Medewerkers en/of onderaannemers zich houden aan de acceptatie van
          deze GCU en het bijgevoegde Privacy Charter. In het algemeen is de
          Klant ten opzichte van CLIMALIFE verantwoordelijk voor de naleving van
          de algemene gebruiksvoorwaarden door zijn Agenten en partners.
        </p>

        <h2>3. Toegang tot de diensten van Clim'app</h2>

        <p>
          De Klant is er zich van bewust dat de Dienst online wordt aangeboden
          en dat elke toegang tot de Dienst vereist dat de Klant en/of zijn
          Gebruikers over een internetabonnement en computerapparatuur
          beschikken die hen in staat stellen om verbinding te maken met de
          Dienst. De Klant erkent dat de kosten van de aansluiting en de
          aanschaf van de computerapparatuur voor rekening van de Klant en de
          Gebruikers zijn;
        </p>

        <h3>Het downloaden van de Clim'app</h3>

        <p>
          Voor toegang tot de Diensten is vereist dat u het Clim's app-aanbod
          van CLIMALIFE heeft geaccepteerd en dat u eerst de mobiele applicatie
          Clim's app heeft gedownload, waarvoor de functionaliteiten en
          voorwaarden voor interoperabiliteit zijn aangegeven op het moment van
          downloaden, in de applicatiewinkel, zoals de App Store of Google Play.
        </p>

        <p>
          Regelmatig worden nieuwe versies van de applicatie beschikbaar gesteld
          via de applicatieshop, zoals de App Store of Google Play. Door het
          downloaden van deze updates accepteert de gebruiker de wijzigingen die
          zijn aangebracht in de applicatie of de diensten die daaruit
          voortvloeien. De Gebruiker verbindt zich ertoe om regelmatig updates
          te downloaden en erkent dat CLIMALIFE niet aansprakelijk kan worden
          gesteld indien de Gebruiker een versie gebruikt vóór de bijgewerkte
          versie.
        </p>

        <h3>Account aanmaken</h3>

        <p>
          De Diensten zijn toegankelijk zodra de Gebruiker zijn Account heeft
          aangemaakt in overeenstemming met de in dit artikel aangegeven
          voorwaarden.
        </p>

        <h3>Account aanmaken</h3>

        <p>
          Het aanmaken van een Account op Clim'app verloopt in verschillende
          stappen: elke stap wordt achtereenvolgens gekruist door de "klik" van
          een bevestigingsicoon. Tijdens de verschillende stappen van het
          aanmaken van een account kan het zijn dat de gebruiker persoonlijke
          gegevens moet invullen (zoals uw naam, achternaam, voornaam, enz.),
          waarvan de verwerking wordt geregeld door het Privacybeleid.
        </p>

        <p>
          Wanneer een gebruiker op een pictogram klikt waarvan de tekst of de
          presentatie een verbintenis inhoudt die elke gemiddelde
          internetgebruiker zou begrijpen, is de gebruiker gebonden aan zijn
          klik.
        </p>

        <h3>Controles voorafgaand aan het aanmaken van de Account</h3>

        <p>
          CLIMALIFE behoudt zich het recht voor om het verzoek tot het aanmaken
          van een Account niet te valideren, met name in de volgende gevallen:
        </p>

        <ul>
          <li>In geval van ongeldige informatie;</li>
          <li>
            Indien het aanbod van CLIMALIFE niet formeel door de Cliënt is
            aanvaard.
          </li>
        </ul>

        <p>
          In een dergelijk geval wordt het verzoek tot het aanmaken van een
          Account niet gevalideerd en kan er geen enkele verplichting uit
          voortvloeien.
        </p>

        <h3>Geldigheid en bewijs van het aanmaken van de Account</h3>

        <p>
          Niettegenstaande enig ander schriftelijk bewijs of enig bewijs dat op
          een andere duurzame drager wordt bewaard, wordt overeengekomen dat de
          computergegevens, die in de computersystemen van CLIMALIFE of die van
          haar onderaannemers worden bewaard, geldig zijn als bewijs van de
          mededeling van de inhoud van de creatie van Uw Account en de datum
          ervan.
        </p>

        <h3>Hulp</h3>

        <p>
          Er is een elektronische ondersteuningsdienst beschikbaar om anomalieën
          op te lossen. Meldingen van anomalieën moeten onverwijld per e-mail
          naar het volgende adres worden gestuurd: support@Clim'app.dehon.com.
          CLIMALIFE diagnosticeert de anomalie en voert vervolgens de correctie
          ervan uit.
        </p>

        <h2>4. Uw verplichtingen bij het gebruik van onze diensten</h2>
        <h3>4.1 Beveiliging van de rekening</h3>

        <p>
          De toegang tot de Account is voorbehouden aan de Klant na
          identificatie met behulp van de identificatiecode en het wachtwoord
          die elke Gebruiker vrij kan bepalen bij het aanmaken van zijn Account.
        </p>

        <p>
          De Gebruiker erkent dat zijn/haar wachtwoord strikt vertrouwelijk en
          persoonlijk is. Het gebruik van zijn login en wachtwoord via het
          internet is op eigen risico. Het is aan de gebruiker om alle nodige
          maatregelen te nemen om hen te beschermen tegen eventuele inbreuken.
          In die zin kan CLIMALIFE niet verantwoordelijk worden gesteld voor
          frauduleuze toegang tot de Account van de Gebruiker of voor een
          wijziging van deze Account. In dat geval verbindt de Klant zich ertoe
          CLIMALIFE zo snel mogelijk op de hoogte te brengen.
        </p>

        <p>
          De Klant blijft als enige garant staan voor de informatie die in zijn
          Account is opgeslagen. Hij kan ze op elk moment toevoegen, wijzigen of
          verwijderen.
        </p>

        <h3>
          4.2 Door zich toegang te verschaffen tot Clim'app, deze te bezoeken of
          te gebruiken via haar Gebruikers, garandeert en verklaart de Klant dat
          hij verantwoordelijk is voor elk gebruik dat van Clim'app wordt
          gemaakt door hemzelf, of door een persoon die zijn identificatoren
          gebruikt.
        </h3>

        <h3>4.3 Verbod op illegaal of ongeoorloofd gebruik van de Clim'app</h3>

        <p>
          De gebruiker wordt geïnformeerd dat alle geldende wetten en regels van
          toepassing zijn op het internet en verklaart dat hij Clim'app niet
          gebruikt voor illegale doeleinden of doeleinden die verboden zijn door
          de wet of door deze Gebruiksvoorwaarden.
        </p>

        <h2>5. Prijzen</h2>

        <p>
          De prijsvoorwaarden worden overeengekomen in de door CLIMALIFE
          verstrekte prijsaanbieding en worden door de Klant aanvaard.
        </p>

        <p>
          De prijzen zijn exclusief belastingen. De betaling van de facturen
          gebeurt contant voor de eerste bestelling van het Product van de
          Klant, vervolgens op 30 dagen einde van de maand vanaf de datum van de
          factuur, zonder korting voor vervroegde betaling. CLIMALIFE behoudt
          zich het recht voor om de overeengekomen betalingsvoorwaarden te
          herzien indien de Klant niet meer voldoende gedekt is door de
          kredietverzekering van CLIMALIFE. Elke klacht moet schriftelijk worden
          ingediend vóór de vervaldatum van de genoemde factuur. Bovendien
          ontslaat het ontstaan van een geschil de Klant niet van de
          verplichting om de factuur in zijn geheel te betalen.
        </p>

        <p>
          Onverminderd elke andere actie van MATELEX, is de Klant voor alle
          bedragen die niet betaald zijn op de vervaldag, (a) een
          nalatigheidsinterest gelijk aan 1,5% per maand van de verschuldigde
          bedragen vanaf de vervaldag van de factuur; (b) een forfaitaire
          schadeloosstelling voor invorderingskosten van 40 €. CLIMALIFE behoudt
          zich tevens het recht voor om de Klant te factureren voor eventuele
          extra kosten die voortvloeien uit de inning van zijn vordering (met
          name buitengerechtelijke stukken, incassokosten en honoraria, externe
          interventie, etc.), evenals eventuele schade die wordt geleden als
          gevolg van te late betaling. CLIMALIFE behoudt zich tevens het recht
          voor om vanaf de eerste vertraging elke lopende bestelling te
          annuleren of op te schorten en de betalingstermijn te verkorten.
          Evenzo kan elke vertraging op een factuur leiden tot het vervallen van
          de termijn van elke andere nog niet vervallen factuur, deze laatste
          wordt onmiddellijk opeisbaar.
        </p>

        <h2>6. Duur</h2>

        <p>
          De duur van het gebruik van de dienst wordt overeengekomen in de
          bijzondere voorwaarden die door de Klant worden aanvaard. Aan het
          einde van deze periode, ongeacht de oorzaak, zal de Klant elk gebruik
          van de Dienst onmiddellijk stopzetten.
        </p>

        <p>
          Indien de Klant de bepalingen van het Contract niet respecteert,
          behoudt CLIMALIFE zich het recht voor om het Contract zonder
          voorafgaande kennisgeving te beëindigen.
        </p>

        <p>
          De Klant heeft een termijn van 3 maanden om toegang te krijgen tot de
          documenten die door de Aanvraag worden gegenereerd en deze op te
          vragen. Na deze periode heeft de Klant een periode van vijf (5) jaar
          vanaf de datum van beëindiging van het Contract om CLIMALIFE te vragen
          de Klantgegevens terug te sturen. Deze dienst voor de
          overdraagbaarheid van klantgegevens kan aan de klant worden
          gefactureerd.
        </p>

        <h2>7. Vertrouwelijkheid / Gegevens</h2>

        <p>
          CLIMALIFE kan op geen enkele wijze aansprakelijk worden gesteld voor
          de inhoud en de controle van de Klantgegevens en/of enige informatie
          die in het kader van de Dienst wordt doorgevoerd of verwerkt en
          waarvoor de Klant garant blijft staan.
        </p>

        <p>
          CLIMALIFE verbindt zich ertoe de vertrouwelijkheid en de veiligheid
          van de gegevens te verzekeren ten opzichte van derden en binnen de
          grenzen van de contractuele voorwaarden die CLIMALIFE verbindt aan de
          dienstverlener die eigenaar is van het servercentrum. CLIMALIFE kan de
          Klantgegevens echter wel gebruiken in het kader van de uitoefening van
          haar activiteiten. Bij uitdrukkelijke overeenkomst wordt tussen de
          Partijen overeengekomen dat het gebruik van de Klantgegevens door
          CLIMALIFE niet kan worden beschouwd als een inbreuk op de
          vertrouwelijkheidsvoorwaarden die in het Contract zijn voorzien.
        </p>

        <p>Voor de verwerking van persoonsgegevens: zie de Privacy Policy.</p>

        <h2>8. Verantwoordelijkheid</h2>
        <h3>8.1 Algemene informatie</h3>

        <p>
          Voor CLIMALIFE geldt een middelenverbintenis met uitsluiting van alle
          andere. Het garandeert de conformiteit van de diensten met de hierin
          opgenomen beschrijvingen. De Klant neemt alle verantwoordelijkheden op
          zich, behalve die van de conformiteit van de Diensten met de
          beschrijving ervan en in het bijzonder die met betrekking tot :
        </p>

        <ul>
          <li>het behoud van de vertrouwelijkheid van de toegangscodes ;</li>
          <li>
            de werking van het interne netwerk en de eigen IT-apparatuur (met
            name de lokale infrastructuur),
          </li>
          <li>van de in de Service ingevoerde Klantgegevens;</li>
          <li>de rechtmatigheid van haar activiteit;</li>
          <li>de rechtmatigheid en billijkheid van de Klantgegevens;</li>
          <li>van het gebruik van de dienst door zijn agenten; en</li>
          <li>de geschiktheid van de dienst voor zijn behoeften,</li>
          <li>de werking van de Dienst,</li>
          <li>de kwalificatie en de competentie van haar personeel.</li>
        </ul>

        <p>
          Bovendien zal CLIMALIFE, in geval van een bewezen fout van de Klant,
          enkel aansprakelijk zijn voor de vergoeding van de geldelijke gevolgen
          van de directe en voorzienbare schade als gevolg van de uitvoering van
          de Diensten. Bijgevolg zal CLIMALIFE in geen geval aansprakelijk zijn
          voor onrechtstreeks of onvoorzienbaar verlies of schade van de Klant
          of van derden, met inbegrip van met name winstderving, verlies,
          onnauwkeurigheid of corruptie van bestanden of gegevens, commerciële
          schade, verlies van omzet of winst, verlies van klanten, verlies van
          kansen, kosten voor het verkrijgen van een vervangend product, dienst
          of technologie, met betrekking tot of als gevolg van de
          niet-uitvoering of de gebrekkige uitvoering van de Diensten.
        </p>

        <p>
          In alle gevallen is het bedrag van de aansprakelijkheid van CLIMALIFE
          strikt beperkt tot de terugbetaling van het bedrag van de bedragen die
          daadwerkelijk door de klant zijn betaald op de datum van het optreden
          van de gebeurtenis die aanleiding geeft tot de aansprakelijkheid op
          het gemiddelde verbruik van de laatste twaalf maanden.
        </p>

        <p>
          Bovendien kan CLIMALIFE niet aansprakelijk worden gesteld voor de
          toevallige vernietiging van de Gegevens door de Klant of een derde die
          toegang heeft gehad tot de Applicatiediensten door middel van de aan
          de Klant verstrekte Identificatoren.
        </p>

        <p>
          Elk van de partijen verklaart dat zij bij een bekende
          verzekeringsmaatschappij verzekerd is voor alle schadelijke gevolgen
          van de handelingen waarvoor zij op grond van het contract
          aansprakelijk zou kunnen worden gesteld.
        </p>

        <p>
          Deze verzekering moet voor de gehele duur van de overeenkomst worden
          gehandhaafd. Op verzoek van een partij verstrekt de andere partij de
          genoemde partij een jaarlijks certificaat van haar
          verzekeringsmaatschappij, waarin de gedekte bedragen en risico's
          worden vermeld.
        </p>

        <p>
          CLIMALIFE kan in geen geval aansprakelijk worden gesteld voor
          eventuele schade als gevolg van een onderbreking of een onderbreking
          van de dienstverlening door de telecommunicatie-exploitant, de
          elektriciteitsleverancier of in geval van overmacht.
        </p>

        <h3>8.2 Beveiliging/toegang</h3>

        <p>
          CLIMALIFE levert redelijke en zorgvuldige inspanningen om de
          veiligheid en operationele integriteit van Clim'app te handhaven door
          het nemen van beveiligingsmaatregelen die zijn aangepast aan de aard
          van de gegevens en de risico's die deze activiteit met zich meebrengt.
          Er zijn echter veel factoren die de werking van Clim'app kunnen
          verstoren.
        </p>

        <p>
          CLIMALIFE streeft ernaar, voor zover mogelijk, de diensten 7 dagen per
          week, 24 uur per dag toegankelijk te houden.
        </p>

        <p>
          CLIMALIFE behoudt zich het recht voor om de toegang tot alle of een
          deel van de diensten van Clim's app zonder voorafgaande kennisgeving
          te onderbreken, tijdelijk op te schorten of te wijzigen om het
          onderhoud, de upgrading of om enige andere reden, met name van
          technische aard, te waarborgen, zonder dat deze manoeuvres aanleiding
          geven tot enige verplichting of vergoeding.
        </p>

        <p>Bijgevolg:</p>

        <ul>
          <li>
            CLIMALIFE kan niet aansprakelijk worden gesteld in het geval van een
            onderbreking van de toegang tot Clim'app en de gevolgen die hieruit
            kunnen voortvloeien;
          </li>
          <li>
            CLIMALIFE kan niet aansprakelijk worden gesteld voor eventuele
            schade (direct of indirect) of tijdelijke of permanente incidenten
            die zouden worden veroorzaakt aan de gegevens van de Gebruiker of
            aan zijn computerapparatuur tijdens zijn toegang tot Clim's app of
            tijdens het bezoek aan zijn Account of in het algemeen tijdens de
            transmissie op zijn ontvangstapparaat van de bestanden en software
            die deel uitmaken van Clim's app. In het bijzonder is CLIMALIFE niet
            verantwoordelijk voor de mogelijke overdracht van een virus via
            Clim'app.
          </li>
          <li>
            CLIMALIFE is niet verantwoordelijk voor het frauduleuze gebruik van
            zijn distributiemiddelen en wijst alle verantwoordelijkheid af in
            geval van inbraak in zijn computersystemen en gegevensdiefstal,
            behoudens dwingende wettelijke en reglementaire bepalingen die
            hiermee in strijd kunnen zijn, met dien verstande dat CLIMALIFE alle
            nuttige middelen aanwendt om dergelijke ongeoorloofde inbraken te
            voorkomen.
          </li>
        </ul>

        <h3>8.3 Updates</h3>

        <p>
          Ondanks de geleverde inspanningen om de juistheid van de informatie
          en/of documenten op Clim'app te verzekeren, wijst CLIMALIFE alle
          verantwoordelijkheid af, van welke aard dan ook, in geval van
          onnauwkeurigheid of het niet updaten van informatie en/of documenten
          op Clim'app.
        </p>

        <h2>9. Hyperlinks naar andere diensten</h2>

        <p>
          Clim'app kan verwijzen naar andere diensten (websites, applicaties,
          etc.) waarover CLIMALIFE geen technische of inhoudelijke controle
          heeft. Het bestaan van een hypertekstlink naar een andere dienst vormt
          geen validatie door CLIMALIFE van deze dienst of de inhoud ervan.
        </p>

        <p>
          CLIMALIFE kan dus geen enkele garantie bieden over de exhaustieve of
          exacte aard van de inhoud van deze diensten van derden, noch over hun
          beschikbaarheid.
        </p>

        <p>
          CLIMALIFE wijst alle verantwoordelijkheid af voor enige directe of
          indirecte schade die voortvloeit uit de raadpleging of het gebruik van
          de webdiensten waarnaar Clim'app verwijst of de informatie die op deze
          diensten wordt gepubliceerd. Zij wijst ook alle verantwoordelijkheid
          af voor de verwerking van persoonsgegevens op deze diensten.
        </p>

        <h2>10. Intellectueel eigendom</h2>

        <p>
          CLIMALIFE verleent de Klant enkel een persoonlijk, niet-exclusief en
          niet-overdraagbaar recht op toegang en gebruik van Clim'app, mits
          naleving van deze Algemene Gebruiksvoorwaarden. De Klant kan echter
          zijn medewerkers en/of Partners een recht op gebruik en toegang tot de
          Service verlenen namens de Klant, onder zijn volledige
          verantwoordelijkheid.
        </p>

        <p>
          De Klant erkent en aanvaardt dat het gebruik geen enkel intellectueel
          eigendomsrecht verleent op de Dienst of op enig element dat de werking
          en de levering mogelijk maakt en in het algemeen op enig apparaat of
          materiaal dat door CLIMALIFE wordt geleverd of dat in het Platform of
          in de Applicatie is opgenomen.
        </p>

        <p>
          Clim'app (beschouwd als een onlosmakelijk geheel) en de verschillende
          elementen die op Clim'app voorkomen (zoals teksten, partneromgeving,
          foto's, catalogi, lay-outs, merken, logo's, illustraties, specifieke
          software, video's) zijn beschermd door de intellectuele
          eigendomsrechten van CLIMALIFE of haar licentiegevers.
        </p>

        <p>
          Het logo, de namen van producten, diensten of de namen van bedrijven
          die op Clim'app worden vermeld, zijn waarschijnlijk handelsmerken,
          handelsnamen, bedrijfsnamen van hun respectievelijke eigenaars. De
          Klant verbindt zich er uitdrukkelijk toe deze intellectuele
          eigendomsrechten te respecteren.
        </p>

        <p>
          De Klant verbindt zich ertoe de informatie, software, product of
          dienst verkregen van Clim'app niet te wijzigen, te kopiëren, te
          verdelen, te verzenden, uit te zenden, te publiceren, in licentie te
          geven, over te dragen, te verkopen, te reproduceren of te laten
          reproduceren, hetzij tijdelijk, hetzij permanent, via enig proces,
          bekend of onbekend, op welk medium dan ook, zonder de voorafgaande
          schriftelijke toestemming van CLIMALIFE. De niet-naleving van dit
          verbod vormt een inbreuk die kan leiden tot de burgerlijke en
          strafrechtelijke aansprakelijkheid van de vervalser.
        </p>

        <p>
          De Klant zal CLIMALIFE onverwijld op de hoogte brengen van elk gebruik
          van de Dienst dat onwettig of in strijd is met het Contract waarvan
          hij op de hoogte zou kunnen zijn. Indien CLIMALIFE naar aanleiding van
          deze informatie besluit om actie te ondernemen tegen een derde partij,
          verplicht de Gebruiker zich ertoe om alle nodige bijstand te verlenen
          die redelijkerwijs kan worden gevraagd.
        </p>

        <h2>11. Verwijdering van de rekening</h2>

        <p>De gebruiker kan zijn account te allen tijde verwijderen.</p>

        <p>
          CLIMALIFE kan de Account van de Gebruiker op eigen initiatief
          verwijderen, wat de beëindiging van dit contract met zich meebrengt
          (behalve in het geval van bepalingen die ook na de beëindiging van dit
          contract van kracht blijven), in het bijzonder in de volgende
          gevallen, waarbij deze lijst niet uitputtend is:
        </p>

        <ul>
          <li>
            Deze schrapping van het initiatief zal het gevolg zijn van het feit
            dat de Account gedurende een bepaalde periode niet wordt gebruikt;
          </li>
          <li>
            Deze schrapping van het initiatief is een tijdelijke of definitieve
            maatregel van CLIMALIFE tegen een verdachte rekening, bijvoorbeeld
            wanneer CLIMALIFE redenen heeft om aan te nemen dat de Gebruiker het
            huidige contract niet naleeft;
          </li>
        </ul>

        <p>
          Voor zover mogelijk en binnen de geldende wettelijke grenzen, geeft
          CLIMALIFE vooraf kennis van haar beslissing om de Account van de
          Gebruiker te verwijderen.
        </p>

        <p>
          De Gebruiker kan geen vergoeding eisen voor het verwijderen van zijn
          Account.
        </p>

        <p>
          Na de verwijdering van zijn Account bewaart CLIMALIFE de gegevens en
          de inhoud ervan gedurende de tijd die nodig is voor de technische
          operaties van de verwijdering van de Account en in ieder geval binnen
          de grenzen en verplichtingen die worden opgelegd door de toepasselijke
          wetgeving. CLIMALIFE behoudt zich het recht voor om de informatie en
          de inhoud die op Clim'app worden gepubliceerd en die nodig zijn voor
          het beheer van de installatie door CLIMALIFE, te bewaren.
        </p>

        <h2>12. Slotbepalingen</h2>

        <h3>12.1 Wijzigingen van deze algemene gebruiksvoorwaarden</h3>

        <p>
          Deze algemene gebruiksvoorwaarden kunnen op elk moment, zonder
          voorafgaande kennisgeving, gewijzigd worden in overeenstemming met
          wijzigingen in de Clim'app, wijzigingen in de wetgeving of om welke
          andere reden dan ook. De toepasselijke versie van deze
          gebruiksvoorwaarden die afdwingbaar is, is permanent beschikbaar op
          Clim'app. De Gebruiker verbindt zich ertoe deze regelmatig te
          raadplegen.
        </p>

        <h3>12.2 Bewijsovereenkomst</h3>

        <p>
          CLIMALIFE kan samen met de Gebruiker overgaan tot de uitwisseling van
          informatie die nodig is om de Clim'app via elektronische weg te laten
          verlopen. Elke elektronische communicatie tussen de partijen wordt
          verondersteld dezelfde bewijskracht te hebben als een schriftelijk
          document op papier.
        </p>

        <p>
          Een gedrukte versie van deze algemene gebruiksvoorwaarden en van elke
          waarschuwing in elektronische vorm zal worden aanvaard in elke
          juridische of administratieve procedure met betrekking tot deze
          contractuele relatie, op dezelfde manier en onder dezelfde voorwaarden
          als andere handelsdocumenten en -registers die in gedrukte vorm worden
          aangemaakt en bewaard.
        </p>

        <h3>15.3 Scheidbaarheid</h3>

        <p>
          Indien een clausule van deze Algemene Gebruiksvoorwaarden geheel of
          gedeeltelijk nietig, onwettig of niet toepasbaar zou worden verklaard
          onder het toepasselijke recht, zou een dergelijke situatie geen
          invloed hebben op de geldigheid van de overige clausules, onder
          voorbehoud van de hypothese van de nietigheid van een essentiële
          clausule of de onderlinge afhankelijkheid van de contractuele
          bepalingen. De nietige, ongeldige, onwettige of niet-toepasselijke
          clausule zal met terugwerkende kracht worden vervangen door een
          geldige en toepasselijke clausule waarvan de inhoud zo dicht mogelijk
          bij die van de oorspronkelijke clausule ligt.
        </p>

        <h3>15.6 Kennisgeving</h3>

        <p>
          Elke mededeling of mededeling die CLIMALIFE doet, is geldig als deze
          is gericht aan het e-mailadres dat de gebruiker heeft opgegeven, zelfs
          als deze niet meer geldig is.
        </p>

        <h3>15.8 Toepasselijk recht en bevoegde rechtbanken</h3>

        <p>
          Deze algemene gebruiksvoorwaarden worden beheerst en geïnterpreteerd
          in overeenstemming met het recht dat van toepassing is op de
          CLIMALIFE-entiteit die het Clim'app-aanbod heeft gedaan dat de Klant
          heeft aanvaard.
        </p>

        <p>
          Elk geschil met betrekking tot het gebruik van Clim'app en de
          geldigheid, interpretatie, uitvoering of niet-uitvoering van deze
          gebruiksvoorwaarden valt onder de exclusieve bevoegdheid van de
          rechtbanken van de jurisdictie van deze entiteit.
        </p>
      </div>
    );
  }
}
