// Reducer for creating / editing a waste recovery request

import { Actions } from "../actions/createWasteRecoveryRequestActions";

/**
 * @type {{
 *  siteUuid: String,
 *  date: Date,
 *  nbCages: Number,
 *  nbPallets: Number,
 *  comments: ?String,
 *  attestationReceiptAddress: {
 *    country: String,
 *    city: String,
 *    street: String,
 *    streetAddition: ?String,
 *    postalCode: String,
 *  },
 *  containers: [{
 *    containerUuid: String,
 *    treatmentMode: String,
 *    packaging: String,
 *    toReturn: Boolean,
 *    toDestruct: Boolean,
 *  }],
 *  articles: [{
 *    articleUuid: String,
 *    producerUuid: String,
 *    quantity: Number,
 *    mass: Number,
 *    packaging: String,
 *    treatmentMode: String,
 *    toReturn: Boolean,
 *    toDestruct: Boolean
 *  }],
 *  charteredByClient: Boolean
 *  carrierUuid: ?String,
 *  transportNumber: ?String,
 * }}
 */
const initialState = {
  siteUuid: undefined,
  date: undefined,
  comments: undefined,
  nbCages: undefined,
  nbPallets: undefined,
  attestationReceiptAddress: undefined,
  containers: [],
  articles: [],
  charteredByClient: false,
  carrierUuid: undefined,
  transportNumber: undefined,
  violations: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case Actions.SET_INFO: {
      const { siteUuid, date, attestationReceiptAddress, comments } = payload;

      let containers = state.containers;
      let articles = state.articles;
      if (siteUuid !== state.siteUuid) {
        // In case the site changed, reset the containers selection
        containers = [];
        // And the articles as well
        articles = [];
      }

      return {
        ...state,
        siteUuid,
        date,
        attestationReceiptAddress,
        comments,
        containers,
        articles,
      };
    }

    case Actions.ADD_CONTAINER: {
      const { containerUuid } = payload;

      const containers = [...state.containers];
      const match = containers.findIndex(
        (c) => c.containerUuid === containerUuid,
      );

      if (-1 !== match) {
        containers[match] = payload;
      } else {
        containers.push(payload);
      }

      return { ...state, containers };
    }

    case Actions.REMOVE_CONTAINER: {
      const { containerUuid } = payload;

      const containers = state.containers.filter(
        (c) => c.containerUuid !== containerUuid,
      );

      return { ...state, containers };
    }

    case Actions.ADD_ARTICLE: {
      const { index, ...article } = payload;

      const articles = [...state.articles];

      articles[index === undefined ? articles.length : index] = article;

      return { ...state, articles };
    }

    case Actions.REMOVE_ARTICLE: {
      const { index } = payload;

      const articles = [...state.articles];
      articles.splice(index, 1);

      return { ...state, articles };
    }

    case Actions.SET_TRANSPORT_INFO: {
      const {
        charteredByClient,
        nbCages,
        nbPallets,
        carrierUuid,
        transportNumber,
      } = payload;

      return {
        ...state,
        charteredByClient,
        nbCages,
        nbPallets,
        carrierUuid,
        transportNumber,
      };
    }

    case Actions.CLEAR:
      return initialState;

    case Actions.SET_VIOLATIONS:
      return { ...state, violations: payload.violations };

    default:
      return state;
  }
}
