import {
  apolloAddItem,
  apolloRemoveItem,
  apolloUpdateItem,
} from "../../../common/graphql/cache/apolloCacheUtils";

export function onCreatePlannedInterventionRequest(cache, result) {
  const mutation = "createInterventionPlanned";

  apolloAddItem(
    "listInterventionPlanned",
    "InterventionPlanned",
    mutation,
  )(cache, result);
}

export function onUpdatePlannedInterventionRequest(cache, result) {
  const mutation = "updateInterventionPlanned";

  apolloUpdateItem(
    "listInterventionPlanned",
    "InterventionPlanned",
    mutation,
  )(cache, result);
}

export function onDeletePlannedInterventionRequest(cache, result) {
  apolloRemoveItem("deleteInterventionPlanned")(cache, result);
}
