import I18n from "i18n-js";
import React, { Component } from "react";
import { arrayOf, shape, func } from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import ClientTypes from "../models/ClientTypes";
import { alphabetically } from "../../common/utils/filterUtils";
import { ROUTE_ENTERPRISE } from "../../../routes";
import { Link } from "react-router-dom";
import pathToRegexp from "path-to-regexp";
import { getClientsList } from "../actions/clientsActions";
import { CompanySyncStatusesTable } from "../../trackdechets/components/CompanySyncStatusesTable";

class MyEnterpriseList extends Component {
  static propTypes = {
    clients: shape().isRequired,
    clientsList: arrayOf(shape).isRequired,
    getClientsList: func.isRequired,
  };

  constructor() {
    super();

    this.dataTableHead = this.dataTableHead.bind(this);
    this.dataTableRow = this.dataTableRow.bind(this);
  }

  /**
   * {@inheritdoc}
   */
  componentDidMount() {
    this.props.getClientsList();
  }

  /**
   * @return {Array}
   */
  dataTableHead() {
    return [
      I18n.t("pages.my_enterprise.head.account"),
      I18n.t("pages.my_enterprise.head.legalCompanyName"),
      I18n.t("pages.my_enterprise.head.address"),
      I18n.t("pages.my_enterprise.head.phoneNumber"),
      I18n.t("pages.my_enterprise.head.email"),
      I18n.t("pages.my_enterprise.head.certificateOfProfessionalCompetence"),
      I18n.t(
        "pages.my_enterprise.head.certificateOfProfessionalCompetenceExpiration",
      ),
      I18n.t("pages.my_enterprise.head.logo"),
      I18n.t("pages.my_enterprise.head.actions"),
    ];
  }

  /**
   * @param {Object} rowData
   *
   * @return {Array}
   */
  dataTableRow(rowData) {
    const notSpecified = <small>{I18n.t("common.not_specified")}</small>;
    const address = `${rowData.address.street}${
      rowData.address.addressAddition
        ? ` ${rowData.address.addressAddition}`
        : ""
    }, ${rowData.address.postal} ${rowData.address.city}, ${
      rowData.address.country
    }`;

    const actions = (
      <div>
        <Link
          to={{
            pathname: pathToRegexp.compile(ROUTE_ENTERPRISE)({
              uuid: rowData.uuid,
            }),
            state: {
              clientInfo: rowData,
            },
          }}
          className="btn"
        >
          <i className="fa fa-pencil" aria-hidden="true" />
        </Link>
      </div>
    );

    const hasLogo = (
      <div className="text-success">
        <i className="fa fa-check" aria-hidden="true" />
      </div>
    );

    const noLogo = (
      <div className="text-danger">
        <i className="fa fa-times" aria-hidden="true" />
      </div>
    );

    return [
      rowData.externalId || notSpecified,
      rowData.legalCompanyName,
      address,
      rowData.phoneNumber,
      rowData.email,
      rowData.certificateOfProfessionalCompetence &&
        rowData.certificateOfProfessionalCompetence.number,
      rowData.certificateOfProfessionalCompetence &&
        moment(rowData.certificateOfProfessionalCompetence.endDate).format(
          "DD/MM/YYYY",
        ),
      rowData.logo ? hasLogo : noLogo,
      actions,
    ];
  }

  /**
   * Render content page
   */
  renderContent() {
    const { clients, clientsList } = this.props;

    if (clients.inProgress) {
      return <div className="loader" />;
    }

    if (clients.error) {
      return <p>{I18n.t("common.loadingApiError")}</p>;
    }

    return (
      <CompanySyncStatusesTable
        target="CLIENT"
        allData={clientsList || []}
        emptyContent={I18n.t("pages.my_enterprise.list.empty")}
        data={clientsList}
        headData={this.dataTableHead()}
        rowData={this.dataTableRow}
        columnStyle={[null, null, null, null, null, null, null, null, "nowrap"]}
      />
    );
  }

  /**
   * {@inheritdoc}
   */
  render() {
    return (
      <div>
        <h1>{I18n.t("pages.my_enterprise.list.title")}</h1>
        {this.renderContent()}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getClientsList: () => dispatch(getClientsList()),
});

export default connect((state) => {
  const allowedTypes = [ClientTypes.CF, ClientTypes.DO];

  return {
    clients: state.clients,
    clientsList: state.clients.list
      .filter((client) => allowedTypes.includes(client.type))
      .sort(
        (a, b) =>
          alphabetically(a.legalCompanyName, b.legalCompanyName) ||
          alphabetically(a.address.city, b.address.city),
      ),
  };
}, mapDispatchToProps)(MyEnterpriseList);
