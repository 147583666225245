import I18n from "i18n-js";
import React from "react";
import { arrayOf, func, shape } from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { parse } from "qs";
import { getContainersList } from "../actions/containersActions";
import List from "../../common/components/layout/List";
import Quantity from "../../common/components/elements/Quantity";
import Select from "../../common/components/form/Select";
import { alphabetically, fixed } from "../../common/utils/filterUtils";
import DownloadButton from "../../common/components/elements/DownloadButton";
import { fetch } from "../../../appContainer";
import { formatFloat } from "../../common/utils/formatUtils";
import ClientUtils from "../../company/utils/ClientUtils";
import ContainerInterventionsModal from "../components/ContainerInterventionsModal";
import StatelessTable from "../../common/components/elements/StatelessTable";
import StatelessCheckbox from "../../common/components/form/StatelessCheckbox";
import StatelessTextInput from "../../common/components/form/StatelessTextInput";
import ArchiveRequestAction from "../actions/ArchiveRequestAction";

class ContainersList extends List {
  static propTypes = {
    containersState: shape().isRequired,
    getContainersList: func.isRequired,
    containersList: arrayOf(shape()).isRequired,
    location: shape().isRequired, // react-router location
  };

  static POSITION_MOBILE = "mobile";
  static POSITION_SITE = "site";
  static POSITION_NONE = "none";
  static POSITION_ALL = null;

  static FLUID_EMPTY = "empty";

  constructor(props) {
    super(props, {
      archivedContainers: [],
    });

    const query = parse(props.location.search.substr(1));
    const { overloadedOnly } = query;

    this.state.filters.overloaded = !!overloadedOnly; // Displays only overloaded containers if true
    this.state.showContainerModal = null;

    this.renderRow = this.renderRow.bind(this);
    this.exportToTsv = this.exportToTsv.bind(this);
    this.fluidFilter = this.fluidFilter.bind(this);
    this.volumeFilter = this.volumeFilter.bind(this);
    this.clientFilter = this.clientFilter.bind(this);
    this.siteFilter = this.siteFilter.bind(this);
    this.positionFilter = this.positionFilter.bind(this);
    this.providerFilter = this.providerFilter.bind(this);
    this.getFilteredList = this.getFilteredList.bind(this);
    this.archiveContainer = this.archiveContainer.bind(this);
  }

  /**
   * {@inheritdoc}
   */
  componentDidMount() {
    super.componentDidMount();

    this.props.getContainersList();
  }

  getClientsFromContainers() {
    const clientsByUid = this.props.containersList.reduce(
      (clients, container) => {
        if (container.lastPosition) {
          clients[container.lastPosition.client.uuid] =
            container.lastPosition.client;
        }

        return clients;
      },
      {},
    );
    return Object.values(clientsByUid).sort((a, b) =>
      alphabetically(ClientUtils.renderLabel(a), ClientUtils.renderLabel(b)),
    );
  }

  getSitesFromContainers() {
    const sitesByUid = this.props.containersList.reduce((sites, container) => {
      if (container.lastPosition) {
        sites[container.lastPosition.uuid] = container.lastPosition;
      }

      return sites;
    }, {});
    return Object.values(sitesByUid).sort((a, b) =>
      alphabetically(a.designation, b.designation),
    );
  }

  getProvidersFromContainers() {
    const providers = {};

    this.props.containersList.forEach((container) => {
      providers[container.provider.uuid] = container.provider;
    });

    return Object.values(providers).sort((a, b) =>
      alphabetically(a.designation, b.designation),
    );
  }

  exportToTsv() {
    return new Promise((download) => {
      fetch("api").exportContainers(
        this.getFilteredList().map(({ uuid }) => uuid),
        (tsvContent) => download(tsvContent),
        (error) => {
          console.error(error);
          download(null);
        },
      );
    });
  }

  archiveContainer(containerUuid) {
    this.setState({
      archivedContainers: [...this.state.archivedContainers, containerUuid],
    });
  }

  /**
   * @return {Array}
   */
  dataTableHead() {
    return [
      I18n.t("pages.containers.head.barcode"),
      I18n.t("pages.containers.head.designation"),
      I18n.t("pages.containers.head.fluid"),
      I18n.t("pages.containers.head.volume"),
      I18n.t("pages.containers.head.current_load"),
      I18n.t("pages.containers.head.site"),
      I18n.t("pages.containers.head.mobile_site"),
      I18n.t("pages.containers.head.client"),
      I18n.t("pages.containers.head.interventions"),
      "",
    ];
  }

  /**
   * @param {Object} rowData
   *
   * @return {Array}
   */
  renderRow(rowData) {
    const { lastPosition } = rowData;
    const count =
      rowData.interventionUuids.length + rowData.shippingUuids.length;

    return [
      rowData.barcode,
      rowData.designation,
      rowData.currentFluids.join(", "),
      <Quantity key="volume" value={rowData.volume} unit="L" />,
      <Quantity key="currentLoad" value={rowData.currentLoad} />,
      ...(lastPosition
        ? [
            lastPosition.mobile ? null : lastPosition.designation,
            lastPosition.mobile ? lastPosition.designation : null,
            ClientUtils.renderLabel(lastPosition.client),
          ]
        : [null, null, null]),
      count ? (
        <button
          className="more"
          onClick={() => this.setState({ showContainerModal: rowData.uuid })}
        >
          {I18n.t("pages.containers.list.interventions", { count })}
        </button>
      ) : null,
      <ArchiveRequestAction
        uuid={rowData.uuid}
        onDelete={this.archiveContainer}
        key="archive"
      />,
    ];
  }

  /**
   * @param {Object} data
   * @param {String} value
   *
   * @return {Boolean}
   */
  siteFilter(data, value) {
    if (!data.lastPosition) {
      // only show containers without site whenever no site filter is set
      return value === null;
    }

    return data.lastPosition.uuid === value;
  }

  /**
   * @param {Object} data
   * @param {String} value
   *
   * @return {Boolean}
   */
  clientFilter(data, value) {
    const selectedClient = this.getClientsFromContainers().find(
      (client) => client.uuid === value,
    );

    if (!data.lastPosition) {
      // only show containers without site whenever no client filter is set
      return value === null;
    }

    return data.lastPosition.client.uuid === selectedClient.uuid;
  }

  /**
   * @param {Object} data
   * @param {String} value
   *
   * @return {Boolean}
   */
  volumeFilter(data, value) {
    return fixed(parseFloat(data.volume)) === fixed(parseFloat(value));
  }

  /**
   * @param {Object} data
   * @param {String} value
   *
   * @return {Boolean}
   */
  positionFilter({ lastPosition }, value) {
    switch (true) {
      case value === ContainersList.POSITION_MOBILE &&
        true === lastPosition?.mobile:
        return true;
      case value === ContainersList.POSITION_SITE &&
        false === lastPosition?.mobile:
        return true;
      // only show containers without site whenever no position filter is set,
      case value === ContainersList.POSITION_ALL:
        return true;
      // or if the position filter is set to "none"
      case value === ContainersList.POSITION_NONE && !lastPosition:
        return true;
    }

    return false;
  }

  /**
   * @param {Object} data
   * @param {String} value
   *
   * @return {Boolean}
   */
  fluidFilter(data, value) {
    if (value === ContainersList.FLUID_EMPTY) {
      return data.currentFluids.length === 0;
    }

    return data.currentFluids.includes(value);
  }

  /**
   * @param {Object} data
   * @param {String} value
   *
   * @return {Boolean}
   */
  providerFilter(data, value) {
    return data.provider.uuid === value;
  }

  getFilteredList() {
    return this.props.containersList
      .filter(
        (container) => !this.state.archivedContainers.includes(container.uuid),
      )
      .filter(this.getFilterByText("barcode"))
      .filter(this.getCustomFilter("fluid", this.fluidFilter))
      .filter(this.getCustomFilter("volume", this.volumeFilter))
      .filter(this.getCustomFilter("position", this.positionFilter))
      .filter(this.getFilterByCheckbox("overloaded"))
      .filter(this.getCustomFilter("site", this.siteFilter))
      .filter(this.getCustomFilter("client", this.clientFilter))
      .filter(this.getCustomFilter("provider", this.providerFilter));
  }

  /**
   * {@inheritdoc}
   */
  renderFilters() {
    const { containersList } = this.props;
    const { filters } = this.state;
    const barcodes = Array.from(
      new Set(
        [].concat(...containersList.map((container) => container.barcode)),
      ),
    ).sort();
    const fluids = Array.from(
      new Set(
        [].concat(
          ...containersList.map((container) => container.currentFluids),
        ),
      ),
    ).sort();

    return (
      <>
        <StatelessTextInput
          id="filterByBarcode"
          defaultValue={filters.barcode}
          onChangedValue={(value) =>
            this.onChangedFilterValue(value, "barcode")
          }
          label={I18n.t("filters.by_identifier")}
          placeholder={I18n.t("filters.by_identifier:placeholder")}
          datalist={barcodes}
          optional
        />
        <Select
          id="filterByFluid"
          defaultValue={filters.fluid}
          onChange={(event) => this.onChangeFilter(event, "fluid", null, true)}
          label={I18n.t("filters.fluid")}
          placeholder={I18n.t("filters.fluid:placeholder")}
          options={[ContainersList.FLUID_EMPTY].concat(fluids)}
          renderOption={(option) => (
            <option key={option} value={option}>
              {option === ContainersList.FLUID_EMPTY
                ? I18n.t("filters.fluid:empty")
                : option}
            </option>
          )}
          optional
        />
        <Select
          id="filterByVolume"
          defaultValue={filters.volume}
          onChange={(event) => this.onChangeFilter(event, "volume")}
          label={I18n.t("filters.volume")}
          placeholder={I18n.t("filters.volume:placeholder")}
          options={Array.from(
            new window.Set(
              containersList.map((container) => fixed(container.volume)),
            ),
          ).sort()}
          renderOption={(option) => (
            <option key={option} value={option}>
              {option}L
            </option>
          )}
          optional
        />
        <Select
          id="filterBySite"
          defaultValue={filters.site}
          onChange={(event) => this.onChangeFilter(event, "site")}
          label={I18n.t("filters.site")}
          renderOption={(site) => (
            <option key={site.uuid} value={site.uuid}>
              {site.designation}
            </option>
          )}
          options={this.getSitesFromContainers()}
          placeholder={I18n.t("filters.site:placeholder")}
          optional
        />
        <Select
          id="filterByPosition"
          defaultValue={filters.mobile}
          onChange={(event) => this.onChangeFilter(event, "position")}
          label={I18n.t("filters.position")}
          placeholder={I18n.t("filters.position:placeholder")}
          options={[
            ContainersList.POSITION_SITE,
            ContainersList.POSITION_MOBILE,
            ContainersList.POSITION_NONE,
          ]}
          renderOption={(option) => (
            <option key={option} value={option}>
              {I18n.t(`filters.position:${option}`)}
            </option>
          )}
          optional
        />
        <Select
          id="filterByClient"
          defaultValue={filters.client}
          onChange={(event) => this.onChangeFilter(event, "client")}
          label={I18n.t("filters.by_client")}
          renderOption={(option) => (
            <option key={option.uuid} value={option.uuid}>
              {ClientUtils.renderLabel(option)}
            </option>
          )}
          options={this.getClientsFromContainers()}
          placeholder={I18n.t("filters.by_client:default")}
          optional
        />
        <StatelessCheckbox
          id="filterOverLoaded"
          label={I18n.t("filters.overloaded")}
          checked={Boolean(filters.overloaded)}
          onChangedValue={(checked) =>
            this.onChangeCheckboxFilter(checked, "overloaded")
          }
        />
        <Select
          id="filterByProvider"
          defaultValue={filters.provider}
          onChange={(event) => this.onChangeFilter(event, "provider")}
          label={I18n.t("filters.by_provider")}
          placeholder={I18n.t("filters.by_provider:placeholder")}
          options={this.getProvidersFromContainers()}
          renderOption={(option) => (
            <option key={option.uuid} value={option.uuid}>
              {option.designation}
            </option>
          )}
          optional
        />
      </>
    );
  }

  renderContent() {
    const { containersState, containersList } = this.props;
    const { showContainerModal } = this.state;

    if (containersState.inProgress) {
      return <div className="loader" />;
    }

    if (containersState.error) {
      return <p>{I18n.t("common.loadingApiError")}</p>;
    }

    const filteredContainers = this.getFilteredList();

    const paginationAside = (
      <span className="text-primary">
        {I18n.t("pages.containers.list.pagination_aside", {
          count: filteredContainers.length,
          weight: formatFloat(
            filteredContainers.reduce(
              (previous, c) => c.currentLoad + previous,
              0,
            ),
          ),
        })}
      </span>
    );

    const container =
      containersList.find(({ uuid }) => uuid === showContainerModal) || null;

    return (
      <>
        <StatelessTable
          emptyContent={I18n.t("pages.containers.list.empty")}
          data={filteredContainers}
          columnStyle={[null, null, null, "number", "number", null, null, null]}
          headData={this.dataTableHead()}
          rowData={this.renderRow}
          filters={this.renderFilters()}
          pagination
          onPageChanged={this.onPageChanged}
          currentPage={this.getCurrentPage()}
          paginationAside={paginationAside}
          actions={
            <DownloadButton
              fileContent={this.exportToTsv}
              filename={`climapp_contenants_${moment().format(
                "YYYY-MM-DD",
              )}.tsv`}
              label={I18n.t("common.actions.export")}
              contentType="text/csv;charset=utf8;"
            />
          }
        />
        <ContainerInterventionsModal
          onClose={() => this.setState({ showContainerModal: null })}
          container={container}
        />
      </>
    );
  }

  /**
   * {@inheritdoc}
   */
  renderTitle() {
    return I18n.t("pages.containers.list.title");
  }
}

const mapStateToProps = (state) => ({
  containersState: state.containers,
  containersList: state.containers.list.sort((a, b) =>
    alphabetically(a.barcode, b.barcode),
  ),
});

const mapDispatchToProps = (dispatch) => ({
  getContainersList: () => dispatch(getContainersList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContainersList);
