import I18n from "i18n-js";
import React from "react";
import { func, shape } from "prop-types";
import { connect } from "react-redux";
import { ROUTE_SITES } from "../../../routes";
import { getSiteInfos, updateSite } from "../actions/sitesActions";
import FormPage from "../../common/components/layout/FormPage";
import SiteForm from "../components/SiteForm";

class UpdateSite extends FormPage {
  static propTypes = {
    getSiteInfos: func.isRequired,
    updateSite: func.isRequired,
    match: shape().isRequired,
    site: shape().isRequired,
  };

  constructor() {
    super();

    this.onSubmit = this.onSubmit.bind(this);
  }

  /**
   * {@inheritdoc}
   */
  componentDidMount() {
    const { getSiteInfos, match } = this.props;

    getSiteInfos(match.params.uuid);
  }

  /**
   * @param {object} site
   */
  onSubmit(site) {
    const { updateSite, match } = this.props;

    updateSite(match.params.uuid, site);
  }

  /**
   * {@inheritdoc}
   */
  getTitle() {
    return I18n.t("pages.sites.update.title");
  }

  /**
   * {@inheritdoc}
   */
  getBackRoute() {
    return ROUTE_SITES;
  }

  /**
   * {@inheritdoc}
   */
  renderContent() {
    const { site } = this.props;

    return (
      <SiteForm
        loading={site.saving}
        infos={site.infos}
        onSubmit={this.onSubmit}
      />
    );
  }

  /**
   * {@inheritdoc}
   */
  render() {
    const { site } = this.props;

    if (site.infos === undefined) {
      return <div className="loader" />;
    }

    if (site.infos === null) {
      return <p>{I18n.t("pages.site.update.unknown")}</p>;
    }

    return super.render();
  }
}

const mapStateToProps = (state) => ({
  site: state.site,
});

const mapDispatchToProps = (dispatch) => ({
  getSiteInfos: (uuid) => dispatch(getSiteInfos(uuid)),
  updateSite: (uuid, site) => dispatch(updateSite(uuid, site)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateSite);
