import { useQuery } from "@apollo/client";
import I18n from "i18n-js";
import { shape, string } from "prop-types";
import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { ROUTE_INTERVENTIONS_PLANNED } from "../../../routes";
import { errorAlert, successAlert } from "../../common/actions/alertActions";
import { useMutation } from "../../common/api/GraphQLClient";
import FormPage from "../../common/components/layout/FormPage";
import { goBack } from "../../common/utils/navigationUtils";
import InterventionForm from "../components/InterventionForm";
import { onUpdatePlannedInterventionRequest } from "../graphql/cache/apolloCache";
import { updateInterventionPlannedQuery } from "../graphql/mutations/plannedInterventionMutations";
import { getInterventionPlannedQuery } from "../graphql/queries/plannedInterventionQueries";

class UpdatePlannedIntervention extends FormPage {
  /**
   * {@inheritdoc}
   */
  getTitle() {
    return I18n.t("pages.plannedInterventions.update.title");
  }

  constructor() {
    super();
  }

  /**
   * {@inheritdoc}
   */
  getBackRoute() {
    return ROUTE_INTERVENTIONS_PLANNED;
  }

  /**
   * {@inheritdoc}
   */
  renderContent() {
    if (this.props.intervention.inProgress) {
      return <div className="loader" />;
    }

    return (
      <InterventionForm
        onSubmit={this.props.onSubmit}
        info={this.props.intervention.info}
      />
    );
  }
}

const UpdatePlannedInterventionWrapper = (props) => {
  const dispatch = useDispatch();
  const [updateRequest] = useMutation(updateInterventionPlannedQuery, {
    update: onUpdatePlannedInterventionRequest,
  });

  const uuid = props.match.params.uuid;

  const { loading, data } = useQuery(getInterventionPlannedQuery, {
    variables: {
      uuid,
    },
  });

  const intervention = useMemo(() => {
    return {
      inProgress: loading,
      info: data?.getInterventionPlanned,
    };
  }, [data, loading]);

  const onSubmit = (payload) => {
    updateRequest({ variables: { uuid, payload } })
      .then(() => {
        const message = I18n.t("alerts.update:plannedIntervention.success");
        dispatch(successAlert(message));
        return goBack(props.history, ROUTE_INTERVENTIONS_PLANNED);
      })
      .catch(() => {
        const message = I18n.t("alerts.update:plannedIntervention.fail");
        dispatch(errorAlert(message));
      });
  };

  return (
    <UpdatePlannedIntervention
      {...props}
      intervention={intervention}
      onSubmit={onSubmit}
    />
  );
};

UpdatePlannedInterventionWrapper.propTypes = {
  history: shape().isRequired,
  match: shape({
    params: shape({
      uuid: string.isRequired,
    }).isRequired,
  }).isRequired,
};
export default UpdatePlannedInterventionWrapper;
