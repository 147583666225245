import React, { Component } from "react";
import { shape, func, number } from "prop-types";
import { connect } from "react-redux";
import classnames from "classnames";
import { resetAlert } from "../../actions/alertActions";

class Alert extends Component {
  static propTypes = {
    alert: shape().isRequired,
    resetAlert: func.isRequired,
    timeout: number,
  };

  static defaultProps = {
    timeout: 3500,
  };

  constructor() {
    super();

    this.timeout = null;
  }

  /**
   * {@inheritdoc}
   */
  componentDidUpdate(prevProps) {
    const { alert, resetAlert, timeout } = this.props;

    if (prevProps.alert.open === alert.open) {
      return;
    }

    window.clearTimeout(this.timeout);

    if (alert.open && (alert.success || alert.error)) {
      this.timeout = window.setTimeout(() => resetAlert(), timeout);
    }
  }

  /**
   * {@inheritdoc}
   */
  render() {
    const { alert } = this.props;
    const alertClasses = classnames("highlight-alert alert", {
      "alert-success": alert.success,
      "alert-danger": alert.error,
      open: alert.open,
    });

    return (
      <div className={alertClasses} role="alert">
        <p>{alert.message}</p>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  alert: state.alert,
});

const mapDispatchToProps = (dispatch) => ({
  resetAlert: () => dispatch(resetAlert()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Alert);
