import * as Sentry from "@sentry/browser";
import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
import Container from "@elao/container.js";
import rootReducer from "./rootReducer";
import ApiClient from "./modules/common/api/ApiClient";
import Authenticator from "./modules/common/api/Authenticator";
import createSentryMiddleware from "redux-sentry-middleware";

const appContainer = new Container();
const middlewares = [thunk];

if (AppConfig.SENTRY_DSN) {
  Sentry.init({
    dsn: AppConfig.SENTRY_DSN,
    environment: AppConfig.APP_RUNTIME,
    release: "k2@" + AppConfig.APP_VERSION,
    ignoreErrors: [
      /* Ignoring expired JWT tokens errors */
      /^Invalid JWT Token$/,
      /^Expired JWT Token$/,
    ],
  });

  Sentry.configureScope((scope) => {
    scope.setTag("app", "React admin");
  });

  // https://github.com/vidit-sh/redux-sentry-middleware
  middlewares.push(
    createSentryMiddleware(Sentry, {
      actionTransformer: () => null,
      stateTransformer: () => null,
    }),
  );
}

if (AppConfig.NODE_ENV === "development") {
  const logger = createLogger({ collapsed: true });

  middlewares.push(logger);
}

// Container
appContainer.register("container", appContainer);

// Redux store
// Redux dev tools: https://github.com/zalmoxisus/redux-devtools-extension
appContainer.register(
  "redux_store",
  createStore(
    rootReducer,
    /* preloadedState, */ composeWithDevTools(applyMiddleware(...middlewares)),
  ),
);

// API
appContainer.registerDefinition("api", ApiClient, ["api_host", "redux_store"]);
appContainer.registerParameter("api_host", AppConfig.API_HOST);
appContainer.registerDefinition("authenticator", Authenticator, ["api"]);

export const fetch = appContainer.get;
export const get = appContainer.get;

export default appContainer;
