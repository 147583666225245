import React from "react";
import PropTypes from "prop-types";

Spinner.propTypes = {
  show: PropTypes.bool,
};
Spinner.defaultProps = {
  show: true,
};

export default function Spinner({ show, ...props }) {
  return show ? <div className="loader" {...props} /> : null;
}
