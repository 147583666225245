/* eslint react/no-unescaped-entities: 0 */
/* eslint react/display-name: 0 */
import React, { Component } from "react";
import ImportClientsContent from "./ImportClientsContent";

export default class extends Component {
  static propTypes = ImportClientsContent.propTypes;

  /**
   * {@inheritdoc}
   */
  render() {
    return (
      <div>
        <p>
          Vous vous apprêtez à importer des client sur votre entité "
          {this.props.client}".
          <br />
          Assurez-vous que vos données ne correspondent qu'à des clients de
          cette entité.
        </p>

        <p>
          Pour réaliser l'import de vos clients, les données doivent être
          enregistrées au format TSV, et respecter la structure suivante:
        </p>

        <ul>
          <li>N° client</li>
          <li>Raison sociale</li>
          <li>SIRET</li>
          <li>Pays</li>
          <li>Ville</li>
          <li>Code postal</li>
          <li>Rue</li>
          <li>
            Rue complément <i>*1</i>
          </li>
          {this.props.companyCountry.allowsIndividual() && (
            <li>
              Particulier <i>*2</i>
            </li>
          )}
        </ul>

        <p>
          <i>*1 Optionnel</i>
          <br />
          {this.props.companyCountry.allowsIndividual() && (
            <i>
              *2 Colonne optionnelle. 0 ou 1. Précisez 1 pour identifier un
              client comme étant un particulier, ne nécessitant pas de SIRET.
            </i>
          )}
        </p>

        <p>{this.props.downloadSample}</p>

        <p>
          <strong>
            Si parmi vos clients, il existe des entités déjà référencées dans
            notre base de données, voulez-vous:
          </strong>
        </p>

        {this.props.strategyWidget}

        <p>
          <i>
            *2 L'établissement d'une relation de prestation vous permet
            d'accéder à l'ensemble des sites et des installations de votre
            client et de bénéficier automatiquement de ses mises à jour et
            créations.
          </i>
        </p>

        <p>
          Après l'intégration, la synthèse vous informera sur le nombre
          d'entités créées, et mises à jour, et mettra à disposition un fichier
          d'anomalies contenant les lignes non intégrées, et le(s) motif(s) du
          rejet.
        </p>

        <p>Les motifs d'anomalie possibles sont:</p>

        <ul>
          <li>Absence d'une donnée obligatoire</li>
          <li>SIRET incorrect</li>
          <li>Date incorrecte</li>
        </ul>
      </div>
    );
  }
}
