import I18n from "i18n-js";
import React from "react";
import {
  any,
  arrayOf,
  bool,
  func,
  oneOf,
  oneOfType,
  shape,
  string,
} from "prop-types";
import classnames from "classnames";
import InvalidFeedback, { hasErrors } from "./InvalidFeedback";

const OptionType = shape({
  id: string, // unique identifier for the selected value. Can be the same as value for string values.
  value: any.isRequired, // actual value
  label: string, // Optional, can be computed while rendering the option with user's renderOption func
  optGroup: string, // Optional group for option
});

Choice.propTypes = {
  id: string.isRequired,
  error: InvalidFeedback.propTypes.errors,
  label: oneOfType([string, oneOf([false])]).isRequired,
  legend: string,
  options: arrayOf(OptionType).isRequired,
  placeholder: string,
  renderOption: func.isRequired,
  disabled: bool,
  loading: bool,
  loadingError: bool,
  optional: bool,
  onChange: func,
  onChangedValue: func,
  layout: oneOf(["vertical", "horizontal"]),
  labelClassName: string,
  widgetClassName: string,
  selectedOptionId: string,
};

Choice.defaultProps = {
  error: null,
  legend: null,
  options: null,
  placeholder: "",
  disabled: false,
  optional: false,
  defaultValue: "",
  onChange: null,
  onChangedValue: null,
  loading: false,
  loadingError: false,
  layout: "vertical",
  labelClassName: null,
  widgetClassName: null,
  selectedOptionId: null,
};

/**
 * A select widget allowing to configure arbitrary options and getting any kind of data onChangedValue (ex: scalars, arrays, objects, ...).
 */
export default function Choice({
  id,
  error,
  label,
  legend,
  options,
  placeholder,
  renderOption,
  disabled,
  loading,
  loadingError,
  optional,
  onChange,
  onChangedValue,
  layout,
  labelClassName,
  widgetClassName,
  selectedOptionId,
}) {
  const normalizedOptions = options.map((opt) => {
    const { id, value } = opt;
    if (!id) {
      if (typeof value !== "string") {
        throw new Error(
          `Option with non-string value must define an explicit id (${JSON.stringify(
            opt,
          )})`,
        );
      }

      return { ...opt, id: value };
    }

    return opt;
  });

  const _findValue = (selectedId) =>
    normalizedOptions.find(({ id }) => id === selectedId)?.value || null;

  const _onChange = (event) => {
    if (onChange) {
      onChange(event);
    }

    if (onChangedValue) {
      const selectedId = event.target.value;

      selectedId !== ""
        ? onChangedValue(_findValue(selectedId), selectedId)
        : onChangedValue(null, null);
    }
  };

  const _renderOptions = () => {
    const defaultOption = (
      <option key="option-default" value="">
        {placeholder}
      </option>
    );

    if (!options) {
      return defaultOption;
    }

    const groupedOptions = {};
    const orphanOptions = [];
    normalizedOptions.forEach(({ optGroup, ...rest }) => {
      if (optGroup) {
        if (!groupedOptions[optGroup]) {
          groupedOptions[optGroup] = [];
        }

        groupedOptions[optGroup].push({ ...rest });
      } else {
        orphanOptions.push({ ...rest });
      }
    });

    return [
      defaultOption,
      Object.entries(groupedOptions).map(([groupLabel, groupOptions], i) => (
        <optgroup label={groupLabel} key={i}>
          {groupOptions.map(renderOption)}
        </optgroup>
      )),
      orphanOptions.map(renderOption),
    ];
  };

  const horizontalLayout = layout === "horizontal";

  return (
    <div
      className={classnames("form-group", {
        row: horizontalLayout,
      })}
    >
      {false !== label && (
        <label
          htmlFor={id}
          className={classnames(labelClassName, {
            "col-form-label": horizontalLayout,
            [labelClassName || "col-sm-2"]: horizontalLayout,
          })}
        >
          {label}
          {optional ? "" : "*"}
        </label>
      )}
      <div
        className={classnames(widgetClassName, {
          [widgetClassName || "col-sm-10"]: horizontalLayout,
        })}
      >
        <select
          className={classnames("form-control", {
            "is-invalid": hasErrors(error) || loadingError,
          })}
          id={id}
          value={selectedOptionId || ""}
          onChange={_onChange}
          required={!optional}
          disabled={disabled || loading || loadingError}
        >
          {!loading ? (
            _renderOptions()
          ) : (
            <option>{I18n.t("common.loading")}</option>
          )}
        </select>
        <InvalidFeedback errors={error} />
        {loadingError && (
          <div className="invalid-feedback">
            {I18n.t("common.loadingApiError")}
          </div>
        )}
        {legend && <small className="form-text text-muted">{legend}</small>}
      </div>
    </div>
  );
}
