import I18n from "i18n-js";
import React, { Component } from "react";

export default class CGU extends Component {
  /**
   * {@inheritdoc}
   */
  render() {
    const Content = I18n.t("Components.CGU");

    return <Content {...this.props} />;
  }
}
