import { SUBMIT_FAIL, SUBMIT_RESET, SUBMIT_SUCCESS } from "../constants";

const initialState = {
  open: false,
  success: false,
  error: false,
  message: null,
};

/**
 * Alert reducer
 *
 * @param {Object} state
 * @param {Object} action
 *
 * @return {Object}
 */
export default function alert(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case SUBMIT_SUCCESS:
      return {
        ...state,
        open: true,
        success: true,
        error: false,
        message: payload.message,
      };

    case SUBMIT_FAIL:
      return {
        ...state,
        open: true,
        success: false,
        error: true,
        message: payload.message,
      };

    case SUBMIT_RESET:
      return {
        ...initialState,
        open: false,
        message: null,
      };

    default:
      return state;
  }
}
