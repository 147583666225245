import { inPeriod } from "../../common/utils/filterUtils";

/**
 * @param {Function} property func to access to property to compare from data
 * @param {any|null} filteredValue
 *
 * @returns {function(*=): boolean}
 */
export function propertyFilter(property, filteredValue) {
  return (data) => {
    if (!filteredValue) {
      return true;
    }

    return property(data) === filteredValue;
  };
}

/**
 * Returns a filter function asserting the compared value is contained in property value
 *
 * @param {Function} property func to access to property to compare from data
 * @param {any|null} filteredValue
 *
 * @returns {function(*=): boolean}
 */
export function propertyContainsFilter(property, filteredValue) {
  return (data) => {
    if (!filteredValue) {
      return true;
    }

    if (!property(data)) {
      // no match if data is null / empty
      return false;
    }

    return property(data).toLowerCase().includes(filteredValue.toLowerCase());
  };
}

/**
 * @param {Function} property func to access to property to compare from data
 * @param {Number|null} filteredValue
 *
 * @returns {function(*=): boolean}
 */
export function greaterThanNumberFilter(property, filteredValue) {
  return (data) => {
    return property(data) >= filteredValue;
  };
}

/**
 * @param {Function} property func to access to property to compare from data
 * @param {Date|null} filteredDate
 *
 * @returns {function(*=): (boolean)}
 */
export function dateFromFilter(property, filteredDate) {
  return (data) => {
    if (!filteredDate) {
      return true;
    }

    return inPeriod(
      filteredDate instanceof Date ? filteredDate : parseInt(filteredDate),
      null,
      property,
    )(data);
  };
}

/**
 * Filters out by the enum value of data against one or an array of filtered enums.
 *
 * @param {Function} property func to access to property to compare from data
 * @param {Enum|Enum[]|null} filtered Filtered enum(s) (one of)
 *
 * @returns {function(*=): (boolean)}
 */
export function oneOfEnums(property, filtered) {
  if (filtered === null) {
    return () => true;
  }

  return (data) =>
    []
      .concat(filtered)
      .map((status) => status.value)
      .includes(property(data));
}

/**
 * Combines multiple filters so that if at least one matches, the result is true (OR).
 */
export function combineOrFilters(...filters) {
  return (value) => {
    for (const filter of filters) {
      if (filter(value)) {
        return true;
      }
    }

    return false;
  };
}
