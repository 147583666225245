import I18n from "i18n-js";
import { fetch } from "../../../appContainer";
import {
  GET_LEAK_DETECTORS_LIST_IN_PROGRESS,
  GET_LEAK_DETECTORS_LIST_SUCCESS,
  GET_LEAK_DETECTORS_LIST_FAIL,
  GET_LEAK_DETECTOR_INFOS_IN_PROGRESS,
  GET_LEAK_DETECTOR_INFOS_SUCCESS,
  GET_LEAK_DETECTOR_INFOS_FAIL,
  POST_LEAK_DETECTOR_IN_PROGRESS,
  POST_LEAK_DETECTOR_SUCCESS,
  POST_LEAK_DETECTOR_FAIL,
  PUT_LEAK_DETECTOR_IN_PROGRESS,
  PUT_LEAK_DETECTOR_SUCCESS,
  PUT_LEAK_DETECTOR_FAIL,
  DELETE_LEAK_DETECTOR_IN_PROGRESS,
  DELETE_LEAK_DETECTOR_SUCCESS,
  DELETE_LEAK_DETECTOR_FAIL,
} from "../constants";
import { SUBMIT_SUCCESS, SUBMIT_FAIL } from "../../common/constants";

/**
 * Create leak detector
 *
 * @param {Object} detector
 */
export function createLeakDetector(detector) {
  return (dispatch) => {
    dispatch({ type: POST_LEAK_DETECTOR_IN_PROGRESS });

    fetch("api").createLeakDetector(
      detector,
      () => {
        const message = I18n.t("alerts.add:leak_detector.success");

        dispatch({ type: SUBMIT_SUCCESS, payload: { message } });
        dispatch({ type: POST_LEAK_DETECTOR_SUCCESS });
      },
      (error) => {
        const message = I18n.t(
          `alerts.add:leak_detector.fail:${error.violations[0].path}`,
        );

        dispatch({ type: SUBMIT_FAIL, payload: { message } });
        dispatch({
          type: POST_LEAK_DETECTOR_FAIL,
          payload: { violations: error.violations },
        });
      },
    );
  };
}

/**
 * Update leak detector
 *
 * @param {String} uuid
 * @param {Object} detector
 */
export function updateLeakDetector(uuid, detector) {
  return (dispatch) => {
    dispatch({ type: PUT_LEAK_DETECTOR_IN_PROGRESS });

    fetch("api").updateLeakDetector(
      uuid,
      detector,
      () => {
        const message = I18n.t("alerts.update:leak_detector.success");

        dispatch({ type: SUBMIT_SUCCESS, payload: { message } });
        dispatch({ type: PUT_LEAK_DETECTOR_SUCCESS });
      },
      (error) => {
        const message = I18n.t(
          `alerts.update:leak_detector.fail:${error.violations[0].path}`,
        );

        dispatch({ type: SUBMIT_FAIL, payload: { message } });
        dispatch({
          type: PUT_LEAK_DETECTOR_FAIL,
          payload: { violations: error.violations },
        });
      },
    );
  };
}

/**
 * Get carriers list
 */
export function getLeakDetectorsList() {
  return (dispatch) => {
    dispatch({ type: GET_LEAK_DETECTORS_LIST_IN_PROGRESS });

    fetch("api").getLeakDetectorsList(
      (list) => {
        dispatch({ type: GET_LEAK_DETECTORS_LIST_SUCCESS, payload: { list } });
      },
      () => dispatch({ type: GET_LEAK_DETECTORS_LIST_FAIL }),
    );
  };
}

/**
 * Delete leak detector
 *
 * @param {String} uuid
 */
export function deleteLeakDetector(uuid) {
  return (dispatch) => {
    dispatch({ type: DELETE_LEAK_DETECTOR_IN_PROGRESS });

    fetch("api").deleteLeakDetector(
      uuid,
      () => {
        const message = I18n.t("alerts.delete:leak_detector.success");

        dispatch({ type: SUBMIT_SUCCESS, payload: { message } });
        dispatch(getLeakDetectorsList());
        dispatch({ type: DELETE_LEAK_DETECTOR_SUCCESS });
      },
      () => {
        const message = I18n.t("alerts.delete:leak_detector.fail");

        dispatch({ type: DELETE_LEAK_DETECTOR_FAIL });
        dispatch({ type: SUBMIT_FAIL, payload: { message } });
      },
    );
  };
}

/**
 * Get leak detector infos
 */
export function getLeakDetectorInfos(uuid) {
  return (dispatch) => {
    dispatch({ type: GET_LEAK_DETECTOR_INFOS_IN_PROGRESS });

    fetch("api").getLeakDetectorInfos(
      uuid,
      (infos) => {
        dispatch({ type: GET_LEAK_DETECTOR_INFOS_SUCCESS, payload: { infos } });
      },
      () => dispatch({ type: GET_LEAK_DETECTOR_INFOS_FAIL }),
    );
  };
}
