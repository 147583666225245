import React from "react";
import PropTypes from "prop-types";
import deleteInterventionRequestMutation from "../graphql/mutations/deleteInterventionRequest.graphql";
import { errorAlert, successAlert } from "../../common/actions/alertActions";
import { useDispatch } from "react-redux";
import { useMutation } from "../../common/api/GraphQLClient";
import { trans } from "../../../translations";

DeleteRequestAction.propTypes = {
  uuid: PropTypes.string.isRequired,
  onDelete: PropTypes.func,
};

DeleteRequestAction.defaultProps = {
  onDelete: null,
};

export default function DeleteRequestAction({ uuid, onDelete }) {
  const dispatch = useDispatch();
  const [deleteRequest, { loading }] = useMutation(
    deleteInterventionRequestMutation,
  );

  const onClick = () => {
    if (
      !window.confirm(trans(`pages.interventions.actions.delete.alert.message`))
    ) {
      return;
    }

    deleteRequest({ variables: { uuid } })
      .then(() => {
        dispatch(
          successAlert(
            trans(`pages.interventions.actions.delete.alert.success`),
          ),
        );
        if (null !== onDelete) {
          onDelete(uuid);
        }
      })
      .catch(() => {
        dispatch(
          errorAlert(trans(`pages.interventions.actions.delete.alert.error`)),
        );
      });
  };

  return (
    <button
      type="button"
      className="action"
      onClick={onClick}
      disabled={loading}
      title={trans(`pages.interventions.actions.delete.label`)}
    >
      <i className="fa fa-trash" aria-hidden="true" />
    </button>
  );
}
