import I18n from "i18n-js";
import React from "react";
import pathToRegexp from "path-to-regexp";
import { arrayOf, func, shape } from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import ClientTypes from "../models/ClientTypes";
import ButtonCreateSite from "../components/ButtonCreateSite";
import { getSitesList } from "../actions/sitesActions";
import { deleteClient, getClientsList } from "../actions/clientsActions";
import { alphabetically } from "../../common/utils/filterUtils";
import List from "../../common/components/layout/List";
import TextInput from "../../common/components/form/TextInput";
import {
  ROUTE_CLIENT,
  ROUTE_CLIENTS_ADD,
  ROUTE_CLIENTS_IMPORT,
} from "../../../routes";
import Roles from "../../users/models/Roles";
import { CompanySyncStatusesTable } from "../../trackdechets/components/CompanySyncStatusesTable";

class ClientsList extends List {
  static propTypes = {
    deleteClient: func.isRequired,
    clients: shape().isRequired,
    clientsList: arrayOf(shape).isRequired,
    userProfile: shape().isRequired,
  };

  constructor() {
    super();

    this.clientRoute = pathToRegexp.compile(ROUTE_CLIENT);

    this.dataTableRow = this.dataTableRow.bind(this);
  }

  /**
   * {@inheritdoc}
   */
  componentDidMount() {
    super.componentDidMount();

    this.props.getClientsList();
    this.props.getSitesList();
  }

  /**
   * @param {String}
   */
  onDelete(uuid) {
    // eslint-disable-next-line no-alert
    if (!window.confirm(I18n.t("pages.clients.confirm.delete"))) {
      return;
    }

    this.props.deleteClient(uuid);
  }

  userCanImportClients() {
    const { roles, clientType } = this.props.userProfile;
    // At least MANAGER role & not a company user
    return roles.includes(Roles.MANAGER) && clientType !== ClientTypes.COMPANY;
  }

  /**
   * @return {Array}
   */
  dataTableHead() {
    return [
      I18n.t("pages.clients.head.externalId"),
      I18n.t("pages.clients.head.legalCompanyName"),
      I18n.t("pages.clients.head.address"),
      I18n.t("pages.clients.head.actions"),
      I18n.t("pages.clients.head.actions"),
    ];
  }

  /**
   * @param {Object} rowData
   *
   * @return {Array}
   */
  dataTableRow(rowData) {
    const address = rowData.address;
    const addressString =
      address &&
      `${address.street}${
        address.addressAddition ? ` ${address.addressAddition}` : ""
      }, ${address.postal} ${address.city}, ${address.country}`;

    const notProvided = <small>{I18n.t("common.not_specified")}</small>;

    const actions = rowData.type === ClientTypes.FINAL && (
      <div>
        <Link to={this.clientRoute({ uuid: rowData.uuid })} className="action">
          <i className="fa fa-pencil" aria-hidden="true" />
        </Link>
        <button
          onClick={() => this.onDelete(rowData.uuid)}
          className="action danger"
        >
          <i className="fa fa-trash" aria-hidden="true" />
        </button>
      </div>
    );

    return [
      rowData.externalId || notProvided,
      rowData.legalCompanyName,
      addressString,
      <ButtonCreateSite key="createSite" client={rowData} />,
      actions,
    ];
  }

  /**
   * {@inheritdoc}
   */
  renderTitle() {
    return I18n.t("pages.clients.list.title");
  }

  /**
   * {@inheritdoc}
   */
  renderContent() {
    const { clients, clientsList } = this.props;

    if (clients.inProgress) {
      return <div className="loader" />;
    }

    if (clients.error) {
      return <p>{I18n.t("common.loadingApiError")}</p>;
    }

    const actions = (
      <div>
        {this.userCanImportClients() && (
          <Link to={ROUTE_CLIENTS_IMPORT} className="btn btn-primary">
            <i className="fa fa-file" />{" "}
            {I18n.t("pages.clients.list.actions.import")}
          </Link>
        )}
        <Link to={ROUTE_CLIENTS_ADD} className="btn btn-primary">
          <i className="fa fa-plus" />{" "}
          {I18n.t("pages.clients.list.actions.add")}
        </Link>
      </div>
    );

    const filteredList = clientsList
      .filter(this.getFilterByText("legalCompanyName"))
      .filter(this.getCustomFilter("city", this.cityFilter));

    return (
      <CompanySyncStatusesTable
        target="CLIENT"
        allData={clientsList || []}
        emptyContent={I18n.t("pages.clients.list.empty")}
        data={filteredList}
        headData={this.dataTableHead()}
        rowData={this.dataTableRow}
        pagination
        onPageChanged={this.onPageChanged}
        initialPage={this.getCurrentPage()}
        filters={this.renderFilters()}
        actions={actions}
      />
    );
  }

  /**
   * {@inheritdoc}
   */
  renderFilters() {
    const { filters } = this.state;

    return [
      <TextInput
        key="filterByLegalCompanyName"
        id="filterByLegalCompanyName"
        defaultValue={filters.legalCompanyName}
        onChange={(event) => this.onChangeFilter(event, "legalCompanyName")}
        label={I18n.t("filters.by_legalCompanyName")}
        optional
      />,
      <TextInput
        key="filterByCity"
        id="filterByCity"
        defaultValue={filters.city}
        onChange={(event) => this.onChangeFilter(event, "city")}
        label={I18n.t("filters.by_city")}
        optional
      />,
    ];
  }
}

const mapStateToProps = (state) => {
  const allowedTypes = [ClientTypes.FINAL, ClientTypes.SERVICE_BENEFICIARY];

  return {
    clients: state.clients,
    clientsList: state.clients.list
      .filter((client) => allowedTypes.includes(client.type))
      .sort(
        (a, b) =>
          alphabetically(a.legalCompanyName, b.legalCompanyName) ||
          alphabetically(a.address.city, b.address.city),
      ),
    userProfile: state.auth.userProfile,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getClientsList: () => dispatch(getClientsList()),
  getSitesList: () => dispatch(getSitesList()),
  deleteClient: (uuid) => dispatch(deleteClient(uuid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientsList);
