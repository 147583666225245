import { combineReducers } from "redux";
import alert from "./modules/common/reducers/alertReducer";
import error from "./modules/common/reducers/errorReducer";
import carriers from "./modules/carriers/reducers/carriersReducer";
import carrier from "./modules/carriers/reducers/carrierReducer";
import clients from "./modules/company/reducers/clientsReducer";
import client from "./modules/company/reducers/clientReducer";
import dashboard from "./modules/dashboard/reducers/dashboardReducer";
import sites from "./modules/company/reducers/sitesReducer";
import site from "./modules/company/reducers/siteReducer";
import installations from "./modules/installation/reducers/installationsReducer";
import leakDetectors from "./modules/detectors/reducers/leakDetectorsReducer";
import leakDetector from "./modules/detectors/reducers/leakDetectorReducer";
import containers from "./modules/containers/reducers/containersReducer";
import auth from "./modules/authentication/reducers/authReducer";
import users from "./modules/users/reducers/usersReducer";
import user from "./modules/users/reducers/userReducer";
import interventions from "./modules/intervention/reducers/interventionReducer";
import fibsd from "./modules/fibsd/reducers/fibsdReducer";
import completeFibsd from "./modules/fibsd/reducers/completeFibsdReducer";
import shippings from "./modules/shipping/reducers/shippingReducer";
import forgotPassword from "./modules/authentication/reducers/forgotPasswordReducer";
import providers from "./modules/service_provider/reducers/providersReducer";
import beneficiary from "./modules/service_provider/reducers/beneficiaryReducer";
import fluidSummaries from "./modules/intervention/reducers/fluidSummariesReducer";
import importClients from "./modules/company/reducers/importClientsReducer";
import importSites from "./modules/company/reducers/importSitesReducer";
import importInstallations from "./modules/installation/reducers/importInstallationsReducer";
import enterprise from "./modules/company/reducers/enterpriseReducer";
import createWasteRecoveryRequestReducer from "./modules/waste/reducers/createWasteRecoveryRequestReducer";

const rootReducer = combineReducers({
  error,
  alert,
  carriers,
  carrier,
  clients,
  client,
  createWasteRecoveryRequestReducer,
  importClients,
  dashboard,
  sites,
  site,
  importSites,
  installations,
  importInstallations,
  leakDetectors,
  leakDetector,
  containers,
  auth,
  users,
  user,
  interventions,
  shippings,
  forgotPassword,
  fibsd,
  completeFibsd,
  providers,
  beneficiary,
  fluidSummaries,
  enterprise,
});

export default rootReducer;
