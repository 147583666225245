import I18n from "i18n-js";
import React, { Component } from "react";
import { bool, func, string } from "prop-types";
import classnames from "classnames";

class SubmitButton extends Component {
  static propTypes = {
    label: string,
    loading: bool,
    onClick: func.isRequired,
  };

  static defaultProps = {
    loading: false,
    label: null,
  };

  state = {
    isLoading: this.props.loading,
  };

  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
  }

  /**
   * {@inheritdoc}
   */
  componentDidUpdate(prevProps) {
    const { loading } = this.props;

    if (prevProps.loading !== loading) {
      this.setState({ isLoading: loading });
    }
  }

  /**
   * On submit event
   *
   * @param {Object} event
   */
  onSubmit(event) {
    event.preventDefault();

    const { loading, onClick } = this.props;

    if (loading) {
      return;
    }

    onClick();
  }

  /**
   * Render button content
   *
   * @return {String|Element}
   */
  renderContent() {
    const { isLoading } = this.state;
    const { label } = this.props;

    if (isLoading) {
      return <div className="loader" />;
    }

    return label || I18n.t("common.submit");
  }

  /**
   * {@inheritdoc}
   */
  render() {
    // eslint-disable-next-line no-unused-vars
    const { loading, onClick, ...props } = this.props;
    const { isLoading } = this.state;
    const buttonClasses = classnames("btn btn--submit pull-right", {
      "btn--loading": isLoading,
    });

    return (
      <button
        type="submit"
        disabled={isLoading}
        onClick={this.onSubmit}
        className={buttonClasses}
        {...props}
      >
        {this.renderContent()}
      </button>
    );
  }
}

export default SubmitButton;
