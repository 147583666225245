import I18n from "i18n-js";
import React, { Component } from "react";
import { number, oneOf, oneOfType, string } from "prop-types";
import { formatFloat } from "../../utils/formatUtils";

export default class Quantity extends Component {
  static propTypes = {
    value: oneOfType([number, string]),
    unit: oneOf(["kg", "L", "CO2"]),
    className: string,
  };

  static defaultProps = {
    value: 0,
    unit: "kg",
    className: "",
  };

  /**
   * {@inheritdoc}
   */
  render() {
    const { value, unit, className } = this.props;

    return (
      <div
        className={`quantity ${className}`}
        data-unit={" " + I18n.t(`common.quantity.unit:${unit}`)}
      >
        {formatFloat(value || 0)}
      </div>
    );
  }
}
