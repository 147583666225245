/* eslint react/no-unescaped-entities: 0 */
import React, { Component } from "react";

export default class PrivacyPolicy extends Component {
  /**
   * {@inheritdoc}
   */
  render() {
    return (
      <div className="container">
        <h1>CHARTER PRIVATLEBEN CLIM'APP</h1>

        <h2>2. Die Bedeutung des Schutzes persönlicher Daten auf Clim'app</h2>

        <p>
          Climalife (das Unternehmen Dehon Service mit Sitz in 4, rue de la
          Croix Faubin 75011 PARIS) ist in seiner Eigenschaft als
          Datenverantwortlicher von Clim'app um die Wahrung der Privatsphäre des
          Nutzers bei der Nutzung von Clim'app besorgt und ist sich deren
          Bedeutung bewusst.
        </p>

        <p>
          Der Zweck dieser Richtlinie ist es, Sie über unsere Praktiken in Bezug
          auf die Erfassung, Nutzung und Weitergabe von personenbezogenen Daten
          (die Daten) zu informieren und richtet sich an alle Personen, die die
          Website besuchen und die mobile Anwendung Clim'app nutzen. Diese
          Person wird hier als "Benutzer" bezeichnet.
        </p>

        <p>
          Der Nutzer kann Climalife unter der oben angegebenen Postanschrift
          kontaktieren. Bei Fragen zu dieser Charta kann er sich auch an die
          folgende E-Mail-Adresse wenden: info@climapp.dehon.com.
        </p>

        <h2>3. Die verarbeiteten Daten</h2>

        <p>
          Im Rahmen von Clim'app wird Climalife wahrscheinlich die folgenden
          personenbezogenen Daten über den Kunden und/oder den Vertreter
          verarbeiten:
        </p>

        <ul>
          <li>Der Nachname und Vorname des Benutzers.</li>
          <li>
            Das Passwort und die Kennung des Nutzers, die den Zugang zu seinem
            Konto auf Clim'app ermöglichen (das Passwort wird niemals im
            Klartext auf den Servern von Climalife gespeichert, in
            Übereinstimmung mit den Anforderungen der Nationalen Kommission für
            Informationstechnologie und bürgerliche Freiheiten);
          </li>
          <li>Die IP-Adresse (automatisch erkannt) des Benutzers;</li>
          <li>
            Informationen über die Nutzung von Clim'app (einschließlich der
            aufgerufenen Seiten) durch den Nutzer;
          </li>
          <li>
            Im Allgemeinen alle Informationen, die der Nutzer freiwillig angibt,
            z. B. bei der Nutzung der Clim'app.
          </li>
        </ul>

        <h2>4. Die Zwecke der Verarbeitung dieser Daten</h2>

        <p>Climalife verarbeitet diese Daten für die folgenden Zwecke:</p>

        <ul>
          <li>
            den Zugang und die Verwaltung des Kontos im Rahmen des
            abgeschlossenen Vertrages zu ermöglichen;
          </li>
          <li>
            Um dem Nutzer die Nutzung aller auf Clim'app angebotenen Dienste zu
            ermöglichen, einschließlich der Identifizierung in CERFA-Dokumenten;
          </li>
          <li>
            Um die technische Administration und Sicherheit von Clim'app zu
            gewährleisten;
          </li>
          <li>
            Übermittlung von Informationen über Produkte und Dienstleistungen,
            die Climalife anbietet, an den Nutzer, einschließlich per E-Mail;
          </li>
          <li>
            Zur Erstellung von Statistiken, insbesondere über die Nutzung der
            verschiedenen Bereiche von Clim'app durch den Nutzer;
          </li>
          <li>
            Informationen über andere Produkte und Dienstleistungen, die
            Climalife anbietet oder die von Dritten angeboten werden, zu
            übermitteln, einschließlich per E-Mail.
          </li>
        </ul>

        <p>
          Diese Behandlungen basieren auf Ihrer Zustimmung, wenn Sie Ihr Konto
          eröffnen.
        </p>

        <h2>5. Sicherheit und Vertraulichkeit</h2>

        <p>
          Die persönlichen Daten des Nutzers werden ohne seine Zustimmung nicht
          an Dritte weitergegeben, mit Ausnahme von Subunternehmern von
          Climalife und anderen Unternehmen der DEHON Gruppe. Die Subunternehmer
          werden nur auf Anweisung und im Namen von Climalife im Rahmen des
          geschlossenen Vertrages tätig.
        </p>

        <p>
          Climalife kann im Rahmen dieser Charta Informationen über den Nutzer
          auch an andere Unternehmen der DEHON-Gruppe weitergeben.
        </p>

        <p>
          Climalife behält sich jedoch auch das Recht vor, personenbezogene
          Daten auf Anfrage einer Verwaltungs- oder Justizbehörde offenzulegen
          oder wenn Climalife in gutem Glauben davon ausgeht, dass eine solche
          Offenlegung erforderlich ist, um ein geltendes Gesetz oder eine
          Verordnung einzuhalten.
        </p>

        <p>
          In der Regel bewahren wir Ihre personenbezogenen Daten innerhalb der
          Europäischen Union auf. In den Fällen, in denen wir einen Teil Ihrer
          personenbezogenen Daten in Drittländer übermitteln, stellen wir jedoch
          sicher, dass diese Übermittlung in Übereinstimmung mit den geltenden
          Vorschriften erfolgt und ein ausreichendes Schutzniveau für die
          Privatsphäre und die Grundrechte des Einzelnen gemäß der DDR
          gewährleistet.
        </p>

        <h2>6. Aufbewahrungsfrist</h2>

        <p>Die Daten werden am Ende der folgenden Perioden archiviert:</p>

        <ul>
          <li>
            5 Jahre nach der letzten Nutzung der Plattform durch den
            Hauptkontoinhaber, wenn das Konto nicht geschlossen wurde;
          </li>
          <li>2 Jahre nach Schließung des Kontos.</li>
        </ul>

        <h2>7. die Rechte des Benutzers</h2>

        <h3>1. Zugriffsrecht :</h3>

        <p>
          Der Nutzer kann Zugang zu seinen persönlichen Daten haben, die von
          ClimApp verarbeitet werden. Auf Wunsch stellt Climalife dem Nutzer
          eine Kopie aller persönlichen Daten und alle gesetzlich
          vorgeschriebenen Informationen zur Verfügung, einschließlich :
        </p>

        <ul>
          <li>
            Die Kategorien der erhobenen und verarbeiteten personenbezogenen
            Daten;
          </li>
          <li>Die Zwecke ihrer Verarbeitung;</li>
          <li>
            Die Kategorien von Empfängern, an die die personenbezogenen Daten
            weitergegeben wurden oder weitergegeben werden können;
          </li>
          <li>die Aufbewahrungsfrist der personenbezogenen Daten; und</li>
          <li>
            Information über die Rechte des Benutzers in Bezug auf seine
            persönlichen Daten.
          </li>
        </ul>

        <h3>2. Recht auf Berichtigung</h3>

        <p>
          Der Benutzer kann die Berichtigung seiner persönlichen Daten
          verlangen, wenn sich diese als unrichtig, unvollständig oder veraltet
          erweisen.
        </p>

        <h3>3. Recht auf Löschung</h3>

        <p>
          Der Benutzer kann in den folgenden Fällen die Löschung seiner
          personenbezogenen Daten verlangen:
        </p>

        <ul>
          <li>
            Wenn seine personenbezogenen Daten für die Zwecke, für die sie
            erhoben oder verarbeitet wurden, nicht mehr erforderlich sind;
          </li>
          <li>
            Wenn der Benutzer der Erfassung oder Verarbeitung seiner
            personenbezogenen Daten gemäß dem folgenden Absatz dieses Artikels
            widersprochen hat;
          </li>
          <li>
            Wenn die Verarbeitung der personenbezogenen Daten des Benutzers
            unrechtmäßig ist;
          </li>
          <li>
            Wenn die personenbezogenen Daten des Nutzers gelöscht werden müssen,
            damit ClimApp einer gesetzlichen Verpflichtung nachkommen kann.
          </li>
        </ul>

        <h3>4. Recht auf Einspruch</h3>

        <p>
          Der Kunde und/oder der Bevollmächtigte kann sich aus berechtigten
          Gründen, die sich aus der Besonderheit seiner Situation ergeben, der
          Verarbeitung seiner personenbezogenen Daten widersetzen, wenn diese
          aufgrund der Notwendigkeit zur Erfüllung einer Aufgabe von
          öffentlichem Interesse oder zur Verfolgung des berechtigten Interesses
          von ClimApp verarbeitet werden. Der Kunde und/oder der Bevollmächtigte
          kann sich auch immer, ohne Verpflichtung zur Begründung, der
          Verarbeitung seiner personenbezogenen Daten widersetzen, wenn diese zu
          Zwecken der kommerziellen Werbung erfolgt.
        </p>

        <h3>5. Recht auf Einschränkung der Verarbeitung</h3>

        <p>
          Der Benutzer kann die Einschränkung der Verarbeitung seiner
          personenbezogenen Daten in den folgenden Fällen verlangen:
        </p>

        <ul>
          <li>
            Für den Fall, dass er die Richtigkeit seiner persönlichen Daten
            bestreitet, für die Zeit, die Climalife benötigt, um diese zu
            überprüfen;
          </li>
          <li>
            Wenn die Verarbeitung seiner personenbezogenen Daten rechtswidrig
            ist und der Nutzer sie einschränken möchte, anstatt die Löschung
            dieser Daten zu verlangen;
          </li>
          <li>
            Wenn der Nutzer der Verarbeitung seiner personenbezogenen Daten
            widersprochen hat, für den Zeitraum, in dem ClimApp nachweist, dass
            es keine anderen zwingenden legitimen Gründe für die weitere
            Verarbeitung der Daten hat.
          </li>
        </ul>

        <h3>6. Ausübung der vorgenannten Rechte</h3>

        <p>
          Diese Rechte können durch eine einfache E-Mail an die folgende Adresse
          ausgeübt werden: info@climapp.dehon.com, die eine lesbare Kopie des
          Ausweises des Benutzers und, für die Ausübung des Widerspruchsrechts,
          eine Beschreibung der Gründe für den Widerspruch enthalten muss.
        </p>

        <p>
          Climalife kann Anfragen zur Ausübung eines oder mehrerer der oben
          genannten Rechte ablehnen, wenn diese Anfragen missbräuchlich
          erscheinen, insbesondere im Hinblick auf ihre Häufigkeit, Anzahl,
          wiederholende oder systematische Natur.
        </p>
      </div>
    );
  }
}
