import React from "react";
import I18n from "i18n-js";
import FormLayout from "../../common/components/layout/FormLayout";
import {
  ROUTE_COMPANY_WASTE_RECOVERY_REQUESTS,
  ROUTE_MY_WASTE_RECOVERY_REQUESTS,
  ROUTE_WASTE_RECOVERY_REQUEST_STATUS,
} from "../../../routes";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router";
import getWasteRecoveryRequest from "../graphql/queries/getWasteRecoveryRequest.graphql";
import LoadingWrapper from "../../common/components/elements/LoadingWrapper";
import RequestSummary, {
  BooleanCell,
  DetailRow,
} from "../components/wasteRequestSummary/RequestSummary";
import Quantity from "../../common/components/elements/Quantity";
import TreatmentMode from "../models/TreatmentMode";
import Packaging from "../models/Packaging";
import { Link } from "react-router-dom";
import pathToRegexp from "path-to-regexp";
import Roles from "../../users/models/Roles";
import { useAuth } from "../../authentication/contexts/useAuth";
import SoftDeletableRequest from "../components/SoftDeletableRequest";

export default function ShowWasteRecoveryRequestSummary() {
  const { hasRole } = useAuth();
  const { uuid } = useParams();

  const { loading, error, data } = useQuery(getWasteRecoveryRequest, {
    variables: { uuid },
  });

  return (
    <LoadingWrapper loading={loading} error={Boolean(error)}>
      {() => {
        const request = data.getWasteRecoveryRequest;

        return (
          <SoftDeletableRequest deleted={request.deleted}>
            <FormLayout
              title={`Détails de la demande de reprise de déchets N° ${request.number}`}
              size={FormLayout.LARGE}
              backRoute={
                hasRole(Roles.COMMERCIAL)
                  ? ROUTE_MY_WASTE_RECOVERY_REQUESTS
                  : ROUTE_COMPANY_WASTE_RECOVERY_REQUESTS
              }
              actions={
                <>
                  <Link
                    className="btn btn-primary ml-1"
                    to={pathToRegexp.compile(
                      ROUTE_WASTE_RECOVERY_REQUEST_STATUS,
                    )({ uuid })}
                    type="button"
                  >
                    Statut
                  </Link>
                </>
              }
            >
              <div>
                <div className="col-xl-6 mb-3">
                  <DetailRow title="Référence commerciale">
                    {request.commercialReference ?? "-"}
                  </DetailRow>
                  <DetailRow title="Référence interne">
                    {request.authorReference ?? "-"}
                  </DetailRow>
                </div>

                <RequestSummary
                  request={{
                    ...request,
                    siteUuid: request.site.uuid,
                    carrierUuid: request.carrier?.uuid,
                  }}
                  renderArticleRow={renderArticleRow}
                  renderContainerRow={renderContainerRow}
                >
                  <div className="col-12 mt-4 order-last">
                    <Link
                      className="btn btn-primary float-right"
                      to={pathToRegexp.compile(
                        ROUTE_WASTE_RECOVERY_REQUEST_STATUS,
                      )({ uuid })}
                      type="button"
                    >
                      Accéder au statut de la demande
                    </Link>
                  </div>
                </RequestSummary>
              </div>
            </FormLayout>
          </SoftDeletableRequest>
        );
      }}
    </LoadingWrapper>
  );
}

function renderContainerRow({
  container: { barcode },
  currentLoad,
  article,
  fluid,
  treatmentMode,
  packaging,
  toDestruct,
  toReturn,
}) {
  return [
    /* eslint-disable react/jsx-key */
    barcode,
    article.designation,
    fluid?.designation,
    <Quantity value={article.volume} unit={"L"} />,
    <Quantity value={currentLoad} unit={"kg"} />,
    treatmentMode && I18n.t(new TreatmentMode(treatmentMode).getReadable()),
    <BooleanCell value={toDestruct} />,
    <BooleanCell value={toReturn} />,
    packaging && I18n.t(new Packaging(packaging).getReadable()),
    /* eslint-enable react/jsx-key */
  ];
}

function renderArticleRow({
  article: { designation },
  producer,
  mass,
  quantity,
  treatmentMode,
  toDestruct,
  toReturn,
  packaging,
}) {
  return [
    /* eslint-disable react/jsx-key */
    designation,
    producer?.legalCompanyName,
    quantity,
    <Quantity value={mass} unit={"kg"} />,
    treatmentMode && I18n.t(new TreatmentMode(treatmentMode).getReadable()),
    <BooleanCell value={toDestruct} />,
    <BooleanCell value={toReturn} />,
    packaging && I18n.t(new Packaging(packaging).getReadable()),
    /* eslint-enable react/jsx-key */
  ];
}
