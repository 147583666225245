import I18n from "i18n-js";
import { fetch } from "../../../appContainer";
import {
  GET_FIBSD_LIST_IN_PROGRESS,
  GET_FIBSD_LIST_SUCCESS,
  GET_FIBSD_LIST_FAIL,
  GET_FIBSD_IN_PROGRESS,
  GET_FIBSD_SUCCESS,
  GET_FIBSD_FAIL,
  COMPLETE_FIBSD_IN_PROGRESS,
  COMPLETE_FIBSD_SUCCESS,
  COMPLETE_FIBSD_FAIL,
} from "../constants";
import { SUBMIT_SUCCESS, SUBMIT_FAIL } from "../../common/constants";

/**
 * Get FIBSD list
 */
export function getFibsdList() {
  return (dispatch) => {
    dispatch({ type: GET_FIBSD_LIST_IN_PROGRESS });

    fetch("api").getFibsdList(
      (list) => {
        dispatch({ type: GET_FIBSD_LIST_SUCCESS, payload: { list } });
      },
      () => dispatch({ type: GET_FIBSD_LIST_FAIL }),
    );
  };
}

/**
 * Get FIBSD info
 */
export function getFibsdInfo(uuid) {
  return (dispatch) => {
    dispatch({ type: GET_FIBSD_IN_PROGRESS });

    fetch("api").getFibsdInfo(
      uuid,
      (details) => {
        dispatch({ type: GET_FIBSD_SUCCESS, payload: { details } });
      },
      () => dispatch({ type: GET_FIBSD_FAIL }),
    );
  };
}

export function completeFIBSD(uuid, fibsd) {
  return (dispatch) => {
    dispatch({ type: COMPLETE_FIBSD_IN_PROGRESS });

    fetch("api").completeFibsd(
      uuid,
      fibsd,
      () => {
        const message = I18n.t("alerts.complete:fibsd.success");

        dispatch({ type: SUBMIT_SUCCESS, payload: { message } });
        dispatch({ type: COMPLETE_FIBSD_SUCCESS });
      },
      () => {
        const message = I18n.t("alerts.complete:fibsd.fail");

        dispatch({ type: COMPLETE_FIBSD_FAIL });
        dispatch({ type: SUBMIT_FAIL, payload: { message } });
      },
    );
  };
}
