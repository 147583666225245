import I18n from "i18n-js";
import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom";
import DownloadButton from "../../common/components/elements/DownloadButton";
import Dropdown from "../../common/components/elements/Dropdown";
import { alphabetically } from "../../common/utils/filterUtils";
import { getLang } from "../../common/utils/langUtils";
import { getClientsList } from "../../company/actions/clientsActions";
import ClientUtils from "../../company/utils/ClientUtils";
import { getUsersList } from "../../users/actions/usersActions";
import DeleteRequestAction from "../actions/DeleteRequestAction";
import { getInterventionList } from "../actions/interventionActions";
import InterventionList from "../components/InterventionList";
import InterventionType from "../models/InterventionType";
import Purpose from "../models/Purpose";
import pathToRegexp from "path-to-regexp";
import { ROUTE_INTERVENTION } from "../../../routes";
import { fetch } from "../../../appContainer";

class InterventionListAll extends InterventionList {
  constructor(props) {
    super(props);

    this.interventionRoute = pathToRegexp.compile(ROUTE_INTERVENTION);
    this.exportToTsv = this.exportToTsv.bind(this);
  }

  exportToTsv() {
    return new Promise((download) => {
      fetch("api").exportInterventions(
        this.getFilteredList().map(({ uuid }) => uuid),
        (tsvContent) => download(tsvContent),
        (error) => {
          console.error(error);
          download(null);
        },
      );
    });
  }

  renderActions() {
    return (
      <DownloadButton
        fileContent={this.exportToTsv}
        filename={`climapp_intervention_${moment().format("YYYY-MM-DD")}.tsv`}
        label={I18n.t("common.actions.export")}
        contentType="text/csv;charset=utf8;"
      />
    );
  }

  dataTableHead() {
    return [
      I18n.t("pages.interventions.list.head.record"),
      I18n.t("pages.interventions.list.head.date"),
      ...(this.shouldRenderTechFilter
        ? [I18n.t("pages.interventions.list.head.technician")]
        : []),
      I18n.t("pages.interventions.list.head.client"),
      I18n.t("pages.interventions.list.head.installation"),
      I18n.t("pages.interventions.list.head.type"),
      I18n.t("pages.interventions.list.head.purpose"),
      I18n.t("pages.interventions.list.head.document"),
      "",
    ];
  }

  dataTableRow(rowData) {
    const {
      record,
      client,
      installation,
      creation,
      type,
      purpose,
      userUuid,
      documents,
    } = rowData;

    const actions = (
      <div>
        <Link
          to={this.interventionRoute({ uuid: rowData.uuid })}
          className="action"
        >
          <i className="fa fa-eye" aria-hidden="true" />
        </Link>
        <DeleteRequestAction
          uuid={rowData.uuid}
          onDelete={this.removeIntervention}
        />
      </div>
    );

    const documentElements = documents?.map((document, index) => {
      return (
        <a href={document.url} target="blank" key={index}>
          {I18n.t(`pages.interventions.list.document.${document["type"]}`)}
        </a>
      );
    });

    const technician = this.renderTechnician(userUuid);

    return [
      <Link to={this.interventionRoute({ uuid: rowData.uuid })} key="record">
        {record}
      </Link>,
      new Date(creation).toLocaleDateString(getLang()),
      ...(technician ? [technician] : []),
      ClientUtils.renderLabel(client),
      this.getInstallationLabel(installation),
      I18n.t(InterventionType.readableFor(type)),
      purpose ? I18n.t(Purpose.readableFor(purpose)) : null,
      documents && (
        <Dropdown
          label={
            documentElements.length +
            " " +
            I18n.t("pages.interventions.list.head.document")
          }
        >
          {documentElements}
        </Dropdown>
      ),
      actions,
    ];
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  clientsList: state.clients.list.sort((a, b) =>
    alphabetically(a.legalCompanyName, b.legalCompanyName),
  ),
  interventions: state.interventions,
  interventionsList: state.interventions.list,
  users: state.users,
  userProfile: state.auth.userProfile,
});

const mapDispatchToProps = (dispatch) => ({
  getInterventionList: () => dispatch(getInterventionList()),
  getClientsList: () => dispatch(getClientsList()),
  getUsersList: () => dispatch(getUsersList()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InterventionListAll);
