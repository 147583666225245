/*
 * This file was generated by the "elao/enum" PHP package.
 * The code inside belongs to you and can be altered, but no BC promise is guaranteed.
 */

import { ReadableEnum } from "../../../libs/enum";

class Packaging extends ReadableEnum {
  static PALLET = "PALLET";
  static CAGE = "CAGE";

  static get readables() {
    return {
      [Packaging.PALLET]: "enum.waste.packaging.pallet",
      [Packaging.CAGE]: "enum.waste.packaging.cage",
    };
  }
}

export default Packaging;
