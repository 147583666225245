import {
  apolloAddItem,
  apolloPurgeQuery,
  apolloRemoveItem,
  apolloRemoveItemsFromResult,
} from "../../../common/graphql/cache/apolloCacheUtils";

/**
 * Cache side-effects on a new waste recovery request creation
 */
export function onCreateWasteRecoveryRequest(cache, result) {
  const mutation = "createWasteRecoveryRequest";
  // Add new request to the list query:
  apolloAddItem(
    "myWasteRecoveryRequests",
    "WasteRecoveryRequest",
    mutation,
  )(cache, result);
  // Remove used containers from eligible ones for a next request:
  apolloRemoveItemsFromResult((data) =>
    data[mutation].containers.map((c) => c.container),
  )(cache, result);
}

export function onDeleteWasteRecoveryRequest(cache, result) {
  // Remove the request from list:
  apolloRemoveItem("deleteWasteRecoveryRequest")(cache, result);
  // Remove whole results for eligible container query
  apolloPurgeQuery("wasteEligibleContainers")(cache, result);
}
