import { func, string } from "prop-types";
import React from "react";
import { useQuery } from "@apollo/client";
import wasteArticleProducers from "../graphql/queries/wasteArticleProducers.graphql";
import Select from "../../common/components/form/Select";
import { alphabetically, uniq } from "../../common/utils/filterUtils";

SelectWasteArticleProducer.propTypes = {
  value: string, // selected value
  label: Select.propTypes.label,
  id: string.isRequired,
  onChangedValue: func.isRequired,
  siteUuid: string.isRequired,
};

export default function SelectWasteArticleProducer({
  value,
  siteUuid,
  ...props
}) {
  const { loading, error, data } = useQuery(wasteArticleProducers, {
    variables: {
      site: siteUuid,
    },
  });

  let producers = data?.wasteArticleProducers || [];
  producers = uniq(
    producers.reduce(
      (producers, { uuid, legalCompanyName }) => [
        ...producers,
        {
          uuid,
          legalCompanyName,
        },
      ],
      [],
    ),
    "uuid",
  ).sort((a, b) => alphabetically(a.legalCompanyName, b.legalCompanyName));

  return (
    <Select
      loading={loading}
      loadingError={Boolean(error)}
      options={producers}
      renderOption={(option) => (
        <option key={`option-${option.uuid}`} value={option.uuid}>
          {option.legalCompanyName}
        </option>
      )}
      defaultValue={value}
      error={
        data?.wasteArticleProducers.length === 0 && "Aucun producteur éligible"
      }
      {...props}
    />
  );
}
