import React from "react";
import { bool, func, string, oneOf } from "prop-types";
import classnames from "classnames";
import InvalidFeedback, { hasErrors } from "./InvalidFeedback";

Textarea.propTypes = {
  id: string.isRequired,
  errors: InvalidFeedback.propTypes.errors,
  label: string.isRequired,
  optional: bool,
  onChange: func,
  onChangedValue: func,
  defaultValue: string,
  layout: oneOf(["vertical", "horizontal"]),
  labelClassName: string,
  widgetClassName: string,
};

Textarea.defaultProps = {
  errors: null,
  optional: false,
  onChange: null,
  onChangedValue: null,
  defaultValue: null,
  layout: "vertical",
  labelClassName: null,
  widgetClassName: null,
};

export default function Textarea({
  id,
  errors,
  label,
  optional,
  onChange,
  onChangedValue,
  layout,
  labelClassName,
  widgetClassName,
  ...props
}) {
  const horizontalLayout = layout === "horizontal";

  return (
    <div
      className={classnames("form-group", {
        row: horizontalLayout,
      })}
    >
      <label
        htmlFor={id}
        className={classnames({
          "col-form-label": horizontalLayout,
          [labelClassName || "col-sm-2"]: horizontalLayout,
        })}
      >
        {label}
        {optional || "*"}
      </label>
      <div
        className={classnames(widgetClassName, {
          [widgetClassName || "col-sm-10"]: horizontalLayout,
        })}
      >
        <textarea
          required={!optional}
          className={classnames("form-control", {
            "is-invalid": hasErrors(errors),
          })}
          id={id}
          onChange={(e) => {
            onChange && onChange(e);
            onChangedValue && onChangedValue(e.target.value);
          }}
          {...props}
        />
      </div>
      <InvalidFeedback errors={errors} />
    </div>
  );
}
