import gql from "graphql-tag";
import { FRAGMENT_INTERVENTION_PLANNED_FIELDS } from "../fragment/InterventionPlanned";

export const listInterventionPlannedQuery = gql`
  query listInterventionPlanned {
    listInterventionPlanned {
      ...interventionPlannedFields
    }
  }
  ${FRAGMENT_INTERVENTION_PLANNED_FIELDS}
`;

export const getInterventionPlannedQuery = gql`
  query getPlannedIntervention($uuid: ID!) {
    getInterventionPlanned(uuid: $uuid) {
      ...interventionPlannedFields
    }
  }
  ${FRAGMENT_INTERVENTION_PLANNED_FIELDS}
`;
