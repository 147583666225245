export const GET_USERS_LIST_IN_PROGRESS = "GET_USERS_LIST_IN_PROGRESS";
export const GET_USERS_LIST_SUCCESS = "GET_USERS_LIST_SUCCESS";
export const GET_USERS_LIST_FAIL = "GET_USERS_LIST_FAIL";

export const POST_USER_IN_PROGRESS = "POST_USER_IN_PROGRESS";
export const POST_USER_SUCCESS = "POST_USER_SUCCESS";
export const POST_USER_FAIL = "POST_USER_FAIL";
export const PUT_USER_IN_PROGRESS = "PUT_USER_IN_PROGRESS";
export const PUT_USER_SUCCESS = "PUT_USER_SUCCESS";
export const PUT_USER_FAIL = "PUT_USER_FAIL";
export const GET_USER_INFOS_IN_PROGRESS = "GET_USER_INFOS_IN_PROGRESS";
export const GET_USER_INFOS_SUCCESS = "GET_USER_INFOS_SUCCESS";
export const GET_USER_INFOS_FAIL = "GET_USER_INFOS_FAIL";

export const PATCH_USER_LOCK_SUCCESS = "PATCH_USER_LOCK_SUCCESS";
export const PATCH_USER_LOCK_FAIL = "PATCH_USER_LOCK_FAIL";
export const PATCH_USER_UNLOCK_SUCCESS = "PATCH_USER_UNLOCK_SUCCESS";
export const PATCH_USER_UNLOCK_FAIL = "PATCH_USER_UNLOCK_FAIL";
