import React, { Component } from "react";
import { arrayOf, element, string } from "prop-types";

class Fieldset extends Component {
  static propTypes = {
    children: arrayOf(element).isRequired,
    title: string.isRequired,
  };

  render() {
    const { children, title } = this.props;

    return (
      <div className="fieldset">
        <div className="fieldset__title">
          <h3>{title}</h3>
          <div className="border" />
        </div>
        <div className="fieldset__content">
          <div className="border" />
          {children}
        </div>
      </div>
    );
  }
}

export default Fieldset;
