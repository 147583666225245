import I18n from "i18n-js";
import React from "react";
import { func, shape } from "prop-types";
import { connect } from "react-redux";
import { ROUTE_USERS } from "../../../routes";
import CreateUserForm from "../components/CreateUserForm";
import FormPage from "../../common/components/layout/FormPage";
import { getUserInfos, updateUser } from "../actions/usersActions";

class UpdateUser extends FormPage {
  static propTypes = {
    getUserInfos: func.isRequired,
    updateUser: func.isRequired,
    match: shape().isRequired,
    user: shape().isRequired,
  };

  constructor() {
    super();

    this.onSubmit = this.onSubmit.bind(this);
  }

  /**
   * {@inheritdoc}
   */
  componentDidMount() {
    const { getUserInfos, match } = this.props;

    getUserInfos(match.params.uuid);
  }

  /**
   * @param {object} user
   */
  onSubmit(user) {
    const { updateUser, match } = this.props;

    updateUser(match.params.uuid, user);
  }

  /**
   * {@inheritdoc}
   */
  getTitle() {
    return I18n.t("pages.users.update.title");
  }

  /**
   * {@inheritdoc}
   */
  getBackRoute() {
    return ROUTE_USERS;
  }

  /**
   * {@inheritdoc}
   */
  renderContent() {
    const { user } = this.props;

    return (
      <CreateUserForm
        loading={user.saving}
        infos={user.infos}
        onSubmit={this.onSubmit}
      />
    );
  }

  /**
   * {@inheritdoc}
   */
  render() {
    const { user } = this.props;

    if (user.infos === undefined) {
      return <div className="loader" />;
    }

    if (user.infos === null) {
      return <p>{I18n.t("pages.users.update.unknown")}</p>;
    }

    return super.render();
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  getUserInfos: (uuid) => dispatch(getUserInfos(uuid)),
  updateUser: (uuid, user) => dispatch(updateUser(uuid, user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateUser);
