import { SUBMIT_FAIL, SUBMIT_RESET, SUBMIT_SUCCESS } from "../constants";

export function errorAlert(message) {
  return { type: SUBMIT_FAIL, payload: { message } };
}

export function successAlert(message) {
  return { type: SUBMIT_SUCCESS, payload: { message } };
}

/**
 * Reset alert
 */
export function resetAlert() {
  return (dispatch) => dispatch({ type: SUBMIT_RESET });
}
