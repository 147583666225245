import {
  ApolloClient,
  from,
  InMemoryCache,
  useMutation as useApolloMutation,
} from "@apollo/client";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { ErrorLink } from "@apollo/client/link/error";
import { get } from "../../../appContainer";
import { logout } from "../../authentication/actions/authActions";
import I18n from "i18n-js";
import GraphQLErrors from "./GraphQLErrors";
import * as Sentry from "@sentry/browser";
import { error403, error404 } from "../actions/errorActions";
import { cacheConfig } from "../graphql/cache/apolloCacheConfig";

const authenticatedFetch = (fetch) => (uri, options) => {
  const token = get("authenticator").getToken();

  options.headers.Authorization = `Bearer ${token}`;

  return fetch(uri, options);
};

const localizedFetch = (fetch) => (uri, options) => {
  options.headers["Accept-Language"] = I18n.locale;

  return fetch(uri, options);
};

const batchLink = new BatchHttpLink({
  uri: AppConfig.API_HOST + "/graphql/batch",
  batchMax: 10,
  batchInterval: 20,
  fetch: authenticatedFetch(localizedFetch(fetch)),
});

const errorLink = createGraphQLErrorLink();

const graphql = new ApolloClient({
  link: from([errorLink, batchLink]),
  cache: new InMemoryCache(cacheConfig),
});

export default graphql;

/**
 * Global error handling on GraphQL calls.
 * Formats violation errors in the console.
 *
 * @see https://www.apollographql.com/docs/link/links/error.html
 *
 * @returns {ErrorLink}
 */
function createGraphQLErrorLink() {
  return new ErrorLink(onError);
}

function onError({ graphQLErrors, networkError, updateError, operation }) {
  if (graphQLErrors) {
    graphQLErrors.forEach((graphQLError) => {
      const { api_problem, message, path, code } = graphQLError;
      let formattedError = `[GraphQL error]: Message "${message}" at path "${path}"`;
      if (code) {
        formattedError += ` (code: ${code})`;
      }

      if (code === GraphQLErrors.INVALID_PAYLOAD) {
        formattedError += ":";
        api_problem.violations.forEach(({ code, path, reason }) => {
          formattedError += `\n    - ✗ violation at path "${path}": "${reason}" (code: "${code}")`;
        });
      } else if (code === GraphQLErrors.NOT_FOUND) {
        if (operation && operation.getContext()?.on404) {
          // Execute specific callback on 404 if provided:
          operation.getContext()?.on404();

          return;
        }

        get("redux_store").dispatch(error404());
      } else if (code === GraphQLErrors.FORBIDDEN) {
        get("redux_store").dispatch(error403());
      } else if (code === GraphQLErrors.DOMAIN_ERROR) {
        // Do nothing
      } else {
        // Capture non violations errors:
        Sentry.captureException(graphQLError);
      }
      // eslint-disable-next-line no-console
      console.error(formattedError, graphQLErrors);
    });
  }

  if (networkError) {
    if (networkError.statusCode === 401) {
      // eslint-disable-next-line no-console
      console.warn("[Auth error] Expired JWT token. Disconnect.");
      get("redux_store").dispatch(logout());
      return;
    }

    // eslint-disable-next-line no-console
    console.error(`[Network error]: ${networkError}`);
    // Send unexpected network errors to Sentry
    Sentry.captureException(networkError);
  }

  /**
   * Custom error, happening on mutation cache update issue
   * @see useMutation
   */
  if (updateError) {
    // eslint-disable-next-line no-console
    console.error(`[Mutation update error]: ${updateError}`);
    // Send unexpected update errors to Sentry
    Sentry.captureException(updateError);
  }
}

/**
 * Decorates Apollo' useMutation hook so the global onError logic is always called,
 * even in case of an error occurring during Apollo's cache update.
 */
export function useMutation(mutation, { update = null, ...options } = {}) {
  return useApolloMutation(mutation, {
    update: (...updateArgs) => {
      try {
        update && update(...updateArgs);
      } catch (updateError) {
        onError({ updateError });
        throw updateError;
      }
    },
    ...options,
  });
}
