import Enum from "../../common/models/Enum";

class InterventionType extends Enum {
  static DRAINAGE = "drainage";

  static FILLING = "filling";

  static LEAK = "leak";

  static LEAK_REPAIR = "leak_repair";

  static ASSEMBLY = "assembly";

  static DISASSEMBLY = "disassembly";

  static values = [
    InterventionType.DRAINAGE,
    InterventionType.FILLING,
    InterventionType.LEAK,
    InterventionType.LEAK_REPAIR,
    InterventionType.ASSEMBLY,
    InterventionType.DISASSEMBLY,
  ];

  static readables = {
    [InterventionType.DRAINAGE]: "enum.intervention_type.drainage",
    [InterventionType.FILLING]: "enum.intervention_type.filling",
    [InterventionType.LEAK]: "enum.intervention_type.leak",
    [InterventionType.LEAK_REPAIR]: "enum.intervention_type.leak_repair",
    [InterventionType.ASSEMBLY]: "enum.intervention_type.assembly",
    [InterventionType.DISASSEMBLY]: "enum.intervention_type.disassembly",
  };
}

export default InterventionType;
