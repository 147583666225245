import ImportInstallationsContent from "./../modules/installation/pages/ImportInstallations/es";
import ImportSitesContent from "./../modules/company/pages/ImportSites/es";
import ImportClientsContent from "./../modules/company/pages/ImportClients/es";
import PrivacyPolicy from "../modules/common/pages/PrivacyPolicy/es";
import CGU from "../modules/common/pages/CGU/es";

export default {
  ImportInstallationsContent,
  ImportSitesContent,
  ImportClientsContent,
  PrivacyPolicy,
  CGU,
};
