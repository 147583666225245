import {
  GET_FLUID_SUMMARIES_LIST_IN_PROGRESS,
  GET_FLUID_SUMMARIES_LIST_SUCCESS,
  GET_FLUID_SUMMARIES_LIST_FAIL,
  UPDATE_FLUID_SUMMARIES_LIST_IN_PROGRESS,
  UPDATE_FLUID_SUMMARIES_LIST_SUCCESS,
  UPDATE_FLUID_SUMMARIES_LIST_FAIL,
} from "../constants";
import { LOGOUT } from "../../authentication/constants";

const initialState = {
  list: [],
  inProgress: false,
  error: false,
};

/**
 * Fluid summaries reducer
 *
 * @param {Object} state
 * @param {Object} action
 *
 * @return {Object}
 */
export default function fluidSummariesReducer(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case GET_FLUID_SUMMARIES_LIST_IN_PROGRESS:
      return {
        ...state,
        inProgress: true,
        error: false,
      };

    case UPDATE_FLUID_SUMMARIES_LIST_IN_PROGRESS:
      return {
        ...state,
        error: false,
      };

    case GET_FLUID_SUMMARIES_LIST_SUCCESS:
    case UPDATE_FLUID_SUMMARIES_LIST_SUCCESS:
      return {
        ...state,
        list: payload.list,
        inProgress: false,
      };

    case GET_FLUID_SUMMARIES_LIST_FAIL:
    case UPDATE_FLUID_SUMMARIES_LIST_FAIL:
      return {
        ...state,
        inProgress: false,
        error: true,
      };

    case LOGOUT:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
}
