import I18n from "i18n-js";
import React, { Component } from "react";

class Forbidden extends Component {
  /**
   * {@inheritdoc}
   */
  render() {
    return (
      <div className="container not-found">
        <div className="row justify-content-center">
          <div className="col-sm-8 col-md-6 col-lg-5 col-xl-4">
            <h1 className="text-center">{I18n.t("pages.403.title")}</h1>
            <p className="text-center">{I18n.t("pages.403.text")}</p>
          </div>
        </div>
      </div>
    );
  }
}

export default Forbidden;
