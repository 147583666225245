import I18n from "i18n-js";
import React, { Component } from "react";
import { bool, func, shape } from "prop-types";
import { connect } from "react-redux";
import TextInput from "../../common/components/form/TextInput";
import Select from "../../common/components/form/Select";
import SubmitButton from "../../common/components/form/SubmitButton";
import { getLeakDetectorsList } from "../actions/leakDetectorsActions";
import ClientTypes from "../../company/models/ClientTypes";
import { alphabetically } from "../../common/utils/filterUtils";
import DateInput from "../../common/components/form/DateInput";
import ClientUtils from "../../company/utils/ClientUtils";

class CreateLeakDetectorForm extends Component {
  static propTypes = {
    getLeakDetectorsList: func.isRequired,
    clients: shape().isRequired,
    leakDetector: shape().isRequired,
    leakDetectors: shape().isRequired,
    infos: shape(),
    loading: bool,
    onSubmit: func.isRequired,
    goBack: func.isRequired,
  };

  static defaultProps = {
    infos: {},
    loading: false,
  };

  state = {
    clientError: null,
    serialNumberError: null,
    lastInspectionDateError: null,
    markError: null,
    barcodeError: null,
  };

  constructor(props) {
    super(props);

    this.updateMod = !!props.infos.uuid;
    this.lastInspectionDate =
      props.infos.lastInspectionDate &&
      new Date(props.infos.lastInspectionDate);

    this.isDetectorUnique = this.isDetectorUnique.bind(this);
    this.onChangeClient = this.onChangeClient.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    // Fields
    this.setSerialNumber = this.setSerialNumber.bind(this);
    this.setLastInspectionDate = this.setLastInspectionDate.bind(this);
    this.setDesignation = this.setDesignation.bind(this);
    this.setMark = this.setMark.bind(this);
    this.setBarcode = this.setBarcode.bind(this);
    this.setClient = this.setClient.bind(this);

    this.renderClientOption = this.renderClientOption.bind(this);
    this.renderClientsSelect = this.renderClientsSelect.bind(this);
  }

  componentDidMount() {
    this.props.getLeakDetectorsList();
  }

  /**
   * {@inheritdoc}
   */
  componentDidUpdate(prevProps) {
    const { leakDetector, goBack } = this.props;

    if (
      prevProps.leakDetector.saved === leakDetector.saved &&
      prevProps.leakDetector.errors === leakDetector.errors
    ) {
      return;
    }

    if (leakDetector.errors) {
      leakDetector.errors.map((error) =>
        this.setState({ [`${error.path}Error`]: error.reason }),
      );
    }

    if (leakDetector.saved) {
      goBack();
    }
  }

  /**
   * onChange client select
   *
   * @param {Object} event
   */
  onChangeClient(event) {
    const uuid = event.target.value;
    const { clients } = this.props;
    const selectedClient = clients.list.filter(
      (client) => client.uuid === uuid,
    )[0];

    if (!selectedClient) {
      this.setState({ isCompanyClient: false });

      return;
    }

    this.setState({
      isCompanyClient: selectedClient.type === ClientTypes.COMPANY,
    });
  }

  onSubmit() {
    if (!this.isValid()) {
      return;
    }

    const detector = {
      serialNumber: this.serialNumber.value,
      lastInspectionDate: this.lastInspectionDate.toISOString(),
      designation: this.designation.value || null,
      mark: this.mark.value || null,
      barcode: this.barcode.value || null,
    };

    if (!this.updateMod) {
      detector.clientUuid = this.client.value;
    }

    const { leakDetector } = this.props;

    if (
      !this.isDetectorUnique(
        detector,
        leakDetector.infos ? leakDetector.infos.uuid : null,
      )
    ) {
      return;
    }

    this.props.onSubmit(detector);
  }

  /**
   * @param {String} serialNumber
   */
  setSerialNumber(serialNumber) {
    this.serialNumber = serialNumber;
  }

  /**
   * @param {Date} lastInspectionDate
   */
  setLastInspectionDate(lastInspectionDate) {
    this.lastInspectionDate = lastInspectionDate;
  }

  /**
   * @param {String} designation
   */
  setDesignation(designation) {
    this.designation = designation;
  }

  /**
   * @param {String} mark
   */
  setMark(mark) {
    this.mark = mark;
  }

  /**
   * @param {String} barcode
   */
  setBarcode(barcode) {
    this.barcode = barcode;
  }

  /**
   * @param {String} client
   */
  setClient(client) {
    this.client = client;
  }

  /**
   * Validation detector unicity
   *
   * @param {Object} detector
   * @param {String} uuid
   *
   * @return {Boolean}
   */
  isDetectorUnique(detector, uuid = null) {
    const { leakDetectors } = this.props;
    const clientUuid = this.client.value;
    const others = leakDetectors.list.filter(
      (leakDetector) =>
        leakDetector.clientUuid === clientUuid && leakDetector.uuid !== uuid,
    );

    let alreadyExists = false;

    if (
      detector.mark &&
      others.some((leakDetector) => leakDetector.mark === detector.mark)
    ) {
      alreadyExists = true;

      this.setState({
        markError: I18n.t("pages.leak_detectors.add.form.mark:error:unicity"),
      });
    } else {
      this.setState({ markError: null });
    }

    if (
      detector.barcode &&
      others.some((leakDetector) => leakDetector.barcode === detector.barcode)
    ) {
      alreadyExists = true;

      this.setState({
        barcodeError: I18n.t(
          "pages.leak_detectors.add.form.barcode:error:unicity",
        ),
      });
    } else {
      this.setState({ barcodeError: null });
    }

    return !alreadyExists;
  }

  /**
   * @return {Boolean}
   */
  isValid() {
    const serialNumberLength = this.serialNumber.value.length;
    const clientLength = this.client.value.length;

    const serialNumberErrorText = I18n.t(
      "pages.leak_detectors.add.form.serial_number:error:empty",
    );
    const lastInspectionDateErrorText = I18n.t(
      "pages.leak_detectors.add.form.last_inspection_date:error:empty",
    );
    const clientErrorText = I18n.t(
      "pages.leak_detectors.add.form.client:error:empty",
    );

    this.setState({
      serialNumberError: !serialNumberLength ? serialNumberErrorText : null,
      lastInspectionDateError: !this.lastInspectionDate
        ? lastInspectionDateErrorText
        : null,
      clientError: !clientLength ? clientErrorText : null,
    });

    return serialNumberLength && this.lastInspectionDate && clientLength;
  }

  /**
   * @param {Object} option
   *
   * @return {Element}
   */
  renderClientOption(option) {
    return (
      <option key={`option-${option.uuid}`} value={option.uuid}>
        {ClientUtils.renderLabel(option)}
      </option>
    );
  }

  /**
   * Render clients select
   *
   * @return {Element}
   */
  renderClientsSelect() {
    const { clients, infos } = this.props;
    const { clientError } = this.state;
    let options = null;

    if (clients.list.length) {
      options = clients.list
        .filter((client) => new ClientTypes(client.type).canHaveDetectors())
        .sort((a, b) =>
          alphabetically(
            ClientUtils.renderLabel(a),
            ClientUtils.renderLabel(b),
          ),
        );
    }

    return (
      <Select
        id="client"
        label={I18n.t("pages.leak_detectors.add.form.client:label")}
        ref={this.setClient}
        options={options}
        renderOption={this.renderClientOption}
        disabled={this.updateMod}
        defaultValue={(this.updateMod && infos && infos.clientUuid) || ""}
        error={clientError}
        onChange={this.onChangeClient}
      />
    );
  }

  /**
   * {@inheritdoc}
   */
  render() {
    const { loading, infos } = this.props;
    const {
      serialNumberError,
      lastInspectionDateError,
      markError,
      barcodeError,
    } = this.state;

    return (
      <form>
        {this.renderClientsSelect()}
        <TextInput
          id="serialNumber"
          label={`${I18n.t(
            "pages.leak_detectors.add.form.serial_number:label",
          )}`}
          defaultValue={infos.serialNumber}
          ref={this.setSerialNumber}
          error={serialNumberError}
        />
        <DateInput
          id="lastInspectionDate"
          label={`${I18n.t(
            "pages.leak_detectors.add.form.last_inspection_date:label",
          )}`}
          defaultValue={this.lastInspectionDate}
          onChange={this.setLastInspectionDate}
          error={lastInspectionDateError}
        />
        <TextInput
          id="designation"
          label={I18n.t("pages.leak_detectors.add.form.designation:label")}
          defaultValue={infos.designation}
          ref={this.setDesignation}
          optional
        />
        <TextInput
          id="mark"
          label={I18n.t("pages.leak_detectors.add.form.mark:label")}
          defaultValue={infos.mark}
          ref={this.setMark}
          optional
          error={markError}
        />
        <TextInput
          id="barcode"
          label={I18n.t("pages.leak_detectors.add.form.barcode:label")}
          defaultValue={infos.barcode}
          ref={this.setBarcode}
          optional
          error={barcodeError}
        />
        <SubmitButton loading={loading} onClick={this.onSubmit} />
      </form>
    );
  }
}

const mapStateToProps = (state) => ({
  clients: state.clients,
  leakDetector: state.leakDetector,
  leakDetectors: state.leakDetectors,
});

const mapDispatchToProps = (dispatch) => ({
  getLeakDetectorsList: () => dispatch(getLeakDetectorsList()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateLeakDetectorForm);
