import React from "react";
import PropTypes from "prop-types";
import I18n from "i18n-js";

GenericLoadingError.propTypes = {
  message: PropTypes.string,
};
GenericLoadingError.defaultProps = {
  message: null,
};

export default function GenericLoadingError({ message }) {
  return (
    <p className="text-danger">{message || I18n.t("common.loadingApiError")}</p>
  );
}
