import React, { Component } from "react";
import { string, bool, oneOf } from "prop-types";
import classnames from "classnames";
import { Link } from "react-router-dom";

class BigButton extends Component {
  static propTypes = {
    label: string.isRequired,
    loading: bool,
    tagName: oneOf(["a", "button:submit", "button:reset", "link"]),
    to: string,
  };

  static defaultProps = {
    loading: false,
    tagName: "a",
    to: "",
  };

  state = {
    isLoading: this.props.loading,
  };

  constructor(props) {
    super(props);
  }

  /**
   * {@inheritdoc}
   */
  componentDidUpdate(prevProps) {
    const { loading } = this.props;

    if (prevProps.loading !== loading) {
      this.setState({ isLoading: loading });
    }
  }

  /**
   * Render button content
   *
   * @return {String|Element}
   */
  renderContent() {
    const { label } = this.props;
    const { isLoading } = this.state;

    if (isLoading) {
      return <div className="loader" />;
    }

    return label;
  }

  /**
   * {@inheritdoc}
   */
  render() {
    const {
      tagName,
      to,
      ...props // eslint-disable-line no-unused-vars
    } = this.props;
    const { isLoading } = this.state;
    const buttonClasses = classnames("big-button", {
      "big-button--loading": isLoading,
    });

    if (tagName === "button:submit") {
      return (
        <button type="submit" className={buttonClasses} {...props}>
          {this.renderContent()}
        </button>
      );
    }
    if (tagName === "link") {
      return (
        <Link to={to} className={buttonClasses} {...props}>
          {this.renderContent()}
        </Link>
      );
    }

    return (
      <a href={to} className={buttonClasses} {...props}>
        {this.renderContent()}
      </a>
    );
  }
}

export default BigButton;
