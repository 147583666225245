/* eslint react/no-unescaped-entities: 0 */
/* eslint react/display-name: 0 */
import React, { Component } from "react";
import ImportInstallationsContent from "./ImportInstallationsContent";

export default class extends Component {
  static propTypes = ImportInstallationsContent.propTypes;

  /**
   * {@inheritdoc}
   */
  render() {
    return (
      <div>
        <p>
          Pour réaliser l'import de vos clients, les données doivent être
          enregistrées au format TSV, et respecter la structure suivante:
        </p>

        <ul>
          <li>N° client</li>
          <li>N° site</li>
          <li>N° installation</li>
          <li>
            Code fluide <i>*1</i>
          </li>
          <li>Désignation</li>
          <li>Charge nominale</li>
          <li>Charge actuelle</li>
          <li>
            Identifiant num <i>*2</i>
          </li>
          <li>Repère</li>
          <li>Date de mise en service</li>
          <li>Date du dernier contrôle périodique</li>
          <li>
            Détecteur de fuite indirect <i>*3</i>
          </li>
        </ul>

        <p>
          <i>
            *1 : à choisir dans cette{" "}
            <a href="#" onClick={this.props.downloadFluids}>
              liste
            </a>
            <br />
            *2 : optionnel
            <br />
            *3 : 0 = pas de détecteur — 1 = présence d'un détecteur
            <br />
          </i>
        </p>

        <p>{this.props.downloadSample}</p>

        <p>
          Après l'intégration, une synthèse vous informera sur le nombre
          d'entités créées ou mises à jour, et mettra à disposition un fichier
          d'anomalies contenant les lignes non intégrées, et le(s) motif(s) du
          rejet.
        </p>

        <p>Les motifs d'anomalie possibles sont:</p>

        <ul>
          <li>Absence de données obligatoires</li>
          <li>Fluide inexistant</li>
          <li>Valeur du top différent de 1/0</li>
          <li>Date invalide</li>
        </ul>
      </div>
    );
  }
}
