import React from "react";
import PropTypes from "prop-types";
import I18n from "i18n-js";
import StatelessTable from "../../common/components/elements/StatelessTable";
import StatelessCheckbox from "../../common/components/form/StatelessCheckbox";
import { EnumChoice } from "../../common/components/form/EnumChoice";
import TreatmentMode from "../models/TreatmentMode";
import Packaging from "../models/Packaging";
import { ArticleInput } from "../propTypes/types";
import SelectWasteArticle from "./SelectWasteArticle";
import SelectWasteArticleProducer from "./SelectWasteArticleProducer";
import StatelessTextInput from "../../common/components/form/StatelessTextInput";

WasteArticlesForm.propTypes = {
  articlesData: PropTypes.arrayOf(ArticleInput).isRequired,
  onChange: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  siteUuid: PropTypes.string.isRequired,
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      articleUuid: PropTypes.arrayOf(PropTypes.string),
      producerUuid: PropTypes.arrayOf(PropTypes.string),
      quantity: PropTypes.arrayOf(PropTypes.string),
      mass: PropTypes.arrayOf(PropTypes.string),
      treatmentMode: PropTypes.arrayOf(PropTypes.string),
      toDestruct: PropTypes.arrayOf(PropTypes.string),
      toReturn: PropTypes.arrayOf(PropTypes.string),
      packaging: PropTypes.arrayOf(PropTypes.string),
    }),
  ),
};

export default function WasteArticlesForm({
  articlesData,
  onDelete,
  onAdd,
  onChange,
  siteUuid,
  errors,
}) {
  return (
    <StatelessTable
      className="waste-articles-form"
      tableClasses="table-sm table-form table-waste-articles"
      emptyContent={
        "Aucun article à reprendre" ||
        I18n.t(
          "pages.create_waste_recovery.steps.articles.no_articles_to_return",
        )
      }
      data={articlesData}
      headData={[
        /* eslint-disable react/jsx-key */
        <span>
          Article hors récupération<i>*</i>
        </span>,
        <span>
          Producteur<i>*</i>
        </span>,
        <div className="text-right">
          Nombre<i>*</i>
        </div>,
        <div className="text-right">
          Masse nette<i>*</i> <small>(kg)</small>
        </div>,
        <span>
          Traitement<i>*</i>
        </span>,
        <div className="text-center">Destruction contenant</div>,
        <div className="text-center">Renvoi contenant</div>,
        <span>Support logistique</span>,
        /* eslint-enable react/jsx-key */
        null,
      ]}
      rowData={(data, i) => {
        const onFieldChange = (fieldName) => (value) =>
          onChange(i, { ...data, [fieldName]: value });

        return [
          /* eslint-disable react/jsx-key */
          <SelectWasteArticle
            id="article"
            label={false}
            value={data.articleUuid}
            onChangedValue={onFieldChange("articleUuid")}
            error={errors[i]?.articleUuid}
          />,
          <SelectWasteArticleProducer
            id="producer"
            label={false}
            siteUuid={siteUuid}
            value={data.producerUuid}
            onChangedValue={onFieldChange("producerUuid")}
            error={errors[i]?.producerUuid}
          />,
          <StatelessTextInput
            id={`quantity-${i}`}
            label={false}
            type="number"
            step={1}
            min={1}
            defaultValue={data.quantity}
            onChangedValue={onFieldChange("quantity")}
            errors={errors[i]?.quantity}
            widgetClassName="d-flex flex-column align-items-end"
          />,
          <StatelessTextInput
            id={`mass-${i}`}
            label={false}
            type="number"
            step={0.01}
            min={0}
            defaultValue={data.mass}
            onChangedValue={onFieldChange("mass")}
            errors={errors[i]?.mass}
            widgetClassName="d-flex flex-column align-items-end"
          />,
          <EnumChoice
            id={`treatmentMode-${i}`}
            label={false}
            enumClass={TreatmentMode}
            defaultValue={data.treatmentMode}
            onChangedValue={onFieldChange("treatmentMode")}
            error={errors[i]?.treatmentMode}
          />,
          <StatelessCheckbox
            id={`toDestruct-${i}`}
            label={false}
            className="text-center"
            onChangedValue={onFieldChange("toDestruct")}
            checked={data.toDestruct}
            errors={errors[i]?.toDestruct}
          />,
          <StatelessCheckbox
            id={`toReturn-${i}`}
            label={false}
            className="text-center"
            onChangedValue={onFieldChange("toReturn")}
            checked={data.toReturn}
            errors={errors[i]?.toReturn}
          />,
          <EnumChoice
            id={`packaging-${i}`}
            label={false}
            enumClass={Packaging}
            defaultValue={data.packaging}
            onChangedValue={onFieldChange("packaging")}
            error={errors[i]?.packaging}
            optional
          />,
          // Actions
          <div className="text-center">
            <button
              type="button"
              className="action"
              onClick={() => onDelete(i)}
            >
              <i className="fa fa-trash-o" aria-hidden="true" />
            </button>
          </div>,
          /* eslint-enable react/jsx-key */
        ];
      }}
      actions={
        <button type="button" className="btn btn-primary" onClick={onAdd}>
          <i className="fa fa-plus-circle"></i> Ajouter
        </button>
      }
    />
  );
}
