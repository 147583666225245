import React from "react";
import { alphabetically, uniq } from "../../../../common/utils/filterUtils";
import I18n from "i18n-js";
import Select from "../../../../common/components/form/Select";
import { propertyFilter } from "../../../utils/filters";
import { string, array, func } from "prop-types";

SiteFilter.propTypes = {
  filteredClient: string,
  requests: array.isRequired,
  defaultValue: string,
  onChangedValue: func,
};
SiteFilter.defaultProps = {
  filteredClient: null,
  defaultValue: "",
  onChangedValue: null,
};

export default function SiteFilter({
  filteredClient,
  requests,
  defaultValue,
  onChangedValue,
}) {
  const sites = uniq(
    requests.reduce((sites, request) => {
      if (
        !propertyFilter(
          (request) => request.site.client.uuid,
          filteredClient,
        )(request)
      ) {
        // Skip if client does not match selected one
        return sites;
      }

      const { site } = request;

      return [
        ...sites,
        {
          uuid: site.uuid,
          designation: site.designation,
        },
      ];
    }, []),
    "uuid",
  ).sort((a, b) => alphabetically(a.designation, b.designation));

  return (
    <Select
      id="filterBySite"
      label={I18n.t("filters.by_site")}
      placeholder={I18n.t("filters.by_site:default")}
      onChangedValue={onChangedValue}
      renderOption={(option) => (
        <option key={option.uuid} value={option.uuid}>
          {option.designation}
        </option>
      )}
      options={sites}
      defaultValue={defaultValue}
      emptyStringAsNull
      optional
    />
  );
}
