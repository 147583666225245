import gql from "graphql-tag";

export const FRAGMENT_INTERVENTION_PLANNED_FIELDS = gql`
  fragment interventionPlannedFields on InterventionPlanned {
    uuid
    plannedAt
    type
    purpose
    user {
      uuid
      firstname
      lastname
    }
    installation {
      uuid
      site {
        uuid
        designation
        client {
          uuid
          address {
            city
          }
          legalCompanyName
        }
      }
      designation
    }
    type
    purpose
  }
`;
