import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import companyWasteRecoveryRequests from "../graphql/queries/companyWasteRecoveryRequests.graphql";
import I18n from "i18n-js";
import { formatDate } from "../../common/utils/dateUtils";
import Quantity from "../../common/components/elements/Quantity";
import DateInput from "../../common/components/form/DateInput";
import StatelessTextInput from "../../common/components/form/StatelessTextInput";
import WasteRecoveryRequestStatus from "../models/WasteRecoveryRequestStatus";
import { NumberParam, useQueryParam } from "use-query-params";
import { Link } from "react-router-dom";
import {
  ROUTE_WASTE_RECOVERY_REQUEST_STATUS,
  ROUTE_WASTE_RECOVERY_REQUEST_SUMMARY,
} from "../../../routes";
import pathToRegexp from "path-to-regexp";
import LoadingWrapper from "../../common/components/elements/LoadingWrapper";
import StatelessTable from "../../common/components/elements/StatelessTable";
import CommercialStatusFilter from "../components/wasteRequestsList/filters/CommercialStatusFilter";
import {
  combineOrFilters,
  dateFromFilter,
  greaterThanNumberFilter,
  oneOfEnums,
  propertyContainsFilter,
  propertyFilter,
} from "../utils/filters";
import ClientFilter from "../components/wasteRequestsList/filters/ClientFilter";
import SiteFilter from "../components/wasteRequestsList/filters/SiteFilter";
import { useAuth } from "../../authentication/contexts/useAuth";
import Roles from "../../users/models/Roles";

export default function Page(props) {
  return (
    <div>
      <h1>{I18n.t("pages.company_waste_recovery_requests.list.title")}</h1>
      <CompanyWasteRecoveryRequestsList {...props} />
    </div>
  );
}

CompanyWasteRecoveryRequestsList.propTypes = {};

function CompanyWasteRecoveryRequestsList() {
  const [page, setPage] = useQueryParam("page", NumberParam);
  const { hasRole } = useAuth();
  const [filters, setFilters] = useState({
    client: null,
    site: null,
    date: null,
    weight: 0,
    status: hasRole(Roles.COMMERCIAL)
      ? // Commercial initial filter "awaiting commercial action"
        {
          selectedId: CommercialStatusFilter.OPTS.NEEDS_COMMERCIAL_ACTION.id,
          statuses: CommercialStatusFilter.OPTS.NEEDS_COMMERCIAL_ACTION.value,
        }
      : // Sales initial filter "awaiting sales action"
        {
          selectedId: CommercialStatusFilter.OPTS.NEEDS_SALES_ACTION.id,
          statuses: CommercialStatusFilter.OPTS.NEEDS_SALES_ACTION.value,
        },
  });

  const { loading, error, data } = useQuery(companyWasteRecoveryRequests);

  return (
    <LoadingWrapper loading={loading} error={Boolean(error)}>
      {() => {
        const requests = data.companyWasteRecoveryRequests;
        const filteredData = filtered(requests, filters);

        return (
          <StatelessTable
            emptyContent={I18n.t("pages.my_waste_recovery_requests.list.empty")}
            data={filteredData}
            headData={getHeadData()}
            rowData={getRowData}
            filters={getFilters(requests, filters, setFilters)}
            pagination
            onPageChanged={setPage}
            currentPage={page}
            paginationAside={
              <span className="text-primary">
                {I18n.t(
                  "pages.my_waste_recovery_requests.list.pagination_aside",
                  {
                    count: filteredData.length,
                  },
                )}
              </span>
            }
          />
        );
      }}
    </LoadingWrapper>
  );
}

function getHeadData() {
  return [
    /* eslint-disable react/jsx-key */
    I18n.t("pages.my_waste_recovery_requests.head.number"),
    I18n.t("pages.my_waste_recovery_requests.head.authorReference"),
    I18n.t("pages.my_waste_recovery_requests.head.commercialReference"),
    I18n.t("pages.my_waste_recovery_requests.head.client"),
    I18n.t("pages.my_waste_recovery_requests.head.site"),
    I18n.t("pages.my_waste_recovery_requests.head.date"),
    <div className="text-right">
      {I18n.t("pages.my_waste_recovery_requests.head.totalWeight")}
    </div>,
    I18n.t("pages.my_waste_recovery_requests.head.status"),
    null,
    /* eslint-enable react/jsx-key */
  ];
}

function getRowData(request) {
  const { site, number, authorReference, commercialReference } = request;
  const { client } = site;

  return [
    /* eslint-disable react/jsx-key */
    number,
    authorReference,
    commercialReference,
    client.legalCompanyName,
    `${site.designation} - ${site.address.city}`,
    formatDate(request.transportDate || request.date),
    <Quantity value={request.totalWeight} unit="kg" />,
    I18n.t(WasteRecoveryRequestStatus.readableFor(request.status)),
    [
      <Link
        to={pathToRegexp.compile(ROUTE_WASTE_RECOVERY_REQUEST_SUMMARY)({
          uuid: request.uuid,
        })}
        className="action"
        key="details"
      >
        <i className="fa fa-eye" aria-hidden="true" />
      </Link>,
      <Link
        to={pathToRegexp.compile(ROUTE_WASTE_RECOVERY_REQUEST_STATUS)({
          uuid: request.uuid,
        })}
        className="action"
        key="status"
      >
        <i className="fa fa-pencil" aria-hidden="true" />
      </Link>,
    ],
    /* eslint-enable react/jsx-key */
  ];
}

function getFilters(requests, filters, setFilters) {
  return [
    /* eslint-disable react/jsx-key */
    <StatelessTextInput
      id="filterByNumber"
      label={I18n.t("pages.my_waste_recovery_requests.list.filters.number")}
      placeholder={I18n.t(
        "pages.my_waste_recovery_requests.list.filters.number:placeholder",
      )}
      onChangedValue={(v) => setFilters({ ...filters, number: v || null })}
      type="number"
      min={0}
      defaultValue={filters.number}
      datalist={Array.from(
        new Set([].concat(...requests.map((r) => r.number))),
      ).sort()}
      optional
    />,
    <StatelessTextInput
      id="filterByReferences"
      label={I18n.t("pages.my_waste_recovery_requests.list.filters.references")}
      placeholder={I18n.t(
        "pages.my_waste_recovery_requests.list.filters.references:placeholder",
      )}
      onChangedValue={(v) => setFilters({ ...filters, references: v || null })}
      defaultValue={filters.references}
      datalist={Array.from(
        new Set(
          []
            .concat(...requests.map((r) => r.commercialReference))
            .concat(...requests.map((r) => r.authorReference)),
        ),
      ).sort()}
      optional
    />,
    <ClientFilter
      requests={requests}
      onChangedValue={(uuid) =>
        setFilters({ ...filters, site: null, client: uuid })
      }
      defaultValue={filters.client}
    />,
    <SiteFilter
      requests={requests}
      onChangedValue={(uuid) => setFilters({ ...filters, site: uuid })}
      defaultValue={filters.site}
      filteredClient={filters.client}
    />,
    <DateInput
      id="filterByDate"
      label={I18n.t("filters.by_date")}
      onChange={(date) =>
        setFilters({ ...filters, date: date ? Date.parse(date) : null })
      }
      defaultValue={filters.date && new Date(Number(filters.date))}
      legend
      optional
      isClearable
    />,
    <StatelessTextInput
      id="filterByWeight"
      label={I18n.t("filters.by_weight")}
      placeholder={I18n.t("filters.by_weight:placeholder")}
      onChangedValue={(v) => setFilters({ ...filters, weight: v || 0 })}
      type="number"
      min={0}
      step="0.01"
      defaultValue={filters.barcode}
      optional
    />,
    <CommercialStatusFilter
      id="filterByStatus"
      label={I18n.t("filters.by_status")}
      onChangedValue={(statuses, selectedId) =>
        setFilters({
          ...filters,
          status: {
            selectedId,
            statuses,
          },
        })
      }
      selectedOptionId={filters.status.selectedId}
    />,
    /* eslint-enable react/jsx-key */
  ];
}

function filtered(
  requests,
  { client, site, date, weight, status: { statuses }, references, number },
) {
  return requests
    .filter(propertyFilter((request) => request.site.client.uuid, client))
    .filter(propertyFilter((request) => request.site.uuid, site))
    .filter(
      dateFromFilter((request) => request.transportDate || request.date, date),
    )
    .filter(greaterThanNumberFilter((request) => request.totalWeight, weight))
    .filter(oneOfEnums((request) => request.status, statuses))
    .filter(propertyFilter((request) => request.number, number))
    .filter(
      combineOrFilters(
        propertyContainsFilter(
          (request) => request.commercialReference,
          references,
        ),
        propertyContainsFilter(
          (request) => request.authorReference,
          references,
        ),
      ),
    );
}
