import { fetch } from "../../../appContainer";
import {
  GET_SHIPPINGS_LIST_IN_PROGRESS,
  GET_SHIPPINGS_LIST_SUCCESS,
  GET_SHIPPINGS_LIST_FAIL,
} from "../constants";

/**
 * Get shippings list
 */
export function getShippingsList() {
  return (dispatch) => {
    dispatch({ type: GET_SHIPPINGS_LIST_IN_PROGRESS });

    fetch("api").getShippingsList(
      (list) => {
        dispatch({ type: GET_SHIPPINGS_LIST_SUCCESS, payload: { list } });
      },
      () => dispatch({ type: GET_SHIPPINGS_LIST_FAIL }),
    );
  };
}
