/* eslint react/no-unescaped-entities: 0 */
/* eslint react/display-name: 0 */
import React, { Component } from "react";
import ImportInstallationsContent from "./ImportInstallationsContent";

export default class extends Component {
  static propTypes = ImportInstallationsContent.propTypes;

  /**
   * {@inheritdoc}
   */
  render() {
    return (
      <div>
        <p>
          Para importar sus instalaciones, los datos deben guardarse en formato
          TSV y seguir la siguiente estructura:
        </p>

        <ul>
          <li>Nº de cliente</li>
          <li>Nº de centro</li>
          <li>Nº instalación</li>
          <li>
            Código de fluido <i>*1</i>
          </li>
          <li>Designación</li>
          <li>Carga nominal</li>
          <li>Carga actual</li>
          <li>
            Número de identificación <i>*2</i>
          </li>
          <li>Marcador</li>
          <li>Fecha de puesta en servicio </li>
          <li>Fecha de la última verificación periódica</li>
          <li>
            Detector de fugas indirecto <i>*3</i>
          </li>
        </ul>

        <p>
          <i>
            *1 : a elegir de esta{" "}
            <a href="#" onClick={this.props.downloadFluids}>
              lista
            </a>
            <br />
            *2 : opcional
            <br />
            *3 : 0 = sin detector — 1 = presencia de un detector
            <br />
          </i>
        </p>

        <p>{this.props.downloadSample}</p>

        <p>
          Después de la integración, un resumen le informará sobre el número de
          entidades creadas o actualizadas, y le proporcionará un archivo de
          anomalía que contiene las líneas no integradas, y la(s) razón(es) del
          rechazo.
        </p>

        <p>Las posibles causas de las anomalías son:</p>

        <ul>
          <li>Ausencia de datos obligatorios</li>
          <li>No hay fluido disponible</li>
          <li>Valor del indicador diferente de 1/0</li>
          <li>Fecha no válida</li>
        </ul>
      </div>
    );
  }
}
