import React from "react";
import I18n from "i18n-js";
import { useQuery } from "@apollo/client";
import PropTypes from "prop-types";
import getIntervention from "../graphql/queries/getBsffInterventionsFromIntervention.graphql";
import LoadingWrapper from "../../common/components/elements/LoadingWrapper";
import BsffList from "./BsffList";

BsffInterventionInformation.propTypes = {
  interventionUuid: PropTypes.string.isRequired,
};

export default function BsffInterventionInformation({ interventionUuid }) {
  const { loading, error, data } = useQuery(getIntervention, {
    variables: {
      interventionUuid,
    },
  });

  return (
    <div>
      <h3>{I18n.t("trackdechets.name")}</h3>
      <LoadingWrapper loading={loading} error={Boolean(error)}>
        {() => {
          const bsffIntervention = data.getBsffInterventionFromIntervention;

          return (
            <div>
              <dl className="row">
                <dt className="col-sm-6">
                  {I18n.t("trackdechets.synchronised")}
                </dt>
                <dd className="col-sm-6">
                  {bsffIntervention
                    ? I18n.t("common.yes")
                    : I18n.t("common.no")}
                </dd>
              </dl>
              <BsffList
                bsffs={bsffIntervention ? bsffIntervention.bsffs : []}
              />
            </div>
          );
        }}
      </LoadingWrapper>
    </div>
  );
}
