// interventions
export const GET_INTERVENTIONS_LIST_IN_PROGRESS =
  "GET_INTERVENTIONS_LIST_IN_PROGRESS";
export const GET_INTERVENTIONS_LIST_SUCCESS = "GET_INTERVENTIONS_LIST_SUCCESS";
export const GET_INTERVENTIONS_LIST_FAIL = "GET_INTERVENTIONS_LIST_FAIL";
export const GET_INTERVENTION_INFO_IN_PROGRESS =
  "GET_INTERVENTION_INFO_IN_PROGRESS";
export const GET_INTERVENTION_INFO_SUCCESS = "GET_INTERVENTION_INFO_SUCCESS";
export const GET_INTERVENTION_INFO_FAIL = "GET_INTERVENTION_INFO_FAIL";

// fluid summaries
export const GET_FLUID_SUMMARIES_LIST_IN_PROGRESS =
  "GET_FLUID_SUMMARIES_LIST_IN_PROGRESS";
export const GET_FLUID_SUMMARIES_LIST_SUCCESS =
  "GET_FLUID_SUMMARIES_LIST_SUCCESS";
export const GET_FLUID_SUMMARIES_LIST_FAIL = "GET_FLUID_SUMMARIES_LIST_FAIL";
export const UPDATE_FLUID_SUMMARIES_LIST_IN_PROGRESS =
  "UPDATE_FLUID_SUMMARIES_LIST_IN_PROGRESS";
export const UPDATE_FLUID_SUMMARIES_LIST_SUCCESS =
  "UPDATE_FLUID_SUMMARIES_LIST_SUCCESS";
export const UPDATE_FLUID_SUMMARIES_LIST_FAIL =
  "UPDATE_FLUID_SUMMARIES_LIST_FAIL";
