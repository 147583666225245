import I18n from "i18n-js";
import React from "react";
import classnames from "classnames";
import { bool, string } from "prop-types";
import Spinner from "../Spinner";

RawSubmit.propTypes = {
  label: string,
  children: string,
  loading: bool,
  btnClasses: string,
  className: string,
};

RawSubmit.defaultProps = {
  label: null,
  children: null,
  loading: false,
  btnClasses: "btn btn-success",
  className: null,
};

/**
 * Raw submit button, with loading state,
 * without overriding default behavior with custom function.
 * Instead, rely on <form onSubmit={() => {}>
 */
export default function RawSubmit({
  label,
  children,
  loading,
  btnClasses,
  className,
  ...props
}) {
  return (
    <button
      type="submit"
      disabled={loading}
      className={classnames(btnClasses, className, {
        "btn--loading": loading,
      })}
      {...props}
    >
      {loading ? <Spinner /> : children || label || I18n.t("common.submit")}
    </button>
  );
}
