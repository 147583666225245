export const GET_LEAK_DETECTORS_LIST_IN_PROGRESS =
  "GET_LEAK_DETECTORS_LIST_IN_PROGRESS";
export const GET_LEAK_DETECTORS_LIST_SUCCESS =
  "GET_LEAK_DETECTORS_LIST_SUCCESS";
export const GET_LEAK_DETECTORS_LIST_FAIL = "GET_LEAK_DETECTORS_LIST_FAIL";
export const GET_LEAK_DETECTOR_INFOS_IN_PROGRESS =
  "GET_LEAK_DETECTOR_INFOS_IN_PROGRESS";
export const GET_LEAK_DETECTOR_INFOS_SUCCESS =
  "GET_LEAK_DETECTOR_INFOS_SUCCESS";
export const GET_LEAK_DETECTOR_INFOS_FAIL = "GET_LEAK_DETECTOR_INFOS_FAIL";
export const POST_LEAK_DETECTOR_IN_PROGRESS = "POST_LEAK_DETECTOR_IN_PROGRESS";
export const POST_LEAK_DETECTOR_SUCCESS = "POST_LEAK_DETECTOR_SUCCESS";
export const POST_LEAK_DETECTOR_FAIL = "POST_LEAK_DETECTOR_FAIL";
export const PUT_LEAK_DETECTOR_IN_PROGRESS = "PUT_LEAK_DETECTOR_IN_PROGRESS";
export const PUT_LEAK_DETECTOR_SUCCESS = "PUT_LEAK_DETECTOR_SUCCESS";
export const PUT_LEAK_DETECTOR_FAIL = "PUT_LEAK_DETECTOR_FAIL";
export const DELETE_LEAK_DETECTOR_IN_PROGRESS =
  "DELETE_LEAK_DETECTOR_IN_PROGRESS";
export const DELETE_LEAK_DETECTOR_SUCCESS = "DELETE_LEAK_DETECTOR_SUCCESS";
export const DELETE_LEAK_DETECTOR_FAIL = "DELETE_LEAK_DETECTOR_FAIL";
