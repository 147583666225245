import {
  GET_ENTERPRISE_LOGO_IN_PROGRESS,
  GET_ENTERPRISE_LOGO_SUCCESS,
  GET_ENTERPRISE_LOGO_FAIL,
  UPDATE_ENTERPRISE_IN_PROGRESS,
  UPDATE_ENTERPRISE_SUCCESS,
  UPDATE_ENTERPRISE_FAIL,
} from "../constants";

const initialState = {
  logo: undefined,
  saving: undefined,
  saved: undefined,
  errors: undefined,
};

/**
 * Company reducer
 *
 * @param {Object} state
 * @param {Object} action
 *
 * @return {Object}
 */
export default function enterprise(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case GET_ENTERPRISE_LOGO_IN_PROGRESS:
      return {
        ...state,
        logo: undefined,
      };

    case GET_ENTERPRISE_LOGO_SUCCESS:
      return {
        ...state,
        logo: payload.logo,
      };

    case GET_ENTERPRISE_LOGO_FAIL:
      return {
        ...state,
        logo: null,
      };

    case UPDATE_ENTERPRISE_IN_PROGRESS:
      return {
        ...state,
        saving: true,
        saved: false,
        errors: undefined,
      };

    case UPDATE_ENTERPRISE_SUCCESS:
      return {
        ...state,
        saving: false,
        saved: true,
        errors: undefined,
      };

    case UPDATE_ENTERPRISE_FAIL:
      return {
        ...state,
        saving: false,
        saved: false,
        errors: payload.violations,
      };

    default:
      return state;
  }
}
