import { fetch } from "../../../appContainer";
import {
  GET_ENTERPRISE_LOGO_FAIL,
  GET_ENTERPRISE_LOGO_IN_PROGRESS,
  GET_ENTERPRISE_LOGO_SUCCESS,
  UPDATE_ENTERPRISE_FAIL,
  UPDATE_ENTERPRISE_IN_PROGRESS,
  UPDATE_ENTERPRISE_SUCCESS,
} from "../constants";

/**
 * Update DO and CF logo
 */
export function updateEnterprise(uuid, logo) {
  return (dispatch) => {
    dispatch({ type: UPDATE_ENTERPRISE_IN_PROGRESS });

    fetch("api").updateEnterprise(
      uuid,
      logo,
      () => {
        dispatch({ type: UPDATE_ENTERPRISE_SUCCESS });
      },
      (error) => {
        dispatch({
          type: UPDATE_ENTERPRISE_FAIL,
          payload: { violations: error.violations },
        });
      },
    );
  };
}

/**
 * Get enterprise logo
 */
export function getEnterpriseLogo(uuid) {
  return (dispatch) => {
    dispatch({ type: GET_ENTERPRISE_LOGO_IN_PROGRESS });

    fetch("api").getEnterpriseLogo(
      uuid,
      (data) => {
        dispatch({
          type: GET_ENTERPRISE_LOGO_SUCCESS,
          payload: { logo: data },
        });
      },
      () => {
        dispatch({ type: GET_ENTERPRISE_LOGO_FAIL });
      },
    );
  };
}
