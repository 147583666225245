import I18n from "i18n-js";
import fr from "./fr.json";
import en from "./en.json";
import es from "./es.json";
import nl from "./nl.json";
import de from "./de.json";
import frComponents from "./frComponents";
import enComponents from "./enComponents";
import esComponents from "./esComponents";
import nlComponents from "./nlComponents";
import deComponents from "./deComponents";
import { fetch } from "../appContainer";
import { CHANGE_LANGUAGE } from "../modules/authentication/constants";

/**
 * @param {String} locale
 */
export function storeLocale(locale) {
  localStorage.setItem("language", locale);
}

/**
 * @param {String} defaultLocale
 */
export function initTranslations(defaultLocale = "en") {
  const locale = (
    localStorage.getItem("language") ||
    navigator.language ||
    navigator.userLanguage ||
    defaultLocale
  ).slice(0, 2);

  I18n.fallbacks = true;
  I18n.translations = {
    en: { ...en, Components: enComponents },
    fr: { ...fr, Components: frComponents },
    es: { ...es, Components: esComponents },
    nl: { ...nl, Components: nlComponents },
    de: { ...de, Components: deComponents },
  };
  I18n.defaultLocale = defaultLocale;
  I18n.locale = locale;

  fetch("redux_store").dispatch({
    type: CHANGE_LANGUAGE,
    payload: { lang: locale },
  });
}

/**
 * @param {String} locale
 */
export function updateTranslation(locale = "en") {
  I18n.locale = locale;

  storeLocale(locale);
}

export function trans(...args) {
  return I18n.t(...args);
}

export function localize(...args) {
  return I18n.l(...args);
}
