import React from "react";
import PropTypes from "prop-types";

SoftDeletableRequest.propTypes = {
  deleted: PropTypes.bool,
  children: PropTypes.node.isRequired,
};
SoftDeletableRequest.defaultProps = {
  deleted: false,
};

export default function SoftDeletableRequest({ deleted, children }) {
  if (deleted === true) {
    return (
      <div className="container mt-5 mb-5">
        <div className="alert alert-dark">
          <p className="text-center lead font-weight-bold p-3 m-0">
            Cette demande a été supprimée
          </p>
        </div>
      </div>
    );
  }

  return children;
}
