import I18n from "i18n-js";
import React from "react";
import Choice from "../../../../common/components/form/Choice";
import WasteRecoveryRequestStatus from "../../../models/WasteRecoveryRequestStatus";

const inheritedPropTypes = { ...Choice.propTypes };
// set by this type:
delete inheritedPropTypes.options;
delete inheritedPropTypes.renderOption;

CommercialStatusFilter.propTypes = {
  ...inheritedPropTypes,
};

const inheritedDefaults = { ...Choice.defaultProps };
// set by this type:
delete inheritedDefaults.options;
delete inheritedDefaults.renderOption;
delete inheritedDefaults.placeholder;
delete inheritedDefaults.optional;
CommercialStatusFilter.defaultProps = {
  ...inheritedDefaults,
};

CommercialStatusFilter.OPTS = {
  NEEDS_COMMERCIAL_ACTION: {
    id: "needs_commercial_action",
    label: "Action commerciale requise",
    value: WasteRecoveryRequestStatus.needsCommercialAction(),
    optGroup: "En attente",
  },
  NEEDS_SALES_ACTION: {
    id: "needs_sales_action",
    label: "Action logistique requise",
    value: WasteRecoveryRequestStatus.needsSalesAction(),
    optGroup: "En attente",
  },
  NEEDS_AUTHOR_ACTION: {
    id: "needs_author_action",
    label: "Action cliente requise",
    value: WasteRecoveryRequestStatus.needsAuthorAction(),
    optGroup: "En attente",
  },
  NEEDS_TRANSPORT_ACTION: {
    id: "needs_transport_action",
    label: "En attente de reprise",
    value: WasteRecoveryRequestStatus.needsTransportAction(),
    optGroup: "En attente",
  },
};

export default function CommercialStatusFilter(props) {
  return (
    <Choice
      options={[
        ...Object.values(CommercialStatusFilter.OPTS),
        ...WasteRecoveryRequestStatus.instances.map((instance) => ({
          id: instance.value,
          label: I18n.t(instance.getReadable()),
          value: instance,
          optGroup: "Status",
        })),
      ]}
      renderOption={({ label, id }) => (
        <option key={id} value={id}>
          {label}
        </option>
      )}
      placeholder={I18n.t("filters.by_status:placeholder")}
      optional
      {...props}
    />
  );
}
