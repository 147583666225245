import React, { createContext, useContext } from "react";
import { connect } from "react-redux";
import Roles from "../../users/models/Roles";

const authContext = createContext({
  logged: false,
  user: null,
  hasRole: () => false,
});

function getAuth(logged, userProfile) {
  if (!logged) {
    return { logged };
  }

  return {
    logged: logged,
    user: {
      uuid: userProfile?.uuid,
      roles: userProfile?.roles,
      companyCountry: userProfile?.companyCountry,
    },
    hasRole: (role) => Roles.userHasRole(userProfile?.roles, role),
  };
}

export const ProvideAuth = connect((state) => ({
  userProfile: state.auth.userProfile,
  logged: state.auth.logged,
}))(({ userProfile, logged, children }) => (
  <authContext.Provider value={getAuth(logged, userProfile)}>
    {children}
  </authContext.Provider>
));

export const useAuth = () => {
  return useContext(authContext);
};
