import I18n from "i18n-js";
import { arrayOf, func, shape } from "prop-types";
import React from "react";
import Table from "../../common/components/elements/Table";
import DateInput from "../../common/components/form/DateInput";
import Select from "../../common/components/form/Select";
import TextInput from "../../common/components/form/TextInput";
import List from "../../common/components/layout/List";
import { alphabetically, uniq } from "../../common/utils/filterUtils";
import ClientUtils from "../../company/utils/ClientUtils";
import Roles from "../../users/models/Roles";
import UserUtils from "../../users/utils/UserUtils";
import InterventionType from "../models/InterventionType";

export default class InterventionList extends List {
  static propTypes = {
    auth: shape().isRequired,
    interventions: shape().isRequired,
    interventionsList: arrayOf(shape).isRequired,
    users: shape().isRequired,
    clientsList: arrayOf(shape).isRequired,
    getClientsList: func.isRequired,
    userProfile: shape().isRequired,
    getInterventionList: func.isRequired,
    getUsersList: func.isRequired,
    location: shape().isRequired,
  };

  constructor(props) {
    super(props, {
      deletedInterventions: [],
    });

    this.shouldRenderTechFilter = props.userProfile.roles.includes(
      Roles.MANAGER,
    );

    this.refreshDataState = this.refreshDataState.bind(this);
    this.dataTableRow = this.dataTableRow.bind(this);
    this.removeIntervention = this.removeIntervention.bind(this);
  }

  /**
   * {@inheritdoc}
   */
  componentDidMount() {
    super.componentDidMount();

    const { getInterventionList, getUsersList, getClientsList } = this.props;

    getInterventionList();
    getClientsList();

    if (this.shouldRenderTechFilter) {
      getUsersList();
    }
  }

  /**
   * @param {Object} prevState
   */
  refreshDataState(prevState) {
    const { client, site } = this.state.filters;

    if (!client || prevState.filters.client !== client) {
      this.setState({
        filters: {
          ...this.state.filters,
          site: null,
          installation: null,
        },
      });
    }

    if (!site || prevState.filters.site !== site) {
      this.setState({
        filters: {
          ...this.state.filters,
          installation: null,
        },
      });
    }
  }

  getSitesFromInterventions() {
    const sites = {};

    this.props.interventionsList
      .filter(this.getFilterBySelect("client"))
      .forEach((intervention) => {
        sites[intervention.site.uuid] = intervention.site;
      });

    return Object.values(sites).sort((a, b) => {
      return alphabetically(a.designation, b.designation);
    });
  }

  getInstallationsFromInterventions() {
    const installations = {};

    this.props.interventionsList
      .filter(this.getFilterBySelect("client"))
      .filter(this.getFilterBySelect("site"))
      .forEach((intervention) => {
        installations[intervention.installation.uuid] =
          intervention.installation;
      });

    return Object.values(installations).sort((a, b) =>
      alphabetically(a.designation, b.designation),
    );
  }

  getServiceProvidersFromInterventions() {
    const serviceProviders = {};

    this.props.interventionsList
      .filter(this.getFilterBySelect("client"))
      .filter(this.getFilterBySelect("site"))
      .filter(this.getFilterBySelect("installation"))
      .forEach((intervention) => {
        const provider = intervention.serviceProvider;

        if (
          provider &&
          this.props.auth.userProfile.clientUuid !== provider.uuid
        ) {
          serviceProviders[provider.uuid] = provider;
        }
      });

    return Object.values(serviceProviders).sort((a, b) =>
      alphabetically(ClientUtils.renderLabel(a), ClientUtils.renderLabel(b)),
    );
  }

  getInstallationLabel(installation) {
    return `${installation.mark || ""} (${installation.designation})`.trim();
  }

  renderTechnician(userUuid) {
    if (this.shouldRenderTechFilter) {
      const technician = this.props.users.list.find(
        (user) => user.uuid === userUuid,
      );
      return technician ? (
        `${technician.firstname} ${technician.lastname}`
      ) : (
        <span className="text-muted" key="external-operator">
          {I18n.t("pages.interventions.list.external_operator")}
        </span>
      );
    }
    return null;
  }

  renderActions() {
    throw new Error("You must implement method renderActions");
  }

  removeIntervention(interventionUuid) {
    this.setState({
      deletedInterventions: [
        ...this.state.deletedInterventions,
        interventionUuid,
      ],
    });
  }

  /**
   * @param {Object} option
   *
   * @return {Element}
   */
  renderFilterUserOption(option) {
    return (
      <option key={`option-${option.uuid}`} value={option.uuid}>
        {UserUtils.renderLabel(option)}
      </option>
    );
  }

  /**
   * {@inheritdoc}
   */
  renderFilters() {
    const { interventionsList, users, clientsList } = this.props;
    const { filters } = this.state;

    const allFilters = [
      <Select
        key="filterByClient"
        id="filterByClient"
        onChange={(event) =>
          this.onChangeFilter(event, "client", this.refreshDataState)
        }
        label={I18n.t("filters.by_client")}
        renderOption={(option) => (
          <option key={option.uuid} value={option.uuid}>
            {ClientUtils.renderLabel(option)}
          </option>
        )}
        options={clientsList}
        defaultValue={filters.client}
        placeholder={I18n.t("filters.by_client:default")}
        optional
      />,
      <Select
        key="filterBySite"
        id="filterBySite"
        onChange={(event) =>
          this.onChangeFilter(event, "site", this.refreshDataState)
        }
        label={I18n.t("filters.by_site")}
        renderOption={(option) => (
          <option key={option.uuid} value={option.uuid}>
            {option.designation}
          </option>
        )}
        options={this.getSitesFromInterventions()}
        defaultValue={filters.site}
        placeholder={I18n.t("filters.by_site:default")}
        optional
      />,
      <Select
        key="filterByInstallation"
        id="filterByInstallation"
        onChange={(event) => this.onChangeFilter(event, "installation")}
        label={I18n.t("filters.by_installation")}
        renderOption={(option) => (
          <option key={option.uuid} value={option.uuid}>
            {this.getInstallationLabel(option)}
          </option>
        )}
        options={this.getInstallationsFromInterventions()}
        defaultValue={filters.installation}
        placeholder={I18n.t("filters.by_installation:default")}
        optional
      />,
      <Select
        key="filterByServiceProvider"
        id="filterByServiceProvider"
        onChange={(event) => this.onChangeFilter(event, "serviceProvider")}
        label={I18n.t("filters.by_service_provider")}
        renderOption={(option) => (
          <option key={option.uuid} value={option.uuid}>
            {option.legalCompanyName}
          </option>
        )}
        options={this.getServiceProvidersFromInterventions()}
        defaultValue={filters.serviceProvider}
        placeholder={I18n.t("filters.by_service_provider:default")}
        optional
      />,
      <Select
        key="filterByInterventionType"
        id="filterByInterventionType"
        onChange={(event) => this.onChangeFilter(event, "type")}
        label={I18n.t("filters.by_intervention_type")}
        renderOption={(option) => (
          <option key={option.type} value={option.type}>
            {I18n.t(InterventionType.readableFor(option.type))}
          </option>
        )}
        options={uniq(interventionsList, "type")}
        defaultValue={filters.type}
        placeholder={I18n.t("filters.by_intervention_type:default")}
        optional
      />,
      <DateInput
        key="filterByStartDate"
        id="filterByStartDate"
        onChange={(date) => {
          this.onChangeDateFilter(date, "startDate");
        }}
        label={I18n.t("filters.by_start_date")}
        defaultValue={filters.startDate && new Date(Number(filters.startDate))}
        legend
        optional
      />,
      <DateInput
        key="filterByEndDate"
        id="filterByEndDate"
        onChange={(date) => this.onChangeDateFilter(date, "endDate")}
        label={I18n.t("filters.by_end_date")}
        defaultValue={filters.endDate && new Date(Number(filters.endDate))}
        legend
        optional
      />,
      <TextInput
        key="filterByObservation"
        id="filterByObservation"
        onChange={(event) => this.onChangeFilter(event, "observation")}
        label={I18n.t("filters.by_observation")}
        defaultValue={filters.observation}
        optional
      />,
    ];

    if (this.shouldRenderTechFilter) {
      // Select user options
      const userOptions = uniq(interventionsList, "userUuid")
        .map((intervention) =>
          users.list.find((user) => user.uuid === intervention.userUuid),
        )
        .filter((a) => a)
        .sort((a, b) => alphabetically(a.lastname, b.lastname));

      allFilters.push(
        <Select
          key="filterByUser"
          id="filterByUser"
          defaultValue={filters.user}
          onChange={(event) => this.onChangeFilter(event, "user")}
          label={I18n.t("filters.by_user")}
          renderOption={this.renderFilterUserOption}
          options={userOptions}
          placeholder={I18n.t("filters.by_user:default")}
          optional
        />,
      );
    }

    return allFilters;
  }

  getFilteredList() {
    return this.props.interventionsList
      .filter(
        (intervention) =>
          !this.state.deletedInterventions.includes(intervention.uuid),
      )
      .filter(this.getFilterBySelect("client"))
      .filter(this.getFilterBySelect("site"))
      .filter(this.getFilterBySelect("installation"))
      .filter(this.getFilterBySelect("serviceProvider"))
      .filter(this.getFilterByText("observation"))
      .filter(this.getFilterBySelect("type", false))
      .filter(this.getFilterByDate("creation"))
      .filter(this.getFilterBySelect("user"));
  }

  /**
   * {@inheritdoc}
   */
  renderContent() {
    const { interventions, users } = this.props;

    if (
      interventions.inProgress ||
      (this.shouldRenderTechFilter && users.inProgress)
    ) {
      return <div className="loader" />;
    }

    if (interventions.error || (this.shouldRenderTechFilter && users.error)) {
      return <p>{I18n.t("common.loadingApiError")}</p>;
    }

    return (
      <Table
        emptyContent={I18n.t("pages.interventions.list.empty")}
        data={this.getFilteredList()}
        headData={this.dataTableHead()}
        rowData={this.dataTableRow}
        filters={this.renderFilters()}
        pagination
        onPageChanged={this.onPageChanged}
        initialPage={this.getCurrentPage()}
        actions={this.renderActions()}
      />
    );
  }

  /**
   * {@inheritdoc}
   */
  renderTitle() {
    return I18n.t("pages.interventions.list.title");
  }
}
