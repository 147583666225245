/* eslint react/no-unescaped-entities: 0 */
import React, { Component } from "react";

export default class CGU extends Component {
  /**
   * {@inheritdoc}
   */
  render() {
    return (
      <div className="container">
        <h1>Conditions générales d’utilisation CLIM’app</h1>
        <p>
          Clim'app est régie par les présentes Conditions Générales
          d’Utilisation (CGU). Sauf convention écrite particulière, toute
          commande et/ou utilisation des Service Clim'app par le client (Client)
          emporte de plein droit de la part du Client son adhésion aux présentes
          conditions générales d’utilisation. Le Client s’engage à les respecter
          et à les faire respecter par ses préposés et/ou partenaires lors de
          toute création de Compte, tout accès à Clim'app, et de manière
          générale, toute utilisation de Clim'app.
        </p>
        <h2>1. Description des services fournis via l’Application</h2>
        <p>
          CLIMALIFE a développé Clim'app, accessible à la fois via un site
          internet et via une application mobile qui accompagne les
          professionnels du froid au quotidien.
        </p>
        <p>Clim'app est une application mobile qui permet :</p>
        <ul>
          <li>La gestion et la déclaration des interventions</li>
          <li>La gestion des installations et contenants</li>
          <li>
            La production de documents réglementaires (FI BSD &amp; Annexe 1)
          </li>
        </ul>
        <p>Clim'app est aussi une application web qui offre :</p>
        <ul>
          <li>Une visibilité sur le stock de fluides frigorigènes</li>
          <li>
            La possibilité d’administrer l’environnement du professionnel du
            froid (sites, installations, véhicules, détecteurs…)
          </li>
          <li>Des tableaux de bord synthétisant l’essentiel de l’activité</li>
        </ul>
        <p>
          Les Services de Clim'app peuvent évoluer à tout moment pour tenir
          compte notamment de l’évolution des technologies et/ou de la
          législation applicable. CLIMALIFE fournit ses meilleurs efforts pour
          informer l’utilisateur de tout changement important des Services.
        </p>
        <h2>2. Engagements du Client</h2>
        <p>
          Dans le cadre l’Utilisation des Services, le Client agit en tant que
          professionnel aux fins de son activité commerciale, industrielle,
          artisanale ou libérale, et à l’exclusion de toutes fins personnelles.
        </p>
        <p>
          Il s’engage à exercer via le Service une activité licite et de
          disposer de toutes les autorisations nécessaires que ce soit de la
          part de ses partenaires ou en cas d’exercice d’une activité
          réglementée et/ou de se conformer aux dispositions règlementaires
          applicables à son activité ;
        </p>
        <p>
          Le Client dispose de la possibilité de permettre à ses Préposés et/ou
          sous-traitants (Utilisateur) l’accès pour son compte au service
          Clim'app. A cet égard, le Client fera du respect de ses Préposés et/ou
          sous-traitants quant à l’acceptation des présentes CGU et de la Charte
          de vie privée jointe. De façon générale, le Client sera responsable
          vis-à-vis de CLIMALIFE du respect des conditions générales
          d’utilisation par ses Préposés et partenaires.
        </p>
        <h2>3. Accès aux Services Clim'app</h2>
        <p>
          Le Client a conscience que le Service est proposé online et que tout
          accès au Service nécessite pour le Client et/ou ses Utilisateurs de
          disposer d’un abonnement Internet et de matériel informatique
          permettant de se connecter au Service. Le Client reconnaît que le coût
          de la connexion et l’acquisition du matériel informatique est à sa
          charge et à celle des Utilisateurs ;
        </p>
        <h3>Le téléchargement de l’application Clim'app</h3>
        <p>
          L’accès aux Services nécessite d’avoir accepté l’offre Clim'app de
          CLIMALIFE puis d’avoir préalablement téléchargé l’application mobile
          Clim'app, pour laquelle les fonctionnalités et les conditions
          d’interopérabilité sont indiquées en amont du téléchargement, dans la
          boutique d’applications, telle que l’App Store ou Google Play.
        </p>
        <p>
          De nouvelles versions de l’application sont régulièrement mises à
          disposition à travers la boutique d’applications, telle que l’App
          Store ou Google Play. En téléchargeant ces mises à jour, l’Utilisateur
          accepte les modifications apportées à l’application ou aux Services et
          qui résultent de celles-ci. L’utilisateur s’engage à télécharger
          régulièrement les mises à jour et reconnait qu’aucune responsabilité
          ne peut être imputée à CLIMALIFE si l’utilisateur utilise une version
          antérieure à celle mise à jour.
        </p>
        <h3>La création de Compte</h3>
        <p>
          Les Services sont accessibles dès que l’Utilisateur a créé son Compte
          conformément aux modalités indiquées dans le présent article.
        </p>
        <h3>La création de Compte</h3>
        <p>
          La création d’un Compte sur Clim'app suit différentes étapes : chaque
          étape est successivement franchie par le « clic » d’une icône de
          confirmation. Au cours des différentes étapes de création du compte,
          l’Utilisateur pourra avoir à renseigner des informations à caractère
          personnel (tels que vos nom, prénom, etc.) dont le traitement est régi
          par la Charte Vie privée.
        </p>
        <p>
          Lorsqu'un Utilisateur clique sur une icône dont le libellé ou la
          présentation engendre un engagement que tout internaute moyen
          comprendrait, l’Utilisateur est lié par son clic.
        </p>
        <h3>Les vérifications préalables à la création du Compte</h3>
        <p>
          CLIMALIFE se réserve de ne pas valider la demande de création d’un
          Compte notamment dans les hypothèses suivantes :
        </p>
        <ul>
          <li>En cas d’informations fournies non valide ;</li>
          <li>
            Si l’offre de CLIMALIFE n’a pas été acceptée formellement par le
            Client.
          </li>
        </ul>
        <p>
          Dans une telle hypothèse, la demande de création d’un Compte n’est pas
          validée et aucune obligation ne pourra en découler.
        </p>
        <h3>La validité et la preuve de la création du Compte</h3>
        <p>
          Nonobstant les autres preuves écrites ou toutes preuves conservées sur
          un autre support durable, il est convenu que les registres
          informatisés, conservés dans les systèmes informatisés de CLIMALIFE ou
          ceux de ses sous-traitants, pourront valoir comme preuve des
          communications du contenu de la création de Votre Compte et de sa
          date.
        </p>
        <h3>Assistance</h3>
        <p>
          Une prestation de support par voie électronique permettant de traiter
          les anomalies est disponible. Les signalements d’anomalie doivent être
          transmis par email à l’adresse suivante : support@Clim’app.dehon.com
          sans délai. CLIMALIFE procède au diagnostic de l’anomalie et met
          ensuite en œuvre sa correction.
        </p>
        <h2>4. Vos obligations dans l’utilisation de Nos Services</h2>
        <h3>4.1 La sécurité du Compte</h3>
        <p>
          L’accès au Compte est réservé au Client après identification à l’aide
          de l’identifiant et du mot de passe que chaque Utilisateur détermine
          librement lors de la création de son Compte.
        </p>
        <p>
          L’Utilisateur reconnait que son mot de passe est strictement
          confidentiel et personnel. L’utilisation de son identifiant et de son
          mot de passe à travers l’internet se fait à ses risques et périls. Il
          lui appartient de prendre toutes les dispositions nécessaires
          permettant de les protéger contre toute atteinte. En ce sens,
          CLIMALIFE ne peut être tenu responsable de quelconque accès frauduleux
          au Compte de l’Utilisateur ou de tout changement effectué à ce Compte.
          Dans une telle hypothèse, le Client s’engage à informer CLIMALIFE dans
          les plus brefs délais.
        </p>
        <p>
          Le Client reste seul garant des informations stockées dans son Compte.
          Il peut à tout instant en ajouter, les modifier ou les retirer.
        </p>
        <h3>
          4.2 En accédant, visitant ou utilisant Clim'app via ses Utilisateurs,
          Le Client garantit et certifie qu’il est responsable de toute
          utilisation qui est faite de Clim'app par lui-même, ou par toute
          personne utilisant ses identifiants.
        </h3>
        <h3>
          4.3 L’Interdiction d’utilisation illégale ou illicite de Clim'app
        </h3>
        <p>
          L’utilisateur est informé que l’ensemble des lois et règlements en
          vigueur est applicable sur internet et certifie qu’il n’utilise pas
          Clim'app à des fins illégales ou interdites par la loi ou par les
          présentes Conditions Générales d’Utilisation.
        </p>
        <h2>5. Prix</h2>
        <p>
          Les conditions tarifaires sont convenues dans l’offre de prix remise
          par CLIMALIFE et acceptées par le Client.
        </p>
        <p>
          Les prix s’entendent hors taxes. Le règlement des factures est
          effectué au comptant pour la première commande de Produit du Client,
          puis à 30 jours fin de mois à compter de la date de la facture, sans
          escompte pour paiement anticipé. CLIMALIFE se réserve le droit de
          revoir les délais de paiement convenus dans le cas où le Client ne
          serait plus couvert de façon satisfaisante par l’assurance-crédit de
          CLIMALIFE. Toute réclamation devra être formulée par écrit avant la
          date d’échéance de la dite facture. Par ailleurs, la survenance d’un
          litige ne dispense pas le Client d’acquitter la facture dans son
          intégralité.
        </p>
        <p>
          Sans préjudice de toute autre action de MATELEX, pour toutes sommes
          non payées à l’échéance, le Client s’expose au paiement (a) d’intérêts
          de retard égaux à 1,5% par mois des sommes dues à compter de la date
          d’exigibilité de la facture ; (b) de l'indemnité forfaitaire pour
          frais de recouvrement de 40 €. CLIMALIFE se réserve également la
          possibilité de facturer au Client tous frais complémentaires entraînés
          par le recouvrement de sa créance (notamment acte extra judiciaire,
          frais et honoraires de recouvrement, intervention extérieure, etc.)
          ainsi que tous dommages subis du fait du retard de paiement. Dès le
          premier retard, CLIMALIFE se réserve également le droit d’annuler ou
          de suspendre toute commande en cours et de réduire le délai de
          paiement. De même, tout retard sur une facture pourra entraîner la
          déchéance du terme de toute autre facture alors non échue, celle-ci
          devenant immédiatement exigible.
        </p>
        <h2>6. Durée</h2>
        <p>
          La durée d’utilisation du service est convenue dans les conditions
          particulières acceptées par le Client. A la fin de cette durée quelle
          qu’en soit la cause, le Client cesse immédiatement toute utilisation
          du Service
        </p>
        <p>
          Dans le cas où le Client ne respecterait les dispositions du Contrat,
          CLIMALIFE se réserve le droit de résilier le Contrat sans préavis.
        </p>
        <p>
          Le Client disposera d’un délai de 3 mois pour accéder aux documents
          générés par l’Application et les récupérer. Passé ce délai, le Client
          disposera d’un délai de cinq (5) année à compter de la date de
          résiliation du Contrat pour demander à CLIMALIFE de lui restituer les
          Données Client. Ce service de portabilité des Données Client pourra
          lui être facturé.
        </p>
        <h2>7. Confidentialité / Données</h2>
        <p>
          CLIMALIFE ne peut en aucun cas être tenu responsable du contenu et du
          contrôle des Données Client et/ou de toutes informations transitant ou
          traitées dans le cadre du Service dont le Client reste le garant.
        </p>
        <p>
          CLIMALIFE s’engage à en assurer la confidentialité et la sécurité des
          Données à l’égard des tiers et dans la limite des conditions
          contractuelles liant CLIMALIFE au prestataire propriétaire du centre
          serveur. Toutefois, CLIMALIFE pourra utiliser les Données Client dans
          le cadre de l’exercice de ses activités. De convention expresse, il
          est convenu entre les Parties que l’exploitation des Données Client
          par CLIMALIFE ne peut être considérée comme une violation des
          conditions de confidentialité prévues par le Contrat.
        </p>
        <p>
          Pour le traitement des données à caractère personnel : voir la Charte
          Vie privée
        </p>
        <h2>8. Responsabilité</h2>
        <h3>8.1 Généralités</h3>
        <p>
          CLIMALIFE est soumis à une obligation de moyen, à l’exclusion de tout
          autre. Il garantit la conformité des Service aux descriptions
          réalisées dans les présentes. Le Client assume toutes les
          responsabilités autres que celle de conformité des Services à leur
          description et notamment celles qui concernent :
        </p>
        <ul>
          <li>de la préservation de la confidentialité des Codes d’Accès ;</li>
          <li>
            du fonctionnement de son réseau interne et de ses propres
            équipements informatiques (en particulier de l’Infrastructure
            Locale),
          </li>
          <li>des Données Client introduites dans le Service ;</li>
          <li>de la licéité de son activité ;</li>
          <li>de la licéité et de la loyauté des Données Client ;</li>
          <li>de l’utilisation du Service par ses Préposés</li>
          <li>l’adéquation du Service à ses besoins,</li>
          <li>l’exploitation du Service,</li>
          <li>la qualification et la compétence de son personnel.</li>
        </ul>
        <p>
          En outre, et en cas de faute prouvée par le Client, CLIMALIFE ne sera
          tenu que de la réparation des conséquences pécuniaires des dommages
          directs et prévisibles du fait de l'exécution des Services. En
          conséquence, CLIMALIFE ne pourra en aucune circonstance encourir de
          responsabilité au titre des pertes ou dommages indirects ou
          imprévisibles du Client ou des tiers, ce qui inclut notamment tout
          gain manqué, perte, inexactitude ou corruption de fichiers ou de
          Données, préjudice commercial, perte de chiffre d'affaires ou de
          bénéfice, perte de clientèle, perte d'une chance, coût de l’obtention
          d’un produit, d’un service ou de technologie de substitution, en
          relation ou provenant de l’inexécution ou de l’exécution fautive des
          prestations.
        </p>
        <p>
          Dans tous les cas, le montant de la responsabilité de CLIMALIFE est
          strictement limité au remboursement du montant des sommes
          effectivement payées par le Client à la date de survenance du fait
          générateur de responsabilité sur la moyenne de consommation des douze
          derniers mois.
        </p>
        <p>
          CLIMALIFE ne saurait, en outre, être tenu responsable de la
          destruction accidentelle des Données par le Client ou un tiers ayant
          accédé aux Services applicatifs au moyen des Identifiants remis au
          Client.
        </p>
        <p>
          Chacune des Parties déclare être assurée auprès d’une compagnie
          d’assurance notoire, pour toutes les conséquences dommageables des
          actes dont elle pourrait être tenue responsable en vertu du Contrat.
        </p>
        <p>
          Cette assurance devra être maintenue pendant toute la durée du
          Contrat. Sur demande d’une Partie, l’autre Partie communiquera à
          ladite Partie une attestation annuelle émanant de sa compagnie
          d’assurances, précisant les montants et les risques garantis.
        </p>
        <p>
          CLIMALIFE ne pourra en aucun cas être tenu pour responsable de tout
          dommage en cas de préjudice causé par une interruption ou une baisse
          de service de l’opérateur de télécommunications, du fournisseur
          d’électricité ou en cas de force majeure.
        </p>
        <h3>8.2 Sécurité/accès</h3>
        <p>
          CLIMALIFE s’efforce raisonnablement et avec diligence de maintenir la
          sécurité et l’intégrité opérationnelle de Clim'app en adoptant des
          mesures de sécurité adaptées à la nature des données et aux risques
          présentés par cette activité. Toutefois, de nombreux facteurs hors de
          contrôle peuvent interférer avec le fonctionnement de Clim'app.
        </p>
        <p>
          CLIMALIFE s’efforce, dans la mesure du possible, de maintenir
          accessible les Services 7 jours sur 7, 24h sur 24.
        </p>
        <p>
          CLIMALIFE se réserve la possibilité d’interrompre, de suspendre
          momentanément ou de modifier sans préavis l’accès à tout ou partie des
          Services de Clim'app, afin d’en assurer la maintenance, la mise à
          niveau ou pour toutes autres raisons, notamment techniques, sans que
          ces manœuvres n’ouvrent droit à aucune obligation ni indemnisation.
        </p>
        <p>Par conséquent :</p>
        <ul>
          <li>
            la responsabilité de CLIMALIFE en cas d’une interruption d’accès à
            Clim'app et des conséquences qui peuvent en découler ne pourra être
            engagée ;
          </li>
          <li>
            CLIMALIFE ne peut pas être tenu responsable de dommages éventuels
            (directs ou indirects) ou d’incident temporaire ou permanent qui
            seraient causés aux données de l’Utilisateur ou à son matériel
            informatique lors de son accès à Clim'app ou lors de la visite de
            son Compte ou généralement lors de la transmission sur son appareil
            de réception des fichiers et logiciels qui composent Clim'app. En
            particulier, CLIMALIFE n’est pas responsable de l’éventuelle
            transmission d’un virus par le biais de Clim'app.
          </li>
          <li>
            CLIMALIFE n’est pas responsable de l’utilisation frauduleuse de ses
            moyens de diffusion et décline toute responsabilité en cas
            d’intrusion dans ses systèmes informatiques et de vol de données,
            sous réserve de dispositions légales et réglementaires impératives
            le cas échéant contraires, étant entendu que CLIMALIFE met en œuvre
            tous les moyens utiles afin de prévenir de telles intrusions
            illicites.
          </li>
        </ul>
        <h3>8.3 Mises à jour</h3>
        <p>
          Malgré les efforts afin d’assurer l’exactitude des informations et/ou
          documents sur Clim'app, CLIMALIFE décline toute responsabilité de
          quelque nature que ce soit, en cas d’inexactitude ou de défaut de mise
          à jour d’une information et/ou d’un document présent sur Clim'app.
        </p>
        <h2>9. Hyperliens vers d’autres services</h2>
        <p>
          Clim'app peut renvoyer vers d’autres services (sites internet,
          applications, etc.) sur lesquels CLIMALIFE n’exerce aucun contrôle
          technique ou de contenu. L’existence d’un lien hypertexte vers un
          autre service ne constitue pas une validation par CLIMALIFE de ce
          service ou de son contenu.
        </p>
        <p>
          CLIMALIFE ne peut dès lors offrir aucune garantie quant au caractère
          exhaustif ou exact du contenu de ces services-tiers, pas plus qu’en ce
          qui concerne leur disponibilité.
        </p>
        <p>
          CLIMALIFE décline toute responsabilité pour les dommages directs ou
          indirects résultant de la consultation ou de l’utilisation des
          services web vers lesquels Clim'app renvoie ou des informations
          publiées sur ces services. Il décline également toute responsabilité
          quant au traitement des données à caractère personnel sur ces
          services.
        </p>
        <h2>10. Propriété intellectuelle</h2>
        <p>
          CLIMALIFE ne concède au Client qu’un droit personnel, non exclusif et
          non transférable d’accès et d’utilisation de Clim'app, conditionné par
          le respect des présentes Conditions Générales d’Utilisation.
          Toutefois, le Client peut concéder à ses préposés et/ou Partenaires un
          droit d’utilisation et d’accès au Service pour le compte du Client, ce
          sous son entière responsabilité.
        </p>
        <p>
          Le Client reconnaît et accepte que l’utilisation ne lui confère aucun
          droit de propriété intellectuelle sur le Service ou sur tout élément
          en permettant le fonctionnement et la délivrance et de manière
          générale sur tout dispositif ou matériel fournis par CLIMALIFE ou
          inclus dans la Plateforme ou dans l’Application.
        </p>
        <p>
          Clim'app (considéré comme un tout indissociable) et les différents
          éléments figurant sur Clim'app (tels notamment les textes,
          Environnement partenaire, photographies, catalogues, mises en page,
          marques, logos, illustrations, logiciels spécifiques, vidéos) sont
          protégés par des droits intellectuels appartenant à CLIMALIFE ou à ses
          donneurs de licences.
        </p>
        <p>
          Le logo, le nom des produits, des services ou le nom de sociétés
          mentionnés sur Clim'app sont susceptibles de constituer des marques,
          noms commerciaux, dénominations sociales de leurs titulaires
          respectifs. Le Client s’engage expressément à respecter ces droits de
          propriété intellectuelle.
        </p>
        <p>
          Le Client s’interdit, sans que cette liste ne soit limitative : de
          modifier, copier, distribuer, transmettre, diffuser, publier, concéder
          sous licence, transférer, vendre, reproduire ou faire reproduire tout
          ou partie des informations, du logiciel, du produit ou service obtenu
          à partir de Clim'app, à titre temporaire ou définitif, par tout
          procédé, connus ou inconnus, sur tout support, sans l’accord préalable
          écrit de CLIMALIFE. Le non-respect de cette interdiction constitue une
          contrefaçon susceptible d’engager la responsabilité civile et pénale
          du contrefacteur.
        </p>
        <p>
          Le Client devra informer sans délai CLIMALIFE de toute utilisation du
          Service illicite ou contraire au Contrat dont il pourrait avoir
          connaissance. Si, suite à cette information, CLIMALIFE décide d’agir à
          l’encontre d’un tiers, l’Utilisateur s’engage à lui fournir toute
          l’aide nécessaire qui pourra raisonnablement lui être demandée.
        </p>
        <h2>11. Suppression du Compte</h2>
        <p>L’Utilisateur peut supprimer son Compte à tout moment.</p>
        <p>
          CLIMALIFE peut supprimer d’initiative le Compte de l’Utilisateur, ce
          qui emportera résiliation des présentes (sauf dispositions restant en
          vigueur même après résiliation des présentes), notamment dans les
          hypothèses suivantes, cette liste n’étant pas limitative :
        </p>
        <ul>
          <li>
            Cette suppression d'initiative sera le résultat de l’inutilisation
            du Compte pendant une période donnée ;
          </li>
          <li>
            Cette suppression d’initiative constitue une mesure temporaire ou
            définitive prise par CLIMALIFE à l’encontre d’un compte suspect, par
            exemple lorsque CLIMALIFE a des raisons de penser que l’Utilisateur
            ne respecte pas les présentes ;
          </li>
        </ul>
        <p>
          Dans la mesure du possible et dans les limites légales applicables,
          CLIMALIFE notifie préalablement sa décision de supprimer le Compte de
          l’Utilisateur.
        </p>
        <p>
          L’Utilisateur ne peut réclamer aucune indemnisation du chef d'une
          suppression de son Compte.
        </p>
        <p>
          Après la suppression de son Compte, CLIMALIFE conserve les données et
          contenus la durée nécessaire aux opérations techniques de suppression
          du Compte et en toute hypothèse dans les limites et obligations
          imposées par les lois applicables. CLIMALIFE se réserve le droit de
          conserver les informations et contenus publiés sur Clim'app qui sont
          nécessaires à la gestion par le CLIMALIFE de son Installation.
        </p>
        <h2>12. Dispositions Finales</h2>
        <h3>
          12.1 Modifications des présentes Conditions Générales d’Utilisation
        </h3>
        <p>
          Les présentes Conditions Générales d’Utilisation peuvent être
          modifiées à tout moment, sans préavis, en fonction des modifications
          apportées à Clim'app, de l’évolution de la législation ou pour tout
          autre motif. La version applicable des présentes Conditions Générales
          d’Utilisation qui est opposable est disponible en permanence sur
          Clim'app. L’Utilisateur s’engage à la consulter régulièrement.
        </p>
        <h3>12.2 Convention de Preuve</h3>
        <p>
          CLIMALIFE peut procéder avec l’Utilisateur à l’échange des
          informations nécessaires à Clim'app par voie électronique. Toute
          communication électronique entre les parties est présumée avoir la
          même force probante qu’un écrit sur support papier.
        </p>
        <p>
          Une version imprimée des présentes Conditions Générales d’Utilisation
          et de toute mention d’avertissement délivrée sous forme électronique
          sera acceptée dans toute procédure judiciaire ou administrative liée à
          cette relation contractuelle, au même titre et dans les mêmes
          conditions que d’autres documents et registres commerciaux créés et
          conservés sous forme imprimée.
        </p>
        <h3>15.3 Divisibilité</h3>
        <p>
          Si une clause des présentes Conditions Générales d’Utilisation devait
          être déclarée nulle, invalide, illégale ou inapplicable en vertu du
          droit applicable, en tout ou partie, une telle situation n’affecterait
          pas la validité des clauses restantes, sous réserve de l’hypothèse de
          la nullité d’une clause essentielle ou de l’interdépendance des
          stipulations contractuelles. La clause nulle, invalide, illégale ou
          inapplicable sera remplacée rétroactivement par une clause valable et
          applicable dont la teneur est aussi proche que possible de celle de la
          clause originale.
        </p>
        <h3>15.6 Notification</h3>
        <p>
          Toute communication ou notification que CLIMALIFE fera sera valable si
          elle est adressée à l’adresse électronique que l’Utilisateur aura
          donnée, même si celle-ci n’est plus valide.
        </p>
        <h3>15.8 Droit applicable et tribunaux compétents</h3>
        <p>
          Les présentes Conditions Générales d’Utilisation sont régies et
          interprétées conformément au droit applicable à l’entité CLIMALIFE
          ayant réalisé l’offre Clim'app que le Client a accepté.
        </p>
        <p>
          Tout différend relatif à l’utilisation de Clim'app ainsi qu’à la
          validité, l’interprétation, l’exécution ou l’inexécution des présentes
          Conditions Générales d’Utilisation relèvera de la compétence exclusive
          des Tribunaux du ressort de cette entitée
        </p>
      </div>
    );
  }
}
