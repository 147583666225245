import I18n from "i18n-js";
import { fetch } from "../../../appContainer";
import {
  GET_PROVIDERS_LIST_IN_PROGRESS,
  GET_PROVIDERS_LIST_SUCCESS,
  GET_PROVIDERS_LIST_FAIL,
  SERVICE_BENEFICIARY_FOUND,
  SERVICE_BENEFICIARY_NOT_FOUND,
  POST_SERVICE_BENEFICIARY_CONTRACT_IN_PROGRESS,
  POST_SERVICE_BENEFICIARY_CONTRACT_SUCCESS,
  POST_SERVICE_BENEFICIARY_CONTRACT_FAILURE,
  PATCH_SERVICE_PROVIDER_CONTRACT_IN_PROGRESS,
  PATCH_SERVICE_PROVIDER_CONTRACT_SUCCESS,
  PATCH_SERVICE_PROVIDER_CONTRACT_FAILURE,
  PUT_SERVICE_PROVIDER_CONTRACT_IN_PROGRESS,
  PUT_SERVICE_PROVIDER_CONTRACT_SUCCESS,
  PUT_SERVICE_PROVIDER_CONTRACT_FAILURE,
  REFUSE_SERVICE_PROVIDER_CONTRACT_IN_PROGRESS,
  REFUSE_SERVICE_PROVIDER_CONTRACT_SUCCESS,
  REFUSE_SERVICE_PROVIDER_CONTRACT_FAILURE,
} from "../constants";
import { SUBMIT_SUCCESS, SUBMIT_FAIL } from "../../common/constants";

export function getProvidersList() {
  return (dispatch, getState) => {
    if (getState().providers.list === undefined) {
      dispatch({ type: GET_PROVIDERS_LIST_IN_PROGRESS });
    }

    fetch("api").getProvidersList(
      (list) => {
        dispatch({ type: GET_PROVIDERS_LIST_SUCCESS, payload: { list } });
      },
      () => dispatch({ type: GET_PROVIDERS_LIST_FAIL }),
    );
  };
}

/**
 * @param {String} enterpriseIdentifier
 */
export function searchServiceBeneficiary(enterpriseIdentifier) {
  return (dispatch) => {
    fetch("api").postSearchServiceBeneficiary(
      enterpriseIdentifier,
      (infos) => {
        dispatch({ type: SERVICE_BENEFICIARY_FOUND, payload: { infos } });
      },
      () => {
        dispatch({ type: SERVICE_BENEFICIARY_NOT_FOUND });
      },
    );
  };
}

/**
 * @param {String}  uuid
 * @param {Boolean} isRenew
 */
export function postProviderContract(uuid, isRenew = false) {
  return (dispatch) => {
    dispatch({ type: POST_SERVICE_BENEFICIARY_CONTRACT_IN_PROGRESS });

    fetch("api").postServiceProviderContract(
      uuid,
      () => {
        dispatch({ type: POST_SERVICE_BENEFICIARY_CONTRACT_SUCCESS });

        if (isRenew) {
          const message = I18n.t("alerts.create:provider_contract.success");

          dispatch({ type: SUBMIT_SUCCESS, payload: { message } });
        }
      },
      () => {
        const message = I18n.t("alerts.create:provider_contract.fail");

        dispatch({ type: POST_SERVICE_BENEFICIARY_CONTRACT_FAILURE });
        dispatch({ type: SUBMIT_FAIL, payload: { message } });
      },
    );
  };
}

/**
 * @param {String} startDate
 * @param {String} endDate
 * @param {String} uuid
 */
export function acceptServiceProviderContract(startDate, endDate, uuid) {
  return (dispatch) => {
    dispatch({ type: PATCH_SERVICE_PROVIDER_CONTRACT_IN_PROGRESS });

    fetch("api").patchServiceProviderContract(
      uuid,
      { startDate, endDate },
      () => {
        const message = I18n.t("alerts.patch:provider_contract.success");

        dispatch({ type: SUBMIT_SUCCESS, payload: { message } });
        dispatch({ type: PATCH_SERVICE_PROVIDER_CONTRACT_SUCCESS });

        dispatch(getProvidersList());
      },
      () => {
        const message = I18n.t("alerts.patch:provider_contract.fail");

        dispatch({ type: PATCH_SERVICE_PROVIDER_CONTRACT_FAILURE });
        dispatch({ type: SUBMIT_FAIL, payload: { message } });
      },
    );
  };
}

/**
 * @param {String} uuid
 */
export function refuseServiceProviderContract(uuid) {
  return (dispatch) => {
    dispatch({ type: REFUSE_SERVICE_PROVIDER_CONTRACT_IN_PROGRESS });

    fetch("api").refuseServiceProviderContract(
      uuid,
      () => {
        const message = I18n.t("alerts.refuse:provider_contract.success");

        dispatch({ type: SUBMIT_SUCCESS, payload: { message } });
        dispatch({ type: REFUSE_SERVICE_PROVIDER_CONTRACT_SUCCESS });

        dispatch(getProvidersList());
      },
      () => {
        const message = I18n.t("alerts.refuse:provider_contract.fail");

        dispatch({ type: REFUSE_SERVICE_PROVIDER_CONTRACT_FAILURE });
        dispatch({ type: SUBMIT_FAIL, payload: { message } });
      },
    );
  };
}

/**
 * @param {String} startDate
 * @param {String} endDate
 * @param {String} uuid
 */
export function updateServiceProviderContract(startDate, endDate, uuid) {
  return (dispatch) => {
    dispatch({ type: PUT_SERVICE_PROVIDER_CONTRACT_IN_PROGRESS });

    fetch("api").putServiceProviderContract(
      uuid,
      { startDate, endDate },
      () => {
        const message = I18n.t("alerts.update:provider_contract.success");

        dispatch({ type: SUBMIT_SUCCESS, payload: { message } });
        dispatch({ type: PUT_SERVICE_PROVIDER_CONTRACT_SUCCESS });
      },
      () => {
        const message = I18n.t("alerts.update:provider_contract.fail");

        dispatch({ type: PUT_SERVICE_PROVIDER_CONTRACT_FAILURE });
        dispatch({ type: SUBMIT_FAIL, payload: { message } });
      },
    );
  };
}
