import React from "react";
import BsddShippingReceiptInformation from "./BsddShippingReceiptInformation";
import BsddShippingSendInformation from "./BsddShippingSendInformation";
import PropTypes from "prop-types";
import { trans } from "../../../translations";
import ShippingType from "../models/ShippingType";

BsddShippingInformations.propTypes = {
  shipping: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    shippedBsdds: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  shippingType: PropTypes.string.isRequired,
};

export default function BsddShippingInformations({ shipping, shippingType }) {
  return (
    <div>
      <h4>{trans("trackdechets.bsdd.list.title")}</h4>
      <div>
        {trans(
          shipping.shippedBsdds.length === 0
            ? "trackdechets.bsdd.shipping.no-scan"
            : "trackdechets.bsdd.shipping.scan",
        )}
        <ul>
          {shipping.shippedBsdds.map((bordereauId) => (
            <li key={`bsdd-${bordereauId}`}>{bordereauId}</li>
          ))}
        </ul>
      </div>
      {shippingType === ShippingType.IN ? (
        <BsddShippingReceiptInformation shippingUuid={shipping.uuid} />
      ) : (
        <BsddShippingSendInformation shippingUuid={shipping.uuid} />
      )}
    </div>
  );
}
