import ImportInstallationsContent from "../modules/installation/pages/ImportInstallations/de";
import ImportSitesContent from "../modules/company/pages/ImportSites/de";
import ImportClientsContent from "../modules/company/pages/ImportClients/de";
import PrivacyPolicy from "../modules/common/pages/PrivacyPolicy/de";
import CGU from "../modules/common/pages/CGU/de";

export default {
  ImportInstallationsContent,
  ImportSitesContent,
  ImportClientsContent,
  PrivacyPolicy,
  CGU,
};
