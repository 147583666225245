/* eslint react/no-unescaped-entities: 0 */
/* eslint react/display-name: 0 */
import React, { Component } from "react";
import ImportSitesContent from "./ImportSitesContent";

export default class extends Component {
  static propTypes = ImportSitesContent.propTypes;

  /**
   * {@inheritdoc}
   */
  render() {
    return (
      <div>
        <p>
          Para importar sus sitios, los datos deben guardarse en formato TSV y
          seguir la siguiente estructura:
        </p>

        <ul>
          <li>Nº de cliente</li>
          <li>Nº de centro</li>
          <li>Designación</li>
          <li>País</li>
          <li>Ciudad</li>
          <li>Código postal</li>
          <li>Calle</li>
          <li>
            Calle complementaria <i>*1</i>
          </li>
          <li>Nombre de la persona de contacto</li>
          <li>Nombre del contacto</li>
          <li>dirección @contacto</li>
          <li>
            Almacenamiento en el sitio superior <i>*2</i>
          </li>
          <li>
            Nombre del jefe de obra <i>*3</i>
          </li>
        </ul>

        <p>
          <i>
            *1 : opcional
            <br />
            *2 : 0 = ningún lugar de almacenamiento — 1= lugar de almacenamiento
            <br />
            *3 : obligatorio si se trata de un emplazamiento de almacenamiento
            <br />
          </i>
        </p>

        <p>{this.props.downloadSample}</p>

        <p>
          Después de la integración, un resumen le informará sobre el número de
          entidades creadas o actualizadas, y le proporcionará un archivo de
          anomalía que contiene las líneas no integradas, y la(s) razón(es) del
          rechazo.
        </p>

        <p>Las posibles causas de las anomalías son:</p>

        <ul>
          <li>Ausencia de datos obligatorios</li>
          <li>Dirección @ incorrecta</li>
          <li>Valor del indicador diferente de 1/0</li>
        </ul>
      </div>
    );
  }
}
