import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import I18n from "i18n-js";
import Quantity from "../../common/components/elements/Quantity";
import { useQuery } from "@apollo/client";
import wasteEligibleContainers from "../graphql/queries/wasteEligibleContainers.graphql";
import { removeElement } from "../../common/utils/arrayUtils";
import StatelessTable from "../../common/components/elements/StatelessTable";
import StatelessCheckbox from "../../common/components/form/StatelessCheckbox";
import { EnumChoice } from "../../common/components/form/EnumChoice";
import TreatmentMode from "../models/TreatmentMode";
import Packaging from "../models/Packaging";
import WasteContainersFormBatchActions from "./WasteContainersFormBatchActions";
import { ContainerInput } from "../propTypes/types";
import GenericLoadingError from "../../common/components/elements/GenericLoadingError";

WasteContainersForm.propTypes = {
  containersData: PropTypes.arrayOf(ContainerInput).isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      treatmentMode: PropTypes.arrayOf(PropTypes.string),
      toDestruct: PropTypes.arrayOf(PropTypes.string),
      toReturn: PropTypes.arrayOf(PropTypes.string),
      packaging: PropTypes.arrayOf(PropTypes.string),
    }),
  ),
};
WasteContainersForm.defaultProps = {
  errors: [],
};

export default function WasteContainersForm({
  containersData,
  onDelete,
  onChange,
  errors,
}) {
  const { loading, error, data } = useQuery(wasteEligibleContainers);

  const uuids = useMemo(
    () => containersData.map((c) => c.containerUuid),
    [containersData],
  );

  const [selected, setSelected] = useState([]);
  const addSelected = (uuid) =>
    setSelected(Array.from(new Set([...selected, uuid])));
  const removeSelected = (uuid) => setSelected(removeElement(selected, uuid));
  const toggleSelected = (uuid) => (checked) =>
    checked ? addSelected(uuid) : removeSelected(uuid);
  // On data change, recompute selected list to exclude removed rows:
  useEffect(() => {
    setSelected((prevSelected) =>
      prevSelected.filter((uuid) => uuids.includes(uuid)),
    );
  }, [containersData, uuids]);

  if (loading) {
    return <div className="loader" />;
  }

  if (error) {
    return <GenericLoadingError />;
  }

  const containers = (data?.wasteEligibleContainers || []).filter((c) =>
    uuids.includes(c.uuid),
  );

  const toggleAll = (checked) =>
    checked ? setSelected(uuids) : setSelected([]);
  const areAllSelected = selected.length === uuids.length;

  const applyBatchValuesToSelection = (data) => {
    selected.forEach((uuid) => {
      const prevData =
        containersData.find((d) => d.containerUuid === uuid) || {};
      onChange(uuid, { ...prevData, ...data });
    });
    // empty selection:
    setSelected([]);
  };

  return (
    <StatelessTable
      className="waste-containers-form"
      tableClasses="table-sm table-form table-waste-containers"
      emptyContent={
        "Aucun contenant sélectionné" ||
        I18n.t(
          "pages.create_waste_recovery.steps.containers.no_containers_selected",
        )
      }
      data={containersData.map((data) => [
        containers.find((c) => c.uuid === data.containerUuid),
        data,
      ])}
      headData={[
        /* eslint-disable react/jsx-key */
        <StatelessCheckbox
          id="select-all"
          label={false}
          className="text-center"
          onChangedValue={toggleAll}
          checked={areAllSelected}
        />,
        "Identifiant",
        "Désignation",
        "Fluide",
        <div className="text-right">Volume</div>,
        <div className="text-right">Qté actuelle</div>,
        <span>
          Traitement<i>*</i>
        </span>,
        <div className="text-center">Destruction contenant</div>,
        <div className="text-center">Renvoi contenant</div>,
        "Support logistique",
        null,
        /* eslint-enable react/jsx-key */
      ]}
      rowData={([container, data], i) => {
        const onFieldChange = (fieldName) => (value) =>
          onChange(container.uuid, { ...data, [fieldName]: value });

        return [
          /* eslint-disable react/jsx-key */
          // Select row
          <StatelessCheckbox
            id={`select-${i}`}
            label={false}
            className="text-center"
            onChangedValue={toggleSelected(container.uuid)}
            checked={selected.includes(container.uuid)}
          />,
          // Data
          container.barcode,
          container.article.designation,
          container.knownFluid?.designation,
          <Quantity value={container.article.volume} unit={"L"} />,
          <Quantity value={container.currentLoad} unit={"kg"} />,
          // Form
          <EnumChoice
            id={`treatmentMode-${i}`}
            label={false}
            enumClass={TreatmentMode}
            defaultValue={data.treatmentMode}
            onChangedValue={onFieldChange("treatmentMode")}
            error={errors[i]?.treatmentMode}
          />,
          <StatelessCheckbox
            id={`toDestruct-${i}`}
            label={false}
            className="text-center"
            onChangedValue={onFieldChange("toDestruct")}
            checked={data.toDestruct}
            errors={errors[i]?.toDestruct}
          />,
          <StatelessCheckbox
            id={`toReturn-${i}`}
            label={false}
            className="text-center"
            onChangedValue={onFieldChange("toReturn")}
            checked={data.toReturn}
            errors={errors[i]?.toReturn}
          />,
          <EnumChoice
            id={`packaging-${i}`}
            label={false}
            enumClass={Packaging}
            defaultValue={data.packaging}
            onChangedValue={onFieldChange("packaging")}
            error={errors[i]?.packaging}
            optional
          />,
          // Actions
          <div className="text-center">
            <button
              type="button"
              className="action"
              onClick={() => onDelete(container)}
            >
              <i className="fa fa-trash-o" aria-hidden="true" />
            </button>
          </div>,
          /* eslint-enable react/jsx-key */
        ];
      }}
      actions={
        <div className="row">
          <div className="offset-xl-6 col-xl-6">
            <WasteContainersFormBatchActions
              onApply={applyBatchValuesToSelection}
              enabled={selected.length > 0}
            />
          </div>
        </div>
      }
    />
  );
}
