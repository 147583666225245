export const ROUTE_CONTACT_US = AppConfig.CONTACT_URL;
export const ROUTE_API_ADMIN = `${AppConfig.API_HOST}/admin`;
export const ROUTE_HOME = "/";
export const ROUTE_NOT_FOUND = "/404";
export const ROUTE_FORBIDDEN = "/403";
export const ROUTE_PRIVACY_POLICY = "/politique-de-confidentialite";
export const ROUTE_CGU = "/cgu";
export const ROUTE_LOGIN = "/connexion";
export const ROUTE_TRACKDECHETS_OAUTH_STATUS = "/auth/trackdechets/status";
export const ROUTE_TRACKDECHETS_OAUTH_CALLBACK = "/auth/trackdechets/callback";
export const ROUTE_CHANGE_PASSWORD = "/changer-le-mot-de-passe";
export const ROUTE_FORGOT_PASSWORD = "/mot-de-passe-oublie";
export const ROUTE_MY_ENTERPRISE = "/mon-entreprise";
export const ROUTE_USERS = "/utilisateurs";
export const ROUTE_USERS_ADD = "/utilisateurs/creer";
export const ROUTE_USER = "/utilisateurs/:uuid";
export const ROUTE_INSTALLATIONS_IMPORT = "/installations/import";
export const ROUTE_INSTALLATIONS = "/installations";
export const ROUTE_SITES_IMPORT = "/sites/import";
export const ROUTE_SITES = "/sites";
export const ROUTE_SITE = "/sites/:uuid";
export const ROUTE_MOBILE_SITES = "/sites-mobiles";
export const ROUTE_MOBILE_SITE = "/sites-mobiles/:uuid";
export const ROUTE_MOBILE_SITES_ADD = "/sites-mobiles/creer";
export const ROUTE_SITES_ADD = "/sites/creer";
export const ROUTE_CARRIERS = "/transporteurs";
export const ROUTE_CARRIERS_ADD = "/transporteurs/creer";
export const ROUTE_CARRIER = "/transporteurs/:uuid";
export const ROUTE_LEAK_DETECTORS = "/detecteurs-de-fuite";
export const ROUTE_LEAK_DETECTOR = "/detecteurs-de-fuite/:uuid";
export const ROUTE_LEAK_DETECTORS_ADD = "/detecteurs-de-fuite/creer";
export const ROUTE_LEAK_DETECTOR_UPDATE = "/detecteurs-de-fuite/:uuid/modifier";
export const ROUTE_CONTAINERS = "/contenants";
export const ROUTE_INTERVENTIONS = "/interventions";
export const ROUTE_INTERVENTIONS_PLANNED = "/interventions-programmees";
export const ROUTE_INTERVENTIONS_PLANNED_ADD = `${ROUTE_INTERVENTIONS_PLANNED}/creer`;
export const ROUTE_INTERVENTIONS_PLANNED_UPDATE = `${ROUTE_INTERVENTIONS_PLANNED}/:uuid`;
export const ROUTE_INTERVENTION = "/interventions/:uuid";
export const ROUTE_FLUID_SUMMARIES = "/fluid-summaries";
export const ROUTE_FIBSDS = "/fibsds";
export const ROUTE_FIBSD = "/fibsd/:uuid";
export const ROUTE_SHIPPINGS = "/deplacements";
export const ROUTE_CLIENT = "/clients/:uuid";
export const ROUTE_CLIENTS_IMPORT = "/clients/import";
export const ROUTE_CLIENTS = "/clients";
export const ROUTE_CLIENTS_ADD = "/clients/creer";
export const ROUTE_PROVIDERS = "/prestataires";
export const ROUTE_PROVIDER_CONTRACTS = "/contrats-de-prestataire";
export const ROUTE_ENTERPRISE = "/enterprise/:uuid";
// Waste recovery requests
export const ROUTE_COMPANY_WASTE_RECOVERY_REQUESTS =
  "/demandes-reprise-déchets";
export const ROUTE_MY_WASTE_RECOVERY_REQUESTS = "/reprise-déchets";
export const ROUTE_WASTE_RECOVERY_REQUESTS_CREATE = "/reprise-déchets/créer";
export const ROUTE_WASTE_RECOVERY_REQUESTS_CREATE_CONTAINERS =
  "/reprise-déchets/créer/contenants";
export const ROUTE_WASTE_RECOVERY_REQUESTS_CREATE_ARTICLES =
  "/reprise-déchets/créer/articles";
export const ROUTE_WASTE_RECOVERY_REQUESTS_CREATE_TRANSPORT =
  "/reprise-déchets/créer/transport";
export const ROUTE_WASTE_RECOVERY_REQUESTS_CREATE_SUMMARY =
  "/reprise-déchets/créer/résumé";
export const ROUTE_WASTE_RECOVERY_REQUEST_SUMMARY =
  "/reprise-déchets/:uuid/résumé";
export const ROUTE_WASTE_RECOVERY_REQUEST_STATUS =
  "/reprise-déchets/:uuid/statut";
