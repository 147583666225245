import I18n from "i18n-js";
import React from "react";
import { func, shape, arrayOf } from "prop-types";
import { connect } from "react-redux";
import pathToRegexp from "path-to-regexp";
import moment from "moment";
import { Link } from "react-router-dom";
import { getLang } from "../../common/utils/langUtils";
import { alphabetically } from "../../common/utils/filterUtils";
import TextInput from "../../common/components/form/TextInput";
import Select from "../../common/components/form/Select";
import DateInput from "../../common/components/form/DateInput";
import { getFibsdList } from "../actions/fibsdActions";
import InterventionType from "../../intervention/models/InterventionType";
import List from "../../common/components/layout/List";
import Table from "../../common/components/elements/Table";
import { ROUTE_FIBSD } from "../../../routes";
import Checkbox from "../../common/components/form/Checkbox";
import ClientUtils from "../../company/utils/ClientUtils";
import { getClientsList } from "../../company/actions/clientsActions";
import { formatFloat } from "../../common/utils/formatUtils";

class FibsdList extends List {
  static propTypes = {
    fibsds: shape().isRequired,
    fibsdsList: arrayOf(shape).isRequired,
    clientsList: arrayOf(shape()).isRequired,
    getClientsList: func.isRequired,
    getFibsdList: func.isRequired,
    location: shape().isRequired, // react-router location
  };

  constructor() {
    super();

    this.refreshDataState = this.refreshDataState.bind(this);
    this.siteInterventionFilter = this.siteInterventionFilter.bind(this);
    this.installationOwnerFilter = this.installationOwnerFilter.bind(this);
    this.idContainerFilter = this.idContainerFilter.bind(this);
    this.latelyCompletedFilter = this.latelyCompletedFilter.bind(this);
  }

  /**
   * {@inheritdoc}
   */
  componentDidMount() {
    super.componentDidMount();

    this.props.getFibsdList();
    this.props.getClientsList();
  }

  /**
   * @return {Array}
   */
  getSitesFromFibsds() {
    const sites = {};

    this.props.fibsdsList
      .filter(this.getFilterBySelect("client"))
      .forEach((fibsd) => {
        sites[fibsd.intervention.site.uuid] = fibsd.intervention.site;
      });

    return Object.values(sites).sort((a, b) =>
      alphabetically(a.designation, b.designation),
    );
  }

  /**
   * @return {Array}
   */
  getInstallationOwnersFromFibsds() {
    const owners = {};

    this.props.fibsdsList
      .filter(this.getFilterBySelect("client"))
      .filter(this.getCustomFilter("site", this.siteInterventionFilter))
      .forEach((fibsd) => {
        owners[fibsd.intervention.client.uuid] = fibsd.intervention.client;
      });

    return Object.values(owners).sort((a, b) =>
      alphabetically(ClientUtils.renderLabel(a), ClientUtils.renderLabel(b)),
    );
  }

  /**
   * @param {Object} prevState
   */
  refreshDataState(prevState) {
    const { client, site } = this.state.filters;

    if (!client || prevState.filters.client !== client) {
      this.setState({
        filters: {
          ...this.state.filters,
          site: null,
          installationOwner: null,
        },
      });
    }

    if (!site || prevState.filters.site !== site) {
      this.setState({
        filters: {
          ...this.state.filters,
          installationOwner: null,
        },
      });
    }
  }

  /**
   * @return {Array}
   */
  dataTableHead() {
    return [
      I18n.t("pages.fibsd.list.head.record"),
      I18n.t("pages.fibsd.list.head.annex"),
      I18n.t("pages.fibsd.list.head.date"),
      I18n.t("pages.fibsd.list.head.client"),
      I18n.t("pages.fibsd.list.head.installation_owner"),
      I18n.t("pages.fibsd.list.head.installation"),
      I18n.t("pages.fibsd.list.head.containers"),
      I18n.t("pages.fibsd.list.head.fluid"),
      I18n.t("pages.fibsd.list.head.complete"),
      "",
    ];
  }

  /**
   * @param {Object} rowData
   *
   * @return {Array}
   */
  dataTableRow(rowData) {
    const {
      fluid,
      client,
      intervention,
      complete,
      uuid,
      number,
      annexNumber,
      sentDate,
      userCanComplete,
      containerLoads,
    } = rowData;
    const { installation } = intervention;
    const completeLink = userCanComplete && !complete && (
      <Link to={pathToRegexp.compile(ROUTE_FIBSD)({ uuid })}>
        {" "}
        {I18n.t("pages.fibsd.list.actions.complete")}
      </Link>
    );
    const completeIndic = (
      <div className={`text-${complete ? "success" : "danger"}`}>
        <i
          className={`fa fa-${complete ? "check" : "times"}`}
          aria-hidden="true"
        />
      </div>
    );

    // Dedupe container loads by uuid to get the different current loads by container used.
    let lastContainerLoadsByUuids = {};
    containerLoads.forEach((containerLoad) => {
      lastContainerLoadsByUuids[containerLoad.container.uuid] = containerLoad;
    });
    lastContainerLoadsByUuids = Object.values(lastContainerLoadsByUuids);

    const realContainerLoads = lastContainerLoadsByUuids.map(
      ({ barcode, load, knownCurrentLoad, interventionType }) => {
        let realLoad = load;
        if (
          knownCurrentLoad &&
          [InterventionType.DRAINAGE, InterventionType.FILLING].includes(
            interventionType,
          )
        ) {
          realLoad =
            InterventionType.DRAINAGE === interventionType
              ? knownCurrentLoad + load
              : knownCurrentLoad - load;
        }

        return `${barcode} (${formatFloat(realLoad)} kg)`;
      },
    );

    return [
      number,
      annexNumber,
      new Date(sentDate).toLocaleDateString(getLang()),
      ClientUtils.renderLabel(client),
      ClientUtils.renderLabel(intervention.client),
      installation.designation,
      realContainerLoads.join(", "),
      fluid,
      completeIndic,
      completeLink,
    ];
  }

  /**
   * @return {Array}
   */
  getFibsdList() {
    return this.props.fibsdsList
      .filter(this.getFilterBySelect("client"))
      .filter(this.getCustomFilter("site", this.siteInterventionFilter))
      .filter(
        this.getCustomFilter("installationOwner", this.installationOwnerFilter),
      )
      .filter(this.getFilterByText("number"))
      .filter(this.getFilterByText("annexNumber"))
      .filter(this.getCustomFilter("idContainer", this.idContainerFilter))
      .filter(
        this.getCustomFilter("latelyCompleted", this.latelyCompletedFilter),
      )
      .filter(this.getFilterByCheckbox("complete", true))
      .filter(this.getFilterByDate("sentDate"));
  }

  /**
   * @param {Object} data
   * @param {String} value
   *
   * @return {Boolean}
   */
  siteInterventionFilter(data, value) {
    return data.intervention.site.uuid === value;
  }

  /**
   * @param {Object} data
   * @param {String} value
   *
   * @return {Boolean}
   */
  installationOwnerFilter(data, value) {
    return data.intervention.client.uuid === value;
  }

  /**
   * @param {Object} data
   * @param {String} value
   *
   * @return {Boolean}
   */
  idContainerFilter(data, value) {
    const containerBarcodes = data.containerLoads.reduce(
      (barcodes, containerLoad) => barcodes.concat([containerLoad.barcode]),
      [],
    );

    return containerBarcodes.includes(value);
  }

  /**
   * @param {Object} data
   *
   * @return {Boolean}
   */
  latelyCompletedFilter(data) {
    return (
      data.completedAt &&
      moment(data.completedAt).isAfter(moment().subtract(3, "months"))
    );
  }

  /**
   * {@inheritdoc}
   */
  renderFilters() {
    const { clientsList } = this.props;
    const { filters } = this.state;

    return [
      <Select
        key="filterByClient"
        id="filterByClient"
        defaultValue={filters.client}
        onChange={(event) =>
          this.onChangeFilter(event, "client", this.refreshDataState)
        }
        label={I18n.t("filters.by_client")}
        renderOption={(option) => (
          <option key={option.uuid} value={option.uuid}>
            {ClientUtils.renderLabel(option)}
          </option>
        )}
        options={clientsList}
        placeholder={I18n.t("filters.by_client:default")}
        optional
      />,
      <Select
        key="filterBySite"
        id="filterBySite"
        defaultValue={filters.site}
        onChange={(event) =>
          this.onChangeFilter(event, "site", this.refreshDataState)
        }
        label={I18n.t("filters.by_site")}
        renderOption={(option) => (
          <option key={option.uuid} value={option.uuid}>
            {option.designation}
          </option>
        )}
        options={this.getSitesFromFibsds()}
        placeholder={I18n.t("filters.by_site:default")}
        optional
      />,
      <Select
        key="filterByInstallationOwner"
        id="filterByInstallationOwner"
        defaultValue={filters.installationOwner}
        onChange={(event) => this.onChangeFilter(event, "installationOwner")}
        label={I18n.t("filters.by_installation_owner")}
        renderOption={(option) => (
          <option key={option.uuid} value={option.uuid}>
            {ClientUtils.renderLabel(option)}
          </option>
        )}
        options={this.getInstallationOwnersFromFibsds()}
        placeholder={I18n.t("filters.by_installation_owner:default")}
        optional
      />,
      <TextInput
        key="filterByFibsdNumber"
        id="filterByFibsdNumber"
        defaultValue={filters.number}
        onChange={(event) => this.onChangeFilter(event, "number")}
        label={I18n.t("filters.by_fibsd_number")}
        optional
      />,
      <TextInput
        key="filterByAnnexNumber"
        id="filterByAnnexNumber"
        defaultValue={filters.annexNumber}
        onChange={(event) => this.onChangeFilter(event, "annexNumber")}
        label={I18n.t("filters.by_annex_number")}
        optional
      />,
      <TextInput
        key="filterByIdContainer"
        id="filterByIdContainer"
        defaultValue={filters.idContainer}
        onChange={(event) =>
          this.onChangeFilter(event, "idContainer", null, true)
        }
        label={I18n.t("filters.by_id_container")}
        optional
      />,
      <DateInput
        key="filterByStartDate"
        id="filterByStartDate"
        defaultValue={filters.startDate && new Date(Number(filters.startDate))}
        onChange={(date) => this.onChangeDateFilter(date, "startDate")}
        label={I18n.t("filters.by_start_date")}
        legend
        optional
      />,
      <DateInput
        key="filterByEndDate"
        id="filterByEndDate"
        defaultValue={filters.endDate && new Date(Number(filters.endDate))}
        onChange={(date) => this.onChangeDateFilter(date, "endDate")}
        label={I18n.t("filters.by_end_date")}
        legend
        optional
      />,
      <Checkbox
        key="filterLatelyCompletedOnly"
        id="filterLatelyCompletedOnly"
        label={I18n.t("filters.lately_completed_only")}
        checked={Boolean(filters.latelyCompleted)}
        onChange={(checked) =>
          this.onChangeCheckboxFilter(checked, "latelyCompleted")
        }
      />,
      <Checkbox
        key="filterToCompleteOnly"
        id="filterToCompleteOnly"
        label={I18n.t("filters.to_complete_only")}
        checked={Boolean(filters.complete)}
        onChange={(checked) => this.onChangeCheckboxFilter(checked, "complete")}
      />,
    ];
  }

  /**
   * {@inheritdoc}
   */
  renderContent() {
    const { fibsds } = this.props;

    if (fibsds.inProgress) {
      return <div className="loader" />;
    }

    if (fibsds.error) {
      return <p>{I18n.t("common.loadingApiError")}</p>;
    }

    return (
      <Table
        emptyContent={I18n.t("pages.fibsd.list.empty")}
        data={this.getFibsdList()}
        headData={this.dataTableHead()}
        rowData={this.dataTableRow}
        filters={this.renderFilters()}
        pagination
        onPageChanged={this.onPageChanged}
        initialPage={this.getCurrentPage()}
      />
    );
  }

  /**
   * {@inheritdoc}
   */
  renderTitle() {
    return I18n.t("pages.fibsd.list.title");
  }
}

const mapStateToProps = (state) => ({
  fibsds: state.fibsd,
  fibsdsList: state.fibsd.list,
  clientsList: state.clients.list.sort((a, b) =>
    alphabetically(ClientUtils.renderLabel(a), ClientUtils.renderLabel(b)),
  ),
});

const mapDispatchToProps = (dispatch) => ({
  getFibsdList: () => dispatch(getFibsdList()),
  getClientsList: () => dispatch(getClientsList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FibsdList);
