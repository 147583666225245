import Spinner from "../../common/components/Spinner";
import StatusFlag from "../models/StatusFlag";
import I18n from "i18n-js";
import { OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import { formatDateTime } from "../../common/utils/dateUtils";
import React from "react";
import PropTypes from "prop-types";

const triggers = ["focus", "hover", "click"];

BadgeWithTooltip.propTypes = {
  type: PropTypes.string.isRequired,
  tooltipTitle: PropTypes.string.isRequired,
  badgeLabel: PropTypes.string.isRequired,
  lastUpdatedAt: PropTypes.instanceOf(Date),
};
BadgeWithTooltip.defaultProps = {
  lastUpdatedAt: null,
};

function BadgeWithTooltip({ type, tooltipTitle, badgeLabel, lastUpdatedAt }) {
  return (
    <OverlayTrigger
      trigger={triggers}
      placement="auto"
      overlay={
        <Tooltip>
          {tooltipTitle}
          <br />
          {lastUpdatedAt && (
            <small>
              {I18n.t(
                "trackdechets.sync_statuses_table.cell.badges.invalid.lastUpdatedAt",
                {
                  date: formatDateTime(lastUpdatedAt),
                },
              )}{" "}
            </small>
          )}
        </Tooltip>
      }
    >
      <span tabIndex={1} className={`badge badge-${type}`}>
        {badgeLabel}
      </span>
    </OverlayTrigger>
  );
}

CompanySyncStatusCell.propTypes = {
  bitFlags: PropTypes.number,
  loading: PropTypes.bool,
  lastUpdatedAt: PropTypes.instanceOf(Date),
  error: PropTypes.bool,
  refresh: PropTypes.func.isRequired,
};

CompanySyncStatusCell.defaultProps = {
  bitFlags: null,
  lastUpdatedAt: null,
  loading: false,
  error: false,
};

export function CompanySyncStatusCell({
  bitFlags,
  lastUpdatedAt,
  error,
  loading,
  refresh,
}) {
  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return BadgeWithTooltip({
      type: "danger",
      tooltipTitle: I18n.t(
        "trackdechets.sync_statuses_table.cell.badges.error.title",
      ),
      badgeLabel: I18n.t(
        "trackdechets.sync_statuses_table.cell.badges.error.value",
      ),
      lastUpdatedAt,
    });
  }

  const flagBag = new StatusFlag(bitFlags || StatusFlag.NONE);

  let badge;

  switch (true) {
    case [null, undefined].includes(bitFlags):
      badge = BadgeWithTooltip({
        type: "dark",
        tooltipTitle: I18n.t(
          "trackdechets.sync_statuses_table.cell.badges.unknown.title",
        ),
        badgeLabel: I18n.t(
          "trackdechets.sync_statuses_table.cell.badges.unknown.value",
        ),
        lastUpdatedAt,
      });
      break;

    case bitFlags === StatusFlag.NONE:
      badge = BadgeWithTooltip({
        type: "success",
        tooltipTitle: I18n.t(
          "trackdechets.sync_statuses_table.cell.badges.valid.title",
        ),
        badgeLabel: I18n.t(
          "trackdechets.sync_statuses_table.cell.badges.valid.value",
        ),
        lastUpdatedAt,
      });
      break;

    case flagBag.hasFlag(StatusFlag.FORBIDDEN):
      badge = BadgeWithTooltip({
        type: "danger",
        tooltipTitle: I18n.t(
          new StatusFlag(StatusFlag.FORBIDDEN).getReadable(),
        ),
        badgeLabel: I18n.t(
          "trackdechets.sync_statuses_table.cell.badges.forbidden",
        ),
        lastUpdatedAt,
      });
      break;

    case flagBag.hasFlag(StatusFlag.SIRET_NOT_FOUND):
      badge = BadgeWithTooltip({
        type: "danger",
        tooltipTitle: I18n.t(
          new StatusFlag(StatusFlag.SIRET_NOT_FOUND).getReadable(),
        ),
        badgeLabel: I18n.t(
          "trackdechets.sync_statuses_table.cell.badges.siret_not_found",
        ),
        lastUpdatedAt,
      });
      break;

    case flagBag.hasFlag(StatusFlag.NOT_REGISTERED):
      badge = BadgeWithTooltip({
        type: "danger",
        tooltipTitle: I18n.t(
          new StatusFlag(StatusFlag.NOT_REGISTERED).getReadable(),
        ),
        badgeLabel: I18n.t(
          "trackdechets.sync_statuses_table.cell.badges.not_registered",
        ),
        lastUpdatedAt,
      });
      break;

    default:
      badge = (
        <OverlayTrigger
          trigger={triggers}
          placement="auto"
          overlay={
            <Popover>
              <Popover.Title as="h5">
                {I18n.t(
                  "trackdechets.sync_statuses_table.cell.badges.invalid.title",
                )}
              </Popover.Title>
              <Popover.Content>
                {I18n.t(
                  "trackdechets.sync_statuses_table.cell.badges.invalid.content",
                )}
                <br />
                <ul className="mt-1 mb-0 pt-0">
                  {flagBag
                    .getFlags()
                    .map((bit) => new StatusFlag(bit))
                    .map((flag) => (
                      <li key={flag.value}>{I18n.t(flag.getReadable())}</li>
                    ))}
                </ul>
                <br />
                <small>
                  {I18n.t(
                    "trackdechets.sync_statuses_table.cell.badges.invalid.lastUpdatedAt",
                    {
                      date: formatDateTime(lastUpdatedAt),
                    },
                  )}{" "}
                </small>
              </Popover.Content>
            </Popover>
          }
        >
          <span tabIndex={1} className="badge badge-warning">
            {I18n.t(
              "trackdechets.sync_statuses_table.cell.badges.invalid.value",
            )}
          </span>
        </OverlayTrigger>
      );
      break;
  }

  return (
    <div className="trackdechets-sync-status">
      {badge}
      <button
        className="btn btn-xs refresh"
        title="Rafraîchir"
        onClick={refresh}
      >
        <i className="fa fa-refresh" />
      </button>
    </div>
  );
}
