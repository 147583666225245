import I18n from "i18n-js";
import React, { Component } from "react";
import { node } from "prop-types";

export default class ImportSitesContent extends Component {
  static propTypes = {
    downloadSample: node.isRequired,
  };

  /**
   * {@inheritdoc}
   */
  render() {
    const Content = I18n.t("Components.ImportSitesContent");

    return <Content {...this.props} />;
  }
}
