import React from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import { useAuth } from "../../authentication/contexts/useAuth";
import unlinkTrackdechets from "../qraphql/mutation/unlinkTrackdechets.graphql";

TrackdechetsStatus.propTypes = {
  userIdentity: PropTypes.shape({
    token: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }).isRequired,
};

export default function TrackdechetsStatus({ userIdentity }) {
  const { user } = useAuth();
  const [unlink] = useMutation(unlinkTrackdechets);

  function dissociateTrackdechets(user, mutation) {
    mutation({
      variables: {
        userUuid: user.uuid,
      },
    }).then(() => window.location.reload());
  }

  return (
    <div className="trackdechets-status">
      <div className="d-flex flex-column align-items-center">
        <i className="check-icon fa m-3 text-success fa-check-circle" />
        Vous êtes connecté à Trackdéchets avec le compte {userIdentity.email}
      </div>
      <div className="row justify-content-center m-3">
        <button
          className={"btn btn-danger"}
          onClick={() => dissociateTrackdechets(user, unlink)}
          role="button"
        >
          Dissocier mon compte de Trackdéchets
        </button>
      </div>
    </div>
  );
}
