import React from "react";
import { useQuery } from "@apollo/client";
import LoadingWrapper from "../../common/components/elements/LoadingWrapper";
import bsffFromShippingOut from "../graphql/queries/bsffFromShippingOut.graphql";
import BsffList from "./BsffList";
import PropTypes from "prop-types";

BsffShippingSendInformation.propTypes = {
  shippingUuid: PropTypes.string.isRequired,
};

export default function BsffShippingSendInformation({ shippingUuid }) {
  const { loading, error, data } = useQuery(bsffFromShippingOut, {
    variables: {
      uuid: shippingUuid,
    },
  });

  return (
    <LoadingWrapper loading={loading} error={Boolean(error)}>
      {() => {
        const bsffs = data.bsffFromShippingOut ?? [];

        return <BsffList bsffs={bsffs} />;
      }}
    </LoadingWrapper>
  );
}
