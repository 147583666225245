import I18n from "i18n-js";
import React from "react";
import { func, shape } from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { getShippingsList } from "../actions/shippingActions";
import { getUsersList } from "../../users/actions/usersActions";
import ShippingUtils from "../../shipping/utils/ShippingUtils";
import List from "../../common/components/layout/List";
import Table from "../../common/components/elements/Table";
import TextInput from "../../common/components/form/TextInput";
import Select from "../../common/components/form/Select";
import ShippingType from "../models/ShippingType";
import { alphabetically, uniq } from "../../common/utils/filterUtils";
import Roles from "../../users/models/Roles";
import DateInput from "../../common/components/form/DateInput";
import Modal from "../../common/components/elements/Modal";
import { fetch } from "../../../appContainer";
import DownloadButton from "../../common/components/elements/DownloadButton";
import { formatDate } from "../../common/utils/dateUtils";
import TrackdechetsShippingInformation from "../components/TrackdechetsShippingInformation";
import UserUtils from "../../users/utils/UserUtils";

class ShippingsList extends List {
  static propTypes = {
    shippings: shape().isRequired,
    users: shape().isRequired,
    userProfile: shape().isRequired,
    getShippingsList: func.isRequired,
    getUsersList: func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state.containerModal = null;
    this.state.trackDechetModal = null;
    this.shouldRenderTechFilter = props.userProfile.roles.includes(
      Roles.MANAGER,
    );

    this.dataTableRow = this.dataTableRow.bind(this);
    this.filterBySite = this.filterBySite.bind(this);
    this.getSitesFromShippings = this.getSitesFromShippings.bind(this);
  }

  /**
   * {@inheritdoc}
   */
  componentDidMount() {
    super.componentDidMount();

    const { getShippingsList, getUsersList } = this.props;

    getShippingsList();

    if (this.shouldRenderTechFilter) {
      getUsersList();
    }
  }

  getContainersByShipping(uuid) {
    const { shippings } = this.props;

    if (typeof shippings.list === "undefined" || !uuid) {
      return [];
    }

    const shipping = shippings.list.find((item) => item.uuid === uuid);

    return shipping ? shipping.containers : [];
  }

  exportToTsv() {
    return new Promise((success) => {
      fetch("api").exportShippings((tsvContent) => success(tsvContent));
    });
  }

  getSitesFromShippings() {
    const sites = {};
    const shippings = this.props.shippings.list;

    shippings.forEach((shipping) => {
      if (shipping.site) {
        sites[shipping.site.uuid] = shipping.site;
      }

      if (shipping.originSite) {
        sites[shipping.originSite.uuid] = shipping.originSite;
      }
    });

    return Object.values(sites).sort((a, b) =>
      alphabetically(a.designation, b.designation),
    );
  }

  filterBySite(value) {
    const filteredSite = this.state.filters["site"];

    if (!filteredSite) {
      // Nothing to filter
      return true;
    }

    return [value.site?.uuid, value.originSite?.uuid].includes(filteredSite);
  }

  /**
   * @return {Array}
   */
  dataTableHead() {
    return [
      I18n.t("pages.shippings.list.head.record"),
      I18n.t("pages.shippings.list.head.type"),
      I18n.t("pages.shippings.list.head.site"),
      I18n.t("pages.shippings.list.head.arianeOperationNumber"),
      I18n.t("pages.shippings.list.head.carrier"),
      I18n.t("pages.shippings.list.head.treatmentSite"),
      I18n.t("pages.shippings.list.head.containers"),
      I18n.t("pages.shippings.list.head.date"),
      I18n.t("pages.shippings.list.head.trackDechet"),
    ];
  }

  /**
   * @param {Object} rowData
   *
   * @return {Array}
   */
  dataTableRow(rowData) {
    const {
      uuid,
      record,
      site,
      originSite,
      carrier,
      treatmentSite,
      containers,
      creation,
      type,
      arianeOperation,
    } = rowData;

    const notSpecified = (
      <span className="text-muted">{I18n.t("common.not_specified")}</span>
    );

    return [
      record || notSpecified,
      I18n.t(ShippingType.readableFor(type)),
      ShippingUtils.renderShippingSitesLabel(type, site, originSite),
      arianeOperation && arianeOperation.number,
      (carrier && carrier.legalCompanyName) || notSpecified,
      (treatmentSite && treatmentSite.designation) || notSpecified,
      this.renderContainers(uuid, containers),
      formatDate(creation),
      this.renderTrackdechets(uuid),
    ];
  }

  renderTrackdechets(uuid) {
    const shipping = this.props.shippings.list.find(
      (element) => element.uuid === uuid,
    );
    const label = I18n.t("pages.shippings.list.content.trackDechet", {
      countBsff: shipping.shippedBsffs.length,
      countBsdd: shipping.shippedBsdds.length,
    });

    if (
      0 === shipping.shippedBsffs.length &&
      0 === shipping.shippedBsdds.length
    ) {
      return label;
    }

    return (
      <button
        className="more"
        onClick={() => this.setState({ trackDechetModal: uuid })}
      >
        {label}
      </button>
    );
  }

  renderTrackdechetsModal() {
    const { trackDechetModal } = this.state;

    return (
      <Modal
        isOpen={trackDechetModal !== null}
        title={I18n.t("pages.shippings.list.trackDechet_modal.title")}
        onClose={() => this.setState({ trackDechetModal: null })}
      >
        <TrackdechetsShippingInformation shippingUuid={trackDechetModal} />
      </Modal>
    );
  }

  renderContainers(uuid, containers) {
    const label = I18n.t("pages.shippings.list.content.containers", {
      count: containers.length,
    });

    if (0 === containers.length) {
      // Don't render link when there is no container
      return label;
    }

    return (
      <button
        className="more"
        onClick={() => this.setState({ containerModal: uuid })}
      >
        {label}
      </button>
    );
  }

  renderContainerModal() {
    const { containerModal } = this.state;

    return (
      <Modal
        isOpen={containerModal !== null}
        title={I18n.t("pages.shippings.list.containers_modal.title")}
        onClose={() => this.setState({ containerModal: null })}
        className="big"
      >
        <Table
          data={this.getContainersByShipping(containerModal)}
          headData={[
            I18n.t("pages.shippings.list.containers_modal.container.barcode"),
            I18n.t(
              "pages.shippings.list.containers_modal.container.designation",
            ),
            I18n.t("pages.shippings.list.containers_modal.container.fluids"),
            I18n.t("pages.shippings.list.containers_modal.container.volume"),
            // TODO: uncomment once quantity is denormalized on API-side
            // I18n.t('pages.shippings.list.containers_modal.container.currentLoad'),
            I18n.t("pages.shippings.list.containers_modal.container.fibsd"),
            I18n.t("pages.shippings.list.containers_modal.container.annex"),
          ]}
          className=""
          rowData={(container) => {
            const {
              barcode,
              designation,
              currentFluidUuids,
              volume,
              // eslint-disable-next-line no-unused-vars
              currentLoad,
              fibsd,
              annex,
            } = container;

            return [
              barcode,
              designation,
              currentFluidUuids.join(", "),
              volume,
              // currentLoad, // TODO: uncomment once quantity is denormalized on API-side
              fibsd && (
                <a
                  href={`${fetch("api_host")}/fibsd/${fibsd}.pdf`}
                  target="blank"
                >
                  {I18n.t("pages.interventions.list.head.FIBSD")}
                </a>
              ),
              annex && (
                <a
                  href={`${fetch("api_host")}/annex/${annex}.pdf`}
                  target="blank"
                >
                  {I18n.t("pages.interventions.list.head.annex")}
                </a>
              ),
            ];
          }}
        />
      </Modal>
    );
  }

  /**
   * @param {Object} option
   *
   * @return {Element}
   */
  renderFilterTypeOption(option) {
    return (
      <option key={option.type} value={option.type}>
        {I18n.t(ShippingType.readableFor(option.type))}
      </option>
    );
  }

  /**
   * @param {Object} option
   *
   * @return {Element}
   */
  renderFilterUserOption(option) {
    return (
      <option key={`option-${option.uuid}`} value={option.uuid}>
        {UserUtils.renderLabel(option)}
      </option>
    );
  }

  /**
   * @return {Array}
   */
  renderFilters() {
    const { shippings, users } = this.props;
    const { filters } = this.state;

    // Select type options
    const typeOptions = uniq(shippings.list, "type");

    const allFilters = [
      <Select
        key="filterBySite"
        id="filterBySite"
        onChange={(event) => this.onChangeFilter(event, "site")}
        label={I18n.t("filters.by_site")}
        renderOption={(option) => (
          <option key={option.uuid} value={option.uuid}>
            {option.designation}
          </option>
        )}
        options={this.getSitesFromShippings()}
        defaultValue={filters.site}
        placeholder={I18n.t("filters.by_site:default")}
        optional
      />,
      <TextInput
        key="filterByObservation"
        id="filterByObservation"
        defaultValue={filters.observations}
        onChange={(event) => this.onChangeFilter(event, "observations")}
        label={I18n.t("filters.by_observation")}
        optional
      />,
      <Select
        key="filterByType"
        id="filterByType"
        defaultValue={filters.type}
        onChange={(event) => this.onChangeFilter(event, "type")}
        label={I18n.t("filters.by_type")}
        renderOption={this.renderFilterTypeOption}
        options={typeOptions}
        placeholder={I18n.t("filters.by_type:default")}
        optional
      />,
      <DateInput
        key="filterByStartDate"
        id="filterByStartDate"
        defaultValue={filters.startDate && new Date(Number(filters.startDate))}
        onChange={(date) => this.onChangeDateFilter(date, "startDate")}
        label={I18n.t("filters.by_start_date")}
        legend
        optional
      />,
      <DateInput
        key="filterByEndDate"
        id="filterByEndDate"
        defaultValue={filters.endDate && new Date(Number(filters.endDate))}
        onChange={(date) => this.onChangeDateFilter(date, "endDate")}
        label={I18n.t("filters.by_end_date")}
        legend
        optional
      />,
    ];

    if (this.shouldRenderTechFilter) {
      // Select user options
      const userOptions = uniq(shippings.list, "userUuid")
        .map((shipping) =>
          users.list.find((user) => user.uuid === shipping.userUuid),
        )
        .filter((a) => a)
        .sort((a, b) => alphabetically(a.lastname, b.lastname));
      allFilters.push(
        <Select
          key="filterByUser"
          id="filterByUser"
          onChange={(event) => this.onChangeFilter(event, "user")}
          label={I18n.t("filters.by_user")}
          renderOption={this.renderFilterUserOption}
          options={userOptions}
          placeholder={I18n.t("filters.by_user:default")}
          optional
        />,
      );
    }

    return allFilters;
  }

  /**
   * Render content page
   */
  renderContent() {
    const { shippings, users } = this.props;

    if (
      shippings.inProgress ||
      (this.shouldRenderTechFilter && users.inProgress)
    ) {
      return <div className="loader" />;
    }

    if (shippings.error || (this.shouldRenderTechFilter && users.error)) {
      return <p>{I18n.t("common.loadingApiError")}</p>;
    }

    const list = shippings.list
      .filter(this.getFilterByText("observations"))
      .filter(this.getFilterBySelect("type", false))
      .filter(this.getFilterByDate("creation"))
      .filter(this.getFilterBySelect("user"))
      .filter(this.filterBySite);
    return (
      <div>
        <Table
          emptyContent={I18n.t("pages.shippings.list.empty")}
          data={list}
          headData={this.dataTableHead()}
          rowData={this.dataTableRow}
          filters={this.renderFilters()}
          pagination
          onPageChanged={this.onPageChanged}
          initialPage={this.getCurrentPage()}
          actions={
            <DownloadButton
              fileContent={this.exportToTsv}
              filename={`climapp_shippings_${moment().format(
                "YYYY-MM-DD",
              )}.tsv`}
              label={I18n.t("common.actions.export")}
              contentType="text/csv;charset=utf8;"
            />
          }
        />
        {this.renderContainerModal()}
        {this.renderTrackdechetsModal()}
      </div>
    );
  }

  /**
   * {@inheritdoc}
   */
  renderTitle() {
    return I18n.t("pages.shippings.list.title");
  }
}

const mapStateToProps = (state) => ({
  shippings: state.shippings,
  users: state.users,
  userProfile: state.auth.userProfile,
});

const mapDispatchToProps = (dispatch) => ({
  getShippingsList: () => dispatch(getShippingsList()),
  getUsersList: () => dispatch(getUsersList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShippingsList);
