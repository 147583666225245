import React, { Component } from "react";
import { string, bool, arrayOf, oneOfType, array } from "prop-types";

class PreviewableImage extends Component {
  static propTypes = {
    id: string.isRequired,
    label: string.isRequired,
    optional: bool,
    defaultValue: string,
    accept: arrayOf(string),
    error: oneOfType([bool, string, array]),
    inline: bool,
  };

  static defaultProps = {
    defaultValue: null,
    optional: false,
    accept: ["image/png", "image/jpeg"],
    error: null,
    inline: false,
  };

  constructor(props) {
    super(props);

    const existingImage = props.defaultValue ? props.defaultValue : null;

    this.state = {
      imageData: existingImage,
      errorContent: props.error,
    };

    this.onChange = this.onChange.bind(this);
  }

  /**
   * {@inheritdoc}
   */
  componentDidUpdate(prevProps) {
    const { error } = this.props;

    if (prevProps.error !== error) {
      this.setState({ errorContent: error });
    }
  }

  onChange(event) {
    event.preventDefault();

    const reader = new FileReader();
    const file = event.target.files[0];

    reader.onloadend = () => {
      this.setState({
        imageData: reader.result,
      });
    };

    this.setState({ errorContent: null });

    reader.readAsDataURL(file);
  }

  /**
   * @return {String}
   */
  get value() {
    return this.state.imageData;
  }

  /**
   * @param {String} value
   */
  set value(value) {
    this.setState({ imageData: value });
  }

  renderErrors() {
    const { errorContent } = this.state;

    if (!errorContent || typeof errorContent === "boolean") {
      return null;
    }

    return (
      <div className="invalid-feedback" style={{ display: "block" }}>
        {[].concat(errorContent).map((error) => (
          <p key={error}>{error}</p>
        ))}
      </div>
    );
  }

  /**
   * {@inheritdoc}
   */
  render() {
    const { id, label, optional, accept, inline } = this.props;

    const { imageData } = this.state;

    const image = imageData && (
      <div className="image">
        <img src={imageData} height="80" alt="" />
      </div>
    );

    return (
      <div className="form-group">
        <label htmlFor={id} className="custom-file-input">
          {label}
          {optional ? "" : "*"}
        </label>
        <div className={inline ? "previewable-image" : ""}>
          {image}
          <div className="custom-file">
            <input
              type="file"
              accept={accept.join(", ")}
              className="custom-file-input"
              id={id}
              ref={(input) => {
                this.input = input;
              }}
              onChange={this.onChange}
            />
            {this.renderErrors()}
          </div>
        </div>
      </div>
    );
  }
}

export default PreviewableImage;
