import "isomorphic-fetch";
import I18n from "i18n-js";
import pathToRegexp from "path-to-regexp";
import { logout } from "../../authentication/actions/authActions";
import DisconnectError from "../utils/DisconnectError";

class ApiClient {
  /**
   * Default headers
   *
   * @type {Object}
   */
  static headers = {
    Accept: "application/x.k2.api+json;v=1.0",
    "Content-Type": "application/json",
  };

  /**
   * Available API routes
   *
   */
  static ROUTES = {
    carriers: "/carriers",
    carrierInfos: "/carriers/:uuid",
    clients: "/clients",
    clientInfo: "/clients/:uuid",
    clientsImport: "/import/clients",
    clientSearchServiceBeneficiary: "/clients/search-service-beneficiary",
    clientServiceProviderContract: "/clients/:uuid/service-provider-contracts",
    sites: "/sites",
    siteInfos: "/sites/:uuid",
    sitesImport: "/import/sites",
    installations: "/installations",
    installationsImport: "/import/installations",
    leakDetectors: "/leak-detectors",
    leakDetectorInfos: "/leak-detectors/:uuid",
    containers: "/containers",
    containersExport: "/containers.tsv",
    login: "/login",
    userProfile: "/users/profiles/me",
    users: "/users",
    userInfos: "/users/:uuid",
    lockUser: "/users/:uuid/lock",
    unlockUser: "/users/:uuid/unlock",
    forgotPassword: "/users/:email/forgot-password",
    changePassword: "/users/:email/password",
    changePasswordForUser: "/users/:uuid/change-password",
    acceptTerms: "/users/profiles/accept-terms",
    interventions: "/interventions",
    interventionsExport: "/interventions.tsv",
    interventionInfo: "/interventions/:uuid",
    shippings: "/shippings",
    fibsds: "/fibsds",
    fibsdInfo: "/fibsds/:uuid",
    completeFibsd: "/fibsds/:uuid/complete",
    fluidTreatmentMode: "/nomenclature/fluidTreatmentMode",
    providers: "/service-provider-contracts",
    updateProviderContract: "/service-provider-contracts/:uuid",
    acceptProviderContract: "/service-provider-contracts/:uuid/accept",
    refuseProviderContract: "/service-provider-contracts/:uuid/refuse",
    fluidSummaries: "/dashboard/fluids/summaries",
    dashboardMetrics: "/dashboard/metrics",
    exportFluids: "/export/fluids.tsv",
    exportShippings: "/export/shippings.tsv",
    enterprise: "/enterprise/:uuid",
    enterpriseLogo: "/enterprise/:uuid/logo",
  };

  /**
   * @param {String} host
   * @param {Object} store
   */
  constructor(host, store) {
    this.host = host;
    this.store = store;
    this.token = null;

    this.setToken = this.setToken.bind(this);
    this.onError = this.onError.bind(this);
  }

  /**
   * Get providers list
   *
   * @param {Function} success
   * @param {Function} failure
   *
   * @return {Array}
   */
  getProvidersList(success, failure) {
    this.call("GET", ApiClient.ROUTES.providers).then((result) =>
      result.ok ? success(result.data) : failure(result.data),
    );
  }

  /**
   * Post Search service beneficiary
   *
   * @param {String}   enterpriseIdentifier
   * @param {Function} success
   * @param {Function} failure
   *
   * @return {Array}
   */
  postSearchServiceBeneficiary(enterpriseIdentifier, success, failure) {
    this.call("POST", ApiClient.ROUTES.clientSearchServiceBeneficiary, {
      enterpriseIdentifier,
    }).then((result) =>
      result.ok ? success(result.data) : failure(result.data),
    );
  }

  /**
   * Post Service provider contract
   *
   * @param {String}   uuid
   * @param {Function} success
   * @param {Function} failure
   *
   * @return {Array}
   */
  postServiceProviderContract(uuid, success, failure) {
    const route = pathToRegexp.compile(
      ApiClient.ROUTES.clientServiceProviderContract,
    );

    this.call("POST", route({ uuid })).then((result) =>
      result.ok ? success(result.data) : failure(result.data),
    );
  }

  /**
   * Patch Service provider contract
   *
   * @param {String}   uuid
   * @param {Object}   body
   * @param {Function} success
   * @param {Function} failure
   *
   * @return {Array}
   */
  putServiceProviderContract(uuid, body, success, failure) {
    const route = pathToRegexp.compile(ApiClient.ROUTES.updateProviderContract);

    this.call("PUT", route({ uuid }), body).then((result) =>
      result.ok ? success(result.data) : failure(result.data),
    );
  }

  /**
   * Patch Service provider contract
   *
   * @param {String}   uuid
   * @param {Object}   body
   * @param {Function} success
   * @param {Function} failure
   *
   * @return {Array}
   */
  patchServiceProviderContract(uuid, body, success, failure) {
    const route = pathToRegexp.compile(ApiClient.ROUTES.acceptProviderContract);

    this.call("PATCH", route({ uuid }), body).then((result) =>
      result.ok ? success(result.data) : failure(result.data),
    );
  }

  /**
   * Refuse Service provider contract
   *
   * @param {String}   uuid
   * @param {Function} success
   * @param {Function} failure
   *
   * @return {Array}
   */
  refuseServiceProviderContract(uuid, success, failure) {
    const route = pathToRegexp.compile(ApiClient.ROUTES.refuseProviderContract);

    this.call("PATCH", route({ uuid })).then((result) =>
      result.ok ? success(result.data) : failure(result.data),
    );
  }

  /**
   * Get new token by email to change password
   *
   * @param {String}   email
   * @param {Function} success
   * @param {Function} failure
   */
  forgotPassword(email, success, failure) {
    const route = pathToRegexp.compile(ApiClient.ROUTES.forgotPassword);

    this.call("POST", route({ email }), null, {}).then((result) =>
      result.ok ? success() : failure(result.data),
    );
  }

  /**
   * Change password
   *
   * @param {String}   token
   * @param {String}   email
   * @param {String}   newPassword
   * @param {Function} success
   * @param {Function} failure
   */
  changePassword(token, email, newPassword, success, failure) {
    const route = pathToRegexp.compile(ApiClient.ROUTES.changePassword);

    this.call("PUT", route({ email }), { token, newPassword }, {}).then(
      (result) => (result.ok ? success() : failure(result.data)),
    );
  }

  /**
   * Change password for another user as an admin
   *
   * @param {String}   uuid
   * @param {String}   newPassword
   * @param {String}   passwordConfirm
   * @param {Function} success
   * @param {Function} failure
   */
  changePasswordForUser(
    { uuid, newPassword, passwordConfirm },
    success,
    failure,
  ) {
    const route = pathToRegexp.compile(ApiClient.ROUTES.changePasswordForUser);

    this.call("PATCH", route({ uuid }), { newPassword, passwordConfirm }).then(
      (result) => (result.ok ? success() : failure(result.data)),
    );
  }

  /**
   * Accept terms
   *
   * @param {Function} success
   * @param {Function} failure
   */
  acceptTerms(success, failure) {
    this.call("POST", ApiClient.ROUTES.acceptTerms).then((result) =>
      result.ok ? success() : failure(result.data),
    );
  }

  /**
   * Get users list
   *
   * @param {Function} success
   * @param {Function} failure
   *
   * @return {Array}
   */
  getUsersList(success, failure) {
    this.call("GET", ApiClient.ROUTES.users).then((result) =>
      result.ok ? success(result.data) : failure(result.data),
    );
  }

  /**
   * Create user
   *
   * @param {Object}   user
   * @param {Function} success
   * @param {Function} failure
   *
   * @return {Object}
   */
  createUser(user, success, failure) {
    this.call("POST", ApiClient.ROUTES.users, user).then((result) =>
      result.ok ? success(result.data) : failure(result.data),
    );
  }

  /**
   * Get user infos
   *
   * @param {String}   uuid
   * @param {Function} success
   * @param {Function} failure
   *
   * @return {Object}
   */
  getUserInfos(uuid, success, failure) {
    const route = pathToRegexp.compile(ApiClient.ROUTES.userInfos);

    this.call("GET", route({ uuid })).then((result) =>
      result.ok ? success(result.data) : failure(result.data),
    );
  }

  /**
   * Update user
   *
   * @param {String}   uuid
   * @param {Object}   user
   * @param {Function} success
   * @param {Function} failure
   *
   * @return {Object}
   */
  updateUser(uuid, user, success, failure) {
    const route = pathToRegexp.compile(ApiClient.ROUTES.userInfos);

    this.call("PUT", route({ uuid }), user).then((result) =>
      result.ok ? success(result.data) : failure(result.data),
    );
  }

  /**
   * Lock user
   *
   * @param {String}   uuid
   * @param {Function} success
   * @param {Function} failure
   *
   * @return {Object}
   */
  lockUser(uuid, success, failure) {
    const route = pathToRegexp.compile(ApiClient.ROUTES.lockUser);

    this.call("PATCH", route({ uuid })).then((result) =>
      result.ok ? success() : failure(result.data),
    );
  }

  /**
   * Unlock user
   *
   * @param {String}   uuid
   * @param {Function} success
   * @param {Function} failure
   *
   * @return {Object}
   */
  unlockUser(uuid, success, failure) {
    const route = pathToRegexp.compile(ApiClient.ROUTES.unlockUser);

    this.call("PATCH", route({ uuid })).then((result) =>
      result.ok ? success() : failure(result.data),
    );
  }

  /**
   * Create client
   *
   * @param {Object}   client
   * @param {Function} success
   * @param {Function} failure
   *
   * @return {Object}
   */
  createClient(client, success, failure) {
    this.call("POST", ApiClient.ROUTES.clients, client).then((result) =>
      result.ok ? success(result.data) : failure(result.data),
    );
  }

  /**
   * Update client
   *
   * @param {String}   uuid
   * @param {Object}   client
   * @param {Function} success
   * @param {Function} failure
   *
   * @return {Object}
   */
  updateClient(uuid, client, success, failure) {
    const route = pathToRegexp.compile(ApiClient.ROUTES.clientInfo);

    this.call("PUT", route({ uuid }), client).then((result) =>
      result.ok ? success(result.data) : failure(result.data),
    );
  }

  /**
   * Delete client
   *
   * @param {String}   uuid
   * @param {Function} success
   * @param {Function} failure
   *
   * @return {Object}
   */
  deleteClient(uuid, success, failure) {
    const route = pathToRegexp.compile(ApiClient.ROUTES.clientInfo);

    this.call("DELETE", route({ uuid })).then((result) =>
      result.ok ? success(result.data) : failure(result.data),
    );
  }

  /**
   * Import final clients
   *
   * @param {String}   strategy (see ImportFinalClientStrategy)
   * @param {String}   tsv      Raw TSV string
   * @param {Function} success
   * @param {Function} failure
   *
   * @return {Object}
   */
  importClients(strategy, tsv, success, failure) {
    this.call("POST", ApiClient.ROUTES.clientsImport, { strategy, tsv }).then(
      (result) =>
        result.ok
          ? success(result.data, result.response)
          : failure(result.data, result.response),
    );
  }

  /**
   * Get leak detectors list
   *
   * @param {Function} success
   * @param {Function} failure
   *
   * @return {Array}
   */
  getLeakDetectorsList(success, failure) {
    this.call("GET", ApiClient.ROUTES.leakDetectors).then((result) =>
      result.ok ? success(result.data) : failure(result.data),
    );
  }

  /**
   * Get containers list
   *
   * @param {Function} success
   * @param {Function} failure
   *
   * @return {Array}
   */
  getContainersList(success, failure) {
    this.call("GET", ApiClient.ROUTES.containers).then((result) =>
      result.ok ? success(result.data) : failure(result.data),
    );
  }

  /**
   * Call containers tsv export (POST)
   *
   * @param {String[]} uuids
   * @param {Function} success
   * @param {Function} failure
   *
   * @return {String} The TSV file content
   */
  exportContainers(uuids, success, failure) {
    this.call("POST", ApiClient.ROUTES.containersExport, { uuids }).then(
      (result) => (result.ok ? success(result.data) : failure(result.data)),
    );
  }

  /**
   * Get fluids tsv export (for installations import purpose)
   *
   * @param {Function} success
   * @param {Function} failure
   *
   * @return {String} The TSV file content
   */
  exportFluids(success, failure) {
    this.call("GET", ApiClient.ROUTES.exportFluids).then((result) =>
      result.ok ? success(result.data, result.response) : failure(result.data),
    );
  }

  /**
   * Get shippings tsv export
   *
   * @param {Function} success
   * @param {Function} failure
   *
   * @return {String} The TSV file content
   */
  exportShippings(success, failure) {
    this.call("GET", ApiClient.ROUTES.exportShippings).then((result) =>
      result.ok ? success(result.data, result.response) : failure(result.data),
    );
  }

  /**
   * Get interventions list
   *
   * @param {Function} success
   * @param {Function} failure
   *
   * @return {Array}
   */
  getInterventionList(success, failure) {
    this.call("GET", ApiClient.ROUTES.interventions).then((result) =>
      result.ok && Array.isArray(result.data)
        ? success(result.data)
        : failure(result.data),
    );
  }

  /**
   * Get intervention info
   *
   * @param {String}   uuid
   * @param {Function} success
   * @param {Function} failure
   *
   * @return {Object}
   */
  getInterventionInfo(uuid, success, failure) {
    const route = pathToRegexp.compile(ApiClient.ROUTES.interventionInfo);

    this.call("GET", route({ uuid })).then((result) =>
      result.ok ? success(result.data) : failure(result.data),
    );
  }

  /**
   * Call interventions tsv export (POST)
   *
   * @param {String[]} uuids
   * @param {Function} success
   * @param {Function} failure
   *
   * @return {String} The TSV file content
   */
  exportInterventions(uuids, success, failure) {
    this.call("POST", ApiClient.ROUTES.interventionsExport, { uuids }).then(
      (result) => (result.ok ? success(result.data) : failure(result.data)),
    );
  }

  /**
   * Get completable FIBSDs list
   *
   * @param {Function} success
   * @param {Function} failure
   *
   * @return {Array}
   */
  getFibsdList(success, failure) {
    this.call("GET", ApiClient.ROUTES.fibsds).then((result) =>
      result.ok ? success(result.data) : failure(result.data),
    );
  }

  /**
   * Get fluid summaries list
   *
   * @param {Date}   fromDate
   * @param {Date}   toDate
   * @param {Function} success
   * @param {Function} failure
   *
   * @return {Array}
   */
  getFluidSummariesList(fromDate, toDate, success, failure) {
    const url = `${
      ApiClient.ROUTES.fluidSummaries
    }?from=${fromDate.toISOString()}&to=${toDate.toISOString()}`;

    this.call("GET", url).then((result) =>
      result.ok ? success(result.data) : failure(result.data),
    );
  }

  /**
   * Get dashboard metrics
   *
   * @param {Date|null} interventionsSince
   * @param {Function} success
   * @param {Function} failure
   *
   * @return {Array}
   */
  getDashboardMetrics(interventionsSince, success, failure) {
    let url = ApiClient.ROUTES.dashboardMetrics;
    if (url !== null) {
      url += `?interventionsSince=${interventionsSince.toISOString()}`;
    }
    this.call("GET", url).then((result) =>
      result.ok ? success(result.data) : failure(result.data),
    );
  }

  /**
   * Get FIBSDs list
   *
   * @param {String}   uuid
   * @param {Function} success
   * @param {Function} failure
   *
   * @return {Array}
   */
  getFibsdInfo(uuid, success, failure) {
    this.call(
      "GET",
      pathToRegexp.compile(ApiClient.ROUTES.fibsdInfo)({ uuid }),
    ).then((result) =>
      result.ok ? success(result.data) : failure(result.data),
    );
  }

  /**
   * Complete FIBSD
   *
   * @param {String}   uuid
   * @param {Object}   fibsd
   * @param {Function} success
   * @param {Function} failure
   */
  completeFibsd(uuid, fibsd, success, failure) {
    const route = pathToRegexp.compile(ApiClient.ROUTES.completeFibsd);

    this.call("PATCH", route({ uuid }), fibsd).then((result) =>
      result.ok ? success(result.data) : failure(result.data),
    );
  }

  /**
   * Get Fluid Treatment Modes list
   *
   * @param {Function} success
   * @param {Function} failure
   *
   * @return {Array}
   */
  getFluidTreatmentModes(success, failure) {
    this.call("GET", ApiClient.ROUTES.fluidTreatmentMode).then((result) =>
      result.ok ? success(result.data) : failure(result.data),
    );
  }

  /**
   * Get shippings list
   *
   * @param {Function} success
   * @param {Function} failure
   *
   * @return {Array}
   */
  getShippingsList(success, failure) {
    this.call("GET", ApiClient.ROUTES.shippings).then((result) =>
      result.ok && Array.isArray(result.data)
        ? success(result.data)
        : failure(result.data),
    );
  }

  /**
   * Get leak detector infos
   *
   * @param {String}   uuid
   * @param {Function} success
   * @param {Function} failure
   *
   * @return {Object}
   */
  getLeakDetectorInfos(uuid, success, failure) {
    const route = pathToRegexp.compile(ApiClient.ROUTES.leakDetectorInfos);

    this.call("GET", route({ uuid })).then((result) =>
      result.ok ? success(result.data) : failure(result.data),
    );
  }

  /**
   * Create leak detector
   *
   * @param {Object}   data    The detector data
   * @param {Function} success
   * @param {Function} failure
   *
   * @return {Array}
   */
  createLeakDetector(data, success, failure) {
    this.call("POST", ApiClient.ROUTES.leakDetectors, data).then((result) =>
      result.ok ? success() : failure(result.data),
    );
  }

  /**
   * Update leak detector
   *
   * @param {String}   uuid
   * @param {Object}   data    The detector data
   * @param {Function} success
   * @param {Function} failure
   *
   * @return {Object}
   */
  updateLeakDetector(uuid, data, success, failure) {
    const route = pathToRegexp.compile(ApiClient.ROUTES.leakDetectorInfos);

    this.call("PUT", route({ uuid }), data).then((result) =>
      result.ok ? success(result.data) : failure(result.data),
    );
  }

  /**
   * Delete leak detector
   *
   * @param {String}   uuid
   * @param {Function} success
   * @param {Function} failure
   *
   * @return {Object}
   */
  deleteLeakDetector(uuid, success, failure) {
    const route = pathToRegexp.compile(ApiClient.ROUTES.leakDetectorInfos);

    this.call("DELETE", route({ uuid })).then((result) =>
      result.ok ? success(result.data) : failure(result.data),
    );
  }

  /**
   * Get carriers list
   *
   * @param {Function} success
   * @param {Function} failure
   *
   * @return {Array}
   */
  getCarriersList(success, failure) {
    this.call("GET", ApiClient.ROUTES.carriers).then((result) =>
      result.ok ? success(result.data) : failure(result.data),
    );
  }

  /**
   * Get carrier infos
   *
   * @param {String}   uuid
   * @param {Function} success
   * @param {Function} failure
   *
   * @return {Object}
   */
  getCarrierInfos(uuid, success, failure) {
    const route = pathToRegexp.compile(ApiClient.ROUTES.carrierInfos);

    this.call("GET", route({ uuid })).then((result) =>
      result.ok ? success(result.data) : failure(result.data),
    );
  }

  /**
   * Create carrier
   *
   * @param {Object}   carrier
   * @param {Function} success
   * @param {Function} failure
   *
   * @return {Object}
   */
  createCarrier(carrier, success, failure) {
    this.call("POST", ApiClient.ROUTES.carriers, carrier).then((result) =>
      result.ok ? success(result.data) : failure(result.data),
    );
  }

  /**
   * Update carrier
   *
   * @param {String}   uuid
   * @param {Object}   carrier
   * @param {Function} success
   * @param {Function} failure
   *
   * @return {Object}
   */
  updateCarrier(uuid, carrier, success, failure) {
    const route = pathToRegexp.compile(ApiClient.ROUTES.carrierInfos);

    this.call("PUT", route({ uuid }), carrier).then((result) =>
      result.ok ? success(result.data) : failure(result.data),
    );
  }

  /**
   * Delete carrier
   *
   * @param {String}   uuid
   * @param {Function} success
   * @param {Function} failure
   *
   * @return {Object}
   */
  deleteCarrier(uuid, success, failure) {
    const route = pathToRegexp.compile(ApiClient.ROUTES.carrierInfos);

    this.call("DELETE", route({ uuid })).then((result) =>
      result.ok ? success(result.data) : failure(result.data),
    );
  }

  /**
   * Get installations list
   *
   * @param {Function} success
   * @param {Function} failure
   *
   * @return {Array}
   */
  getInstallationsList(success, failure) {
    this.call("GET", ApiClient.ROUTES.installations).then((result) =>
      result.ok ? success(result.data) : failure(result.data),
    );
  }

  /**
   * Import installations
   *
   * @param {String}   tsv      Raw TSV string
   * @param {Function} success
   * @param {Function} failure
   *
   * @return {Object}
   */
  importInstallations(tsv, success, failure) {
    this.call(
      "POST",
      ApiClient.ROUTES.installationsImport,
      tsv,
      this.getAuthHeader(),
      true,
    ).then((result) =>
      result.ok
        ? success(result.data, result.response)
        : failure(result.data, result.response),
    );
  }

  /**
   * Get sites list
   *
   * @param {Function} success
   * @param {Function} failure
   *
   * @return {Array}
   */
  getSitesList(success, failure) {
    this.call("GET", ApiClient.ROUTES.sites).then((result) =>
      result.ok ? success(result.data) : failure(result.data),
    );
  }

  /**
   * Get site infos
   *
   * @param {String}   uuid
   * @param {Function} success
   * @param {Function} failure
   *
   * @return {Object}
   */
  getSiteInfos(uuid, success, failure) {
    const route = pathToRegexp.compile(ApiClient.ROUTES.siteInfos);

    this.call("GET", route({ uuid })).then((result) =>
      result.ok ? success(result.data) : failure(result.data),
    );
  }

  /**
   * Create site
   *
   * @param {Object}   site
   * @param {Function} success
   * @param {Function} failure
   *
   * @return {Object}
   */
  createSite(site, success, failure) {
    this.call("POST", ApiClient.ROUTES.sites, site).then((result) =>
      result.ok ? success(result.data) : failure(result.data),
    );
  }

  /**
   * Update site
   *
   * @param {String}   uuid
   * @param {Object}   site
   * @param {Function} success
   * @param {Function} failure
   *
   * @return {Object}
   */
  updateSite(uuid, site, success, failure) {
    const route = pathToRegexp.compile(ApiClient.ROUTES.siteInfos);

    this.call("PUT", route({ uuid }), site).then((result) =>
      result.ok ? success(result.data) : failure(result.data),
    );
  }

  /**
   * Delete site
   *
   * @param {String}   uuid
   * @param {Function} success
   * @param {Function} failure
   *
   * @return {Object}
   */
  deleteSite(uuid, success, failure) {
    const route = pathToRegexp.compile(ApiClient.ROUTES.siteInfos);

    this.call("DELETE", route({ uuid })).then((result) =>
      result.ok ? success(result.data) : failure(result.data),
    );
  }

  /**
   * Import sites
   *
   * @param {String}   tsv      Raw TSV string
   * @param {Function} success
   * @param {Function} failure
   *
   * @return {Object}
   */
  importSites(tsv, success, failure) {
    this.call(
      "POST",
      ApiClient.ROUTES.sitesImport,
      tsv,
      this.getAuthHeader(),
      true,
    ).then((result) =>
      result.ok
        ? success(result.data, result.response)
        : failure(result.data, result.response),
    );
  }

  /**
   * Get clients list
   *
   * @param {Function} success
   * @param {Function} failure
   *
   * @return {Array}
   */
  getClientsList(success, failure) {
    this.call("GET", ApiClient.ROUTES.clients).then((result) =>
      result.ok ? success(result.data) : failure(result.data),
    );
  }

  /**
   * Get client info
   *
   * @param {String}   uuid
   * @param {Function} success
   * @param {Function} failure
   *
   * @return {Object}
   */
  getClientInfo(uuid, success, failure) {
    const route = pathToRegexp.compile(ApiClient.ROUTES.clientInfo);

    this.call("GET", route({ uuid })).then((result) =>
      result.ok ? success(result.data) : failure(result.data),
    );
  }

  getEnterpriseLogo(uuid, success, failure) {
    const route = pathToRegexp.compile(ApiClient.ROUTES.enterpriseLogo);

    this.call("GET", route({ uuid })).then((result) =>
      result.ok ? success(result.data) : failure(result.data),
    );
  }

  updateEnterprise(uuid, enterprise, success, failure) {
    const route = pathToRegexp.compile(ApiClient.ROUTES.enterprise);

    this.call("PUT", route({ uuid }), enterprise).then((result) =>
      result.ok ? success(result.data) : failure(result.data),
    );
  }

  /**
   * Get JWT access token by login
   *
   * @param {Object}   credentials
   * @param {Function} success
   * @param {Function} failure
   *
   * @return {Object}
   */
  login(credentials, success, failure) {
    this.call("POST", ApiClient.ROUTES.login, credentials, {}).then((result) =>
      result.ok ? success(result.data) : failure(result.data),
    );
  }

  /**
   * Get current authenticated user profile
   *
   * @param {Function} success
   * @param {Function} failure
   *
   * @return {Object}
   */
  getUserProfile(success, failure) {
    this.call("GET", ApiClient.ROUTES.userProfile, null).then((result) =>
      result.ok ? success(result.data) : failure(result.data),
    );
  }

  /**
   * Get authorization header
   *
   * @return {Object}
   */
  getAuthHeader() {
    return {
      Authorization: `Bearer ${this.token}`,
    };
  }

  /**
   * Create an HTTP call
   *
   * @param {String}             method
   * @param {String}             path
   * @param {Object|String|null} data
   * @param {Object}             headers
   * @param {Boolean}            raw If true, will send data as a raw string (not json encoded)
   *
   * @return {Promise}
   */
  call(method, path, data = null, headers = this.getAuthHeader(), raw = false) {
    let ok;
    let originalResponse;
    const url = `${this.host}${path}`;
    const controller = new AbortController();
    const params = {
      headers: Object.assign(headers, ApiClient.headers, {
        "Accept-Language": I18n.locale,
      }),
      method,
      signal: controller.signal,
    };

    if (data) {
      params.body = raw ? data : JSON.stringify(data);
    }

    console.info("API call:", url, params);

    return fetch(url, params)
      .then((response) => {
        ok = response.ok;
        originalResponse = response;

        const contentTypeHeaders = response.headers.get("Content-Type");
        const contentType = contentTypeHeaders
          ? contentTypeHeaders.toLowerCase().split(";")[0]
          : null;

        switch (true) {
          case "application/json" === contentType:
          case "application/problem+json" === contentType:
            return response
              .clone()
              .text()
              .then((value) => {
                if (value.length === 0) {
                  return null;
                }

                return response.json();
              });

          case "text/csv; charset=ISO-8859-1" ===
            response.headers.get("Content-Type"):
            return response.blob();

          default:
            return response.text();
        }
      })
      .then((responseData) => {
        if (
          responseData &&
          responseData.code === 401 &&
          path !== ApiClient.ROUTES.login
        ) {
          this.store.dispatch(logout(true));

          throw new DisconnectError(responseData.message);
        }

        return {
          ok,
          data: responseData,
          response: originalResponse,
        };
      })
      .catch((error) => {
        controller.abort();
        console.warn("Aborting request", { error });

        return this.onError(error);
      });
  }

  onError(error) {
    return { ok: false, data: error };
  }

  /**
   * Set authentication token
   *
   * @param {String} token
   */
  setToken(token) {
    this.token = token;
  }
}

export default ApiClient;
