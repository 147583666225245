import ImportInstallationsContent from "../modules/installation/pages/ImportInstallations/en";
import ImportSitesContent from "../modules/company/pages/ImportSites/en";
import ImportClientsContent from "../modules/company/pages/ImportClients/en";
import PrivacyPolicy from "../modules/common/pages/PrivacyPolicy/en";
import CGU from "../modules/common/pages/CGU/en";

export default {
  ImportInstallationsContent,
  ImportSitesContent,
  ImportClientsContent,
  PrivacyPolicy,
  CGU,
};
