import { useEffect, useMemo } from "react";
import { useHistory, useLocation } from "react-router";
import { ROUTE_TRACKDECHETS_OAUTH_STATUS } from "../../../routes";
import { useMutation } from "@apollo/client";
import linkTrackdechetsMutation from "../qraphql/mutation/linkTrackdechets.graphql";

export default function TrackdechetsCallback() {
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);

  const [mutate] = useMutation(linkTrackdechetsMutation, {
    variables: {
      authorizationCode: query.get("code"),
    },
  });

  useEffect(() => {
    mutate();
  }, [mutate]);

  const history = useHistory();
  history.push(ROUTE_TRACKDECHETS_OAUTH_STATUS);

  return null;
}
