export function hasPendingRequest(state) {
  return Boolean(state.createWasteRecoveryRequestReducer.siteUuid);
}

const PAYLOAD_PREFIX = "payload.";

/**
 * Gets a single field violations list
 *
 * @return {Array}
 */
export function getFieldViolations(fieldName, transform = (v) => v) {
  return (state) => {
    const wasteState = state.createWasteRecoveryRequestReducer;
    const { violations } = wasteState;
    if (violations.length === 0) {
      return [];
    }

    const reduced = [];

    violations.forEach((violation) => {
      let path = violation.path;
      if (0 === path.indexOf(PAYLOAD_PREFIX)) {
        path = path.slice(PAYLOAD_PREFIX.length);
      }

      if (path !== fieldName) {
        return;
      }

      reduced.push(
        transform({
          code: violation.code,
          message: violation.reason,
          placeholders: violation.placeholders,
        }),
      );
    });

    return reduced;
  };
}

/**
 * Gets violations for each of the specified fields, for given path item
 * Ex: path: containers[0], fields: ['packaging', 'treatmentMode']
 */
export function getItemFieldsViolations(path, fields, transform = (v) => v) {
  return (state) => {
    const violationsAtPath = {};

    fields.forEach((field) => {
      violationsAtPath[field] = getFieldViolations(
        `${path}.${field}`,
        transform,
      )(state);
    });

    return violationsAtPath;
  };
}

/**
 * Gets violations for each of the specified fields, for each of the collection items at path
 * Ex: path: containers, fields: ['packaging', 'treatmentMode'], length: 3
 */
export function getCollectionFieldsViolations(
  path,
  fields,
  length,
  transform = (v) => v,
) {
  return (state) => {
    const collectionViolations = [];
    for (let i = 0; i < length; i++) {
      collectionViolations.push(
        getItemFieldsViolations(`${path}[${i}]`, fields, transform)(state),
      );
    }

    return collectionViolations;
  };
}
