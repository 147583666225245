import {
  GET_DASHBOARD_METRICS_FAIL,
  GET_DASHBOARD_METRICS_IN_PROGRESS,
  GET_DASHBOARD_METRICS_SUCCESS,
} from "../constants";

const initialState = {
  metrics: undefined, // metrics displayed on the dashboard
};

export default function dashboard(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case GET_DASHBOARD_METRICS_IN_PROGRESS:
      return {
        ...state,
        metrics: undefined,
      };

    case GET_DASHBOARD_METRICS_SUCCESS:
      return {
        ...state,
        metrics: payload.metrics,
      };

    case GET_DASHBOARD_METRICS_FAIL:
      return {
        ...state,
        metrics: null,
      };

    default:
      return state;
  }
}
