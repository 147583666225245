/*
 * This file was generated by the "elao/enum" PHP package.
 * The code inside belongs to you and can be altered, but no BC promise is guaranteed.
 */

import { FlaggedEnum } from "../../../libs/enum";

class StatusFlag extends FlaggedEnum {
  static MISSING_CONTACT_PHONE = 1;
  static MISSING_CONTACT_EMAIL = 2;
  static NOT_REGISTERED = 4;
  static SIRET_NOT_FOUND = 8;
  static MISSING_ADDRESS = 16;
  static FORBIDDEN = 32;

  // Named masks

  static get readables() {
    return {
      [StatusFlag.MISSING_CONTACT_PHONE]:
        "enum.trackdechets.sync_status_flag.missing_contact_phone",
      [StatusFlag.MISSING_CONTACT_EMAIL]:
        "enum.trackdechets.sync_status_flag.missing_contact_email",
      [StatusFlag.NOT_REGISTERED]:
        "enum.trackdechets.sync_status_flag.not_registered",
      [StatusFlag.SIRET_NOT_FOUND]:
        "enum.trackdechets.sync_status_flag.siret_not_found",
      [StatusFlag.MISSING_ADDRESS]:
        "enum.trackdechets.sync_status_flag.missing_address",
      [StatusFlag.FORBIDDEN]: "enum.trackdechets.sync_status_flag.forbidden",

      // Named masks
    };
  }
}

export default StatusFlag;
