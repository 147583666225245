export const GET_PROVIDERS_LIST_IN_PROGRESS = "GET_PROVIDERS_LIST_IN_PROGRESS";
export const GET_PROVIDERS_LIST_SUCCESS = "GET_PROVIDERS_LIST_SUCCESS";
export const GET_PROVIDERS_LIST_FAIL = "GET_PROVIDERS_LIST_FAIL";
export const SERVICE_BENEFICIARY_FOUND = "SERVICE_BENEFICIARY_FOUND";
export const SERVICE_BENEFICIARY_NOT_FOUND = "SERVICE_BENEFICIARY_NOT_FOUND";
export const POST_SERVICE_BENEFICIARY_CONTRACT_IN_PROGRESS =
  "POST_SERVICE_BENEFICIARY_CONTRACT_IN_PROGRESS";
export const POST_SERVICE_BENEFICIARY_CONTRACT_SUCCESS =
  "POST_SERVICE_BENEFICIARY_CONTRACT_SUCCESS";
export const POST_SERVICE_BENEFICIARY_CONTRACT_FAILURE =
  "POST_SERVICE_BENEFICIARY_CONTRACT_FAILURE";
export const PATCH_SERVICE_PROVIDER_CONTRACT_IN_PROGRESS =
  "PATCH_SERVICE_PROVIDER_CONTRACT_IN_PROGRESS";
export const PATCH_SERVICE_PROVIDER_CONTRACT_SUCCESS =
  "PATCH_SERVICE_PROVIDER_CONTRACT_SUCCESS";
export const PATCH_SERVICE_PROVIDER_CONTRACT_FAILURE =
  "PATCH_SERVICE_PROVIDER_CONTRACT_FAILURE";
export const PUT_SERVICE_PROVIDER_CONTRACT_IN_PROGRESS =
  "PUT_SERVICE_PROVIDER_CONTRACT_IN_PROGRESS";
export const PUT_SERVICE_PROVIDER_CONTRACT_SUCCESS =
  "PUT_SERVICE_PROVIDER_CONTRACT_SUCCESS";
export const PUT_SERVICE_PROVIDER_CONTRACT_FAILURE =
  "PUT_SERVICE_PROVIDER_CONTRACT_FAILURE";
export const REFUSE_SERVICE_PROVIDER_CONTRACT_IN_PROGRESS =
  "REFUSE_SERVICE_PROVIDER_CONTRACT_IN_PROGRESS";
export const REFUSE_SERVICE_PROVIDER_CONTRACT_SUCCESS =
  "REFUSE_SERVICE_PROVIDER_CONTRACT_SUCCESS";
export const REFUSE_SERVICE_PROVIDER_CONTRACT_FAILURE =
  "REFUSE_SERVICE_PROVIDER_CONTRACT_FAILURE";
