import { arrayOf, func, instanceOf, node, oneOfType, shape } from "prop-types";

export const children = oneOfType([node, arrayOf(node)]);
export const requiredChildren = children.isRequired;

/**
 * Proptype for a React ref.
 *
 * @see https://stackoverflow.com/a/51127130
 */
export const ref = oneOfType([
  // Either a function
  func,
  // Or the instance of a DOM native element (see the note about SSR)
  shape({ current: instanceOf(Element) }),
]);

const CommonTypes = {
  children,
  requiredChildren,
  ref,
};

export default CommonTypes;
