import I18n from "i18n-js";
import React, { Component, createRef, forwardRef } from "react";
import InvalidFeedback from "./InvalidFeedback";
import StatelessTextInput from "./StatelessTextInput";

// eslint-disable-next-line react/display-name
const WithRef = forwardRef((props, ref) => (
  <StatelessTextInput {...props} forwardedRef={ref} />
));

const inheritedPropTypes = StatelessTextInput.propTypes;
delete inheritedPropTypes.errors;

const inheritedDefaults = StatelessTextInput.defaultProps;
delete inheritedDefaults.errors;

/**
 * @deprecated Please use StatelessTextInput instead
 */
class TextInput extends Component {
  static propTypes = {
    error: InvalidFeedback.propTypes.errors,
    ...inheritedPropTypes,
  };

  static defaultProps = {
    error: null,
    ...inheritedDefaults,
  };

  static emailReg = /^.+@.+\..+$/;
  static siretReg = /^((?:\s*\d){14})$/;

  constructor(props) {
    super(props);

    this.input = createRef();

    this.state = {
      errorContent: props.error,
    };

    this.onChange = this.onChange.bind(this);
  }

  /**
   * {@inheritdoc}
   */
  componentDidUpdate(prevProps) {
    const { error } = this.props;

    if (prevProps.error !== error) {
      this.setState({ errorContent: error });
    }
  }

  /**
   * @return {String}
   */
  get value() {
    return this.input.current.value;
  }

  /**
   * @param {String} value
   */
  set value(value) {
    this.input.current.value = value;
  }

  onChange() {
    this.setState({ errorContent: null });
  }

  isValid() {
    const { type, optional } = this.props;
    const { value } = this.input.current;
    let inputIsValid = true;

    this.setState({ errorContent: null });

    if (!optional && !value.length) {
      inputIsValid = false;
      this.setState({ errorContent: I18n.t("common.not_specified") });
    }

    if (type === "email" && value.length) {
      if (!this.constructor.emailReg.test(value)) {
        inputIsValid = false;
        this.setState({ errorContent: I18n.t("form.invalidEmail") });
      }
    }

    if (type === "siret") {
      if (value.length && !this.constructor.siretReg.test(value)) {
        inputIsValid = false;
        this.setState({ errorContent: I18n.t("form.siret:error") });
      }
    }

    return inputIsValid;
  }

  /**
   * {@inheritdoc}
   */
  render() {
    const { errorContent } = this.state;
    const { ...props } = this.props;

    delete props.error;

    return (
      <WithRef
        errors={errorContent}
        onChange={this.onChange}
        ref={this.input}
        {...props}
      />
    );
  }
}

export default TextInput;
