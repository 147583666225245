import I18n from "i18n-js";
import {
  LOGIN_IN_PROGRESS,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_FAIL,
  ACCEPT_TERMS_SUCCESS,
  CHANGE_LANGUAGE,
} from "../constants";

const initialState = {
  loginInProgress: false,
  logged: false,
  userProfile: null,
  errorCode: null,
  previousPath: null,
  lang: localStorage.getItem("language") || I18n.currentLocale().slice(0, 2),
};

/**
 * Auth reducer
 *
 * @param {Object} state
 * @param {Object} action
 *
 * @return {Object}
 */
export default function auth(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case LOGIN_IN_PROGRESS:
      return {
        ...state,
        loginInProgress: true,
        errorCode: null,
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        loginInProgress: false,
        logged: true,
        userProfile: null,
        errorCode: null,
      };

    case LOGIN_FAIL:
    case GET_USER_PROFILE_FAIL:
      return {
        ...state,
        loginInProgress: false,
        logged: false,
        errorCode: payload ? payload.errorCode : null,
        userProfile: null,
      };

    case GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        loginInProgress: false,
        errorCode: null,
        userProfile: payload.userProfile,
      };

    case LOGOUT:
      return {
        ...state,
        loginInProgress: false,
        logged: false,
        userProfile: null,
        errorCode: null,
        previousPath: payload ? payload.pathname : null,
      };

    case ACCEPT_TERMS_SUCCESS:
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          termsAccepted: true,
        },
      };

    case CHANGE_LANGUAGE:
      return {
        ...state,
        lang: payload.lang,
      };

    default:
      return state;
  }
}
