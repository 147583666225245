import I18n from "i18n-js";
import React, { Component } from "react";
import { func, node } from "prop-types";

export default class ImportInstallationsContent extends Component {
  static propTypes = {
    downloadSample: node.isRequired,
    downloadFluids: func.isRequired,
  };

  /**
   * {@inheritdoc}
   */
  render() {
    const Content = I18n.t("Components.ImportInstallationsContent");

    return <Content {...this.props} />;
  }
}
