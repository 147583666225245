import React from "react";
import { string, bool, func, oneOf, oneOfType } from "prop-types";
import InvalidFeedback, { hasErrors } from "./InvalidFeedback";
import classnames from "classnames";

/**
 * Stateless, controlled checkbox input
 */
StatelessCheckbox.propTypes = {
  id: string.isRequired,
  errors: InvalidFeedback.propTypes.errors,
  label: oneOfType([string, oneOf([false])]).isRequired,
  checked: bool,
  onChangedValue: func,
  onChange: func,
  className: string,
};

StatelessCheckbox.defaultProps = {
  checked: false,
  onChangedValue: null,
  onChange: null,
  className: null,
};

export default function StatelessCheckbox({
  id,
  label,
  checked,
  onChangedValue,
  className,
  errors,
  onChange,
  ...props
}) {
  const _onChange = (event) => {
    onChange && onChange(event);
    onChangedValue && onChangedValue(event.target.checked);
  };

  return (
    <div className={classnames("form-check", className)}>
      <input
        id={id}
        type="checkbox"
        checked={checked}
        className={classnames("form-check-input", {
          "is-invalid": hasErrors(errors),
        })}
        {...props}
        onChange={_onChange}
      />
      <label htmlFor={id} className="form-check-label">
        {label !== false ? label : " "}
      </label>
      <InvalidFeedback errors={errors} />
    </div>
  );
}
