import I18n from "i18n-js";
import { fetch } from "../../../appContainer";
import { updateTranslation } from "../../../translations";
import { SUBMIT_SUCCESS, SUBMIT_FAIL } from "../../common/constants";
import {
  LOGIN_IN_PROGRESS,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_FAIL,
  POST_FORGOT_PASSWORD_IN_PROGRESS,
  POST_FORGOT_PASSWORD_SUCCESS,
  POST_FORGOT_PASSWORD_FAIL,
  PUT_CHANGE_PASSWORD_IN_PROGRESS,
  PUT_CHANGE_PASSWORD_SUCCESS,
  PUT_CHANGE_PASSWORD_FAIL,
  RESET_FORGOT_PASSWORD,
  ACCEPT_TERMS_SUCCESS,
  ACCEPT_TERMS_FAILURE,
  CHANGE_LANGUAGE,
} from "../constants";
import graphql from "../../common/api/GraphQLClient";
import { errorAlert } from "../../common/actions/alertActions";

/**
 * Auth access at launch
 */
export function authAccess() {
  return (dispatch) => {
    const token = fetch("authenticator").getToken();
    const matchAppVersion = fetch("authenticator").isAppVersionMatch();

    if (token && matchAppVersion) {
      dispatch({ type: LOGIN_SUCCESS });
    } else {
      dispatch(logout());
      dispatch({ type: LOGIN_FAIL });
    }
  };
}

/**
 * Login action
 *
 * @param {String} username
 * @param {String} password
 */
export function login(username, password) {
  return (dispatch) => {
    dispatch({ type: LOGIN_IN_PROGRESS });

    fetch("authenticator").login({ username, password }, (token, errorCode) => {
      if (token) {
        dispatch({ type: LOGIN_SUCCESS });
      } else {
        dispatch({ type: LOGIN_FAIL, payload: { errorCode } });
        dispatch(errorAlert(I18n.t("alerts.common.fail")));
      }
    });
  };
}

/**
 * Get user profile action
 *
 * @param {Boolean} force
 */
export function getUserProfile(force = false) {
  return (dispatch) => {
    fetch("authenticator").getUserProfile((userProfile, errorCode) => {
      if (userProfile) {
        dispatch({ type: GET_USER_PROFILE_SUCCESS, payload: { userProfile } });
      } else {
        dispatch({ type: GET_USER_PROFILE_FAIL, payload: { errorCode } });
      }
    }, force);
  };
}

/**
 * Logout action
 *
 * @param {Boolean} byUnauthorized
 */
export function logout(byUnauthorized = false) {
  return (dispatch) => {
    const authenticator = fetch("authenticator");
    const action = { type: LOGOUT };

    authenticator.removeToken();
    authenticator.removeAppVersion();
    authenticator.eraseProfile();

    if (byUnauthorized) {
      const message = I18n.t("alerts.unauthorized");
      const { pathname } = window.location;

      action.payload = { pathname };

      dispatch({ type: SUBMIT_FAIL, payload: { message } });
    }

    dispatch(action);

    // https://www.apollographql.com/docs/react/networking/authentication/#reset-store-on-logout
    graphql.clearStore();
  };
}

/**
 * Forgot password
 *
 * @param {String} email
 */
export function forgotPassword(email) {
  return (dispatch) => {
    dispatch({ type: POST_FORGOT_PASSWORD_IN_PROGRESS });

    fetch("api").forgotPassword(
      email,
      () => {
        const message = I18n.t("alerts.forgot_password.success", { email });

        dispatch({ type: SUBMIT_SUCCESS, payload: { message } });
        dispatch({ type: POST_FORGOT_PASSWORD_SUCCESS });
      },
      () => {
        dispatch({
          type: SUBMIT_FAIL,
          payload: { message: I18n.t("alerts.common.fail") },
        });
        dispatch({ type: POST_FORGOT_PASSWORD_FAIL });
      },
    );
  };
}

/**
 * Change password
 *
 * @param {String} token
 * @param {String} email
 * @param {String} password
 */
export function changePassword(token, email, password) {
  return (dispatch) => {
    dispatch({ type: PUT_CHANGE_PASSWORD_IN_PROGRESS });

    fetch("api").changePassword(
      token,
      email,
      password,
      () => {
        const message = I18n.t("alerts.change_password.success");

        dispatch({ type: SUBMIT_SUCCESS, payload: { message } });
        dispatch({ type: PUT_CHANGE_PASSWORD_SUCCESS });
      },
      (error) => {
        dispatch({
          type: SUBMIT_FAIL,
          payload: { message: I18n.t("alerts.common.fail") },
        });
        dispatch({
          type: PUT_CHANGE_PASSWORD_FAIL,
          payload: { error: error.detail },
        });
      },
    );
  };
}

/**
 * Reset forgot password
 */
export function resetForgotPassword() {
  return (dispatch) => dispatch({ type: RESET_FORGOT_PASSWORD });
}

export function acceptTerms() {
  return (dispatch) => {
    fetch("api").acceptTerms(
      () => {
        dispatch({ type: ACCEPT_TERMS_SUCCESS });
        dispatch(getUserProfile(true));
      },
      () => dispatch({ type: ACCEPT_TERMS_FAILURE }),
    );
  };
}

/**
 * Update lang content
 *
 * @param {String} lang
 */
export function changeLanguage(lang) {
  return (dispatch) => {
    updateTranslation(lang);

    dispatch({ type: CHANGE_LANGUAGE, payload: { lang } });
  };
}
