import React from "react";
import { arrayOf, bool, oneOfType, string } from "prop-types";

InvalidFeedback.propTypes = {
  errors: oneOfType([bool, string, arrayOf(string)]),
};

export default function InvalidFeedback({ errors }) {
  if (!hasErrors(errors)) {
    return null;
  }

  return (
    <div className="invalid-feedback">
      {[].concat(errors).map((error) => (
        <p key={error}>{error}</p>
      ))}
    </div>
  );
}

export function hasErrors(errors) {
  if (!errors || typeof errors === "boolean") {
    return false;
  }

  if (Array.isArray(errors) && errors.length === 0) {
    return false;
  }

  return true;
}

/**
 * Standalone feedback, without any form-control.
 * Useful for global form errors.
 */
export function StandaloneInvalidFeedback(props) {
  return (
    <div className="is-invalid standalone">
      <InvalidFeedback {...props} />
    </div>
  );
}
