import React from "react";
import PropTypes from "prop-types";
import WasteRecoveryRequestStatus from "../models/WasteRecoveryRequestStatus";
import classnames from "classnames";
import I18n from "i18n-js";
import CommercialRecoveryRequestRejectReason from "../models/CommercialRecoveryRequestRejectReason";
import { formatDate } from "../../common/utils/dateUtils";
import { requiredChildren } from "../../common/propTypes/types";

WasteStatusTimeline.propTypes = {
  status: PropTypes.instanceOf(WasteRecoveryRequestStatus).isRequired,
  request: PropTypes.shape({
    authorReference: PropTypes.string,
    commercialReference: PropTypes.string,
    authorRejectComment: PropTypes.string,
    commercialRejectReason: PropTypes.string,
    date: PropTypes.string,
    transportDate: PropTypes.string,
    transportNumber: PropTypes.string,
    carrier: PropTypes.shape(),
    charteredByClient: PropTypes.bool.isRequired,
  }),
  className: PropTypes.string,
};
WasteStatusTimeline.defaultTypes = {
  request: {
    authorReference: null,
    commercialReference: null,
    authorRejectComment: null,
    commercialRejectReason: null,
    date: null,
    transportDate: null,
    transportNumber: null,
    carrier: null,
    charteredByClient: null,
  },
  className: null,
};

export default function WasteStatusTimeline({ status, request, className }) {
  const {
    authorReference,
    commercialReference,
    authorRejectComment,
    commercialRejectReason,
    charteredByClient,
  } = request;

  const isDone = (target) =>
    new WasteRecoveryRequestStatus(target).isDone(status);
  const isDoneOrCurrent = (target) =>
    new WasteRecoveryRequestStatus(target).isDoneOrCurrent(status);
  const isActive = (target) => status.is(target);

  return (
    <div className={classnames("timeline", className)} key="timeline">
      {/* Acknowledgment */}
      <TimelineSection
        label="En attente de prise en compte par notre service commercial"
        first
        active={isActive(WasteRecoveryRequestStatus.CREATED)}
        done={isDone(WasteRecoveryRequestStatus.CREATED)}
      />
      <TimelineSection
        label="Prise en compte par notre service commercial"
        active={isActive(WasteRecoveryRequestStatus.AWAITING_ESTIMATE)}
        done={isDone(WasteRecoveryRequestStatus.AWAITING_ESTIMATE)}
      />

      {/* Commercial decision */}
      <TimelineSection
        label="Acceptation / Refus par notre service commercial"
        active={isActive(WasteRecoveryRequestStatus.COMMERCIAL_APPROVED)}
        // Hide until decided by commercial:
        hidden={
          isDoneOrCurrent(WasteRecoveryRequestStatus.COMMERCIAL_APPROVED) ||
          isDoneOrCurrent(WasteRecoveryRequestStatus.COMMERCIAL_REJECTED)
        }
      />
      <TimelineSection
        label="Accepté par notre service commercial"
        done={isDoneOrCurrent(WasteRecoveryRequestStatus.COMMERCIAL_APPROVED)}
        // Hide until not approved by commercial:
        hidden={
          !isDoneOrCurrent(WasteRecoveryRequestStatus.COMMERCIAL_APPROVED)
        }
      />
      <TimelineSection
        label="Refusé par notre service commercial"
        rejected={isActive(WasteRecoveryRequestStatus.COMMERCIAL_REJECTED)}
        // Hide until not reject by commercial:
        hidden={!isActive(WasteRecoveryRequestStatus.COMMERCIAL_REJECTED)}
      >
        {() => (
          <Alert>
            <DetailRow title="Motif du refus">
              {I18n.t(
                new CommercialRecoveryRequestRejectReason(
                  commercialRejectReason,
                ).getReadable(),
              )}
            </DetailRow>
          </Alert>
        )}
      </TimelineSection>

      {/* Author decision */}
      <TimelineSection
        label="En attente de votre avis"
        active={isActive(WasteRecoveryRequestStatus.COMMERCIAL_APPROVED)}
        done={isDone(WasteRecoveryRequestStatus.COMMERCIAL_APPROVED)}
        // Hide until decided by user:
        hidden={
          isDoneOrCurrent(WasteRecoveryRequestStatus.AUTHOR_APPROVED) ||
          isDoneOrCurrent(WasteRecoveryRequestStatus.AUTHOR_REJECTED)
        }
      />
      <TimelineSection
        label="Acceptée par vos soins"
        done={isDoneOrCurrent(WasteRecoveryRequestStatus.AUTHOR_APPROVED)}
        // Hide until not approved by user:
        hidden={!isDoneOrCurrent(WasteRecoveryRequestStatus.AUTHOR_APPROVED)}
      >
        {() => (
          <InfoSection>
            <DetailRow title="Notre référence">{commercialReference}</DetailRow>
            <DetailRow title="Votre référence">
              {authorReference || (
                <span className="badge badge-pill badge-secondary">
                  Non précisé
                </span>
              )}
            </DetailRow>
          </InfoSection>
        )}
      </TimelineSection>
      <TimelineSection
        label="Refusée par vos soins"
        rejected={isActive(WasteRecoveryRequestStatus.AUTHOR_REJECTED)}
        // Hide until not reject by user:
        hidden={!isActive(WasteRecoveryRequestStatus.AUTHOR_REJECTED)}
      >
        {() => (
          <Alert>
            <DetailRow title="Motif du refus">
              {authorRejectComment || (
                <span className="badge badge-pill badge-secondary">
                  Non précisé
                </span>
              )}
            </DetailRow>
          </Alert>
        )}
      </TimelineSection>

      {/* Transport if non chartered by client */}
      <TimelineSection
        label="En attente de traitement par notre service logistique"
        active={isActive(WasteRecoveryRequestStatus.AUTHOR_APPROVED)}
        // Hidden if chartered by client
        hidden={
          charteredByClient ||
          isActive(WasteRecoveryRequestStatus.AWAITING_DISPATCH) ||
          isDoneOrCurrent(WasteRecoveryRequestStatus.CLOSED)
        }
      />
      <TimelineSection
        label="Traité par notre service logistique"
        done={isDoneOrCurrent(WasteRecoveryRequestStatus.AWAITING_DISPATCH)}
        // Hidden if chartered by client
        hidden={
          charteredByClient ||
          (!isActive(WasteRecoveryRequestStatus.AWAITING_DISPATCH) &&
            !isDoneOrCurrent(WasteRecoveryRequestStatus.AWAITING_DISPATCH))
        }
      >
        {() => <TransportSection request={request} />}
      </TimelineSection>

      {/* Transport if chartered by client */}
      <TimelineSection
        label="Transport affrété par vos soins"
        done={isDoneOrCurrent(WasteRecoveryRequestStatus.AWAITING_DISPATCH)}
        hidden={!charteredByClient}
      >
        {() => <TransportSection request={request} />}
      </TimelineSection>

      {/* Dispatched ? */}
      <TimelineSection
        label={
          isDoneOrCurrent(WasteRecoveryRequestStatus.CLOSED)
            ? "Expédiée"
            : "En attente d'expédition"
        }
        active={isActive(WasteRecoveryRequestStatus.AWAITING_DISPATCH)}
        done={isDoneOrCurrent(WasteRecoveryRequestStatus.CLOSED)}
        last
      />
    </div>
  );
}

TimelineSection.propTypes = {
  label: PropTypes.string.isRequired,
  first: PropTypes.bool,
  last: PropTypes.bool,
  active: PropTypes.bool,
  done: PropTypes.bool,
  rejected: PropTypes.bool,
  hidden: PropTypes.bool,
  children: PropTypes.func,
};
TimelineSection.defaultProps = {
  first: false,
  last: false,
  active: false,
  done: false,
  rejected: false,
  hidden: false,
  children: null,
};

function TimelineSection({
  label,
  first,
  last,
  active,
  done,
  rejected,
  hidden,
  children,
}) {
  if (hidden) {
    return null;
  }

  return (
    <div
      className={classnames("row align-items-center timeline-descr d-flex", {
        active,
        done,
        rejected,
      })}
    >
      <div
        className={classnames(
          "col-auto timeline-step text-center d-inline-flex justify-content-center align-items-center",
          {
            bottom: first,
            top: last,
            full: !first && !last,
          },
        )}
      >
        <div className="circle font-weight-bold" />
      </div>
      <div className="col">
        <h5
          className={classnames({
            "font-weight-bold": active || rejected,
          })}
        >
          {label}
        </h5>
        {children && children()}
      </div>
    </div>
  );
}

Alert.propTypes = {
  children: requiredChildren,
};
function Alert({ children }) {
  return <div className="alert alert-danger p-2">{children}</div>;
}

InfoSection.propTypes = {
  children: requiredChildren,
};
function InfoSection({ children }) {
  return <div className="bg-light p-2 pb-0 border mb-4">{children}</div>;
}

DetailRow.propTypes = {
  title: PropTypes.string.isRequired,
  children: requiredChildren,
};
function DetailRow({ title, children, ...props }) {
  return (
    <div className="row" {...props}>
      <strong className="col-xl-4">{title}</strong>
      <div className="col-xl-8">{children}</div>
    </div>
  );
}

TransportSection.propTypes = {
  request: PropTypes.shape({
    date: PropTypes.string,
    transportDate: PropTypes.string,
    transportNumber: PropTypes.string,
    carrier: PropTypes.shape(),
  }),
};
function TransportSection({
  request: { carrier, transportNumber, transportDate, date },
}) {
  return (
    <InfoSection>
      <DetailRow title="Transporteur">{carrier?.legalCompanyName}</DetailRow>
      <DetailRow title="N° de retour">{transportNumber}</DetailRow>
      <DetailRow title="Date de la reprise">
        {formatDate(transportDate || date)}
      </DetailRow>
    </InfoSection>
  );
}
