import { Actions } from "../actions/errorActions";

const initialState = {
  code: null,
};

export default function error(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case Actions.SET:
      return {
        ...state,
        code: payload.code,
      };

    case Actions.RESET:
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
