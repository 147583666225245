import React, { useEffect, useMemo } from "react";
import { trans } from "../../../translations";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../common/components/elements/Modal";
import ClientUtils from "../../company/utils/ClientUtils";
import InterventionType from "../../intervention/models/InterventionType";
import Purpose from "../../intervention/models/Purpose";
import { formatFloat } from "../../common/utils/formatUtils";
import { Link } from "react-router-dom";
import ShippingType from "../../shipping/models/ShippingType";
import { getLang } from "../../common/utils/langUtils";
import ShippingUtils from "../../shipping/utils/ShippingUtils";
import pathToRegexp from "path-to-regexp";
import { ROUTE_INTERVENTION } from "../../../routes";
import { getInterventionList } from "../../intervention/actions/interventionActions";
import { getShippingsList } from "../../shipping/actions/shippingActions";
import { getSitesList } from "../../company/actions/sitesActions";
import GenericLoadingError from "../../common/components/elements/GenericLoadingError";
import StatelessTable from "../../common/components/elements/StatelessTable";

ContainerInterventionsModal.propTypes = {
  container: PropTypes.shape({
    interventionUuids: PropTypes.arrayOf(PropTypes.string).isRequired,
    shippingUuids: PropTypes.arrayOf(PropTypes.string).isRequired,
  }),
  onClose: PropTypes.func.isRequired,
};

ContainerInterventionsModal.defaultProps = {
  container: null,
};

export default function ContainerInterventionsModal({ container, onClose }) {
  const opened = Boolean(container);

  const dispatch = useDispatch();

  const interventionsState = useSelector((state) => state.interventions);
  const shippingsState = useSelector((state) => state.shippings);
  const sitesState = useSelector((state) => state.sites);

  const initializationCalled =
    interventionsState.listCalled &&
    shippingsState.listCalled &&
    sitesState.listCalled;

  /*
   * On first time this modal is mounted, fetches the required listings.
   * LATER: fetch only the current container info?.
   */
  useEffect(() => {
    if (!opened || initializationCalled) {
      return;
    }

    dispatch(getInterventionList());
    dispatch(getShippingsList());
    dispatch(getSitesList());
  }, [opened, initializationCalled, dispatch]);

  const containerInterventions = useMemo(
    () =>
      (container?.interventionUuids ?? [])
        .map((uuid) =>
          (interventionsState.list ?? []).find(
            (intervention) => intervention.uuid === uuid,
          ),
        )
        .filter(Boolean),
    [container?.interventionUuids, interventionsState.list],
  );

  const containerShippings = useMemo(
    () =>
      (container?.shippingUuids ?? [])
        .map((uuid) =>
          (shippingsState.list ?? []).find(
            (shipping) => shipping.uuid === uuid,
          ),
        )
        .filter(Boolean),
    [container?.shippingUuids, shippingsState.list],
  );

  if (!opened) {
    return null;
  }

  return (
    <Modal
      isOpen
      title={trans("pages.containers.list.interventions_modal.title")}
      onClose={onClose}
      className="big"
    >
      <InterventionsTable
        data={containerInterventions}
        sites={sitesState.list}
        loading={sitesState.inProgress || interventionsState.inProgress}
        error={Boolean(sitesState.error || interventionsState.error)}
      />
      <ShippingsTable
        data={containerShippings}
        loading={shippingsState.inProgress}
        error={Boolean(shippingsState.error)}
      />
    </Modal>
  );
}

const interventionPath = pathToRegexp.compile(ROUTE_INTERVENTION);

InterventionsTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  sites: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
};

function InterventionsTable({ data, sites, loading, error }) {
  if (loading) {
    return (
      <div>
        <h5>
          {trans("pages.containers.list.interventions_modal.interventions")}
        </h5>
        <h6>{trans("common.loading")}</h6>
        <div className="loader" />
      </div>
    );
  }

  if (error) {
    return (
      <div>
        <h5>
          {trans("pages.containers.list.interventions_modal.interventions")}
        </h5>
        <GenericLoadingError />
      </div>
    );
  }

  if (data.length === 0) {
    return null;
  }

  return (
    <div>
      <h5>
        {trans("pages.containers.list.interventions_modal.interventions")}
      </h5>
      <StatelessTable
        className=""
        data={data}
        headData={[
          trans("pages.interventions.list.head.client"),
          trans("pages.interventions.list.head.installation"),
          trans("pages.interventions.list.head.city"),
          trans("pages.interventions.list.head.type"),
          trans("pages.interventions.list.head.purpose"),
          trans("pages.interventions.list.head.fluid"),
          trans("pages.interventions.list.head.quantity"),
          trans("pages.interventions.list.head.date"),
          "",
        ]}
        rowData={(intervention) => {
          const {
            uuid,
            client,
            installation,
            type,
            purpose,
            creation,
            containerLoads,
          } = intervention;
          const quantity = containerLoads.reduce(
            (quantity, { load }) => load + quantity,
            0,
          );
          const fluid =
            (containerLoads[0] &&
              containerLoads[0].fluid &&
              containerLoads[0].fluid.designation) ||
            null;
          const site = sites.find(
            ({ uuid }) => uuid === intervention.site.uuid,
          );

          return [
            ClientUtils.renderLabel(client),
            `${installation.mark || ""} (${installation.designation})`.trim(),
            (site && site.address && site.address.city) || null,
            trans(InterventionType.readableFor(type)),
            trans(Purpose.readableFor(purpose)),
            fluid,
            `${formatFloat(quantity)} kg`,
            new Date(creation).toLocaleString(getLang()),
            <Link key="link" to={interventionPath({ uuid })} className="action">
              <i className="fa fa-eye" aria-hidden="true" />
            </Link>,
          ];
        }}
      />
    </div>
  );
}

ShippingsTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
};

function ShippingsTable({ data, loading, error }) {
  if (loading) {
    return (
      <div>
        <h5>{trans("pages.containers.list.interventions_modal.shippings")}</h5>
        <h6>{trans("common.loading")}</h6>
        <div className="loader" />
      </div>
    );
  }

  if (error) {
    return (
      <div>
        <h5>{trans("pages.containers.list.interventions_modal.shippings")}</h5>
        <GenericLoadingError />
      </div>
    );
  }

  if (data.length === 0) {
    return null;
  }

  return (
    <div>
      <h5>{trans("pages.containers.list.interventions_modal.shippings")}</h5>
      <StatelessTable
        className=""
        data={data}
        headData={[
          trans("pages.shippings.list.head.type"),
          trans("pages.shippings.list.head.site"),
          trans("pages.shippings.list.head.arianeOperationNumber"),
          trans("pages.shippings.list.head.date"),
          "",
        ]}
        rowData={({ arianeOperation, creation, originSite, site, type }) => [
          trans(ShippingType.readableFor(type)),
          ShippingUtils.renderShippingSitesLabel(type, site, originSite),
          arianeOperation && arianeOperation.number,
          new Date(creation).toLocaleDateString(getLang()),
        ]}
      />
    </div>
  );
}
