import React, { Component } from "react";
import { string, bool, node, func } from "prop-types";
import classnames from "classnames";
import RModal from "react-modal";

RModal.setAppElement("#root");

class Modal extends Component {
  static propTypes = {
    className: string,
    isOpen: bool.isRequired,
    title: string.isRequired,
    children: node.isRequired,
    onClose: func,
  };

  static defaultProps = {
    className: "",
    onClose: null,
  };

  static styles = {
    overlay: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "rgba(0, 0, 0, 0.75)",
    },
  };

  render() {
    const { styles } = Modal;
    const { className, isOpen, title, children, onClose } = this.props;

    return (
      <RModal
        style={styles}
        isOpen={isOpen}
        contentLabel={title}
        onRequestClose={onClose}
        className={classnames("modal", className)}
      >
        {children}
      </RModal>
    );
  }
}

export default Modal;
