import React, { useState } from "react";
import PropTypes from "prop-types";
import WasteRecoveryRequestStatus from "../models/WasteRecoveryRequestStatus";
import RawSubmit from "../../common/components/form/RawSubmit";
import { errorAlert, successAlert } from "../../common/actions/alertActions";
import { EnumChoice } from "../../common/components/form/EnumChoice";
import CommercialRecoveryRequestRejectReason from "../models/CommercialRecoveryRequestRejectReason";
import { useMutation } from "../../common/api/GraphQLClient";
import acknowledgeWasteRecoveryRequestMutation from "../graphql/mutations/acknowledgeWasteRecoveryRequest.graphql";
import acceptWasteRecoveryRequestAsCommercialMutation from "../graphql/mutations/acceptWasteRecoveryRequestAsCommercial.graphql";
import rejectWasteRecoveryRequestAsCommercialMutation from "../graphql/mutations/rejectWasteRecoveryRequestAsCommercial.graphql";
import { useDispatch } from "react-redux";
import {
  DocumentsList,
  RejectedOutcome,
  SuccessOutcome,
  WaitingOutcome,
} from "./RequestLifecycleOutcome";
import ConfirmRequestDispatchAction from "./ConfirmRequestDispatchAction";

RequestStatusLifecycleCommercialForm.propTypes = {
  status: PropTypes.instanceOf(WasteRecoveryRequestStatus).isRequired,
  uuid: PropTypes.string.isRequired,
  className: PropTypes.string,
  documents: DocumentsList.propTypes.documents,
};

RequestStatusLifecycleCommercialForm.defaultProps = {
  className: null,
};

export default function RequestStatusLifecycleCommercialForm({
  uuid,
  status,
  className,
  documents,
}) {
  return (
    <div className={className}>
      {(() => {
        switch (status.value) {
          case WasteRecoveryRequestStatus.CREATED:
            return <AcknowledgeForm uuid={uuid} />;

          case WasteRecoveryRequestStatus.AWAITING_ESTIMATE:
            return (
              <div className="d-flex flex-column h-100 w-100 justify-content-between">
                <AcceptForm uuid={uuid} />
                <RejectForm uuid={uuid} className="mt-4" />
              </div>
            );

          case WasteRecoveryRequestStatus.COMMERCIAL_APPROVED:
            return (
              <WaitingOutcome>
                <p>
                  <i>En attente de la décision du demandeur</i>
                </p>
              </WaitingOutcome>
            );

          case WasteRecoveryRequestStatus.AUTHOR_APPROVED:
            // only if non-chartered by client, otherwise, the request is immediately closed:
            return (
              <WaitingOutcome>
                <p>
                  <i>En attente de finalisation par notre service logistique</i>
                </p>
              </WaitingOutcome>
            );

          case WasteRecoveryRequestStatus.COMMERCIAL_REJECTED:
          case WasteRecoveryRequestStatus.AUTHOR_REJECTED:
            return <RejectedOutcome />;

          case WasteRecoveryRequestStatus.AWAITING_DISPATCH:
            return (
              <SuccessOutcome>
                <DocumentsList documents={documents} />
                <ConfirmRequestDispatchAction uuid={uuid} />
              </SuccessOutcome>
            );

          case WasteRecoveryRequestStatus.CLOSED:
            return (
              <SuccessOutcome>
                <DocumentsList documents={documents} />
              </SuccessOutcome>
            );
        }
      })()}
    </div>
  );
}

const LifecycleForm = {
  propTypes: {
    uuid: PropTypes.string.isRequired,
  },
};

AcknowledgeForm.propTypes = {
  ...LifecycleForm.propTypes,
};

function AcknowledgeForm({ uuid }) {
  const dispatch = useDispatch();
  const [acknowledgeRequest, { loading }] = useMutation(
    acknowledgeWasteRecoveryRequestMutation,
    {
      variables: { uuid },
    },
  );

  const onSubmit = (e) => {
    e.preventDefault();

    acknowledgeRequest()
      .then(() => dispatch(successAlert("Enregistré avec succès")))
      .catch(() => dispatch(errorAlert("Une erreur est survenue")));
  };

  return (
    <form onSubmit={onSubmit}>
      <RawSubmit btnClasses="btn btn-primary btn-lg" loading={loading}>
        Prendre en compte la demande
      </RawSubmit>
    </form>
  );
}

AcceptForm.propTypes = {
  ...LifecycleForm.propTypes,
};

function AcceptForm({ uuid, className }) {
  const dispatch = useDispatch();
  const [accept, { loading }] = useMutation(
    acceptWasteRecoveryRequestAsCommercialMutation,
    {
      variables: { uuid },
    },
  );

  const onSubmit = (e) => {
    e.preventDefault();

    accept()
      .then(() => dispatch(successAlert("Enregistré avec succès")))
      .catch(() => dispatch(errorAlert("Une erreur est survenue")));
  };

  return (
    <form onSubmit={onSubmit} className={className}>
      <div className="row">
        <div className="offset-xl-4 col-xl-8">
          <RawSubmit
            btnClasses="btn btn-primary btn-block btn-lg"
            loading={loading}
          >
            Accepter la demande
          </RawSubmit>
        </div>
      </div>
    </form>
  );
}

RejectForm.propTypes = {
  ...LifecycleForm.propTypes,
};

function RejectForm({ uuid, className }) {
  const dispatch = useDispatch();
  const [reject, { loading }] = useMutation(
    rejectWasteRecoveryRequestAsCommercialMutation,
  );
  const [reason, setReason] = useState(null);

  const onSubmit = (e) => {
    e.preventDefault();

    reject({
      variables: { uuid, reason },
    })
      .then(() => dispatch(successAlert("Enregistré avec succès")))
      .catch(() => dispatch(errorAlert("Une erreur est survenue")));
  };

  return (
    <form onSubmit={onSubmit} className={className}>
      <EnumChoice
        id="reject-reason"
        label="Motif du refus"
        enumClass={CommercialRecoveryRequestRejectReason}
        layout="horizontal"
        labelClassName="col-lg-2 col-xl-4 text-xl-right"
        widgetClassName="col-lg-8 col-xl-8"
        defaultValue={reason}
        onChangedValue={setReason}
      />

      <div className="row">
        <div className="offset-xl-4 col-xl-8">
          <RawSubmit
            btnClasses="btn btn-danger btn-block btn-lg"
            loading={loading}
            disabled={!reason}
          >
            Refuser la demande
          </RawSubmit>
        </div>
      </div>
    </form>
  );
}
