import I18n from "i18n-js";
import React, { Component } from "react";
import { string } from "prop-types";
import classnames from "classnames";
import { Link } from "react-router-dom";
import { ROUTE_PRIVACY_POLICY, ROUTE_CGU } from "../../../../routes";

class Footer extends Component {
  static propTypes = {
    className: string,
  };

  static defaultProps = {
    className: null,
  };

  constructor(props, context) {
    super(props, context);
  }

  switchColors() {
    const body = document.getElementsByTagName("body")[0];

    body.classList.contains(AppConfig.APP_RUNTIME)
      ? body.classList.remove(AppConfig.APP_RUNTIME)
      : body.classList.add(AppConfig.APP_RUNTIME);

    return false;
  }

  render() {
    const classes = classnames("footer", this.props.className);

    return (
      <div className={classes}>
        &copy; {I18n.t("common.copyright")}
        <Link to={ROUTE_PRIVACY_POLICY}>{I18n.t("common.privacy_policy")}</Link>
        <Link to={ROUTE_CGU}>{I18n.t("common.cgu")}</Link>
        {AppConfig.APP_RUNTIME !== "production" && (
          <a href="#" onClick={this.switchColors}>
            🎨 Switch colors
          </a>
        )}
      </div>
    );
  }
}

export default Footer;
