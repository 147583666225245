import I18n from "i18n-js";
import React from "react";
import { connect } from "react-redux";
import AbstractSitesList from "./AbstractSitesList";
import { ROUTE_MOBILE_SITE, ROUTE_MOBILE_SITES_ADD } from "../../../routes";
import ClientUtils from "../utils/ClientUtils";
import { alphabetically } from "../../common/utils/filterUtils";
import TextInput from "../../common/components/form/TextInput";
import { deleteSite, getSitesList } from "../actions/sitesActions";

class MobileSitesList extends AbstractSitesList {
  /**
   * @inheritDoc
   */
  getI18nPageKey() {
    return "mobile_sites";
  }

  /**
   * @inheritDoc
   */
  getSiteRoute() {
    return ROUTE_MOBILE_SITE;
  }

  /**
   * @inheritDoc
   */
  getAddSiteRoute() {
    return ROUTE_MOBILE_SITES_ADD;
  }

  /**
   * @inheritDoc
   */
  dataTableHead() {
    return [
      this.getLabel("head.designation"),
      this.getLabel("head.client"),
      this.getLabel("head.actions"),
    ];
  }

  /**
   * @inheritDoc
   */
  dataTableRow(rowData) {
    return [
      rowData.designation,
      ClientUtils.renderLabel(rowData.client),
      this.renderRowActions(rowData),
    ];
  }

  /**
   * {@inheritdoc}
   */
  renderFilters() {
    return [
      <TextInput
        key="filterByDesignation"
        id="filterByDesignation"
        onChange={(event) => this.onChangeFilter(event, "designation")}
        label={I18n.t("filters.by_designation")}
        optional
      />,
    ];
  }
}

const mapStateToProps = (state) => ({
  clients: state.clients,
  sites: state.sites,
  sitesList: state.sites.list
    .filter((site) => site.mobile)
    .sort((a, b) => alphabetically(a.designation, b.designation)),
});

const mapDispatchToProps = (dispatch) => ({
  getSitesList: () => dispatch(getSitesList()),
  deleteSite: (uuid, isMobile) => dispatch(deleteSite(uuid, isMobile)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileSitesList);
