import I18n from "i18n-js";
import React from "react";
import { func } from "prop-types";
import Select from "./Select";
import { ReadableEnum } from "../../../../libs/enum";

const inheritedPropTypes = { ...Select.propTypes };
// set by this type:
delete inheritedPropTypes.options;
delete inheritedPropTypes.renderOption;

EnumChoice.propTypes = {
  ...inheritedPropTypes,
  enumClass: func.isRequired,
};

EnumChoice.defaultProps = {
  ...Select.defaultProps,
  emptyStringAsNull: true,
};

// set by this type:
delete EnumChoice.defaultProps.options;
delete EnumChoice.defaultProps.renderOption;

export function EnumChoice({ enumClass, ...props }) {
  return (
    <Select
      options={enumClass.instances}
      renderOption={(instance, i) => (
        <option key={i} value={instance.value}>
          {instance instanceof ReadableEnum
            ? I18n.t(instance.getReadable())
            : instance.value}
        </option>
      )}
      {...props}
    />
  );
}
