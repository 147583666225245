import QRCode from "react-qr-code";
import React from "react";
import I18n from "i18n-js";
import PropTypes from "prop-types";

BsffList.propTypes = {
  bsffs: PropTypes.arrayOf(
    PropTypes.shape({
      designation: PropTypes.string.isRequired,
      bordereauId: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
};

export default function BsffList({ bsffs }) {
  return (
    <div key={`bsff-list-intervention`}>
      <h4>{I18n.t("trackdechets.bsff.list.title")}</h4>
      <ul className="list-group">
        {bsffs.map((bsff) => {
          return (
            <li key={`bsff-${bsff.id}`} className="list-group-item">
              <div className="row switch-qrcode">
                <div className="col-sm-9">
                  <dl>
                    <dt>{I18n.t("trackdechets.bsff.list.designation")}</dt>
                    <dd>{bsff.designation ?? "-"}</dd>
                    <dt>{I18n.t("trackdechets.bsff.list.noteId")}</dt>
                    <dd>{bsff.bordereauId}</dd>
                    <dt>{I18n.t("trackdechets.bsff.list.status")}</dt>
                    <dd>{I18n.t("trackdechets.bsff.status." + bsff.status)}</dd>
                  </dl>
                </div>
                <div className="col-sm-3">
                  <QRCode
                    className="blur-qrcode"
                    value={bsff.bordereauId}
                    size={128}
                  />
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
