import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useMutation } from "../../common/api/GraphQLClient";
import dispatchWasteRecoveryRequestMutation from "../graphql/mutations/dispatchWasteRecoveryRequest.graphql";
import { errorAlert, successAlert } from "../../common/actions/alertActions";
import RawSubmit from "../../common/components/form/RawSubmit";

ConfirmRequestDispatchAction.propTypes = {
  /** request uuid */
  uuid: PropTypes.string.isRequired,
};

/**
 * Action to confirm a waste request was dispatched with the transporter
 */
export default function ConfirmRequestDispatchAction({ uuid }) {
  const dispatch = useDispatch();
  const [dispatchRequest, { loading }] = useMutation(
    dispatchWasteRecoveryRequestMutation,
    {
      variables: { uuid },
    },
  );

  const onSubmit = (e) => {
    e.preventDefault();

    dispatchRequest()
      .then(() => dispatch(successAlert("Enregistré avec succès")))
      .catch(() => dispatch(errorAlert("Une erreur est survenue")));
  };

  return (
    <form onSubmit={onSubmit} className="text-center">
      <hr className="w-100" />
      <p>
        Une fois les déchets collectés par le transporteur, veuillez confirmer
        l&apos;expédition :
      </p>
      <RawSubmit btnClasses="btn btn-primary" loading={loading}>
        Confirmer l&apos;expédition
      </RawSubmit>
    </form>
  );
}
