import I18n from "i18n-js";
import React, { Component, createRef } from "react";
import {
  arrayOf,
  bool,
  func,
  oneOf,
  oneOfType,
  shape,
  string,
} from "prop-types";
import classnames from "classnames";
import InvalidFeedback, { hasErrors } from "./InvalidFeedback";
import { alphabetically } from "../../utils/filterUtils";

class Select extends Component {
  static propTypes = {
    id: string.isRequired,
    error: InvalidFeedback.propTypes.errors,
    label: oneOfType([string, oneOf([false])]).isRequired,
    legend: string,
    options: arrayOf(oneOfType([shape(), string])).isRequired,
    optionGroup: func,
    placeholder: string,
    renderOption: func.isRequired,
    disabled: bool,
    loading: bool,
    loadingError: bool,
    optional: bool,
    defaultValue: string,
    onChange: func,
    onChangedValue: func,
    emptyStringAsNull: bool,
    layout: oneOf(["vertical", "horizontal"]),
    labelClassName: string,
    widgetClassName: string,
  };

  static defaultProps = {
    error: null,
    legend: null,
    options: null,
    optionGroup: null,
    placeholder: "",
    disabled: false,
    optional: false,
    defaultValue: "",
    onChange: null,
    onChangedValue: null,
    loading: false,
    loadingError: false,
    emptyStringAsNull: false,
    layout: "vertical",
    labelClassName: null,
    widgetClassName: null,
  };

  state = {
    selectedValue: this.props.defaultValue,
    errorContent: this.props.error,
  };

  constructor(props) {
    super(props);

    this.select = createRef();

    this.onChange = this.onChange.bind(this);
  }

  /**
   * {@inheritdoc}
   */
  componentDidUpdate(prevProps) {
    const { defaultValue, error } = this.props;

    if (prevProps.defaultValue !== defaultValue) {
      this.setState({ selectedValue: defaultValue || "" });
    }

    if (prevProps.error !== error) {
      this.setState({ errorContent: error });
    }
  }

  /**
   * On change event
   *
   * @param {Object} event
   */
  onChange(event) {
    const { onChange, onChangedValue, emptyStringAsNull } = this.props;
    let { value } = event.target;

    if (emptyStringAsNull && value === "") {
      value = null;
    }

    this.setState({
      selectedValue: value,
      errorContent: null,
    });

    if (onChange) {
      onChange(event);
    }

    if (onChangedValue) {
      onChangedValue(value);
    }
  }

  /**
   * @return {String}
   */
  get value() {
    return this.select.current.value;
  }

  /**
   * @param {String} value
   */
  set value(value) {
    this.select.current.value = value;
    this.setState({ selectedValue: value });
  }

  isValid() {
    const { optional } = this.props;
    const { value } = this.select.current;

    let inputIsValid = true;

    if (!optional && !value.length) {
      inputIsValid = false;
      this.setState({ errorContent: I18n.t("common.not_specified") });
    }

    return inputIsValid;
  }

  /**
   * Render options in select
   *
   * @return {Element|Element[]}
   */
  renderOptions() {
    const { options, placeholder, renderOption, optionGroup } = this.props;

    const defaultOption = (
      <option key="option-default" value="">
        {placeholder}
      </option>
    );

    if (!options) {
      return defaultOption;
    }

    if (optionGroup !== null) {
      const optionsByGroup = options.reduce((carry, option) => {
        const group = optionGroup(option);
        carry[group] = carry[group] || [];
        carry[group].push(option);

        return carry;
      }, {});

      return [
        defaultOption,
        Object.entries(optionsByGroup)
          .sort(([groupA], [groupB]) => alphabetically(groupA, groupB))
          .map(([group, options]) => (
            <optgroup key={group} label={group}>
              {options.map(renderOption)}
            </optgroup>
          )),
      ];
    }

    return [defaultOption, options.map(renderOption)];
  }

  /**
   * {@inheritdoc}
   */
  render() {
    const { selectedValue, errorContent } = this.state;
    const {
      id,
      label,
      legend,
      disabled,
      optional,
      loading,
      loadingError,
      layout,
      labelClassName,
      widgetClassName,
    } = this.props;

    const horizontalLayout = layout === "horizontal";

    return (
      <div
        className={classnames("form-group", {
          row: horizontalLayout,
        })}
      >
        {false !== label && (
          <label
            htmlFor={id}
            className={classnames(labelClassName, {
              "col-form-label": horizontalLayout,
              [labelClassName || "col-sm-2"]: horizontalLayout,
            })}
          >
            {label}
            {optional ? "" : "*"}
          </label>
        )}
        <div
          className={classnames(widgetClassName, {
            [widgetClassName || "col-sm-10"]: horizontalLayout,
          })}
        >
          <select
            className={classnames("form-control", {
              "is-invalid": hasErrors(errorContent) || loadingError,
            })}
            id={id}
            ref={this.select}
            value={selectedValue || ""}
            onChange={this.onChange}
            required={!optional}
            disabled={disabled || loading || loadingError}
          >
            {!loading ? (
              this.renderOptions()
            ) : (
              <option>{I18n.t("common.loading")}</option>
            )}
          </select>
          <InvalidFeedback errors={errorContent} />
          {loadingError && (
            <div className="invalid-feedback">
              {I18n.t("common.loadingApiError")}
            </div>
          )}
          {legend && <small className="form-text text-muted">{legend}</small>}
        </div>
      </div>
    );
  }
}

export default Select;
