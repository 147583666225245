import I18n from "i18n-js";
import React from "react";
import { func, shape } from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import {
  getProvidersList,
  postProviderContract,
} from "../actions/serviceProviderActions";
import List from "../../common/components/layout/List";
import Table from "../../common/components/elements/Table";
import { getLang } from "../../common/utils/langUtils";
import StatelessCheckbox from "../../common/components/form/StatelessCheckbox";

class ProviderContractsList extends List {
  static propTypes = {
    getProvidersList: func.isRequired,
    postProviderContract: func.isRequired,
    providers: shape().isRequired,
    auth: shape().isRequired,
    location: shape().isRequired, // react-router location
  };

  constructor() {
    super();

    this.dataTableRow = this.dataTableRow.bind(this);
    this.isActiveRow = this.isActiveRow.bind(this);
    this.dataTableRow = this.dataTableRow.bind(this);
    this.activeFilter = this.activeFilter.bind(this);
    this.toRenewSoonFilter = this.toRenewSoonFilter.bind(this);
  }

  /**
   * {@inheritdoc}
   */
  componentDidMount() {
    super.componentDidMount();

    this.props.getProvidersList();
  }

  /**
   * @return {Array}
   */
  dataTableHead() {
    return [
      I18n.t("pages.provider_contracts.head.beneficiaryName"),
      I18n.t("pages.provider_contracts.head.providerName"),
      I18n.t("pages.service_provider.head.startDate"),
      I18n.t("pages.service_provider.head.endDate"),
      I18n.t("pages.provider_contracts.head.enabled"),
      "",
    ];
  }

  /**
   * @param {Object} rowData
   *
   * @return {Boolean}
   */
  isActiveRow(rowData) {
    if (!rowData.endDate) {
      return true;
    }

    const endDate = new Date(rowData.endDate);
    const currentDate = new Date();

    return endDate > currentDate;
  }

  /**
   * @param {Object} rowData
   *
   * @return {Array}
   */
  dataTableRow(rowData) {
    const { beneficiary, provider } = rowData;
    const startDateLabel = rowData.startDate
      ? new Date(rowData.startDate).toLocaleDateString(getLang())
      : null;
    const currentDate = new Date();
    const endDate = new Date(rowData.endDate);
    const endDateLabel = rowData.endDate
      ? new Date(rowData.endDate).toLocaleDateString(getLang())
      : null;
    const enable = (
      <div className={`text-${!rowData.enabled ? "muted" : "success"}`}>
        <i
          className={`fa fa-${!rowData.enabled ? "hourglass" : "check"}`}
          aria-hidden="true"
        />
      </div>
    );
    const actions = rowData.endDate && endDate < currentDate && (
      <button
        onClick={() => this.props.postProviderContract(beneficiary.uuid, true)}
        className="btn btn--secondary"
      >
        {I18n.t("pages.provider_contracts.list.action:renew")}
      </button>
    );

    return [
      beneficiary.legalCompanyName,
      provider.legalCompanyName,
      startDateLabel,
      endDateLabel,
      enable,
      actions,
    ];
  }

  /**
   * @param {Object} data
   *
   * @return {Boolean}
   */
  activeFilter(data) {
    const now = moment();

    return data.enabled && moment(data.endDate) > now;
  }

  /**
   * @param {Object} data
   *
   * @return {Boolean}
   */
  toRenewSoonFilter(data) {
    const now = moment();
    const toRenewDate = now.clone().add(2, "months");

    return (
      data.enabled &&
      moment(data.endDate) > now &&
      moment(data.endDate) <= toRenewDate
    );
  }

  /**
   * @param {Object} data
   *
   * @return {Boolean}
   */
  toApproveFilter(data) {
    return !data.enabled;
  }

  /**
   * {@inheritdoc}
   */
  renderFilters() {
    const { filters } = this.state;

    return [
      <StatelessCheckbox
        key="filterActiveOnly"
        id="filterActiveOnly"
        label={I18n.t("filters.active_only")}
        checked={Boolean(filters.active)}
        onChangedValue={(checked) =>
          this.onChangeCheckboxFilter(checked, "active")
        }
      />,
      <StatelessCheckbox
        key="filterToRenewSoonOnly"
        id="filterToRenewSoonOnly"
        label={I18n.t("filters.to_renew_soon_only")}
        checked={Boolean(filters.toRenewSoon)}
        onChangedValue={(checked) =>
          this.onChangeCheckboxFilter(checked, "toRenewSoon")
        }
      />,
      <StatelessCheckbox
        key="filterWaitingApprovalOnly"
        id="filterWaitingApprovalOnly"
        label={I18n.t("filters.waiting_approval_only")}
        checked={Boolean(filters.approve)}
        onChangedValue={(checked) =>
          this.onChangeCheckboxFilter(checked, "approve")
        }
      />,
    ];
  }

  /**
   * {@inheritdoc}
   */
  renderContent() {
    const { providers, auth } = this.props;

    if (providers.inProgress) {
      return <div className="loader" />;
    }

    if (providers.error) {
      return <p>{I18n.t("common.loadingApiError")}</p>;
    }

    const list = providers.list
      .filter(this.getCustomFilter("active", this.activeFilter))
      .filter(this.getCustomFilter("toRenewSoon", this.toRenewSoonFilter))
      .filter(this.getCustomFilter("approve", this.toApproveFilter))
      .filter(
        (contract) => contract.provider.uuid === auth.userProfile.clientUuid,
      );

    return (
      <Table
        emptyContent={I18n.t("pages.provider_contracts.list.empty")}
        data={list}
        headData={this.dataTableHead()}
        rowData={this.dataTableRow}
        isActiveRow={this.isActiveRow}
        filters={this.renderFilters()}
        pagination
        onPageChanged={this.onPageChanged}
        initialPage={this.getCurrentPage()}
      />
    );
  }

  /**
   * {@inheritdoc}
   */
  renderTitle() {
    return I18n.t("pages.provider_contracts.list.title");
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  providers: state.providers,
});

const mapDispatchToProps = (dispatch) => ({
  getProvidersList: () => dispatch(getProvidersList()),
  postProviderContract: (uuid, isRenew) =>
    dispatch(postProviderContract(uuid, isRenew)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProviderContractsList);
