import I18n from "i18n-js";
import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { arrayOf, func, shape } from "prop-types";
import { connect } from "react-redux";
import { getInstallationsList } from "../actions/installationsActions";
import List from "../../common/components/layout/List";
import Table from "../../common/components/elements/Table";
import Quantity from "../../common/components/elements/Quantity";
import Select from "../../common/components/form/Select";
import { alphabetically } from "../../common/utils/filterUtils";
import Checkbox from "../../common/components/form/Checkbox";
import ClientUtils from "../../company/utils/ClientUtils";
import { ROUTE_INSTALLATIONS_IMPORT } from "../../../routes";
import ClientTypes from "../../company/models/ClientTypes";
import Roles from "../../users/models/Roles";
import { formatDate } from "../../common/utils/dateUtils";
import ArchiveRequestAction from "../actions/ArchiveRequestAction";

class InstallationsList extends List {
  static propTypes = {
    getInstallationsList: func.isRequired,
    installations: shape().isRequired,
    installationsList: arrayOf(shape()).isRequired,
    clients: arrayOf(shape()).isRequired,
    location: shape().isRequired,
    userProfile: shape().isRequired,
  };

  constructor(props) {
    super(props, {
      archivedInstallations: [],
    });

    this.refreshDataState = this.refreshDataState.bind(this);
    this.dataTableRow = this.dataTableRow.bind(this);
    this.archiveInstallation = this.archiveInstallation.bind(this);
  }

  /**
   * {@inheritdoc}
   */
  componentDidMount() {
    super.componentDidMount();

    this.props.getInstallationsList();
  }

  /**
   * @param {Object} prevState
   */
  refreshDataState(prevState) {
    const { client } = this.state.filters;

    if (!client || prevState.filters.client !== client) {
      this.setState({
        filters: {
          ...this.state.filters,
          site: null,
        },
      });
    }
  }

  getSitesFromInstallations() {
    const sites = {};

    this.props.installationsList
      .filter(this.getFilterBySelect("client"))
      .forEach((installation) => {
        sites[installation.site.uuid] = installation.site;
      });

    return Object.values(sites).sort((a, b) =>
      alphabetically(a.designation, b.designation),
    );
  }

  getFluidsFromInstallations() {
    const fluids = {};

    this.props.installationsList.forEach((installation) => {
      fluids[installation.fluid.uuid] = installation.fluid;
    });

    return Object.values(fluids).sort((a, b) =>
      alphabetically(a.designation, b.designation),
    );
  }

  archiveInstallation(installationUuid) {
    this.setState({
      archivedInstallations: [
        ...this.state.archivedInstallations,
        installationUuid,
      ],
    });
  }

  /**
   * @return {Array}
   */
  dataTableHead() {
    return [
      I18n.t("pages.installations.head.externalId"),
      I18n.t("pages.installations.head.client"),
      I18n.t("pages.installations.head.site"),
      I18n.t("pages.installations.head.name"),
      I18n.t("pages.installations.head.mark"),
      I18n.t("pages.installations.head.fluid"),
      I18n.t("pages.installations.head.nominal_load"),
      I18n.t("pages.installations.head.current_load"),
      I18n.t("pages.installations.head.to_repair"),
      I18n.t("pages.installations.head.must_be_checked_after_repair"),
      I18n.t("pages.installations.head.expecting_leak_detection"),
      "",
    ];
  }

  /**
   * @param {Object} rowData
   *
   * @return {Array}
   */
  dataTableRow(rowData) {
    const notSpecified = <small>{I18n.t("common.not_specified")}</small>;
    const inversedBooleanIndic = (fail, warn = null) => {
      const doWarn = true === warn;
      return (
        <i
          className={classNames({
            fa: true,
            "text-success": !fail && !doWarn,
            "text-danger": fail,
            "text-warning": !fail && doWarn,
            "fa-check": !fail && !doWarn,
            "fa-times": fail,
            "fa-warning": !fail && doWarn,
          })}
          aria-hidden="true"
        />
      );
    };

    const toRepairIndic = inversedBooleanIndic(rowData.hasLeaks);
    const toBeCheckedAfterRepairIndic = inversedBooleanIndic(
      rowData.mustBeCheckedAfterRepair,
    );
    const expectLeakDetectionIndic = (
      <span className={"text-nowrap"}>
        {inversedBooleanIndic(
          rowData.expectLeakDetection,
          rowData.expectLeakDetectionSoon,
        )}
        &nbsp;
        {rowData.nextLeakDetectionDate &&
          formatDate(rowData.nextLeakDetectionDate)}
      </span>
    );

    const archiveAction = !rowData.isDeleted &&
      !this.state.archivedInstallations.includes(rowData.uuid) && (
        <ArchiveRequestAction
          uuid={rowData.uuid}
          onDelete={this.archiveInstallation}
          key="archive"
        />
      );

    return [
      rowData.externalId || notSpecified,
      ClientUtils.renderLabel(rowData.client),
      rowData.site.designation,
      rowData.designation || notSpecified,
      rowData.mark || notSpecified,
      rowData.fluid.designation,
      <Quantity key="nominalLoad" value={rowData.nominalLoad} />,
      <Quantity key="currentLoad" value={rowData.currentLoad} />,
      toRepairIndic,
      toBeCheckedAfterRepairIndic,
      expectLeakDetectionIndic,
      archiveAction,
    ];
  }

  userCanImportInstallations() {
    const { roles, clientType } = this.props.userProfile;
    // At least MANAGER role & not a company user
    return roles.includes(Roles.MANAGER) && clientType !== ClientTypes.COMPANY;
  }

  /**
   * {@inheritdoc}
   */
  renderFilters() {
    const { clients } = this.props;
    const { filters } = this.state;

    return [
      <Select
        key="filterByClient"
        id="filterByClient"
        onChange={(event) =>
          this.onChangeFilter(event, "client", this.refreshDataState)
        }
        label={I18n.t("filters.by_client")}
        renderOption={(option) => (
          <option key={option.uuid} value={option.uuid}>
            {ClientUtils.renderLabel(option)}
          </option>
        )}
        options={clients}
        defaultValue={filters.client}
        placeholder={I18n.t("filters.by_client:default")}
        optional
      />,
      <Select
        key="filterBySite"
        id="filterBySite"
        onChange={(event) => this.onChangeFilter(event, "site")}
        label={I18n.t("filters.by_site")}
        renderOption={(option) => (
          <option key={option.uuid} value={option.uuid}>
            {option.designation}
          </option>
        )}
        options={this.getSitesFromInstallations()}
        defaultValue={filters.site}
        placeholder={I18n.t("filters.by_site:default")}
        optional
      />,
      <Select
        key="filterBySite"
        id="filterBySite"
        onChange={(event) => this.onChangeFilter(event, "fluid", null, true)}
        label={I18n.t("filters.by_fluid")}
        renderOption={(option) => (
          <option key={option.uuid} value={option.uuid}>
            {option.designation}
          </option>
        )}
        options={this.getFluidsFromInstallations()}
        defaultValue={filters.fluid}
        placeholder={I18n.t("filters.by_fluid:default")}
        optional
      />,
      <Checkbox
        key="filterWithLeaks"
        id="filterWithLeaks"
        label={I18n.t("filters.with_leaks")}
        checked={Boolean(filters.hasLeaks)}
        onChange={(checked) => this.onChangeCheckboxFilter(checked, "hasLeaks")}
      />,
      <Checkbox
        key="filterToCheckAfterRepair"
        id="filterToCheckAfterRepair"
        label={I18n.t("filters.must_be_checked_after_repair")}
        checked={Boolean(filters.mustBeCheckedAfterRepair)}
        onChange={(checked) =>
          this.onChangeCheckboxFilter(checked, "mustBeCheckedAfterRepair")
        }
      />,
      <Checkbox
        key="filterSoon"
        id="filterExpectLeakDetectionSoon"
        label={I18n.t("filters.expecting_leak_detection")}
        checked={Boolean(filters.expectLeakDetectionSoon)}
        onChange={(checked) =>
          this.onChangeCheckboxFilter(checked, "expectLeakDetectionSoon")
        }
      />,
      <Checkbox
        key="filterIsDeleted"
        id="filterIsDeleted"
        label={I18n.t("filters.is_deleted")}
        checked={Boolean(filters.isDeleted)}
        onChange={(checked) =>
          this.onChangeCheckboxFilter(checked, "isDeleted")
        }
      />,
      <Checkbox
        key="filterDisassembly"
        id="filterDisassembly"
        label={I18n.t("filters.is_disassembled")}
        checked={Boolean(filters.isDisassembly)}
        onChange={(checked) =>
          this.onChangeCheckboxFilter(checked, "isDisassembly")
        }
      />,
    ];
  }

  /**
   * {@inheritdoc}
   */
  renderContent() {
    const { installations, installationsList } = this.props;
    const showDeleted = ["true", true].includes(this.state.filters.isDeleted);
    const showDisassembled = ["true", true].includes(
      this.state.filters.isDisassembly,
    );

    if (installations.inProgress) {
      return <div className="loader" />;
    }

    if (installations.error) {
      return <p>{I18n.t("common.loadingApiError")}</p>;
    }

    const list = installationsList
      .filter(this.getFilterBySelect("client"))
      .filter(this.getFilterBySelect("site"))
      .filter(this.getFilterBySelect("fluid"))
      .filter(this.getFilterByCheckbox("hasLeaks"))
      .filter(this.getFilterByCheckbox("mustBeCheckedAfterRepair"))
      .filter(this.getFilterByCheckbox("expectLeakDetectionSoon"))
      .filter((installation) => {
        const isDeleted =
          installation.isDeleted ||
          this.state.archivedInstallations.includes(installation.uuid);
        return showDeleted ? isDeleted : !isDeleted;
      })
      .filter((installation) => {
        const isDisassembled = Boolean(installation.disassemblyAt);
        return showDisassembled ? isDisassembled : !isDisassembled;
      });
    const actions = (
      <div>
        {this.userCanImportInstallations() && (
          <Link to={ROUTE_INSTALLATIONS_IMPORT} className="btn btn-primary">
            <i className="fa fa-file" />{" "}
            {I18n.t("pages.installations.list.actions.import")}
          </Link>
        )}
      </div>
    );

    return (
      <Table
        emptyContent={I18n.t("pages.installations.list.empty")}
        data={list}
        headData={this.dataTableHead()}
        rowData={this.dataTableRow}
        filters={this.renderFilters()}
        pagination
        onPageChanged={this.onPageChanged}
        initialPage={this.getCurrentPage()}
        actions={actions}
      />
    );
  }

  /**
   * {@inheritdoc}
   */
  renderTitle() {
    return I18n.t("pages.installations.list.title");
  }
}

const mapStateToProps = (state) => ({
  clients: state.clients.list.sort((a, b) =>
    alphabetically(ClientUtils.renderLabel(a), ClientUtils.renderLabel(b)),
  ),
  installations: state.installations,
  installationsList: state.installations.list.sort(
    (a, b) =>
      alphabetically(a.client.legalCompanyName, b.client.legalCompanyName) ||
      alphabetically(a.site.designation, b.site.designation) ||
      alphabetically(a.designation, b.designation),
  ),
  userProfile: state.auth.userProfile,
});

const mapDispatchToProps = (dispatch) => ({
  getInstallationsList: () => dispatch(getInstallationsList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(InstallationsList);
