import React, { useState } from "react";
import PropTypes from "prop-types";
import StatelessTable from "../../common/components/elements/StatelessTable";
import StatelessCheckbox from "../../common/components/form/StatelessCheckbox";
import { EnumChoice } from "../../common/components/form/EnumChoice";
import TreatmentMode from "../models/TreatmentMode";
import Packaging from "../models/Packaging";

WasteContainersFormBatchActions.propTypes = {
  onApply: PropTypes.func.isRequired,
  enabled: PropTypes.bool.isRequired,
};

export default function WasteContainersFormBatchActions({ onApply, enabled }) {
  const [treatmentMode, setTreatmentMode] = useState(null);
  const [toDestruct, setToDestruct] = useState(false);
  const [toReturn, setToReturn] = useState(false);
  const [packaging, setPackaging] = useState(null);

  const submit = () => {
    const data = {
      treatmentMode,
      toDestruct,
      toReturn,
      packaging,
    };

    // Clean null values we don't want to apply
    Object.entries(data).forEach(([key, value]) => {
      if (null === value) {
        delete data[key];
      }
    });

    onApply(data);
  };

  return (
    <div className="table-batch">
      <div className="row">
        <StatelessTable
          tableClasses="table-sm table--no-bg table--unbordered table-form"
          headData={[
            /* eslint-disable react/jsx-key */
            <div className="text-center">Traitement</div>,
            <div className="text-center">Destruction contenant</div>,
            <div className="text-center">Renvoi contenant</div>,
            <div className="text-center">Support logistique</div>,
            /* eslint-enable react/jsx-key */
          ]}
          rowData={(row) => row}
          data={[
            [
              /* eslint-disable react/jsx-key */
              <EnumChoice
                id="batch-treatmentMode"
                label={false}
                optional
                enumClass={TreatmentMode}
                defaultValue={treatmentMode}
                onChangedValue={setTreatmentMode}
              />,
              <StatelessCheckbox
                id="batch-toDestruct"
                label={false}
                className="text-center"
                onChangedValue={setToDestruct}
                checked={toDestruct}
              />,
              <StatelessCheckbox
                id="batch-toReturn"
                label={false}
                className="text-center"
                onChangedValue={setToReturn}
                checked={toReturn}
              />,
              <EnumChoice
                id="batch-packaging"
                label={false}
                optional
                enumClass={Packaging}
                defaultValue={packaging}
                onChangedValue={setPackaging}
              />,
              /* eslint-enable react/jsx-key */
            ],
          ]}
        />
      </div>
      <div className="row">
        <div className="col-12 d-flex">
          <button
            type="button"
            className="btn btn-dark btn--radius-xs flex-grow-0 mr-1"
            onClick={() => {
              setPackaging(null);
              setToDestruct(false);
              setToReturn(false);
              setTreatmentMode(null);
            }}
          >
            Réinitialiser
          </button>
          <button
            type="button"
            className="btn btn-success btn--radius-xs flex-grow-1"
            onClick={submit}
            disabled={!enabled}
          >
            Appliquer au(x) contenant(s) sélectionné(s)
          </button>
        </div>
      </div>
    </div>
  );
}
