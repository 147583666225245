import React from "react";
import PropTypes from "prop-types";
import archiveContainerRequestMutation from "../graphql/mutations/archiveRequest.graphql";
import { errorAlert, successAlert } from "../../common/actions/alertActions";
import { useDispatch } from "react-redux";
import { useMutation } from "../../common/api/GraphQLClient";
import { trans } from "../../../translations";
import GraphQLErrors from "../../common/api/GraphQLErrors";

ArchiveRequestAction.propTypes = {
  uuid: PropTypes.string.isRequired,
  onDelete: PropTypes.func,
};

ArchiveRequestAction.defaultProps = {
  onDelete: null,
};

export default function ArchiveRequestAction({ uuid, onDelete }) {
  const dispatch = useDispatch();
  const [deleteRequest, { loading }] = useMutation(
    archiveContainerRequestMutation,
  );

  const onClick = () => {
    if (
      !window.confirm(trans(`pages.containers.actions.delete.alert.message`))
    ) {
      return;
    }

    deleteRequest({ variables: { uuid } })
      .then(() => {
        dispatch(
          successAlert(trans(`pages.containers.actions.delete.alert.success`)),
        );
        if (null !== onDelete) {
          onDelete(uuid);
        }
      })
      .catch(({ graphQLErrors }) => {
        const errorCodes = graphQLErrors.map((error) => error.code);
        if (errorCodes.includes(GraphQLErrors.DOMAIN_ERROR)) {
          dispatch(
            errorAlert(trans(`pages.containers.actions.delete.domain.error`)),
          );
        } else {
          dispatch(
            errorAlert(trans(`pages.containers.actions.delete.alert.error`)),
          );
        }
      });
  };

  return (
    <button
      type="button"
      className="action"
      onClick={onClick}
      disabled={loading}
      title={trans(`pages.containers.actions.delete.label`)}
    >
      <i className="fa fa-trash" aria-hidden="true" />
    </button>
  );
}
