import I18n from "i18n-js";
import React, { Component } from "react";

export default class PrivacyPolicy extends Component {
  /**
   * {@inheritdoc}
   */
  render() {
    const Content = I18n.t("Components.PrivacyPolicy");

    return <Content {...this.props} />;
  }
}
