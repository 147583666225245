import Enum from "../../common/models/Enum";
import InterventionType from "./InterventionType";

/**
 * Available purpose values for an Intervention
 */
class Purpose extends Enum {
  // Drainage
  static RECUPERATION = "recuperation";

  static TRANSFER = "transfer";

  // Filling
  static FILLING_AFTER_TRANSFER = "filling_after_transfer";

  static RETROFIT = "retrofit";

  static MAINTENANCE_LOAD = "maintenance_load";

  static COMMISSIONING = "commissioning";

  // Leak
  static CONTROL_PERIODIC = "control_periodic";

  static CONTROL_NON_PERIODIC = "control_non_periodic";

  // Assembly
  static ASSEMBLY = "assembly";

  // Disassembly
  static DISASSEMBLY = "disassembly";

  static values = [
    Purpose.RECUPERATION,
    Purpose.TRANSFER,
    Purpose.FILLING_AFTER_TRANSFER,
    Purpose.RETROFIT,
    Purpose.MAINTENANCE_LOAD,
    Purpose.COMMISSIONING,
    Purpose.CONTROL_PERIODIC,
    Purpose.CONTROL_NON_PERIODIC,
    Purpose.ASSEMBLY,
    Purpose.DISASSEMBLY,
  ];

  static readables = {
    [Purpose.RECUPERATION]: "enum.purpose.recuperation",
    [Purpose.TRANSFER]: "enum.purpose.transfer",
    [Purpose.FILLING_AFTER_TRANSFER]: "enum.purpose.filling_after_transfer",
    [Purpose.RETROFIT]: "enum.purpose.retrofit",
    [Purpose.MAINTENANCE_LOAD]: "enum.purpose.maintenance_load",
    [Purpose.COMMISSIONING]: "enum.purpose.commissioning",
    [Purpose.CONTROL_PERIODIC]: "enum.purpose.control_periodic",
    [Purpose.CONTROL_NON_PERIODIC]: "enum.purpose.control_non_periodic",
    [Purpose.ASSEMBLY]: "enum.purpose.assembly",
    [Purpose.DISASSEMBLY]: "enum.purpose.disassembly",
  };

  static PURPOSES_PER_INTERVENTION_TYPE = {
    [InterventionType.DRAINAGE]: [Purpose.RECUPERATION, Purpose.TRANSFER],
    [InterventionType.FILLING]: [
      Purpose.COMMISSIONING,
      Purpose.MAINTENANCE_LOAD,
      Purpose.FILLING_AFTER_TRANSFER,
      Purpose.RETROFIT,
    ],
    [InterventionType.LEAK]: [
      Purpose.CONTROL_PERIODIC,
      Purpose.CONTROL_NON_PERIODIC,
    ],
    [InterventionType.LEAK_REPAIR]: [Purpose.CONTROL_NON_PERIODIC],
    [InterventionType.ASSEMBLY]: [Purpose.ASSEMBLY],
    [InterventionType.DISASSEMBLY]: [Purpose.DISASSEMBLY],
  };

  static isValidPurpose(purpose, intervention) {
    return this.PURPOSES_PER_INTERVENTION_TYPE[intervention]?.includes(purpose);
  }
}

export default Purpose;
