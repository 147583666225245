import React, { useState } from "react";
import PropTypes from "prop-types";
import WasteRecoveryRequestStatus from "../models/WasteRecoveryRequestStatus";
import RawSubmit from "../../common/components/form/RawSubmit";
import { errorAlert, successAlert } from "../../common/actions/alertActions";
import { useMutation } from "../../common/api/GraphQLClient";
import acceptWasteRecoveryRequestAsAuthorMutation from "../graphql/mutations/acceptWasteRecoveryRequestAsAuthor.graphql";
import rejectWasteRecoveryRequestAsAuthorMutation from "../graphql/mutations/rejectWasteRecoveryRequestAsAuthor.graphql";
import { useDispatch } from "react-redux";
import Textarea from "../../common/components/form/Textarea";
import StatelessTextInput from "../../common/components/form/StatelessTextInput";
import {
  DocumentsList,
  RejectedOutcome,
  SuccessOutcome,
  WaitingOutcome,
} from "./RequestLifecycleOutcome";
import ConfirmRequestDispatchAction from "./ConfirmRequestDispatchAction";

RequestStatusLifecycleAuthorForm.propTypes = {
  status: PropTypes.instanceOf(WasteRecoveryRequestStatus).isRequired,
  uuid: PropTypes.string.isRequired,
  className: PropTypes.string,
  documents: DocumentsList.propTypes.documents,
};

RequestStatusLifecycleAuthorForm.defaultProps = {
  className: null,
};

export default function RequestStatusLifecycleAuthorForm({
  uuid,
  status,
  documents,
  className,
}) {
  return (
    <div className={className}>
      {(() => {
        switch (status.value) {
          case WasteRecoveryRequestStatus.CREATED:
          case WasteRecoveryRequestStatus.AWAITING_ESTIMATE:
            return (
              <WaitingOutcome>
                <p>
                  <i>
                    En attente d&apos;une action de la part du service
                    commercial
                  </i>
                </p>
              </WaitingOutcome>
            );

          case WasteRecoveryRequestStatus.AUTHOR_APPROVED:
            return (
              <WaitingOutcome>
                <p>
                  <i>En attente de finalisation par notre service logistique</i>
                </p>
              </WaitingOutcome>
            );

          case WasteRecoveryRequestStatus.COMMERCIAL_APPROVED:
            return (
              <div className="d-flex flex-column h-100 w-100 justify-content-between">
                <AcceptForm uuid={uuid} />
                <RejectForm uuid={uuid} className="mt-4" />
              </div>
            );

          case WasteRecoveryRequestStatus.COMMERCIAL_REJECTED:
          case WasteRecoveryRequestStatus.AUTHOR_REJECTED:
            return <RejectedOutcome />;

          case WasteRecoveryRequestStatus.AWAITING_DISPATCH:
            return (
              <SuccessOutcome>
                <DocumentsList documents={documents} />
                <ConfirmRequestDispatchAction uuid={uuid} />
              </SuccessOutcome>
            );

          case WasteRecoveryRequestStatus.CLOSED:
            return (
              <SuccessOutcome>
                <DocumentsList documents={documents} />
              </SuccessOutcome>
            );
        }
      })()}
    </div>
  );
}

const LifecycleForm = {
  propTypes: {
    uuid: PropTypes.string.isRequired,
  },
};

AcceptForm.propTypes = {
  ...LifecycleForm.propTypes,
};

function AcceptForm({ uuid, className }) {
  const dispatch = useDispatch();
  const [accept, { loading }] = useMutation(
    acceptWasteRecoveryRequestAsAuthorMutation,
  );
  const [reference, setReference] = useState(null);
  const [commercialReference, setCommercialReference] = useState(null);

  const isValid = () => {
    return commercialReference;
  };

  const onSubmit = (e) => {
    e.preventDefault();

    accept({
      variables: {
        uuid,
        payload: {
          commercialReference,
          authorReference: reference,
        },
      },
    })
      .then(() => dispatch(successAlert("Enregistré avec succès")))
      .catch(() => dispatch(errorAlert("Une erreur est survenue")));
  };

  const inputProps = {
    layout: "horizontal",
    labelClassName: "col-xl-4 text-xl-right",
    widgetClassName: "col-xl-8",
  };

  return (
    <form onSubmit={onSubmit} className={className}>
      <StatelessTextInput
        id="reference"
        label="Votre référence"
        defaultValue={reference}
        onChangedValue={setReference}
        optional
        {...inputProps}
      />

      <StatelessTextInput
        id="commercialReference"
        label="Notre référence"
        defaultValue={commercialReference}
        onChangedValue={setCommercialReference}
        {...inputProps}
      />

      <div className="row">
        <div className="offset-xl-4 col-xl-8">
          <RawSubmit
            btnClasses="btn btn-primary btn-block btn-lg"
            loading={loading}
            disabled={!isValid()}
          >
            Accepter la proposition
          </RawSubmit>
        </div>
      </div>
    </form>
  );
}

RejectForm.propTypes = {
  ...LifecycleForm.propTypes,
};

function RejectForm({ uuid, className }) {
  const dispatch = useDispatch();
  const [reject, { loading }] = useMutation(
    rejectWasteRecoveryRequestAsAuthorMutation,
  );
  const [reason, setReason] = useState(null);

  const onSubmit = (e) => {
    e.preventDefault();

    reject({
      variables: { uuid, reason },
    })
      .then(() => dispatch(successAlert("Enregistré avec succès")))
      .catch(() => dispatch(errorAlert("Une erreur est survenue")));
  };

  return (
    <form onSubmit={onSubmit} className={className}>
      <Textarea
        id="reject-reason"
        label="Motif du refus"
        layout="horizontal"
        labelClassName="col-lg-4 col-xl-4 text-xl-right"
        widgetClassName="col-lg-8 col-xl-8"
        defaultValue={reason}
        onChangedValue={setReason}
        optional
      />

      <div className="row">
        <div className="offset-xl-4 col-xl-8">
          <RawSubmit
            btnClasses="btn btn-danger btn-block btn-lg"
            loading={loading}
          >
            Refuser la proposition
          </RawSubmit>
        </div>
      </div>
    </form>
  );
}
