import {
  POST_FORGOT_PASSWORD_IN_PROGRESS,
  POST_FORGOT_PASSWORD_SUCCESS,
  POST_FORGOT_PASSWORD_FAIL,
  PUT_CHANGE_PASSWORD_IN_PROGRESS,
  PUT_CHANGE_PASSWORD_SUCCESS,
  PUT_CHANGE_PASSWORD_FAIL,
  RESET_FORGOT_PASSWORD,
} from "../constants";

const initialState = {
  inProgress: false,
  success: false,
  error: null,
};

/**
 * Forgot password reducer
 *
 * @param {Object} state
 * @param {Object} action
 *
 * @return {Object}
 */
export default function forgotPassword(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case POST_FORGOT_PASSWORD_IN_PROGRESS:
    case PUT_CHANGE_PASSWORD_IN_PROGRESS:
      return {
        ...state,
        inProgress: true,
        success: false,
        error: null,
      };

    case POST_FORGOT_PASSWORD_SUCCESS:
    case PUT_CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        inProgress: false,
        success: true,
      };

    case POST_FORGOT_PASSWORD_FAIL:
    case PUT_CHANGE_PASSWORD_FAIL:
      return {
        ...state,
        inProgress: false,
        error: payload.error || null,
      };

    case RESET_FORGOT_PASSWORD:
      return initialState;

    default:
      return state;
  }
}
