import I18n from "i18n-js";
import React from "react";
import { func, shape } from "prop-types";
import { connect } from "react-redux";
import { ROUTE_MY_ENTERPRISE } from "../../../routes";
import FormPage from "../../common/components/layout/FormPage";
import EnterpriseForm from "../components/EnterpriseForm";
import {
  updateEnterprise,
  getEnterpriseLogo,
} from "../actions/enterpriseActions";
import { getClientInfo } from "../actions/clientsActions";

class UpdateEnterprise extends FormPage {
  static propTypes = {
    updateEnterprise: func.isRequired,
    getEnterpriseLogo: func.isRequired,
    getClientInfo: func.isRequired,
    enterprise: shape().isRequired,
    client: shape().isRequired,
    match: shape().isRequired,
  };

  constructor() {
    super();

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    const { getEnterpriseLogo, getClientInfo, match } = this.props;
    let clientInfo = undefined;
    if (this.props.location.state !== undefined) {
      clientInfo = this.props.location.state.clientInfo;
    }

    if (clientInfo === undefined) {
      getClientInfo(match.params.uuid);
    } else {
      this.props.client.info = clientInfo;
    }

    getEnterpriseLogo(match.params.uuid);
  }

  /** {@inheritdoc} */
  getBackRoute() {
    return ROUTE_MY_ENTERPRISE;
  }

  /** {@inheritdoc} */
  getTitle() {
    const { client } = this.props;

    return (
      <div
        dangerouslySetInnerHTML={{
          __html: I18n.t("pages.my_enterprise.update.title", {
            enterprise: `<span class="form-title--secondary">${client.info.legalCompanyName}</span>`,
          }),
        }}
      />
    );
  }

  /**
   * @param {object} enterprise
   */
  onSubmit(enterprise) {
    const { updateEnterprise, match } = this.props;

    updateEnterprise(match.params.uuid, enterprise);
  }

  /** {@inheritdoc} */
  renderContent() {
    const { enterprise, client } = this.props;

    return (
      <EnterpriseForm
        enterprise={{
          ...client.info,
          logo: enterprise.logo,
        }}
        saved={enterprise.saved || false}
        errors={enterprise.errors}
        loading={enterprise.saving}
        onSubmit={this.onSubmit}
        goBack={this.goBack}
      />
    );
  }

  render() {
    const { enterprise, client } = this.props;

    if (enterprise.logo === undefined || client.info === undefined) {
      return <div className="loader" />;
    }

    return super.render();
  }
}

const mapStateToProps = (state) => ({
  enterprise: state.enterprise,
  client: state.client,
});

const mapDispatchToProps = (dispatch) => ({
  getEnterpriseLogo: (uuid) => dispatch(getEnterpriseLogo(uuid)),
  updateEnterprise: (uuid, enterprise) =>
    dispatch(updateEnterprise(uuid, enterprise)),
  getClientInfo: (uuid) => dispatch(getClientInfo(uuid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateEnterprise);
