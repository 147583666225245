import React, { Component } from "react";
import { func, number, shape } from "prop-types";
import { connect } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter,
} from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import classnames from "classnames";
import Alert from "../components/elements/Alert";
import AuthRoute from "../components/routes/AuthRoute";
import PrivateRoute from "../components/routes/PrivateRoute";
import PublicRoute from "../components/routes/PublicRoute";
import AdminRoute from "../components/routes/AdminRoute";
import { authAccess } from "../../authentication/actions/authActions";
import Dashboard from "../../dashboard/pages/Dashboard";
import MyEnterpriseList from "../../company/pages/MyEnterpriseList";
import SitesList from "../../company/pages/SitesList";
import ImportSites from "../../company/pages/ImportSites";
import InstallationsList from "../../installation/pages/InstallationsList";
import ImportInstallations from "../../installation/pages/ImportInstallations";
import MobileSitesList from "../../company/pages/MobileSitesList";
import CreateSite from "../../company/pages/CreateSite";
import CreateMobileSite from "../../company/pages/CreateMobileSite";
import UpdateSite from "../../company/pages/UpdateSite";
import UpdateMobileSite from "../../company/pages/UpdateMobileSite";
import CarriersList from "../../carriers/pages/CarriersList";
import CreateCarrier from "../../carriers/pages/CreateCarrier";
import UpdateCarrier from "../../carriers/pages/UpdateCarrier";
import LeakDetectorsList from "../../detectors/pages/LeakDetectorsList";
import LeakDetector from "../../detectors/pages/LeakDetector";
import CreateLeakDetector from "../../detectors/pages/CreateLeakDetector";
import UpdateLeakDetector from "../../detectors/pages/UpdateLeakDetector";
import ContainersList from "../../containers/pages/ContainersList";
import Intervention from "../../intervention/pages/Intervention";
import FibsdList from "../../fibsd/pages/FibsdList";
import CompleteFibsd from "../../fibsd/pages/CompleteFibsd";
import ShippingsList from "../../shipping/pages/ShippingsList";
import ClientList from "../../company/pages/ClientsList";
import ImportClients from "../../company/pages/ImportClients";
import CreateClient from "../../company/pages/CreateClient";
import UpdateClient from "../../company/pages/UpdateClient";
import UsersList from "../../users/pages/UsersList";
import CreateUser from "../../users/pages/CreateUser";
import UpdateUser from "../../users/pages/UpdateUser";
import ProvidersList from "../../service_provider/pages/ProvidersList";
import ProviderContractsList from "../../service_provider/pages/ProviderContractsList";
import FluidSummaryList from "../../intervention/pages/FluidSummaryList";
import Login from "../../authentication/pages/Login";
import ChangePassword from "../../authentication/pages/ChangePassword";
import ForgotPassword from "../../authentication/pages/ForgotPassword";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import CGU from "../pages/CGU";
import Forbidden from "../pages/Forbidden";
import NotFound from "../pages/NotFound";
import UpdateEnterprise from "../../company/pages/UpdateEnterprise";
import {
  ROUTE_CARRIER,
  ROUTE_CARRIERS,
  ROUTE_CARRIERS_ADD,
  ROUTE_CGU,
  ROUTE_CHANGE_PASSWORD,
  ROUTE_CLIENT,
  ROUTE_CLIENTS,
  ROUTE_CLIENTS_ADD,
  ROUTE_CLIENTS_IMPORT,
  ROUTE_ENTERPRISE,
  ROUTE_COMPANY_WASTE_RECOVERY_REQUESTS,
  ROUTE_CONTAINERS,
  ROUTE_FIBSD,
  ROUTE_FIBSDS,
  ROUTE_FLUID_SUMMARIES,
  ROUTE_FORBIDDEN,
  ROUTE_FORGOT_PASSWORD,
  ROUTE_HOME,
  ROUTE_INSTALLATIONS,
  ROUTE_INSTALLATIONS_IMPORT,
  ROUTE_INTERVENTION,
  ROUTE_INTERVENTIONS,
  ROUTE_INTERVENTIONS_PLANNED,
  ROUTE_INTERVENTIONS_PLANNED_ADD,
  ROUTE_LEAK_DETECTOR,
  ROUTE_LEAK_DETECTOR_UPDATE,
  ROUTE_LEAK_DETECTORS,
  ROUTE_LEAK_DETECTORS_ADD,
  ROUTE_LOGIN,
  ROUTE_MOBILE_SITE,
  ROUTE_MOBILE_SITES,
  ROUTE_MOBILE_SITES_ADD,
  ROUTE_MY_ENTERPRISE,
  ROUTE_MY_WASTE_RECOVERY_REQUESTS,
  ROUTE_NOT_FOUND,
  ROUTE_PRIVACY_POLICY,
  ROUTE_PROVIDER_CONTRACTS,
  ROUTE_PROVIDERS,
  ROUTE_SHIPPINGS,
  ROUTE_SITE,
  ROUTE_SITES,
  ROUTE_SITES_ADD,
  ROUTE_SITES_IMPORT,
  ROUTE_USER,
  ROUTE_USERS,
  ROUTE_USERS_ADD,
  ROUTE_WASTE_RECOVERY_REQUEST_STATUS,
  ROUTE_WASTE_RECOVERY_REQUEST_SUMMARY,
  ROUTE_WASTE_RECOVERY_REQUESTS_CREATE,
  ROUTE_WASTE_RECOVERY_REQUESTS_CREATE_ARTICLES,
  ROUTE_WASTE_RECOVERY_REQUESTS_CREATE_CONTAINERS,
  ROUTE_WASTE_RECOVERY_REQUESTS_CREATE_SUMMARY,
  ROUTE_WASTE_RECOVERY_REQUESTS_CREATE_TRANSPORT,
  ROUTE_TRACKDECHETS_OAUTH_STATUS,
  ROUTE_TRACKDECHETS_OAUTH_CALLBACK,
  ROUTE_INTERVENTIONS_PLANNED_UPDATE,
} from "../../../routes";
import { ApolloProvider } from "@apollo/client";
import graphql from "../api/GraphQLClient";
import MyWasteRecoveryRequestsList from "../../waste/pages/MyWasteRecoveryRequestsList";
import CompanyWasteRecoveryRequestsList from "../../waste/pages/CompanyWasteRecoveryRequestsList";
import CreateWasteRecoveryRequest from "../../waste/pages/CreateWasteRecoveryRequest";
import CreateWasteRecoveryRequestContainers from "../../waste/pages/CreateWasteRecoveryRequestContainers";
import CreateWasteRecoveryRequestArticles from "../../waste/pages/CreateWasteRecoveryRequestArticles";
import CreateWasteRecoveryRequestTransport from "../../waste/pages/CreateWasteRecoveryRequestTransport";
import CreateWasteRecoveryRequestSummary from "../../waste/pages/CreateWasteRecoveryRequestSummary";
import WasteRecoveryRequestStatus from "../../waste/pages/WasteRecoveryRequestStatus";
import { ProvideAuth } from "../../authentication/contexts/useAuth";
import { clearError } from "../actions/errorActions";
import ShowWasteRecoveryRequestSummary from "../../waste/pages/ShowWasteRecoveryRequestSummary";
import TrackdechetsStatusPage from "../../trackdechets/pages/TrackdechetsStatusPage";
import TrackdechetsCallback from "../../trackdechets/pages/TrackdechetsCallback";
import InterventionListAll from "../../intervention/pages/InterventionListAll";
import InterventionListPlanned from "../../intervention/pages/InterventionListPlanned";
import CreatePlannedIntervention from "../../intervention/pages/CreatePlannedIntervention";
import UpdatePlannedIntervention from "../../intervention/pages/UpdatePlannedIntervention";

class App extends Component {
  static propTypes = {
    auth: shape().isRequired,
    authAccess: func.isRequired,
    error: number,
    clearError: func.isRequired,
  };

  static defaultProps = {
    error: null,
  };

  /**
   * {@inheritdoc}
   */
  componentDidMount() {
    this.props.authAccess();
  }

  /**
   * {@inheritdoc}
   */
  render() {
    const appClasses = classnames("app", {
      public: !this.props.auth.logged,
    });
    const { clearError, error } = this.props;

    return (
      <ApolloProvider client={graphql}>
        <ProvideAuth>
          <div className={appClasses}>
            <Alert />
            <Router>
              <QueryParamProvider ReactRouterRoute={Route}>
                <Switch>
                  {/* In case there is an identified global error, force rendering the corresponding error page */}
                  <PublicRoute
                    exact
                    path={error === 404 ? "*" : ROUTE_NOT_FOUND}
                    component={withRouter(NotFound)}
                    onBack={clearError}
                  />
                  <PublicRoute
                    exact
                    path={error === 403 ? "*" : ROUTE_FORBIDDEN}
                    component={withRouter(Forbidden)}
                    onBack={clearError}
                  />

                  <PrivateRoute
                    exact
                    path={ROUTE_HOME}
                    component={withRouter(Dashboard)}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTE_MY_ENTERPRISE}
                    component={withRouter(MyEnterpriseList)}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTE_SITES}
                    component={withRouter(SitesList)}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTE_SITES_IMPORT}
                    component={withRouter(ImportSites)}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTE_SITES_ADD}
                    component={withRouter(CreateSite)}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTE_SITE}
                    component={withRouter(UpdateSite)}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTE_MOBILE_SITES}
                    component={withRouter(MobileSitesList)}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTE_MOBILE_SITES_ADD}
                    component={withRouter(CreateMobileSite)}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTE_MOBILE_SITE}
                    component={withRouter(UpdateMobileSite)}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTE_CARRIERS}
                    component={withRouter(CarriersList)}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTE_CARRIERS_ADD}
                    component={withRouter(CreateCarrier)}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTE_CARRIER}
                    component={withRouter(UpdateCarrier)}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTE_LEAK_DETECTORS}
                    component={withRouter(LeakDetectorsList)}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTE_LEAK_DETECTORS_ADD}
                    component={withRouter(CreateLeakDetector)}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTE_LEAK_DETECTOR}
                    component={withRouter(LeakDetector)}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTE_LEAK_DETECTOR_UPDATE}
                    component={withRouter(UpdateLeakDetector)}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTE_CONTAINERS}
                    component={withRouter(ContainersList)}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTE_INTERVENTIONS}
                    component={withRouter(InterventionListAll)}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTE_INTERVENTIONS_PLANNED}
                    component={withRouter(InterventionListPlanned)}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTE_INTERVENTIONS_PLANNED_ADD}
                    component={withRouter(CreatePlannedIntervention)}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTE_INTERVENTIONS_PLANNED_UPDATE}
                    component={withRouter(UpdatePlannedIntervention)}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTE_INTERVENTION}
                    component={withRouter(Intervention)}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTE_FLUID_SUMMARIES}
                    component={withRouter(FluidSummaryList)}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTE_FIBSDS}
                    component={withRouter(FibsdList)}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTE_FIBSD}
                    component={withRouter(CompleteFibsd)}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTE_SHIPPINGS}
                    component={withRouter(ShippingsList)}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTE_CLIENTS}
                    component={withRouter(ClientList)}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTE_CLIENTS_IMPORT}
                    component={withRouter(ImportClients)}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTE_CLIENTS_ADD}
                    component={withRouter(CreateClient)}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTE_CLIENT}
                    component={withRouter(UpdateClient)}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTE_INSTALLATIONS}
                    component={withRouter(InstallationsList)}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTE_INSTALLATIONS_IMPORT}
                    component={withRouter(ImportInstallations)}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTE_ENTERPRISE}
                    component={withRouter(UpdateEnterprise)}
                  />

                  {/* Waste recovery requests */}
                  <PrivateRoute
                    exact
                    path={ROUTE_COMPANY_WASTE_RECOVERY_REQUESTS}
                    component={withRouter(CompanyWasteRecoveryRequestsList)}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTE_MY_WASTE_RECOVERY_REQUESTS}
                    component={withRouter(MyWasteRecoveryRequestsList)}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTE_WASTE_RECOVERY_REQUESTS_CREATE}
                    component={withRouter(CreateWasteRecoveryRequest)}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTE_WASTE_RECOVERY_REQUESTS_CREATE_CONTAINERS}
                    component={withRouter(CreateWasteRecoveryRequestContainers)}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTE_WASTE_RECOVERY_REQUESTS_CREATE_ARTICLES}
                    component={withRouter(CreateWasteRecoveryRequestArticles)}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTE_WASTE_RECOVERY_REQUESTS_CREATE_TRANSPORT}
                    component={withRouter(CreateWasteRecoveryRequestTransport)}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTE_WASTE_RECOVERY_REQUESTS_CREATE_SUMMARY}
                    component={withRouter(CreateWasteRecoveryRequestSummary)}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTE_WASTE_RECOVERY_REQUEST_SUMMARY}
                    component={withRouter(ShowWasteRecoveryRequestSummary)}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTE_WASTE_RECOVERY_REQUEST_STATUS}
                    component={withRouter(WasteRecoveryRequestStatus)}
                  />

                  {/* trackDechets */}
                  <PrivateRoute
                    exact
                    path={ROUTE_TRACKDECHETS_OAUTH_STATUS}
                    component={withRouter(TrackdechetsStatusPage)}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTE_TRACKDECHETS_OAUTH_CALLBACK}
                    component={withRouter(TrackdechetsCallback)}
                  />

                  <AdminRoute
                    exact
                    path={ROUTE_USERS}
                    component={withRouter(UsersList)}
                  />
                  <AdminRoute
                    exact
                    path={ROUTE_USERS_ADD}
                    component={withRouter(CreateUser)}
                  />
                  <AdminRoute
                    exact
                    path={ROUTE_USER}
                    component={withRouter(UpdateUser)}
                  />
                  <AdminRoute
                    exact
                    path={ROUTE_PROVIDERS}
                    component={withRouter(ProvidersList)}
                  />
                  <AdminRoute
                    exact
                    path={ROUTE_PROVIDER_CONTRACTS}
                    component={withRouter(ProviderContractsList)}
                  />
                  <AuthRoute exact path={ROUTE_LOGIN} component={Login} />
                  <AuthRoute
                    exact
                    path={ROUTE_CHANGE_PASSWORD}
                    component={withRouter(ChangePassword)}
                  />
                  <AuthRoute
                    exact
                    path={ROUTE_FORGOT_PASSWORD}
                    component={withRouter(ForgotPassword)}
                  />

                  <PublicRoute
                    exact
                    path={ROUTE_PRIVACY_POLICY}
                    component={withRouter(PrivacyPolicy)}
                  />
                  <PublicRoute
                    exact
                    path={ROUTE_CGU}
                    component={withRouter(CGU)}
                  />

                  <PublicRoute path="*" component={withRouter(NotFound)} />
                </Switch>
              </QueryParamProvider>
            </Router>
          </div>
        </ProvideAuth>
      </ApolloProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  error: state.error.code,
});

const mapDispatchToProps = (dispatch) => ({
  authAccess: () => dispatch(authAccess()),
  clearError: () => dispatch(clearError()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
