import {
  IMPORT_INSTALLATIONS_IN_PROGRESS,
  IMPORT_INSTALLATIONS_SUCCESS,
  IMPORT_INSTALLATIONS_FAIL,
} from "../constants";

const initialState = {
  error: undefined,
  success: null,
  tsvReport: null,
};

/**
 * Import installations reducer
 *
 * @param {Object} state
 * @param {Object} action
 *
 * @return {Object}
 */
export default function importInstallations(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case IMPORT_INSTALLATIONS_IN_PROGRESS:
      return {
        ...initialState,
      };

    case IMPORT_INSTALLATIONS_SUCCESS:
      return {
        ...state,
        success: true,
        error: null,
        tsvReport: payload.tsvReport,
      };

    case IMPORT_INSTALLATIONS_FAIL:
      return {
        ...state,
        success: false,
        error: payload.error,
      };

    default:
      return state;
  }
}
