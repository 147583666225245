import I18n from "i18n-js";
import React from "react";
import { func, shape } from "prop-types";
import { connect } from "react-redux";
import { ROUTE_LEAK_DETECTORS } from "../../../routes";
import { getLang } from "../../common/utils/langUtils";
import { getLeakDetectorInfos } from "../actions/leakDetectorsActions";
import FormPage from "../../common/components/layout/FormPage";
import ClientUtils from "../../company/utils/ClientUtils";

class LeakDetector extends FormPage {
  static propTypes = {
    clients: shape().isRequired,
    getLeakDetectorInfos: func.isRequired,
    leakDetector: shape().isRequired,
    match: shape().isRequired,
  };

  /**
   * {@inheritdoc}
   */
  componentDidMount() {
    this.props.getLeakDetectorInfos(this.props.match.params.uuid);
  }

  /**
   * {@inheritdoc}
   */
  getTitle() {
    return I18n.t("pages.leak_detectors.view.title");
  }

  /**
   * {@inheritdoc}
   */
  getBackRoute() {
    return ROUTE_LEAK_DETECTORS;
  }

  /**
   * {@inheritdoc}
   */
  renderContent() {
    const { leakDetector, clients } = this.props;
    const { infos } = leakDetector;

    if (infos === undefined || clients.inProgress) {
      return <div className="loader" />;
    }

    if (infos === null) {
      return <p>{I18n.t("pages.leak_detectors.view.unknown")}</p>;
    }

    const lastInspection = new Date(
      infos.lastInspectionDate,
    ).toLocaleDateString(getLang());
    const expirationDate = new Date(infos.expirationDate).toLocaleDateString(
      getLang(),
    );

    const currentDate = new Date();
    const expired = currentDate > new Date(infos.expirationDate);
    const expiration = (
      <div className={`text-${expired ? "danger" : "success"}`}>
        <i
          className={`fa fa-${expired ? "times" : "check"}`}
          aria-hidden="true"
        />
      </div>
    );

    const owner =
      clients.list.find((client) => client.uuid === infos.clientUuid) || null;

    return (
      <div className="card card-block">
        <dl className="row">
          <dt className="col-sm-3">
            {I18n.t("pages.leak_detectors.head.serial_number")}
          </dt>
          <dd className="col-sm-9">{leakDetector.infos.serialNumber}</dd>

          <dt className="col-sm-3">
            {I18n.t("pages.leak_detectors.head.last_inspection")}
          </dt>
          <dd className="col-sm-9">{lastInspection}</dd>

          <dt className="col-sm-3">
            {I18n.t("pages.leak_detectors.head.validity")}
          </dt>
          <dd className="col-sm-9">{expiration}</dd>

          <dt className="col-sm-3">
            {I18n.t("pages.leak_detectors.head.expiration_date")}
          </dt>
          <dd className="col-sm-9">{expirationDate}</dd>

          <dt className="col-sm-3">
            {I18n.t("pages.leak_detectors.head.client")}
          </dt>
          <dd className="col-sm-9">
            {owner ? ClientUtils.renderLabel(owner) : I18n.t("common.unknown")}
          </dd>

          <dt className="col-sm-3">
            {I18n.t("pages.leak_detectors.head.designation")}
          </dt>
          <dd className="col-sm-9">
            {leakDetector.infos.designation || I18n.t("common.not_specified")}
          </dd>

          <dt className="col-sm-3">
            {I18n.t("pages.leak_detectors.head.barcode")}
          </dt>
          <dd className="col-sm-9">
            {leakDetector.infos.barcode || I18n.t("common.not_specified")}
          </dd>
        </dl>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  clients: state.clients,
  leakDetector: state.leakDetector,
});

const mapDispatchToProps = (dispatch) => ({
  getLeakDetectorInfos: (uuid) => dispatch(getLeakDetectorInfos(uuid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LeakDetector);
