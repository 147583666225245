import I18n from "i18n-js";
import React from "react";
import { func, shape } from "prop-types";
import { connect } from "react-redux";
import { ROUTE_CLIENTS } from "../../../routes";
import ClientForm from "../components/ClientForm";
import FormPage from "../../common/components/layout/FormPage";
import { updateClient, getClientInfo } from "../actions/clientsActions";

class CreateClient extends FormPage {
  static propTypes = {
    getClientInfo: func.isRequired,
    updateClient: func.isRequired,
    match: shape().isRequired,
    client: shape().isRequired,
    history: shape().isRequired,
    ownClient: shape().isRequired,
  };

  constructor() {
    super();

    this.onSubmit = this.onSubmit.bind(this);
  }

  /**
   * {@inheritdoc}
   */
  componentDidMount() {
    const { getClientInfo, match } = this.props;

    getClientInfo(match.params.uuid);
  }

  /**
   * @param {object} client
   */
  onSubmit(client) {
    this.props.updateClient(this.props.match.params.uuid, client);
  }

  /**
   * {@inheritdoc}
   */
  getTitle() {
    return I18n.t("pages.clients.update.title");
  }

  /**
   * {@inheritdoc}
   */
  getBackRoute() {
    return ROUTE_CLIENTS;
  }

  /**
   * {@inheritdoc}
   */
  renderContent() {
    const { client, history, ownClient } = this.props;

    return (
      <ClientForm
        ownClient={ownClient}
        loading={client.saving}
        info={client.info}
        onSubmit={this.onSubmit}
        history={history}
      />
    );
  }

  /**
   * {@inheritdoc}
   */
  render() {
    const { client } = this.props;

    if (client.info === undefined) {
      return <div className="loader" />;
    }

    if (client.info === null) {
      return <p>{I18n.t("pages.clients.update.unknown")}</p>;
    }

    return super.render();
  }
}

const mapStateToProps = (state) => ({
  client: state.client,
});

const mapDispatchToProps = (dispatch) => ({
  getClientInfo: (uuid) => dispatch(getClientInfo(uuid)),
  updateClient: (uuid, client) => dispatch(updateClient(uuid, client)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateClient);
