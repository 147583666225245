import React, { useState } from "react";
import { bool, func, number, string } from "prop-types";
import WasteFormBreadcrumb from "../components/WasteFormBreadcrumb";
import FormLayout from "../../common/components/layout/FormLayout";
import {
  ROUTE_MY_WASTE_RECOVERY_REQUESTS,
  ROUTE_WASTE_RECOVERY_REQUESTS_CREATE_SUMMARY,
} from "../../../routes";
import { setTransportInfo } from "../actions/createWasteRecoveryRequestActions";
import { useHistory } from "react-router";
import { connect, useSelector } from "react-redux";
import SelectCarrier from "../../company/components/SelectCarrier";
import { getFieldViolations } from "../selectors/createWasteRecoveryRequestSelectors";
import StatelessCheckbox from "../../common/components/form/StatelessCheckbox";
import StatelessTextInput from "../../common/components/form/StatelessTextInput";

export default connect(
  (state) => {
    const {
      charteredByClient,
      carrierUuid,
      transportNumber,
      nbCages,
      nbPallets,
    } = state.createWasteRecoveryRequestReducer;

    return {
      charteredByClient,
      carrierUuid,
      transportNumber,
      nbCages,
      nbPallets,
    };
  },
  {
    setTransportInfo,
  },
)(CreateWasteRecoveryRequestTransport);

CreateWasteRecoveryRequestTransport.propTypes = {
  setTransportInfo: func.isRequired,
  charteredByClient: bool.isRequired,
  carrierUuid: string,
  transportNumber: string,
  nbCages: number,
  nbPallets: number,
};

function CreateWasteRecoveryRequestTransport({
  setTransportInfo,
  charteredByClient,
  carrierUuid,
  transportNumber,
  nbCages,
  nbPallets,
}) {
  const history = useHistory();
  const [inputs, setInputs] = useState({
    charteredByClient,
    carrierUuid,
    transportNumber,
    nbCages,
    nbPallets,
  });

  const setInput = (inputName) => (v) =>
    setInputs({ ...inputs, [inputName]: v });
  // TODO: rework this. A hook is not supposed to be called like this and can cause troubles.
  const getFieldErrors = (fieldName) =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useSelector(getFieldViolations(fieldName)).map((v) => v.message);
  const errors = {
    carrierUuid: getFieldErrors("carrierUuid"),
    transportNumber: getFieldErrors("transportNumber"),
    charteredByClient: getFieldErrors("charteredByClient"),
    nbPallets: getFieldErrors("nbPallets"),
    nbCages: getFieldErrors("nbCages"),
  };

  const isValid = () => {
    if (
      inputs.charteredByClient &&
      (!inputs.carrierUuid || !inputs.transportNumber)
    ) {
      return false;
    }

    if (inputs.nbCages < 0 || inputs.nbPallets < 0) {
      return false;
    }

    return true;
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!isValid()) {
      return;
    }

    const payload = inputs;

    if (!inputs.charteredByClient) {
      payload.carrierUuid = null;
      payload.transportNumber = null;
    }

    setTransportInfo(payload);

    history.push(ROUTE_WASTE_RECOVERY_REQUESTS_CREATE_SUMMARY);
  };

  return (
    <div>
      <FormLayout
        title="Demande de reprise de déchets"
        backRoute={ROUTE_MY_WASTE_RECOVERY_REQUESTS}
        size={FormLayout.MEDIUM}
      >
        <form onSubmit={onSubmit}>
          <WasteFormBreadcrumb position={WasteFormBreadcrumb.TRANSPORT} />

          <div className="row">
            <div className="col-xl-4">
              <StatelessTextInput
                id="nbPallets"
                label="Nombre de palettes"
                onChangedValue={setInput("nbPallets")}
                defaultValue={inputs.nbPallets}
                type="number"
                step={1}
                min={0}
                errors={errors.nbPallets}
              />
            </div>

            <div className="col-xl-4">
              <StatelessTextInput
                id="nbCages"
                label="Nombre de cages métalliques"
                onChangedValue={setInput("nbCages")}
                defaultValue={inputs.nbCages}
                type="number"
                step={1}
                min={0}
                errors={errors.nbCages}
              />
            </div>
          </div>

          <hr />

          <div className="row">
            <div className="col-xl-5 mb-3">
              <StatelessCheckbox
                id="charteredByClient"
                label="Transport affreté par mes soins"
                checked={Boolean(inputs.charteredByClient)}
                onChangedValue={setInput("charteredByClient")}
                errors={errors.charteredByClient}
              />
            </div>
          </div>

          <fieldset
            disabled={!inputs.charteredByClient}
            hidden={!inputs.charteredByClient}
          >
            <div className="row">
              <div className="col-xl-4">
                <SelectCarrier
                  id="carrier"
                  label="Transporteur"
                  value={inputs.carrierUuid}
                  onChangedValue={setInput("carrierUuid")}
                  errors={errors.carrierUuid}
                />
              </div>
              <div className="col-xl-4">
                <StatelessTextInput
                  id="transportNumber"
                  label="Numéro du doc. de transport de déchet"
                  onChangedValue={setInput("transportNumber")}
                  defaultValue={inputs.transportNumber}
                  errors={errors.transportNumber}
                />
              </div>
            </div>
          </fieldset>

          <button type="submit" className="btn btn-primary float-right">
            Passer à l&apos;étape suivante
          </button>
        </form>
      </FormLayout>
    </div>
  );
}
