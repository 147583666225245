import { useAuth } from "../../authentication/contexts/useAuth";
import React, { useEffect, useMemo, useState } from "react";
import CompanyCountry from "../../company/models/CompanyCountry";
import { useLazyQuery, useMutation } from "@apollo/client";
import findSyncStatuses from "../graphql/queries/findSyncStatuses.graphql";
import triggerSyncStatusesUpdate from "../graphql/mutations/triggerSyncStatusesUpdate.graphql";
import { arrayToObject, removeElement } from "../../common/utils/arrayUtils";
import Table from "../../common/components/elements/Table";
import { CompanySyncStatusCell } from "./CompanySyncStatusCell";
import I18n from "i18n-js";
import PropTypes from "prop-types";

CompanySyncStatusesTable.propTypes = {
  allData: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
  uuidKey: PropTypes.string.isRequired,
  target: PropTypes.oneOf(["CLIENT", "SITE", "CARRIER"]).isRequired,
  ...Table.propTypes,
};

CompanySyncStatusesTable.defaultProps = {
  allData: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
  uuidKey: "uuid",
};

export function CompanySyncStatusesTable({
  allData,
  target,
  uuidKey,
  ...props
}) {
  const { user } = useAuth();
  const usesTrackdechets = useMemo(
    () => new CompanyCountry(user.companyCountry).usesTrackdechets,
    [user],
  );
  const [showTrackdechetsSyncStatuses, setShowTrackdechetsSyncStatuses] =
    useState(false);

  const [fetch, { data, loading, error }] = useLazyQuery(findSyncStatuses, {
    variables: {
      // fetch the statuses for the whole table data:
      uuids: allData.map((item) => item[uuidKey]),
      target,
    },
  });

  // fetch Trackdéchets sync statuses only once, once required:
  useEffect(() => {
    if (usesTrackdechets && showTrackdechetsSyncStatuses) {
      fetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showTrackdechetsSyncStatuses, usesTrackdechets]);

  const { refreshSyncStatus, isItemRefreshing, hasItemError } =
    useRefreshSyncStatus(target);

  const headers = !showTrackdechetsSyncStatuses
    ? props.headData
    : props.headData.concat(["Statut Trackdéchets"]);

  const trackdechetsStatuses = useMemo(
    () => arrayToObject(data?.trackdechetsCompaniesSyncStatus || [], "uuid"),
    [data],
  );

  if (!usesTrackdechets) {
    return <Table {...props} />;
  }

  return (
    <Table
      {...props}
      headData={headers}
      actions={
        <>
          {props.actions}
          <button
            type="button"
            className="btn btn-xs btn-link mt-2 p-0 text-primary"
            onClick={() =>
              setShowTrackdechetsSyncStatuses(!showTrackdechetsSyncStatuses)
            }
          >
            {I18n.t(
              `trackdechets.sync_statuses_table.${
                showTrackdechetsSyncStatuses ? "hide" : "show"
              }`,
            )}
          </button>
        </>
      }
      rowData={(row, index) => {
        const cells = props.rowData(row, index);

        if (!showTrackdechetsSyncStatuses) {
          return cells;
        }

        const uuid = row.uuid;
        const syncStatus = trackdechetsStatuses[uuid] || null;

        return cells.concat([
          <CompanySyncStatusCell
            key="td-sync-status-cell"
            loading={loading || isItemRefreshing(uuid)}
            error={error || hasItemError(uuid)}
            bitFlags={syncStatus?.flags}
            lastUpdatedAt={
              syncStatus?.lastUpdatedAt
                ? new Date(syncStatus.lastUpdatedAt)
                : null
            }
            refresh={() => refreshSyncStatus(uuid)}
          />,
        ]);
      }}
    />
  );
}

/**
 * Hook providing logic for refreshing a single status item in the list,
 * by triggering an async update and waiting a bit before fetching the new value.
 */
function useRefreshSyncStatus(target) {
  const [refreshingRows, setRefreshingRows] = useState([]);
  const [errors, setErrors] = useState([]);
  const [triggerUpdate] = useMutation(triggerSyncStatusesUpdate);
  const [fetch] = useLazyQuery(findSyncStatuses, {
    fetchPolicy: "network-only", // No cache to force refresh of the Apollo cache
  });

  return {
    isItemRefreshing: (uuid) => refreshingRows.includes(uuid),
    hasItemError: (uuid) => errors.includes(uuid),
    refreshSyncStatus: async (uuid) => {
      setRefreshingRows(refreshingRows.concat(uuid));
      setErrors(removeElement(errors, uuid));

      const options = {
        variables: {
          uuids: [uuid],
          target,
        },
      };

      try {
        // trigger async update of the status
        await triggerUpdate(options);

        // wait a second
        await new Promise((resolve) => setTimeout(resolve, 1000));

        // attempt to fetch the updated result,
        // which will update Apollo's cached data for this item:
        //await fetch(options);
        await fetch({
          ...options,
          onError() {
            // Until apollo/client 3.5.8 patch version is released, we need to duplicate the catch behavior here,
            // since there is currently a bug with the Promise returned by useLazyQuery.
            // See https://github.com/apollographql/apollo-client/pull/9328
            setErrors(errors.concat(uuid));
          },
        });
      } catch {
        setErrors(errors.concat(uuid));
      } finally {
        setRefreshingRows(removeElement(refreshingRows, uuid));
      }
    },
  };
}
