import { Component } from "react";

class Form extends Component {
  constructor(props) {
    super(props);

    this.inputs = {};

    this.state = {
      errors: [],
    };
  }

  /**
   * @return {Boolean}
   */
  isFormValid() {
    return FormUtils.validate(this.inputs);
  }

  /**
   * @param {String} inputId
   *
   * @return {Array?}
   */
  getInputErrors(inputId) {
    return FormUtils.getInputErrors(this.state.errors, inputId);
  }

  /**
   * @param {String} inputId
   *
   * @return {String?}
   */
  getInputValue(inputId) {
    return (
      (this.inputs[inputId].current && this.inputs[inputId].current.value) ||
      null
    );
  }
}

export default Form;

export class FormUtils {
  /**
   * @return {Boolean}
   */
  static validate(inputs) {
    const resultInputs = Object.keys(inputs).map((key) => {
      const input = inputs[key];

      return input.current && input.current.isValid && input.current.isValid();
    });

    return resultInputs.filter((item) => item != null).every((item) => item);
  }

  static getInputErrors(errors, inputId) {
    const matchError = errors
      .filter((error) => error.path.includes(inputId))
      .map((error) => error.reason);

    return matchError.length ? matchError : null;
  }
}
