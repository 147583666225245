/* eslint react/no-unescaped-entities: 0 */
/* eslint react/display-name: 0 */
import React, { Component } from "react";
import ImportSitesContent from "./ImportSitesContent";

export default class extends Component {
  static propTypes = ImportSitesContent.propTypes;

  /**
   * {@inheritdoc}
   */
  render() {
    return (
      <div>
        <p>
          To import your sites, the data must be saved in TSV format, and follow
          the following structure:
        </p>

        <ul>
          <li>Klant nr.</li>
          <li>Locatie nummer</li>
          <li>Omschrijving</li>
          <li>Land</li>
          <li>Stad</li>
          <li>Postcode</li>
          <li>Straat</li>
          <li>
            Straat 2 <i>*1</i>
          </li>
          <li>Naam van de contactpersoon</li>
          <li>Voornaam contactpersoon</li>
          <li>e-mail adres</li>
          <li>
            Opslag <i>*2</i>
          </li>
          <li>
            Naam verantwoordelijke locatie <i>*3</i>
          </li>
        </ul>

        <p>
          <i>
            *1 : optional
            <br />
            *2 : 0 = no storage site — 1= storage site
            <br />
            *3 : mandatory if it is a storage site
            <br />
          </i>
        </p>

        <p>{this.props.downloadSample}</p>

        <p>
          After integration, a summary will inform you about the number of
          entities created or updated, and will provide an anomaly file
          containing the unintegrated lines, and the reason(s) for the
          rejection.
        </p>

        <p>The possible reasons for anomalies are:</p>

        <ul>
          <li>Absence of mandatory data</li>
          <li>Address @ wrong</li>
          <li>Value different from 1/0</li>
        </ul>
      </div>
    );
  }
}
