/* eslint react/no-unescaped-entities: 0 */
/* eslint react/display-name: 0 */
import React, { Component } from "react";
import ImportSitesContent from "./ImportSitesContent";

export default class extends Component {
  static propTypes = ImportSitesContent.propTypes;

  /**
   * {@inheritdoc}
   */
  render() {
    return (
      <div>
        <p>
          Pour réaliser l'import de vos clients, les données doivent être
          enregistrées au format TSV, et respecter la structure suivante:
        </p>

        <ul>
          <li>N° client</li>
          <li>N° site</li>
          <li>
            SIRET <i>*1</i>
          </li>
          <li>Désignation</li>
          <li>Pays</li>
          <li>Ville</li>
          <li>Code postal</li>
          <li>Rue</li>
          <li>
            Rue complément <i>*2</i>
          </li>
          <li>Prénom contact</li>
          <li>Nom contact</li>
          <li>adresse @contact</li>
          <li>
            Top site entreposage <i>*3</i>
          </li>
          <li>
            Nom responsable site <i>*4</i>
          </li>
        </ul>

        <p>
          <i>
            *1 : optionnel s'il s'agit d'un site de particulier
            <br />
            *2 : optionnel
            <br />
            *3 : 0 = pas site d'entreposage — 1= site d'entreposage
            <br />
            *4 : obligatoire s'il s'agit d'un site d'entreposage
            <br />
          </i>
        </p>

        <p>{this.props.downloadSample}</p>

        <p>
          Après l'intégration, une synthèse vous informera sur le nombre
          d'entités créées ou mises à jour, et mettra à disposition un fichier
          d'anomalies contenant les lignes non intégrées, et le(s) motif(s) du
          rejet.
        </p>

        <p>Les motifs d'anomalie possibles sont:</p>

        <ul>
          <li>Absence de données obligatoires</li>
          <li>Adresse @ erronée</li>
          <li>Valeur du top différente de 1/0</li>
        </ul>
      </div>
    );
  }
}
