const prefix = "CREATE_WASTE_RECOVERY_REQUEST_";
export const Actions = {
  SET_INFO: prefix + "SET_INFO",
  ADD_CONTAINER: prefix + "ADD_CONTAINER",
  REMOVE_CONTAINER: prefix + "REMOVE_CONTAINER",
  ADD_ARTICLE: prefix + "ADD_ARTICLE",
  REMOVE_ARTICLE: prefix + "REMOVE_ARTICLE",
  SET_TRANSPORT_INFO: prefix + "SET_TRANSPORT_INFO",
  CLEAR: prefix + "CLEAR",
  SET_VIOLATIONS: prefix + "SET_VIOLATIONS",
};

/**
 * @param {String} siteUuid
 * @param {Date} date
 * @param {Object} attestationReceiptAddress
 * @param {?String} comments
 */
export function setInfo({
  siteUuid,
  date,
  attestationReceiptAddress,
  comments,
}) {
  return {
    type: Actions.SET_INFO,
    payload: { siteUuid, date, attestationReceiptAddress, comments },
  };
}

/**
 * @param {String} containerUuid
 * @param {Boolean} toReturn
 * @param {?String} treatmentMode
 * @param {?String} packaging
 * @param {Boolean} toDestruct
 */
export function addContainer({
  containerUuid,
  treatmentMode,
  packaging,
  toReturn = false,
  toDestruct = false,
}) {
  return {
    type: Actions.ADD_CONTAINER,
    payload: { containerUuid, toReturn, treatmentMode, packaging, toDestruct },
  };
}

/**
 * @param {String} containerUuid
 */
export function removeContainer(containerUuid) {
  return {
    type: Actions.REMOVE_CONTAINER,
    payload: { containerUuid },
  };
}

/**
 * @param {?String} articleUuid
 * @param {?String} producerUuid
 * @param {?Number} quantity
 * @param {?Number} mass
 * @param {?String} treatmentMode
 * @param {?String} packaging
 * @param {Boolean} toReturn
 * @param {Boolean} toDestruct
 * @param {?Number} index Updates article at index number if provided
 */
export function addArticle(
  {
    articleUuid,
    producerUuid,
    quantity,
    mass,
    treatmentMode,
    packaging,
    toReturn = false,
    toDestruct = false,
  },
  index,
) {
  return {
    type: Actions.ADD_ARTICLE,
    payload: {
      index,
      articleUuid,
      producerUuid,
      quantity,
      mass,
      treatmentMode,
      packaging,
      toReturn,
      toDestruct,
    },
  };
}

/**
 * @param {Number} index
 */
export function removeArticle(index) {
  return {
    type: Actions.REMOVE_ARTICLE,
    payload: { index },
  };
}

/**
 * @param {Boolean} charteredByClient
 * @param {Number} nbCages
 * @param {Number} nbPallets
 * @param {?String} carrierUuid
 * @param {?String} transportNumber
 */
export function setTransportInfo({
  charteredByClient,
  nbCages,
  nbPallets,
  carrierUuid = null,
  transportNumber = null,
}) {
  return {
    type: Actions.SET_TRANSPORT_INFO,
    payload: {
      charteredByClient,
      nbCages,
      nbPallets,
      carrierUuid,
      transportNumber,
    },
  };
}

export function clear() {
  return { type: Actions.CLEAR };
}

export function setViolations(violations) {
  return {
    type: Actions.SET_VIOLATIONS,
    payload: { violations },
  };
}
