import I18n from "i18n-js";
import pathToRegexp from "path-to-regexp";
import React from "react";
import { arrayOf, func, shape } from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getUsersList, lockUser, unlockUser } from "../actions/usersActions";
import { alphabetically } from "../../common/utils/filterUtils";
import List from "../../common/components/layout/List";
import Table from "../../common/components/elements/Table";
import TextInput from "../../common/components/form/TextInput";
import Select from "../../common/components/form/Select";
import { ROUTE_USER, ROUTE_USERS_ADD } from "../../../routes";
import Roles from "../models/Roles";
import ClientUtils from "../../company/utils/ClientUtils";
import ChangePwdForUserModal from "../components/ChangePwdForUserModal";
import { SUBMIT_SUCCESS } from "../../common/constants";

class UsersList extends List {
  static propTypes = {
    getUsersList: func.isRequired,
    unlockUser: func.isRequired,
    lockUser: func.isRequired,
    users: shape().isRequired,
    usersList: arrayOf(shape).isRequired,
    clients: arrayOf(shape).isRequired,
    onSuccessfulPasswordChange: func.isRequired,
  };

  constructor(props) {
    super(props);

    this.userRoute = pathToRegexp.compile(ROUTE_USER);

    this.dataTableRow = this.dataTableRow.bind(this);

    this.state = {
      ...this.state,
      changePasswordForUserUuid: null,
    };

    props.getUsersList();
  }

  /**
   * @param {Object} event
   * @param {Object} user
   */
  onLockUser(event, user) {
    event.preventDefault();

    const { unlockUser, lockUser } = this.props;
    const action = user.locked ? unlockUser : lockUser;

    action(user.uuid);
  }

  /**
   * @return {Array}
   */
  dataTableHead() {
    return [
      I18n.t("pages.users.head.lastname"),
      I18n.t("pages.users.head.firstname"),
      I18n.t("pages.users.head.email"),
      I18n.t("pages.users.head.client"),
      I18n.t("pages.users.head.role"),
      I18n.t("pages.users.head.position"),
      I18n.t("pages.users.head.locked"),
      I18n.t("pages.users.head.actions"),
    ];
  }

  /**
   * @param {Object} rowData
   *
   * @return {Array}
   */
  dataTableRow(rowData) {
    let actions = [];
    let locked = null;

    if (!Roles.userHasRole(rowData.roles, Roles.SUPER_ADMIN)) {
      actions.push(
        <Link
          to={this.userRoute({ uuid: rowData.uuid })}
          className="action"
          key="edit"
        >
          <i className="fa fa-pencil" aria-hidden="true" />
        </Link>,
      );

      locked = (
        <button
          key="action-lock"
          className="action"
          onClick={(event) => this.onLockUser(event, rowData)}
        >
          <i
            className={`fa fa-${rowData.locked ? "lock" : "unlock"}`}
            aria-hidden="true"
          />
        </button>
      );

      actions.push(
        <button
          key="change-pwd"
          onClick={() =>
            this.setState({ changePasswordForUserUuid: rowData.uuid })
          }
          className="action"
          title={I18n.t("pages.users.list.modal.change-pwd.title")}
        >
          <i className="fa fa-key" aria-hidden="true" />
        </button>,
      );
    }

    const client = rowData.client;

    return [
      rowData.lastname,
      rowData.firstname,
      rowData.email,
      client ? ClientUtils.renderLabel(client) : I18n.t("common.unknown"),
      I18n.t(Roles.readableFor(rowData.roles[0])),
      rowData.position,
      locked,
      actions,
    ];
  }

  /**
   * {@inheritdoc}
   */
  renderContent() {
    const { users, usersList } = this.props;

    if (users.inProgress) {
      return <div className="loader" />;
    }

    if (users.error) {
      return <p>{I18n.t("common.loadingApiError")}</p>;
    }

    const actions = (
      <Link to={ROUTE_USERS_ADD} className="btn btn-primary">
        {I18n.t("pages.users.list.actions.add")}
      </Link>
    );

    const list = usersList
      .filter(this.getFilterByText("lastname"))
      .filter(this.getFilterByText("email"))
      .filter(this.getFilterBySelect("client"));

    return [
      <Table
        key="list"
        emptyContent={I18n.t("pages.users.list.empty")}
        data={list}
        headData={this.dataTableHead()}
        rowData={this.dataTableRow}
        pagination
        filters={this.renderFilters()}
        actions={actions}
        onPageChanged={this.onPageChanged}
        initialPage={this.getCurrentPage()}
      />,
      <ChangePwdForUserModal
        key="change-pwd-modal"
        onClose={() => this.setState({ changePasswordForUserUuid: null })}
        uuid={this.state.changePasswordForUserUuid}
        onSuccess={this.props.onSuccessfulPasswordChange}
      />,
    ];
  }

  /**
   * {@inheritdoc}
   */
  renderFilters() {
    const { filters } = this.state;

    const { clients } = this.props;

    return [
      <TextInput
        key="filterByName"
        id="filterByName"
        defaultValue={filters.lastname}
        onChange={(event) => this.onChangeFilter(event, "lastname")}
        label={I18n.t("filters.by_name")}
        optional
      />,
      <TextInput
        key="filterByEmail"
        id="filterByEmail"
        defaultValue={filters.email}
        onChange={(event) => this.onChangeFilter(event, "email")}
        label={I18n.t("filters.by_email")}
        optional
      />,
      <Select
        key="filterByClient"
        id="filterByClient"
        defaultValue={filters.client}
        onChange={(event) => this.onChangeFilter(event, "client")}
        label={I18n.t("filters.by_client")}
        renderOption={(option) => (
          <option key={option.uuid} value={option.uuid}>
            {ClientUtils.renderLabel(option)}
          </option>
        )}
        options={clients.sort((a, b) =>
          alphabetically(
            ClientUtils.renderLabel(a),
            ClientUtils.renderLabel(b),
          ),
        )}
        placeholder={I18n.t("filters.by_client:default")}
        optional
      />,
    ];
  }

  /**
   * {@inheritdoc}
   */
  renderTitle() {
    return I18n.t("pages.users.list.title");
  }
}

/**
 * Get unique array of clients from users list
 */
function getClientsFromUserList(usersList) {
  return Object.values(
    usersList.reduce((clientsMap, user) => {
      const { client } = user;
      clientsMap[client.uuid] = client;

      return clientsMap;
    }, {}),
  );
}

const mapStateToProps = (state) => ({
  users: state.users,
  usersList: state.users.list.sort((a, b) =>
    alphabetically(a.lastname, b.lastname),
  ),
  clients: getClientsFromUserList(state.users.list ?? []),
});

const mapDispatchToProps = (dispatch) => ({
  getUsersList: () => dispatch(getUsersList()),
  unlockUser: (uuid) => dispatch(unlockUser(uuid)),
  lockUser: (uuid) => dispatch(lockUser(uuid)),
  onSuccessfulPasswordChange: () =>
    dispatch({
      type: SUBMIT_SUCCESS,
      payload: { message: I18n.t("pages.users.list.modal.change-pwd.success") },
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(UsersList);
