/* eslint react/no-unescaped-entities: 0 */
import React, { Component } from "react";

export default class CGU extends Component {
  /**
   * {@inheritdoc}
   */
  render() {
    return (
      <div className="container">
        <h1>General terms and conditions of use-user</h1>

        <h2>Warning Notice</h2>
        <p>
          The Clim'app application is provided in beta version. Consequently,
          You agree that Clim'app may, during Your use, contain bugs or other
          malfunctions.
        </p>
        <p>
          You therefore agree that We shall not be liable for any damages
          suffered by You as a result of using Clim'app.
        </p>

        <h2>Preamble</h2>
        <p>
          These General Terms and Conditions of Use (hereinafter referred to as
          "GTC") are an appendix to the SaaS contract signed between the Partner
          and Climalife (hereinafter referred to as the "Contract") and form an
          integral part thereof.
        </p>
        <p>
          The SaaS contract, these Clim'app Terms and Conditions of Use and the
          Privacy Policy constitute the entire contractual framework applicable
          to any service provided by Clim'app for Your benefit.
        </p>
        <p>
          It is important to read, understand and accept each of the following
          terms, because any service provided by Clim'app for Your benefit is
          subject to the application of this contractual framework, and in
          particular these General Terms and Conditions of Use. If you do not
          agree with the terms hereof, do not use Clim'app.
        </p>
        <p>
          This Preamble is an integral part of the General Terms and Conditions
          of Use of the Clim'app mobile application.
        </p>

        <h2>I - General information</h2>

        <h3>
          1.1 To whom are these General Terms and Conditions of Use addressed?
        </h3>
        <p>
          These General Terms and Conditions of Use are addressed to any
          Employee of a Climalife Partner who has signed and accepted the
          Agreement and accepted these T&Cs and the Privacy Policy attached to
          the Agreement. who visits the site and uses the mobile application
          named Clim'app (hereinafter "Clim'app"). This person is referred to
          here as the "You" (see definitions below).
        </p>

        <h3>1.2 Who are we and how to contact us?</h3>
        <p>
          The mobile application, Clim'app that You use and/or in general the
          information society service on which these General Conditions of Use
          are integrated, is that of : Climalife (hereinafter referred to as
          "We").
        </p>
        <p>Our precise contact details:</p>
        <p>Corporate form: public limited company</p>
        <p>Head office address: 4rue de la Croix Faubin - 75011 Paris</p>
        <p>E-mail address: info@climapp.dehon.com</p>
        <p>Registration number in the Trade Register: 310 259 205</p>
        <p>VAT NO.: EN 62 310 259 205</p>
        <p>You can contact Us at the postal address indicated above.</p>
        <p>
          For any question relating to these General Conditions of Use, You can
          also use the following email address: info@climapp.dehon.com
        </p>

        <h2>II - Definitions</h2>
        <p>
          In these General Terms and Conditions of Use, the following terms
          shall be understood as follows, it being specified that the
          definitions incorporated in the Agreement shall apply to these GTU:
        </p>
        <p>
          Clim'app or the Application refers to the mobile application on which
          We offer our Services.
        </p>
        <p>
          Account: the page listing all Partner Data, including personal data
          hosted on Clim'app in accordance with the Privacy Policy.
        </p>
        <p>
          General Terms and Conditions of Use or GTC refers to these Clim'app
          General Terms and Conditions of Use governing the Use of Clim'app.
        </p>
        <p>
          Content (s): all information, data, images, texts, videos,
          photographs, published on Clim'app by the Agent and/or the Partner.
        </p>
        <p>
          Contract refers to the SaaS contract signed between Climalife and the
          Partner governing the terms and conditions of access to the Platform.
        </p>
        <p>
          Agent: any person accessing the Application exercising his activities
          under the authority and control of the Partner.{" "}
        </p>
        <p>
          Durable medium: any instrument allowing You and/or Us to store
          information addressed to You in a manner that allows You to refer to
          it later for a period of time adapted to the purposes for which the
          information is intended and that allows the identical reproduction of
          the stored information.
        </p>

        <h2>
          III - Purpose and acceptance of these General Terms and Conditions of
          Use
        </h2>
        <p>
          Clim'app is governed by these General Terms and Conditions of Use. The
          Partner undertakes to bring them to the attention of the Agent and to
          ensure that the Agent undertakes to respect them when creating an
          Account, when accessing Clim'app, and in general, when using Clim'app
          and Our Services.
        </p>
        <p>
          In any event, the Agent who downloads and uses the Application
          acknowledges having read and accepted these General Conditions of Use.
        </p>

        <h2>IV - Description of the services provided via the Application</h2>
        <p>
          Climalife has developed Clim'app, the mobile application that supports
          refrigeration professionals on a daily basis.
        </p>
        <p>Clim'app is a mobile application that allows you to:</p>
        <ul>
          <li>Management and reporting of interventions</li>
          <li>Facility and container management</li>
          <li>The production of regulatory documents (FI BSD & Annex 1)</li>
        </ul>
        <p>Clim'app is also a web application that offers:</p>
        <ul>
          <li>Visibility on the stock of refrigerants</li>
          <li>
            The possibility of administering the environment of the
            refrigeration professional (sites, installations, vehicles,
            detectors...)
          </li>
          <li>Dashboards summarizing the main part of the activity</li>
        </ul>

        <h2>V - Evolution of Our Services</h2>
        <p>
          You understand that We may change the Services at any time to take
          into account, in particular, changes in technology and/or applicable
          legislation. We make every effort to inform You of any material
          changes to Our Services.
        </p>
        <p>
          You understand that the version of Clim'app that You download is a
          test version. You acknowledge that all or part of the functionalities
          of the current test version of Clim'app, as a result, may be
          incomplete and/or faulty.{" "}
        </p>

        <h2>VI - Your general commitments</h2>
        <p>
          Within the framework of these General Conditions of Use, You are
          acting as a professional for the purposes of Your commercial,
          industrial, craft or liberal activity, and to the exclusion of all
          personal purposes.
        </p>
        <p>
          You acknowledge that you are acting, within the scope of this
          agreement, as a professional within the meaning of the Consumer Code.
        </p>

        <h2>VII - Access to Our Services</h2>

        <h3>7.1 Downloading the Clim'app application</h3>
        <p>
          Access to Our Services requires having previously downloaded the
          Clim'app mobile application, for which the functionalities and
          interoperability conditions are indicated before downloading, in the
          application store, such as the App Store or Google Play.
        </p>
        <p>
          New versions of the application are regularly made available to You
          through the application store, such as the App Store or Google Play.
          By downloading these updates, You agree to any changes to the
          application or Services that result from them. You agree to regularly
          download updates and acknowledge that no liability can be attributed
          to Us if You use a version prior to the one updated.{" "}
        </p>

        <h3>7.2 Creating Your Account</h3>
        <p>
          Our Services are accessible to You as soon as You have created Your
          Account in accordance with the terms and conditions set out in this
          article.
        </p>
        <p>Creating Your Account</p>
        <p>
          The creation of an Account on Clim'app follows different steps: each
          step is successively completed by the "click" of a confirmation icon.
        </p>
        <p>
          During the various steps of creating an account, you may be required
          to provide personal information (such as your name, first name, etc.)
          whose processing by Us is governed by Our Privacy Policy.
        </p>
        <p>
          When You click on an icon whose wording or presentation generates a
          commitment that any average Internet user would understand, You are
          bound by its click. This is the case, for example, for icons entitled
          "Validation", "Confirmation" or any other similar title.
        </p>
        <p>
          Subject to the following paragraph, We are bound by the creation of
          Your Account as soon as all the information requested in the Account
          creation process is completed.
        </p>
        <p>Checks prior to the creation of Your Account</p>
        <p>
          We reserve the right not to validate the request to create an Account
          in particular in the following cases:
        </p>
        <ul>
          <li>In case of invalid information provided;</li>
          <li>If the Contract has not been signed.</li>
        </ul>
        <p>
          In such a case, the request to create an Account is not validated and
          no obligation can arise from it.
        </p>
        <p>The validity and proof of the creation of Your Account</p>
        <p>
          Notwithstanding any other written evidence or any evidence stored on
          another durable medium to which You may have access, it is agreed that
          the computerized records, stored in Our computerized systems or those
          of Our subcontractors, may be used as proof of communications, the
          content of the creation of Your Account and its date.
        </p>

        <h2>VIII - Your obligations in the use of Our Services</h2>

        <h3>8.1 The security of Your Account</h3>
        <p>
          Access to Your Account is reserved for You after identification using
          Your username and password that You freely determine when creating
          Your Account.
        </p>
        <p>
          You acknowledge that Your password is strictly confidential. The use
          of Your username and password through the Internet is at Your own
          risk. It is Your responsibility to take all necessary measures to
          protect Your username and password against any breach. You agree to
          disconnect from Your Account after any use. In this sense, We cannot
          be held responsible for any fraudulent access to Your Account or for
          any change made to this Account. In such a case, You undertake to
          inform Us as soon as possible.
        </p>
        <p>
          You remain the sole guarantor of the information stored in Your
          Account. You can add, modify or remove them at any time.
        </p>

        <h3>8.2 The use of Our Services</h3>
        <p>
          By accessing, visiting or using Clim'app, You warrant and certify that
          You are responsible for all use of Clim'app by You, or by any person
          using Your credentials.
        </p>

        <h3>8.3 Prohibition of illegal or unlawful use of Clim'app</h3>
        <p>
          You are informed that all applicable laws and regulations are
          applicable on the Internet.
        </p>
        <p>
          You certify that You are not using Clim'app for any purpose that is
          illegal or prohibited by law or these Terms of Use.
        </p>
        <p>As a non-exhaustive list, You must not under any circumstances:</p>
        <ul>
          <li>
            Intercept or attempt to intercept e-mail or any other private
            communication that is not intended for You;
          </li>
          <li>
            Use means that would limit the legitimate and peaceful use of
            Clim'app by a third party;
          </li>
          <li>Misrepresent yourself as Our employee, agent or servant;</li>
          <li>
            Use information obtained from Clim'app to abuse, harass or injure
            another person or to contact, promote, solicit or sell information
            about that person without their prior explicit consent.
          </li>
        </ul>

        <h3>8.4 The Content You Publish on Our Services</h3>
        <p>The publication of Your Content</p>
        <p>
          By publishing information, Content, comments or reactions on Clim'app,
          you are contributing to the functioning of Clim'app. In order for this
          operation to be carried out properly and for Clim'app to be effective,
          You undertake to comply with all of these General Terms and Conditions
          of Use as well as the applicable legal and regulatory provisions.
        </p>
        <p>
          The Content You provide on Clim'app cannot, but this list is not
          exhaustive:
        </p>
        <ul>
          <li>damage Clim'app's image;</li>
          <li>
            be false, inaccurate or misleading (it should, as far as possible,
            be true and verified);
          </li>
          <li>be defamatory, unlawfully threatening or harassing;</li>
          <li>
            contain viruses, Trojan horses or any other computer program whose
            purpose is to harm, intercept or expropriate system, data or
            personal information;
          </li>
          <li>
            infringe the rights of third parties such as intellectual property
            rights, the right to privacy and the protection of personal data.
          </li>
        </ul>
        <p>
          The information You provide, configure, modify and control is Your
          sole responsibility.
        </p>
        <p>Intellectual Property on Your Content</p>
        <p>
          For the Content that You publish on Clim'app, protected by
          intellectual property rights that You may hold, You grant Us a
          non-exclusive, transferable, sub-licensable, royalty-free and
          worldwide license to use, reproduce, modify, translate, distribute,
          adapt and communicate such Content published on Clim'app for purposes
          including the proper administration and operation of Clim'app. This
          license will apply for the entire legal term of the copyright, even if
          You decide to no longer use Clim'app.
        </p>

        <h3>8.5 Compensation</h3>
        <p>
          You guarantee and indemnify Us for any claim and/or procedure brought
          by a third party, whatever its form and nature, which is directly or
          indirectly related to the information provided and other Content
          disseminated by You on Clim'app or which is caused by Your use of
          Clim'app that is contrary to these General Terms and Conditions of Use
          and the laws and/or regulations in force. As such, You will be
          responsible for all damages to which We may be condemned as well as
          legal costs and fees that We should incur.
        </p>
        <p>
          We reserve the right, at Our option and at Our expense, to participate
          in the defence and/or amicable settlement of a claim or to assume
          alone the defence and control of the proceedings, without releasing
          You from Your obligations to compensate. You may under no
          circumstances accept an amicable solution that would implicate Our
          liability or impose any obligation whatsoever on Us, without Our prior
          consent.
        </p>

        <h2>IX - Limitation of Our liability</h2>

        <h3>9.1 General information</h3>
        <p>
          Clim'app and its Services are provided on an "as is" basis, "as is"
          and without any express or implied warranty as to their terms of use
          and/or availability.
        </p>
        <p>
          You are informed that Our Services comply with current French
          legislation. We cannot be held liable in the event of non-compliance
          with the legislation of another State.
        </p>
        <p>
          In the event of non-performance of Our Services, Our liability may not
          be sought if the non-performance of the Services, or its improper
          performance, is attributable to You or is attributable either to the
          unforeseeable and insurmountable fact of a third party outside the
          service, or finally to a case of force majeure.
        </p>
        <p>
          In general, Our liability cannot be engaged in the event of use of the
          Services that do not comply with these General Terms and Conditions of
          Use.
        </p>
        <p>
          You acknowledge that the version of Clim'app that You download is a
          test version. You acknowledge that all or part of the functionalities
          of the current test version of Clim'app, as a result, may be
          incomplete and/or faulty. We cannot be held responsible for any
          damage, loss of data or information, alterations to Your devices and
          peripherals that the current Clim'app test version may cause You. In
          general, You acknowledge that You download this test version at Your
          own risk and that We cannot be held liable for any direct or indirect
          damage.
        </p>

        <h3>9.2 Quality of information and Content</h3>
        <p>
          We are only bound by an obligation of means. We do not support any
          obligation of result of any kind whatsoever with regard to the quality
          of the information and Content available on Clim'app.
        </p>
        <p>
          In general, We do not guarantee the completeness, exhaustiveness,
          accuracy, legitimacy, reliability or availability of the Content of
          the information and Services that We offer in this beta version. We
          will use all available means to offer You quality Content but under no
          circumstances will we be able to accept any liability for any
          consequence whatsoever, resulting in particular from the use of Our
          Services by You or resulting from the information provided via Our
          Services.
        </p>

        <h3>9.3 Limitation of Our liability for Content provided by You</h3>
        <p>
          We cannot be held liable, civilly or criminally, for Content sent,
          distributed or put online by You through Clim'app.{" "}
        </p>
        <p>
          When We have actual knowledge of any unlawful activity or information
          committed through or through its Services, We take appropriate action
          in accordance with applicable laws or regulations, which may result in
          We being unable to access such information, deleting it at Our sole
          discretion, or immediately communicating unlawful activities or
          information to the appropriate administrative or judicial authorities.{" "}
        </p>
        <p>
          When Content is deleted or made inaccessible by Us, You acknowledge
          and agree that You are not entitled to any compensation in connection
          with this action.
        </p>

        <h3>9.4 Security/access</h3>
        <p>
          We reasonably and diligently strive to maintain the security and
          operational integrity of Clim'app by adopting security measures
          adapted to the nature of the data and the risks presented by Our
          activity. However, many factors beyond Our control may interfere with
          the operation of Clim'app.
        </p>
        <p>
          We strive, as far as possible, to keep Our Services accessible 7 days
          a week, 24 hours a day.
        </p>
        <p>
          We reserve the right to interrupt, temporarily suspend or modify
          without notice access to all or part of the Clim'app Services, in
          order to ensure maintenance, upgrading or for any other reason, in
          particular technical, without these operations giving rise to any
          obligation or compensation, of which We will endeavour to inform you
          in advance except in urgent cases.
        </p>
        <p>Therefore:</p>
        <ul>
          <li>
            Our responsibility in the event of an interruption of access to
            Clim'app and the consequences that may result from it cannot be
            engaged;
          </li>
          <li>
            We cannot be held liable for any possible damage (direct or
            indirect) or temporary or permanent incident that may be caused to
            Your data or computer equipment when You access Clim'app or when
            visiting Your Account or generally when sending to Your device the
            files and software that make up Clim'app. In particular, We are not
            responsible for the possible transmission of a virus through
            Clim'app.
          </li>
          <li>
            We are not responsible for the fraudulent use of Our means of
            distribution and decline any liability in the event of intrusion
            into Our computer systems and data theft, subject to mandatory legal
            and regulatory provisions where applicable contrary, it being
            understood that We use all necessary means to prevent such illegal
            intrusions.
          </li>
        </ul>

        <h3>9.5 Updates</h3>
        <p>
          Despite all efforts to ensure the accuracy of the information and/or
          documents on Clim'app, we disclaim all liability of any kind
          whatsoever for any inaccuracy or failure to update any information
          and/or document on Clim'app.
        </p>

        <h3>9.6 Damage</h3>
        <p>
          We are not liable for damages, related to a case of force majeure or
          related to any moral, image or commercial prejudice, loss of
          customers, loss of brand image, commercial disorder or any other
          special, incidental or indirect damage arising from or in connection
          with Clim'app.
        </p>

        <h2>X - Disclosure to third parties</h2>
        <p>
          To the extent permitted by applicable law, We may disclose any
          Clim'app Content or data to comply with applicable laws or if, in good
          faith, We believe that such action is necessary, including in
          connection with legal proceedings, to enforce the Terms of Use, to
          respond to complaints and/or claims alleging infringement of the
          rights of third parties, to protect Our rights or interests, those of
          Our customers or the public.
        </p>

        <h2>XI - Hyperlinks to other services</h2>
        <p>
          Clim'app may refer to other services (websites, applications, etc.)
          over which we do not exercise any technical or content control. The
          existence of a hypertext link to another service does not constitute
          an endorsement by Us of that service or its content.
        </p>
        <p>
          We cannot therefore offer any guarantee as to the completeness or
          accuracy of the content of these third-party services, nor as to their
          availability.
        </p>
        <p>
          We accept no liability for any direct or indirect damage resulting
          from the consultation or use of the web services to which Clim'app
          refers or the information published on these services. We also
          disclaim any responsibility for the processing of personal data on
          these services.
        </p>

        <h2>XII - Intellectual Property</h2>
        <p>
          We grant You only a free, personal, non-exclusive and non-transferable
          right to access and use Clim'app, subject to compliance with these
          General Terms and Conditions of Use.
        </p>
        <p>
          Clim'app (considered as an inseparable whole) and the various elements
          appearing on Clim'app (such as texts, photographs, catalogues,
          layouts, trademarks, logos, illustrations, specific software, videos)
          are protected by intellectual property rights belonging to Us or to
          Our licensors. Where applicable, they have been authorised for
          publication, distribution or right of use acquired from these third
          parties.
        </p>
        <p>
          The logo, product names, service names or company names mentioned on
          Clim'app may constitute trademarks, trade names and company names of
          their respective owners. You expressly undertake to respect these
          intellectual property rights.
        </p>
        <p>
          You agree, without this list being exhaustive: to modify, copy,
          distribute, transmit, broadcast, publish, license, transfer, sell,
          reproduce or have reproduced all or part of the information, software,
          product or service obtained from Clim'app, temporarily or permanently,
          by any process, known or unknown, on any medium, without the prior
          written consent of Clim'app. Failure to comply with this prohibition
          constitutes an infringement likely to incur the civil and criminal
          liability of the infringer.
        </p>

        <h2>XIII - Processing of personal data</h2>
        <p>
          Regarding the processing of Your personal data that We carry out, We
          refer You to the Privacy Policy.
        </p>

        <h2>XIV - Deletion of the Account</h2>
        <p>
          You may delete Your Account at any time and You acknowledge that the
          deletion of Your Account constitutes termination of these Terms and
          Conditions.
        </p>
        <p>
          We may delete Your Account on our own initiative, which will result in
          the termination of this Agreement (unless otherwise provided for even
          after termination of this Agreement), in particular in the following
          cases, this list not being exhaustive:
        </p>
        <ul>
          <li>
            This deletion of initiative will be the result of the unused portion
            of Your Account for a given period of time;
          </li>
          <li>
            This deletion of initiative constitutes a temporary or definitive
            measure taken by Us against a suspicious account, for example when
            We have reason to believe that You are not complying with these
            conditions;
          </li>
          <li>
            This suppression of initiative may be the result of the occurrence
            of a bankruptcy for Clim'app or changes in Our commercial
            activities.
          </li>
        </ul>
        <p>
          To the extent possible and within the applicable legal limits, We will
          notify You in advance of Our decision to delete Your Account.
        </p>
        <p>
          You may not claim any compensation for the deletion of Your Account.
        </p>
        <p>
          After the deletion of Your Account, We will keep Your data and Content
          that You have published on Clim'app for the time necessary for the
          technical operations of deleting the Account and in any event within
          the limits and obligations imposed by applicable laws. We reserve the
          right to keep the information and Content published on Clim'app that
          is necessary for Us to manage Your Installation.
        </p>

        <h2>XV - Final Provisions</h2>

        <h3>15.1 Amendments to these General Terms and Conditions of Use</h3>
        <p>
          We may modify these General Terms and Conditions of Use at any time,
          without notice, in accordance with changes made to Clim'app, changes
          in legislation or for any other legitimate reason. The version of
          these General Terms and Conditions of Use that is enforceable against
          You is permanently available on Clim'app. You undertake to consult it
          regularly.
        </p>
        <p>
          In the particular case of the need to adapt Clim'app to legislative
          changes, we will do our utmost to make these adaptations as quickly as
          possible. During this period, You acknowledge that We cannot be held
          responsible for temporary non-compliance.
        </p>

        <h3>15.2 Proof Agreement</h3>
        <p>
          We can exchange with You the information necessary for Clim'app by
          electronic means. Any electronic communication between You and Us is
          presumed to have the same probative value as a written paper document.
        </p>
        <p>
          A printed version of these General Conditions of Use and any warning
          notice issued in electronic form shall be accepted in any judicial or
          administrative proceedings related to this contractual relationship,
          on the same basis and under the same conditions as other business
          documents and records created and maintained in printed form.
        </p>

        <h3>15.3 Severability</h3>
        <p>
          If any provision of these General Terms and Conditions of Use should
          be declared null and void, invalid, illegal or unenforceable under
          applicable law, in whole or in part, such a situation shall not affect
          the validity of the remaining provisions, subject to the event that an
          essential provision is null and void or that the contractual
          provisions are interdependent. The invalid, void, illegal or
          unenforceable clause shall be replaced retroactively by a valid and
          enforceable clause whose content is as close as possible to that of
          the original clause.
        </p>

        <h3>15.4 Force majeure</h3>
        <p>
          Notwithstanding any provision to the contrary, no Contracting Party
          shall be liable for any delay in the performance or non-performance of
          its obligations resulting from events of force majeure (such as
          strikes, wars, earthquakes, cataclysm of any kind, direct or indirect
          effects of explosion, fire, heat release, floods or any other
          unforeseeable and irresistible external force majeure).
        </p>

        <h3>15.5 Relationships</h3>
        <p>
          These Terms and Conditions of Use shall in no event be considered a
          joint venture or other association between You and Us, and neither of
          the Contracting Parties to these Terms and Conditions of Use shall be
          considered the agent or employee of the other. The relationship that
          We have with You is that of an independent contractor.
        </p>
        <p>
          In the event of a dispute between You and a third party, We have no
          obligation to get involved. You release Us, Our directors, employees
          and other members from any action, damages of any kind whatsoever,
          known or unknown, relating to such conflicts to which You will be
          bound.
        </p>

        <h3>15.6 Notification</h3>
        <p>
          Any communication or notification that We make to You will be valid if
          it is sent to the e-mail address that You have given, even if it is no
          longer valid.
        </p>

        <h3>15.8 Applicable law and competent courts</h3>
        <p>
          These General Terms and Conditions of Use shall be governed by and
          construed in accordance with French law.
        </p>
        <p>
          Any dispute relating to the use of Clim'app and the validity,
          interpretation, execution or non-execution of these General Terms and
          Conditions of Use shall fall within the exclusive jurisdiction of the
          Courts of Paris, for any type of procedure.
        </p>
      </div>
    );
  }
}
