class UserUtils {
  static renderLabel(userRef) {
    const { lastname, firstname, email } = userRef;
    if (lastname && firstname) {
      return `${lastname} ${firstname}`;
    }
    return email;
  }
}

export default UserUtils;
