/* eslint react/no-unescaped-entities: 0 */
import React, { Component } from "react";

export default class PrivacyPolicy extends Component {
  /**
   * {@inheritdoc}
   */
  render() {
    return (
      <div className="container">
        <h1>CHARTE VIE PRIVEE CLIM’APP</h1>

        <h2>
          2. L’importance de la protection des données à caractère personnel sur
          Clim'app
        </h2>
        <p>
          Climalife (la société Dehon Service dont le siège social est établi à
          4, rue de la Croix Faubin 75011 PARIS), en sa qualité de responsable
          de traitement de Clim’App, est soucieuse du respect de la vie privée
          de l’Utilisateur lors de l’utilisation de Clim'app et a conscience de
          son importance.
        </p>
        <p>
          La présente politique vise à vous informer de nos pratiques concernant
          la collecte, l’utilisation et le partage des donnée à caractère
          personnel (les Données) et s’adresse à toute personne qui visite le
          site et utilise l’application mobile Clim'app. Cette personne est ici
          désignée par « l’Utilisateur ».
        </p>
        <p>
          L’Utilisateur peut contacter Climalife à l’adresse postale ci-dessus
          indiquée. Pour toute question relative à la présente Charte, il peut
          aussi écrire à l’adresse email suivante : info@climapp.dehon.com.
        </p>

        <h2>3. Les Données traitées</h2>
        <p>
          Dans le cadre de Clim'app, Climalife est susceptible de traiter les
          données à caractère personnel suivantes concernant le Client et/ou le
          Préposé :
        </p>
        <ul>
          <li>Le nom et prénom de l’Utilisateur.</li>
          <li>
            Le mot de passe et l’identifiant de l’Utilisateur permettant
            d’accéder à son Compte sur Clim'app (le mot de passe n’est jamais
            stocké en clair sur les serveurs du Climalife, conformément aux
            exigences de la Commission Nationale de l’Informatique et des
            Libertés) ;
          </li>
          <li>L’adresse IP (détectés automatiquement) de l’Utilisateur ;</li>
          <li>
            L’information concernant l’utilisation de Clim'app (y compris les
            pages consultées) par l’Utilisateur ;
          </li>
          <li>
            De manière générale, toute information donnée volontairement, par
            exemple lors de l’utilisation de Clim'app par l’Utilisateur.
          </li>
        </ul>

        <h2>4. Les finalités du traitement de ces données</h2>
        <p>Le Climalife traite ces données pour les finalités suivantes :</p>
        <ul>
          <li>
            permettre l’accès et la gestion du compte dans le cadre du contrat
            conclu ;
          </li>
          <li>
            Permettre à l’Utilisateur d’utiliser l’ensemble des services
            proposés sur Clim'app dont l’identification dans des documents
            CERFA;
          </li>
          <li>
            Assurer l’administration technique et la sécurité de Clim'app ;
          </li>
          <li>
            Transmettre à l’Utilisateur, y compris par voie d’e-mails, des
            informations sur des produits et services analogues à Clim'app que
            le Climalife fournit ;
          </li>
          <li>
            Réaliser des statistiques notamment sur la fréquentation des
            différentes rubriques de Clim'app par l’Utilisateur ;
          </li>
          <li>
            transmettre, y compris par voie d’e-mail, des informations sur
            d’autres produits et services que Climalife propose ou que des tiers
            proposent.
          </li>
        </ul>
        <p>
          Ces traitements sont basés sur votre consentement lors de l’ouverture
          de votre compte.
        </p>

        <h2>5. Sécurité et confidentialité</h2>
        <p>
          Les données à caractère personnel de l’Utilisateur ne seront
          transmises à aucun tiers sans son consentement, à l’exception des
          sous-traitants de Climalife et des autres entités du groupe DEHON. Les
          sous traitants n’agiront que sur les instructions et pour le compte du
          Climalife dans le cadre du contrat conclu.
        </p>
        <p>
          Climalife également susceptible de partager des informations vous
          concernant l’Utilisateur avec d’autres entités du groupe DEHON dans le
          cadre prévu par la présente Charte.
        </p>
        <p>
          Toutefois, Climalife se réserve par ailleurs le droit de communiquer
          des données à caractère personnel sur requête d’une autorité
          administrative ou judiciaire ou si le Climalife estime de bonne foi
          que cette divulgation est requise pour se conformer à toute loi ou
          réglementation en vigueur.
        </p>
        <p>
          En règle générale, nous conservons vos Données Personnelles au sein de
          l’Union Européenne. Cependant, dans les cas où nous transférons
          certaines de vos Données Personnelles dans des Pays Tiers, nous nous
          assurons que ce transfert soit effectué en conformité avec la
          réglementation applicable et garantisse un niveau de protection
          suffisant de la vie privée et des droits fondamentaux des personnes
          conforme au RGPD.
        </p>

        <h2>6. Durée de conservation</h2>
        <p>Les Données sont archivées à l’issue des périodes suivantes :</p>
        <ul>
          <li>
            5 ans après la dernière utilisation de la Plateforme par le
            détenteur du compte principal, si le compte n’a pas été fermé;
          </li>
          <li>2 ans après la fermeture du compte.</li>
        </ul>

        <h2>7. Les droits de l’Utilisateur</h2>

        <h3>1. Droit d’accès :</h3>
        <p>
          L’Utilisateur peut avoir accès à ses données à caractère personnel
          traitées par ClimApp. Dans l’hypothèse où demande lui en serait faite,
          Climalife fournira à l’Utilisateur copie de toutes ses données à
          caractère personnel ainsi que toutes les informations légalement
          requises, en ce compris :
        </p>
        <ul>
          <li>
            Les catégories des données personnelles collectées et traitées ;
          </li>
          <li>Les finalités de leur traitement ;</li>
          <li>
            Les catégories des destinataires auxquels les données à caractère
            personnel ont été communiquées ou sont susceptibles d’être
            communiquées ;
          </li>
          <li>
            La durée de conservation des données à caractère personnel ; et
          </li>
          <li>
            Des informations concernant les droits de l’Utilisateur à l’égard de
            ses données à caractère personnel.
          </li>
        </ul>

        <h3>2. Droit de rectification</h3>
        <p>
          L’Utilisateur peut demander à corriger ses données à caractère
          personnel si celles-ci se révèlent inexactes, incomplètes ou
          obsolètes.
        </p>

        <h3>3. Droit à l’effacement</h3>
        <p>
          L’Utilisateur peut demander à ce que ses données à caractère personnel
          soient supprimées dans les hypothèses suivantes :
        </p>
        <ul>
          <li>
            Si ses données à caractère personnel ne sont plus nécessaires au
            regard des finalités pour lesquelles elles ont été collectées ou
            sont traitées ;
          </li>
          <li>
            Si l’Utilisateur s’est opposé à la collecte ou au traitement de ses
            données à caractère personnel conformément à l’alinéa suivant du
            présent article ;
          </li>
          <li>
            Si le traitement des données à caractère personnel de l’Utilisateur
            est illégal ;
          </li>
          <li>
            Si les données à caractère personnel de l’Utilisateur doivent être
            supprimées afin pour ClimApp de se conformer à une obligation légale
            mise à sa charge.
          </li>
        </ul>

        <h3>4. Droit d’opposition</h3>
        <p>
          Sous réserve de motifs légitimes tenant à la particularité de sa
          situation, le Client et/ou le Préposé peut s’opposer à tout traitement
          de ses données à caractère personnel lorsque ses données à caractère
          personnel sont traitées sur le fondement de la nécessité pour
          l’exécution d’une mission d’intérêt public ou de la poursuite de
          l’intérêt légitime de ClimApp le Client et/ou le Préposé peut
          également toujours s’opposer, sans obligation de motif, au traitement
          de ses données à caractère personnel lorsqu’il intervient à des fins
          de prospection commerciale.
        </p>

        <h3>5. Droit à la limitation du traitement</h3>
        <p>
          L’Utilisateur peut demander la limitation du traitement de ses données
          à caractère personnel dans les hypothèses suivantes :
        </p>
        <ul>
          <li>
            Dans l’hypothèse où il contesterait l’exactitude de ses données à
            caractère personnel, et ce pour la durée nécessaire au Climalife
            pour la vérifier ;
          </li>
          <li>
            Si le traitement de ses données à caractère personnel est illicite
            et que l’Utilisateur souhaite le limiter plutôt que de demander la
            suppression de ces données ;
          </li>
          <li>
            Si l’Utilisateur s’est opposé au traitement de ses données à
            caractère personnel, pendant la période au cours de laquelle ClimApp
            vérifie si elle n’a pas d’autres motifs légitimes prépondérants pour
            poursuivre leur traitement.
          </li>
        </ul>

        <h3>6. Exercice des droits précités</h3>
        <p>
          Ces droits peuvent être exercés par simple envoi d’un courrier
          électronique à l’adresse suivante : info@climapp.dehon.com, comportant
          obligatoirement copie lisible d’une pièce d’identité de l’Utilisateur
          et, pour l’exercice du droit d’opposition, description des motifs de
          l’opposition.
        </p>
        <p>
          Climalife pourra s’opposer aux demandes d’exercice d’un ou plusieurs
          des droits susmentionnés si ces demandes lui paraissent abusives, au
          regard notamment de leur fréquence, de leur nombre, de leur caractère
          répétitif ou systématique.
        </p>
      </div>
    );
  }
}
