import { gql } from "@apollo/client";

/**
 * Helper to update Apollo cache on item creation.
 *
 * @param {String} queryName Query name to modify in cache
 * @param {String} typename Type of data
 * @param {String} dataKey Key to access the data from the mutation response (i.e mutation's name)
 * @param {String} identifier Property acting as uq id for the type of data
 */
export function apolloAddItem(
  queryName,
  typename,
  dataKey,
  identifier = "uuid",
) {
  return (cache, { data }) => {
    cache.modify({
      fields: {
        [queryName](existing = []) {
          return [
            ...existing,
            cache.writeFragment({
              data: data[dataKey],
              fragment: gql`
              fragment NewItem on ${typename} {
                ${identifier}
              }
            `,
            }),
          ];
        },
      },
    });
  };
}

/**
 * Helper to update Apollo cache on item update.
 *
 * @param {String} queryName Query name to modify in cache
 * @param {String} typename Type of data
 * @param {String} dataKey Key to access the data from the mutation response (i.e mutation's name)
 * @param {String} identifier Property acting as uq id for the type of data
 */
export function apolloUpdateItem(
  queryName,
  typename,
  dataKey,
  identifier = "uuid",
) {
  return (cache, { data }) => {
    apolloRemoveItem(dataKey)(cache, { data });
    apolloAddItem(queryName, typename, dataKey, identifier)(cache, { data });
  };
}

/**
 * Helper to update Apollo cache on item removal.
 *
 * @param {String} dataKey Key to access the data from the mutation response (i.e mutation's name)
 */
export function apolloRemoveItem(dataKey) {
  return (cache, { data }) => {
    cache.evict({
      id: cache.identify(data[dataKey]),
    });
    cache.gc();
  };
}

/**
 * Purge a whole query results to force re-fetching it on needs.
 *
 * @param {String} queryName Query name to modify in cache
 */
export function apolloPurgeQuery(queryName) {
  return (cache) => {
    cache.evict({ id: "ROOT_QUERY", fieldName: queryName });
    cache.gc();
  };
}

/**
 * Removes items from a query according to a mutation result.
 *
 * @param {Function} itemsAccessor How to access, in the response, to the items to remove
 */
export function apolloRemoveItemsFromResult(itemsAccessor) {
  return (cache, result) => {
    itemsAccessor(result.data).forEach((item) => {
      cache.evict({
        id: cache.identify(item),
      });
    });
    cache.gc();
  };
}
