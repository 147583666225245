import { func, string } from "prop-types";
import React from "react";
import { useQuery } from "@apollo/client";
import getCountries from "../../graphql/queries/getCountries.graphql";
import Select from "../../components/form/Select";
import { alphabetically } from "../../utils/filterUtils";
import InvalidFeedback from "./InvalidFeedback";

SelectCountry.propTypes = {
  value: string, // selected value
  label: string.isRequired,
  id: string.isRequired,
  onChangedValue: func.isRequired,
  errors: InvalidFeedback.propTypes.errors,
};

SelectCountry.defaultProps = {
  errors: null,
};

export default function SelectCountry({ value, errors, ...props }) {
  const { loading, error, data } = useQuery(getCountries);

  let countries = data?.countries.slice() || [];
  countries = countries.sort((a, b) => alphabetically(a.label, b.label));

  return (
    <Select
      loading={loading}
      loadingError={Boolean(error)}
      options={countries}
      renderOption={(option) => (
        <option key={`option-${option.code}`} value={option.code}>
          {option.label}
        </option>
      )}
      defaultValue={value}
      error={!loading && (countries.length === 0 || errors)}
      {...props}
    />
  );
}
