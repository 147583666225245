import Modal from "../../common/components/elements/Modal";
import I18n from "i18n-js";
import React, { createRef, useState } from "react";
import TextInput from "../../common/components/form/TextInput";
import { FormUtils } from "../../common/components/form/Form";
import { fetch } from "../../../appContainer";
import RawSubmit from "../../common/components/form/RawSubmit";
import { func, string } from "prop-types";

ChangePwdForUserModal.propTypes = {
  uuid: string,
  onSuccess: func,
  onClose: func,
};

ChangePwdForUserModal.defaultProps = {
  uuid: null,
  onSuccess: () => {},
  onClose: () => {},
};

export default function ChangePwdForUserModal({ uuid, onClose, onSuccess }) {
  /** @type {ApiClient} api */
  const api = fetch("api");

  const [newPassword, setNewPassword] = useState(null);
  const [passwordConfirm, setPasswordConfirm] = useState(null);
  const [errors, setErrors] = useState([]);
  const [loading, setLoaading] = useState(false);

  if (null === uuid) {
    return null;
  }

  const inputs = {
    newPassword: createRef(),
    passwordConfirm: createRef(),
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!FormUtils.validate(inputs)) {
      return;
    }

    setLoaading(true);
    api.changePasswordForUser(
      { uuid, newPassword, passwordConfirm },
      () => {
        setLoaading(false);
        setErrors([]);
        onSuccess();
        onClose();
      },
      (error) => {
        setLoaading(false);
        setErrors(error.violations);
      },
    );
  };

  const title = I18n.t("pages.users.list.modal.change-pwd.title");

  return (
    <Modal key="containersModal" isOpen={true} title={title} onClose={onClose}>
      <form onSubmit={onSubmit}>
        <h4>{title}</h4>
        <hr />

        <TextInput
          id="newPassword"
          type="password"
          autoComplete="new-password"
          onChangedValue={setNewPassword}
          ref={inputs.newPassword}
          label={I18n.t(
            "pages.users.list.modal.change-pwd.form.newPassword:label",
          )}
          error={FormUtils.getInputErrors(errors, "newPassword")}
        />
        <TextInput
          id="passwordConfirm"
          type="password"
          autoComplete="new-password"
          onChangedValue={setPasswordConfirm}
          ref={inputs.passwordConfirm}
          label={I18n.t(
            "pages.users.list.modal.change-pwd.form.passwordConfirm:label",
          )}
          error={FormUtils.getInputErrors(errors, "passwordConfirm")}
        />

        <RawSubmit loading={loading} className="float-right" />
      </form>
    </Modal>
  );
}
