import QRCode from "react-qr-code";
import React from "react";
import PropTypes from "prop-types";
import { trans } from "../../../translations";

BsffList.propTypes = {
  bsffs: PropTypes.arrayOf(
    PropTypes.shape({
      designation: PropTypes.string.isRequired,
      bordereauId: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      previousBsff: PropTypes.shape({ bordereauId: PropTypes.string }),
      nextBsff: PropTypes.shape({
        bordereauId: PropTypes.string,
      }),
    }).isRequired,
  ).isRequired,
};

export default function BsffList({ bsffs }) {
  return (
    <div key="bsff-list-shipping">
      <ul className="list-group">
        {bsffs.map((bsff) => (
          <li key={`bsff-${bsff.id}`} className="list-group-item">
            <div className="row switch-qrcode">
              <div className="col-sm-9">
                <dl>
                  <dt>{trans("trackdechets.bsff.list.designation")}</dt>
                  <dd>{bsff.designation ?? "-"}</dd>
                  <dt>{trans("trackdechets.bsff.list.noteId")}</dt>
                  <dd>{bsff.bordereauId}</dd>
                  <dt>{trans("trackdechets.bsff.list.status")}</dt>
                  <dd>{trans("trackdechets.bsff.status." + bsff.status)}</dd>
                  <dt>{trans("trackdechets.bsff.list.previousBsff")}</dt>
                  <dd>{bsff.previousBsff?.bordereauId ?? "-"}</dd>
                  <dt>{trans("trackdechets.bsff.list.nextBsff")}</dt>
                  <dd>{bsff.nextBsff?.bordereauId ?? "-"}</dd>
                  {bsff.nextBsff?.bordereauId && (
                    <dd>
                      <QRCode
                        className="blur-qrcode"
                        value={bsff.nextBsff.bordereauId}
                        size={128}
                      />
                    </dd>
                  )}
                </dl>
              </div>
              <div className="col-sm-3">
                <QRCode
                  className="blur-qrcode"
                  value={bsff.bordereauId}
                  size={128}
                />
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
