import I18n from "i18n-js";
import React from "react";
import { connect } from "react-redux";
import AbstractSitesList from "./AbstractSitesList";
import { ROUTE_SITE, ROUTE_SITES_ADD } from "../../../routes";
import ClientUtils from "../utils/ClientUtils";
import { alphabetically } from "../../common/utils/filterUtils";
import Select from "../../common/components/form/Select";
import TextInput from "../../common/components/form/TextInput";
import LocationOkIcon from "../../../../assets/images/location-ok.png";
import LocationNokIcon from "../../../../assets/images/location-nok.png";
import { deleteSite, getSitesList } from "../actions/sitesActions";
import { CompanySyncStatusesTable } from "../../trackdechets/components/CompanySyncStatusesTable";

class SitesList extends AbstractSitesList {
  static defaultProps = {
    allowImport: true,
  };

  constructor() {
    super();

    this.i18nPageKey = "sites";

    this.renderFilterClientOption = this.renderFilterClientOption.bind(this);
    this.renderTableComponent = this.renderTableComponent.bind(this);
  }

  /**
   * {@inheritdoc}
   */
  getI18nPageKey() {
    return "sites";
  }

  /**
   * {@inheritdoc}
   */
  getSiteRoute() {
    return ROUTE_SITE;
  }

  /**
   * {@inheritdoc}
   */
  getAddSiteRoute() {
    return ROUTE_SITES_ADD;
  }

  /**
   * {@inheritdoc}
   */
  dataTableHead() {
    return [
      this.getLabel("head.externalId"),
      this.getLabel("head.designation"),
      this.getLabel("head.client"),
      this.getLabel("head.address"),
      this.getLabel("head.location"),
      this.getLabel("head.actions"),
    ];
  }

  /**
   * {@inheritdoc}
   */
  dataTableRow(rowData) {
    const notProvided = <small>{I18n.t("common.not_specified")}</small>;

    const address = rowData.address && (
      <span>
        {rowData.address.street}
        <br />
        {rowData.address.postal} {rowData.address.city}
        {", "}
        {rowData.address.country}
      </span>
    );

    return [
      rowData.externalId || notProvided,
      rowData.designation,
      ClientUtils.renderLabel(rowData.client),
      address,
      this.renderLocation(rowData),
      this.renderRowActions(rowData),
    ];
  }

  /**
   * @param {Object} rowData
   */
  renderLocation(rowData) {
    const { address } = rowData;

    if (!address) {
      return null;
    }

    if (address.latitude && address.longitude) {
      return (
        <a
          href={`https://www.google.com/maps/place/${address.latitude}+${address.longitude}`}
          className="location-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="location" src={LocationOkIcon} alt="" />
        </a>
      );
    }

    return <img className="location" src={LocationNokIcon} alt="" />;
  }

  /**
   * @param {Object} option
   *
   * @return {Element}
   */
  renderFilterClientOption(option) {
    return (
      <option key={option.uuid} value={option.uuid}>
        {ClientUtils.renderLabel(option)}
      </option>
    );
  }

  /**
   * {@inheritdoc}
   */
  renderFilters() {
    const { filters } = this.state;
    const options = this.props.clients.list.sort((a, b) =>
      alphabetically(ClientUtils.renderLabel(a), ClientUtils.renderLabel(b)),
    );

    return [
      <Select
        key="filterByClient"
        id="filterByClient"
        onChange={(event) => this.onChangeFilter(event, "client")}
        label={I18n.t("filters.by_client")}
        renderOption={this.renderFilterClientOption}
        options={options}
        placeholder={I18n.t("filters.by_client:default")}
        defaultValue={filters.client}
        optional
      />,
      <TextInput
        key="filterByDesignation"
        id="filterByDesignation"
        onChange={(event) => this.onChangeFilter(event, "designation")}
        label={I18n.t("filters.by_designation")}
        defaultValue={filters.designation}
        optional
      />,
      <TextInput
        key="filterByCity"
        id="filterByCity"
        onChange={(event) => this.onChangeFilter(event, "city")}
        defaultValue={filters.city}
        label={I18n.t("filters.by_city")}
        optional
      />,
    ];
  }

  renderTableComponent(sitesList, tableProps) {
    return (
      <CompanySyncStatusesTable
        target="SITE"
        allData={sitesList || []}
        {...tableProps}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  clients: state.clients,
  sites: state.sites,
  sitesList: state.sites.list
    .filter((site) => !site.mobile)
    .sort((a, b) => alphabetically(a.designation, b.designation)),
  userProfile: state.auth.userProfile,
});

const mapDispatchToProps = (dispatch) => ({
  getSitesList: () => dispatch(getSitesList()),
  deleteSite: (uuid, isMobile) => dispatch(deleteSite(uuid, isMobile)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SitesList);
