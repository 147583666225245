import I18n from "i18n-js";
import React, { Fragment } from "react";
import { func, shape } from "prop-types";
import { connect } from "react-redux";
import FormPage from "../../common/components/layout/FormPage";
import Select from "../../common/components/form/Select";
import TextInput from "../../common/components/form/TextInput";
import SubmitButton from "../../common/components/form/SubmitButton";
import { completeFIBSD, getFibsdInfo } from "../actions/fibsdActions";
import { ROUTE_FIBSDS } from "../../../routes";
import DateInput from "../../common/components/form/DateInput";
import TreatmentMode from "../../waste/models/TreatmentMode";

class CompleteFibsd extends FormPage {
  static propTypes = {
    fibsd: shape().isRequired,
    completeFibsd: shape().isRequired,
    match: shape().isRequired,
    getFibsdInfo: func.isRequired,
    completeFIBSD: func.isRequired,
    history: shape().isRequired,
  };

  state = {
    quantityError: null,
    presentationDateError: null,
    acceptationDateError: null,
    treatmentDateError: null,
    treatmentModeError: null,
  };

  constructor() {
    super();

    this.quantityReceived = null;
    this.presentationDate = new Date();
    this.acceptationDate = new Date();
    this.treatmentDate = new Date();
    this.treatmentMode = null;

    this.setQuantityReceived = this.setQuantityReceived.bind(this);
    this.setPresentationDate = this.setPresentationDate.bind(this);
    this.setAcceptationDate = this.setAcceptationDate.bind(this);
    this.setTreatmentDate = this.setTreatmentDate.bind(this);
    this.setTreatmentMode = this.setTreatmentMode.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  /**
   * {@inheritdoc}
   */
  componentDidMount() {
    this.props.getFibsdInfo(this.props.match.params.uuid);
  }

  /**
   * {@inheritdoc}
   */
  componentDidUpdate(prevProps) {
    const { completeFibsd, history } = this.props;

    if (
      prevProps.completeFibsd.saved === completeFibsd.saved &&
      prevProps.completeFibsd.errors === completeFibsd.errors
    ) {
      return;
    }

    if (completeFibsd.saved) {
      history.push(ROUTE_FIBSDS);
    }
  }

  onSubmit() {
    if (!this.isValid()) {
      return;
    }

    const { props } = this;
    const fibsd = {
      presentationDate: this.presentationDate.toISOString(),
      acceptationDate: this.acceptationDate.toISOString(),
      treatmentDate: this.treatmentDate.toISOString(),
      treatmentMode: this.treatmentMode.value,
      quantityReceived: this.quantityReceived.value,
    };

    props.completeFIBSD(props.fibsd.details.uuid, fibsd);
  }

  setQuantityReceived(value) {
    this.quantityReceived = value;
  }

  setPresentationDate(value) {
    this.presentationDate = value;
  }

  setAcceptationDate(value) {
    this.acceptationDate = value;
  }

  setTreatmentDate(value) {
    this.treatmentDate = value;
  }

  setTreatmentMode(value) {
    this.treatmentMode = value;
  }

  isValid() {
    const quantityCorrect =
      !isNaN(this.quantityReceived.value) && this.quantityReceived.value.length;
    const treatmentModeCorrect = this.treatmentMode !== null;

    const quantityError = I18n.t("pages.fibsd.details.errors.quantity");
    const dateError = I18n.t("pages.fibsd.details.errors.date");
    const treatmentModeError = I18n.t(
      "pages.fibsd.details.errors.treatmentMode",
    );

    this.setState({
      quantityError: !quantityCorrect ? quantityError : null,
      presentationDateError: !this.presentationDate ? dateError : null,
      acceptationDateError: !this.acceptationDate ? dateError : null,
      treatmentDateError: !this.treatmentDate ? dateError : null,
      treatmentModeError: !treatmentModeCorrect ? treatmentModeError : null,
    });

    return (
      quantityCorrect &&
      this.presentationDate &&
      this.acceptationDate &&
      this.treatmentDate &&
      treatmentModeCorrect
    );
  }

  /**
   * {@inheritdoc}
   */
  getTitle() {
    return I18n.t("pages.fibsd.details.title");
  }

  /**
   * {@inheritdoc}
   */
  getBackRoute() {
    return ROUTE_FIBSDS;
  }

  /**
   * {@inheritdoc}
   */
  renderContent() {
    const { fibsd, completeFibsd } = this.props;
    const {
      quantityError,
      presentationDateError,
      acceptationDateError,
      treatmentDateError,
      treatmentModeError,
    } = this.state;

    return (
      <Fragment>
        {I18n.t("pages.fibsd.details.containers", {
          containers: fibsd.details.containerBarcodes,
        })}
        <TextInput
          id="quantityReceived"
          type="number"
          step="0.01"
          label={I18n.t("pages.fibsd.details.quantityReceived")}
          ref={this.setQuantityReceived}
          error={quantityError}
        />
        <DateInput
          id="presentationDate"
          label={I18n.t("pages.fibsd.details.presentationDate")}
          onChange={this.setPresentationDate}
          error={presentationDateError}
          defaultValue={this.presentationDate}
        />
        <DateInput
          id="acceptationDate"
          label={I18n.t("pages.fibsd.details.acceptationDate")}
          onChange={this.setAcceptationDate}
          error={acceptationDateError}
          defaultValue={this.acceptationDate}
        />
        <DateInput
          id="treatmentDate"
          label={I18n.t("pages.fibsd.details.treatmentDate")}
          onChange={this.setTreatmentDate}
          error={treatmentDateError}
          defaultValue={this.treatmentDate}
        />
        <Select
          ref={this.setTreatmentMode}
          id="fluidTreatmentMode"
          label={I18n.t("pages.fibsd.details.treatmentMode")}
          renderOption={(mode) => (
            <option value={mode.value} key={mode.value}>
              {mode.processTypeCode}
            </option>
          )}
          options={TreatmentMode.instances}
          placeholder="--"
          error={treatmentModeError}
        />
        <SubmitButton loading={completeFibsd.saving} onClick={this.onSubmit} />
      </Fragment>
    );
  }

  /**
   * {@inheritdoc}
   */
  render() {
    const { fibsd } = this.props;

    if (fibsd.details === undefined) {
      return <div className="loader" />;
    }

    if (fibsd.details === null) {
      return <p>{I18n.t("common.loadingApiError")}</p>;
    }

    return super.render();
  }
}

const mapStateToProps = (state) => ({
  fibsd: state.fibsd,
  completeFibsd: state.completeFibsd,
});

const mapDispatchToProps = (dispatch) => ({
  getFibsdInfo: (uuid) => dispatch(getFibsdInfo(uuid)),
  completeFIBSD: (uuid, fibsd) => dispatch(completeFIBSD(uuid, fibsd)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompleteFibsd);
