import ImportInstallationsContent from "../modules/installation/pages/ImportInstallations/nl";
import ImportSitesContent from "../modules/company/pages/ImportSites/nl";
import ImportClientsContent from "../modules/company/pages/ImportClients/nl";
import PrivacyPolicy from "../modules/common/pages/PrivacyPolicy/nl";
import CGU from "../modules/common/pages/CGU/nl";

export default {
  ImportInstallationsContent,
  ImportSitesContent,
  ImportClientsContent,
  PrivacyPolicy,
  CGU,
};
