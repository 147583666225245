// eslint-disable react/no-multi-comp
import I18n from "i18n-js";
import React, { Component } from "react";
import { func, instanceOf, string } from "prop-types";
import DatePicker from "react-datepicker";

/**
 * To be used with https://reactdatepicker.com#example-38 "Custom input"
 */
class TextualDateInput extends Component {
  static propTypes = {
    onClick: func,
    value: string,
  };

  static defaultProps = {
    value: null,
    onClick: () => {},
  };

  render() {
    return (
      <mark className="textual-date-input" onClick={this.props.onClick}>
        {this.props.value}
      </mark>
    );
  }
}

class TextDateInput extends Component {
  static propTypes = {
    placeholder: string,
    onChange: func,
    defaultValue: instanceOf(Date),
    className: string,
  };

  static defaultProps = {
    placeholder: "JJ/MM/AAAA",
    onChange: () => {},
    defaultValue: null,
    className: null,
  };

  state = {
    value: this.props.defaultValue,
  };

  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  /**
   * @param {Date} newDate
   */
  onChange(newDate) {
    this.setState(
      {
        value: newDate,
      },
      () => this.props.onChange(this.state.value),
    );
  }

  /**
   * {@inheritdoc}
   */
  render() {
    const {
      placeholder,
      onChange, // eslint-disable-line no-unused-vars
      className,
      ...props
    } = this.props;

    return (
      <div className={className}>
        <DatePicker
          dateFormat="dd/MM/yyyy"
          customInput={<TextualDateInput />}
          todayButton={I18n.t("form.todayButton")}
          selected={this.state.value}
          placeholderText={placeholder}
          onChange={this.onChange}
          withPortal
          fixedHeight
          {...props}
        />
      </div>
    );
  }
}

export default TextDateInput;
