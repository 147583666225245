import I18n from "i18n-js";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { func, shape } from "prop-types";
import { connect } from "react-redux";
import { logout } from "../../../authentication/actions/authActions";
import Dropdown from "../elements/Dropdown";
import Roles from "../../../users/models/Roles";
import {
  ROUTE_API_ADMIN,
  ROUTE_TRACKDECHETS_OAUTH_STATUS,
} from "../../../../routes";
import CompanyCountry from "../../../company/models/CompanyCountry";

class HeadUserInfos extends Component {
  static propTypes = {
    logout: func.isRequired,
    userProfile: shape().isRequired,
  };

  constructor() {
    super();

    this.onLogout = this.onLogout.bind(this);
  }

  /**
   * Logout user
   *
   * @param {Object} event
   */
  onLogout(event) {
    event.preventDefault();

    this.props.logout();
  }

  /**
   * @return {Element|null}
   */
  renderNomenclaturesLink() {
    const userRoles = this.props.userProfile.roles;

    if (!userRoles.includes(Roles.SUPER_ADMIN)) {
      return null;
    }

    return (
      <a href={ROUTE_API_ADMIN} target="_blank" rel="noopener noreferrer">
        {I18n.t("navbar.nomenclatures")}
      </a>
    );
  }

  renderTrackdechetsLink() {
    const companyCountry = new CompanyCountry(
      this.props.userProfile.companyCountry,
    );

    if (!companyCountry.usesTrackdechets()) {
      return null;
    }

    return (
      <Link to={ROUTE_TRACKDECHETS_OAUTH_STATUS}>
        {I18n.t("navbar.trackdechets_configuration")}
      </Link>
    );
  }

  /**
   * {@inheritdoc}
   */
  render() {
    const { userProfile } = this.props;
    const dropdownLabel = (
      <span>
        <i className="fa fa-user-circle-o" aria-hidden="true" />
        {`${userProfile.firstname}`}
      </span>
    );

    return (
      <Dropdown
        className="nav-item dropdown-user"
        tagName="li"
        label={dropdownLabel}
      >
        <a
          href={I18n.t("help.firststep.url")}
          rel="noopener noreferrer"
          target="_blank"
        >
          {I18n.t("help.firststep.label")}
        </a>
        <a
          href={I18n.t("help.more.url")}
          rel="noopener noreferrer"
          target="_blank"
        >
          {I18n.t("help.more.label")}
        </a>
        <a
          href={I18n.t("help.mobile.url")}
          rel="noopener noreferrer"
          target="_blank"
        >
          {I18n.t("help.mobile.label")}
        </a>
        {this.renderNomenclaturesLink()}
        {this.renderTrackdechetsLink()}
        <button type="button" onClick={this.onLogout}>
          <i className="fa fa-power-off" aria-hidden="true" />
          {I18n.t("common.logout")}
        </button>
      </Dropdown>
    );
  }
}

const mapStateToProps = (state) => ({
  userProfile: state.auth.userProfile,
  lang: state.auth.lang, // So that this part of the UI is updated as-well on lang change
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(HeadUserInfos);
