import ImportInstallationsContent from "./../modules/installation/pages/ImportInstallations/fr";
import ImportSitesContent from "./../modules/company/pages/ImportSites/fr";
import ImportClientsContent from "./../modules/company/pages/ImportClients/fr";
import PrivacyPolicy from "../modules/common/pages/PrivacyPolicy/fr";
import CGU from "../modules/common/pages/CGU/fr";

export default {
  ImportInstallationsContent,
  ImportSitesContent,
  ImportClientsContent,
  PrivacyPolicy,
  CGU,
};
