/* eslint react/no-unescaped-entities: 0 */
/* eslint react/display-name: 0 */
import React, { Component } from "react";
import ImportClientsContent from "./ImportClientsContent";

export default class extends Component {
  static propTypes = ImportClientsContent.propTypes;

  /**
   * {@inheritdoc}
   */
  render() {
    return (
      <div>
        <p>
          You are about to import customers to your entity "{this.props.client}
          ".
          <br />
          Make sure that your data only corresponds to customers of this entity.
        </p>

        <p>
          To import your customers: the data must be saved as, TSV format, and
          respect the following structure:
        </p>

        <ul>
          <li>Customer number</li>
          <li>Company name</li>
          <li>SIRET or VAT Code</li>
          <li>Country</li>
          <li>City</li>
          <li>Postal code</li>
          <li>Street</li>
          <li>
            Complementary street <i>*1</i>
          </li>
        </ul>

        <p>
          <i>*1 Optional</i>
        </p>

        <p>{this.props.downloadSample}</p>

        <p>
          <strong>
            If among your customers, there are already referenced entities in
            our database, do you want to:
          </strong>
        </p>

        {this.props.strategyWidget}

        <p>
          <i>
            *2 The establishment of a service relationship allows you to access
            all of your client's sites and installations and automatically
            benefit from their bets and creations.
          </i>
        </p>

        <p>
          After integration, the synthesis will inform you about the number of
          entities created, and updated, and will provide an anomaly file
          containing the unintegrated lines, and the reason(s) for the
          rejection.
        </p>

        <p>The possible reasons for anomalies are:</p>

        <ul>
          <li>Absence of mandatory data</li>
          <li>Incorrect SIRET or VAT Code</li>
          <li>Incorrect date</li>
        </ul>
      </div>
    );
  }
}
