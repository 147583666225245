import I18n from "i18n-js";
import React from "react";
import { func, shape } from "prop-types";
import { connect } from "react-redux";
import { ROUTE_CLIENTS } from "../../../routes";
import FormPage from "../../common/components/layout/FormPage";
import ClientForm from "../components/ClientForm";
import { createClient } from "../actions/clientsActions";

class CreateClient extends FormPage {
  static propTypes = {
    createClient: func.isRequired,
    client: shape().isRequired,
    history: shape().isRequired,
    ownClient: shape().isRequired,
  };

  constructor() {
    super();

    this.onSubmit = this.onSubmit.bind(this);
  }

  /**
   * @param {Object}  client
   * @param {Boolean} createDefaultSite
   */
  onSubmit(client, createDefaultSite) {
    this.props.createClient(client, createDefaultSite);
  }

  /**
   * {@inheritdoc}
   */
  getTitle() {
    return I18n.t("pages.clients.create.title");
  }

  /**
   * {@inheritdoc}
   */
  getBackRoute() {
    return ROUTE_CLIENTS;
  }

  /**
   * {@inheritdoc}
   */
  renderContent() {
    const { client, history, ownClient } = this.props;

    return (
      <ClientForm
        ownClient={ownClient}
        loading={client.saving}
        onSubmit={this.onSubmit}
        history={history}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  client: state.client,
});

const mapDispatchToProps = (dispatch) => ({
  createClient: (client, createDefaultSite) =>
    dispatch(createClient(client, createDefaultSite)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateClient);
