import React, { Component } from "react";
import {
  any,
  arrayOf,
  bool,
  shape,
  string,
  func,
  oneOfType,
  array,
} from "prop-types";

class Radio extends Component {
  static propTypes = {
    name: string.isRequired,
    choices: arrayOf(
      shape({
        label: string.isRequired,
        value: any.isRequired, // eslint-disable-line react/forbid-prop-types
        disabled: bool,
      }),
    ).isRequired,
    value: any, // eslint-disable-line react/forbid-prop-types
    onChange: func,
    error: oneOfType([string, array]),
  };

  static defaultProps = {
    error: null,
    value: null,
    onChange: () => {},
  };

  constructor(props) {
    super(props);

    this.renderChoice = this.renderChoice.bind(this);
    this.isSelectedChoice = this.isSelectedChoice.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onChange(event) {
    this.props.onChange(event.target.value);
  }

  isSelectedChoice(choice) {
    return choice.disabled !== true && this.props.value === choice.value;
  }

  renderChoice(choice, index) {
    return (
      <div key={this.props.name + index}>
        <input
          id={this.props.name + index}
          type="radio"
          name={this.props.name}
          value={choice.value}
          disabled={choice.disabled === true}
          checked={this.isSelectedChoice(choice)}
          onChange={this.onChange}
        />
        <label htmlFor={this.props.name + index}>{choice.label}</label>
      </div>
    );
  }

  renderErrors() {
    let { error } = this.props;

    if (!error) {
      return null;
    }

    return (
      <div className="text-danger">
        {[].concat(error).map((error) => (
          <p key={error}>{error}</p>
        ))}
      </div>
    );
  }

  /**
   * {@inheritdoc}
   */
  render() {
    return (
      <div className="form-group">
        <div className="radio">
          {this.props.choices.map(this.renderChoice)}
          {this.renderErrors()}
        </div>
      </div>
    );
  }
}

export default Radio;
