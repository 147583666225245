/*
 * This file was generated by the "elao/enum" PHP package.
 * The code inside belongs to you and can be altered, but no BC promise is guaranteed.
 */

import { ReadableEnum } from "../../../libs/enum";

class TreatmentMode extends ReadableEnum {
  static REGENERATION = "REGENERATION";
  static DESTRUCTION = "DESTRUCTION";

  static PROCESS_TYPE_CODE = {
    [TreatmentMode.REGENERATION]: "R",
    [TreatmentMode.DESTRUCTION]: "D",
  };

  get processTypeCode() {
    return TreatmentMode.PROCESS_TYPE_CODE[this.value];
  }

  static get readables() {
    return {
      [TreatmentMode.REGENERATION]: "enum.waste.treatment_mode.regeneration",
      [TreatmentMode.DESTRUCTION]: "enum.waste.treatment_mode.destruction",
    };
  }
}

export default TreatmentMode;
