const GraphQLErrors = {
  /**
   * @see App\Infra\GraphQL\Error\InvalidPayloadError
   */
  INVALID_PAYLOAD: "c401ff19-145c-4cf7-8c24-30679f058b5a",
  /**
   * @see App\Infra\GraphQL\Error\ForbiddenError
   */
  FORBIDDEN: "5c52ae68-69bd-42ff-a610-9a4aff8cc895",
  /**
   * @see App\Infra\GraphQL\Error\NotFoundError
   */
  NOT_FOUND: "85377fc1-feb3-4e4c-b1f0-47ead2125a44",
  /**
   * @see  App\Infra\Bridge\GraphQL\Error\DomainError
   */
  DOMAIN_ERROR: "d3b05254-4efa-4530-ac01-cf87bd23dd80",
};

export default GraphQLErrors;
