import I18n from "i18n-js";
import React, { Component } from "react";
import { func, shape, bool } from "prop-types";
import { connect } from "react-redux";
import TextInput from "../../common/components/form/TextInput";
import Select from "../../common/components/form/Select";
import SubmitButton from "../../common/components/form/SubmitButton";
import Roles from "../models/Roles";
import ClientTypes from "../../company/models/ClientTypes";
import { alphabetically } from "../../common/utils/filterUtils";
import ClientUtils from "../../company/utils/ClientUtils";
import { getClientsList } from "../../company/actions/clientsActions";

class CreateUserForm extends Component {
  static propTypes = {
    clients: shape().isRequired,
    getClientsList: func.isRequired,
    user: shape().isRequired,
    loading: bool,
    infos: shape(),
    onSubmit: func.isRequired,
    goBack: func.isRequired,
    denyAccess: func.isRequired,
  };

  static defaultProps = {
    loading: false,
    infos: {},
  };

  state = {
    // Init selected client uuid in state from existing info, if any
    selectedClient:
      (this.props.infos?.client &&
        this.props.clients?.list?.find(
          (client) => client.uuid === this.props.infos.client.uuid,
        )) ||
      null,
    clientError: null,
    roleError: null,
    emailError: null,
    passwordError: null,
    lastnameError: null,
    firstnameError: null,
  };

  constructor(props) {
    super(props);

    this.updateMod = !!props.infos.uuid;

    this.onSubmit = this.onSubmit.bind(this);
    this.setClient = this.setClient.bind(this);
    this.setRole = this.setRole.bind(this);
    this.setEmail = this.setEmail.bind(this);
    this.setPassword = this.setPassword.bind(this);
    this.setLastname = this.setLastname.bind(this);
    this.setFirstname = this.setFirstname.bind(this);
    this.setPosition = this.setPosition.bind(this);
    this.getRolesUserList = this.getRolesUserList.bind(this);
    this.onSelectClient = this.onSelectClient.bind(this);
    this.renderOption = this.renderOption.bind(this);
    this.renderClientOption = this.renderClientOption.bind(this);
    this.renderClientsSelect = this.renderClientsSelect.bind(this);
  }
  /**
   * {@inheritdoc}
   */
  componentDidMount() {
    this.props.getClientsList();
  }

  /**
   * {@inheritdoc}
   */
  componentDidUpdate(prevProps) {
    const { infos, denyAccess, goBack, user } = this.props;

    if (infos.roles && infos.roles[0] === Roles.SUPER_ADMIN) {
      denyAccess();

      return;
    }

    if (
      prevProps.user.saved === user.saved &&
      prevProps.user.errors === user.errors
    ) {
      return;
    }

    if (user.errors) {
      user.errors.map((error) =>
        this.setState({ [`${error.path}Error`]: error.reason }),
      );
    }

    if (user.saved) {
      goBack();
    }
  }

  /**
   * onSubmit form
   */
  onSubmit() {
    if (!this.isValid()) {
      return;
    }

    const user = {
      clientUuid: this.client.value,
      roles: [this.role.value],
      lastname: this.lastname.value,
      firstname: this.firstname.value,
      position: this.position.value,
    };

    if (!this.updateMod) {
      user.email = this.email.value;
      user.password = this.password.value;
    }

    this.props.onSubmit(user);
  }

  /**
   * @param {Event} event
   */
  onSelectClient(event) {
    this.setSelectedClient(event.target.value);
  }

  /**
   * @param {String} clientUuid
   * @param {Array}  clients
   */
  setSelectedClient(clientUuid) {
    const selectedClient = this.props.clients.list.find(
      (client) => client.uuid === clientUuid,
    );

    this.setState({ selectedClient });
  }

  /**
   * @param {String} client
   */
  setClient(client) {
    this.client = client;
  }

  /**
   * @param {String} role
   */
  setRole(role) {
    this.role = role;
  }

  /**
   * @param {String} email
   */
  setEmail(email) {
    this.email = email;
  }

  /**
   * @param {String} password
   */
  setPassword(password) {
    this.password = password;
  }

  /**
   * @param {String} lastname
   */
  setLastname(lastname) {
    this.lastname = lastname;
  }

  /**
   * @param {String} firstname
   */
  setFirstname(firstname) {
    this.firstname = firstname;
  }

  /**
   * @param {String} position
   */
  setPosition(position) {
    this.position = position;
  }

  /**
   * Define roles user list depending on selected client
   *
   * @return {Array}
   */
  getRolesUserList() {
    const { selectedClient } = this.state;
    const { clients } = this.props;

    if (
      !selectedClient ||
      typeof selectedClient === "string" ||
      !clients.list.length
    ) {
      return [];
    }

    const clientType = new ClientTypes(selectedClient.type);

    return Roles.forClientType(clientType).map((role) => ({
      label: I18n.t(Roles.readableFor(role)),
      value: role,
    }));
  }

  /**
   * @return {Boolean}
   */
  isValid() {
    const clientLength = this.client.value.length;
    const roleLength = this.role.value.length;
    const lastnameLength = this.lastname.value.length;
    const firstnameLength = this.firstname.value.length;

    const emptyInputText = I18n.t("common.not_specified");
    const clientErrorText = I18n.t(
      "pages.users.create.form.client:error:empty",
    );
    const roleErrorText = I18n.t("pages.users.create.form.role:error:empty");

    this.setState({
      clientError: !clientLength ? clientErrorText : null,
      roleError: !roleLength ? roleErrorText : null,
      lastnameError: !lastnameLength ? emptyInputText : null,
      firstnameError: !firstnameLength ? emptyInputText : null,
    });

    if (this.updateMod) {
      return roleLength && lastnameLength && firstnameLength;
    }

    const emailLength = this.email.value.length;
    const passwordLength = this.password.value.length;

    this.setState({
      emailError: !emailLength ? emptyInputText : null,
      passwordError: !passwordLength ? emptyInputText : null,
    });

    return (
      clientLength &&
      roleLength &&
      emailLength &&
      passwordLength &&
      lastnameLength &&
      firstnameLength
    );
  }

  /**
   * @param {Object} option
   *
   * @return {Element}
   */
  renderOption(option) {
    return (
      <option key={`option-${option.value}`} value={option.value}>
        {option.label}
      </option>
    );
  }

  /**
   * @param {Object} client
   *
   * @return {Element}
   */
  renderClientOption(client) {
    return (
      <option key={`option-${client.uuid}`} value={client.uuid}>
        {ClientUtils.renderLabel(client)}
      </option>
    );
  }

  /**
   * Render clients select
   *
   * @return {Element}
   */
  renderClientsSelect() {
    const { clients, infos } = this.props;
    const { clientError } = this.state;

    const options = clients.list
      .filter((client) => new ClientTypes(client.type).canCreateUsers())
      .sort((a, b) =>
        alphabetically(ClientUtils.renderLabel(a), ClientUtils.renderLabel(b)),
      );

    return (
      <Select
        id="client"
        label={I18n.t("pages.users.create.form.client:label")}
        ref={this.setClient}
        options={options}
        renderOption={this.renderClientOption}
        defaultValue={(this.updateMod && infos.client.uuid) || ""}
        onChange={this.onSelectClient}
        error={clientError}
        disabled={this.updateMod}
        loadingError={Boolean(clients.error)}
        loading={clients.inProgress}
      />
    );
  }

  /**
   * {@inheritdoc}
   */
  render() {
    const { infos, loading } = this.props;
    const {
      roleError,
      emailError,
      passwordError,
      lastnameError,
      firstnameError,
    } = this.state;

    return (
      <form>
        {this.renderClientsSelect()}
        <Select
          id="role"
          label={I18n.t("pages.users.create.form.role:label")}
          ref={this.setRole}
          options={this.getRolesUserList()}
          renderOption={this.renderOption}
          defaultValue={infos.roles && infos.roles[0]}
          error={roleError}
        />
        <TextInput
          id="lastname"
          label={I18n.t("pages.users.create.form.lastname:label")}
          ref={this.setLastname}
          defaultValue={infos.lastname}
          error={lastnameError}
        />
        <TextInput
          id="firstname"
          label={I18n.t("pages.users.create.form.firstname:label")}
          ref={this.setFirstname}
          defaultValue={infos.firstname}
          error={firstnameError}
        />
        {!this.updateMod && (
          <TextInput
            id="email"
            type="email"
            label={I18n.t("pages.users.create.form.email:label")}
            ref={this.setEmail}
            error={emailError}
          />
        )}
        {!this.updateMod && (
          <TextInput
            id="password"
            type="password"
            label={I18n.t("pages.users.create.form.password:label")}
            ref={this.setPassword}
            error={passwordError}
          />
        )}
        <TextInput
          id="position"
          label={I18n.t("pages.users.create.form.position:label")}
          ref={this.setPosition}
          defaultValue={infos.position}
          optional
        />
        <SubmitButton loading={loading} onClick={this.onSubmit} />
      </form>
    );
  }
}

export default connect(
  (state) => ({
    clients: state.clients,
    user: state.user,
  }),
  (dispatch) => ({
    getClientsList: () => dispatch(getClientsList()),
  }),
)(CreateUserForm);
