class ClientUtils {
  /**
   * We identify the client by the "[legalCompanyName] - [city]" couple on the BO
   * in order to be able to distinguish multi-DO clients, e.g:
   * "AXIMA REFRIGERATION FRANCE"
   * becomes:
   * "AXIMA REFRIGERATION FRANCE - LESQUIN"
   * as there are many clients named "AXIMA REFRIGERATION FRANCE"
   *
   * @param {Object} clientRef A client reference of resource
   */
  static renderLabel(clientRef) {
    return `${clientRef.legalCompanyName} - ${
      clientRef.city || clientRef.address.city
    }`;
  }
}

export default ClientUtils;
