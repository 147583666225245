import { fetch } from "../../../appContainer";
import {
  GET_DASHBOARD_METRICS_FAIL,
  GET_DASHBOARD_METRICS_IN_PROGRESS,
  GET_DASHBOARD_METRICS_SUCCESS,
} from "../constants";

export function getDashboardMetrics(interventionsSince) {
  return (dispatch) => {
    dispatch({ type: GET_DASHBOARD_METRICS_IN_PROGRESS });

    fetch("api").getDashboardMetrics(
      interventionsSince,
      (metrics) => {
        dispatch({ type: GET_DASHBOARD_METRICS_SUCCESS, payload: { metrics } });
      },
      () => dispatch({ type: GET_DASHBOARD_METRICS_FAIL }),
    );
  };
}
