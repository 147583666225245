import { fetch } from "../../../appContainer";
import {
  GET_CONTAINERS_LIST_IN_PROGRESS,
  GET_CONTAINERS_LIST_SUCCESS,
  GET_CONTAINERS_LIST_FAIL,
} from "../constants";

export function getContainersList() {
  return (dispatch) => {
    dispatch({ type: GET_CONTAINERS_LIST_IN_PROGRESS });

    fetch("api").getContainersList(
      (list) => {
        dispatch({ type: GET_CONTAINERS_LIST_SUCCESS, payload: { list } });
      },
      () => dispatch({ type: GET_CONTAINERS_LIST_FAIL }),
    );
  };
}
