import I18n from "i18n-js";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { bool, func, shape, string, oneOfType, instanceOf } from "prop-types";
import { ROUTE_CLIENTS } from "../../../routes";
import { importClients } from "../actions/clientsActions";
import ImportFinalClientStrategy from "../models/ImportFinalClientStrategy";
import FormPage from "../../common/components/layout/FormPage";
import SubmitButton from "../../common/components/form/SubmitButton";
import DownloadButton from "../../common/components/elements/DownloadButton";
import Radio from "../../common/components/form/Radio";
import { FILE_ENCODINGS, readFileContent } from "../../common/utils/fileUtils";
import ImportClientsContent from "./ImportClients/ImportClientsContent";
import CompanyCountry from "../models/CompanyCountry";

class ImportClients extends FormPage {
  static propTypes = {
    error: oneOfType([shape(), bool]),
    success: bool,
    tsvReport: shape({
      content: instanceOf(Blob).isRequired,
      name: string.isRequired,
    }),
    importClients: func.isRequired,
    userClient: shape().isRequired,
    userProfile: shape().isRequired,
  };

  constructor() {
    super();

    this.state = {
      strategy: ImportFinalClientStrategy.ASK_PRESTATION,
      file: null,
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onSelectedFile = this.onSelectedFile.bind(this);
  }

  /**
   * {@inheritdoc}
   */
  componentDidUpdate(prevProps) {
    const { error, success } = this.props;

    if (prevProps.error === error || prevProps.success === success) {
      return;
    }

    if (error || success) {
      this.setState({ loading: false });
    }
  }

  onSelectedFile(e) {
    const file = e.target.files.length > 0 && e.target.files[0];
    this.setState({ file });
  }

  onSubmit() {
    const { strategy, file } = this.state;
    const { importClients } = this.props;

    this.setState({ loading: true });

    readFileContent(file, FILE_ENCODINGS.TSV).then((tsvContent) => {
      importClients(strategy, tsvContent);
    });
  }

  getStrategyChoices() {
    return ImportFinalClientStrategy.values.map((strategy) => ({
      label: I18n.t(
        `pages.clients.import.${ImportFinalClientStrategy.readables[strategy]}`,
      ),
      value: strategy,
    }));
  }

  hasSelectedFile() {
    return !!this.state.file;
  }

  /**
   * {@inheritdoc}
   */
  getTitle() {
    return I18n.t("pages.clients.import.title");
  }

  /**
   * {@inheritdoc}
   */
  getBackRoute() {
    return ROUTE_CLIENTS;
  }

  /**
   * {@inheritdoc}
   */
  renderContent() {
    const { loading } = this.state;
    const { error, success, tsvReport, userClient, userProfile } = this.props;
    const locale = I18n.locale;

    return (
      <Fragment>
        <ImportClientsContent
          client={userClient.legalCompanyName}
          companyCountry={new CompanyCountry(userProfile.companyCountry)}
          strategyWidget={
            <Radio
              name="strategy"
              choices={this.getStrategyChoices()}
              onChange={(strategy) => {
                this.setState({ strategy });
              }}
              value={this.state.strategy}
            />
          }
          downloadSample={
            <a href={`/import_samples/${locale}/import_clients.tsv`}>
              {I18n.t("pages.clients.import.file_sample")}
            </a>
          }
        />

        <span style={{ marginRight: "10px" }}>
          {I18n.t("pages.clients.import.file_input")}
        </span>
        <input type="file" accept=".csv, .tsv" onChange={this.onSelectedFile} />
        <SubmitButton
          loading={loading}
          onClick={this.onSubmit}
          label={I18n.t("pages.clients.import.submit")}
          disabled={!this.hasSelectedFile()}
        />
        <br />
        {success && (
          <div className="text-primary">
            <span>{I18n.t("pages.clients.import.success")}</span>
          </div>
        )}
        {error && (
          <div className="text-danger">
            <span>{I18n.t("pages.clients.import.error")}</span>
          </div>
        )}
        {error && typeof error === "object" && (
          <div>
            {error.detail.split("\n").map((message) => (
              <span className="text-danger" key={message}>
                {message}
              </span>
            ))}
          </div>
        )}
        {tsvReport && (
          <div>
            <p className="text-warning">
              ⚠︎ {I18n.t("pages.clients.import.tsv_report.explanation")}
            </p>
            <DownloadButton
              fileContent={() => tsvReport.content}
              filename={tsvReport.name}
              label={I18n.t("pages.clients.import.tsv_report.download_btn")}
            />
          </div>
        )}
        {/* TODO: List & explain most common errors */}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  error: state.importClients.error,
  success: state.importClients.success,
  tsvReport: state.importClients.tsvReport,
  userClient: state.clients.list.find(
    (client) => client.uuid === state.auth.userProfile.clientUuid,
  ),
  userProfile: state.auth.userProfile,
});

const mapDispatchToProps = (dispatch) => ({
  importClients: (strategy, tsvContent) =>
    dispatch(importClients(strategy, tsvContent)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImportClients);
