import React, { Component } from "react";
import { string, func } from "prop-types";

class DownloadButton extends Component {
  static propTypes = {
    label: string.isRequired,
    filename: string.isRequired,
    fileContent: func.isRequired, // Function to evaluate in order to retrieve the file content (string or Blob)
    contentType: string,
  };

  static defaultProps = {
    contentType: null,
  };

  /**
   * @param {String|Blob} content
   */
  static download(content, fileName, contentType) {
    const blob =
      content instanceof Blob
        ? content
        : new Blob([content], { type: contentType });
    const element = document.createElement("a");

    document.body.appendChild(element);
    element.setAttribute("href", window.URL.createObjectURL(blob));
    element.setAttribute("download", fileName);
    element.style.display = "";
    element.click();
    document.body.removeChild(element);
  }

  constructor(props) {
    super(props);

    this.state = { isLoading: false };

    this.onClick = this.onClick.bind(this);
  }

  onClick(event) {
    event.stopPropagation();

    this.setState({ isLoading: true });

    const fileType = event.target.innerText;
    const content = this.props.fileContent(fileType);
    const { contentType, filename } = this.props;

    const download = (fileContent) => {
      if (fileContent !== null) {
        DownloadButton.download(fileContent, filename, contentType);
      }

      this.setState({ isLoading: false });
    };

    if (content instanceof Promise) {
      content.then(download);
    } else {
      download(content);
    }
  }

  /**
   * Render button content
   *
   * @return {String|Element}
   */
  render() {
    const { label } = this.props;
    const { isLoading } = this.state;

    return (
      <button
        onClick={this.onClick}
        className="btn btn-primary"
        disabled={isLoading}
      >
        <span className="fa fa-download" />
        {label}
        {isLoading && <div className="loader pull-right" />}
      </button>
    );
  }
}

export default DownloadButton;
