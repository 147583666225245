import I18n from "i18n-js";
import React, { Component } from "react";
import { func, shape } from "prop-types";
import { connect, useDispatch } from "react-redux";
import { Link, Redirect, Route } from "react-router-dom";
import { fetch } from "../../../../appContainer";
import {
  ROUTE_CGU,
  ROUTE_FORBIDDEN,
  ROUTE_LOGIN,
  ROUTE_PRIVACY_POLICY,
} from "../../../../routes";
import Header from "../layout/Header";
import Footer from "../layout/Footer";
import Modal from "../elements/Modal";
import {
  acceptTerms,
  getUserProfile,
  logout,
} from "../../../authentication/actions/authActions";
import { useQuery } from "@apollo/client";
import getUserClient from "../../../company/graphql/queries/getUserClient.graphql";
import { useHistory } from "react-router";

/**
 * Private route
 * Custom react-router route
 */
class PrivateRoute extends Component {
  static propTypes = {
    component: func.isRequired,
    location: shape().isRequired,
    auth: shape().isRequired,
    getUserProfile: func.isRequired,
    acceptTerms: func.isRequired,
  };

  /**
   * {@inheritdoc}
   */
  componentDidMount() {
    if (!this.props.auth.logged) {
      return;
    }

    this.getData();
  }

  /**
   * {@inheritdoc}
   */
  componentDidUpdate(prevProps) {
    const { auth } = this.props;

    if (prevProps.auth.logged !== auth.logged && auth.logged) {
      this.getData();
    }
  }

  getData() {
    const { getUserProfile, auth } = this.props;

    // Logged without user profile
    if (!auth.userProfile) {
      getUserProfile();
    }
  }

  /**
   * @return {Boolean}
   */
  hasAccess() {
    return true;
  }

  renderTermsModal() {
    const { auth, acceptTerms } = this.props;

    return (
      <Modal
        isOpen={!auth.userProfile.termsAccepted}
        title={I18n.t("terms_alert.title")}
        className="center small"
      >
        <p>{I18n.t("terms_alert.content")}</p>
        <p>
          <Link to={ROUTE_PRIVACY_POLICY} target="_blank">
            {I18n.t("terms_alert.link_to_privacy")}
          </Link>
        </p>
        <p>
          <Link to={ROUTE_CGU} target="_blank">
            {I18n.t("terms_alert.link_to_cgu")}
          </Link>
        </p>
        <button className="btn btn-primary" onClick={acceptTerms}>
          {I18n.t("terms_alert.validate")}
        </button>
      </Modal>
    );
  }

  renderComponent(props, ownClient) {
    const { component: Component, auth } = this.props;
    const { location, history } = props;

    if (!this.hasAccess()) {
      history.replace(ROUTE_FORBIDDEN);

      return null;
    }

    return (
      <div className="protected container-fluid">
        {this.renderTermsModal()}
        <Header
          ownClient={ownClient}
          userProfile={auth.userProfile}
          location={location}
        />
        <section>
          <Component ownClient={ownClient} {...props} />
        </section>
        <Footer />
      </div>
    );
  }

  render() {
    const { component, ...rest } = this.props; // eslint-disable-line

    if (!fetch("authenticator").isAuthenticated()) {
      return (
        <Redirect
          to={{
            pathname: ROUTE_LOGIN,
            state: { from: this.props.location.pathname },
          }}
        />
      );
    }

    if (!rest.auth?.userProfile?.clientUuid) {
      return <div className="route-loader" />;
    }

    return (
      <WithUserClientRoute clientUuid={rest.auth.userProfile.clientUuid}>
        {({ ownClient }) => (
          <Route
            {...rest}
            render={(props) => this.renderComponent(props, ownClient)}
          />
        )}
      </WithUserClientRoute>
    );
  }
}

const mapStateToProps = (state) => ({
  clients: state.clients,
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  getUserProfile: () => dispatch(getUserProfile()),
  acceptTerms: () => dispatch(acceptTerms()),
});

export const RawPrivateRoute = PrivateRoute;

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);

/**
 * Renders a route content once the user client is loaded.
 */
function WithUserClientRoute({ children, clientUuid }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { loading, error, data } = useQuery(getUserClient, {
    variables: {
      uuid: clientUuid,
    },
    context: {
      // On 404 trying to get the user client, logout, as we're likely to have an outdated profile in app local storage.
      on404: () => {
        dispatch(logout());
        history.push(ROUTE_LOGIN);
      },
    },
  });

  if (loading) {
    return <div className="route-loader" />;
  }

  if (error) {
    return <p>{I18n.t("common.loadingApiError")}</p>;
  }

  return children({ ownClient: data.getClient });
}
