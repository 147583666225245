import React from "react";
import I18n from "i18n-js";
import WasteFormBreadcrumb from "../components/WasteFormBreadcrumb";
import FormLayout from "../../common/components/layout/FormLayout";
import { ROUTE_MY_WASTE_RECOVERY_REQUESTS } from "../../../routes";
import { useQuery } from "@apollo/client";
import { useMutation } from "../../common/api/GraphQLClient";
import createWasteRecoveryRequestMutation from "../graphql/mutations/createWasteRecoveryRequest.graphql";
import { useHistory } from "react-router";
import { WasteRequestInput } from "../propTypes/types";
import { func } from "prop-types";
import { connect } from "react-redux";
import getContainer from "../../containers/graphql/queries/getContainer.graphql";
import getWasteRecoverableArticle from "../../waste/graphql/queries/getWasteRecoverableArticle.graphql";
import getClient from "../../company/graphql/queries/getClient.graphql";
import Spinner from "../../common/components/Spinner";
import Quantity from "../../common/components/elements/Quantity";
import TreatmentMode from "../models/TreatmentMode";
import Packaging from "../models/Packaging";
import {
  clear,
  setViolations,
} from "../actions/createWasteRecoveryRequestActions.js";
import { errorAlert, successAlert } from "../../common/actions/alertActions";
import RawSubmit from "../../common/components/form/RawSubmit";
import { getGraphQLViolations } from "../../common/api/api_problem";
import RequestSummary, {
  BooleanCell,
} from "../components/wasteRequestSummary/RequestSummary";
import { onCreateWasteRecoveryRequest } from "../graphql/cache/apolloCache";

export default connect(
  (state) => ({
    request: state.createWasteRecoveryRequestReducer,
  }),
  {
    clear,
    successAlert,
    errorAlert,
    setViolations,
  },
)(CreateWasteRecoveryRequestSummary);

CreateWasteRecoveryRequestSummary.propTypes = {
  request: WasteRequestInput,
  clear: func.isRequired,
  successAlert: func.isRequired,
  errorAlert: func.isRequired,
  setViolations: func.isRequired,
};

function CreateWasteRecoveryRequestSummary({
  request,
  clear,
  successAlert,
  errorAlert,
  setViolations,
}) {
  const history = useHistory();
  const [createWasteRecoveryRequest, { loading }] = useMutation(
    createWasteRecoveryRequestMutation,
    {
      update: onCreateWasteRecoveryRequest,
    },
  );

  const submit = (event) => {
    event.preventDefault();

    const {
      siteUuid,
      date,
      comments,
      nbCages,
      nbPallets,
      attestationReceiptAddress,
      containers,
      articles,
      charteredByClient,
      carrierUuid,
      transportNumber,
    } = request;

    createWasteRecoveryRequest({
      variables: {
        payload: {
          siteUuid,
          date: date ? new Date(date).toISOString() : null,
          comments,
          nbCages,
          nbPallets,
          attestationReceiptAddress,
          containers,
          articles,
          charteredByClient,
          carrierUuid,
          transportNumber,
        },
      },
    })
      .then(() => {
        successAlert("Demande enregistrée avec succès");
        history.push(ROUTE_MY_WASTE_RECOVERY_REQUESTS);
        clear(); // clear waste state
      })
      .catch(({ graphQLErrors }) => {
        // Show error alert:
        errorAlert("Une erreur est survenue");

        // Store violations:
        const violations = getGraphQLViolations(graphQLErrors);
        setViolations(violations);

        // Redirect to first error step, if any:
        const redirectStepNb = resolveErrorStep(violations);
        if (redirectStepNb !== WasteFormBreadcrumb.SUMMARY) {
          history.push(WasteFormBreadcrumb.ROUTES[redirectStepNb]);
        }
      });
  };

  return (
    <form onSubmit={submit}>
      <FormLayout
        title="Demande de reprise de déchets"
        backRoute={ROUTE_MY_WASTE_RECOVERY_REQUESTS}
        size={FormLayout.LARGE}
        actions={
          <>
            <RawSubmit loading={loading} className="ml-1">
              Enregistrer
            </RawSubmit>
          </>
        }
      >
        <WasteFormBreadcrumb position={WasteFormBreadcrumb.SUMMARY} />

        <RequestSummary
          request={request}
          renderContainerRow={ContainerRow}
          renderArticleRow={ArticleRow}
        >
          <div className="col-12 mt-4 order-last">
            <RawSubmit loading={loading} className="float-right">
              Enregistrer
            </RawSubmit>
          </div>
        </RequestSummary>
      </FormLayout>
    </form>
  );
}

function ContainerRow(containerData) {
  const { loading, data } = useQuery(getContainer, {
    variables: { uuid: containerData.containerUuid },
  });

  const container = data?.getContainer;
  const loader = loading && <Spinner key="loader" />;

  return [
    /* eslint-disable react/jsx-key */
    loader || container.barcode,
    loader || container.article.designation,
    loader || container.knownFluid?.designation,
    loader || <Quantity value={container.article.volume} unit={"L"} />,
    loader || <Quantity value={container.currentLoad} unit={"kg"} />,
    containerData.treatmentMode &&
      I18n.t(new TreatmentMode(containerData.treatmentMode).getReadable()),
    <BooleanCell value={containerData.toDestruct} />,
    <BooleanCell value={containerData.toReturn} />,
    containerData.packaging &&
      I18n.t(new Packaging(containerData.packaging).getReadable()),
    /* eslint-enable react/jsx-key */
  ];
}

function ArticleRow(articleData) {
  const { loading, data } = useQuery(getWasteRecoverableArticle, {
    variables: { uuid: articleData.articleUuid },
  });
  const article = data?.getWasteRecoverableArticle;

  const { loading: clientLoading, data: clientData } = useQuery(getClient, {
    variables: { uuid: articleData.producerUuid },
  });
  const producer = clientData?.getClient;
  const loader = <Spinner key="loader" />;

  return [
    /* eslint-disable react/jsx-key */
    loading ? loader : article?.designation,
    clientLoading ? loader : producer?.legalCompanyName,
    articleData.quantity,
    <Quantity value={articleData.mass} unit={"kg"} />,
    articleData.treatmentMode &&
      I18n.t(new TreatmentMode(articleData.treatmentMode).getReadable()),
    <BooleanCell value={articleData.toDestruct} />,
    <BooleanCell value={articleData.toReturn} />,
    articleData.packaging &&
      I18n.t(new Packaging(articleData.packaging).getReadable()),
    /* eslint-enable react/jsx-key */
  ];
}

/**
 * Resolves the first step where to redirect according to the GraphQL API violations and fields handled by each step.
 */
function resolveErrorStep(violations) {
  const stepFields = {
    [WasteFormBreadcrumb.INFO]: [
      "siteUuid",
      "date",
      "attestationReceiptAddress",
      "comments",
    ],
    [WasteFormBreadcrumb.CONTAINERS]: ["containers"],
    [WasteFormBreadcrumb.ARTICLES]: ["articles"],
    [WasteFormBreadcrumb.TRANSPORT]: [
      "carrierUuid",
      "transportNumber",
      "charteredByClient",
      "nbPallets",
      "nbCages",
    ],
  };
  const firstStep = Math.min(Object.keys(stepFields));
  const matches = new Set([WasteFormBreadcrumb.SUMMARY]);

  violations.forEach(({ path }) => {
    Object.entries(stepFields).forEach(([step, fields]) => {
      // Skip if the step to test is greater than the min match, as it's useless to process...
      if (step < Math.min(...matches)) {
        const regex = new RegExp(
          `^payload\\.(${fields.join("|")})((\\.|\\[\\w+\\]\\.)(.+))?`,
        );

        if (regex.test(path)) {
          if (step === firstStep) {
            return firstStep;
          }

          matches.add(step);
        }
      }
    });
  });

  return Math.min(...matches);
}
