import I18n from "i18n-js";
import React from "react";
import { func, shape } from "prop-types";
import { connect } from "react-redux";
import { ROUTE_LEAK_DETECTORS } from "../../../routes";
import { createLeakDetector } from "../actions/leakDetectorsActions";
import FormPage from "../../common/components/layout/FormPage";
import CreateLeakDetectorForm from "../components/CreateLeakDetectorForm";

class CreateLeakDetector extends FormPage {
  static propTypes = {
    leakDetector: shape().isRequired,
    match: shape().isRequired,
    createLeakDetector: func.isRequired,
  };

  constructor() {
    super();

    this.onSubmit = this.onSubmit.bind(this);
  }

  /**
   * @param {object} detector
   */
  onSubmit(detector) {
    const { createLeakDetector, match } = this.props;
    const paramsDetector = {
      clientUuid: match.params.uuid,
      ...detector,
    };

    createLeakDetector(paramsDetector);
  }

  /**
   * {@inheritdoc}
   */
  getTitle() {
    return I18n.t("pages.leak_detectors.add.title");
  }

  /**
   * {@inheritdoc}
   */
  getBackRoute() {
    return ROUTE_LEAK_DETECTORS;
  }

  /**
   * {@inheritdoc}
   */
  renderContent() {
    const { leakDetector } = this.props;

    return (
      <CreateLeakDetectorForm
        loading={leakDetector.saving}
        onSubmit={this.onSubmit}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  leakDetector: state.leakDetector,
});

const mapDispatchToProps = (dispatch) => ({
  createLeakDetector: (params) => dispatch(createLeakDetector(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateLeakDetector);
