/**
 * Returns a copy of the array without given element.
 */
export function removeElement(array, element) {
  const copy = [...array];
  const index = copy.indexOf(element);

  if (-1 !== index) {
    copy.splice(index, 1);
  }

  return copy;
}

/**
 * Converts an array of data to an object with properties taken from indexKey value.
 *
 * E.g, from:
 *
 * ```js
 * [
 *   { id: 2, name: 'John' },
 *   { id: 3, name: 'Jane' },
 * ]
 * ```
 *
 * to:
 *
 * ```js
 * {
 *   2: { id: 2, name: 'John' },
 *   3: { id: 3, name: 'Jane' },
 * }
 * ```
 */
export function arrayToObject(array, indexKey) {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[indexKey]]: item,
    };
  }, initialValue);
}
