import I18n from "i18n-js";
import React from "react";
import moment from "moment";
import { arrayOf, func, shape } from "prop-types";
import { connect } from "react-redux";
import List from "../../common/components/layout/List";
import Table from "../../common/components/elements/Table";
import Quantity from "../../common/components/elements/Quantity";
import DateInput from "../../common/components/form/DateInput";
import {
  getFluidSummariesList,
  updateFluidSummariesList,
} from "../actions/fluidSummariesActions";

class FluidSummaryList extends List {
  static propTypes = {
    fluidSummaries: shape().isRequired,
    fluidSummariesList: arrayOf(shape).isRequired,
    getFluidSummariesList: func.isRequired,
    updateFluidSummariesList: func.isRequired,
  };

  constructor() {
    super();

    this.state.filters.startDate = new Date(moment().startOf("year")).getTime();
    this.state.filters.endDate = new Date(moment().endOf("year")).getTime();

    this.refreshDataState = this.refreshDataState.bind(this);
  }

  /**
   * {@inheritdoc}
   */
  componentDidMount() {
    super.componentDidMount();

    const { startDate, endDate } = this.state.filters;

    this.props.getFluidSummariesList(
      new Date(parseInt(startDate)),
      new Date(parseInt(endDate)),
    );
  }

  /**
   * @param {Object} prevState
   */
  refreshDataState(prevState) {
    const { startDate, endDate } = this.state.filters;

    if (
      prevState.filters.startDate === startDate &&
      prevState.filters.endDate === endDate
    ) {
      return;
    }

    this.props.updateFluidSummariesList(
      new Date(parseInt(startDate)),
      new Date(parseInt(endDate)),
    );
  }

  /**
   * @return {Array}
   */
  dataTableHead() {
    return [
      I18n.t("pages.fluid_summaries.list.head.fluid_designation"),
      I18n.t("pages.fluid_summaries.list.head.filling_quantity"),
      I18n.t("pages.fluid_summaries.list.head.filling_quantityCO2"),
      I18n.t("pages.fluid_summaries.list.head.treatment_quantity"),
      I18n.t("pages.fluid_summaries.list.head.treatment_quantityCO2"),
      I18n.t("pages.fluid_summaries.list.head.recuperation_quantity"),
      I18n.t("pages.fluid_summaries.list.head.recuperation_quantityCO2"),
      I18n.t("pages.fluid_summaries.list.head.ceded_quantity"),
      I18n.t("pages.fluid_summaries.list.head.ceded_quantityCO2"),
      I18n.t("pages.fluid_summaries.list.head.treatment_site_designation"),
    ];
  }

  /**
   * @param {Object} rowData A single fluid usage summary item
   *
   * @return {Array}
   */
  dataTableRow(rowData) {
    const {
      fluidName,
      fillingQuantity,
      fillingQuantityCO2,
      treatmentQuantity,
      treatmentQuantityCO2,
      recuperationQuantity,
      recuperationQuantityCO2,
      cededQuantity,
      cededQuantityCO2,
      treatmentSiteDesignation,
    } = rowData;

    return [
      fluidName,
      <Quantity key="fillingQuantity" value={fillingQuantity} />,
      <Quantity
        key="fillingQuantityCO2"
        value={fillingQuantityCO2}
        unit="CO2"
      />,
      <Quantity key="treatmentQuantity" value={treatmentQuantity} />,
      <Quantity
        key="treatmentQuantityCO2"
        value={treatmentQuantityCO2}
        unit="CO2"
      />,
      <Quantity key="recuperationQuantity" value={recuperationQuantity} />,
      <Quantity
        key="recuperationQuantityCO2"
        value={recuperationQuantityCO2}
        unit="CO2"
      />,
      <Quantity key="cededQuantity" value={cededQuantity} />,
      <Quantity key="cededQuantityCO2" value={cededQuantityCO2} unit="CO2" />,
      treatmentSiteDesignation,
    ];
  }

  /**
   * {@inheritdoc}
   */
  renderFilters() {
    const { filters } = this.state;

    return [
      <DateInput
        key="filterByStartDate"
        id="filterByStartDate"
        onChange={(date) =>
          this.onChangeDateFilter(date, "startDate", this.refreshDataState)
        }
        label={I18n.t("filters.by_start_date")}
        defaultValue={
          filters.startDate && new Date(parseInt(filters.startDate))
        }
        legend
        optional
      />,
      <DateInput
        key="filterByEndDate"
        id="filterByEndDate"
        onChange={(date) =>
          this.onChangeDateFilter(date, "endDate", this.refreshDataState)
        }
        label={I18n.t("filters.by_end_date")}
        defaultValue={filters.endDate && new Date(parseInt(filters.endDate))}
        legend
        optional
      />,
    ];
  }

  /**
   * {@inheritdoc}
   */
  renderContent() {
    const { fluidSummaries, fluidSummariesList } = this.props;

    if (fluidSummaries.inProgress) {
      return <div className="loader" />;
    }

    if (fluidSummaries.error) {
      return <p>{I18n.t("common.loadingApiError")}</p>;
    }

    return (
      <Table
        tableClasses="table--selectable"
        emptyContent={I18n.t("pages.fluid_summaries.list.no_content")}
        data={fluidSummariesList}
        headData={this.dataTableHead()}
        rowData={this.dataTableRow}
        pagination={false}
        filters={this.renderFilters()}
      />
    );
  }

  /**
   * {@inheritdoc}
   */
  renderTitle() {
    return I18n.t("pages.fluid_summaries.list.title");
  }
}

const mapStateToProps = (state) => ({
  fluidSummaries: state.fluidSummaries,
  fluidSummariesList: state.fluidSummaries.list,
});

const mapDispatchToProps = (dispatch) => ({
  getFluidSummariesList: (startDate, endDate) =>
    dispatch(getFluidSummariesList(startDate, endDate)),
  updateFluidSummariesList: (startDate, endDate) =>
    dispatch(updateFluidSummariesList(startDate, endDate)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FluidSummaryList);
