import React from "react";
import { array, bool, func, oneOf, oneOfType, string } from "prop-types";
import InvalidFeedback, { hasErrors } from "./InvalidFeedback";
import classnames from "classnames";
import CommonTypes from "../../propTypes/types";

StatelessTextInput.propTypes = {
  id: string.isRequired,
  errors: InvalidFeedback.propTypes.errors,
  label: oneOfType([string, oneOf([false])]).isRequired,
  labelInside: bool,
  legend: string,
  placeholder: string,
  datalist: array,
  type: string,
  optional: bool,
  onChangedValue: func,
  onChange: func,
  layout: oneOf(["vertical", "horizontal"]),
  labelClassName: string,
  widgetClassName: string,
  forwardedRef: CommonTypes.ref,
};

StatelessTextInput.defaultProps = {
  errors: null,
  labelInside: false,
  legend: null,
  placeholder: null,
  type: "text",
  optional: false,
  datalist: null,
  onChangedValue: null,
  onChange: null,
  layout: "vertical",
  labelClassName: null,
  widgetClassName: null,
  forwardedRef: null,
};

export default function StatelessTextInput({
  id,
  errors,
  label,
  labelInside,
  legend,
  datalist,
  type,
  optional,
  onChange,
  onChangedValue,
  forwardedRef, // forwarded ref for legacy textInput support
  layout,
  labelClassName,
  widgetClassName,
  ...props
}) {
  const horizontalLayout = layout === "horizontal";

  const _onChange = (event) => {
    onChange && onChange(event);

    let value = event.target.value;

    if (type === "number") {
      value = "" !== value ? parseFloat(value) : null;
    }

    onChangedValue && onChangedValue(value === "" ? null : value);
  };

  return (
    <div
      className={classnames("form-group", {
        row: horizontalLayout,
      })}
    >
      {label && (
        <label
          htmlFor={id}
          className={classnames({
            "sr-only": labelInside,
            "col-form-label": horizontalLayout,
            [labelClassName || "col-sm-2"]: horizontalLayout,
          })}
        >
          {label}
          {optional || "*"}
        </label>
      )}
      <div
        className={classnames(widgetClassName, {
          [widgetClassName || "col-sm-10"]: horizontalLayout,
        })}
      >
        <input
          id={id}
          ref={forwardedRef}
          type={type}
          className={classnames("form-control", {
            "is-invalid": hasErrors(errors),
          })}
          list={datalist && `datalist-${id}`}
          required={!optional}
          {...props}
          onChange={_onChange}
        />
        <InvalidFeedback errors={errors} />
        {legend && <small className="form-text text-muted">{legend}</small>}
        {datalist && (
          <datalist id={`datalist-${id}`}>
            {datalist.map((item) => (
              <option key={`datalist-${id}-item-${item}`} value={item} />
            ))}
          </datalist>
        )}
      </div>
    </div>
  );
}
