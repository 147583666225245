export const GET_CLIENTS_LIST_IN_PROGRESS = "GET_CLIENTS_LIST_IN_PROGRESS";
export const GET_CLIENTS_LIST_SUCCESS = "GET_CLIENTS_LIST_SUCCESS";
export const GET_CLIENTS_LIST_FAIL = "GET_CLIENTS_LIST_FAIL";

export const GET_SITES_LIST_IN_PROGRESS = "GET_SITES_LIST_IN_PROGRESS";
export const GET_SITES_LIST_SUCCESS = "GET_SITES_LIST_SUCCESS";
export const GET_SITES_LIST_FAIL = "GET_SITES_LIST_FAIL";

export const GET_SITE_INFOS_IN_PROGRESS = "GET_SITE_INFOS_IN_PROGRESS";
export const GET_SITE_INFOS_SUCCESS = "GET_SITE_INFOS_SUCCESS";
export const GET_SITE_INFOS_FAIL = "GET_SITE_INFOS_FAIL";
export const POST_SITE_IN_PROGRESS = "POST_SITE_IN_PROGRESS";
export const POST_SITE_SUCCESS = "POST_SITE_SUCCESS";
export const POST_SITE_FAIL = "POST_SITE_FAIL";
export const PUT_SITE_IN_PROGRESS = "PUT_SITE_IN_PROGRESS";
export const PUT_SITE_SUCCESS = "PUT_SITE_SUCCESS";
export const PUT_SITE_FAIL = "PUT_SITE_FAIL";

export const PUT_CLIENT_IN_PROGRESS = "PUT_CLIENT_IN_PROGRESS";
export const PUT_CLIENT_SUCCESS = "PUT_CLIENT_SUCCESS";
export const PUT_CLIENT_FAIL = "PUT_CLIENT_FAIL";
export const POST_CLIENT_IN_PROGRESS = "POST_CLIENT_IN_PROGRESS";
export const POST_CLIENT_SUCCESS = "POST_CLIENT_SUCCESS";
export const POST_CLIENT_FAIL = "POST_CLIENT_FAIL";
export const GET_CLIENT_INFO_IN_PROGRESS = "GET_CLIENT_INFO_IN_PROGRESS";
export const GET_CLIENT_INFO_SUCCESS = "GET_CLIENT_INFO_SUCCESS";
export const GET_CLIENT_INFO_FAIL = "GET_CLIENT_INFO_FAIL";
export const DELETE_CLIENT_IN_PROGRESS = "DELETE_CLIENT_IN_PROGRESS";
export const DELETE_CLIENT_SUCCESS = "DELETE_CLIENT_SUCCESS";
export const DELETE_CLIENT_FAIL = "DELETE_CLIENT_FAIL";
export const DELETE_SITE_IN_PROGRESS = "DELETE_SITE_IN_PROGRESS";
export const DELETE_SITE_SUCCESS = "DELETE_SITE_SUCCESS";
export const DELETE_SITE_FAIL = "DELETE_SITE_FAIL";

export const IMPORT_CLIENTS_IN_PROGRESS = "IMPORT_CLIENTS_IN_PROGRESS";
export const IMPORT_CLIENTS_SUCCESS = "IMPORT_CLIENTS_SUCCESS";
export const IMPORT_CLIENTS_FAIL = "IMPORT_CLIENTS_FAIL";

export const IMPORT_SITES_IN_PROGRESS = "IMPORT_SITES_IN_PROGRESS";
export const IMPORT_SITES_SUCCESS = "IMPORT_SITES_SUCCESS";
export const IMPORT_SITES_FAIL = "IMPORT_SITES_FAIL";

export const GET_ENTERPRISE_LOGO_IN_PROGRESS = "GET_COMPANY_LOGO_IN_PROGRESS";
export const GET_ENTERPRISE_LOGO_SUCCESS = "GET_COMPANY_LOGO_SUCCESS";
export const GET_ENTERPRISE_LOGO_FAIL = "GET_COMPANY_LOGO_FAIL";
export const UPDATE_ENTERPRISE_IN_PROGRESS = "UPDATE_COMPANY_LOGO_IN_PROGRESS";
export const UPDATE_ENTERPRISE_SUCCESS = "UPDATE_COMPANY_LOGO_SUCCESS";
export const UPDATE_ENTERPRISE_FAIL = "UPDATE_COMPANY_LOGO_FAIL";
