import { fetch } from "../../../appContainer";
import {
  GET_INTERVENTIONS_LIST_IN_PROGRESS,
  GET_INTERVENTIONS_LIST_SUCCESS,
  GET_INTERVENTIONS_LIST_FAIL,
  GET_INTERVENTION_INFO_IN_PROGRESS,
  GET_INTERVENTION_INFO_SUCCESS,
  GET_INTERVENTION_INFO_FAIL,
} from "../constants";

/**
 * Get interventions list
 */
export function getInterventionList() {
  return (dispatch) => {
    dispatch({ type: GET_INTERVENTIONS_LIST_IN_PROGRESS });

    fetch("api").getInterventionList(
      (list) => {
        dispatch({ type: GET_INTERVENTIONS_LIST_SUCCESS, payload: { list } });
      },
      () => dispatch({ type: GET_INTERVENTIONS_LIST_FAIL }),
    );
  };
}

/**
 * Get intervention info
 */
export function getInterventionInfo(uuid) {
  return (dispatch) => {
    dispatch({ type: GET_INTERVENTION_INFO_IN_PROGRESS });

    fetch("api").getInterventionInfo(
      uuid,
      (info) => {
        dispatch({ type: GET_INTERVENTION_INFO_SUCCESS, payload: { info } });
      },
      () => dispatch({ type: GET_INTERVENTION_INFO_FAIL }),
    );
  };
}
