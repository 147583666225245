export const FILE_ENCODINGS = {
  UTF_8: "UTF-8",
  /**
   * Expected encoding for TSV files:
   */
  TSV: "ISO-8859-1",
};

/**
 * @param {File}   file
 * @param {String} encoding
 *
 * @return {Promise<String>} Promise will read file content.
 */
export function readFileContent(file, encoding = FILE_ENCODINGS.UTF_8) {
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.readAsText(file, encoding);
    reader.onloadend = () => {
      resolve(reader.result);
    };
    reader.onerror = () => {
      reject();
    };
  });
}
