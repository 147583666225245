import I18n from "i18n-js";
import React, { createRef } from "react";
import { bool, func, shape } from "prop-types";
import { connect } from "react-redux";
import Form from "../../common/components/form/Form";
import Checkbox from "../../common/components/form/Checkbox";
import TextInput from "../../common/components/form/TextInput";
import Select from "../../common/components/form/Select";
import SubmitButton from "../../common/components/form/SubmitButton";
import ClientTypes from "../models/ClientTypes";
import CompanyCountry from "../models/CompanyCountry";
import Modal from "../../common/components/elements/Modal";
import { alphabetically } from "../../common/utils/filterUtils";
import {
  postProviderContract,
  searchServiceBeneficiary,
} from "../../service_provider/actions/serviceProviderActions";
import { ROUTE_PROVIDER_CONTRACTS } from "../../../routes";
import ClientUtils from "../utils/ClientUtils";
import { getClientsList } from "../actions/clientsActions";

class ClientForm extends Form {
  static propTypes = {
    searchServiceBeneficiary: func.isRequired,
    postProviderContract: func.isRequired,
    loading: bool,
    clients: shape().isRequired,
    getClientsList: func.isRequired,
    client: shape().isRequired,
    userProfile: shape().isRequired,
    ownClient: shape().isRequired,
    onSubmit: func.isRequired,
    info: shape(),
    beneficiary: shape().isRequired,
    history: shape().isRequired,
    goBack: func.isRequired,
  };

  static defaultProps = {
    info: {
      address: {},
    },
    loading: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      beneficiaryFound: false,
      createDefaultSite: false,
    };

    this.updateMod = !!props.info.uuid;
    this.beneficiaryTimeout = null;

    // Define all inputs form
    this.inputs.parent = createRef();
    this.inputs.legalCompanyName = createRef();
    this.inputs.externalId = createRef();
    this.inputs.individual = createRef();
    this.inputs.siret = createRef();
    this.inputs.vatCode = createRef();
    this.inputs.street = createRef();
    this.inputs.addressAddition = createRef();
    this.inputs.city = createRef();
    this.inputs.postal = createRef();
    this.inputs.country = createRef();
    this.inputs.wasteProducerRegistryCode = createRef();

    this.requestProviderContract = this.requestProviderContract.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeEnterpriseIdentifier =
      this.onChangeEnterpriseIdentifier.bind(this);
    this.renderOption = this.renderOption.bind(this);
    this.renderClientOption = this.renderClientOption.bind(this);
  }

  /**
   * {@inheritdoc}
   */
  componentDidUpdate(prevProps) {
    const { beneficiary, client, history, goBack, ownClient } = this.props;

    window.clearTimeout(this.beneficiaryTimeout);

    if (
      prevProps.beneficiary.postContractSuccess !==
        beneficiary.postContractSuccess &&
      beneficiary.postContractSuccess
    ) {
      this.beneficiaryTimeout = window.setTimeout(
        () => history.push(ROUTE_PROVIDER_CONTRACTS),
        2000,
      );

      return;
    }

    if (
      prevProps.beneficiary.infos !== beneficiary.infos &&
      ownClient &&
      (!beneficiary.infos || ownClient.uuid !== beneficiary.infos.uuid)
    ) {
      this.setState({ beneficiaryFound: beneficiary.infos !== null });
    }

    if (
      prevProps.client.saved === client.saved &&
      prevProps.client.errors === client.errors
    ) {
      return;
    }

    if (client.errors) {
      this.setState({ errors: client.errors });
    }

    if (client.saved) {
      goBack();
    }
  }

  /**
   * {@inheritdoc}
   */
  componentDidMount() {
    this.props.getClientsList();
  }

  /**
   * {@inheritdoc}
   */
  componentWillUnmount() {
    window.clearTimeout(this.beneficiaryTimeout);
  }

  onCloseModal() {
    this.setState({ beneficiaryFound: false });
  }

  /**
   * onSubmit form
   */
  onSubmit() {
    if (!this.isFormValid()) {
      return;
    }

    const client = {
      name: this.getInputValue("legalCompanyName"),
      individual: this.getInputValue("individual") || false,
      siret: this.getInputValue("siret"),
      vatCode: this.getInputValue("vatCode"),
      address: {
        street: this.getInputValue("street"),
        addressAddition: this.getInputValue("addressAddition"),
        city: this.getInputValue("city"),
        postal: this.getInputValue("postal"),
        country: this.getInputValue("country"),
      },
      parentUuid: this.getInputValue("parent"),
      externalId: this.getInputValue("externalId"),
      wasteProducerRegistryCode: this.getInputValue(
        "wasteProducerRegistryCode",
      ),
    };

    this.props.onSubmit(client, this.state.createDefaultSite);
  }

  /**
   * @param {Object} event
   */
  onChangeEnterpriseIdentifier(event) {
    const { value } = event.target;
    const { ownClient } = this.props;
    const isAuthorized = new ClientTypes(ownClient.type).canHaveFinalClient();

    if (!value.length || !isAuthorized) {
      return;
    }

    this.props.searchServiceBeneficiary(value);
  }

  requestProviderContract() {
    const { postProviderContract, beneficiary } = this.props;

    postProviderContract(beneficiary.infos.uuid);
  }

  /**
   * @param {Object} option
   *
   * @return {Element}
   */
  renderOption(option) {
    return (
      <option key={`option-${option.value}`} value={option.value}>
        {option.label}
      </option>
    );
  }

  /**
   * Render clients select
   *
   * @return {Element}
   */
  renderClientsSelect() {
    const { clients, info } = this.props;

    const options =
      clients?.list
        .filter((client) => new ClientTypes(client.type).canHaveFinalClient())
        .sort((a, b) =>
          alphabetically(
            ClientUtils.renderLabel(a),
            ClientUtils.renderLabel(b),
          ),
        ) || [];

    return (
      <Select
        id="parent"
        label={I18n.t("pages.clients.create.form.parent:label")}
        ref={this.inputs.parent}
        options={options}
        disabled={!!info.uuid}
        renderOption={this.renderClientOption}
        defaultValue={info.parentUuid}
        error={this.getInputErrors("parent")}
        loadingError={Boolean(clients.error)}
        loading={clients.inProgress}
      />
    );
  }

  /**
   * @param {Object} client
   *
   * @return {Element}
   */
  renderClientOption(client) {
    return (
      <option key={`option-${client.uuid}`} value={client.uuid}>
        {ClientUtils.renderLabel(client)}
      </option>
    );
  }

  /**
   * {@inheritdoc}
   */
  render() {
    const { loading, info, beneficiary, userProfile } = this.props;
    const { beneficiaryFound, createDefaultSite } = this.state;
    const companyCountry = new CompanyCountry(userProfile.companyCountry);

    let actionModal = (
      <div>
        <button
          className="btn btn--secondary"
          onClick={this.requestProviderContract}
        >
          {I18n.t("pages.clients.create.modal.action:yes")}
        </button>
        <button className="btn btn--secondary" onClick={this.onCloseModal}>
          {I18n.t("pages.clients.create.modal.action:no")}
        </button>
      </div>
    );

    if (beneficiary.postContractInProgress) {
      actionModal = (
        <p>{I18n.t("pages.clients.create.modal.in_progress:loader")}</p>
      );
    }

    if (beneficiary.postContractSuccess) {
      actionModal = (
        <p className="success">
          {I18n.t("pages.clients.create.modal.in_progress:success")}
        </p>
      );
    }

    return (
      <div>
        {beneficiary.infos && (
          <Modal
            isOpen={beneficiaryFound}
            title={I18n.t("pages.clients.create.modal.title")}
            onClose={this.onCloseModal}
            className="small"
          >
            <h2>
              {I18n.t("pages.clients.create.modal.content", {
                name: beneficiary.infos.legalCompanyName,
              })}
            </h2>
            <div>
              <p>{I18n.t("pages.clients.create.modal.action:label")}</p>
              {actionModal}
            </div>
          </Modal>
        )}
        <form>
          {this.renderClientsSelect()}
          <TextInput
            id="externalId"
            defaultValue={info.externalId}
            label={I18n.t("pages.clients.create.form.externalId:label")}
            ref={this.inputs.externalId}
            error={this.getInputErrors("externalId")}
            optional
          />
          <TextInput
            id="legalCompanyName"
            defaultValue={info.legalCompanyName}
            label={I18n.t("pages.clients.create.form.name:label")}
            ref={this.inputs.legalCompanyName}
            error={this.getInputErrors("legalCompanyName")}
          />
          {companyCountry.allowsIndividual() && (
            <Checkbox
              id="individual"
              label={I18n.t("pages.clients.create.form.individual")}
              ref={this.inputs.individual}
              checked={info.individual}
            />
          )}
          {companyCountry.needsSiret() && (
            <TextInput
              id="siret"
              type="siret"
              label={I18n.t("pages.clients.create.form.siret:label")}
              defaultValue={info.siret}
              ref={this.inputs.siret}
              error={this.getInputErrors("siret")}
              onBlur={this.onChangeEnterpriseIdentifier}
              optional
            />
          )}
          {companyCountry.canUseVatCode() && (
            <TextInput
              id="vatCode"
              label={I18n.t("pages.clients.create.form.vatCode:label")}
              defaultValue={info.vatCode}
              ref={this.inputs.vatCode}
              error={this.getInputErrors("vatCode")}
              onBlur={this.onChangeEnterpriseIdentifier}
              optional={!companyCountry.needsVatCode()}
            />
          )}
          <TextInput
            id="wasteProducerRegistryCode"
            label={I18n.t(
              "pages.clients.create.form.wasteProducerRegistryCode:label",
            )}
            defaultValue={info.wasteProducerRegistryCode}
            ref={this.inputs.wasteProducerRegistryCode}
            error={this.getInputErrors("wasteProducerRegistryCode")}
            optional
          />
          <hr />
          <TextInput
            id="street"
            defaultValue={info.address.street}
            label={I18n.t("pages.clients.create.form.address:label")}
            ref={this.inputs.street}
            error={this.getInputErrors("street")}
          />
          <TextInput
            id="addressAddition"
            defaultValue={info.address.addressAddition}
            label={I18n.t("pages.clients.create.form.addressAddition:label")}
            ref={this.inputs.addressAddition}
            optional
          />
          <TextInput
            id="postal"
            defaultValue={info.address.postal}
            label={I18n.t("pages.clients.create.form.postal:label")}
            ref={this.inputs.postal}
            error={this.getInputErrors("postal")}
          />
          <TextInput
            id="city"
            defaultValue={info.address.city}
            label={I18n.t("pages.clients.create.form.city:label")}
            ref={this.inputs.city}
            error={this.getInputErrors("city")}
          />
          <TextInput
            id="country"
            defaultValue={info.address.country}
            label={I18n.t("pages.clients.create.form.country:label")}
            ref={this.inputs.country}
            error={this.getInputErrors("country")}
          />
          {!this.updateMod && (
            <Checkbox
              key="createSite"
              id="createSite"
              label={I18n.t("pages.clients.create.form.create_site")}
              checked={createDefaultSite}
              onChange={() =>
                this.setState({ createDefaultSite: !createDefaultSite })
              }
            />
          )}
          <SubmitButton loading={loading} onClick={this.onSubmit} />
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  clients: state.clients,
  client: state.client,
  beneficiary: state.beneficiary,
  userProfile: state.auth.userProfile,
});

const mapDispatchToProps = (dispatch) => ({
  searchServiceBeneficiary: (value) =>
    dispatch(searchServiceBeneficiary(value)),
  getClientsList: () => dispatch(getClientsList()),
  postProviderContract: (uuid) => dispatch(postProviderContract(uuid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientForm);
