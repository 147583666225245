import gql from "graphql-tag";
import { FRAGMENT_INTERVENTION_PLANNED_FIELDS } from "../fragment/InterventionPlanned";

export const createInterventionPlannedQuery = gql`
  mutation createInterventionPlanned(
    $payload: CreateOrUpdateInterventionPlannedRequest!
  ) {
    createInterventionPlanned(payload: $payload) {
      ...interventionPlannedFields
    }
  }
  ${FRAGMENT_INTERVENTION_PLANNED_FIELDS}
`;

export const updateInterventionPlannedQuery = gql`
  mutation updateInterventionPlanned(
    $uuid: ID!
    $payload: CreateOrUpdateInterventionPlannedRequest!
  ) {
    updateInterventionPlanned(uuid: $uuid, payload: $payload) {
      ...interventionPlannedFields
    }
  }
  ${FRAGMENT_INTERVENTION_PLANNED_FIELDS}
`;

export const deleteInterventionPlannedQuery = gql`
  mutation deleteInterventionPlanned($uuid: ID!) {
    deleteInterventionPlanned(uuid: $uuid) {
      uuid
    }
  }
`;
