import I18n from "i18n-js";
import React from "react";
import { func, shape } from "prop-types";
import { connect } from "react-redux";
import { ROUTE_LEAK_DETECTORS } from "../../../routes";
import {
  updateLeakDetector,
  getLeakDetectorInfos,
} from "../actions/leakDetectorsActions";
import FormPage from "../../common/components/layout/FormPage";
import CreateLeakDetectorForm from "../components/CreateLeakDetectorForm";

class UpdateLeakDetector extends FormPage {
  static propTypes = {
    leakDetector: shape().isRequired,
    match: shape().isRequired,
    getLeakDetectorInfos: func.isRequired,
    updateLeakDetector: func.isRequired,
  };

  constructor() {
    super();

    this.onSubmit = this.onSubmit.bind(this);
  }

  /**
   * {@inheritdoc}
   */
  componentDidMount() {
    const { getLeakDetectorInfos, match } = this.props;

    getLeakDetectorInfos(match.params.uuid);
  }

  /**
   * @param {object} detector
   */
  onSubmit(detector) {
    const { updateLeakDetector, match } = this.props;

    updateLeakDetector(match.params.uuid, detector);
  }

  /**
   * {@inheritdoc}
   */
  getTitle() {
    return I18n.t("pages.leak_detectors.update.title");
  }

  /**
   * {@inheritdoc}
   */
  getBackRoute() {
    return ROUTE_LEAK_DETECTORS;
  }

  /**
   * {@inheritdoc}
   */
  renderContent() {
    const { leakDetector } = this.props;

    return (
      <CreateLeakDetectorForm
        loading={leakDetector.saving}
        infos={leakDetector.infos}
        onSubmit={this.onSubmit}
      />
    );
  }

  /**
   * {@inheritdoc}
   */
  render() {
    const { leakDetector } = this.props;

    if (leakDetector.infos === undefined) {
      return <div className="loader" />;
    }

    if (leakDetector.infos === null) {
      return <p>{I18n.t("pages.leak_detector.update.unknown")}</p>;
    }

    return super.render();
  }
}

const mapStateToProps = (state) => ({
  leakDetector: state.leakDetector,
});

const mapDispatchToProps = (dispatch) => ({
  getLeakDetectorInfos: (uuid) => dispatch(getLeakDetectorInfos(uuid)),
  updateLeakDetector: (uuid, detector) =>
    dispatch(updateLeakDetector(uuid, detector)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateLeakDetector);
