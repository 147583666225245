import {
  GET_USERS_LIST_IN_PROGRESS,
  GET_USERS_LIST_SUCCESS,
  GET_USERS_LIST_FAIL,
  PATCH_USER_LOCK_SUCCESS,
  PATCH_USER_UNLOCK_SUCCESS,
} from "../constants";
import { LOGOUT } from "../../authentication/constants";

const initialState = {
  list: [],
  inProgress: false,
  error: false,
};

/**
 * Sites reducer
 *
 * @param {Object} state
 * @param {Object} action
 *
 * @return {Object}
 */
export default function users(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case GET_USERS_LIST_IN_PROGRESS:
      return {
        ...state,
        inProgress: true,
        error: false,
      };

    case GET_USERS_LIST_SUCCESS:
      return {
        ...state,
        list: payload.list,
        inProgress: false,
      };

    case GET_USERS_LIST_FAIL:
      return {
        ...state,
        list: [],
        inProgress: false,
        error: true,
      };

    case PATCH_USER_LOCK_SUCCESS:
    case PATCH_USER_UNLOCK_SUCCESS: {
      const { list } = state;
      const userIndex = list.findIndex((user) => user.uuid === payload.uuid);

      list[userIndex].locked = payload.locked;

      return {
        ...state,
        list,
      };
    }

    case LOGOUT:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
}
