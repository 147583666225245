import I18n from "i18n-js";
import React from "react";
import { func, shape } from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import List from "../../common/components/layout/List";
import Table from "../../common/components/elements/Table";
import {
  getProvidersList,
  acceptServiceProviderContract,
  updateServiceProviderContract,
  refuseServiceProviderContract,
} from "../actions/serviceProviderActions";
import DateInput from "../../common/components/form/DateInput";
import StatelessCheckbox from "../../common/components/form/StatelessCheckbox";

class ProvidersList extends List {
  static propTypes = {
    getProvidersList: func.isRequired,
    updateServiceProviderContract: func.isRequired,
    acceptServiceProviderContract: func.isRequired,
    refuseServiceProviderContract: func.isRequired,
    providers: shape().isRequired,
    auth: shape().isRequired,
    location: shape().isRequired, // react-router location
  };

  constructor() {
    super();

    this.dataTableRow = this.dataTableRow.bind(this);
    this.activeFilter = this.activeFilter.bind(this);
    this.toRenewSoonFilter = this.toRenewSoonFilter.bind(this);
  }

  /**
   * {@inheritdoc}
   */
  componentDidMount() {
    super.componentDidMount();

    this.props.getProvidersList();
  }

  /**
   * @param {String} startDate
   * @param {String} endDate
   * @param {Object} data
   */
  onValidate(startDate, endDate, data) {
    if (!startDate || !endDate) {
      return;
    }

    const { updateServiceProviderContract, acceptServiceProviderContract } =
      this.props;
    const { uuid, enabled } = data;
    const startDateFormat = startDate.toISOString();
    const endDateFormat = endDate.toISOString();

    if (enabled) {
      updateServiceProviderContract(startDateFormat, endDateFormat, uuid);

      return;
    }

    acceptServiceProviderContract(startDateFormat, endDateFormat, uuid);
  }

  /**
   * @param {String} uuid
   */
  onRefuse(uuid) {
    this.props.refuseServiceProviderContract(uuid);
  }

  /**
   * @return {Array}
   */
  dataTableHead() {
    return [
      I18n.t("pages.service_provider.head.beneficiaryName"),
      I18n.t("pages.service_provider.head.providerName"),
      I18n.t("pages.service_provider.head.startDate"),
      I18n.t("pages.service_provider.head.endDate"),
      I18n.t("pages.service_provider.head.actions"),
    ];
  }

  /**
   * @param {Object} rowData
   *
   * @return {Array}
   */
  dataTableRow(rowData) {
    const { beneficiary, provider, enabled } = rowData;

    let startDate = rowData.startDate && new Date(rowData.startDate);
    let endDate = rowData.endDate && new Date(rowData.endDate);

    return [
      beneficiary.legalCompanyName,
      provider.legalCompanyName,
      <DateInput
        key="startDate"
        onChange={(date) => {
          startDate = date;
        }}
        defaultValue={startDate}
      />,
      <DateInput
        key="endDate"
        onChange={(date) => {
          endDate = date;
        }}
        defaultValue={endDate}
      />,
      [
        <button
          key="validate"
          onClick={() => this.onValidate(startDate, endDate, rowData)}
          className="btn btn--secondary"
        >
          {enabled
            ? I18n.t("pages.service_provider.list.action:update")
            : I18n.t("pages.service_provider.list.action:accept")}
        </button>,
        !enabled && (
          <button
            key="refuse"
            onClick={() => this.onRefuse(rowData.uuid)}
            className="btn btn--secondary"
          >
            {I18n.t("pages.service_provider.list.action:refuse")}
          </button>
        ),
      ],
    ];
  }

  /**
   * @param {Object} data
   *
   * @return {Boolean}
   */
  activeFilter(data) {
    const now = moment();

    return data.enabled && moment(data.endDate) > now;
  }

  /**
   * @param {Object} data
   *
   * @return {Boolean}
   */
  toRenewSoonFilter(data) {
    const now = moment();
    const toRenewDate = now.clone().add(2, "months");

    return (
      data.enabled &&
      moment(data.endDate) > now &&
      moment(data.endDate) <= toRenewDate
    );
  }

  /**
   * @param {Object} data
   *
   * @return {Boolean}
   */
  toApproveFilter(data) {
    return !data.enabled;
  }

  /**
   * {@inheritdoc}
   */
  renderFilters() {
    const { filters } = this.state;

    return [
      <StatelessCheckbox
        key="filterActiveOnly"
        id="filterActiveOnly"
        label={I18n.t("filters.active_only")}
        checked={Boolean(filters.active)}
        onChangedValue={(checked) =>
          this.onChangeCheckboxFilter(checked, "active")
        }
      />,
      <StatelessCheckbox
        key="filterToRenewSoonOnly"
        id="filterToRenewSoonOnly"
        label={I18n.t("filters.to_renew_soon_only")}
        checked={Boolean(filters.toRenewSoon)}
        onChangedValue={(checked) =>
          this.onChangeCheckboxFilter(checked, "toRenewSoon")
        }
      />,
      <StatelessCheckbox
        key="filterWaitingApprovalOnly"
        id="filterWaitingApprovalOnly"
        label={I18n.t("filters.waiting_approval_only")}
        checked={Boolean(filters.approve)}
        onChangedValue={(checked) =>
          this.onChangeCheckboxFilter(checked, "approve")
        }
      />,
    ];
  }

  /**
   * {@inheritdoc}
   */
  renderContent() {
    const { auth, providers } = this.props;

    if (providers.inProgress) {
      return <div className="loader" />;
    }

    if (providers.error) {
      return <p>{I18n.t("common.loadingApiError")}</p>;
    }

    const list = providers.list
      .filter(this.getCustomFilter("active", this.activeFilter))
      .filter(this.getCustomFilter("toRenewSoon", this.toRenewSoonFilter))
      .filter(this.getCustomFilter("approve", this.toApproveFilter))
      .filter(
        (contract) => contract.provider.uuid !== auth.userProfile.clientUuid,
      );

    return (
      <Table
        emptyContent={I18n.t("pages.service_provider.list.empty")}
        data={list}
        headData={this.dataTableHead()}
        rowData={this.dataTableRow}
        filters={this.renderFilters()}
        pagination
        onPageChanged={this.onPageChanged}
        initialPage={this.getCurrentPage()}
      />
    );
  }

  /**
   * {@inheritdoc}
   */
  renderTitle() {
    return I18n.t("pages.service_provider.list.title");
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  providers: state.providers,
});

const mapDispatchToProps = (dispatch) => ({
  getProvidersList: () => dispatch(getProvidersList()),
  updateServiceProviderContract: (startDate, endDate, uuid) =>
    dispatch(updateServiceProviderContract(startDate, endDate, uuid)),
  acceptServiceProviderContract: (startDate, endDate, uuid) =>
    dispatch(acceptServiceProviderContract(startDate, endDate, uuid)),
  refuseServiceProviderContract: (uuid) =>
    dispatch(refuseServiceProviderContract(uuid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProvidersList);
