import { connect } from "react-redux";
import { RawPrivateRoute } from "./PrivateRoute";
import Roles from "../../../users/models/Roles";
import {
  getUserProfile,
  acceptTerms,
} from "../../../authentication/actions/authActions";
import { getClientsList } from "../../../company/actions/clientsActions";

/**
 * Admin route
 * Custom react-router route
 */
class AdminRoute extends RawPrivateRoute {
  /**
   * {@inheritdoc}
   */
  hasAccess() {
    return Roles.userHasRole(this.props.auth.userProfile.roles, Roles.ADMIN);
  }
}

const mapStateToProps = (state) => ({
  clients: state.clients,
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  getClientsList: () => dispatch(getClientsList()),
  getUserProfile: () => dispatch(getUserProfile()),
  acceptTerms: () => dispatch(acceptTerms()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminRoute);
