import { getLang } from "./langUtils";

export function parseDate(value) {
  if (!value) {
    return null;
  }

  const fr = /(\d{2})[/\- ](\d{2})[/\- ](\d{4})/g.exec(value);

  if (fr) {
    return new Date(fr[3], parseInt(fr[2], 10) - 1, fr[1], 0, 0, 0, 0);
  }

  const us = /(\d{4})[/\- ](\d{2})[/\- ](\d{2})/g.exec(value);

  if (us) {
    return new Date(us[1], parseInt(us[2], 10) - 1, us[3], 0, 0, 0, 0);
  }

  return new Date(value);
}

/**
 * @param {Date|string} date
 *
 * @return {String}
 */
export function formatDate(date) {
  return new Date(date).toLocaleDateString(getLang());
}

/**
 * @param {Date|string} date
 *
 * @return {String}
 */
export function formatDateTime(date) {
  return (
    new Date(date).toLocaleDateString(getLang()) +
    " " +
    new Date(date).toLocaleTimeString(getLang())
  );
}
