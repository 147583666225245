import I18n from "i18n-js";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { bool, func, instanceOf, oneOfType, shape, string } from "prop-types";
import { ROUTE_SITES } from "../../../routes";
import FormPage from "../../common/components/layout/FormPage";
import SubmitButton from "../../common/components/form/SubmitButton";
import DownloadButton from "../../common/components/elements/DownloadButton";
import { importSites } from "../actions/sitesActions";
import { FILE_ENCODINGS, readFileContent } from "../../common/utils/fileUtils";
import ImportSitesContent from "./ImportSites/ImportSitesContent";

class ImportSites extends FormPage {
  static propTypes = {
    error: oneOfType([shape(), bool]),
    success: bool,
    tsvReport: shape({
      content: instanceOf(Blob).isRequired,
      name: string.isRequired,
    }),
    importSites: func.isRequired,
  };

  constructor() {
    super();
    this.state = {
      file: null,
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onSelectedFile = this.onSelectedFile.bind(this);
    this.hasSelectedFile = this.hasSelectedFile.bind(this);
  }

  /**
   * {@inheritdoc}
   */
  componentDidUpdate(prevProps) {
    const { error, success } = this.props;

    if (prevProps.error === error || prevProps.success === success) {
      return;
    }

    if (error || success) {
      this.setState({ loading: false });
    }
  }

  onSelectedFile(e) {
    const file = e.target.files.length > 0 && e.target.files[0];

    this.setState({ file });
  }

  onSubmit() {
    const { importSites } = this.props;
    const { file } = this.state;

    this.setState({ loading: true });

    readFileContent(file, FILE_ENCODINGS.TSV).then((tsvContent) => {
      importSites(tsvContent);
    });
  }

  hasSelectedFile() {
    return !!this.state.file;
  }

  /**
   * {@inheritdoc}
   */
  getTitle() {
    return I18n.t("pages.sites.import.title");
  }

  /**
   * {@inheritdoc}
   */
  getBackRoute() {
    return ROUTE_SITES;
  }

  /**
   * {@inheritdoc}
   */
  renderContent() {
    const { loading } = this.state;
    const { error, success, tsvReport } = this.props;
    const locale = I18n.locale;

    return (
      <Fragment>
        <ImportSitesContent
          downloadSample={
            <a href={`/import_samples/${locale}/import_sites.tsv`}>
              {I18n.t("pages.sites.import.file_sample")}
            </a>
          }
        />

        <span style={{ marginRight: "10px" }}>
          {I18n.t("pages.sites.import.file_input")}
        </span>
        <input type="file" accept=".csv, .tsv" onChange={this.onSelectedFile} />
        <SubmitButton
          loading={loading}
          onClick={this.onSubmit}
          label={I18n.t("pages.sites.import.submit")}
          disabled={!this.hasSelectedFile()}
        />
        <br />
        {success && (
          <div className="text-primary">
            <span>{I18n.t("pages.sites.import.success")}</span>
          </div>
        )}
        {error && (
          <div className="text-danger">
            <span>{I18n.t("pages.sites.import.error")}</span>
          </div>
        )}
        {error && typeof error === "object" && (
          <div>
            {error.detail.split("\n").map((message) => (
              <span className="text-danger" key={message}>
                {message}
              </span>
            ))}
          </div>
        )}
        {tsvReport && (
          <div>
            <p className="text-warning">
              ⚠︎ {I18n.t("pages.sites.import.tsv_report.explanation")}
            </p>
            <DownloadButton
              fileContent={() => tsvReport.content}
              filename={tsvReport.name}
              label={I18n.t("pages.sites.import.tsv_report.download_btn")}
            />
          </div>
        )}
        {/* TODO: List & explain most common errors */}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  error: state.importSites.error,
  success: state.importSites.success,
  tsvReport: state.importSites.tsvReport,
});

const mapDispatchToProps = (dispatch) => ({
  importSites: (tsvContent) => dispatch(importSites(tsvContent)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImportSites);
