class Authenticator {
  static STORAGE_TOKEN = "accessToken";
  static STORAGE_PROFILE = "userProfile";
  static STORAGE_APP_VERSION = "appVersion";

  /**
   * @param {ApiClient} api
   */
  constructor(api) {
    this.api = api;
  }

  /**
   * Fetch login API
   *
   * @param {Object}   credentials
   * @param {Function} callback
   */
  login(credentials, callback) {
    this.api.login(
      credentials,
      (data) => this.onSuccessLogin(data, callback),
      (data) => this.onFailureLogin(data, callback),
    );
  }

  /**
   * Success login response
   *
   * @param {Object}   data
   * @param {Function} callback
   */
  onSuccessLogin(data, callback) {
    const { token } = data;

    this.createToken(token);
    this.setAppVersion();

    callback(token);
  }

  /**
   * Failure login response
   *
   * @param {Object}   data
   * @param {Function} callback
   */
  onFailureLogin(data, callback) {
    callback(null, data.code);
  }

  /**
   * Create and store the access token
   *
   * @param {String} token
   */
  createToken(token) {
    localStorage.setItem(Authenticator.STORAGE_TOKEN, token);

    this.api.setToken(token);
  }

  /**
   * Set app version
   */
  setAppVersion() {
    localStorage.setItem(
      Authenticator.STORAGE_APP_VERSION,
      AppConfig.APP_VERSION,
    );
  }

  /**
   * Get access token
   *
   * @return {String}
   */
  getToken() {
    const token = localStorage.getItem(Authenticator.STORAGE_TOKEN);

    this.api.setToken(token);

    return token;
  }

  /**
   * @return {String}
   */
  isAppVersionMatch() {
    const currentAppVersion = localStorage.getItem(
      Authenticator.STORAGE_APP_VERSION,
    );

    return currentAppVersion === AppConfig.APP_VERSION;
  }

  /**
   * Remove stored access token
   */
  removeToken() {
    localStorage.removeItem(Authenticator.STORAGE_TOKEN);

    this.api.setToken(null);
  }

  /**
   * Remove app version
   */
  removeAppVersion() {
    localStorage.removeItem(Authenticator.STORAGE_APP_VERSION);
  }

  /**
   * Get stored profile
   *
   * @return {Object}
   */
  getProfile() {
    return localStorage.getItem(Authenticator.STORAGE_PROFILE);
  }

  /**
   * Fetch user profile
   *
   * @param {Function} callback
   * @param {Boolean} force
   */
  getUserProfile(callback, force = false) {
    const profile = force ? null : this.getProfile();

    if (profile) {
      this.onSuccessGetUserProfile(JSON.parse(profile), callback);

      return;
    }

    this.api.getUserProfile(
      (data) => this.onSuccessGetUserProfile(data, callback),
      (data) => this.onFailureGetUserProfile(data, callback),
    );
  }

  /**
   * Success user profile response
   *
   * @param {Object}   data
   * @param {Function} callback
   */
  onSuccessGetUserProfile(data, callback) {
    this.saveProfile(data);

    callback(data);
  }

  /**
   * Failure user profile response
   *
   * @param {Object}   data
   * @param {Function} callback
   */
  onFailureGetUserProfile(data, callback) {
    callback(null, data.code);
  }

  /**
   * Create and store the profile
   *
   * @param {Object} profile
   */
  saveProfile(profile) {
    localStorage.setItem(
      Authenticator.STORAGE_PROFILE,
      JSON.stringify(profile),
    );
  }

  /**
   * Remove stored profile
   */
  eraseProfile() {
    localStorage.removeItem(Authenticator.STORAGE_PROFILE);
  }

  /**
   * Is user authenticated?
   *
   * @return {Boolean}
   */
  isAuthenticated() {
    return !!this.getToken();
  }
}

export default Authenticator;
