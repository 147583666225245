import React, { Component } from "react";
import { shape } from "prop-types";
import { goBack } from "../../utils/navigationUtils";
import { ROUTE_FORBIDDEN } from "../../../../routes";
import I18n from "i18n-js";

class FormPage extends Component {
  static propTypes = {
    history: shape().isRequired,
  };

  constructor(props) {
    super(props);

    this.goBack = this.goBack.bind(this);
    this.denyAccess = this.denyAccess.bind(this);
  }

  /**
   * Get the title of the page
   *
   * @return {String|JSX.Element}
   */
  getTitle() {
    throw new Error("You must implement method getTitle");
  }

  /**
   * Get the back route button
   *
   * @return {String} route
   */
  getBackRoute() {
    throw new Error("You must implement method getBackRoute");
  }

  /**
   * Render the content of the page
   *
   * @return {Element}
   */
  renderContent() {
    throw new Error("You must implement method renderContent");
  }

  /**
   * Back to the previous page
   *
   * @return {Function} history method
   */
  goBack(event) {
    const { history } = this.props;

    if (event) {
      event.preventDefault();
    }

    return goBack(history, this.getBackRoute());
  }

  denyAccess() {
    this.props.history.replace(ROUTE_FORBIDDEN);
  }

  /**
   * {@inheritdoc}
   */
  render() {
    return (
      <div className="row justify-content-center">
        <div className="col-xs-12 col-sm-10 col-lg-7">
          <button
            type="button"
            className="btn btn--secondary back float-right"
            onClick={this.goBack}
          >
            {I18n.t("common.back_to_list")}
          </button>
          <h1>{this.getTitle()}</h1>
        </div>
        <div className="col-xs-12 col-sm-10 col-lg-7">
          {React.cloneElement(this.renderContent(), {
            denyAccess: this.denyAccess,
            goBack: this.goBack,
          })}
        </div>
      </div>
    );
  }
}

export default FormPage;
