import React, { useState } from "react";
import { func, instanceOf, string } from "prop-types";
import WasteFormBreadcrumb from "../components/WasteFormBreadcrumb";
import FormLayout from "../../common/components/layout/FormLayout";
import {
  ROUTE_MY_WASTE_RECOVERY_REQUESTS,
  ROUTE_WASTE_RECOVERY_REQUESTS_CREATE_ARTICLES,
  ROUTE_WASTE_RECOVERY_REQUESTS_CREATE_CONTAINERS,
} from "../../../routes";
import { AddressInput } from "../propTypes/types";
import { connect, useSelector } from "react-redux";
import DateInput from "../../common/components/form/DateInput";
import Textarea from "../../common/components/form/Textarea";
import { useHistory } from "react-router";
import { setInfo } from "../actions/createWasteRecoveryRequestActions";
import SelectWasteSite from "../components/SelectWasteSite";
import { getFieldViolations } from "../selectors/createWasteRecoveryRequestSelectors";
import StatelessTextInput from "../../common/components/form/StatelessTextInput";
import SelectCountry from "../../common/components/form/SelectCountry";
import { useAuth } from "../../authentication/contexts/useAuth";
import { useQuery } from "@apollo/client";
import wasteEligibleContainers from "../graphql/queries/wasteEligibleContainers.graphql";

export default connect(
  (state) => {
    const { siteUuid, date, attestationReceiptAddress, comments } =
      state.createWasteRecoveryRequestReducer;

    return {
      siteUuid,
      date: typeof date === "string" ? new Date(date) : date,
      attestationReceiptAddress,
      comments,
    };
  },
  {
    setInfo,
  },
)(CreateWasteRecoveryRequest);

CreateWasteRecoveryRequest.propTypes = {
  siteUuid: string,
  date: instanceOf(Date),
  attestationReceiptAddress: AddressInput,
  comments: string,
  setInfo: func.isRequired,
};

function CreateWasteRecoveryRequest({
  siteUuid,
  date,
  attestationReceiptAddress,
  comments,
  setInfo,
}) {
  const history = useHistory();
  const { user } = useAuth();
  const [inputs, setInputs] = useState({
    siteUuid,
    date,
    attestationReceiptAddress,
    comments,
  });

  const setInput = (inputName) => (v) =>
    setInputs({ ...inputs, [inputName]: v });
  const setAddressInput = (inputName) => (v) =>
    setInputs({
      ...inputs,
      attestationReceiptAddress: {
        ...inputs.attestationReceiptAddress,
        [inputName]: v,
      },
    });

  // TODO: rework this. A hook is not supposed to be called like this and can cause troubles.
  const getFieldErrors = (fieldName) =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useSelector(getFieldViolations(fieldName)).map((v) => v.message);
  const errors = {
    siteUuid: getFieldErrors("siteUuid"),
    date: getFieldErrors("date"),
    attestationReceiptAddress: {
      street: getFieldErrors("attestationReceiptAddress.street").concat(
        getFieldErrors("attestationReceiptAddress"),
      ),
      streetAddition: getFieldErrors(
        "attestationReceiptAddress.streetAddition",
      ),
      postalCode: getFieldErrors("attestationReceiptAddress.postalCode"),
      city: getFieldErrors("attestationReceiptAddress.city"),
      country: getFieldErrors("attestationReceiptAddress.country"),
    },
    comments: getFieldErrors("comments"),
  };

  // Pre-fetch the list of eligible containers, so we can redirect accordingly
  // (no need to ask for container selection if the site has no eligible container)
  const { loading, error, data } = useQuery(wasteEligibleContainers);

  const isValid = () => {
    if (!inputs.siteUuid || !inputs.date) {
      return false;
    }

    return true;
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!isValid()) {
      return;
    }

    setInfo(inputs);

    const sitesUuidsWithContainers = (
      data?.wasteEligibleContainers || []
    ).reduce((sites, { site }) => [...sites, site.uuid], []);
    if (!sitesUuidsWithContainers.includes(inputs.siteUuid)) {
      // no need to ask for container selection if the site has no eligible container
      history.push(ROUTE_WASTE_RECOVERY_REQUESTS_CREATE_ARTICLES);

      return;
    }

    history.push(ROUTE_WASTE_RECOVERY_REQUESTS_CREATE_CONTAINERS);
  };

  const hasStartedFillingAddress = Boolean(
    Object.values(inputs?.attestationReceiptAddress || {}).find((v) =>
      Boolean(v),
    ),
  );
  let country = inputs.attestationReceiptAddress?.country;
  if (typeof country === "undefined" && hasStartedFillingAddress) {
    // init the country with the current company's country code.
    // After hasStartedFillingAddress, because it should not make the address fields mandatory unless explicitly set by the user
    country = user.companyCountry;
    setAddressInput("country")(country);
  }

  return (
    <div>
      <FormLayout
        title="Demande de reprise de déchets"
        backRoute={ROUTE_MY_WASTE_RECOVERY_REQUESTS}
      >
        <form onSubmit={onSubmit}>
          <WasteFormBreadcrumb position={WasteFormBreadcrumb.INFO} />

          <SelectWasteSite
            id="site"
            label="Site"
            value={inputs.siteUuid}
            onChangedValue={setInput("siteUuid")}
            errors={errors.siteUuid}
          />
          <DateInput
            id="date"
            label="Date de reprise souhaitée"
            defaultValue={inputs.date}
            onChange={setInput("date")}
            minDate={new Date()}
            error={errors.date}
          />

          <fieldset>
            <legend>
              Adresse d&apos;envoi postal de l&apos;attestation de traitement
              <button
                type="button"
                className="btn btn-dark btn-xs btn--radius-xs pull-right mt-2"
                onClick={() => setInput("attestationReceiptAddress")(null)}
              >
                Effacer
              </button>
            </legend>
            <div className="row">
              <div className="offset-lg-1 col-lg-11 col-12">
                <StatelessTextInput
                  id="address1"
                  label="Addresse 1"
                  onChangedValue={setAddressInput("street")}
                  value={inputs.attestationReceiptAddress?.street || ""}
                  optional={!hasStartedFillingAddress}
                  errors={errors.attestationReceiptAddress.street}
                  layout="horizontal"
                />
                <StatelessTextInput
                  id="address2"
                  label="Addresse 2"
                  onChangedValue={setAddressInput("streetAddition")}
                  value={inputs.attestationReceiptAddress?.streetAddition || ""}
                  optional
                  errors={errors.attestationReceiptAddress.streetAddition}
                  layout="horizontal"
                />
                <StatelessTextInput
                  id="postalCode"
                  label="Code postal"
                  onChangedValue={setAddressInput("postalCode")}
                  value={inputs.attestationReceiptAddress?.postalCode || ""}
                  optional={!hasStartedFillingAddress}
                  errors={errors.attestationReceiptAddress.postalCode}
                  layout="horizontal"
                />
                <StatelessTextInput
                  id="city"
                  label="Ville"
                  onChangedValue={setAddressInput("city")}
                  value={inputs.attestationReceiptAddress?.city || ""}
                  optional={!hasStartedFillingAddress}
                  errors={errors.attestationReceiptAddress.city}
                  layout="horizontal"
                />
                <SelectCountry
                  id="country"
                  label="Pays"
                  onChangedValue={setAddressInput("country")}
                  value={country}
                  optional={!hasStartedFillingAddress}
                  errors={errors.attestationReceiptAddress.country}
                  layout="horizontal"
                />
              </div>
            </div>
          </fieldset>

          <Textarea
            id="comments"
            label="Commentaires"
            onChangedValue={setInput("comments")}
            defaultValue={inputs.comments}
            optional
            errors={errors.comments}
          />

          <button
            type="submit"
            className="btn btn-primary float-right"
            disabled={loading || error}
          >
            Passer à l&apos;étape suivante
          </button>
        </form>
      </FormLayout>
    </div>
  );
}
