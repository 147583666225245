export const GET_CARRIERS_LIST_IN_PROGRESS = "GET_CARRIERS_LIST_IN_PROGRESS";
export const GET_CARRIERS_LIST_SUCCESS = "GET_CARRIERS_LIST_SUCCESS";
export const GET_CARRIERS_LIST_FAIL = "GET_CARRIERS_LIST_FAIL";
export const GET_CARRIER_INFOS_IN_PROGRESS = "GET_CARRIER_INFOS_IN_PROGRESS";
export const GET_CARRIER_INFOS_SUCCESS = "GET_CARRIER_INFOS_SUCCESS";
export const GET_CARRIER_INFOS_FAIL = "GET_CARRIER_INFOS_FAIL";
export const POST_CARRIER_IN_PROGRESS = "POST_CARRIER_IN_PROGRESS";
export const POST_CARRIER_SUCCESS = "POST_CARRIER_SUCCESS";
export const POST_CARRIER_FAIL = "POST_CARRIER_FAIL";
export const PUT_CARRIER_IN_PROGRESS = "PUT_CARRIER_IN_PROGRESS";
export const PUT_CARRIER_SUCCESS = "PUT_CARRIER_SUCCESS";
export const PUT_CARRIER_FAIL = "PUT_CARRIER_FAIL";
export const DELETE_CARRIER_IN_PROGRESS = "DELETE_CARRIER_IN_PROGRESS";
export const DELETE_CARRIER_SUCCESS = "DELETE_CARRIER_SUCCESS";
export const DELETE_CARRIER_FAIL = "DELETE_CARRIER_FAIL";
export const RESET_CARRIER = "RESET_CARRIER";
