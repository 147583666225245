import I18n from "i18n-js";
import { shape } from "prop-types";
import React from "react";
import { useDispatch } from "react-redux";
import { ROUTE_INTERVENTIONS_PLANNED } from "../../../routes";
import { errorAlert, successAlert } from "../../common/actions/alertActions";
import { useMutation } from "../../common/api/GraphQLClient";
import FormPage from "../../common/components/layout/FormPage";
import { goBack } from "../../common/utils/navigationUtils";
import InterventionForm from "../components/InterventionForm";
import { onCreatePlannedInterventionRequest } from "../graphql/cache/apolloCache";
import { createInterventionPlannedQuery } from "../graphql/mutations/plannedInterventionMutations";

class CreatePlannedIntervention extends FormPage {
  /**
   * {@inheritdoc}
   */
  getTitle() {
    return I18n.t("pages.plannedInterventions.create.title");
  }

  /**
   * {@inheritdoc}
   */
  getBackRoute() {
    return ROUTE_INTERVENTIONS_PLANNED;
  }

  onSubmit(intervention) {
    this.props.createPlannedIntervention(intervention);
  }

  /**
   * {@inheritdoc}
   */
  renderContent() {
    return <InterventionForm onSubmit={this.props.onSubmit} />;
  }
}

const CreatePlannedInterventionWrapper = (props) => {
  const dispatch = useDispatch();
  const [createRequest] = useMutation(createInterventionPlannedQuery, {
    update: onCreatePlannedInterventionRequest,
  });

  const onSubmit = (payload) => {
    createRequest({ variables: { payload } })
      .then(() => {
        const message = I18n.t("alerts.create:plannedIntervention.success");
        dispatch(successAlert(message));
        goBack(props.history, ROUTE_INTERVENTIONS_PLANNED);
      })
      .catch(() => {
        const message = I18n.t("alerts.create:plannedIntervention.fail");
        dispatch(errorAlert(message));
      });
  };
  return <CreatePlannedIntervention {...props} onSubmit={onSubmit} />;
};

CreatePlannedInterventionWrapper.propTypes = {
  history: shape().isRequired,
};

export default CreatePlannedInterventionWrapper;
